import React from "react";
import { Divider, Text, VStack, Hidden } from "native-base";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { EventDetailLocation } from "features/tickets/events/components/event-detail-location.component";
import { TicketProductDetailHeader } from "features/tickets/ticket-products/components/ticket-product-detail-header.component";
import { TicketProductDetailSupportButton } from "features/tickets/ticket-products/components/ticket-product-detail-support-button.component";
import { TicketProductDetailLineup } from "features/tickets/ticket-products/components/ticket-product-detail-lineup.component";
import { TicketCollectibleDetailMyTickets } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-my-tickets.component";
import { TicketProductDetailVenue } from "features/tickets/ticket-products/components/ticket-product-detail-venue.component";
import { PostList } from "features/general/posts/components/post-list.component";
// helper functions
import { translate } from "utils/helpers";
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";
import { ErrorBoundary } from "common/components/misc/error-boundary.component";

export const TicketCollectibleDetailEvent = ({
  isDardan,
  auction,
  collectible = null,
  collectibles,
  event,
}) => {
  return auction && event ? (
    <VStack py={5} space={10}>
      <TicketProductDetailHeader
        isDardan={isDardan}
        auction={auction}
        event={event}
      />

      <Hidden from={"lg"}>
        {collectibles?.length > 0 && (
          <TicketCollectibleDetailMyTickets
            isDardan={isDardan}
            collectible={collectible}
            collectibles={collectibles}
            event={auction?.events?.length > 0 ? auction?.events[0] : null}
          />
        )}
      </Hidden>

      <VStack space={10}>
        {event?.description && (
          <ExpandableTextReadMore
            text={event?.description}
            title={isDardan ? translate("about") : translate("about_event")}
            noOfLines={6}
          />
        )}

        <Divider />

        <VStack space={6}>
          <Text fontSize={["xl", "2xl"]} fontWeight="semibold">
            {translate("details")}
          </Text>
          <TicketProductDetailLineup musicians={auction?.musicians} />

          <TicketProductDetailVenue
            entryTime={auction?.events?.[0]?.entry_time}
          />
        </VStack>

        <Divider />

        <PostList auctionID={auction?.id} />

        <ErrorBoundary>
          {!isDardan && (
            <EventDetailLocation
              location={event?.location}
              lat={event?.latitude}
              lng={event?.longitude}
            />
          )}
        </ErrorBoundary>

        <TicketProductDetailSupportButton />
      </VStack>
    </VStack>
  ) : (
    <Loading />
  );
};
