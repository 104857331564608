import React from "react";
import { Modal } from "theme/feedback/modal.component";
import { Box, Text, VStack } from "native-base";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { translate } from "utils/helpers";

interface PublicProfileProps {
  showModal: boolean;
  setProfilePublic: () => void;
  setProfilePrivate: () => void;
  toggleModal: () => void;
}

export const ProfilePublicModal = ({
  showModal,
  setProfilePublic,
  setProfilePrivate,
  toggleModal,
}: PublicProfileProps) => {
  return (
    <Modal
      pressableChildren={undefined}
      modalChildren={
        <Box
          width={[275, 300, 350]}
          _dark={{ bg: "dark.900" }}
          _light={{ bg: "light.100" }}
          borderRadius={"xl"}
          padding={5}
          mx={2}
        >
          <VStack space={6}>
            <Text bold fontSize={["md", "lg"]} numberOfLines={1} mr={4}>
              {translate("set_profile_public_title")}
            </Text>
            <Text fontSize={["sm", "md"]}>
              {translate("set_profile_public")}
            </Text>
            <VStack space={2} mx={5} justifyContent={"space-between"}>
              <SecondaryButton
                text={translate("no")}
                onPress={setProfilePrivate}
              />
              <PrimaryButton
                text={translate("yes")}
                textSize={["lg", "xl"]}
                onPress={setProfilePublic}
              />
            </VStack>
          </VStack>
        </Box>
      }
      modalStyles={{
        alignItems: "center",
        bg: "transparent",
      }}
      onClose={toggleModal}
      isOpen={showModal}
    />
  );
};
