import { useContext } from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import { translate } from "utils/helpers";
import { ToastAlert } from "theme/feedback/toast-alert.component";

const useDetachPaymentMethod = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation(
    (paymentMethodID) => client.delete(`/payment_methods/${paymentMethodID}/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`payment-methods`);
        Toast.show({
          placement: "top",
          render: ({ id }) => (
            <ToastAlert
              id={id}
              status="info"
              description={translate("payment_method_removed")}
            />
          ),
        });
      },
    }
  );
};

export default useDetachPaymentMethod;
