import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserLicenseCollectibleStreams = () => {
  const { client } = useContext(AxiosContext);

  return useMutation(
    (params = {}) =>
      client
        .get(`me/streams/`, {
          params: { ...params, limit: 2000 },
        })
        .then((resp) => resp.data),
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
