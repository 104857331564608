import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "services/authentication.context";
import { AxiosContext } from "services/axios.context";

export const useCollectible = (id, initialData = {}) => {
  const { client } = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery(
    `collectible-${id}`,
    () =>
      client.get(`collectibles/${id}/`).then((resp) => {
        console.debug(`💙 Collectible ${id} `);
        return resp.data;
      }),
    {
      enabled: !!id && isAuthenticated,
      placeholderData: initialData,
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
