import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateTrackContributor = (trackID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createTrackContributor: useMutation(
      (data) =>
        client
          .post(`tracks/${trackID}/contributors/`, data)
          .then((res) => res.data),
      {
        onSuccess: () => queryClient.invalidateQueries(`track-${trackID}`),
      }
    ),
    error: error,
  };
};

export default useCreateTrackContributor;
