import "react-native-url-polyfill/auto";
import i18n from "i18n-js";
import React, { useEffect } from "react";

import { Text, TextInput, Platform } from "react-native";
import { extendTheme } from "native-base";
import { QueryClient } from "react-query";
import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { AppContainer } from "./src/app-container";
// utility functions
import { theme } from "theme/options";

// FireStore Initialisation

console.debug("⛓️ Chain ID: ", Constants.expoConfig.extra.CHAIN_ID);
console.debug("⛓️ Polygon RPC: ", Constants.expoConfig.extra.POLYGON_RPC);

// Translation Support
const en = require("./translations/en.json");
const de = require("./translations/de.json");
i18n.translations = { en, de };
i18n.fallbacks = true;

// Custom NativeBase Theme Setup
export const customTheme = extendTheme(theme);
// provider config
const config = {
  dependencies: {
    "linear-gradient": require("expo-linear-gradient").LinearGradient,
  },
};

// Disable font scaling
Text.defaultProps = Text.defaultProps || {};
TextInput.defaultProps = TextInput.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps.allowFontScaling = false;

// Initialize React Query Settings
const queryClient = new QueryClient();

export default function App() {
  useEffect(() => {
    if (Platform.OS === "web") {
      document.documentElement.lang = i18n.locale;
    }
    // Connect wallet automatically if app is trusted once
    (async () => {
      try {
        const language = await AsyncStorage.getItem("@language");
        i18n.locale = language || "de";
        await window.solana?.connect({ onlyIfTrusted: true });
      } catch (error) {
        console.debug(error);
      }
    })();
  }, []);

  return (
    <AppContainer
      customTheme={customTheme}
      config={config}
      queryClient={queryClient}
    />
  );
}
