import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserLicenseCollectibleEarnings = () => {
  const { client } = useContext(AxiosContext);

  return useMutation(
    (params = {}) =>
      client
        .get(`me/fan_transactions/`, {
          params: {
            response_type: "series",
            type: "deposit",
            deposit_type: "royalties_actual",
            num_records: 16,
            decimals: 2,
            ...(params.start && { service_date__gte: params.start }),
            ...(params.end && { service_date__lte: params.end }),
            ...(params.id && { license_id: params.id }),
          },
        })
        .then((resp) => resp.data),
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
