import React from "react";
import { useForm } from "react-hook-form";
import { FormControl, Icon, VStack } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal functions
import { translate } from "utils/helpers";
// internal hooks
import useReportComment from "features/general/comments/hooks/useReportComment";
// internal components
import { Modal } from "theme/feedback/modal.component";
import { TextArea } from "theme/forms/text-area.component";
import { AuthenticationContext } from "services/authentication.context";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { FormControlInput } from "theme/forms/form-control-input.component";

export const CommentDetailReport = ({ auctionID, commentID }) => {
  const [showModal, setShowModal] = React.useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { reportComment } = useReportComment(auctionID, commentID);

  const { setShowAuthModal, user } = React.useContext(AuthenticationContext);

  const onPress = (data) => {
    const updatedData = {
      subject: data.subject.trim(),
      body: data.body.trim(),
    };
    reportComment.mutate(updatedData);
  };

  React.useEffect(() => {
    if (reportComment.isSuccess) setShowModal(false);
  }, [reportComment.isSuccess]);

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      pressableChildren={
        <Icon
          size="xs"
          name="flag"
          color="primary.300"
          as={MaterialCommunityIcons}
          onPress={() => {
            if (!!user) {
              setShowModal(true);
            } else setShowAuthModal(true);
          }}
        />
      }
      modalStyles={{
        p: 4,
        _light: { bg: "light.100" },
        _dark: { bg: "dark.900" },
        width: ["90%", 500],
      }}
      modalChildren={
        <VStack space={5} px={5} pt={10}>
          <FormControlInput
            space={5}
            name="subject"
            maxLength={500}
            control={control}
            isRequired={false}
            placeholder="Title"
            label={translate("subject")}
            rules={{ required: true, maxLength: 500, pattern: /\S/ }}
          />
          <FormControl isInvalid={errors?.body}>
            <VStack space={5}>
              <FormControl.Label>{translate("reason")}</FormControl.Label>
              <TextArea
                name="body"
                maxLength={500}
                control={control}
                textAlignVertical="top"
                placeholder={translate("reason_placeholder")}
                rules={{ required: true, maxLength: 500, pattern: /\S/ }}
              />
              <FormControl.ErrorMessage>
                {translate("invalid_input")}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>
          <PrimaryButton
            nativeID="report-comment"
            text={translate("report")}
            onPress={handleSubmit(onPress)}
          />
        </VStack>
      }
    />
  );
};
