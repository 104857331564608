import React, { useEffect } from "react";
import { VStack, ScrollView, Text, HStack, IconButton } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { useForm } from "react-hook-form";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { Loading } from "common/components/loading/loading.component";
import { PageTitle } from "common/components/layout/page-title.component";
//internal hooks
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
import { useUpdateMusicianStatus } from "features/accounts/musicians/hooks/useUpdateMusicianStatus";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { useCountries } from "features/royalties/releases/hooks/useCountries";
// utility functions
import { translate, navigateBack, isWeb } from "utils/helpers";

const Item = ({ title, value }) => (
  <HStack space={2}>
    <Text flex={1} fontSize={["md", "lg"]}>
      {title}
    </Text>
    <Text
      flex={1}
      fontSize={["md", "lg"]}
      fontWeight="semibold"
      textAlign="right"
    >
      {value}
    </Text>
  </HStack>
);

const EditIcon = ({ onPress }) => (
  <IconButton
    p={1}
    icon={<MaterialIcons size={14} name="edit" color="white" />}
    borderWidth={1.5}
    borderColor="white"
    borderRadius={20}
    onPress={onPress}
  />
);

export const MusicianEditSummaryScreen = ({ navigation }) => {
  const { handleSubmit } = useForm();
  const { data: profile, isLoading: profileLoading } = useUserProfile();
  const { data: musician, isLoading: musicianLoading } = useUserMusician();
  const countries = useCountries();
  const { updateMusicianStatus } = useUpdateMusicianStatus(musician?.id);

  const country = countries.data?.find(
    (item) => item.value === profile?.country_of_residence
  );

  const isDraftApplication = musician?.status === `draft`;

  const ADDRESS = `${profile?.street_of_residence || ""} ${
    profile?.house_number_of_residence || ""
  }, ${profile?.postal_code_of_residence || ""}, ${
    profile?.city_of_residence || ""
  }, ${country?.label || ""}`;

  const onPress = () => {
    const data = { new: "under_review" };
    updateMusicianStatus.mutate(data);
  };

  useEffect(() => {
    if (updateMusicianStatus.isSuccess) {
      if (isWeb) {
        navigation.navigate("ReleaseTabs", { screen: "ReleaseList" });
      } else navigation.navigate("NativeReleaseNavigator");
    }
  }, [updateMusicianStatus.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("profile")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        profileProgress={{ type: "musician", activeIndex: 3 }}
      />
      <ScrollView showsVerticalScrollIndicator={false}>
        <PageTitle title={translate("summary_correct")} />
        {musicianLoading || profileLoading ? (
          <Loading />
        ) : (
          <VStack space="6">
            <VStack
              p="5"
              space="5"
              _light={{ bg: "light.100" }}
              _dark={{ bg: "dark.900" }}
            >
              <HStack mb="4" justifyContent="space-between" alignItems="center">
                <Text fontSize={["lg", "xl"]} bold>
                  {translate("basic_information")}
                </Text>
                {isDraftApplication && (
                  <EditIcon
                    onPress={() => {
                      navigation.navigate("Musicians", {
                        screen: "MusicianEditPersonalData",
                      });
                    }}
                  />
                )}
              </HStack>

              <Item title={translate("name")} value={profile?.full_name} />
              <Item title={translate("email")} value={profile?.email} />
              <Item title={translate("address")} value={ADDRESS} />
              <Item title={translate("alias")} value={musician?.alias} />
            </VStack>

            {isDraftApplication && (
              <PrimaryButton
                p="5"
                showBackButton
                text={translate("submit")}
                onPress={handleSubmit(onPress)}
              />
            )}
          </VStack>
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
