import React from "react";
import dayjs from "dayjs";
import { Linking } from "react-native";
import { Box, VStack, Image } from "native-base";
// utility functions
import { translate } from "utils/helpers";
// internal components
import { Modal } from "theme/feedback/modal.component";
import { NewButtonBody } from "theme/buttons/new-button-body.component";
import { HorizontalIconButton } from "theme/buttons/horizontal-icon-button.component";

export const AddToCalendarButton = ({
  startDate,
  endDate,
  location,
  eventName,
}) => {
  const generateGoogleCalendarEvent = () => {
    const start = dayjs(startDate).format("YYYYMMDDTHHmm");
    const end = dayjs(endDate).format("YYYYMMDDTHHmm");
    Linking.openURL(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventName}&location=${location}&dates=${start}/${end}`
    );
  };

  const generateICSFile = () => {
    const start = dayjs(startDate).format("YYYYMMDDTHHmmss");
    const end = dayjs(endDate).format("YYYYMMDDTHHmmss");
    const file = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//ZContent.net//Zap Calendar 1.0//EN\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nDTSTART:${start}\nDTEND:${end}\nSUMMARY:${eventName}\nLOCATION:${location}\nEND:VEVENT\nEND:VCALENDAR`;
    let blob = new Blob([file], { type: "text/calendar;charset=utf-8" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `${eventName}.ics`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      modalChildren={
        <Box borderRadius={"xl"} overflow={"hidden"} bg={"light.100"}>
          <VStack pt={16} pb={10} px={5} space={5}>
            <HorizontalIconButton
              iconName={"google"}
              text={translate("google_calendar")}
              onPress={generateGoogleCalendarEvent}
            />
            <HorizontalIconButton
              iconName={"apple"}
              text={translate("apple_calendar")}
              onPress={generateICSFile}
            />
          </VStack>
        </Box>
      }
      pressableChildren={
        <NewButtonBody
          text={translate("add_to_calendar")}
          fontSize={"sm"}
          fontWeight={"semibold"}
          rightComponent={
            <Image
              h={6}
              w={6}
              alt={"Calendar Symbol"}
              source={require("assets/images/icons/calendar-black.png")}
            />
          }
        />
      }
    />
  );
};
