import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTrack = (id, initialData = {}) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `track-${id}`,
    () => {
      console.debug(`💿 Track-${id}`);
      return client.get(`tracks/${id}/`).then((resp) => resp.data);
    },
    {
      initialData: initialData,
    }
  );
};
