import React from "react";
import { Text, Box, Toast, Icon } from "native-base";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
// internal
import { translate } from "utils/helpers";
import { PrimaryButton } from "theme/buttons/primary-button.component";

export const onCopy = async ({ text, onPress, toast }) => {
  const toastID = "copy_text";
  Clipboard.setStringAsync(text).then(() => {
    !Toast.isActive(toastID) &&
      Toast.show({
        id: toastID,
        placement: "top",
        duration: 5000,
        render: () => (
          <Box py={3} px={5} mx={5} bg={"light.200"} borderRadius={"xl"}>
            <Text>{toast || translate("data_copied")}</Text>
          </Box>
        ),
      });
    onPress && onPress();
  });
};

export const CopyTextButton = ({ text, onPress, ...props }) => {
  return (
    <PrimaryButton
      {...props}
      fontSize={"md"}
      text={translate("copy")}
      onPress={() => onCopy({ text, onPress })}
      rightComponent={
        <Icon
          size={4}
          as={Ionicons}
          color={"lightText"}
          name={"copy-outline"}
        />
      }
    />
  );
};
