import React, { useContext, useState } from "react";
import { Platform } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUpdateAuctionPromoImage = (auctionID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateAuctionPromoImage: useMutation(
      (image) => {
        let params = {
          base64: image.base64,
        };
        if (Platform.OS === "web") {
          params["type"] = image.uri.split(";")[0].split("/")[1];
        } else {
          const uri_split = image.uri.split(".");
          params["type"] = uri_split[uri_split.length - 1];
        }
        console.debug(image.uri.split("."));
        console.debug(params.type);
        return client
          .patch(`auctions/${auctionID}/promotional_image/`, params)
          .then((res) => res.data)
          .catch((error) => console.debug(error.response.data?.detail));
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`auction-${auctionID}`);
        },
      }
    ),
    error: error,
  };
};
