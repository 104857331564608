import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "services/firebase.context";

export const useComments = (auctionID) => {
  const [comments, setComments] = React.useState([]);
  const { firestore } = useContext(FirebaseContext);
  React.useEffect(() => {
    if (auctionID) {
      const cleanUp = onSnapshot(
        query(
          collection(firestore, `auctions/${auctionID}/comments`),
          orderBy("created_at", "desc")
        ),
        (snapshot) => {
          const commentsQuery = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          setComments(commentsQuery);
          console.debug(`📬 Comments | Auction ${auctionID}`);
        }
      );
      return () => cleanUp();
    }
  }, [auctionID]);
  return comments;
};
