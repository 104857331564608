import React, { memo, useState } from "react";
import {
  FlatList,
  HStack,
  VStack,
  Text,
  Divider,
  Image,
  Pressable,
  useMediaQuery,
} from "native-base";
import dayjs from "dayjs";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { TourEventCard } from "features/tickets/tours/components/tour-event-card.component";
// internal hooks
import { useTourAuctions } from "features/tickets/tours/hooks/useTourAuctions";
import { useTour } from "features/tickets/tours/hooks/useTour";
// helpers
import {
  translate,
  getLocalizedNumber,
  getMoreText,
  isWeb,
} from "utils/helpers";

const MoreText = memo(({ icon, alt, data, maxLength, showOne = false }) => {
  const [showAll, setShowAll] = useState(false);
  const moreText = getMoreText(data, maxLength, showOne);
  return (
    <HStack space={2} alignItems={"center"}>
      <Image size={5} source={icon} alt={alt} />
      <Pressable flex={1} onPress={() => setShowAll(!showAll)}>
        <HStack space={1} alignItems={"center"}>
          <Text
            fontWeight={500}
            numberOfLines={showAll ? undefined : 1}
            color={"darkText"}
          >
            {showAll ? data?.join(", ") : moreText?.text}
          </Text>
          {!!moreText?.moreCount && !showAll && (
            <Text
              color={"gray.400"}
              selectable={false}
            >{`+${moreText?.moreCount} more`}</Text>
          )}
        </HStack>
      </Pressable>
    </HStack>
  );
});

export const TourAuctionListScreen = ({ navigation, route }) => {
  const { id: tourID, initialData } = route.params;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const tour = useTour(tourID, initialData);
  const auctions = useTourAuctions({ tour_id: tourID }, 10);
  const data = auctions.data?.pages.map((page) => page.items).flat();

  const price = data?.[0]?.ticket_products?.[0]?.price_per_unit || 0;
  const locations = data
    ?.map((auction) => auction?.events?.map((e) => e.city))
    ?.flat();

  const dates = data
    ?.map((auction) =>
      auction?.events?.map((e) => dayjs(e.start_time).format("DD MMM YYYY"))
    )
    ?.flat();

  return (
    <ScreenFrame>
      <NavigationPanel goesBack={!isWeb} />
      <FlatList
        ListHeaderComponent={
          <VStack>
            <VStack flex={1} space={5}>
              <VStack px={isDesktop ? 24 : 0}>
                <Image
                  alt={"Tour Cover"}
                  resizeMode={"cover"}
                  height={[250, 300, 350]}
                  source={{ uri: data?.[0]?.image_with_url?.original?.url }}
                />
              </VStack>
              <VStack mt={5} space={2} px={isDesktop ? 24 : 5}>
                <Text mb={5} fontSize={["2xl", "4xl"]} bold>
                  {tour.data?.title}
                </Text>
                <HStack space={2} alignItems="center">
                  <Image
                    size={5}
                    alt={"coin-black"}
                    source={require("assets/images/icons/coin-black.png")}
                  />
                  <HStack space={1} alignItems={"center"}>
                    <Text color={"darkText"} fontWeight={500}>
                      {translate("from")}
                    </Text>
                    <Text color={"secondary.300"} fontWeight={500}>
                      {getLocalizedNumber(price)} €
                    </Text>
                  </HStack>
                </HStack>
                <MoreText
                  alt={"calendar-black"}
                  icon={require("assets/images/icons/calendar-black.png")}
                  data={dates}
                  maxLength={32}
                />
                <MoreText
                  alt={"location-black"}
                  icon={require("assets/images/icons/location-black.png")}
                  data={locations}
                  maxLength={32}
                />
              </VStack>
              <Divider my={4} bg={"gray.300"} />
              <Text mb={8} fontSize={"2xl"} px={isDesktop ? 24 : 5} bold>
                {translate("event_calendar")}
              </Text>
            </VStack>
          </VStack>
        }
        onEndReachedThreshold={0.3}
        onEndReached={() =>
          auctions.hasNextPage && !auctions.isFetching
            ? auctions.fetchNextPage()
            : null
        }
        data={data}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <VStack key={index} px={isDesktop ? 24 : 5}>
            <TourEventCard auction={item} navigation={navigation} />
          </VStack>
        )}
        ListFooterComponent={
          auctions.isFetching && (
            <VStack alignItems={"center"}>
              <CenteredSpinner />
            </VStack>
          )
        }
      />
    </ScreenFrame>
  );
};
