import React from "react";
import { VStack } from "native-base";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
// internal functions
import { navigateBack, translate } from "utils/helpers";
import { NewButton } from "theme/buttons/new-button.component";

export const HistoryListScreen = ({ navigation }) => (
  <ScreenFrame isAuthenticationRequired>
    <NavigationHeader
      title={translate("transactions")}
      headerLeft={{
        iconName: "arrow-left",
        onPress: () => navigateBack(navigation),
      }}
    />
    <VStack>
      <NewButton
        textStyles={{
          fontSize: ["md", "lg", "xl"],
          fontWeight: "semibold",
        }}
        text={translate("orders_history")}
        onPress={() =>
          navigation.navigate("Orders", {
            screen: "OrderList",
          })
        }
      />

      <NewButton
        textStyles={{
          fontSize: ["md", "lg", "xl"],
          fontWeight: "semibold",
        }}
        text={translate("payouts_history")}
        onPress={() =>
          navigation.navigate("Payouts", {
            screen: "PayoutList",
          })
        }
      />
    </VStack>
  </ScreenFrame>
);
