import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import { Platform } from "react-native";

export default function useUpdateReleaseImage() {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateReleaseImage: useMutation(
      ({ image, id }) => {
        let params = {
          base64: image.base64,
        };
        if (Platform.OS === "web") {
          params["type"] = image.uri.split(";")[0].split("/")[1];
        } else {
          const uri_split = image.uri.split(".");
          params["type"] = uri_split[uri_split.length - 1];
        }
        console.debug(image.uri.split("."));
        console.debug(params.type);
        return client
          .patch(`releases/${id}/image/`, params)
          .then((res) => res.data);
      },
      {
        onSuccess: () => queryClient.invalidateQueries("releases"),
      }
    ),
    error: error,
  };
}
