import React, { useState, useEffect } from "react";
import {
  Box,
  FlatList,
  HStack,
  VStack,
  Text,
  useMediaQuery,
  Pressable,
  Image,
} from "native-base";
// internal functions
import { getWindowWidth, translate } from "utils/helpers";
// internal hooks
import { useUserCollectibles } from "features/general/collectibles/hooks/useUserCollectibles";
// internal components
import { ListEmptyComponent } from "common/components/layout/list-empty.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { EventCollectibleListCard } from "features/general/collectibles/components/event-collectible-list-card.component";

export const FilterButton = ({ text, selected, onPress }) => (
  <Pressable
    py={3}
    px={6}
    onPress={onPress}
    borderRadius={"full"}
    bg={selected ? "secondary.300" : "gray.100"}
  >
    <Text fontSize={"sm"}>{translate(text)}</Text>
  </Pressable>
);

export const Header = ({
  selectedFilter,
  setSelectedFilter,
  gridView,
  setGridView,
}) => (
  <VStack space={4} pb={4} pt={{ lg: 5 }} px={{ base: 5, lg: 24 }}>
    <HStack alignItems={"center"} justifyContent={"space-between"}>
      <Text fontSize={"2xl"} fontWeight={600}>
        Events
      </Text>
      <HStack
        space={5}
        alignItems={"center"}
        _ios={{ flex: 1, justifyContent: "flex-end" }}
      >
        <Pressable onPress={() => setGridView(false)}>
          <Image
            size={7}
            alt={"menu"}
            opacity={!gridView ? 1 : 0.3}
            source={require("assets/images/icons/navigation/menu.png")}
          />
        </Pressable>
        <Pressable onPress={() => setGridView(true)}>
          <Image
            size={6}
            alt={"menu"}
            opacity={gridView ? 1 : 0.3}
            source={require("assets/images/icons/navigation/boxes.png")}
          />
        </Pressable>
      </HStack>
    </HStack>

    <HStack space={3}>
      {["upcoming", "attended", "all"].map((filter) => (
        <FilterButton
          key={filter}
          text={filter}
          selected={selectedFilter === filter}
          onPress={() => setSelectedFilter(filter)}
        />
      ))}
    </HStack>
  </VStack>
);

export const EventCollectibleList = ({ userID }) => {
  const windowWidth = getWindowWidth();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const [gridView, setGridView] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("upcoming");

  const getQueryParams = (filter) => {
    let params = {
      by_product: true,
      category: "ticket",
    };
    if (filter === "attended") {
      params.event_start_time__lt = new Date().toISOString();
    } else if (filter === "upcoming") {
      params.event_start_time__gte = new Date().toISOString();
    }

    return params;
  };

  const queryParams = getQueryParams(selectedFilter);
  const collectibles = useUserCollectibles({
    params: queryParams,
    key: "events-collectibles",
    filter: selectedFilter,
  });

  useEffect(() => {
    // Now refetch the data
    collectibles.refetch();
  }, [selectedFilter]);

  const cardWidth = windowWidth / (isDesktop ? 3.25 : 1.1);

  return (
    <FlatList
      key={
        !gridView
          ? "list-view"
          : isDesktop
          ? "desktop-collection"
          : "collection"
      }
      numColumns={!gridView ? 1 : isDesktop ? 3 : 1}
      columnWrapperStyle={
        gridView && isDesktop ? { justifyContent: "center" } : undefined
      }
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={collectibles?.data?.pages.map((page) => page.items).flat()}
      onEndReached={() =>
        collectibles.hasNextPage && !collectibles.isFetching
          ? collectibles.fetchNextPage()
          : null
      }
      ListHeaderComponent={
        <Header
          gridView={gridView}
          setGridView={setGridView}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      }
      onEndReachedThreshold={0.3}
      renderItem={({ item, index }) => (
        <Box
          width={gridView && !isDesktop ? "100%" : undefined}
          alignItems={gridView && !isDesktop ? "center" : undefined}
        >
          <EventCollectibleListCard
            key={index}
            event={item}
            width={cardWidth}
            gridView={gridView}
            height={cardWidth * 1.2}
          />
        </Box>
      )}
      ListEmptyComponent={
        collectibles.isFetched ? (
          <ListEmptyComponent height={250} text={translate("no_results")} />
        ) : (
          <Box justifyContent={"center"} h={250}>
            <CenteredSpinner />
          </Box>
        )
      }
      keyExtractor={(item, index) => index.toString()}
      ListFooterComponent={
        <Box m={2}>
          {collectibles.isFetching && !collectibles.isLoading && (
            <CenteredSpinner />
          )}
        </Box>
      }
    />
  );
};
