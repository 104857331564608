export const appRoutesConfig = {
  screens: {
    BottomNavigator: {
      initialRouteName: "Marketplace",
      screens: {
        Marketplace: "/home",
        ReleaseTabs: {
          initialRouteName: "ReleaseList",
          screens: {
            ReleaseList: {
              screens: {
                AllReleases: "releases",
                ReleaseCreateInfo: "releases/create",
              },
            },
            ReleaseDeals: {
              screens: {
                AllDeals: "deals",
                ExistingReleaseList: "releases/existing",
              },
            },
            ReleaseSplits: "splits",
            ReleaseInsight: "insight",
          },
        },
        Shares: "/shares",
        Events: "/events",
        Lounge: {
          path: "lounge/:defaultTab?/",
        },
        GroupChannelList: "messaging/group-channels",
      },
    },
    // Accounts
    Account: {
      screens: {
        AccountSettings: "account/settings/",
        LanguageSelection: "account/language/",
        LegalList: "account/legal/",
        HistoryList: "account/history/",
      },
    },
    Users: {
      screens: {
        UserDetail: "account/security/",
        UserBadges: "users/:userID/badges",
        UserEditTermsOfAgreement: "account/terms/",
        Login: "login",
        CodeVerification: {
          path: "login/verify/:is_coupon?",
          parse: { is_coupon: (is_coupon) => "" },
          stringify: { is_coupon: (is_coupon) => "" },
        },
        AdminLogin: "admin/login/",
        CouponDetail: "airdrops/:code/",
        CodeVerification: {
          path: "login/verify/:is_coupon?",
          parse: { is_coupon: (is_coupon) => "" },
          stringify: { is_coupon: (is_coupon) => "" },
        },
      },
    },
    Profiles: {
      path: "profiles",
      screens: {
        ProfileEditPersonalData: {
          path: ":id/edit-private/:goToProfileSwitch?/",
          parse: { goToProfileSwitch: (goToProfileSwitch) => "" },
          stringify: { goToProfileSwitch: (goToProfileSwitch) => "" },
        },
        ProfileEdit: ":id/edit-public",
        ProfileDetail: ":profileID",
      },
    },
    Musicians: {
      screens: {
        AddMusicianToTrack:
          "releases/:releaseID/tracks/:trackID/musicians/create",
        MusicianCreate: "musicians/create",
        MusicianList: "musicians",
        MusicianDetail: {
          path: "musicians/:id/:initialData?/",
          parse: { initialData: (initialData) => "" },
          stringify: { initialData: (initialData) => "" },
        },
        MusicianEditSummary: "musicians/edit-summary",
        MusicianEditPayouts: "musicians/payouts",
        MusicianEditProfile: "musicians/edit-public",
        MusicianEditPersonalData: "musicians/edit-private",
      },
    },
    // Royalties
    Releases: {
      screens: {
        ReleaseDetail: {
          path: "releases/:id/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEdit: {
          path: "releases/:id/edit/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEditCoverArt: {
          path: "releases/:id/edit-cover/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEditTracks: {
          path: "releases/:id/tracks/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEditUserTracks: {
          path: "releases/:id/user-tracks/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEditDistribution: {
          path: "releases/:id/edit-distribution/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
        ReleaseEditSummary: {
          path: "releases/:id/edit-summary/:releasePartial?",
          parse: { releasePartial: (releasePartial) => "" },
          stringify: { releasePartial: (releasePartial) => "" },
        },
      },
    },
    Tracks: {
      screens: {
        TrackEdit: {
          path: "releases/:releaseID/tracks/:trackID/edit/:releasePartials?/:trackPartial?",
          parse: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
          stringify: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
        },
        TrackEditAudio: {
          path: "releases/:releaseID/tracks/:trackID/edit-audio/:releasePartials?/:trackPartial?",
          parse: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
          stringify: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
        },
        TrackEditContent: {
          path: "releases/:releaseID/tracks/:trackID/edit-content/:releasePartials?/:trackPartial?",
          parse: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
          stringify: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
        },
        TrackEditArtists: {
          path: "releases/:releaseID/tracks/:trackID/edit-artists/:releasePartials?/:trackPartial?",
          parse: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
          stringify: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
        },
        TrackEditShare: {
          path: "releases/:releaseID/tracks/:trackID/edit-share/:releasePartials?/:trackPartial?",
          parse: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
          stringify: {
            trackPartial: (trackPartial) => "",
            releasePartials: (releasePartials) => "",
          },
        },
      },
    },
    Contributors: {
      screens: {
        AddContributorToTrack: {
          path: "releases/:releaseID/tracks/:trackID/contributors/:contributorID?/:trackPartials?",
          parse: { trackPartials: (trackPartials) => "" },
          stringify: { trackPartials: (trackPartials) => "" },
        },
      },
    },
    MasterRights: {
      screens: {
        CreateTrackMasterRight: {
          path: "releases/:releaseID/tracks/:trackID/master-rights/:masterRightID?/:trackPartials?",
          parse: { trackPartials: (trackPartials) => "" },
          stringify: { trackPartials: (trackPartials) => "" },
        },
      },
    },
    LicenseCollectibles: {
      path: "license-collectibles",
      screens: {
        CollectiblesList: "/",
        LicenseCollectibleBenefitsList: "/benefits/",
        LicenseCollectibleDetail: {
          path: ":id/:initialData?/:scrollToBenefits?",
          parse: {
            initialData: (initialData) => "",
            scrollToBenefits: (scrollToBenefits) => "",
          },
          stringify: {
            initialData: (initialData) => "",
            scrollToBenefits: (scrollToBenefits) => "",
          },
        },
        LicenseCollectibleDetailAnalytics: {
          path: ":licenseCollectibleID/dashboard",
        },
      },
    },
    // General
    Auctions: {
      screens: {
        TourAuctionList: {
          path: "tours/:id/deals/:initialData?/",
          parse: { initialData: (initialData) => "" },
          stringify: { initialData: (initialData) => "" },
        },
        AuctionList: "deals/list",
        AuctionCalculator: "deals/calculator",
        AuctionInfo: {
          path: "deals/:id/info/:initialData?/",
          parse: { initialData: (initialData) => "" },
          stringify: { initialData: (initialData) => "" },
        },
        AuctionDetail: {
          path: "deals/:id/:initialData?/",
          parse: { initialData: (initialData) => "" },
          stringify: { initialData: (initialData) => "" },
        },
        AuctionEditInfo: {
          path: "deals/:id/edit-info/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionEditFinancials: {
          path: "deals/:id/edit-financials/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionEditBenefits: {
          path: "deals/:id/edit-benefits/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionEditCard: {
          path: "deals/:id/edit-card/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionEditPromotional: {
          path: "deals/:id/edit-promotional/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionEditSummary: {
          path: "deals/:id/edit-summary/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        OrderCreate: {
          path: "deals/:auctionID/orders/create/:auctionInitialData?/:basket?/",
          parse: {
            auctionInitialData: (auctionInitialData) => "",
            basket: (basket) => "",
          },
          stringify: {
            auctionInitialData: (auctionInitialData) => "",
            basket: (basket) => "",
          },
        },
        LicenseProductOrderCreate: {
          path: "deals/:auctionID/license-orders/create/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
        AuctionOffer: {
          path: "deals/:id/offer/:auctionPartials?/",
          parse: { auctionPartials: (auctionPartials) => "" },
          stringify: { auctionPartials: (auctionPartials) => "" },
        },
      },
    },
    Collectibles: {
      screens: {
        AuctionCollectibleList: "deals/:auctionID/collectibles",
      },
    },
    Orders: {
      path: "orders",
      screens: {
        OrderList: "/",
        OrderDetail: ":orderID/",
      },
    },
    Posts: {
      screens: {
        PostList: "deals/:auctionID/posts/",
      },
    },
    Payouts: {
      path: "payouts",
      screens: {
        PayoutList: "/",
      },
    },
    PaymentMethods: {
      path: "payment-methods",
      initialRouteName: "list",
      screens: {
        PaymentMethodList: "/",
        PaymentMethodPayouts: "payouts",
        PaymentMethodSetupIntentSofort: "setup-intent-sofort",
        PaymentMethodSetupIntentCard: "setup-intent-card",
      },
    },
    Transfers: {
      path: "transfers",
      screens: {
        TransferDetail: ":transferID",
      },
    },
    TicketCollectibles: {
      path: "ticket-collectibles",
      screens: {
        TicketCollectibleDetail: {
          path: ":id/:initialData?/",
          parse: {
            initialData: (initialData) => "",
          },
          stringify: {
            initialData: (initialData) => "",
          },
        },
        TicketCollectibleDetailTransferCreate: {
          path: ":collectibleID/transfer/:collectibleInitialData?/",
          parse: { collectibleInitialData: (collectibleInitialData) => "" },
          stringify: { collectibleInitialData: (collectibleInitialData) => "" },
        },
        TicketCollectibleDetailMint: {
          path: ":collectibleID/mint/:collectibleInitialData?/",
          parse: { collectibleInitialData: (collectibleInitialData) => "" },
          stringify: { collectibleInitialData: (collectibleInitialData) => "" },
        },
        TicketCollectibleDetailValidateResponse:
          ":ticketCollectibleID/users/:userID/",
        TicketCollectibleDetailValidate: "/validate",
      },
    },
    Collectibles: {
      path: "basic-collectibles",
      screens: {
        CollectibleDetailBasic: {
          path: ":id/:initialData?/:scrollToBenefits?",
          parse: {
            initialData: (initialData) => "",
            scrollToBenefits: (scrollToBenefits) => "",
          },
          stringify: {
            initialData: (initialData) => "",
            scrollToBenefits: (scrollToBenefits) => "",
          },
        },
      },
    },
  },
};
