import React from "react";
import { HStack, VStack } from "native-base";

export const AppFrame = ({ children }) => {
  return (
    <HStack flex={1} bg={"lightBackground"} justifyContent={"center"}>
      <VStack minWidth={[0, 0, 768, 992, 1280]} flex={1}>
        {children}
      </VStack>
    </HStack>
  );
};
