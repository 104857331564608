import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteReleaseTrack = (release) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteReleaseTrack: useMutation(
      (track) => client.delete(`releases/${release.id}/tracks/${track.id}/`),
      {
        enabled: !!release?.id,
        onSuccess: () => {
          queryClient.invalidateQueries("releases");
          queryClient.invalidateQueries(`release-${release.id}`);
          console.debug(`invalidated query release-${release.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useDeleteReleaseTrack;
