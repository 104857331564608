import React from "react";
import { Animated } from "react-native";
import { Entypo } from "@expo/vector-icons";
import { Box, FlatList, Icon, Image, Pressable } from "native-base";
import { useIsFocused, useNavigation } from "@react-navigation/native";

export const MusicianListAnimated = ({ musicians, index, setIndex, left }) => {
  const navigation = useNavigation();
  const scrollXIndex = React.useRef(new Animated.Value(0)).current;
  const scrollXAnimated = React.useRef(new Animated.Value(0)).current;
  const isFocused = useIsFocused();

  const VISIBLE_ITEMS = 3;

  const setActiveIndex = React.useCallback((activeIndex) => {
    scrollXIndex.setValue(activeIndex);
    setIndex(activeIndex);
  }, []);

  React.useEffect(() => {
    Animated.spring(scrollXAnimated, {
      toValue: scrollXIndex,
      useNativeDriver: false,
    }).start();
    //swiping cards automatically after 2 seconds if screen is in focus
    if (isFocused) {
      setTimeout(() => {
        if (index === musicians?.length - 1) {
          setActiveIndex(0);
        } else setActiveIndex(index + 1);
      }, 2000);
    }
  });

  return (
    <FlatList
      listKey="Musicians Cards"
      justifyContent="center"
      alignItems="center"
      width={"100%"}
      height={100}
      left={left || ["-15%", "-10%", "-6%"]}
      scrollEnabled={false}
      removeClippedSubviews={false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={musicians}
      CellRendererComponent={({ item, index, children, style, ...props }) => {
        const newStyle = [
          style,
          {
            zIndex: musicians.length - index,
          },
        ];
        return (
          <Box style={newStyle} index={index} {...props}>
            {children}
          </Box>
        );
      }}
      renderItem={({ item, index: i }) => {
        const inputRange = [i - 1, i, i + 1];
        const translateX = scrollXAnimated.interpolate({
          inputRange,
          outputRange: [20, 0, -40],
        });
        const scale = scrollXAnimated.interpolate({
          inputRange,
          outputRange: [0.8, 1, 1.3],
        });
        const opacity = scrollXAnimated.interpolate({
          inputRange,
          outputRange: [1 - 1 / VISIBLE_ITEMS, 1, 0],
        });
        return (
          <Animated.View
            style={{
              position: "absolute",
              opacity,
              transform: [
                {
                  translateX,
                },
                { scale },
              ],
            }}
          >
            {item?.image_with_url?.sm?.url ? (
              <Pressable
                zIndex={100}
                onPress={() => {
                  navigation.navigate("Musicians", {
                    screen: "MusicianDetail",
                    params: {
                      id: musicians[index]?.id,
                      initialData: musicians[index],
                    },
                  });
                }}
              >
                <Image
                  borderRadius={"xl"}
                  alt="musician"
                  height={[120, 150]}
                  width={[120, 150]}
                  source={{ uri: item?.image_with_url?.sm?.url }}
                />
              </Pressable>
            ) : (
              <Box bg={"dark.900"} p={10} rounded={"xl"}>
                <Icon
                  size={10}
                  as={Entypo}
                  name={item?.icon}
                  color={"secondary.300"}
                />
              </Box>
            )}
          </Animated.View>
        );
      }}
    />
  );
};
