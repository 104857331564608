import React from "react";
import {
  VStack,
  HStack,
  Progress,
  Hidden,
  Text,
  Box,
  Icon,
  CheckIcon,
  Pressable,
} from "native-base";
import { Platform } from "react-native";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { useRoute, useNavigation } from "@react-navigation/native";
// utility functions
import { translate } from "utils/helpers";

const options = {
  release: [
    { title: "information", icon: "information", screen: "ReleaseEdit" },
    { title: "cover", icon: "image", screen: "ReleaseEditCoverArt" },
    { title: "track_list", icon: "album", screen: "ReleaseEditTracks" },
    {
      title: "distribution_details",
      icon: "send",
      screen: "ReleaseEditDistribution",
    },
    { title: "summary", icon: "view-list", screen: "ReleaseEditSummary" },
  ],
  deal: [
    { title: "information", icon: "information", screen: "AuctionEditInfo" },
    { title: "financial", icon: "finance", screen: "AuctionEditFinancials" },
    { title: "benefits", icon: "hand-coin", screen: "AuctionEditBenefits" },
    { title: "cards", icon: "cards", screen: "AuctionEditCard" },
    { title: "promotion", icon: "image", screen: "AuctionEditPromotional" },
    { title: "summary", icon: "view-list", screen: "AuctionEditSummary" },
  ],
  musician: [
    {
      title: "information",
      icon: "information",
      screen: "MusicianEditPersonalData",
    },
    {
      title: "profile",
      icon: "account",
      screen: "MusicianEditProfile",
    },
    {
      title: "summary",
      icon: "view-list",
      screen: "MusicianEditSummary",
    },
  ],
};

const ProgressBar = ({ option, active, completed = false }) => {
  const { params } = useRoute();
  const { navigate } = useNavigation();
  const dark = active ? "lightText" : completed ? "primary.600" : "light.800";
  const light = active ? "darkText" : completed ? "primary.600" : "light.800";

  return (
    <Pressable
      flex={1}
      disabled={!completed && !active}
      onPress={() => navigate(option.screen, params)}
    >
      <VStack space="1">
        <Box alignItems="center" justifyContent="center">
          <Hidden till="lg">
            <Text
              numberOfLines={1}
              fontWeight="semibold"
              fontSize={["md", "lg"]}
              _dark={{ color: dark }}
              _light={{ color: light }}
            >
              {translate(option.title)}
            </Text>
          </Hidden>
          <Hidden from="lg">
            <Icon
              as={MaterialCommunityIcons}
              name={option.icon}
              size="6"
              _dark={{ color: dark }}
              _light={{ color: light }}
            />
          </Hidden>
        </Box>
        <Box position="relative">
          <Progress
            h="6"
            rounded="0"
            value={active || completed ? 100 : 0}
            _filledTrack={{
              rounded: 0,
              _dark: { bg: active ? "lightBackground" : "primary.600" },
              _light: { bg: active ? "darkBackground" : "primary.600" },
            }}
          />
          <Box
            h="full"
            w="full"
            position="absolute"
            alignItems="center"
            justifyContent="center"
          >
            {active && (
              <Icon
                as={Feather}
                name="edit-3"
                size="md"
                _dark={{ color: active ? "darkText" : "primary.600" }}
                _light={{ color: active ? "lightText" : "primary.600" }}
              />
            )}
            {completed && <CheckIcon size="5" color="white" />}
          </Box>
        </Box>
      </VStack>
    </Pressable>
  );
};

export const ProfileProgress = ({ type, activeIndex, bg = "dark.900" }) => {
  return (
    <HStack
      bg={bg}
      space="1"
      px="5"
      pt={{ base: 0, lg: 5 }}
      pb={{ base: 5, lg: 7 }}
    >
      {options[type].map((option, idx) => (
        <ProgressBar
          key={option.title}
          option={option}
          active={activeIndex === idx}
          completed={idx < activeIndex}
        />
      ))}
    </HStack>
  );
};
