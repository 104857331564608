import React, { useEffect } from "react";
import { Box, Text, VStack, FlatList, useMediaQuery } from "native-base";
// internal hooks
import { useUserTracks } from "features/royalties/tracks/hooks/useUserTracks";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
// internal components
import { ModalFrame } from "navigation/components/modal-frame.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ExistingTrackCard } from "features/royalties/releases/components/existing-track-card.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";

export const ReleaseEditUserTracksScreen = ({ navigation, route }) => {
  const { id: releaseID, releasePartial = {} } = route.params;
  const { data: release } = useRelease(releaseID, releasePartial);
  const tracks = useUserTracks("", 16);
  const [isDesktop] = useMediaQuery({ minWidth: 1280 });
  const isFirstRouteInParent = useIsFirstRouteInParent();

  useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.navigate("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        overflow={"hidden"}
        borderTopRadius={20}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
      >
        <Box mt="3" />
        <VStack p="5" maxH={500}>
          <PageTitle pt="0" title={translate("select_existing_track")} />

          <FlatList
            key={isDesktop ? "horizontal-collection" : "vertical-collection"}
            numColumns={isDesktop ? 2 : 1}
            showsVerticalScrollIndicator={false}
            data={tracks.data?.pages?.map((page) => page.items).flat()}
            renderItem={({ item, index }) => (
              <ExistingTrackCard
                key={item.id}
                index={index + 1}
                track={item}
                release={release}
              />
            )}
            ListEmptyComponent={
              <Text flex={1} mt={10} fontSize={["md", "lg"]} textAlign="center">
                {translate("no_tracks")}
              </Text>
            }
            onEndReachedThreshold={0.3}
            onEndReached={() =>
              tracks.hasNextPage && !tracks.isFetching
                ? tracks.fetchNextPage()
                : null
            }
          />
          <Box mt="5">
            <PrimaryButton
              text={translate("done")}
              onPress={() => navigateBack(navigation)}
            />
          </Box>
        </VStack>
      </VStack>
    </ModalFrame>
  );
};
