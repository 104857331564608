import React from "react";
import { HStack, Skeleton, VStack, ZStack } from "native-base";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";

export const PostListSkeleton = () => {
  return (
    <HStack w={"100%"} h={280} overflow={"hidden"}>
      {Array(3)
        .fill("")
        .map((_unique, i) => (
          <HStack mr={5} key={i} rounded={"xl"} flexDirection={"row"} w={300}>
            <ZStack h={"100%"} w={"100%"} flex={1}>
              <Skeleton
                h={"100%"}
                w={"100%"}
                startColor={"skeleton.dark"}
                rounded={"xl"}
                overflow={"hidden"}
              />
              <VStack w={"100%"} h={"100%"} p={3} space={5}>
                <HStack w={"50%"} space={2} alignItems="center">
                  <Skeleton
                    startColor="skeleton.light"
                    h={46}
                    w={46}
                    rounded={"full"}
                    overflow={"hidden"}
                  />

                  <SkeletonTextVertical
                    numberOfLines={2}
                    color={"skeleton.light"}
                  />
                </HStack>
                <SkeletonTextVertical
                  numberOfLines={2}
                  color={"skeleton.light"}
                />
                <Skeleton
                  h={"60%"}
                  startColor={"skeleton.light"}
                  rounded={15}
                  overflow={"hidden"}
                />
              </VStack>
            </ZStack>
          </HStack>
        ))}
    </HStack>
  );
};
