import React, { useEffect, useState } from "react";
import { ScrollView, VStack, Flex, Text } from "native-base";
// hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateReleaseImage from "features/royalties/releases/hooks/useUpdateReleaseImage";
// components
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { ImagePicker } from "features/royalties/releases/components/image-picker.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseEditCoverArtScreen = ({ navigation, route }) => {
  const { id: releaseId, releasePartial = {} } = route.params;
  const { data: release, isFetched } = useRelease(releaseId, releasePartial);
  const { updateReleaseImage } = useUpdateReleaseImage();
  const releaseImage = release?.image_with_url?.sm?.url;

  const [image, setImage] = useState({ uri: releaseImage, validate: false });

  const navigateForward = () => {
    navigation.navigate("Releases", {
      screen: "ReleaseEditTracks",
      params: { id: release.id, releasePartial: release },
    });
  };

  const uploadImage = async () => {
    const updatedRelease = await updateReleaseImage.mutateAsync({
      image,
      id: release.id,
    });
    setImage({
      uri: updatedRelease?.image_with_url?.sm?.url,
      validate: false,
    });
  };

  const onPress = async () => {
    if (!image.uri) {
      setImage({ ...image, validate: true });
    } else if (image.uri !== releaseImage) {
      await uploadImage();
      navigateForward();
    } else {
      navigateForward();
    }
  };

  useEffect(() => {
    if (updateReleaseImage.error) setImage({ ...image, uri: releaseImage });
  }, [updateReleaseImage.error]);

  useEffect(() => {
    setImage({ ...image, uri: releaseImage });
  }, [isFetched]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("release")}
        headerLeft={{
          iconName: "close",
          onPress: () => {
            navigation.navigate("Releases", {
              screen: "ReleaseEdit",
              params: { id: release?.id, releasePartial: release },
            });
          },
        }}
        profileProgress={{ type: "release", activeIndex: 1 }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <PageTitle
          nativeID={"save-release-cover-art"}
          isDisabled={updateReleaseImage.isLoading}
          title={translate("cover_art_title")}
          helpText={translate("cover_art_help_text")}
        />
        {isFetched ? (
          <VStack px="5" space="10">
            <Flex
              my={{ lg: "16" }}
              direction={{ lg: "row" }}
              alignItems={{ lg: "center" }}
              justifyContent="center"
            >
              <VStack p={{ base: "10", lg: "16" }}>
                <ImagePicker
                  image={image}
                  setImage={setImage}
                  isDisabled={release?.is_delivered}
                />
              </VStack>
              <VStack space="5" pl={{ lg: "16" }} pt={{ base: "10", lg: "0" }}>
                <VStack space="2">
                  {[
                    "cover_art_format",
                    "cover_art_color_spectrum",
                    "cover_art_size",
                    "cover_art_square",
                  ].map((item) => (
                    <Text key={item} fontSize={["md", "lg"]}>
                      • {translate(item)}
                    </Text>
                  ))}
                </VStack>
              </VStack>
            </Flex>
            <PrimaryButton
              pb={[5, 0]}
              showNextIcon
              showBackButton
              disabled={updateReleaseImage.isLoading}
              isLoading={updateReleaseImage.isLoading}
              text={translate("next")}
              onPress={onPress}
            />
          </VStack>
        ) : (
          <Loading />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
