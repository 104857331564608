import React from "react";
import { Pressable, Text } from "native-base";

interface ViewAllButtonProps {
  onPress: () => void;
  children?: string;
  disabled?: boolean;
  fontSize?: "xs" | "sm" | "md" | "lg" | "xl" | Array<string> | number;
}

export const ViewAllButton = ({
  onPress,
  children = "View All",
  disabled = false,
  fontSize = "md",
  ...props
}: ViewAllButtonProps) => (
  <Pressable
    onPress={onPress}
    disabled={disabled}
    {...props}
    alignSelf="center"
  >
    <Text fontSize={fontSize}>{children}</Text>
  </Pressable>
);
