import React from "react";
import { HStack, Skeleton, VStack } from "native-base";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";
import { SkeletonListHorizontal } from "theme/skeletons/skeleton.list-horizontal";
import { SkeletonAuctionCard } from "features/general/auctions/skeletons/auction-list-card.skeleton";

export const MusicianDetailSekeleton = () => (
  <VStack
    mt={[0, 0, 16]}
    w="100%"
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Skeleton
      borderRadius={[0, 0, 15]}
      overflow="hidden"
      startColor={"skeleton.dark"}
      h={96}
      w={"100%"}
    />
    <Skeleton h={"5%"} w={"40%"} startColor={"skeleton.light"} mt={-32} />
    <Skeleton h={"2%"} w={"30%"} startColor={"skeleton.light"} m={2} />
    <HStack h={20} w={"40%"} justifyContent={"center"}>
      {Array(3)
        .fill("")
        .map((_unique, i) => (
          <Skeleton
            key={i}
            size={10}
            rounded={"full"}
            overflow={"hidden"}
            startColor={"skeleton.light"}
            mx={2}
          />
        ))}
    </HStack>
    <SkeletonTextVertical numberOfLines={5} p={5} mb={10} />
    <SkeletonAuctionCard isCompact m={2} />
    <SkeletonListHorizontal numberOfItems={3} />
    <SkeletonListHorizontal numberOfItems={7} itemWidth={20} h={20} my={2} />
  </VStack>
);
