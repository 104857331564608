import React, { forwardRef } from "react";
import { useTheme } from "native-base";
import RNSwiper from "react-native-web-swiper";

export const Swiper = forwardRef(({ children, ...props }, ref) => {
  const { colors } = useTheme();
  return (
    <RNSwiper
      ref={ref}
      controlsProps={{
        dotsTouchable: true,
        dotActiveStyle: {
          backgroundColor: colors?.primary[800],
        },
        prevPos: false,
        nextPos: false,
      }}
      {...props}
    >
      {children}
    </RNSwiper>
  );
});
