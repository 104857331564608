import React from "react";
import { Dimensions } from "react-native";
import { ScrollView, Text } from "native-base";

export const TermsOfUse = () => {
  const windowHeight = Dimensions.get("window").height;
  return (
    <ScrollView
      mx={4}
      px={4}
      flex={1}
      h={windowHeight * 0.8}
      showsVerticalScrollIndicator
    >
      <Text fontWeight={"semibold"} fontSize={["xs", "sm"]}>
        Nutzungsbedingungen{"\n"}Nutzungsbedingungen für das Portal biddz der
        biddz GmbH{"\n"}
        {"\n"}1. Geltungsbereich und Allgemeines{"\n"}(1) Diese
        Nutzungsbedingungen regeln die Nutzung der Webseite https://app.biddz.io
        („biddz Portal“). Betreiber der Plattform ist die{"\n"}
        {"\n"}biddz GmbH{"\n"}Straße der Pariser Kommune 12-16{"\n"}10243 Berlin
        {"\n"}Deutschland{"\n"}
        {"\n"}Geschäftsführer: Dr. Alexander Franck, Michael Höweler{"\n"}
        Wirtschafts-ID: 37/229/50599{"\n"}Register: Handelsregister{"\n"}
        Registernummer: HRB 231831 B{"\n"}Registergericht: Amtsgericht
        Charlottenburg{"\n"}(„biddz“).{"\n"}
        {"\n"}Die Nutzungsbedingungen gelten unabhängig davon, ob der Nutzer des
        biddz Portals („Nutzer“) Verbraucher oder Unternehmer ist.{"\n"}
        {"\n"}(2) biddz ermöglicht es Nutzern, die Künstler bzw. Musiker sind
        (in dieser Eigenschaft „Künstler“), über das biddz Portal
        Vertriebsrechte an vom Künstler erstellen Songs („Song(s)“) an biddz zu
        übertragen („Lizenzierung“). Für die Lizenzierung erhält der Künstler
        Vergütungen von biddz. Im Übrigen wird auf die jeweils gültigen
        Sonderbedingungen für die Lizenzierung verwiesen.{"\n"}
        {"\n"}(3) Weiterhin kann der Künstler über das biddz Portal Anteile an
        Songs („Songanteil(e)) bestimmen, die biddz an Nutzer veräußert (in
        dieser Eigenschaft „Fanvestoren“), z.B. in Form von NFT („Veräußerung“).
        Im Übrigen wird auf die Sonderbedingungen für die Veräußerung von
        Songanteilen verwiesen.{"\n"}
        {"\n"}(4) Der Kundenservice ist von jeweils Montag bis Freitag von 9.30
        bis 17.00 Uhr unter folgenden Kontaktdaten erreichbar:{"\n"}
        {"\n"}E-Mail: support@biddz.io{"\n"}Tel: 030/921054680{"\n"}
        {"\n"}(5) biddz kann dem Nutzer jederzeit eine Änderung der
        Nutzungsbedingungenvorschlagen. Änderungen der Nutzungsbedingungen
        werden dem Nutzer spätestens 20 Kalendertage vor dem vorgeschlagenen
        Zeitpunkt ihres Wirksamwerdens in Textform (z. B. per E-Mail) angeboten.
        Änderungen der Nutzungsbedingungen werden mit ausdrücklicher Zustimmung
        des Nutzers wirksam. biddz weist darauf hin, dass biddz das biddz Portal
        regelmäßig um weitere Funktionen erweitert und die Nutzung von neuen
        Funktionen die Zustimmung der Geltung der aktuellen Version der
        Nutzungsbedingungen erfordert.{"\n"}
        {"\n"}2. Registrierung auf dem biddz Portal und Nutzung des biddz
        Portals{"\n"}(1) Die Nutzung des biddz Portals setzt die Registrierung
        als Nutzer voraus. Die Registrierung erfolgt durch Eröffnung eines
        Nutzerkontos auf dem biddz Portal („Nutzerkonto“) unter Zustimmung der
        Nutzungsbedingungen, Kenntnisnahme der Datenschutzerklärung und Angabe
        einer Mobiltelefonnummer. Vor Abschluss der Registrierung kann der
        Nutzer seine angegebenen Daten überprüfen und ggf. korrigieren. Mit
        erfolgreicher Registrierung, die von biddz in Textform (z.B. SMS,
        Textnachricht oder E-Mail) bestätigt wird, kommt zwischen biddz und dem
        Nutzer ein Vertrag über die Nutzung des biddz Portals
        („Nutzungsvertrag”) zustande und es wird für den Nutzer ein Nutzerkonto
        eröffnet. Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht
        nicht.{"\n"}
        {"\n"}(2) Die Registrierung gemäß Abs. 1 berechtigt Nutzer noch nicht
        zum Erwerb von Songanteilen. Erst nach korrekter Eingabe von fehlenden
        Daten im Nutzerkonto (z.B. Zahlungsmethode) sind Fanvestoren zum Erwerb
        von Songanteilen berechtigt. {"\n"}
        {"\n"}(3) Nutzern, bei denen es sich um natürliche Personen handelt, ist
        die Registrierung nur gestattet, wenn sie mindestens das 18. Lebensjahr
        vollendet haben und unbeschränkt geschäftsfähig sind. Die Registrierung
        ist juristischen Personen und Personengesellschaften gestattet, sofern
        diese sich als Künstler registrieren (siehe Absatz 3).{"\n"}
        {"\n"}(4) Die bei der Registrierung abgefragten Daten sind vollständig
        und korrekt anzugeben. Des Weiteren ist anzugeben in welcher Eigenschaft
        (Künstler oder Fanvestor) der Nutzer das Nutzerkonto nutzen will. Die
        Registrierung einer juristischen Person oder Personengesellschaft darf
        nur von einer vertretungsberechtigten natürlichen Person vorgenommen
        werden, die namentlich genannt werden muss. Bei der Registrierung
        natürlicher Personen dürfen nur einzelne Personen als Inhaber des
        Nutzerkontos angegeben werden.{"\n"}
        {"\n"}(5) Mehrfachregistrierungen sind unzulässig.{"\n"}
        {"\n"}(6) Ändern sich nach der Registrierung die angegebenen Daten, so
        ist der Nutzer verpflichtet, die Angaben in seinem Nutzerkonto
        unverzüglich zu aktualisieren.{"\n"}
        {"\n"}(7) Voraussetzung für die Nutzung ist, dass sie der Nutzer das
        biddz Portal auf eigene Rechnung nutzt. Der Nutzer darf nicht für eine
        andere Person handeln.{"\n"}
        {"\n"}(8) Nutzer müssen ihr Passwort geheim halten und den Zugang zu
        ihrem Nutzerkonto sorgfältig sichern. Nutzer sind verpflichtet, biddz
        umgehend zu informieren, wenn es Anhaltspunkte dafür gibt, dass ein
        Nutzerkonto von Dritten genutzt wurde oder wird.{"\n"}
        {"\n"}(9) Nutzerkonten sind nicht übertragbar.{"\n"}
        {"\n"}(10) biddz kann die Nutzung des biddz Portals oder einzelner
        Funktionen des biddz Portals oder den Umfang, in dem einzelne Funktionen
        genutzt werden können, an bestimmte Voraussetzungen knüpfen, wie z. B.
        Prüfung der Registrierungsdaten, Nutzungsdauer, Zahlungsverhalten oder
        von der Vorlage bestimmter Nachweise (z. B. Identitätsnachweise)
        abhängig machen.{"\n"}
        {"\n"}3. Profil des Nutzers{"\n"}(1) Registrierte Nutzer legen ein für
        andere Nutzer einsehbares Profil („Profil“) an.{"\n"}
        {"\n"}(2) Ein Profil muss einen Nutzernamen, der frei wählbar ist und
        von dem tatsächlichen Namen des Nutzers abweichen kann („Profilname“),
        enthalten. Ein Profilname darf nicht gegen geltendes Recht, rechte
        Dritter oder die guten Sitten verstoßen, beleidigend sein oder auf eine
        vom konkreten Nutzer personenverschiedenen Dritten hindeuten. Des
        Weiteren darf kein Profilname gewählt werden, der bereits von einem
        anderen Nutzer als Profilnamen ausgewählt wurde.{"\n"}
        {"\n"}(3) Der Nutzer kann in seinem Profil in einem Freitextfeld
        („Freitextfeld") sich beschreiben und von seiner Person erzählen. Der
        Nutzer darf im Freitextfeld keine Inhalte eingeben, die durch ihren
        Inhalt oder ihre Form oder Gestaltung oder auf sonstige Weise gegen
        geltendes Recht oder die guten Sitten verstoßen. Insbesondere unterlässt
        es der Nutzer gegen geltendes Recht (zum Beispiel Straf-, Wettbewerbs-
        und Jugendschutzrecht) zu verstoßen und Rechte Dritter (zum Beispiel
        Namens-, Marken-, Urheber-, Bild- und Datenschutzrechte) zu verletzen.
        {"\n"}
        {"\n"}(4) Weiterhin kann der Nutzer in seinem Profil Verlinkungen zu
        seinen sonstigen Social Media Profilen aufnehmen. Es ist untersagt
        Verlinkungen zu fremden Social Media Profilen auszunehmen. {"\n"}
        {"\n"}4. Veröffentlichung von Inhalten durch die Nutzer und
        Kommentarfunktion{"\n"}(1) Der Nutzer hat die Möglichkeit, Inhalte
        (Text, Fotos, Grafiken, Videos etc.) auf dem biddz Portal hochzuladen
        (zu publizieren), insbesondere kann der Nutzer unter Angabe seines
        Profilnamens Songs für andere Nutzer sichtbar kommentieren.{"\n"}
        {"\n"}(2) Der Nutzer verpflichtet sich gegenüber biddz, keine Inhalte
        (einschließlich Kommentare) auf dem biddz Portal hochzuladen, die durch
        ihren Inhalt oder ihre Form oder Gestaltung oder auf sonstige Weise
        gegen geltendes Recht oder die guten Sitten verstoßen. Insbesondere
        verpflichtet sich der Nutzer, bei dem Hochladen von Inhalten geltendes
        Recht (zum Beispiel Straf-, Wettbewerbs- und Jugendschutzrecht) zu
        beachten und keine Rechte Dritter (zum Beispiel Namens-, Marken-,
        Urheber-, Bild- und Datenschutzrechte) zu verletzen.{"\n"}
        {"\n"}(3) Der Nutzer gewährt biddz bezüglich seiner auf dem biddz Portal
        hochgeladenen (publizierten) Inhalte und Kommentare ein nicht
        exklusives, nicht unterlizenzierbares, unentgeltliches Recht, diese
        Inhalte weltweit auf dem biddz Portal zu nutzen, zu vervielfältigen, zu
        veröffentlichen und zu verändern (lediglich technischum die Inhalte auf
        dem biddz Portal darstellen zu können) sowie das Recht, den Profilnamen
        des Nutzers in Verbindung mit diesen Inhalten bzw. Kommentaren zu
        verwenden. {"\n"}
        {"\n"}(4) biddz ist jederzeit berechtigt gemäß Ziffer 5, einzelne
        Inhalte zu sperren oder zu löschen, zum Beispiel wenn der Verdacht
        besteht, dass diese gegen geltendes Recht oder Rechte Dritter verstoßen.
        {"\n"}
        {"\n"}5. Maßnahmen gegen Nutzer{"\n"}(1) biddz kann insbesondere die
        folgenden Maßnahmen ergreifen, wenn konkrete Anhaltspunkte dafür
        vorliegen, dass ein Nutzer gegen gesetzliche Vorschriften, die
        Nutzungsbedingungen oder sonstige vertragliche Bestimmungen verstößt,
        Rechte Dritter verletzt oder in betrügerischen Aktivitäten involviert
        ist:{"\n"}
        {"\n"}Verwarnung des jeweiligen Nutzers,{"\n"}Löschung des
        gegenständlichen Inhalts, Kommentars oder Profilnamens {"\n"}
        Einschränkung der Nutzung des biddz Portals (insbesondere die
        Kommentarfunktion und die Kaufaktivitäten des Nutzers),{"\n"}
        vorübergehende Sperrung des Nutzers{"\n"}und endgültige Sperrung des
        Nutzers (außerordentliche Kündigung dieses Nutzungsvertrags).{"\n"}
        {"\n"}(2) Bei der Entscheidung, ob bzw. welche Maßnahme zu ergreifen
        ist, werden die berechtigten Interessen des betroffenen Nutzers
        berücksichtigen, insbesondere ob Anhaltspunkte dafür bestehen, dass der
        Nutzer den Verstoß nicht zu vertreten hat. Darüber hinaus wird dem
        Nutzer die Möglichkeit eingeräumt, zu dem Vorwurf Stellung zu nehmen.
        biddz wird auch diese Stellungnahme in seine Entscheidung einbeziehen.
        {"\n"}
        {"\n"}(3) Sobald der Verstoß behoben ist, werden Maßnahmen, die für die
        Behebung des Verstoßes bzw. Sicherstellung, dass keine Wiederholung des
        Verstoßes erfolgt, beendet. Im Falle einer endgültigen Sperrung des
        Nutzers, ist eine Wiederherstellung des gesperrten Nutzerkontos
        ausgeschlossen.{"\n"}
        {"\n"}6. Gebühren für die Nutzung des biddz Portals{"\n"}Gebühren für
        die Nutzung des biddz Portals fallen nicht an. Hinsichtlich der
        Gebühren, die bei der Lizenzierung und der Veräußerung anfallen, wird
        auf die Sonderbedingungen für die Lizenzierung bzw. Sonderbedingungen
        für die Veräußerung von Songanteilen verwiesen.{"\n"}
        {"\n"}7. Kündigung{"\n"}(1) Nutzer können den Nutzungsvertrag jederzeit
        kündigen.{"\n"}
        {"\n"}(2) biddz kann den Nutzungsvertrag jederzeit ordentlich mit einer
        Frist von 30 Tagen zum Monatsende kündigen. Das Recht zur
        außerordentlichen Kündigung aus wichtigem Grund bleibt hiervon
        unberührt.{"\n"}
        {"\n"}(3) Sobald der Nutzungsvertrag von biddz gekündigt wurde, darf der
        Nutzer die Dienste des biddz Portals auch mit anderen Nutzerkonten nicht
        mehr nutzen und sich nicht erneut registrieren.{"\n"}
        {"\n"}(4) Eine Kündigung hat keine Auswirkungen auf die Wirksamkeit von
        bereits auf dem biddz Portal zustande gekommenen Kaufverträgen und
        Lizenzverträgen.{"\n"}
        {"\n"}8. Technische Verfügbarkeit des biddz Portals{"\n"}(1) biddz
        stellt Nutzern das biddz Portal im Durchschnitt 98% bezogen auf ein
        Kalenderjahr zur Verfügung. Ausfallzeiten, die die Nutzer betreffen,
        wird biddz den Nutzern mitteilen.{"\n"}
        {"\n"}(2) Um die Qualität und Weiterentwicklung des biddz Portals
        gewährleisten zu können, ist biddz berechtigt, Wartungsarbeiten
        durchzuführen. Wartungsarbeiten werden bei der Berechnung der
        Verfügbarkeit des biddz Portals nicht berücksichtigt. Soweit dies
        möglich ist, wird biddz die Wartungsarbeiten zu Zeiten mit geringer
        Auslastung des biddz Portals durchführen. Wartungsarbeiten werden den
        Nutzern mit angemessener Vorlaufzeit angekündigt.{"\n"}
        {"\n"}(3) Bei der Berechnung der Verfügbarkeit des biddz Portals bleiben
        auch Zeiten unberücksichtigt, in denen das biddz Portal aufgrund
        dringender Anpassungen oder Aktualisierungen der für das biddz Portal
        genutzten IT-Infrastruktur zur Behebung von Sicherheitslücken, akut
        instabiler Software und Hardware oder bei Gefahr im Verzug, wie z.B. bei
        laufenden Angriffen, nicht verfügbar ist. In solchen Fällen kann biddz
        auch außerplanmäßige und unangekündigte Wartungsarbeiten vornehmen.
        biddz wird sich jedoch bemühen, die Nutzer unverzüglich zu
        benachrichtigen und über den Stand der Wartungsarbeiten zu informieren.
        {"\n"}
        {"\n"}(4) Bei der Berechnung der Verfügbarkeit werden Zeiten, in denen
        das biddz Portal aufgrund von technischen oder sonstigen Problemen, die
        nicht im Einflussbereich von biddz liegen (höhere Gewalt, Verschulden
        Dritter (die nicht Erfüllungsgehilfen sind), Ursachen im Einflussbereich
        der Nutzer (z.B. Hardwarefehler), usw.) nicht berücksichtigt.{"\n"}
        {"\n"}(5) Ziffer 9 der Nutzungsbedingungen (Haftung) bleibt von den
        vorstehenden Regelungen dieser Ziffer unberührt.{"\n"}
        {"\n"}9. Haftung{"\n"}(1) biddz haftet dem Nutzer gegenüber in allen
        Fällen vertraglicher und außervertraglicher Haftung bei Vorsatz und
        grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen auf
        Schadensersatz oder Ersatz vergeblicher Aufwendungen.{"\n"}
        {"\n"}(2) In sonstigen Fällen haftet biddz – soweit in Abs. 3 nicht
        abweichend geregelt – nur bei Verletzung einer Vertragspflicht, deren
        Erfüllung die ordnungsgemäße Durchführung des Nutzungsvertrags überhaupt
        erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen
        darf (so genannte Kardinalpflicht), und zwar beschränkt auf den Ersatz
        des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist
        die Haftung von biddz vorbehaltlich der Regelung in Abs. 3
        ausgeschlossen.{"\n"}
        {"\n"}(3) Die Haftung für Schäden aus der Verletzung des Lebens, des
        Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt
        von den vorstehenden Haftungsbeschränkungen und -ausschlüssen unberührt
        {"\n"}
        {"\n"}10. Freistellung{"\n"}Der Nutzer stellt biddz von sämtlichen
        Ansprüchen frei, die Dritte gegenüber biddz wegen einer unsachgemäßen,
        vertragswidrigen oder rechtswidrigen Nutzung des Kundenkontos und des
        biddz Portals geltend machen, sofern der Nutzer die unsachgemäße,
        vertragswidrige oder rechtswidrige Nutzung zu vertreten hat. Der Nutzer
        übernimmt hierbei die Kosten der notwendigen Rechtsverteidigung von
        biddz einschließlich sämtlicher Gerichts- und Anwaltskosten in
        gesetzlicher Höhe. Der Nutzer ist verpflichtet, biddz für den Fall einer
        Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und
        vollständig alle Informationen zur Verfügung zu stellen, die für die
        Prüfung der Ansprüche und eine Verteidigung erforderlich sind.{"\n"}
        {"\n"}11. Datenschutz{"\n"}Informationen zum Datenschutz sind in der
        Datenschutzerklärung enthalten.{"\n"}
        {"\n"}12. Schlussbestimmungen{"\n"}(1) Der Nutzer kann die aktuelle
        Version dieser Nutzungsbedingungen über das biddz Portal herunterladen
        und speichern.{"\n"}
        {"\n"}(2) Es gilt das Recht der Bundesrepublik Deutschland unter
        Ausschluss des UN-Kaufrechts, solange dem keine zwingenden gesetzlichen
        Vorschriften entgegenstehen. Dies gilt auch im Übrigen, sofern keine
        gesetzlichen nationalen Verbraucherschutzvorschriften in dem Land, in
        dem der Nutzer seinen Wohnsitz oder gewöhnlichen Aufenthaltsort hat, zu
        seinen Gunsten Vorrang haben. Nutzer, die Verbraucher sind, können sich
        jederzeit auf günstigere Regelungen ihres Heimatrechts berufen.{"\n"}
        {"\n"}(3) Vertragssprache sind deutsch und englisch. Maßgeblich für die
        Auslegung ist die deutsche Version der Nutzungsbedingungen.{"\n"}
        {"\n"}(4) Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz
        oder teilweise unwirksam, undurchführbar oder nicht vollstreckbar sein
        oder werden, wird dadurch die Wirksamkeit, Durchführbarkeit und
        Vollstreckbarkeit der übrigen Bestimmungen dieser nicht berührt. Die
        unwirksame, ungesetzliche, nicht vollstreckbare und/oder undurchführbare
        Bestimmung gilt als durch eine solche wirksame, gesetzliche und
        vollstreckbare Bestimmung ersetzt, welche weitestmöglich dem Geist und
        wirtschaftlichen Zweck dieser Nutzungsbedingungen sowie dem
        ursprünglichen Willen der Parteien entspricht.{"\n"}
        {"\n"}(5) Unter folgendem Link ist Online-Streitbeilegung (OS) für
        Privatpersonen der Europäischen Kommission erreichbar:
        https://ec.europa.eu/consumers/odr/ genutzt werden.{"\n"}biddz ist nicht
        bereit und nicht verpflichtet an dem Online-Streitbeilegungsverfahren
        der Europäischen Kommission teilzunehmen.{"\n"}
      </Text>
    </ScrollView>
  );
};
