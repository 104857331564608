import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateTransfer = (collectibleID) => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation((data) =>
    client.post(`collectibles/${collectibleID}/transfer/`, data).then((_) => {
      queryClient.invalidateQueries(`collectibles`);
      queryClient.invalidateQueries(`collectible-${collectibleID}`);
    })
  );
};

export default useCreateTransfer;
