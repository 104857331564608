import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTransfer = (transferID) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `transfer-${transferID}`,
    () =>
      client.get(`transfers/${transferID}/`).then((resp) => {
        console.debug(`👴🏼 -> 🧒🏻 Collectible Transfer ${transferID} `);
        return resp.data;
      }),
    {
      enabled: !!transferID && !!client,
    }
  );
};
