import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useCoupon = (code) => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const { client } = useContext(AxiosContext);
  const axios = isAuthenticated ? client : client;
  return useQuery(
    `coupon-${code}`,
    () => axios.get(`/coupons/${code}/`).then((resp) => resp.data),
    {
      enabled: !!code,
    }
  );
};
