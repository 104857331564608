import React from "react";
import { Image } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal functions
import { translate } from "utils/helpers";
import { HorizontalIconButton } from "theme/buttons/horizontal-icon-button.component";

export const TicketCollectibleDetailTransferCreateButton = ({
  collectible,
}) => {
  const navigation = useNavigation();
  return (
    <HorizontalIconButton
      fontSize={"sm"}
      fontWeight={"semibold"}
      text={translate("transfer_ticket")}
      onPress={() =>
        navigation.navigate("TicketCollectibles", {
          screen: "TicketCollectibleDetailTransferCreate",
          params: {
            collectibleID: collectible?.id,
            collectibleInitialData: collectible,
          },
        })
      }
      rightComponent={
        <Image
          size={6}
          alt={"Paper Plane Symbol"}
          source={require("assets/images/features/paper-plane.png")}
        />
      }
    />
  );
};
