import React from "react";
import { Box, VStack, HStack, Text, Image, Hidden, Divider } from "native-base";
// utility functions
import {
  getBenefitIconFromTier,
  getLocalizedNumber,
  translate,
} from "utils/helpers";

export const LicenseCollectibleDetailMainInfo = ({ collectible }) => {
  return (
    <Box p={5} shadow={5} bg={"light.50"} borderRadius={"2xl"}>
      <HStack alignItems={"center"} justifyContent={"space-evenly"}>
        <VStack alignItems={"center"} flex={1}>
          <Text fontSize={["xs", "sm"]}>{translate("biddz_owned")}</Text>
          <Text fontSize={["2xl", "3xl"]} fontWeight={600}>
            {getLocalizedNumber(collectible?.units)}
          </Text>
        </VStack>
        <Divider orientation={"vertical"} bg={"gray.200"} />
        <VStack alignItems={"center"} flex={1.25}>
          <Text fontSize={["xs", "sm"]}>{`Tier`}</Text>
          <HStack space={2} alignItems={"center"}>
            {collectible?.license?.tier && (
              <Image
                size={10}
                alt={"tier"}
                source={getBenefitIconFromTier(collectible?.license?.tier)}
              />
            )}
            <Text
              fontWeight={600}
              fontSize={["2xl", "3xl"]}
              textTransform={"capitalize"}
            >
              {collectible?.license?.tier}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};
