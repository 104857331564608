import React from "react";
import { Skeleton } from "native-base";
//internal components
import { SkeletonTextHorizontal } from "theme/skeletons/skeleton.text-horizontal";

export const SkeletonLicenseEarningsChart = () => (
  <>
    <Skeleton
      _dark={{ startColor: "dark.500" }}
      _light={{ startColor: "light.500" }}
      h={[210, 185]}
      w={"100%"}
      mt={[12, 16]}
    />
    <SkeletonTextHorizontal numberOfWords={3} my={10} />
  </>
);
