import React from "react";
import { Box, Text, VStack } from "native-base";
import { Modal } from "theme/feedback/modal.component";
// internal
import { translate } from "utils/helpers";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NewButtonBody } from "theme/buttons/new-button-body.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";

export const UserLogoutButton = ({
  onLogout,
  fontSize = ["md", "lg", "xl"],
}) => {
  const toggleModal = () => setShowModal(!showModal);
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Modal
      pressableChildren={
        <NewButtonBody
          textStyles={{
            fontSize: fontSize,
            fontWeight: "semibold",
            color: "danger.300",
          }}
          iconStyles={{ color: "danger.300" }}
          iconName={"logout"}
          text={translate("logout")}
        />
      }
      modalChildren={
        <Box
          mx={2}
          padding={5}
          borderRadius={"xl"}
          _dark={{ bg: "dark.900" }}
          _light={{ bg: "light.100" }}
        >
          <VStack space={6}>
            <Text bold fontSize={["md", "lg"]} numberOfLines={1}>
              {translate("confirm_logout")}
            </Text>
            <Text fontSize={["sm", "md"]}>
              {translate("logout_from_biddz")}
            </Text>
            <VStack mx={5} justifyContent={"space-between"}>
              <Box mb={2}>
                <SecondaryButton
                  nativeID="logout-from-app"
                  text={translate("logout")}
                  textSize={["lg", "xl"]}
                  onPress={() => {
                    setShowModal(false);
                    onLogout();
                  }}
                />
              </Box>
              <PrimaryButton
                nativeID="abort-logout"
                text={translate("abort")}
                textSize={["lg", "xl"]}
                onPress={toggleModal}
              />
            </VStack>
          </VStack>
        </Box>
      }
      modalStyles={{
        alignItems: "center",
        _dark: { bg: "dark.900" },
        _light: { bg: "light.100" },
      }}
      onPress={toggleModal}
      onClose={toggleModal}
      isOpen={showModal}
    />
  );
};
