import React, { memo } from "react";
import { VStack, HStack, Divider, Text } from "native-base";
// utility functions
import { getLocalizedNumber, translate } from "utils/helpers";

const Item = memo(({ value, subtitle }) => {
  return (
    <VStack alignItems={"center"}>
      <Text fontSize={["xs", "sm", "md"]}>{subtitle}</Text>
      <Text bold fontSize={["2xl", "3xl"]}>
        {value}
      </Text>
    </VStack>
  );
});

export const LicenseProductDetailMainInfo = ({ product, isCollectible }) => (
  <HStack
    alignItems={"center"}
    p={isCollectible ? 0 : 4}
    justifyContent={"space-evenly"}
  >
    <Item
      value={`${getLocalizedNumber(product?.price_per_unit)} €`}
      subtitle={isCollectible ? translate("per_piece") : translate("per_bz")}
    />
    <Divider mx={"4"} orientation={"vertical"} />
    <Item
      value={getLocalizedNumber(product?.available_units)}
      subtitle={isCollectible ? translate("piece_left") : translate("bz_left")}
    />
    <Divider mx={"4"} orientation={"vertical"} />
    <Item
      value={`${getLocalizedNumber(
        Math.round((1 - product?.available_units / product?.units) * 1000) / 10
      )} %`}
      subtitle={translate("bz_sold")}
    />
  </HStack>
);
