import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AuthenticationContext } from "services/authentication.context";
import { AxiosContext } from "services/axios.context";

export const useUserBenefits = (params, size = 4) => {
  const { client } = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useInfiniteQuery(
    [`me-benefits`, params],
    ({ pageParam = 1 }) =>
      client
        .get(`me/collectible_benefits/?page=${pageParam}&size=${size}`, {
          params: params,
        })
        .then((resp) => {
          console.debug(`🥰 User Benefits | Page ${pageParam} `);
          return resp.data;
        }),
    {
      enabled: isAuthenticated,
      staleTime: 300000,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
