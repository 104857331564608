import React, { useContext } from "react";
import Constants from "expo-constants";
import { ScrollView, VStack } from "native-base";

// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { CouponDetailFail } from "features/general/coupons/components/coupon-detail-fail.component";
import { CouponDetailSuccess } from "features/general/coupons/components/coupon-detail-success.component";
// internal hooks
import { useCoupon } from "features/general/coupons/hooks/useCoupon";

export const CouponDetailScreen = ({ navigation, route }) => {
  const { code } = route.params;
  const coupon = useCoupon(code);

  const isDardan =
    coupon.data?.product?.auction_id === Constants.expoConfig.extra.DARDAN_ID;

  return (
    <ScreenFrame p={5}>
      <ScrollView
        h={1}
        contentContainerStyle={{ flexGrow: 1 }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {coupon.isSuccess && !coupon.isLoading && (
          <CouponDetailSuccess
            isDardan={isDardan}
            product={coupon.data?.product}
            coupon={coupon.data}
            navigation={navigation}
          />
        )}
        {!coupon.isSuccess && !coupon.isLoading && (
          <CouponDetailFail navigation={navigation} />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
