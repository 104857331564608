import React, { useContext, useEffect } from "react";
import { Dimensions, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HStack, KeyboardAvoidingView, VStack } from "native-base";
import { AuthenticationContext } from "services/authentication.context";
import { AuthenticationRequired } from "navigation/components/auth-required.component";
import { MyDrawerNavigator } from "navigation/drawer/my-drawer.navigator";
import { NavigationPanel } from "navigation/components/navigation-panel.component";

const { height } = Dimensions.get("screen");

export const ScreenFrame = ({
  children,
  bg = null,
  p = [0, 0, 0],
  showDrawer = true,
  showFooter = false,
  safeAreaBottom = true,
  safeAreaTop = 0 || true,
  safeAreaTopWithTopBar = true,
  isAuthenticationRequired = false,
  ...props
}) => {
  const windowHeight = Dimensions.get("window").height;
  const { isAuthenticated, setShowAuthModal, setRemoteURL, userDetails } =
    useContext(AuthenticationContext);
  const navigation = useNavigation();

  useEffect(() => {
    if (isAuthenticated && !!userDetails) {
      if (!userDetails?.has_responded_latest_terms_and_conditions)
        navigation.navigate("Users", { screen: "UserEditTermsOfAgreement" });
    } else if (!isAuthenticated) {
      navigation.navigate("Users", { screen: "Login" });
    }
  }, [userDetails]);

  return (
    <HStack
      flex={1}
      maxH={windowHeight}
      bg={bg ? bg : "lightBackground"}
      safeAreaTop={safeAreaTopWithTopBar}
    >
      {showDrawer && <MyDrawerNavigator />}
      <VStack
        p={p}
        flex={1}
        {...props}
        maxHeight={height}
        safeAreaBottom={safeAreaBottom}
        safeAreaTop={safeAreaTop && Platform.OS !== "web" ? 12 : 0}
      >
        <KeyboardAvoidingView
          flex={1}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          {isAuthenticationRequired && !isAuthenticated ? (
            <VStack flex={1}>
              <NavigationPanel />
              <AuthenticationRequired
                flex={1}
                navigation={navigation}
                setRemoteURL={setRemoteURL}
                setShowAuthModal={setShowAuthModal}
              />
            </VStack>
          ) : (
            children
          )}
        </KeyboardAvoidingView>
      </VStack>
    </HStack>
  );
};
