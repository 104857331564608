import React, { useState } from "react";
import { Text, Modal, Box, Icon, Pressable } from "native-base";
import { getWindowWidth } from "utils/helpers";

export const IconModal = ({
  icon,
  size = 5,
  color = "darkText",
  contentText = null,
  ...props
}) => {
  const width = getWindowWidth();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  return (
    <Box {...props}>
      <Pressable onPress={toggleModal}>
        <Icon size={size} as={icon} color={color} />
      </Pressable>

      <Modal
        isOpen={showModal}
        borderRadius="xl"
        onClose={toggleModal}
        alignItems="center"
      >
        <Modal.Content
          borderRadius="xl"
          maxWidth={width}
          _light={{
            bg: "light.100",
          }}
          _dark={{
            bg: "dark.900",
          }}
        >
          <Modal.Body>
            {contentText && <Text fontSize={["md", "lg"]}>{contentText}</Text>}
            {props.children}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Box>
  );
};
