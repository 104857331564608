import React from "react";
import { useController } from "react-hook-form";
import { FormControl, TextArea, HStack, Text, VStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { IconModal } from "theme/feedback/icon-modal.component";
import { translate } from "utils/helpers";

export const FormControlTextarea = ({
  name,
  label,
  control,
  rules = {},
  value = null,
  isDisabled = false,
  helperText = null,
  infoText = null,
  ...props
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });

  return (
    <FormControl
      isRequired={rules?.required}
      isInvalid={invalid}
      isDisabled={isDisabled}
    >
      <HStack flex={1}>
        <VStack w={"100%"} space={1}>
          {label && (
            <HStack alignItems="center" justifyContent="space-between">
              <HStack space="2" alignItems="center">
                <FormControl.Label>{label}</FormControl.Label>
                {!rules?.required && (
                  <Text color="primary.600">{`(${translate(
                    "optional"
                  )})`}</Text>
                )}
              </HStack>
              {infoText && (
                <IconModal
                  iconColor="primary.600"
                  icon={<AntDesign name="questioncircleo" />}
                  contentText={infoText}
                />
              )}
            </HStack>
          )}
          <TextArea
            {...props}
            borderRadius="lg"
            minHeight={150}
            maxHeight={150}
            numberOfLines={4}
            size="xl"
            isRequired={!!rules?.required}
            value={field.value}
            onChangeText={field.onChange}
          />
          <FormControl.ErrorMessage>
            {error?.type == "required" && translate("is_required")}
            {error?.type != "required" && translate("invalid_input")}
          </FormControl.ErrorMessage>
          {helperText && (
            <FormControl.HelperText>{helperText}</FormControl.HelperText>
          )}
        </VStack>
      </HStack>
    </FormControl>
  );
};
