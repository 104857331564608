import React from "react";
import { Select as NBSelect, HStack, VStack, FormControl } from "native-base";
import { useController } from "react-hook-form";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate } from "utils/helpers";

export const FormControlSelectWithDict = ({
  name,
  dict,
  control,
  label = "",
  rules = {},
  isDisabled = false,
  infoText = undefined,
  helperText = undefined,
  selectedValue = undefined,
  onValueChange = undefined,
}) => {
  const { field, fieldState } = useController({
    name: name,
    rules: rules,
    control: control,
    defaultValue: selectedValue,
  });

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={rules?.required}
      isInvalid={fieldState?.invalid}
    >
      <HStack flex={1}>
        <VStack w={"100%"} space={1} pl={1}>
          {label && (
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <HStack space={2} alignItems={"center"}>
                <FormControl.Label _text={{ color: "darkText" }}>
                  {label}
                </FormControl.Label>
                {!rules?.required && (
                  <Text color={"primary.600"}>{`(${translate(
                    "optional"
                  )})`}</Text>
                )}
              </HStack>
              {infoText && (
                <IconModal
                  iconColor={"primary.600"}
                  icon={<AntDesign name="questioncircleo" />}
                  contentText={infoText}
                />
              )}
            </HStack>
          )}
          <NBSelect
            size={"xl"}
            borderRadius={"lg"}
            selectedValue={field.value}
            isRequired={rules?.required}
            onValueChange={field.onChange}
          >
            {dict.map((row, index) => (
              <NBSelect.Item key={index} label={row.label} value={row.value} />
            ))}
          </NBSelect>
          <FormControl.ErrorMessage>
            {fieldState?.error?.type == "required" && translate("is_required")}
            {fieldState?.error?.type != "required" &&
              translate("invalid_input")}
          </FormControl.ErrorMessage>
          {helperText && (
            <FormControl.HelperText>{helperText}</FormControl.HelperText>
          )}
        </VStack>
      </HStack>
    </FormControl>
  );
};
