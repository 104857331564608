import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import Constants from "expo-constants";
import { AxiosContext } from "services/axios.context";

export const useTermsVersion = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`term-and-conditions`, () =>
    client.get("/version/terms_and_conditions/").then((resp) => {
      console.debug("Term and Conditions version retrieved");
      return resp.data;
    })
  );
};

export const useAppVersion = () => {
  const baseURL = Constants.expoConfig.extra.API_BASE_URL;
  return useQuery(`app-version`, () =>
    axios.get(`${baseURL}/version/`).then((resp) => {
      console.debug(" 💻 Version retrieved");
      return resp.data;
    })
  );
};
