import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserTracks = (searchText = "", size = 8) => {
  const { client } = useContext(AxiosContext);
  return useInfiniteQuery(
    ["user-tracks"],
    ({ pageParam = 1 }) =>
      client
        .get(
          `/me/tracks/?page=${pageParam}&size=${size}&title__contains=${searchText}`
        )
        .then((resp) => resp.data),
    {
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
