import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTicketCollectibleQRCode = (
  ticketCollectibleID,
  enabled = true
) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `ticket-collectible-${ticketCollectibleID}-qr-code`,
    () =>
      client
        .get(`ticket_collectibles/${ticketCollectibleID}/QR_code/`)
        .then((resp) => {
          console.debug(`🎟 Ticket Collectible ${ticketCollectibleID} QR-Code`);
          return resp.data;
        }),
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
      enabled: !!ticketCollectibleID && enabled,
    }
  );
};
