import React from "react";
import { Text, VStack, HStack, Checkbox } from "native-base";
// hooks
import useAddTrackToRelease from "features/royalties/tracks/hooks/useAddTrackToRelease";
import useDeleteTrackFromRelease from "features/royalties/tracks/hooks/useDeleteTrackFromRelease";
// helpers
import { translate } from "utils/helpers";

export const ExistingTrackCard = ({ index, track, release }) => {
  const { addTrackToRelease } = useAddTrackToRelease(release.id);
  const { deleteTrackFromRelease } = useDeleteTrackFromRelease(release.id);

  return (
    <HStack
      flex={1}
      p="5"
      my="3"
      mx={{ lg: "3" }}
      space="2"
      bg="dark.800"
      borderRadius="xl"
      justifyContent="space-between"
      alignItems="center"
      _dark={{ bg: "dark.800" }}
      _light={{ bg: "light.200" }}
    >
      <HStack flex={1} space="10" alignItems="center">
        <Text fontSize={["2xl", "3xl"]}>{index}</Text>
        <VStack flex={1} space="2">
          <Text fontSize={["md", "lg"]} fontWeight="semibold">
            {track.title ? track.title : translate("untitled_track")}{" "}
          </Text>
        </VStack>
      </HStack>
      <Checkbox
        value=""
        _dark={{ bg: "dark.800" }}
        _light={{ bg: "light.200" }}
        colorScheme="primary"
        accessibilityLabel="Checkbox"
        onChange={(v) => {
          if (v) addTrackToRelease.mutate(track.id);
          else deleteTrackFromRelease.mutate(track.id);
        }}
      />
    </HStack>
  );
};
