import React from "react";
import * as Clipboard from "expo-clipboard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Text,
  Toast,
  VStack,
} from "native-base";
// import openMap from "react-native-open-maps";
import { createOpenLink } from "react-native-open-maps";
import { Modal } from "theme/feedback/modal.component";
import { translate } from "utils/helpers";

interface IProps {
  address: { location: string; lat: number; lng: number };
  showModal: boolean;
  toggleModal: () => void;
}

export const EventMapModal = ({ showModal, address, toggleModal }: IProps) => {
  const copyLink = () => {
    toggleModal();

    setTimeout(async () => {
      Clipboard.setString(address.location);
      Toast.show({
        placement: "top",
        render: ({ id, onClose }) => (
          <Box
            mx={5}
            py={3}
            px={5}
            _light={{ bg: "light.100" }}
            _dark={{ bg: "dark.900" }}
            borderRadius={"xl"}
          >
            <Text>{translate("address_copied")}</Text>
          </Box>
        ),
      });
    }, 300);
  };

  const end = `${address.lat}, ${address.lng}`;

  const openLocation = createOpenLink({ end: end });

  return (
    <Modal
      pressableChildren={<></>}
      modalChildren={
        <Box
          borderRadius={"xl"}
          width={[275, 300, 350]}
          padding={5}
          mx={2}
          _dark={{ bg: "dark.900" }}
          _light={{ bg: "light.100" }}
        >
          <VStack space={5}>
            <Text bold fontSize={["md", "lg"]}>
              {translate("copy_address")}
            </Text>
            <HStack alignItems={"center"} justifyContent="space-between">
              <Text mb={3} fontSize={["sm", "md"]}>
                {address.location}
              </Text>

              <Icon
                onPress={copyLink}
                as={MaterialCommunityIcons}
                size={5}
                name="content-copy"
              />
            </HStack>
            <HStack alignItems="center" justifyContent="space-between">
              <Text bold fontSize={["md", "lg"]}>
                {translate("open_maps")}
              </Text>

              <Icon
                onPress={openLocation}
                as={MaterialCommunityIcons}
                size={5}
                name="open-in-new"
              />
            </HStack>
          </VStack>
        </Box>
      }
      modalStyles={{
        alignItems: "center",
        bg: "transparent",
      }}
      onPress={toggleModal}
      onClose={toggleModal}
      isOpen={showModal}
    />
  );
};
