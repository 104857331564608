import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useMusician = (id) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    [`musician-${id}`],
    () => {
      console.debug(`👩‍🎤 Musician ${id} `);
      return client.get(`/musicians/${id}/`).then((resp) => resp.data);
    },
    {
      enabled: !!id && id !== "undefined",
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
