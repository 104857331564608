import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserAuction = (id, initialData = {}) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `auction-${id}-private`,
    () => client.get(`auctions/${id}/`).then((resp) => resp.data),
    {
      enabled: !!id,
      placeholderData: initialData,
      staleTime: 5000,
      // refetchOnWindowFocus: true,
    }
  );
};
