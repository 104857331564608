import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateMasterRightStatus = (masterRightId) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateMasterRightStatus: useMutation(
      (status) =>
        client
          .patch(`master_rights/${masterRightId}/`, { status })
          .then((res) => res.data),
      {
        enabled: !!masterRightId,
        onSuccess: () => {
          queryClient.invalidateQueries(`master-rights`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateMasterRightStatus;
