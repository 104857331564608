import React from "react";
import { useNavigation } from "@react-navigation/native";
import { VStack, HStack, Box, Text, Progress, CheckIcon } from "native-base";
// internal components
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditHeader = ({ step, track, release }) => {
  const navigation: any = useNavigation();
  const trackNum =
    release?.tracklisting?.findIndex((t) => t.track_id === track.id) || 0;
  return (
    <>
      <NavigationHeader
        lightContent
        bg="primary.700"
        title={`Edit Track ${trackNum + 1}`}
        headerLeft={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("Releases", {
              screen: "ReleaseEditTracks",
              params: { id: release.id, releasePartial: release },
            }),
        }}
      />
      <Box
        px="7"
        pt={{ base: 0, lg: 7 }}
        pb={{ base: 5, lg: 7 }}
        bg="primary.700"
      >
        <ResponsiveStack
          p="5"
          my="0"
          space="4"
          bg="primary.400"
          borderRadius="xl"
        >
          <HStack space="10">
            <Text fontSize={["lg", "xl"]} bold _light={{ color: "lightText" }}>
              {trackNum + 1}
            </Text>
            <VStack space="2" maxW="80%">
              <Text
                fontSize={["md", "lg"]}
                bold
                _light={{ color: "lightText" }}
              >
                {track?.title ? track.title : translate("untitled_track")}
              </Text>
              <Text fontSize={["sm", "md"]} _light={{ color: "lightText" }}>
                {release?.title ? release.title : translate("untitled_release")}
              </Text>
            </VStack>
          </HStack>
          <HStack flex={1} justifyContent="center" alignItems="center">
            <Box
              ml={{ lg: "-10%" }}
              w={{ base: "100%", lg: "40%" }}
              position="relative"
            >
              <Progress
                h={6}
                rounded="0"
                value={(step / 5) * 100}
                background="primary.900"
                _filledTrack={{
                  rounded: 0,
                  background: "white",
                }}
              />
              <Text
                w="100%"
                h="100%"
                lineHeight="sm"
                color={Number(step) < 3 ? "light.100" : "light.800"}
                fontSize={["md", "lg"]}
                textAlign="center"
                position="absolute"
              >
                {step === "5" ? (
                  <CheckIcon mt="1" size="5" color="light.800" />
                ) : (
                  `${step} / 5`
                )}
              </Text>
            </Box>
          </HStack>
        </ResponsiveStack>
      </Box>
    </>
  );
};
