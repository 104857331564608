import React from "react";
import { HStack, Image, useColorMode } from "native-base";
import { Video, ResizeMode } from "expo-av";
// internal components
import { InteractiveImage } from "theme/media/interactive-image.component";
// helpers
import { getWindowWidth } from "utils/helpers";

export const AuctionDetailImage = ({
  url,
  videoUrl = null,
  isDardan = false,
  interactiveImageTop = 0,
  ...props
}) => {
  const minWidth = 400;
  const width = getWindowWidth() / 1.5;
  const { colorMode } = useColorMode();
  const imgWidth = Math.min(width, minWidth);

  return (
    <HStack
      {...props}
      zIndex={-1}
      alignItems="center"
      // _light={{ shadow: 3, borderRadius: "xl", bg: "dark.900", p: 10 }}
    >
      {(colorMode === "dark" || isDardan) && (
        <Image
          width={imgWidth}
          height={imgWidth}
          position="absolute"
          resizeMode="stretch"
          resizeMethod="resize"
          alt="Decorative Lights"
          source={
            isDardan
              ? require("assets/images/features/dardan-backdrop.png")
              : require("assets/images/features/common-light-lights.png")
          }
        />
      )}
      {!!videoUrl ? (
        <Video
          isMuted
          isLooping
          shouldPlay
          useNativeControls={false}
          source={{ uri: videoUrl }}
          resizeMode={ResizeMode.CONTAIN}
          style={{ height: imgWidth, width: imgWidth }}
          videoStyle={{ height: "100%", width: imgWidth }}
        />
      ) : (
        <InteractiveImage
          imageURL={url}
          width={imgWidth}
          height={imgWidth}
          top={interactiveImageTop}
        />
      )}
    </HStack>
  );
};
