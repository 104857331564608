import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { VStack, ScrollView, Box, Text } from "native-base";
// internal hooks
import { useUserAuction } from "features/general/auctions/hooks/useUserAuction";
import { useUpdateProduct } from "features/royalties/license-products/hooks/useUpdateProduct";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { AuctionEditFinancialForm } from "features/general/auctions/components/auction-edit-financial-form.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { AuctionCalculatorCard } from "features/general/auctions/components/auction-calculator-card.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";
// services
import { AxiosContext } from "services/axios.context";

export const AuctionEditFinancialsScreen = ({ navigation, route }) => {
  const { id: auctionID, auctionPartials = {} } = route.params;
  const { control, handleSubmit, setError } = useForm();
  const { client } = useContext(AxiosContext);
  const { data: auction, isFetched } = useUserAuction(
    auctionID,
    auctionPartials
  );
  const product = auction?.products && auction?.products[0];
  const { updateProduct } = useUpdateProduct(product?.id);

  const navigateForward = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditBenefits",
      params: { id: auctionID, auctionPartials: auction },
    });
  };

  const getParams = (data) => {
    const { max_units_per_buyer, units, expected_total_streams } = data;
    if (max_units_per_buyer > units / 2) {
      setError("max_units_per_buyer", {
        type: "custom",
        message: translate("max_share_per_fan_error"),
      });
      return null;
    } else if (max_units_per_buyer <= 0) {
      setError("max_units_per_buyer", { type: "other" });
      return null;
    }
    return {
      license_product: { expected_total_streams },
      units,
      max_units_per_buyer,
    };
  };

  const onPress = async (data) => {
    if (["draft", "action_required"].includes(auction?.status)) {
      const params = getParams(data);
      if (params) {
        await updateProduct.mutateAsync(params);
        await client.get(
          `/license_products/${product?.license_product.id}/offer/`
        );
        navigateForward();
      }
    } else navigateForward();
  };

  const onSave = async (data) => {
    if (["draft", "action_required"].includes(auction?.status)) {
      const params = getParams(data);
      if (params) {
        await updateProduct.mutateAsync(params);
        await client.get(
          `/license_products/${product?.license_product.id}/offer/`
        );
      }
    }
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: { screen: "ReleaseDeals" },
            }),
        }}
        profileProgress={{ type: "deal", activeIndex: 1 }}
      />
      <VStack flex={1}>
        <ScrollView
          mt={{ base: 0, lg: 10 }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <PageTitle
            nativeID="save-deal-financial"
            isDisabled={updateProduct.isLoading || !isFetched}
            title={translate("deal_numbers")}
            onSave={handleSubmit(onSave)}
          />
          <Box px="7" pb="5">
            <AuctionCalculatorCard />
          </Box>
          {!isFetched ? (
            <Loading />
          ) : (
            <AuctionEditFinancialForm control={control} auction={auction} />
          )}
          <Text m="10" p="5" fontSize="md" color="light.700" textAlign="center">
            {translate("auction_financials_help_text_bottom")}
          </Text>
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        showNextIcon
        disabled={updateProduct.isLoading || !isFetched}
        isLoading={updateProduct.isLoading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
