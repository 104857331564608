import React, { memo, useEffect, useState } from "react";
import { HStack, VStack, Text, Box, ScrollView } from "native-base";
import { useForm, useWatch } from "react-hook-form";
// utility functions
import { translate, navigateBack } from "utils/helpers";
// internal hooks
import useCreateTrackMusician from "features/accounts/musicians/hooks/useCreateTrackMusician";
import { useSearchSpotifyMusicians } from "features/accounts/musicians/hooks/useSearchSpotifyMusicians";
import { useSearchAppleMusicians } from "features/accounts/musicians/hooks/useSearchAppleMusicians";
// internal components
import { ArtistList } from "features/accounts/musicians/components/artist-list.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";

const Selector = memo(({ control }) => (
  <Box flex="4">
    <FormControlSelectWithDict
      selectedValue="1"
      name="is_primary"
      control={control}
      rules={{ required: true }}
      dict={[
        { label: translate("primary_artist"), value: "1" },
        { label: translate("featured_artist"), value: "0" },
      ]}
    />
  </Box>
));

export const AddMusicianToTrackScreen = ({ navigation, route }) => {
  const { trackID } = route.params;
  const { handleSubmit, control, setValue } = useForm();

  const onSpotify = useWatch({
    control,
    name: "on_spotify",
    defaultValue: true,
  });
  const onApple = useWatch({ control, name: "on_apple", defaultValue: true });
  const alias = useWatch({ control, name: "alias" });

  const { createTrackMusician } = useCreateTrackMusician(trackID);
  const { data: spotifyArtists, isLoading: spotifyLoading } =
    useSearchSpotifyMusicians(alias, onSpotify);
  const { data: appleArtists, isLoading: appleLoading } =
    useSearchAppleMusicians(alias, onApple);

  const [spotifyArtist, setSpotifyArtist] = useState(null);
  const [appleArtist, setAppleArtist] = useState(null);

  const onPress = (data) => {
    const params = {
      is_primary: Boolean(Number(data.is_primary)),
      alias:
        !onSpotify || (!spotifyArtist?.spotify_id && !data?.spotify_id)
          ? alias
          : null,
      spotify_id: onSpotify
        ? spotifyArtist?.spotify_id || data?.spotify_id || null
        : null,
      apple_id: onApple
        ? appleArtist?.apple_id || data?.apple_id || null
        : null,
    };
    createTrackMusician.mutate(params);
  };

  useEffect(() => {
    if (createTrackMusician.isSuccess) {
      navigateBack(navigation);
    }
  }, [createTrackMusician.isSuccess]);

  useEffect(() => {
    if (appleArtists?.length > 0 || spotifyArtists?.length > 0) {
      setValue("spotify_id", "");
      setValue("apple_id", "");
    }
  }, [appleLoading, spotifyLoading]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("add_artist")}
        headerLeft={{
          iconName: "trash-can",
          onPress: () => navigateBack(navigation),
        }}
      />
      <VStack flex={1} pt="10">
        <ScrollView showsVerticalScrollIndicator={false}>
          <VStack p="5" space="7">
            <HStack space="2" alignItems="center">
              <Box flex={6}>
                <FormControlInput
                  name="alias"
                  control={control}
                  rules={{ required: true }}
                  placeholder={translate("artist_name")}
                />
              </Box>
              {/* Not sure why but if we put the code directly here then it causes alias input to lose focus on every key press. */}
              {/* But somehow extracting it to a separate component works :) */}
              <Selector control={control} />
            </HStack>

            <BooleanCheckbox
              name="on_spotify"
              control={control}
              value={true}
              justifyContent="flex-end"
            >
              <Text ml="2">{translate("is_artist_on_spotify")}</Text>
            </BooleanCheckbox>

            {onSpotify &&
              alias &&
              (spotifyArtists?.length > 0 ? (
                <ArtistList
                  data={spotifyArtists}
                  isLoading={spotifyLoading}
                  selectedArtist={spotifyArtist}
                  setSelectedArtist={setSpotifyArtist}
                />
              ) : (
                <Box w="30%">
                  <FormControlInput
                    name="spotify_id"
                    control={control}
                    rules={{ required: true, minLength: 20 }}
                    placeholder="Spotify ID"
                  />
                </Box>
              ))}

            <BooleanCheckbox
              name="on_apple"
              control={control}
              value={true}
              justifyContent="flex-end"
            >
              <Text ml="2">{translate("is_artist_on_apple_music")}</Text>
            </BooleanCheckbox>

            {onApple &&
              alias &&
              (appleArtists?.length > 0 ? (
                <ArtistList
                  data={appleArtists}
                  isLoading={appleLoading}
                  selectedArtist={appleArtist}
                  setSelectedArtist={setAppleArtist}
                />
              ) : (
                <Box w="30%">
                  <FormControlInput
                    name="apple_id"
                    control={control}
                    rules={{ required: true }}
                    placeholder="Apple ID"
                  />
                </Box>
              ))}
          </VStack>
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        disabled={createTrackMusician.isLoading}
        isLoading={createTrackMusician.isLoading}
        text={translate("save")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
