import React from "react";
import { compactInteger } from "humanize-plus";
import {
  Box,
  VStack,
  Text,
  Stack,
  Image,
  HStack,
  useMediaQuery,
  Icon,
  IconButton,
} from "native-base";
import { ImageBackground } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as Linking from "expo-linking";
// internal
import { translate } from "utils/helpers";
import { MusicianDetailHeaderSocials } from "features/accounts/musicians/components/musician-detail-header-socials.component";

export const MusicianDetailHeader = ({ musician }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    <Box mb={-5}>
      <ImageBackground
        source={
          !!musician?.profile?.cover_image_with_url?.original?.url
            ? {
                uri: musician?.profile?.cover_image_with_url?.original?.url,
              }
            : !!musician?.profile?.cover_image_with_url?.md?.url
            ? { uri: musician?.profile?.cover_image_with_url?.md?.url }
            : !!musician?.image?.original?.url
            ? { uri: musician?.image?.original?.url }
            : !!musician?.image?.md?.url
            ? { uri: musician?.image?.md?.url }
            : require("assets/images/features/profile-banner-white.png")
        }
        resizeMode={"cover"}
        alt={musician?.alias}
        style={{
          height: isDesktop ? 280 : 180,
          marginHorizontal: isDesktop ? -96 : 0,
        }}
      />
      <VStack space={5} p={5} justifyContent={"flex-end"}>
        <Stack space={3} direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} justifyContent={"flex-start"} space={3}>
            <Image
              mt={-70}
              size={32}
              rounded={"full"}
              alt={musician?.alias}
              source={{ uri: musician?.image_with_url?.md?.url }}
            />
            <VStack mt={-2}>
              <Text bold fontSize={["2xl", "3xl"]}>
                {musician?.alias}
              </Text>
              <HStack space={1} alignItems={"center"}>
                <IconButton
                  isDisabled={!musician?.spotify_id}
                  p={0}
                  _pressed={{ bg: "light.100" }}
                  _disabled={{ opacity: 0.6 }}
                  icon={
                    <Icon
                      size={"md"}
                      name={"spotify"}
                      color={"darkText"}
                      as={MaterialCommunityIcons}
                    />
                  }
                  onPress={() =>
                    Linking.openURL(
                      `https://open.spotify.com/artist/${musician?.spotify_id}`
                    )
                  }
                />
                <Text color={"gray.500"} fontSize={"sm"}>
                  {musician?.monthly_listeners > 0
                    ? translate("monthly_listeners", {
                        count: compactInteger(musician?.monthly_listeners),
                      })
                    : musician?.followers > 0 &&
                      translate("followers_count", {
                        count: compactInteger(musician?.followers),
                      })}
                </Text>
              </HStack>
            </VStack>
          </Stack>
          {isDesktop && (
            <VStack space={2} justifyContent={"space-between"}>
              <MusicianDetailHeaderSocials
                iconSize={5}
                socials={musician?.profile?.socials_with_url}
                spotifyURL={musician?.spotify_url}
                justifyContent={"flex-end"}
              />
            </VStack>
          )}
        </Stack>
        {!isDesktop && (
          <HStack px={2} space={2} justifyContent={"space-between"}>
            <Box>
              <MusicianDetailHeaderSocials
                spotifyURL={musician?.spotify_url}
                socials={musician?.profile?.socials_with_url}
              />
            </Box>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};
