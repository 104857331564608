import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useUserOrders = (size = 8) => {
  const { client } = useContext(AxiosContext);

  return useInfiniteQuery(
    ["orders-history"],
    ({ pageParam = 1 }) =>
      client.get(`me/orders/?page=${pageParam}&size=${size}`).then((resp) => {
        console.debug(`🚀 Orders | Page ${pageParam} `);
        return resp.data;
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
