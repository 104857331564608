import React from "react";
import { Divider, VStack } from "native-base";
import { translate } from "utils/helpers";
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";

export const LicenseProductDetailDescription = ({
  description,
  isCollectible,
}) => {
  return (
    <VStack space={5} maxW={450}>
      <ExpandableTextReadMore
        noOfLines={6}
        text={description}
        title={
          isCollectible
            ? translate("about_collectible")
            : translate("about_release")
        }
      />
    </VStack>
  );
};
