import React from "react";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { useTransfer } from "features/general/transfers/hooks/useTransfer";
import { TransferDetailFail } from "features/general/transfers/components/transfer-detail-fail.component";
import { TransferDetailSuccess } from "features/general/transfers/components/transfer-detail-success.component";

export const TransferDetailScreen = ({ navigation, route }) => {
  const { transferID } = route.params;
  const transfer = useTransfer(transferID);

  return (
    <ScreenFrame isAuthenticationRequired p={5}>
      {transfer.isSuccess && (
        <TransferDetailSuccess
          navigation={navigation}
          transfer={transfer?.data}
        />
      )}
      {transfer.isError && <TransferDetailFail />}
    </ScreenFrame>
  );
};
