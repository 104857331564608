import React from "react";
import { openBrowserAsync } from "expo-web-browser";
import { VStack, ScrollView, Box } from "native-base";
// internal functions
import { translate, navigateBack } from "utils/helpers";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NewButton } from "theme/buttons/new-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";

export const LegalListScreen = ({ navigation }) => (
  <ScreenFrame isAuthenticationRequired>
    <NavigationHeader
      title={translate("legal")}
      headerLeft={{
        iconName: "arrow-left",
        onPress: () => navigateBack(navigation),
      }}
    />
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <VStack space={6} p={5}>
        <NewButton
          textStyles={{ fontSize: "md", fontWeight: "semibold" }}
          text={translate("terms_of_agreement")}
          onPress={async () =>
            openBrowserAsync("https://www.biddz.io/de/terms-of-use-de")
          }
        />
        <NewButton
          textStyles={{ fontSize: "md", fontWeight: "semibold" }}
          text={"biddz " + translate("special_terms")}
          onPress={async () =>
            openBrowserAsync(
              "https://storage.googleapis.com/app-public-europe-west3-1d3f335b/terms/20230904_special_terms_shares_ec607db2-aed9-487a-8b97-4a451d6e7309.pdf"
            )
          }
        />
        <NewButton
          textStyles={{ fontSize: "md", fontWeight: "semibold" }}
          text={"Tickets " + translate("special_terms")}
          onPress={async () =>
            openBrowserAsync(
              "https://storage.googleapis.com/app-public-europe-west3-1d3f335b/terms/20230904_special_terms_tickets_ec607db2-aed9-487a-8b97-4a451d6e7309.pdf"
            )
          }
        />
        <NewButton
          textStyles={{ fontSize: "md", fontWeight: "semibold" }}
          text={translate("privacy_policy")}
          onPress={async () =>
            openBrowserAsync("https://www.biddz.io/de/privacy-notice")
          }
        />
        <Box p={5}></Box>
      </VStack>
    </ScrollView>
  </ScreenFrame>
);
