import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import axios from "axios";

const useUpdateTrackAudio = (trackID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateTrackAudio: useMutation(
      async (audio) => {
        console.debug(audio);
        // 1. Get GCS signed url from server
        const tempGUrl = await client.get(`tracks/${trackID}/audio/`);
        console.debug("Temporary google storage url ", tempGUrl.data.url);
        // 2. Upload file to GCS using signed url
        const form = new FormData();
        for (const [key, value] of Object.entries(tempGUrl.data.fields)) {
          form.append(key, value);
        }
        if (audio.file) {
          form.append("file", audio.file);
        } else {
          let filename = audio.uri.split("/").pop();
          let match = /\.(\w+)$/.exec(filename);
          let type = match ? `audio/${match[1]}` : `audio`;
          console.debug({ uri: audio.uri, name: filename, type });
          form.append("file", { uri: audio.uri, name: filename, type });
        }
        const response = await axios.post(tempGUrl.data.url, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.debug("File uploaded to GCS...", response.status);
        // 3. Make post request back to server let server now that audio has been uploaded
        return client
          .post(`tracks/${trackID}/audio/`)
          .then((resp) => resp.data);
      },
      {
        onSuccess: () => queryClient.invalidateQueries(`track-${trackID}`),
      }
    ),
    error: error,
  };
};

export default useUpdateTrackAudio;
