import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useProfile = (id) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `profile-${id}`,
    () =>
      client.get(`profiles/${id}/`).then((resp) => {
        console.debug(`Profile ${id}`);
        return resp.data;
      }),
    {
      enabled: true,
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
