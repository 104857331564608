import React from "react";
import { Icon, IconButton } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export const AppIconButton = ({
  p = 2,
  onPress,
  iconName,
  size = "lg",
  bg = "dark.900",
  disabled = false,
  borderRadius = "xl",
  as = MaterialCommunityIcons,
  ...props
}) => {
  return (
    <IconButton
      p={p}
      bg={bg}
      {...props}
      onPress={onPress}
      isDisabled={disabled}
      borderRadius={borderRadius}
      _hover={{ bg: "dark.800" }}
      _pressed={{ bg: "dark.800" }}
      _disabled={{ bg: "gray.600" }}
      icon={<Icon color={"lightText"} name={iconName} as={as} size={size} />}
    />
  );
};
