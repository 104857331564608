import React from "react";
import { VStack, HStack, Skeleton } from "native-base";

export interface SkeletonListProps {
  numberOfItems: number;
  fillParent?: boolean;
}

export const AuctionCollectibleListSkeleton = ({
  numberOfItems = 3,
  fillParent = false,
  ...props
}: SkeletonListProps) => {
  const itemHeight = 100 / numberOfItems;
  return (
    <VStack {...props} width={"100%"} height={fillParent ? "100%" : 200}>
      {Array(numberOfItems)
        .fill("")
        .map((_unique, i) => (
          <HStack
            key={i}
            width={"100%"}
            height={`${itemHeight}%`}
            alignItems={"center"}
            justifyContent={"center"}
            marginY={1}
          >
            <Skeleton height={"50%"} flex={1} />
            <Skeleton height={"80%"} flex={0.3} padding={0.8} marginLeft={10} />
          </HStack>
        ))}
    </VStack>
  );
};
