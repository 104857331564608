import React, { useState } from "react";
import { Box, HStack, Text, useColorMode } from "native-base";
import * as Animatable from "react-native-animatable";
// utility functions
import { translate } from "utils/helpers";

export const AuctionListCardSoldOutBadge = ({
  startAnimation = "slideInLeft",
  startAnimationDuration = 10000,
  endAnimation = "slideOutRight",
  endAnimationDuration = 10000,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const [animation, setAnimation] = useState(startAnimation);
  const [animationDuration, setAnimationDuration] = useState(
    startAnimationDuration
  );
  const onAnimationEnd = async () => {
    if (animation == startAnimation) {
      setAnimationDuration(endAnimationDuration);
      setAnimation(endAnimation);
    } else {
      setAnimationDuration(startAnimationDuration);
      setAnimation(startAnimation);
    }
  };

  return (
    <Box {...props}>
      <Box bg={"rgba(0, 0, 0, 0.5)"}>
        <Animatable.View
          delay={0}
          easing="linear"
          iterationDelay={0}
          animation={animation}
          duration={animationDuration}
          onAnimationEnd={onAnimationEnd}
        >
          <HStack justifyContent={"space-between"}>
            {Array(5)
              .fill()
              .map((value, index) => (
                <Text
                  p={1}
                  key={index}
                  width={"100%"}
                  numberOfLines={1}
                  fontWeight="bold"
                  fontSize={["md", "lg"]}
                  color={"white"}
                  textTransform="uppercase"
                >
                  {translate("sold_out")}
                </Text>
              ))}
          </HStack>
        </Animatable.View>
      </Box>
    </Box>
  );
};
