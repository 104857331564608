import React, { useContext, useState } from "react";
import {
  Box,
  FlatList,
  HStack,
  Icon,
  Input,
  Pressable,
  ScrollView,
  Text,
  VStack,
  useMediaQuery,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
// internal functions
import { getWindowWidth, isWeb, translate } from "utils/helpers";
// internal hooks
import { AuthenticationContext } from "services/authentication.context";
import { useCollectibles } from "features/general/collectibles/hooks/useCollectibles";
import { useUserCollectibles } from "features/general/collectibles/hooks/useUserCollectibles";
// internal components
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { CollectibleListImagesCard } from "features/general/collectibles/components/collectible-list-images-card.component";
import { CollectibleListImagesEmpty } from "features/general/collectibles/components/collectible-list-images-empty.component";
import { ListEmptyComponent } from "common/components/layout/list-empty.component";

export const CollectibleListImages = ({
  userID,
  size = 8,
  navigation,
  paginated = true,
  eventCollectibles = false,
}) => {
  const windowWidth = getWindowWidth();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const { userDetails } = useContext(AuthenticationContext);
  const isOtherUserProfile = userDetails?.id !== userID;

  const [search, setSearch] = useState("");

  const collectibles = isOtherUserProfile
    ? useCollectibles({
        size: size,
        owner_id: userID,
        by_product: true,
        title__icontains: search,
        ...(eventCollectibles
          ? { category: "ticket" }
          : { category__not: "ticket" }),
      })
    : useUserCollectibles({
        params: {
          by_product: true,
          title__icontains: search,
          ...(eventCollectibles
            ? { category: "ticket" }
            : { category__not: "ticket" }),
        },
        size: size,
        key: "my-collectibles",
      });

  const dataList = collectibles?.data?.pages.map((page) => page.items).flat();

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <VStack mb={4} space={4}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Text
            fontSize={"2xl"}
            fontWeight={600}
            mt={isOtherUserProfile ? 4 : 0}
          >
            {eventCollectibles ? translate("events") : translate("share")}
          </Text>
          {!isOtherUserProfile && !paginated && (
            <Pressable
              onPress={() =>
                navigation?.navigate("LicenseCollectibles", {
                  screen: "CollectiblesList",
                })
              }
            >
              <Text fontSize={"sm"} color={"gray.500"}>
                {translate("view_all")}
              </Text>
            </Pressable>
          )}
        </HStack>
        <Box px={2} w={isDesktop ? 400 : "100%"}>
          <Input
            h={10}
            value={search}
            variant={"filled"}
            borderRadius={"xl"}
            borderColor={"light.400"}
            placeholder={translate("search")}
            onChangeText={(text) => setSearch(text)}
            InputLeftElement={<Icon ml={3} as={Ionicons} name={"search"} />}
            InputRightElement={
              search && (
                <Pressable pr={3} onPress={() => setSearch("")}>
                  <Icon as={Ionicons} name={"close"} />
                </Pressable>
              )
            }
          />
        </Box>
      </VStack>
      <FlatList
        key={isDesktop ? "desktop-view-collection" : "collection"}
        data={dataList}
        numColumns={isDesktop ? 3 : 2}
        contentContainerStyle={{ flex: 1, alignItems: "center" }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        onEndReached={() =>
          paginated && collectibles.hasNextPage && !collectibles.isFetching
            ? collectibles.fetchNextPage()
            : undefined
        }
        onEndReachedThreshold={0.3}
        renderItem={({ item, index }) => (
          <CollectibleListImagesCard
            key={index}
            navigation={navigation}
            collectibles={item?.collectibles}
            isOtherUserProfile={isOtherUserProfile}
            width={windowWidth / (isDesktop ? 4.25 : 2.5)}
            height={windowWidth / (isDesktop ? 3.25 : 1.75)}
            hasUnclaimedBenefits={item?.has_unclaimed_benefits}
          />
        )}
        ListEmptyComponent={
          collectibles.isFetched ? (
            search ? (
              <Box h={64} justifyContent={"center"}>
                <ListEmptyComponent text={translate("no_results")} />
              </Box>
            ) : (
              <CollectibleListImagesEmpty />
            )
          ) : (
            <Box h={64} justifyContent={"center"}>
              <CenteredSpinner />
            </Box>
          )
        }
        ListFooterComponent={
          collectibles.isFetching &&
          !collectibles.isLoading &&
          paginated && (
            <Box m={2}>
              <CenteredSpinner />
            </Box>
          )
        }
      />
    </ScrollView>
  );
};
