import { useValidateApplePay } from "features/general/payment-methods/hooks/useValidateApplePay";
import { useOrderDetails } from "features/general/orders/hooks/useOrderDetails";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import useCreateOrder from "features/general/orders/hooks/useCreateOrder";
import { useNavigation } from "@react-navigation/native";

export const useCreateApplePaySession = (
  items,
  totalPrice,
  isDelivery = false
) => {
  const { validateApplePay } = useValidateApplePay();
  const { orderDetails } = useOrderDetails();
  const { createOrder } = useCreateOrder();
  const navigation = useNavigation();

  const [session, setSession] = useState(undefined);
  const ApplePaySession = window.ApplePaySession;

  useEffect(() => {
    if (createOrder.data?.data) {
      navigation.navigate("Orders", {
        screen: "OrderDetail",
        params: {
          orderID: createOrder.data?.data?.id,
        },
      });
      const status = createOrder?.data?.data?.status === "successful" ? 0 : 1;
      session?.completePayment({ status: status });
    }
  }, [createOrder.data?.data]);

  return {
    createApplePaySession: useMutation(
      ([paymentMethodID, paymentMethodType]) => {
        if (ApplePaySession) {
          const label = "biddz GmbH";
          const request = {
            countryCode: "DE",
            currencyCode: "EUR",
            supportedNetworks: ["visa", "masterCard"],
            merchantCapabilities: ["supports3DS"],
            total: {
              label: label,
              amount: totalPrice,
              type: "final",
            },
          };
          const session = new ApplePaySession(1, request);
          setSession(session);
          // validate merchant callback
          session.onvalidatemerchant = async (event) => {
            // backend call here to validate merchant
            console.debug(`🗝️ Validation URL ${event.validationURL}`);
            const merchantSession = await validateApplePay.mutateAsync({
              validationURL: event.validationURL,
            });
            session.completeMerchantValidation(merchantSession);
            console.log(`✅ Apple Pay Merchant Validation completed`);
          };
          // session cancelled
          session.oncancel = (event) => {
            console.debug(`🍎 Apple Pay cancelled: ${event}`);
          };
          // session authorized
          session.onpaymentauthorized = async (event) => {
            const paymentData = event?.payment?.token?.paymentData;
            console.debug(`👌 Payment Authorized ${paymentData}`);
            createOrder.mutate({
              items: items,
              secupay_payment_method_id: paymentMethodID,
              secupay_payment_method_type: paymentMethodType,
              payment_data: paymentData,
              is_delivery: isDelivery,
            });
          };
          // session payment method changed
          session.onpaymentmethodselected = async (event) => {
            const details = await orderDetails.mutateAsync(items);
            // update total shown in Apple Pay overlay
            session.completePaymentMethodSelection({
              newTotal: {
                label: label,
                amount: details?.total_price,
                type: "final",
              },
            });
          };
          // begin session
          console.log(`🍎 Starting Apple Pay Session`);
          session.begin();
        }
      }
    ),
  };
};
