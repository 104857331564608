import React, { useContext } from "react";
import { HStack, VStack, Text } from "native-base";
import { AudioSnippetContext } from "services/audio-snippet.context";
import { LoadingSmall } from "common/components/loading/loading-small.component";
import { PlayAudioSnippetButton } from "common/components/buttons/play-audio-snippet-button.component";

export const LicenseProductDetailAlbumListTrack = ({ index, track }) => {
  const { playing, snippetID } = useContext(AudioSnippetContext);
  const isPlaying = playing && snippetID === track.id;
  return (
    <HStack pb={3} justifyContent={"space-between"} alignItems={"center"}>
      <HStack space={10} alignItems={"center"}>
        <Text fontWeight={500} fontSize={"md"}>
          {index + 1}
        </Text>
        <VStack justifyContent={"center"} space={1}>
          <HStack space={2} alignItems={"center"}>
            <Text
              fontWeight={500}
              fontSize={["lg", "xl"]}
              color={isPlaying ? "secondary.600" : undefined}
            >
              {track.title}
            </Text>
            {isPlaying && (
              <LoadingSmall
                pt={0}
                width="1px"
                maxHeight={2}
                color="secondary.600"
              />
            )}
          </HStack>
          <Text
            maxWidth={56}
            numberOfLines={1}
            color={"gray.500"}
            fontSize={["sm", "md"]}
          >
            {track?.primary_musicians?.map((m) => m?.alias).join(", ")}
          </Text>
        </VStack>
      </HStack>
      {track.audio_snippet && (
        <PlayAudioSnippetButton
          size={30}
          id={track.id}
          variant={"outline"}
          uri={track.audio_snippet}
          iconColor={"secondary.600"}
        />
      )}
    </HStack>
  );
};
