import React, { useState } from "react";
import { VStack, ScrollView, Toast, Box, Text } from "native-base";
import { useForm } from "react-hook-form";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
import useCreatePaymentMethod from "features/general/payment-methods/hooks/useCreatePaymentMethod";
import useSetDefaultPayoutMethod from "features/general/payment-methods/hooks/useSetDefaultPayoutMethod";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { MusicianEditPayoutsForm } from "features/accounts/musicians/components/musician-edit-payouts-form.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const MusicianEditPayoutsScreen = ({ navigation }) => {
  const { control, handleSubmit } = useForm();
  const profile = useUserProfile();
  const bankAccount = usePaymentMethods("bank_account");
  const { updateProfile } = useUpdateProfile(profile.data?.id);
  const addPayoutMethod = useCreatePaymentMethod();
  const setDefaultPayoutMethod = useSetDefaultPayoutMethod();

  const [taxable, setTaxable] = useState(
    !profile?.data?.is_small_business_owner
  );
  const isGermanResident = profile?.data?.country_of_residence === `DE`;

  const getParams = (data) => ({
    profile: {
      tax_number: data.tax_number,
      vat_id: data.vat_id,
      is_small_business_owner: !taxable,
    },
    payout: {
      type: "bank_account",
      private: {
        owner: profile.data?.company_name
          ? profile.data?.company_name
          : profile.data?.full_name,
        iban: data.iban,
        bic: data.bic,
      },
    },
  });

  const saveData = async (data) => {
    const params = getParams(data);
    await updateProfile.mutateAsync(params.profile);

    if (profile.data?.full_name && data.iban && !data.iban.includes("*")) {
      const paymentMethod = await addPayoutMethod.mutateAsync(params.payout);
      // After successfully adding, set it as default
      if (paymentMethod?.data) {
        await setDefaultPayoutMethod.mutateAsync(paymentMethod?.data);
      }
    }
  };

  const onPress = async (data) => {
    if (isGermanResident && taxable && !data.tax_number && !data.vat_id) {
      // abort and show toast
      Toast.show({
        placement: "top",
        duration: 5000,
        render: ({ id }) => (
          <Box key={id} p={3} bg={"gray.200"} rounded={"xl"} mx={5} maxW={300}>
            <Text color={"darkText"}>{translate("provide_tax_or_ustid")}</Text>
          </Box>
        ),
      });
    } else {
      await saveData(data);
      navigateBack(navigation);
    }
  };

  const isComplete = profile.data?.is_artist_payout_complete;

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("artist_payout_info")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        p="5"
        mt={{ base: 0, lg: 10 }}
        showsVerticalScrollIndicator={false}
      >
        <VStack space="4">
          <MusicianEditPayoutsForm
            control={control}
            taxable={taxable}
            setTaxable={setTaxable}
            isComplete={isComplete}
            profileData={profile.data}
            payoutData={bankAccount.data}
            isLoading={profile.isLoading || bankAccount.isLoading}
          />

          <PrimaryButton
            nativeID="edit-payout-info"
            isLoading={updateProfile.isLoading || addPayoutMethod.isLoading}
            text={translate("save")}
            onPress={handleSubmit(onPress)}
          />
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
