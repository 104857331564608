import { HStack, Spinner } from "native-base";
import React from "react";

export const CenteredSpinner = ({ color = "light.100" }) => (
  <HStack justifyContent="center" alignItems="center" p={5}>
    <Spinner
      _light={{ color: "darkText" }}
      _dark={{ color: "lightText" }}
      size="lg"
    />
  </HStack>
);
