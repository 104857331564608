import React, { useContext } from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import { translate } from "utils/helpers";
import { ToastAlert } from "theme/feedback/toast-alert.component";

const useCreatePaymentMethod = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation((data) => client.post(`/payment_methods/`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`payment-methods`);
      Toast.show({
        placement: "top",
        render: ({ id }) => (
          <ToastAlert
            id={id}
            status="success"
            description={translate("payment_method_added")}
          />
        ),
      });
    },
  });
};

export default useCreatePaymentMethod;
