import React from "react";
import { VStack, Image } from "native-base";
import * as Animatable from "react-native-animatable";
import { getWindowWidth } from "utils/helpers";

export const ScreenLoading = () => {
  return (
    <VStack flex={1} justifyContent={"center"} alignItems="center">
      <Image
        height={110}
        alt="Biddz Logo"
        resizeMode={"contain"}
        source={require("assets/images/biddz-theme/biddz-logo-extended-white.png")}
      />
    </VStack>
  );
};
