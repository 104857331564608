import React from "react";
import { Box, HStack, useColorMode } from "native-base";
import { TertiaryButton } from "theme/buttons/tertiary-button.component";
import { isWeb } from "utils/helpers";

export const SeeMoreButton = ({ onPress }) => {
  const { colorMode } = useColorMode();
  const backgroundColor =
    colorMode === "light"
      ? isWeb
        ? "lightBackground"
        : "rgba(256,256,256, 0.9)"
      : "darkBackground";
  return (
    <Box
      position={"absolute"}
      height={100}
      bottom={-20}
      left={0}
      right={0}
      bg={{
        linearGradient: {
          colors: [
            backgroundColor,
            backgroundColor,
            backgroundColor,
            isWeb ? "transparent" : backgroundColor,
          ],
          start: [1, 1],
          end: [1, 0],
        },
      }}
    >
      <HStack alignItems={"flex-end"} justifyContent={"center"} mt={3} flex={1}>
        <Box borderColor="secondary.600" borderWidth={1} borderRadius={"xl"}>
          <TertiaryButton nativeID="see-more" text={"Mehr"} onPress={onPress} />
        </Box>
      </HStack>
    </Box>
  );
};
