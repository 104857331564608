import React from "react";
import { VStack, HStack, Text, Divider, Hidden } from "native-base";
import dayjs from "dayjs";
// internal components
import { ProfileSummary } from "features/accounts/profiles/components/profile-summary.component";
// utility functions
import { translate } from "utils/helpers";

const Item = ({ title, value }) => (
  <HStack justifyContent="space-between" space="4">
    <Text fontSize={["md", "lg"]}>{title}</Text>
    <Text maxW={"50%"} fontSize={["md", "lg"]} fontWeight="semibold">
      {value}
    </Text>
  </HStack>
);

export const ReleaseDistributionDetails = ({ release }) => (
  <VStack space="5">
    <Hidden from="lg">
      <Divider />
    </Hidden>
    <VStack
      p="4"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      space="5"
    >
      {release?.ean && <Item title="EAN" value={release?.ean} />}
      <Item title={translate("genre")} value={release?.genre} />
      <Item
        title={translate("contract_term")}
        value={`${release?.distribution_contract_duration} ${translate(
          "years"
        )}`}
      />
      <Item
        title={translate("scheduled_release")}
        value={dayjs(release?.date).format("DD.MM.YYYY")}
      />
      <Text fontSize={["md", "lg"]}>{translate("invoice")}</Text>
      <Text ml="4" fontSize={["md", "lg"]} fontWeight="semibold">
        {translate("invoice_description")}
      </Text>
    </VStack>
    <Divider />
    <ProfileSummary />
    <Divider />
  </VStack>
);
