import React from "react";
import { Flex, Image, Stack, Text, VStack } from "native-base";

export const ProfileDetailHeaderInfo = ({ profile }) => (
  <Stack px={{ base: 3 }} space={3} direction={{ base: "row", lg: "column" }}>
    <Image
      mt={-24}
      size={"xl"}
      alt={"avatar"}
      borderWidth={6}
      borderRadius={"full"}
      resizeMode={"contain"}
      borderColor={"lightBackground"}
      source={
        profile?.image_with_url?.xs?.url
          ? {
              uri: profile?.image_with_url?.xs?.url,
            }
          : require("assets/images/features/profile-picture.png")
      }
    />
    <Text
      bold
      noOfLines={1}
      maxW={[40, 300]}
      fontSize={"2xl"}
      ml={{ base: 0, lg: 5 }}
      mt={{ base: -8, lg: -4 }}
    >
      {profile?.username ? profile.username : `user-${profile?.id?.slice(-4)}`}
    </Text>
  </Stack>
);
