import React, { memo } from "react";
import {
  Divider,
  Stack,
  VStack,
  Text,
  Image,
  useMediaQuery,
} from "native-base";
import { ImageBackground } from "react-native";
import Constants from "expo-constants";
// internal components
import { MusicianListSummary } from "features/accounts/musicians/components/musician-list-summary.component";
import { AuctionCollectibleList } from "features/general/collectibles/components/auction-collectible-list.component";
import { AuctionDetailDateInfo } from "features/general/auctions/components/auction-detail-date-info.component";
import { LicenseProductDetailMainInfo } from "features/royalties/license-products/components/license-product-detail-main-info.component";
import { LicenseProductDetailDescription } from "features/royalties/license-products/components/license-product-detail-description.component";
import { LicenseDetailBenefitList } from "features/royalties/license-benefits/components/license-detail-benefit-list.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { CollectibleCharacterList } from "features/general/collectibles/components/collectible-character-list.component";
import { CollectibleRarityList } from "features/general/collectibles/components/collectible-rarity-list.component";
// utility functions
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";
import { getWindowWidth, translate, isBasicCollectible } from "utils/helpers";
import { TicketProductDetailOptions } from "features/tickets/ticket-products/components/ticket-product-detail-options.component";

export const CollectibleDetailSection = memo(
  ({ title, description, image, invert = false }) => (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      direction={{ base: "column", lg: invert ? "row-reverse" : "row" }}
    >
      <Stack
        w={"100%"}
        flex={{ lg: 0.4 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          width={400}
          height={400}
          source={image}
          resizeMode={"contain"}
          alt={"Collectible Image"}
        />
      </Stack>
      <VStack space={5} flex={{ lg: 0.58 }}>
        {/* <ExpandableTextReadMore title={title} text={description} noOfLines={6}/> */}
        <Text fontSize={["xl", "2xl"]} fontWeight={"black"}>
          {title}
        </Text>
        <Text fontSize={"md"}>{description}</Text>
      </VStack>
    </Stack>
  )
);

// Note: If collectible is not Jan Tenner then remove the hard coded sections.

export const CollectibleDetail = ({ auction }) => {
  const windowWidth = getWindowWidth();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const product = auction?.products[0];
  const isCollectible = isBasicCollectible(auction?.type);
  const isJanTenner = auction?.id === Constants.expoConfig.extra.JAN_TENNER_ID;

  return (
    <VStack space={10}>
      <ImageBackground
        source={
          isJanTenner
            ? require("assets/images/features/jan-tanner/bg-header.jpg")
            : undefined
        }
      >
        <VStack px={5} pt={10} space={5}>
          <Stack
            space={5}
            flexDir={isDesktop ? "row-reverse" : "column"}
            justifyContent={isDesktop ? "space-evenly" : "center"}
          >
            <AuctionDetailImage
              url={product?.picasso_generated_images?.md?.url}
              justifyContent={{ base: "center", lg: "flex-end" }}
            />
            <Stack mt={5} alignItems={"center"} justifyContent={"center"}>
              <VStack
                p={5}
                space={5}
                shadow={5}
                bg={"light.50"}
                borderRadius={"xl"}
              >
                <LicenseProductDetailMainInfo
                  product={product}
                  isCollectible={isCollectible}
                />
                <Divider />
                <AuctionDetailDateInfo auction={auction} />
              </VStack>
            </Stack>
          </Stack>
        </VStack>
      </ImageBackground>

      <VStack my={5} px={isDesktop ? 24 : 5} space={10}>
        <Stack direction={isDesktop ? "row-reverse" : "column"}>
          <VStack ml={isDesktop ? 24 : 0} flex={1}>
            <TicketProductDetailOptions
              maxW={550}
              isCollectible
              auction={auction}
            />
            {!isDesktop && <Divider my={10} maxW={550} />}
          </VStack>
          <VStack flex={isDesktop ? 1 : undefined} space={10}>
            <LicenseProductDetailDescription
              isCollectible={isCollectible}
              description={product?.description}
            />
            <MusicianListSummary
              imgStyle={{
                height: isDesktop ? windowWidth * 0.12 : 200,
                width: isDesktop ? windowWidth * 0.12 : windowWidth * 0.9,
              }}
              musicians={auction?.musicians}
            />
          </VStack>
        </Stack>
        <Divider />
        {product?.benefits?.length > 0 && (
          <LicenseDetailBenefitList
            benefits={product?.benefits}
            isBasicCollectible={isCollectible}
          />
        )}
        <Divider />
        <CollectibleDetailSection
          description={auction?.description}
          title={translate("about_collection")}
          image={require("assets/images/features/jan-tanner/group.png")}
        />

        {isJanTenner && (
          <>
            <CollectibleCharacterList />

            <CollectibleDetailSection
              title={translate("structure_of_collectibles")}
              description={translate("structure_of_collectibles_description")}
              image={require("assets/images/features/jan-tanner/card-detail.png")}
              invert
            />

            <CollectibleRarityList />
          </>
        )}
        <AuctionCollectibleList isCollectible auctionID={auction.id} />
      </VStack>
    </VStack>
  );
};
