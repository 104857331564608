import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { MusicianDetailScreen } from "features/accounts/musicians/screens/musician-detail.screen";
import { MusicianEditPersonalDataScreen } from "features/accounts/musicians/screens/musician-edit-personal-data.screen";
import { MusicianEditProfileScreen } from "features/accounts/musicians/screens/musician-edit-profile.screen";
import { MusicianListScreen } from "features/accounts/musicians/screens/musician-list.screen";
import { AddMusicianToTrackScreen } from "features/accounts/musicians/screens/add-musician-to-track.screen";
import { MusicianEditSummaryScreen } from "features/accounts/musicians/screens/musician-edit-summary.screen";
import { MusicianEditPayoutsScreen } from "features/accounts/musicians/screens/musician-edit-payouts.screen";
const Stack = createStackNavigator();

export const MusiciansNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="MusicianEditProfile"
        component={MusicianEditProfileScreen}
      />
      <Stack.Screen
        name="MusicianEditPersonalData"
        component={MusicianEditPersonalDataScreen}
      />
      <Stack.Screen
        name="AddMusicianToTrack"
        component={AddMusicianToTrackScreen}
      />
      <Stack.Screen name="MusicianDetail" component={MusicianDetailScreen} />
      <Stack.Screen name="MusicianList" component={MusicianListScreen} />
      <Stack.Screen
        name="MusicianEditSummary"
        component={MusicianEditSummaryScreen}
      />
      <Stack.Screen
        name="MusicianEditPayouts"
        component={MusicianEditPayoutsScreen}
      />
    </Stack.Navigator>
  );
};
