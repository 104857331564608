import React, { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Image,
  Text,
  Divider,
  Checkbox,
  Modal,
  Pressable,
  Icon,
  ScrollView,
} from "native-base";
import { useForm } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
//internal components
import {
  getBenefitDescriptionParsed,
  getBenefitIconFromTier,
  getBenefitTitleParsed,
  translate,
} from "utils/helpers";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { CopyTextButton } from "theme/buttons/copy-text-button.component";
import { useUpdateBenefitCollectable } from "features/general/auctions/hooks/useUpdateBenefitCollectable";
import { Input } from "theme/forms/input.component";

const getTypeDetails = (type) => {
  switch (type) {
    case "tiktok_follow":
      return {
        header: translate("tiktok_header"),
        consent: translate("instagram_consent"),
      };

    case "custom":
      return {
        consent: translate("custom_consent"),
      };

    case "credit_in_music_video":
      return {
        header: translate("credit_in_music_video_header"),
        consent: translate("instagram_consent"),
      };

    case "event_guest_list":
      return {
        header: translate("event_guest_list_header"),
        consent: translate("event_guest_list_consent"),
      };
    case "bonus_content":
      return {
        header: translate("bonus_content_header"),
        consent: translate("bonus_content_consent"),
      };
    case "promo_code":
      return {
        header: translate("promo_code_header"),
        consent: translate("bonus_content_consent"),
      };

    default:
      return {
        header: translate("instagram_header"),
        consent: translate("instagram_consent"),
      };
  }
};

export const LicenseBenefitModal = ({
  benefit,
  isOpen,
  setIsOpen,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={setIsOpen}>
      <VStack mx={5} pb={5} w={350} rounded={"2xl"} bg={"light.100"}>
        <HStack
          px={5}
          py={3}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text bold fontSize={"2xl"} textTransform={"capitalize"}>
            {`${benefit?.tier} Benefit`}
          </Text>
          <Pressable p={2} onPress={() => setIsOpen(false)}>
            <Icon as={Ionicons} name={"close-outline"} size={6} />
          </Pressable>
        </HStack>
        <Divider bg={"gray.300"} />
        <VStack p={5} pb={0}>
          <ScrollView
            mb={5}
            showsVerticalScrollIndicator={false}
            maxH={benefit?.image_with_url?.md?.url ? 64 : 32}
          >
            <VStack space={2}>
              <Image
                size={10}
                alt={"tier"}
                source={getBenefitIconFromTier(benefit?.tier)}
              />
              <HStack space={2} alignItems={"center"}>
                <Text bold fontSize={"md"} textTransform={"capitalize"}>
                  {getBenefitTitleParsed(benefit)}
                </Text>
              </HStack>
              <Text>{getBenefitDescriptionParsed(benefit)}</Text>
              {benefit?.image_with_url?.md?.url && (
                <Image
                  h={64}
                  w={"full"}
                  rounded={"xl"}
                  borderWidth={2}
                  alt={"benefit"}
                  borderColor={"gray.100"}
                  source={{ uri: benefit?.image_with_url?.md?.url }}
                />
              )}
            </VStack>
          </ScrollView>
          {children}
        </VStack>
      </VStack>
    </Modal>
  );
};

export const ClaimLicenseBenefitModal = ({
  benefit,
  isOpen,
  setIsOpen,
  benefitType,
}) => {
  const { control, handleSubmit } = useForm();
  const typeDetail = getTypeDetails(benefit?.type);
  const { updateBenefitCollectable } = useUpdateBenefitCollectable(benefit);

  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    if (!benefit?.claimed_at)
      updateBenefitCollectable.mutate({ user_input: data?.user_input });
  };

  useEffect(() => {
    updateBenefitCollectable.isSuccess && setIsOpen(false);
  }, [updateBenefitCollectable.isSuccess]);

  return (
    <LicenseBenefitModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      benefit={benefit?.benefit}
    >
      {benefitType?.requires_artist_input ? (
        benefit?.benefit?.artist_input && (
          <VStack space={4}>
            <Input
              isDisabled
              height={10}
              size={"2xl"}
              control={control}
              variant={"outline"}
              name={"artist_input"}
              value={benefit?.benefit?.artist_input}
            />
            <CopyTextButton
              onPress={onSubmit}
              text={benefit?.benefit?.artist_input}
              nativeID={"user-claim-benefit-artist-input"}
              isLoading={updateBenefitCollectable.isLoading}
            />
          </VStack>
        )
      ) : (
        <VStack space={4}>
          {benefitType?.requires_user_input && (
            <>
              <Text textAlign={"center"} fontSize={["sm", "md"]}>
                {typeDetail?.header}
              </Text>
              <Input
                height={10}
                size={"2xl"}
                control={control}
                variant={"outline"}
                name={"user_input"}
                rules={{ required: true }}
                value={benefit?.user_input}
                isDisabled={!!benefit?.claimed_at}
              />
            </>
          )}
          {!benefit?.claimed_at && (
            <>
              <Checkbox
                value={"agree_benefit_claim"}
                onChange={(value) => setIsChecked(value)}
                isChecked={isChecked || !!benefit?.claimed_at}
              >
                <Text fontSize={"xs"} maxW={"90%"}>
                  {typeDetail?.consent}
                </Text>
              </Checkbox>
              <PrimaryButton
                m={4}
                fontSize={"md"}
                disabled={!isChecked}
                text={translate("redeem")}
                onPress={handleSubmit(onSubmit)}
                nativeID={"user-claim-benefit-input"}
                isLoading={updateBenefitCollectable.isLoading}
              />
            </>
          )}
        </VStack>
      )}
    </LicenseBenefitModal>
  );
};

export const IgnoreLicenseBenefitModal = ({ benefit, isOpen, setIsOpen }) => {
  const { updateBenefitCollectable } = useUpdateBenefitCollectable(benefit);

  const onIgnore = () => {
    updateBenefitCollectable.mutate({ status: "rejected" });
  };

  useEffect(() => {
    updateBenefitCollectable.isSuccess && setIsOpen(false);
  }, [updateBenefitCollectable.isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={setIsOpen}>
      <VStack
        p={5}
        mx={5}
        space={2}
        maxW={300}
        rounded={"2xl"}
        bg={"light.100"}
      >
        <Pressable
          p={2}
          alignSelf={"flex-end"}
          onPress={() => setIsOpen(false)}
        >
          <Icon as={Ionicons} name={"close-outline"} size={6} />
        </Pressable>
        <Text textAlign={"center"}>{translate("confirm_ignore_benefit")}</Text>
        <Pressable
          p={3}
          rounded={"full"}
          onPress={onIgnore}
          bg={"secondary.300"}
          alignItems={"center"}
        >
          <Text>{translate("yes")}</Text>
        </Pressable>
        <Pressable
          p={3}
          bg={"gray.100"}
          rounded={"full"}
          alignItems={"center"}
          onPress={() => setIsOpen(false)}
        >
          <Text>{translate("cancel")}</Text>
        </Pressable>
      </VStack>
    </Modal>
  );
};
