import React from "react";
import { VStack } from "native-base";
// internal
import { isWeb } from "utils/helpers";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { LicenseCollectibleBenefitList } from "features/royalties/license-benefits/components/license-collectible-benefit-list.component";

export const LicenseCollectibleBenefitsListScreen = ({ navigation }) => {
  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationPanel goesBack={!isWeb} />
      <VStack flex={1} px={5}>
        <LicenseCollectibleBenefitList navigation={navigation} />
      </VStack>
    </ScreenFrame>
  );
};
