import React from "react";
import { FormControl, HStack, VStack } from "native-base";

//internal components
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";

export const SkeletonFormControlInput = ({
  children,
  label,
  helperText = null,
  isLoading = false,
  splitSkeleton = false,
  height = 24,
}) => {
  return isLoading ? (
    <VStack h={height} width={"100%"}>
      <FormControl mb={3}>
        <FormControl.Label>{label}</FormControl.Label>
      </FormControl>
      {splitSkeleton ? (
        <HStack flex={1} w={"100%"} py={2} justifyContent={"space-evenly"}>
          {Array(3)
            .fill("")
            .map((_unique, i) => {
              return (
                <SkeletonRounded key={i} mx={2} height={"80%"} width={"30%"} />
              );
            })}
        </HStack>
      ) : (
        <SkeletonRounded height={"35%"} width={"100%"} />
      )}
      {helperText && (
        <FormControl>
          <FormControl.HelperText>{helperText}</FormControl.HelperText>
        </FormControl>
      )}
    </VStack>
  ) : (
    children
  );
};
