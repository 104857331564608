import React, { useContext, useState } from "react";
import { Popover, HStack, VStack, Pressable, Text, Divider } from "native-base";
// internal hooks
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
// internal components
import { UserAvatar } from "theme/media/user-profile-avatar.component";
// helper functions
import { translate } from "utils/helpers";
import { UserLogoutButton } from "features/accounts/users/components/user-logout-button.component";
import { AuthenticationContext } from "services/authentication.context";

export const AccountDropdown = ({ navigation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const profile = useUserProfile();
  const musician = useUserMusician();
  const { onLogout } = useContext(AuthenticationContext);

  const onAccountLogout = () => {
    setIsOpen(false);
    onLogout();
  };

  return (
    <Popover
      trigger={(triggerProps) => (
        <Pressable
          {...triggerProps}
          onPress={() => {
            profile?.data?.id
              ? setIsOpen(true)
              : navigation.navigate("Users", { screen: "Login" });
          }}
        >
          <UserAvatar
            p={1}
            size={12}
            uri={
              profile?.data?.image_with_url?.md?.url ||
              require("assets/images/features/profile-picture.png")
            }
            alt={"profile"}
          />
        </Pressable>
      )}
      isOpen={isOpen}
      onClose={() => setIsOpen(!isOpen)}
    >
      <Popover.Content
        mt={6}
        shadow={5}
        borderWidth={0}
        accessibilityLabel={""}
      >
        <Popover.Body bg={"light.100"}>
          <VStack>
            <Pressable
              onPress={() => {
                setIsOpen(false);
                navigation.navigate("Profiles", {
                  screen: "ProfileEdit",
                  params: { id: profile?.data?.id },
                });
              }}
            >
              <HStack space={4} alignItems={"center"}>
                <UserAvatar
                  p={1}
                  size={12}
                  alt={"profile"}
                  uri={
                    musician?.data?.image_with_url?.md?.url ||
                    require("assets/images/features/profile-picture.png")
                  }
                />
                <VStack>
                  {profile?.data?.username && (
                    <Text
                      maxW={32}
                      isTruncated
                      fontSize={"md"}
                      fontWeight={500}
                      selectable={false}
                    >
                      Hallo, {profile?.data?.username}!
                    </Text>
                  )}
                  <Text
                    maxW={32}
                    isTruncated
                    fontSize={"xs"}
                    color={"gray.400"}
                    selectable={false}
                  >
                    {profile?.data?.user?.firebase_uid}
                  </Text>
                </VStack>
              </HStack>
            </Pressable>
            <Divider my={1} bg={"gray.200"} />
            {!profile?.isLoading && profile?.data?.type === "artist" && (
              <>
                <Pressable
                  py={2}
                  onPress={() => {
                    setIsOpen(false);
                    navigation?.navigate("Musicians", {
                      screen: "MusicianDetail",
                      params: { id: musician?.data?.id },
                    });
                  }}
                >
                  <HStack space={4} alignItems={"center"}>
                    <UserAvatar
                      p={"1"}
                      size={10}
                      alt={"musician"}
                      uri={
                        musician?.data?.image_with_url?.md?.url ||
                        require("assets/images/features/profile-picture.png")
                      }
                    />
                    <Text fontSize={"md"} fontWeight={500}>
                      {translate("my_artist_profile")}
                    </Text>
                  </HStack>
                </Pressable>
                <Divider my={1} bg={"gray.200"} />
              </>
            )}
            <VStack py={2}>
              <UserLogoutButton onLogout={onAccountLogout} fontSize={"md"} />
            </VStack>
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
