import React from "react";
import { VStack, Text } from "native-base";
import { findFocusedRoute } from "@react-navigation/native";

// internal
import { isWeb, translate } from "utils/helpers";
import { PrimaryButton } from "theme/buttons/primary-button.component";

export const AuthenticationRequired = ({
  navigation,
  setShowAuthModal,
  setRemoteURL,
  ...props
}) => {
  return (
    <VStack {...props} space={5} p={10} justifyContent="center">
      <Text textAlign="center">{translate("sign_in_required")}</Text>
      <PrimaryButton
        text={translate("sign_in")}
        onPress={() => {
          setShowAuthModal(false);
          if (isWeb) {
            if (window.location.pathname !== "/") {
              const search = window.location.search
                ? window.location.search
                : "";
              setRemoteURL(window.location.pathname + search);
            }
          } else {
            setRemoteURL(findFocusedRoute(navigation?.getState()));
          }
          navigation.navigate("Users", {
            screen: "Login",
          });
        }}
      />
    </VStack>
  );
};
