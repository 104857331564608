import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateTrackContributor = (trackID, contributorID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateTrackContributor: useMutation(
      (data) =>
        client
          .patch(`tracks/${trackID}/contributors/${contributorID}/`, data)
          .then((res) => res.data),
      {
        onSuccess: () => queryClient.invalidateQueries(`track-${trackID}`),
      }
    ),
    error: error,
  };
};

export default useUpdateTrackContributor;
