import React, { useState } from "react";
import { HStack, VStack } from "native-base";
import { Animated, Easing, Dimensions } from "react-native";
import {
  GestureHandlerRootView,
  PanGestureHandler,
} from "react-native-gesture-handler";
// internal components
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";

export const InteractiveImage = ({
  imageURL,
  height = 350,
  width = 250,
  ...props
}) => {
  const screenHeight = Dimensions.get("window").height;
  const [isLoading, setIsLoading] = useState(true);
  const [rotateXFrom, setRotateXFrom] = useState(0);
  const [rotateXTo, setRotateXTo] = useState(0);
  const rotateXAnimated = new Animated.Value(0);
  Animated.timing(rotateXAnimated, {
    toValue: 1,
    duration: 500,
    easing: Easing.linear,
    useNativeDriver: false,
  }).start();
  const rotateX = rotateXAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [`${rotateXFrom}deg`, `${rotateXTo}deg`],
  });

  const [rotateYFrom, setRotateYFrom] = useState(0);
  const [rotateYTo, setRotateYTo] = useState(0);
  const rotateYAnimated = new Animated.Value(0);
  Animated.timing(rotateYAnimated, {
    toValue: 1,
    duration: 500,
    easing: Easing.linear,
    useNativeDriver: false,
  }).start();
  const rotateY = rotateYAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [`${rotateYFrom}deg`, `${rotateYTo}deg`],
  });

  const rotate = (event) => {
    const halfHeight = Math.round(height / 2);
    const halfWidth = Math.round(width / 2);

    const y = Math.round(
      Math.min(Math.max(event.nativeEvent.y, -1), height + 1)
    );
    const x = Math.round(
      Math.min(Math.max(event.nativeEvent.x, -1), width + 1)
    );

    setRotateXFrom(rotateXTo);
    setRotateXTo(-(y - halfHeight) / 4);
    setRotateYFrom(rotateYTo);
    setRotateYTo((x - halfWidth) / 4);
  };

  const reset = () => {
    setRotateXFrom(rotateXTo);
    setRotateXTo(0);
    setRotateYFrom(rotateYTo);
    setRotateYTo(0);
  };

  return (
    <GestureHandlerRootView>
      <PanGestureHandler onGestureEvent={rotate} onEnded={reset}>
        <HStack justifyContent={"center"} {...props}>
          <Animated.Image
            resizeMode="contain"
            alt={"Card"}
            source={{ uri: imageURL }}
            style={{
              transform: [
                {
                  perspective: 1000,
                },
                {
                  rotateY: rotateY,
                },
                {
                  rotateX: rotateX,
                },
              ],
              height: height,
              width: width,
              maxHeight: screenHeight * 0.6,
            }}
            onLoadEnd={() => {
              setIsLoading(false);
            }}
          />
          {isLoading && (
            <VStack
              height={height}
              width={width}
              justifyContent="center"
              ml={-width}
              pt={-height}
              py={4}
              px={2}
            >
              <CenteredSpinner />
            </VStack>
          )}
        </HStack>
      </PanGestureHandler>
    </GestureHandlerRootView>
  );
};
