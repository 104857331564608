import React from "react";
import { VStack, HStack, Skeleton } from "native-base";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";

export const SkeletonListHorizontal = ({
  numberOfItems = 3,
  numberOfTextLines = 1,
  itemWidth = 40,
  color = "skeleton.dark",
  ...props
}) => (
  <HStack {...props} h={150} w={"100%"} overflow={"hidden"}>
    {Array(numberOfItems)
      .fill("")
      .map((_unique, i) => (
        <VStack key={i} marginX={5} w={itemWidth}>
          <Skeleton flex={2} startColor={color} />
          <SkeletonTextVertical flex={0.5} numberOfLines={numberOfTextLines} />
        </VStack>
      ))}
  </HStack>
);
