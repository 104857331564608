import React, { memo } from "react";
import { HStack, VStack, Text, Image } from "native-base";
import dayjs from "dayjs";
import { translate } from "utils/helpers";

export const TicketProductDetailVenue = memo(({ entryTime }) =>
  entryTime ? (
    <HStack
      h={24}
      px={5}
      space={5}
      borderWidth={1}
      borderRadius={"lg"}
      alignItems={"center"}
      borderColor={"gray.200"}
    >
      <Image
        size={6}
        alt={"open-door"}
        source={require("assets/images/features/open-door.png")}
      />
      <VStack>
        <Text fontSize={["lg", "xl"]} fontWeight={500}>
          {translate("venue")}
        </Text>
        <Text fontSize={["md", "lg"]} color={"light.800"}>
          {translate("open_gate", {
            time: dayjs(entryTime).format("HH:mm"),
          })}
        </Text>
      </VStack>
    </HStack>
  ) : undefined
);
