import React, { useContext } from "react";
import { Divider, HStack, ScrollView, Text, VStack } from "native-base";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { AuthenticationContext } from "services/authentication.context";
import { translate, navigateBack } from "utils/helpers";

export const UserDetailScreen = ({ navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);
  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("security")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        flex={1}
        p={5}
      >
        <VStack space={3} pb={10}>
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <VStack space={2}>
              <Text fontSize={["md", "lg"]}>{translate("phone_number")}</Text>
              <Text color="#737277">{userDetails?.phone_number}</Text>
            </VStack>
          </HStack>
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
