import React from "react";
import { ScrollView, VStack, Text } from "native-base";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { TrackEditHeader } from "features/royalties/tracks/components/track-edit-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { TrackMasterRights } from "features/royalties/tracks/components/track-master-rights.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditShareScreen = ({ navigation, route }) => {
  const {
    trackID,
    releaseID,
    releasePartials = {},
    trackPartial = {},
  } = route.params;
  const { data: track, isFetched: trackFetched } = useTrack(
    trackID,
    trackPartial
  );
  const { data: release, isFetched: releaseFetched } = useRelease(
    releaseID,
    releasePartials
  );

  const onPress = () => {
    navigation.navigate("Releases", {
      screen: "ReleaseEditTracks",
      params: { id: release.id, releasePartial: release },
    });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <TrackEditHeader step="5" release={release} track={track} />
      <VStack mb={[0, 5]} flex={1}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "space-between",
          }}
        >
          <PageTitle
            title={translate("split_share")}
            helpText={translate("split_share_help_text")}
          />
          {!trackFetched && !releaseFetched ? (
            <Loading />
          ) : (
            <>
              <TrackMasterRights
                navigation={navigation}
                track={track}
                release={release}
              />
              <Text
                mt={[10, 24]}
                p="5"
                fontSize="md"
                color="light.700"
                textAlign="center"
              >
                {translate("split_share_help_text_bottom")}
              </Text>
            </>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        text={translate("save")}
        onPress={onPress}
      />
    </ScreenFrame>
  );
};
