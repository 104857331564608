import React, { useContext, useEffect } from "react";
import Constants from "expo-constants";
import {
  VStack,
  HStack,
  ScrollView,
  Box,
  useMediaQuery,
  Image,
} from "native-base";
// internal hooks
import { useProduct } from "features/general/products/hooks/useProduct";
import { useAuction } from "features/general/auctions/hooks/useAuction";
import { useCollectible } from "features/general/collectibles/hooks/useCollectible";
import { AuthenticationContext } from "services/authentication.context";
import { useUserCollectibles } from "features/general/collectibles/hooks/useUserCollectibles";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { TicketCollectibleDetailEvent } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-event.component";
import { TicketCollectibleQRCodeButton } from "features/tickets/ticket-collectibles/components/ticket-collectible-qr-code-button.component";
import { TicketCollectibleDetailMyTickets } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-my-tickets.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
//helpers
import { isWeb } from "utils/helpers";

export const TicketCollectibleDetailScreen = ({ route, navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);
  const { id, initialData } = route.params;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  // This is very bad code!
  const { data: collectible, isFetched: collectibleFetched } = useCollectible(
    id,
    initialData
  );

  const params = { product_id: collectible?.product_id };
  const { data: collectibles, isFetched: collectiblesFetched } =
    useUserCollectibles({
      params,
      size: 50,
      enabled: !!collectible?.product_id,
      key: collectible?.product_id,
    });
  const productCollectibles = collectibles?.pages
    ?.map((page) => page.items)
    ?.flat();

  const { data: product, isFetched: productFetched } = useProduct(
    collectible?.product_id
  );

  const { data: auction, isFetched: auctionFetched } = useAuction(
    product?.auction_id
  );

  const isDardan = product?.auction_id === Constants.expoConfig.extra.DARDAN_ID;

  useEffect(() => {
    if (userDetails && collectibleFetched) {
      if (userDetails?.id !== collectible?.owner_id) {
        console.debug(`❌ User is not the Collectible's Owner`);
        navigation.navigate("BottomNavigator", {
          screen: "Home",
        });
      }
    }
  }, [userDetails, collectibleFetched]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationPanel goesBack={!isWeb} />
      <ScrollView
        height={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {collectibleFetched &&
        productFetched &&
        auctionFetched &&
        collectiblesFetched ? (
          <VStack space={{ base: 0, lg: 5 }}>
            <Box
              pt={4}
              pb={10}
              bg={"gray.100"}
              alignItems={"center"}
              justifyContent={"center"}
              position={"relative"}
            >
              <AuctionDetailImage
                url={collectible?.image_with_url?.lg?.url}
                justifyContent={{ base: "center", lg: "flex-end" }}
              />
              <Box
                position={"absolute"}
                bottom={{ base: 5, lg: 10 }}
                right={{ base: 5, lg: 32 }}
              >
                {!isDardan &&
                  collectible?.ticket?.has_access &&
                  productCollectibles?.length > 0 && (
                    <TicketCollectibleQRCodeButton
                      pressableChildren={
                        <Box p={4} bg={"darkBackground"} borderRadius={"xl"}>
                          <Image
                            size={6}
                            alt={"QR Code Symbol"}
                            source={require("assets/images/icons/tickets/qr-code-white.png")}
                          />
                        </Box>
                      }
                      collectibles={productCollectibles}
                    />
                  )}
              </Box>
            </Box>

            <HStack px={isDesktop ? 24 : 5}>
              <Box flex={1}>
                <TicketCollectibleDetailEvent
                  isDardan={isDardan}
                  collectible={collectible}
                  collectibles={productCollectibles}
                  event={
                    auction?.events?.length > 0 ? auction?.events[0] : null
                  }
                  auction={auction}
                />
              </Box>
              {isDesktop && productCollectibles?.length > 0 && (
                <VStack ml={10} flex={1} alignItems={"center"}>
                  <TicketCollectibleDetailMyTickets
                    isDardan={isDardan}
                    collectible={collectible}
                    collectibles={productCollectibles}
                    event={
                      auction?.events?.length > 0 ? auction?.events[0] : null
                    }
                  />
                </VStack>
              )}
            </HStack>
          </VStack>
        ) : (
          <CenteredSpinner />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
