import React, { useEffect, useState } from "react";
import {
  Divider,
  HStack,
  Icon,
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { Dimensions, Platform } from "react-native";
import { BlurView } from "expo-blur";
// internal components
import { LicenseProductOrderCreateForm } from "features/royalties/license-product-orders/components/license-product-order-create-form.component";
import { LicenseProductOrderCreateConfirmation } from "features/royalties/license-product-orders/components/license-product-order-create-confirmation.component";
// internal hooks
import {
  getDefaultUnitsFromProduct,
  navigateBack,
  translate,
} from "utils/helpers";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
import { useAuction } from "features/general/auctions/hooks/useAuction";

export const LicenseProductOrderCreateScreen = ({ navigation, route }) => {
  const { auctionID, auctionPartials } = route.params;
  const windowHeight = Dimensions.get("window").height;
  const auction = useAuction(auctionID, auctionPartials);
  const product = auction?.data?.products && auction?.data?.products[0];

  const isFirstRouteInParent = useIsFirstRouteInParent();
  const defaultUnits = getDefaultUnitsFromProduct(product);

  const [units, setUnits] = useState(defaultUnits);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.replace("Auctions", {
        screen: "AuctionDetail",
        params: { id: auctionID },
      });
    }
  }, []);

  return (
    <VStack flex={1}>
      <KeyboardAvoidingView
        flex={1}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <BlurView
          tint={"dark"}
          reducedTransparencyFallbackColor="white"
          intensity={15}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pressable size={"full"} onPress={() => navigateBack(navigation)} />
          <VStack
            mx={4}
            maxW={"90%"}
            rounded={"2xl"}
            bg={"light.100"}
            alignSelf={"center"}
            position={"absolute"}
          >
            <HStack
              px={5}
              py={3}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"2xl"} bold>
                {showPreview
                  ? translate("order_summary")
                  : translate("buy_track_biddz")}
              </Text>
              <Pressable p={2} onPress={() => navigateBack(navigation)}>
                <Icon as={Ionicons} name={"close-outline"} size={6} />
              </Pressable>
            </HStack>
            <Divider bg={"gray.300"} />
            <VStack px={5} maxW={450}>
              <ScrollView
                maxH={windowHeight * 0.7}
                showsVerticalScrollIndicator={false}
              >
                {showPreview ? (
                  <LicenseProductOrderCreateConfirmation
                    units={units}
                    product={product}
                    musicians={auction?.data?.musicians}
                  />
                ) : (
                  <LicenseProductOrderCreateForm
                    units={units}
                    product={product}
                    setUnits={setUnits}
                    auction={auction?.data}
                    setShowPreview={setShowPreview}
                  />
                )}
              </ScrollView>
            </VStack>
          </VStack>
        </BlurView>
      </KeyboardAvoidingView>
    </VStack>
  );
};
