import React from "react";
import { VStack, Text } from "native-base";
import { useWatch } from "react-hook-form";
// internal components
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlTextarea } from "theme/forms/form-control-textarea.component";
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// helpers
import { translate } from "utils/helpers";

export const MusicianEditProfileForm = ({
  profileData,
  musicianData,
  control,
  isLoading,
}) => {
  const isDraftApplication = musicianData?.status === `draft`;
  const isSplitOnlyRegistration = useWatch({
    control,
    name: "is_splits_only_registration",
    defaultValue: true,
  });

  return (
    <ResponsiveStack space="10">
      <VStack
        p={5}
        space={5}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
        flex={{ lg: 1 }}
      >
        <BooleanCheckbox
          value={true}
          control={control}
          isDisabled={true}
          name="is_splits_only_registration"
        >
          <Text ml="2">{translate("no_artist_profile_description")}</Text>
        </BooleanCheckbox>

        <SkeletonFormControlInput
          label={translate("artist_name")}
          isLoading={isLoading}
        >
          <FormControlInput
            name={"alias"}
            label={
              !isSplitOnlyRegistration
                ? translate("artist_name")
                : translate("full_or_artist_name")
            }
            control={control}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            placeholder={translate("your_alias")}
            value={musicianData?.alias}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("spotify_id")}
          isLoading={isLoading}
        >
          <FormControlInput
            name={"spotify_id"}
            label={translate("spotify_id")}
            control={control}
            rules={{ required: !isSplitOnlyRegistration }}
            isDisabled={!isDraftApplication}
            placeholder={translate("spotify_id")}
            value={musicianData?.spotify_id}
            infoText={translate("spotify_id_info")}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("apple_id")}
          isLoading={isLoading}
        >
          <FormControlInput
            name={"apple_id"}
            label={translate("apple_id")}
            control={control}
            rules={{ required: !isSplitOnlyRegistration }}
            isDisabled={!isDraftApplication}
            placeholder={translate("apple_id")}
            value={musicianData?.apple_id}
            infoText={translate("apple_id_info")}
          />
        </SkeletonFormControlInput>
      </VStack>
      <VStack
        p={5}
        space={5}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
        flex={{ lg: 1 }}
      >
        <SkeletonFormControlInput
          label={translate("biography")}
          isLoading={isLoading}
          height={96}
        >
          <FormControlTextarea
            name={"bio"}
            label={translate("biography")}
            control={control}
            rules={{
              required: !isSplitOnlyRegistration,
              maxLength: 2048,
            }}
            isDisabled={!isDraftApplication}
            placeholder={translate("biography_placeholder_text")}
            value={profileData?.bio}
            infoText={translate("biography_info")}
          />
        </SkeletonFormControlInput>
      </VStack>
      <VStack
        p={5}
        space={5}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
        flex={{ lg: 1 }}
      >
        <SkeletonFormControlInput label={"Instagram"} isLoading={isLoading}>
          <FormControlInput
            name={"instagram"}
            label={"Instagram"}
            control={control}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            placeholder={translate("instagram_placeholder_text")}
            value={profileData?.socials?.instagram?.username}
            infoText={translate("instagram_info")}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput label={"Twitter"} isLoading={isLoading}>
          <FormControlInput
            name={"twitter"}
            label={"Twitter"}
            control={control}
            isDisabled={!isDraftApplication}
            placeholder={"www.twitter.com/"}
            value={profileData?.socials?.twitter?.username}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput label={"Tiktok"} isLoading={isLoading}>
          <FormControlInput
            name={"tiktok"}
            label={"TikTok"}
            control={control}
            isDisabled={!isDraftApplication}
            placeholder={"www.tiktok.com/"}
            value={profileData?.socials?.tiktok?.username}
          />
        </SkeletonFormControlInput>
      </VStack>
    </ResponsiveStack>
  );
};
