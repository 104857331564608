import React from "react";
import { Platform } from "react-native";
import { Dimensions } from "react-native";
import {
  Pressable,
  VStack,
  HStack,
  KeyboardAvoidingView,
  useMediaQuery,
} from "native-base";
import { navigateBack } from "utils/helpers";

export const ModalFrame = ({ transparentFlex = 1, navigation, children }) => {
  const windowHeight = Dimensions.get("window").height;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  return (
    <HStack flex={1} maxH={windowHeight}>
      {isDesktop && (
        <Pressable w={300} onPress={() => navigateBack(navigation)} />
      )}
      <VStack flex={1}>
        <KeyboardAvoidingView
          flex={1}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <Pressable
            flex={transparentFlex}
            onPress={() => navigateBack(navigation)}
          />
          {children}
        </KeyboardAvoidingView>
      </VStack>
    </HStack>
  );
};
