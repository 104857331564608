import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useBookmarkAuction = () => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    addBookmark: useMutation((auction) =>
      client.post(`auctions/${auction.id}/bookmark/`).then((res) => {
        console.debug(`💰 Auction ${auction.id} bookmarked`);
        queryClient.invalidateQueries(`auctions`);
        queryClient.invalidateQueries(`auction-${auction.id}`);
        return res.data;
      })
    ),
    removeBookmark: useMutation((auction) =>
      client.delete(`auctions/${auction.id}/bookmark/`).then((res) => {
        console.debug(`💰 Auction ${auction.id} unbookmarked`);
        queryClient.invalidateQueries(`auctions`);
        queryClient.invalidateQueries(`auction-${auction.id}`);
        return res.data;
      })
    ),
    error: error,
  };
};

export default useBookmarkAuction;
