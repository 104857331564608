import React, { useEffect } from "react";
import { ScrollView } from "native-base";
import { useForm } from "react-hook-form";
// hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateRelease from "features/royalties/releases/hooks/useUpdateRelease";
import useDeleteRelease from "features/royalties/releases/hooks/useDeleteRelease";
// components
import { ReleaseForm } from "features/royalties/releases/components/release-form.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseEditScreen = ({ navigation, route }) => {
  const { id: releaseId, releasePartial = {} } = route.params;
  const { data: release, isFetched } = useRelease(releaseId, releasePartial);
  const { updateRelease } = useUpdateRelease({ id: releaseId });
  const { deleteRelease } = useDeleteRelease(releaseId);
  const { control, handleSubmit } = useForm();

  const onPress = async (params) => {
    await updateRelease.mutateAsync({ params });
    navigation.navigate("ReleaseEditCoverArt", {
      id: release.id,
      releasePartial: release,
    });
  };

  const onSave = (params) => updateRelease.mutate({ params });

  useEffect(() => {
    if (deleteRelease.isSuccess) {
      navigation.replace("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, [deleteRelease.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("release")}
        headerLeft={{
          iconName: "close",
          onPress: () => {
            navigation.replace("BottomNavigator", {
              screen: "ReleaseTabs",
            });
          },
        }}
        customHeaderRight={release?.status === "draft"}
        profileProgress={{ type: "release", activeIndex: 0 }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <PageTitle
          nativeID="save-release-information"
          onSave={handleSubmit(onSave)}
          title={translate("release_information")}
          isDisabled={updateRelease.isLoading || release.is_delivered}
        />
        {isFetched ? (
          <ReleaseForm
            {...{
              control,
              release,
              isLoading: updateRelease.isLoading,
              onPress: handleSubmit(onPress),
            }}
          />
        ) : (
          <Loading />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
