import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateAuction = (auctionID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateAuction: useMutation(
      (params) =>
        client.patch(`auctions/${auctionID}/`, params).then((res) => res.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`auction-${auctionID}`);
          queryClient.invalidateQueries(`user-auctions`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateAuction;
