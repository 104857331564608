import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormControl, VStack } from "native-base";
// internal hooks
import useCreatePaymentMethod from "features/general/payment-methods/hooks/useCreatePaymentMethod";
// utility functions
import { translate, navigateBack } from "utils/helpers";
// internal components
import { Input } from "theme/forms/input.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ModalFrame } from "navigation/components/modal-frame.component";

export const PaymentMethodSetupIntentSofortScreen = ({ navigation }) => {
  const addPaymentMethod = useCreatePaymentMethod();
  const { control, handleSubmit } = useForm();

  const addBankAccount = (data) => {
    if (data.owner && data.iban) {
      const payload = {
        type: "bank_account",
        private: {
          iban: data.iban,
          owner: data.owner,
        },
      };
      addPaymentMethod.mutate(payload);
    }
  };

  useEffect(() => {
    if (addPaymentMethod.isSuccess) navigateBack(navigation);
  }, [addPaymentMethod.isSuccess]);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack borderTopRadius={20} overflow="hidden" bg="light.100" shadow={-0}>
        <NavigationHeader
          headerRight={{
            iconName: "close",
            onPress: () => navigateBack(navigation),
            color: "#5c5c5c",
          }}
          bg="transparent"
        />
        <VStack space={5} p={5}>
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                color: "#5c5c5c",
                fontSize: ["md", "lg"],
                fontWeight: "semibold",
              }}
            >
              {translate("owner")}
            </FormControl.Label>
            <Input
              name="owner"
              color="primary"
              autoFocus={true}
              variant="underlined"
              fontSize={["md", "lg"]}
              fontWeight="semibold"
              control={control}
              placeholder="Max Mustermann"
            />
          </FormControl>
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                color: "#5c5c5c",
                fontSize: ["md", "lg"],
                fontWeight: "semibold",
              }}
            >
              {"IBAN"}
            </FormControl.Label>
            <Input
              name="iban"
              color="primary"
              variant="underlined"
              fontSize={["md", "lg"]}
              fontWeight="semibold"
              control={control}
              placeholder="DE1234...."
            />
          </FormControl>
          <FormControl>
            <FormControl.ErrorMessage>{}</FormControl.ErrorMessage>
          </FormControl>
          <PrimaryButton
            text={translate("save")}
            onPress={handleSubmit(addBankAccount)}
            disabled={addPaymentMethod.isLoading}
            isLoading={addPaymentMethod.isLoading}
          />
        </VStack>
      </VStack>
    </ModalFrame>
  );
};
