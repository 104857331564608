import React from "react";
import { VStack } from "native-base";
// internal components
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
import { PageTitle } from "common/components/layout/page-title.component";
// internal hooks
import { useLanguages } from "features/royalties/releases/hooks/useLanguages";
// utility functions
import { translate } from "utils/helpers";

export const TrackDetailsForm = ({
  control,
  track,
  trackVersions,
  trackTypes,
  isLoading,
}) => (
  <VStack space="5">
    <PageTitle
      nativeID={"save-track-info"}
      isDisabled={isLoading}
      title={translate("track_info")}
    />
    <ResponsiveStack space="10">
      <VStack
        p="5"
        space="5"
        flex={{ lg: 1 }}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
      >
        <FormControlInput
          name="title"
          label={translate("title")}
          isDisabled={track?.is_submitted}
          helperText={translate("track_title_helper_text")}
          rules={{ required: true }}
          control={control}
          placeholder={translate("track_title")}
          value={track?.title}
          infoText={translate("track_title_info_text")}
        />
        <FormControlSelect
          name="language"
          label={translate("title_language")}
          rules={{ required: true }}
          control={control}
          isDisabled={track?.is_submitted}
          placeholder={translate("choose_language")}
          value={track?.language}
          query={useLanguages()}
          infoText={translate("title_language_info_text")}
        />
        <FormControlSelect
          name="audio_language"
          label={translate("lyrics_language")}
          rules={{ required: true }}
          control={control}
          isDisabled={track?.is_submitted}
          placeholder={translate("choose_language")}
          value={track?.audio_language}
          query={useLanguages()}
          infoText={translate("lyrics_language_info_text")}
        />
      </VStack>
      <VStack
        p="5"
        space="5"
        flex={{ lg: 1 }}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
      >
        <FormControlSelect
          nullable
          name="version"
          label={translate("version")}
          control={control}
          isDisabled={track?.is_submitted}
          query={trackVersions}
          value={track?.version || ""}
        />
        <FormControlSelect
          name="type"
          label={translate("type")}
          rules={{ required: true }}
          control={control}
          isDisabled={track?.is_submitted}
          query={trackTypes}
          value={track?.type || "Original"}
          helperText={translate("select_track_type")}
        />
        <FormControlInput
          name="isrc"
          label="ISRC"
          control={control}
          isDisabled={track?.is_submitted}
          placeholder="ISRC"
          value={track?.isrc}
          infoText={translate("isrc_info_text")}
          helperText={translate("isrc_helper_text")}
        />
      </VStack>
    </ResponsiveStack>
  </VStack>
);
