import React, { memo } from "react";
import * as Clipboard from "expo-clipboard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  VStack,
  HStack,
  Text,
  Pressable,
  Toast,
  Box,
  Icon,
  Divider,
} from "native-base";
// internal functions
import { translate, getLocalizedDate, getLocalizedNumber } from "utils/helpers";

const Item = ({ title, value, maxWidth = 40, isCopy = false }) => {
  return (
    <HStack justifyContent={"space-between"}>
      <Text fontSize={["md", "lg"]} color={"gray.500"}>
        {title}
      </Text>
      <Pressable
        disabled={!isCopy}
        onPress={async () => {
          await Clipboard.setStringAsync(value);
          !Toast.isActive("copy") &&
            Toast.show({
              id: "copy",
              placement: "top",
              duration: 5000,
              render: () => (
                <Box mx={5} py={3} px={5} bg={"light.100"} borderRadius={"xl"}>
                  <Text>{translate("link_copied")}</Text>
                </Box>
              ),
            });
        }}
      >
        <HStack space={2} alignItems={"center"}>
          {title === "Contract Address" && (
            <Icon size={4} name={"content-copy"} as={MaterialCommunityIcons} />
          )}
          <Text bold isTruncated maxWidth={maxWidth} fontSize={["md", "lg"]}>
            {value}
          </Text>
        </HStack>
      </Pressable>
    </HStack>
  );
};

export const LicenseCollectibleDetailExtraInfo = memo(
  ({ license, release, collectible }) => (
    <VStack p={5} shadow={5} space={5} bg={"light.50"} borderRadius={"2xl"}>
      <Item
        title={translate("total_invested")}
        value={`${getLocalizedNumber(collectible?.cost)} €`}
      />
      <Divider my={1} />
      <Item
        title={translate("purchase_date")}
        value={getLocalizedDate(collectible?.created_at)}
      />
      <Item
        title={translate("contract_start_date")}
        value={getLocalizedDate(license?.start_date)}
      />
      <Item
        title={translate("contract_end_start")}
        value={getLocalizedDate(license?.end_date)}
      />
      <Divider my={1} />
      <Item
        title={translate("est_release_date")}
        value={getLocalizedDate(release?.date)}
      />
    </VStack>
  )
);

export const LicenseBasicCollectibleDetailExtraInfo = memo(
  ({ attributes, contract_address, token_id }) => {
    return (
      <VStack p={5} space={5} shadow={5} bg={"light.100"} borderRadius={"xl"}>
        {attributes?.map((attr, index) => (
          <Item
            value={attr.value}
            key={attr.trait_type}
            title={attr.trait_type}
          />
        ))}
        {contract_address && (
          <Item
            isCopy
            maxWidth={32}
            value={contract_address}
            key={"Contract Address"}
            title={"Contract Address"}
          />
        )}
        {token_id !== undefined && token_id !== null && (
          <Item key={"Token ID"} title={"Token ID"} value={token_id} />
        )}
      </VStack>
    );
  }
);
