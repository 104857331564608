import React from "react";
import { VStack, Text, Box, HStack, Image, Divider } from "native-base";
//internal functions
import { translate } from "utils/helpers";
//internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { LicenseProductOrderCreateFormInfo } from "features/royalties/license-product-orders/components/license-product-order-create-form-info.component";
import { AuctionMusiciansList } from "features/general/auctions/components/auction-musicians-list.component";

export const LicenseProductOrderCreateForm = ({
  units,
  auction,
  product,
  setUnits,
  setShowPreview,
}) => {
  return (
    <VStack px={2} my={5} space={5}>
      <Text fontSize={["md", "lg"]} color={"gray.500"}>
        {translate("set_biddz_you_want_to_buy")}
      </Text>
      <HStack
        p={3}
        space={4}
        rounded={"2xl"}
        borderWidth={1}
        borderColor={"gray.200"}
      >
        <Image
          size={70}
          rounded={"xl"}
          alt={"license"}
          source={{ uri: auction?.image_with_url?.md?.url }}
        />
        <VStack justifyContent={"center"} space={2}>
          <Text fontSize={["md", "lg"]} fontWeight={500}>
            {auction?.title}
          </Text>
          <AuctionMusiciansList
            size={6}
            showsContainer={false}
            musicians={auction?.musicians}
          />
        </VStack>
      </HStack>
      <LicenseProductOrderCreateFormInfo
        units={units}
        product={product}
        setUnits={setUnits}
      />
      <Box width={"100%"}>
        <Divider mb={5} />
        <PrimaryButton
          fontSize={"md"}
          borderRadius={"full"}
          onPress={() => setShowPreview(true)}
          text={translate("preview_purchase")}
          disabled={
            !units ||
            (product?.available_units > (product?.min_units_per_order || 1) &&
              (product?.min_units_per_order || 1) > units) ||
            units > (product?.max_units_per_buyer || product?.available_units)
          }
        />
      </Box>
    </VStack>
  );
};
