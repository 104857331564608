import React from "react";
import { Text, VStack, Divider, HStack } from "native-base";
// internal
import { getLocalizedDate, getLocalizedNumber, translate } from "utils/helpers";

const OrderListCardStatus = ({ status }) => {
  const bg = {
    pending: "success.100",
    successful: "success.100",
    error: "error.100",
  };
  const statusMessage = {
    pending: "successful",
    successful: "successful",
    failed: "failed",
  };
  return (
    <Text
      px={2}
      py={1}
      bg={bg[status] ? bg[status] : "warning.100"}
      borderRadius="xl"
      color="black"
    >
      {statusMessage[status] ? translate(statusMessage[status]) : "Pending"}
    </Text>
  );
};

export const OrderListCard = ({ order }) => (
  <VStack space={2} justifyContent="space-evenly" width="100%">
    <HStack>
      <OrderListCardStatus status={order?.status} />
    </HStack>
    <HStack space={1} alignContent="center" justifyContent="space-between">
      <Text
        textTransform="uppercase"
        fontWeight="bold"
        fontSize={["md", "lg"]}
        width="50%"
        noOfLines={1}
      >
        {order?.auction?.title}
      </Text>
      <Text fontWeight="bold" fontSize={["md", "lg"]}>
        {getLocalizedNumber(order.total_price?.toFixed(2))} €
      </Text>
    </HStack>
    <Text fontWeight="normal">{getLocalizedDate(order.created_at)}</Text>
    <Text noOfLines={2} color="#A3A3A3" fontSize={["xs", "sm"]}>
      {"Bestellung ID:"} {order.id}
    </Text>
    <Divider my={5} />
  </VStack>
);
