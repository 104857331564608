import React, { useState, useEffect } from "react";
import { VStack, ScrollView, Box } from "native-base";
// internal hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import { useAnalytics } from "features/royalties/releases/hooks/useAnalytics";
import { useReleaseEarnings } from "features/royalties/releases/hooks/useReleaseEarnings";
import { useReleaseEstimatedEarnings } from "features/royalties/releases/hooks/useReleaseEstimatedEarnings";
import useDeleteRelease from "features/royalties/releases/hooks/useDeleteRelease";
import useUpdateReleaseStatus from "features/royalties/releases/hooks/useUpdateReleaseStatus";
// internal components
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { EarningSwiper } from "features/royalties/releases/components/earning-swiper.component";
import { ReleaseDetail } from "features/royalties/releases/components/release-detail.component";
import { EstimatedEarningSwiper } from "features/royalties/releases/components/estimated-earnings-swiper.component";
import { TopChartsSwiper } from "features/royalties/releases/components/top-charts-swiper.component";
import { ListenersStatsSwiper } from "features/royalties/releases/components/listeners-stats-swiper.component";
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { DeleteButton } from "common/components/buttons/delete-button.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseDetailScreen = ({ navigation, route }) => {
  const { id: releaseID, releasePartial = {} } = route.params;
  const release = useRelease(releaseID, releasePartial);
  const analytics = useAnalytics(releaseID);
  const earnings = useReleaseEarnings(releaseID);
  const estimatedEarnings = useReleaseEstimatedEarnings(releaseID);
  const { deleteRelease } = useDeleteRelease(releaseID);
  const { updateReleaseStatus } = useUpdateReleaseStatus(releaseID);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (deleteRelease.isSuccess) {
      navigation.replace("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, [deleteRelease.isSuccess]);

  useEffect(() => {
    if (updateReleaseStatus.isSuccess) {
      navigation.replace("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, [updateReleaseStatus.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () =>
            navigation.replace("BottomNavigator", {
              screen: "ReleaseTabs",
            }),
        }}
        customHeaderRight
        headerRight={
          <DeleteButton
            nativeID="delete-release"
            isLoading={deleteRelease.isLoading}
            modalTitle={translate("delete_release_modal_title")}
            modalDescription={translate("delete_release_modal_description")}
            onPress={() => {
              deleteRelease.mutate();
            }}
          />
        }
      />
      <ScrollView
        flex="1"
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <VStack mt="10" p="4" space="6">
          <TabNavigator
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            options={[translate("information"), translate("insights")]}
          />

          {activeTab === 0 &&
            (release.isFetching ? (
              <Loading />
            ) : (
              <ReleaseDetail
                navigation={navigation}
                release={release.data}
                unlockRelease={() => updateReleaseStatus.mutate("draft")}
              />
            ))}

          {activeTab === 1 &&
            (analytics.isFetching ||
            earnings.isFetching ||
            estimatedEarnings.isFetching ? (
              <Loading />
            ) : (
              <ResponsiveStack py="5" px={{ base: 5, lg: 0 }} space="6">
                <VStack space="6" flex={{ lg: 1 }}>
                  <EarningSwiper earnings={earnings.data} />
                  <EstimatedEarningSwiper earnings={estimatedEarnings.data} />
                </VStack>
                <Box flex={{ lg: 1 }}>
                  <TopChartsSwiper
                    analytics={analytics.data}
                    image={release.data?.image_with_url?.xs?.url}
                  />
                </Box>
                <Box flex={{ lg: 1 }}>
                  <ListenersStatsSwiper analytics={analytics.data} />
                </Box>
              </ResponsiveStack>
            ))}
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
