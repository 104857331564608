import React from "react";
import { VStack, Text, Pressable } from "native-base";
import { translate } from "utils/helpers";
import { useNavigation } from "@react-navigation/native";
import { PrimaryButton } from "theme/buttons/primary-button.component";

export const AuctionCalculatorCard = () => {
  const navigation = useNavigation();
  return (
    <VStack
      p="5"
      space={5}
      _light={{ bg: "light.100", shadow: 5 }}
      _dark={{ bg: "dark.900" }}
      borderRadius="xl"
    >
      <Text bold fontSize={"md"}>
        {translate("deal_calculator")}
      </Text>
      <Text color="dark.500" fontSize={"md"}>
        {translate("estimate_income")}
      </Text>
      <PrimaryButton
        nativeID="try-deal-calculator"
        size="sm"
        onPress={() => {
          navigation.navigate("Auctions", {
            screen: "AuctionCalculator",
          });
        }}
        text={translate("try_it_now")}
      />
    </VStack>
  );
};
