import { VStack, Text, HStack, Divider, FlatList } from "native-base";
// internal
import { OrderCreateConfirmationPreviewBreakdownAddOn } from "features/general/orders/components/order-create-preview-breakdown-add-ons.component";

export const OrderCreateConfirmationPreviewBreakdown = ({
  basketItemData,
  setBasketForProduct,
}) => {
  const [productID, basketItem] = basketItemData;
  let title = basketItem?.title;
  if (basketItem?.subtitle) {
    title += ` - ${basketItem?.subtitle}`;
  }

  return (
    <>
      <VStack py={4} space={4} justifyContent={"center"}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Text fontSize={["md", "lg"]}>{title}</Text>
          <Text fontSize={["md", "lg"]} bold>{`x ${basketItem?.units}`}</Text>
        </HStack>
        {!!basketItem?.add_ons && (
          <FlatList
            data={basketItem?.add_ons}
            renderItem={({ item }) => (
              <OrderCreateConfirmationPreviewBreakdownAddOn
                addOn={item}
                maxUnits={basketItem?.units}
                setBasketForProduct={setBasketForProduct}
              />
            )}
          ></FlatList>
        )}
      </VStack>
      <Divider />
    </>
  );
};
