import React, { useState, useEffect } from "react";
import { Text } from "native-base";
import { BarCodeScanner } from "expo-barcode-scanner";
import Constants from "expo-constants";
// utility functions
import { navigateBack, translate } from "utils/helpers";
// internal hooks
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";

export const TicketCollectibleDetailValidateScreen = ({ navigation }) => {
  const [hasPermission, setHasPermission] = useState(undefined);
  const [scanAvailable, setScanAvailable] = useState(true);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, []);

  useEffect(() => {
    const enableScan = navigation.addListener("focus", () => {
      setScanAvailable(true);
      console.debug("📷 Scan enabled");
    });
    return enableScan;
  }, [navigation]);

  const handleBarCodeScanned = ({ type, data }) => {
    console.debug(
      `📸 Bar code with type ${type} and data ${data} has been scanned!`
    );
    setScanAvailable(false);
    const regex = new RegExp(
      `${Constants.expoConfig.extra.APP_BASE_URL}/ticket-collectibles/([a-zA-Z0-9-]+)/users/([a-zA-Z0-9-]+)`
    );
    const match = data.match(regex);
    let ticketCollectibleID = undefined;
    let userID = undefined;
    if (!!match) {
      ticketCollectibleID = match[1];
      userID = match[2];
    } else {
      ticketCollectibleID = data;
    }

    navigation.navigate("TicketCollectibles", {
      screen: "TicketCollectibleDetailValidateResponse",
      params: {
        ticketCollectibleID: ticketCollectibleID,
        userID: userID,
      },
    });
  };
  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("scan_qr_code")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />

      {!hasPermission ? (
        <Text>No access to camera. You can change this in your settings.</Text>
      ) : (
        <BarCodeScanner
          style={{ flex: 1 }}
          onBarCodeScanned={scanAvailable ? handleBarCodeScanned : undefined}
        />
      )}
    </ScreenFrame>
  );
};
