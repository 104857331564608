import React from "react";
import { VStack, Text, HStack, Divider, FlatList } from "native-base";
import { OrderCreateConfirmationPreviewBreakdown } from "features/general/orders/components/order-create-preview-breakdown.component";
// utility functions
import { getLocalizedNumber, translate } from "utils/helpers";

export const TicketProductOrderCreateConfirmationPreview = ({
  basket,
  totalPrice,
  setBasketForProduct,
}) => {
  const category = Object.values(basket)[0]?.category;
  const orderType = category === "basic" ? "Collectible" : category;

  return (
    <VStack space={5}>
      <FlatList
        data={Object.entries(basket).filter(
          ([productID, basketItem]) => !basketItem?.isAddOn
        )}
        renderItem={({ item }) => (
          <OrderCreateConfirmationPreviewBreakdown
            basketItemData={item}
            setBasketForProduct={setBasketForProduct}
          />
        )}
      />
      <VStack space={5}>
        {orderType && (
          <>
            <HStack justifyContent={"space-between"}>
              <Text fontSize={["md", "lg"]}>{translate("order_type")}</Text>
              <Text bold fontSize={["md", "lg"]} textTransform={"capitalize"}>
                {orderType}
              </Text>
            </HStack>

            <Divider />
          </>
        )}

        <HStack justifyContent={"space-between"}>
          <Text fontSize={["md", "lg"]} color={"dark.600"}>
            {translate("total_price")} inkl. MwSt.
          </Text>
          <Text bold fontSize={["md", "lg"]}>
            {getLocalizedNumber(totalPrice)} EUR
          </Text>
        </HStack>
        <Divider />
      </VStack>
    </VStack>
  );
};
