import React from "react";
import { ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  HStack,
  Pressable,
  Text,
  Box,
  VStack,
  Factory,
  Icon,
  Skeleton,
} from "native-base";
// internal
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Carousel } from "theme/media/caraousel.component";
import { TertiaryButton } from "theme/buttons/tertiary-button.component";
import { useFeaturedAuctions } from "features/general/auctions/hooks/useFeaturedAuctions";

const NBImageBackground = Factory(ImageBackground);

export const GiftBox = () => {
  return (
    <NBImageBackground
      alt={"confetti"}
      resizeMode="cover"
      height={[120, 200]}
      source={require("assets/images/features/konfetti.png")}
    >
      <VStack flex={1} mb={[2, 4]} justifyContent={"flex-end"}>
        <HStack
          p={2}
          borderWidth={1}
          height={[60, 100]}
          width={[162, 220]}
          borderRadius={"xl"}
          alignItems={"center"}
          borderColor={"light.500"}
          backgroundColor={"dark.900"}
        >
          <Icon
            name={"gift"}
            size={[8, 10]}
            color={"secondary.600"}
            as={MaterialCommunityIcons}
          />
          <Text ml={2} fontSize={["xxs", "sm"]}>
            Physische Geschenkkarte kostenlos zum biddzMoments Ticket
          </Text>
        </HStack>
      </VStack>
    </NBImageBackground>
  );
};

export const AuctionCardFeatured = ({ title, image, onPress }) => {
  return (
    <Pressable
      flex={1}
      flexDirection="row"
      justifyContent={"center"}
      onPress={onPress}
    >
      <Box px={2} flex={1}>
        <NBImageBackground
          alt={"LEA"}
          resizeMode={"cover"}
          backgroundPosition={"top"}
          height={[230, 270, 350, 425]}
          imageStyle={{ borderRadius: 15 }}
          source={{
            uri: image,
          }}
        >
          <VStack
            p={5}
            flex={1}
            roundedBottom={"xl"}
            justifyContent={"flex-end"}
            bg={{
              linearGradient: {
                colors: [
                  "transparent",
                  "transparent",
                  "rgba(24, 22, 31, 0.5)",
                  "rgba(24, 22, 31, 1)",
                ],
                start: [0, 0],
                end: [0, 1],
              },
            }}
          >
            <HStack justifyContent={"space-between"} alignItems="center">
              <Text
                color="lightText"
                maxWidth={"75%"}
                fontSize={["2xl", "3xl"]}
                fontWeight={"semibold"}
              >
                {title}
              </Text>
              <TertiaryButton text={"Mehr"} onPress={onPress} />
            </HStack>
          </VStack>
        </NBImageBackground>
      </Box>
    </Pressable>
  );
};

export const AuctionListFeatured = () => {
  const now = new Date().getTime() / 1000;
  const navigation = useNavigation();
  const featuredItems = useFeaturedAuctions();

  const navigateMusician = (id) => {
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: { id: id },
    });
  };

  const navigateAuction = (id) => {
    navigation.navigate("Auctions", {
      screen: "AuctionDetail",
      params: { id: id },
    });
  };

  const navigateTour = (id) => {
    navigation.navigate("Auctions", {
      screen: "TourAuctionList",
      params: { id: id },
    });
  };

  return featuredItems.isLoading ? (
    <VStack p={2} h={[300, 350, 420, 500]} alignItems={"center"}>
      <Skeleton flex={1} w={"90%"} rounded={"xl"} />
    </VStack>
  ) : (
    featuredItems.data?.length > 0 && (
      <VStack p={2} h={[300, 350, 420, 500]}>
        <Carousel>
          {featuredItems.data?.map((item, index) => {
            if (!!item?.start_time && item?.start_time?.seconds > now) {
              return undefined;
            }
            if (!!item?.end_time && item?.end_time?.seconds <= now) {
              return undefined;
            }
            return (
              <AuctionCardFeatured
                key={index}
                image={item.image}
                title={item.title}
                onPress={() => {
                  if (item.type === "musician") {
                    navigateMusician(item.object_id);
                  } else navigateAuction(item.object_id);
                }}
              />
            );
          })}
        </Carousel>
      </VStack>
    )
  );
};
