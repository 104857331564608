import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateTrack = (release, track) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateTrack: useMutation(
      (data) => client.patch(`tracks/${track.id}/`, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`release-${release.id}`);
          queryClient.invalidateQueries(`track-${track.id}`);
          console.debug(`invalidated query${release.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateTrack;
