import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTicketCollectibleValidate = (ticketCollectibleID, userID) => {
  const { client } = useContext(AxiosContext);
  let route = `ticket_collectibles/${ticketCollectibleID}/`;
  if (!!userID) {
    route = route + `users/${userID}/`;
  }
  route = route + `validate/`;
  return useQuery(
    `ticket-collectible-${ticketCollectibleID}-validate`,
    () =>
      client.post(route).then((resp) => {
        console.log(`🎟 Ticket Collectible ${ticketCollectibleID} Validate`);
        return resp;
      }),
    {
      retry: false,
      enabled: !!ticketCollectibleID,
    }
  );
};
