import React, { useState } from "react";
import { Box, FlatList, useMediaQuery, VStack, Text } from "native-base";
// internal hooks
import { useTours } from "features/tickets/tours/hooks/useTours";
import { useAuctions } from "features/general/auctions/hooks/useAuctions";
// internal components
import { AuctionCard } from "features/general/auctions/components/auction-list-card.component";
import { Loading } from "common/components/loading/loading.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import {
  FILTERS,
  HEADER,
  AuctionLicenseFilters,
} from "features/general/auctions/components/auction-license-filters.component";
import { ListEmptyComponent } from "common/components/layout/list-empty.component";
// utility functions
import { isWeb, navigateBack, translate } from "utils/helpers";

const getParams = (header) => {
  switch (header) {
    case "upcoming":
      return { is_upcoming: true };
    case "past":
      return { is_over: true };
    case "watchlist":
      return { is_bookmarked: true };

    default:
      return { is_active: true, category: "license" };
  }
};

export const AuctionListScreen = ({ navigation, route }) => {
  const { header } = route.params ? route.params : { header: "share" };
  const params = getParams(header);

  const [filter, setFilter] = useState("is_active");

  const isMoments = header === "moments";
  const isShare = header === "share";

  const auctions = isMoments
    ? useTours({ is_active: true })
    : useAuctions(
        isShare ? HEADER[filter] : header,
        isShare ? FILTERS[filter] : params
      );
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    <ScreenFrame>
      {isMoments ? (
        <NavigationPanel goesBack={!isWeb} />
      ) : (
        <NavigationHeader
          title={translate(header)}
          headerLeft={{
            iconName: "arrow-left",
            onPress: () => navigateBack(navigation),
          }}
        />
      )}
      <VStack
        flex={1}
        space={1}
        pl={{ base: 5, lg: 16 }}
        pr={{ base: 5, lg: 32 }}
      >
        {isMoments && (
          <>
            <Text fontSize={["xl", "2xl"]} bold>
              {translate("explore_moments")}
            </Text>
            <Text fontSize="md" color="dark.600">
              {translate("explore_moments_subtitle")}
            </Text>
          </>
        )}
        {auctions.isLoading && <Loading maxHeight={20} width="3px" />}
        {auctions.isFetched && (
          <FlatList
            key={isDesktop ? "desktop-auction-list" : "auction-list"}
            numColumns={isDesktop ? 3 : 1}
            contentContainerStyle={{ alignItems: "center" }}
            showsVerticalScrollIndicator={false}
            onEndReached={() =>
              auctions.hasNextPage && !auctions.isFetching
                ? auctions.fetchNextPage()
                : null
            }
            onEndReachedThreshold={0.3}
            data={auctions?.data?.pages.map((page) => page.items).flat()}
            renderItem={({ item }) => (
              <Box mb={8} mr={isDesktop ? 12 : 0}>
                <AuctionCard
                  key={item.id}
                  auction={item}
                  onPress={() => {
                    const multiTour = item.auctions?.length > 1;
                    const singleTour = item.auctions?.length == 1;
                    const screen = multiTour
                      ? "TourAuctionList"
                      : "AuctionDetail";
                    const screen_params = singleTour
                      ? {
                          id: item.auctions[0]?.id,
                          initialData: item.auctions[0],
                        }
                      : { id: item.id, initialData: item };
                    navigation.navigate("Auctions", {
                      screen: screen,
                      params: screen_params,
                    });
                  }}
                />
              </Box>
            )}
            ListHeaderComponent={
              isShare && (
                <VStack pb={5}>
                  <AuctionLicenseFilters
                    filter={filter}
                    setFilter={setFilter}
                  />
                </VStack>
              )
            }
            ListEmptyComponent={
              <ListEmptyComponent text={translate("no_results")} />
            }
            ListFooterComponent={
              auctions.isFetching && (
                <VStack flex={1} alignItems={"center"}>
                  <CenteredSpinner />
                </VStack>
              )
            }
          />
        )}
      </VStack>
    </ScreenFrame>
  );
};
