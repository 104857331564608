import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useRelease = (id, initialData) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `release-${id}`,
    () => {
      console.debug(`📗 Release-${id}`);

      return client.get(`releases/${id}/`).then((resp) => resp.data);
    },
    {
      enabled: !!id,
      initialData: initialData,
    }
  );
};
