import React from "react";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { Linking, Alert } from "react-native";
import { Ionicons } from "@expo/vector-icons";
// utility functions
import { isWeb, translate } from "utils/helpers";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

export const DownloadVideoButton = ({ videoURL }) => {
  const [downloading, setDownloading] = React.useState(false);

  const downloadOnNative = async (url) => {
    try {
      const uri = FileSystem.cacheDirectory + Date.now() + ".mp4";
      const photosPermissions = await MediaLibrary.requestPermissionsAsync();

      if (photosPermissions.granted) {
        setDownloading(true);
        FileSystem.downloadAsync(url, uri)
          .then((savedVideo) => {
            MediaLibrary.saveToLibraryAsync(savedVideo.uri)
              .then(() => {
                Alert.alert(
                  translate("video_saved_alert_title"),
                  translate("video_saved_alert_body")
                );
              })
              .catch((error) => console.debug(error));
          })
          .catch((error) => console.debug(error))
          .finally(() => setDownloading(false));
      } else {
        Alert.alert(
          translate("library_permission_alert_title"),
          translate("library_permission_alert_body")
        );
      }
    } catch (error) {
      console.debug(error);
    }
  };

  const downloadVideo = async () => {
    if (videoURL) {
      if (isWeb) {
        Linking.openURL(videoURL);
      } else {
        downloadOnNative(videoURL);
      }
    }
  };

  return (
    <AppIconButton
      p={3}
      as={Ionicons}
      borderRadius={"full"}
      onPress={downloadVideo}
      nativeID={"download-video"}
      iconName={"arrow-redo-outline"}
      disabled={!videoURL || downloading}
    />
  );
};
