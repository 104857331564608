import React from "react";
import { HStack, VStack, Text, Pressable, Box, Image } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { openBrowserAsync } from "expo-web-browser";
// utility functions
import { translate } from "utils/helpers";

export const ProductDetailCustomerInfoSheet = ({ url }) =>
  url ? (
    <VStack _ios={{ maxW: "50%" }} space={5}>
      <Text fontSize={["xl", "2xl"]} fontWeight="bold">
        {translate("documents")}
      </Text>
      <HStack mb={12}>
        <Pressable
          borderColor="light.600"
          borderWidth={1}
          nativeID="open-customer-info-sheet"
          justifyContent="center"
          alignItems="center"
          p={5}
          borderRadius={"xl"}
          onPress={async () => {
            await openBrowserAsync(url);
          }}
        >
          <HStack mb={10} w="100%" justifyContent={"flex-end"}>
            <Image
              resizeMode="contain"
              alt="download-customer-info-sheet"
              size={5}
              source={require("assets/images/features/pdf-download.png")}
            />
          </HStack>
          <VStack mx={4} mb={12} alignItems={"center"}>
            <Box mb={2} py={1} px={2} borderRadius={"sm"} bg="darkText">
              <Text fontSize={"sm"} fontWeight={"semibold"} color="lightText">
                PDF
              </Text>
            </Box>
            <Text>{translate("consumer")}-</Text>
            <Text>{translate("information_sheet")}</Text>
          </VStack>
        </Pressable>
      </HStack>
    </VStack>
  ) : (
    <></>
  );
