import React from "react";
import { Divider, HStack, Text, VStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
//internal components
import { IconModal } from "theme/feedback/icon-modal.component";

import {
  getLocalizedNumber,
  translate,
  getLocalizedDateWithDay,
} from "utils/helpers";

const Item = ({ title, value, info = "", valueTextAlign = "right" }) => (
  <HStack alignItems="center" justifyContent="space-between">
    <HStack space={2} alignItems="center" mr={2}>
      <Text fontSize={["md", "lg"]}>{title}</Text>
      {info && (
        <IconModal
          icon={<AntDesign name="questioncircleo" />}
          contentText={info}
        />
      )}
    </HStack>
    <Text
      fontSize={["md", "lg"]}
      fontWeight={"semibold"}
      textAlign={valueTextAlign}
    >
      {value}
    </Text>
  </HStack>
);

export const AuctionSummaryInfo = ({
  auction,
  isAuctionOffer = false,
  product,
}) => {
  return (
    <VStack space="7">
      <Item
        title={translate("license_start_date")}
        value={getLocalizedDateWithDay(auction?.start_date)}
      />
      <Item
        title={translate("license_end_date")}
        value={getLocalizedDateWithDay(auction?.end_date)}
      />
      <Item
        title={translate("license_start_date_fans")}
        value={getLocalizedDateWithDay(product?.license_product?.start_date)}
      />
      <Item
        title={translate("license_end_date_fans")}
        value={getLocalizedDateWithDay(product?.license_product?.end_date)}
      />
      <Divider />
      <Item
        title={translate("share_offered")}
        value={getLocalizedNumber(product?.units)}
      />
      <Item
        title={translate("expected_streams")}
        value={getLocalizedNumber(
          product?.license_product?.expected_total_streams
        )}
      />
      <Item
        title={translate("possible_advance")}
        value={`${getLocalizedNumber(
          product?.license_product?.expected_artist_advance || 0
        )} EUR`}
        info={translate("possible_advance_help_text")}
      />
      {isAuctionOffer && (
        <Item
          title={translate("price_per_biddz")}
          value={`${getLocalizedNumber(product?.price_per_unit)} €`}
        />
      )}
      <Divider />
      {isAuctionOffer && (
        <Item
          title={translate("evaluation_duration")}
          value={`${
            product?.license_product?.duration_in_years || 0
          } ${translate("years")}`}
        />
      )}

      <Item
        title={translate("license_fee")}
        value={translate("license_fee_description", {
          percentage: 95,
        })}
      />
    </VStack>
  );
};
