import React, { useEffect } from "react";
import { useForm, useController } from "react-hook-form";
import { VStack, HStack, Box, ScrollView } from "native-base";
// internal hooks
import useCreateTrackContributor from "features/royalties/track-contributors/hooks/useCreateTrackContributor";
import useUpdateTrackContributor from "../hooks/useUpdateTrackContributor";
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";
import { Loading } from "common/components/loading/loading.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const AddContributorToTrackScreen = ({ navigation, route }) => {
  const { trackID, contributorID, trackPartials = {} } = route.params;
  const { control, handleSubmit } = useForm();
  const { createTrackContributor } = useCreateTrackContributor(trackID);
  const { updateTrackContributor } = useUpdateTrackContributor(
    trackID,
    contributorID
  );
  const { data: track, isFetching } = useTrack(trackID, trackPartials);
  const contributor = track?.contributors?.find(
    (contributor) => contributor.id === contributorID
  );

  const { field } = useController({ control: control, name: "type" });
  const contributorData = [
    { label: translate("lyricist"), value: "lyricist" },
    { label: translate("producer"), value: "producer" },
    { label: translate("mixer"), value: "mixer" },
    { label: translate("composer"), value: "composer" },
    { label: translate("remixer"), value: "remixer" },
  ];

  const onPress = (data) => {
    if (field.value !== "remixer") {
      delete data["spotify_id"];
      delete data["apple_id"];
    }
    if (!!contributorID) {
      updateTrackContributor.mutate(data);
    } else {
      createTrackContributor.mutate(data);
    }
  };

  useEffect(() => {
    if (createTrackContributor.isSuccess || updateTrackContributor.isSuccess) {
      navigateBack(navigation);
    }
  }, [createTrackContributor.isSuccess, updateTrackContributor.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("add_contributor")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <VStack flex={1} pt={10}>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading />
          ) : (
            <VStack p={5} space={7}>
              <HStack space={2}>
                <Box flex={6}>
                  <FormControlInput
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    value={contributor?.name}
                    placeholder={translate("name_or_alias")}
                  />
                </Box>
                <Box flex={4}>
                  <FormControlSelectWithDict
                    name={"type"}
                    control={control}
                    dict={contributorData}
                    rules={{ required: true }}
                    selectedValue={
                      contributor?.type || contributorData[0].value
                    }
                  />
                </Box>
              </HStack>
              {field?.value === "remixer" && (
                <VStack space={2} alignItems={"center"}>
                  <FormControlInput
                    control={control}
                    name={"spotify_id"}
                    rules={{ required: true }}
                    label={translate("spotify_id")}
                    value={contributor?.spotify_id}
                    placeholder={translate("spotify_id")}
                  />
                  <FormControlInput
                    control={control}
                    name={"apple_id"}
                    rules={{ required: true }}
                    label={translate("apple_id")}
                    value={contributor?.apple_id}
                    placeholder={translate("apple_id")}
                  />
                </VStack>
              )}
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p={[5, 0]}
        showBackButton
        disabled={createTrackContributor.isLoading}
        isLoading={createTrackContributor.isLoading}
        text={translate("save")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
