import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { VStack, Text, HStack } from "native-base";
// internal components
import useDeleteTrackMusician from "features/royalties/tracks/hooks/useDeleteTrackMusician";
import { IconModal } from "theme/feedback/icon-modal.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";
// utility functions
import { translate } from "utils/helpers";

const Musician = ({ primary, musician, showDelete, onDelete }) => (
  <HStack space={2} alignItems="center">
    <Text
      p="3"
      flex={6}
      fontSize="md"
      color="dark.600"
      bg="light.700"
      borderRadius="xl"
    >
      {musician.alias}
    </Text>
    <Text
      p="3"
      flex={4}
      fontSize="md"
      color="dark.600"
      bg="light.700"
      borderRadius="xl"
    >
      {primary ? translate("primary_artist") : translate("featured_artist")}
    </Text>
    {showDelete && (
      <AppIconButton
        nativeID="delete-track-artist"
        onPress={onDelete}
        iconName="trash-can"
      />
    )}
  </HStack>
);

export const TrackMusicians = ({ navigation, track, release }) => {
  const { deleteTrackMusician } = useDeleteTrackMusician(track);

  return (
    <VStack p={5} space={3} bg={"light.100"}>
      <HStack alignItems="center" justifyContent="space-between">
        <Text fontSize="md">{translate("artists")}</Text>
        <IconModal
          iconColor="primary.600"
          icon={<AntDesign name="questioncircleo" />}
          contentText={translate("artists_help_text")}
        />
      </HStack>
      {track?.primary_musicians?.map((musician, index) => (
        <Musician
          primary
          key={musician.id}
          musician={musician}
          showDelete={index !== 0 && !track?.is_submitted}
          onDelete={() => {
            deleteTrackMusician.mutate({ primary: true, id: musician.id });
          }}
        />
      ))}
      {track?.featured_musicians?.map((musician) => (
        <Musician
          primary={false}
          key={musician.id}
          musician={musician}
          showDelete={!track?.is_submitted}
          onDelete={() => {
            deleteTrackMusician.mutate({ primary: false, id: musician.id });
          }}
        />
      ))}
    </VStack>
  );
};
