import { Platform } from "react-native";
import React, { useState, useEffect } from "react";
import { SimpleLineIcons } from "@expo/vector-icons";
import { HStack, Icon, Pressable, Text, useBreakpointValue } from "native-base";
import { translate } from "utils/helpers";

export const ExpandableText = ({
  numberOfLines,
  fontSize,
  text,
  width = "100%" || ["100%", "100%"],
  iconColor = null,
  ...props
}) => {
  const noOfLines = useBreakpointValue(numberOfLines.map((n) => Math.floor(n)));
  const responsiveFontSize = useBreakpointValue(fontSize.map((n) => n));
  const [expanded, setExpanded] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    const lines =
      Platform.OS === "web"
        ? responsiveFontSize === "sm"
          ? noOfLines * 10
          : responsiveFontSize === "md"
          ? noOfLines * 20
          : noOfLines * 20
        : noOfLines;
    if (textHeight > lines) {
      setShowIcon(true);
    } else if (textHeight < lines) {
      setShowIcon(false);
    }
  }, [textHeight]);

  return (
    <HStack w={width} alignItems="flex-end">
      <Text
        {...props}
        fontSize={responsiveFontSize}
        lineHeight="md"
        noOfLines={expanded || !showIcon ? 0 : noOfLines}
        onLayout={(e) => {
          if (Platform.OS === "web") {
            setTextHeight(e.nativeEvent.layout.height);
          }
        }}
        onTextLayout={(e) => {
          if (Platform.OS === "android" || Platform.OS === "ios") {
            setTextHeight(e.nativeEvent.lines.length);
          }
        }}
      >
        {text}
      </Text>
      {showIcon && (
        // <Pressable onPress={() => setExpanded(!expanded)}>
        //   <Text bold>Read more</Text>
        // </Pressable>
        <Icon
          size={14}
          color={iconColor}
          as={SimpleLineIcons}
          style={{ paddingBottom: 1 }}
          name={expanded ? "minus" : "plus"}
          onPress={() => setExpanded(!expanded)}
        />
      )}
    </HStack>
  );
};
