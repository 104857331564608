import React, { useState, useEffect } from "react";
import { VStack } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal functions
import { translate } from "utils/helpers";
// internal hooks
import useCreateOrder from "features/general/orders/hooks/useCreateOrder";
import { useCreateApplePaySession } from "features/general/payment-methods/hooks/useCreateApplePaySession";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ChoosePaymentMethod } from "features/general/payment-methods/components/choose-payment-method.component";
import { OrderCreateAgreeTermsForm } from "features/general/orders/components/order-create-agree-terms-form.component";
import { TicketProductOrderCreateConfirmationPreview } from "features/tickets/ticket-product-orders/components/ticket-product-order-create-confirmation-preview.component";
import { TicketProductOrderCreateAddMerch } from "features/tickets/ticket-product-orders/components/ticket-product-order-create-add-merch.component";

export const TicketProductOrderCreateConfirmation = ({
  basket,
  setBasketForProduct,
  infoSheetURL,
  totalPrice,
  isCollectible,
  isDelivery,
  setIsDelivery,
  musician,
  auction,
}) => {
  const [chosenPaymentMethodID, setChosenPaymentMethodID] = useState(null);
  const [chosenPaymentMethodType, setChosenPaymentMethodType] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const navigation = useNavigation();
  const { createOrder } = useCreateOrder();

  useEffect(() => {
    if (createOrder.isSuccess) {
      navigation.navigate("Orders", {
        screen: "OrderDetail",
        params: {
          orderID: createOrder.data?.data?.id,
        },
      });
    }
  }, [createOrder.isSuccess]);

  const getOrderItems = () => {
    var items = [];
    for (var productID in basket) {
      items.push({ product_id: productID, units: basket[productID]?.units });
    }
    return items;
  };

  const { createApplePaySession } = useCreateApplePaySession(
    getOrderItems(),
    totalPrice,
    isDelivery
  );

  const onBuy = async () => {
    if (chosenPaymentMethodType === "applepay") {
      createApplePaySession.mutate([
        chosenPaymentMethodID,
        chosenPaymentMethodType,
      ]);
    } else {
      createOrder.mutate({
        items: getOrderItems(),
        secupay_payment_method_id: chosenPaymentMethodID,
        secupay_payment_method_type: chosenPaymentMethodType,
        is_delivery: isDelivery,
      });
    }
  };

  const isHardFreeMerch = auction?.title?.includes("🐻");

  return (
    <VStack>
      <VStack space={5}>
        <TicketProductOrderCreateConfirmationPreview
          basket={basket}
          totalPrice={totalPrice}
          setBasketForProduct={setBasketForProduct}
        />
        <ChoosePaymentMethod
          totalPrice={totalPrice}
          items={getOrderItems()}
          chosenPaymentMethodID={chosenPaymentMethodID}
          setChosenPaymentMethodID={setChosenPaymentMethodID}
          setChosenPaymentMethodType={setChosenPaymentMethodType}
        />

        <OrderCreateAgreeTermsForm
          isTicketOrder
          url={infoSheetURL}
          musician={musician}
          setDisabled={setDisabled}
        />

        {/* Show shipping address modal after clicking on buying. */}
        {(isCollectible || isDelivery || isHardFreeMerch) && (
          <TicketProductOrderCreateAddMerch
            showForm={showForm}
            setShowForm={setShowForm}
            isCollectible={isCollectible}
            isDelivery={isDelivery}
            setIsDelivery={setIsDelivery}
            onSave={onBuy}
            fieldsRequired={true}
            isFreeMerch={isHardFreeMerch}
          />
        )}

        <PrimaryButton
          disabled={
            !chosenPaymentMethodID ||
            !chosenPaymentMethodType ||
            createOrder.isLoading ||
            disabled
          }
          isLoading={createOrder.isLoading}
          text={translate("buy_now")}
          onPress={
            isCollectible || isDelivery || isHardFreeMerch
              ? () => setShowForm(true)
              : onBuy
          }
        />
      </VStack>
    </VStack>
  );
};
