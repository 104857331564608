import { encode, decode } from "base-64";
import React, { useContext, useState } from "react";
import axios from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { Toast } from "native-base";
// firebase
import { onIdTokenChanged } from "firebase/auth";
import { FirebaseContext } from "services/firebase.context";
// internal components
import { ToastAlert } from "theme/feedback/toast-alert.component";
// helpers
import { getErrorMessage } from "utils/helpers";

if (!global.btoa) {
  global.btoa = encode;
}
if (!global.atob) {
  global.atob = decode;
}

export const AxiosContext = React.createContext(null);

export const AxiosContextProvider = ({ children }) => {
  const baseURL = Constants.expoConfig.extra.API_BASE_URL;
  const { auth } = useContext(FirebaseContext);

  const [initialized, setInitialized] = useState(false);

  const headers = {
    platform: Platform.OS,
    accept: "application/json",
    version: Constants.expoConfig.version,
  };

  const client = React.useMemo(() => {
    const temp = axios.create({ headers, baseURL });
    temp.interceptors.response.use(
      (response) => response,
      (error) => {
        if (![401, 404, undefined].includes(error?.response?.status)) {
          Toast.show({
            placement: "top",
            render: ({ id }) => (
              <ToastAlert
                closable
                id={id}
                status="error"
                description={getErrorMessage(error)}
              />
            ),
          });
        }
        return Promise.reject(error);
      }
    );
    return temp;
  }, []);

  React.useEffect(() => {
    onIdTokenChanged(auth, (user) => {
      if (user) {
        const token = user.toJSON().stsTokenManager?.accessToken;
        client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        console.debug(`🔑 Access Token added (${baseURL})`);
      } else {
        delete client.defaults.headers.common["Authorization"];
        console.debug(`🔑 Access Token removed (${baseURL})`);
      }
      setInitialized(true);
    });
  }, []);

  return (
    <AxiosContext.Provider value={{ client, initialized }}>
      {children}
    </AxiosContext.Provider>
  );
};
