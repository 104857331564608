import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateRelease = ({ id }) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateRelease: useMutation(
      ({ params }) =>
        client.patch(`releases/${id}/`, params).then((res) => res.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("releases");
          queryClient.invalidateQueries(`release-${id}`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateRelease;
