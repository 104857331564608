import React from "react";
import { Stack, FlatList, Box, HStack, Text } from "native-base";
// utility functions
import { navigateBack, translate } from "utils/helpers";
//internal hooks
import { useUserOrders } from "features/general/orders/hooks/useUserOrders";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { OrderListCard } from "features/general/orders/components/order-list-card.component";
import { OrderListSkeleton } from "features/general/orders/skeletons/order-list-skeleton.component";

export const OrderListScreen = ({ navigation }) => {
  const orders = useUserOrders();

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("orders_history")}
        iconName=""
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <Stack mt={5} px={5} flex={1}>
        {orders.isLoading ? (
          <OrderListSkeleton />
        ) : (
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            onEndReached={() =>
              orders.hasNextPage && !orders.isFetching
                ? orders.fetchNextPage()
                : null
            }
            onEndReachedThreshold={0.3}
            data={orders?.data?.pages.map((page) => page.items).flat()}
            renderItem={({ item }) => <OrderListCard order={item} />}
            ListEmptyComponent={
              <Stack
                marginTop={"40%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontWeight={600} fontSize={["md", "lg"]}>
                  {translate("no_orders_history")}
                </Text>
              </Stack>
            }
            ListFooterComponent={
              <Box m={2}>
                {orders.isFetching && !orders.isLoading && (
                  <HStack justifyContent={"center"}>
                    <CenteredSpinner />
                  </HStack>
                )}
              </Box>
            }
          />
        )}
      </Stack>
    </ScreenFrame>
  );
};
