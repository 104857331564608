import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useUserEvents = (initialData = {}, isValidator = true) => {
  const { client } = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery(
    "events",
    () =>
      client.get(`me/events/?is_validator=${isValidator}`).then((resp) => {
        console.debug(`👽 User Events isValdiator=${isValidator}`);
        return resp.data;
      }),
    {
      staleTime: 300000,
      placeholderData: initialData,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );
};
