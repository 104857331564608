import React from "react";
import { HStack, Skeleton, VStack } from "native-base";
// internal
import { getWindowWidth } from "utils/helpers";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";

export const LicenseProductDetailSkeleton = () => {
  const windowWidth = getWindowWidth();
  const height = Math.min(Math.max(windowWidth * 0.6, 310), 450);
  const width = Math.min(Math.max(windowWidth * 0.6, 200), 285);

  return (
    <VStack h={"100%"} w={"100%"} alignItems={"center"} mt={[10, 12]}>
      <Skeleton startColor={"skeleton.dark"} h={height} w={width} />
      <HStack justifyContent={"center"} my={4}>
        <Skeleton
          startColor={"skeleton.dark"}
          rounded={"full"}
          overflow={"hidden"}
          size={12}
        />
      </HStack>
      <Skeleton
        startColor={"skeleton.dark"}
        w={"80%"}
        h={20}
        rounded={15}
        overflow={"hidden"}
      />
      <SkeletonTextVertical numberOfLines={5} px={6} mt={4} />
      <HStack _ios={{ flex: 1 }} _android={{ flex: 1 }} w={"100%"} p={6}>
        <Skeleton
          startColor={"skeleton.dark"}
          flex={1}
          h={48}
          rounded={10}
          overflow={"hidden"}
        />
        <SkeletonTextVertical numberOfLines={6} p={4} />
      </HStack>
    </VStack>
  );
};
