import React, { memo } from "react";
import { HStack, Text, VStack } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
//internal hooks
import { translate } from "utils/helpers";
import { useAuctionCollectibles } from "features/general/collectibles/hooks/useAuctionCollectibles";
//internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { SeeMoreButton } from "theme/buttons/see-more-button.component";
import { AuctionCollectibleDetail } from "features/general/collectibles/components/auction-collectible-detail.component";
import { AuctionCollectibleListSkeleton } from "features/general/collectibles/skeletons/auction-collectible-list.skeleton";

export const AuctionCollectibleList = memo(
  ({ auctionID, isCollectible = false }) => {
    const [showMore, setShowMore] = React.useState(false);
    const { data: collectibles, isLoading } = useAuctionCollectibles(auctionID);

    return (
      collectibles?.length > 0 && (
        <VStack space={5} mb={10}>
          {!isCollectible && (
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <HStack space={2} alignItems={"center"}>
                <Text fontWeight="black" fontSize={["xl", "2xl"]}>
                  {translate("top_supporters")}
                </Text>
                <IconModal
                  size={"md"}
                  color={"gray.400"}
                  icon={<MaterialCommunityIcons name="information-outline" />}
                  contentText={translate("auction_collectible_list_info_text")}
                />
              </HStack>
            </HStack>
          )}
          <VStack>
            <HStack
              paddingBottom={3}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text w={"15%"} color={"gray.400"} fontSize={["md", "lg"]}>
                #
              </Text>
              <Text w={"30%"} color={"gray.400"} fontSize={["md", "lg"]}>
                {translate("fan")}
              </Text>
              <Text
                w={"15%"}
                color={"gray.400"}
                textAlign={"right"}
                fontSize={["md", "lg"]}
              >
                {translate("biddz")}
              </Text>
            </HStack>
            {isLoading && <AuctionCollectibleListSkeleton />}
            {collectibles
              ?.slice(0, showMore ? collectibles?.length : 5)
              .map((holder, i) => (
                <AuctionCollectibleDetail
                  key={i}
                  rank={i + 1}
                  holder={holder}
                  isCollectible
                />
              ))}
            {collectibles?.length > 5 && !showMore && (
              <SeeMoreButton onPress={() => setShowMore(true)} />
            )}
          </VStack>
        </VStack>
      )
    );
  }
);
