import React from "react";
import { HStack, Stack, Text, VStack } from "native-base";
//components
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";
import { ProfileEditImageBody } from "features/accounts/profiles/components/profile-edit-image-body.component";

interface IProps {
  heading: string;
  title: string;
  description: string;
  url?: string;
  isCover?: boolean;
  isLoading?: boolean;
}

export const ProfileEditImage = ({
  heading,
  title,
  description,
  url,
  isCover = false,
  isLoading = true,
}: IProps) => (
  <VStack space={5}>
    <Text fontSize={["xl", "2xl"]} fontWeight={"semibold"}>
      {heading}
    </Text>
    <HStack justifyContent="flex-start" alignItems="center">
      {isLoading ? (
        <SkeletonRounded width="65px" height="65px" />
      ) : (
        <ProfileEditImageBody isCover={isCover} url={url} />
      )}
      <Stack flex={1} space={3} ml={5}>
        <Text fontSize={["md", "lg"]} fontWeight={"semibold"}>
          {title}
        </Text>
        <Text fontSize={["sm", "md"]}>{description}</Text>
      </Stack>
    </HStack>
  </VStack>
);
