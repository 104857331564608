import React, { useEffect } from "react";
import { VStack, Text } from "native-base";
import { translate } from "utils/helpers";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { CouponDetailProgressBar } from "./coupon-detail-progress-bar.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { Link } from "theme/typography/link.component";
import { Loading } from "common/components/loading/loading.component";

export const CouponRedeem = ({
  profile,
  product,
  control,
  redeemButton,
  isDardan,
}) => {
  return !profile.isFetched ? (
    <Loading />
  ) : (
    <VStack space={5}>
      <CouponDetailProgressBar activeIndex={1} />
      <Text fontSize={["md", "lg", "xl"]}>
        {isDardan
          ? "Hol dir kostenfrei deinen exklusiven Mister Dardy Tour Collectible und werde Teil der Dardan Community."
          : translate("redeem_coupon_description")}
      </Text>
      <FormControlInput
        label={translate("username")}
        name="username"
        rules={{ required: true }}
        control={control}
        value={profile.data?.username}
        placeholder={translate("username").toLowerCase()}
      />
      <FormControlInput
        label={translate("email")}
        name="email"
        control={control}
        rules={{ required: true, pattern: /\S+\@\S+\.\S+/ }}
        placeholder={translate("email")}
        value={profile.data?.email}
      />
      <BooleanCheckbox
        rules={{ required: true }}
        control={control}
        name="special_conditions"
      >
        <Text fontSize={["xs"]}>
          Ich akzeptiere die
          <Link
            fontSize="xs"
            text="Kaufsonderbedingungen"
            url="https://biddz.io/specialterms"
          />
          und nehme das
          <Link
            fontSize="xs"
            text="Verbraucherinformationsblatt"
            url={product?.customer_info_sheet}
          />
          zur Kenntnis
        </Text>
      </BooleanCheckbox>
      <BooleanCheckbox
        control={control}
        name="send_marketing_emails"
        value={profile?.data?.send_marketing_emails}
      >
        <Text fontSize={"xs"}>
          Ich bin damit einverstanden, dass die biddz GmbH mir Neuigkeiten wie
          z.B. zu Dardan per E-Mail schickt. Diese Einwilligung kann ich
          jederzeit gegenüber der biddz GmbH (support@biddz.io) widerrufen.
        </Text>
      </BooleanCheckbox>
      {redeemButton}
    </VStack>
  );
};
