import React from "react";
import { Icon, IconButton } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import useDeleteComment from "features/general/comments/hooks/useDeleteComment";

export const CommentDetailDelete = ({ auctionID, commentID, setLoading }) => {
  const { deleteComment } = useDeleteComment(auctionID, commentID, setLoading);

  return (
    <IconButton
      nativeID="delete-comment"
      p={0}
      disabled={deleteComment?.isLoading}
      isLoading={deleteComment?.isLoading}
      onPress={() => deleteComment.mutate()}
      icon={
        <Icon
          size={"xs"}
          name="delete"
          color={"primary.300"}
          as={MaterialCommunityIcons}
        />
      }
    />
  );
};
