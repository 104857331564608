import React from "react";
import { ImageBackground } from "react-native";
import { Box, HStack, Image, Pressable, Text, VStack } from "native-base";
// internal
import { AuctionBookmark } from "./auction-bookmark.component";
import { RunningText } from "theme/typography/running-text.component";
import { PlayAudioSnippetButton } from "common/components/buttons/play-audio-snippet-button.component";
import { AuctionListCardSoldOutBadge } from "features/general/auctions/components/auction-list-card-sold-out-badge.component";
// helpers
import { isBasicCollectible, translate } from "utils/helpers";

export const AuctionListCardLicense = ({ auction, onPress }) => {
  return (
    <Pressable pb={5} onPress={onPress}>
      <VStack borderRadius={"lg"} overflow={"hidden"} shadow={6}>
        {auction?.available_units <= 0 && (
          <AuctionListCardSoldOutBadge
            style={{ transform: [{ rotate: "-40deg" }] }}
            zIndex={100}
            left={-75}
            top={50}
            width={250}
            position="absolute"
            alignItems="center"
          />
        )}
        <ImageBackground
          alt={`${auction?.title} Cover`}
          style={{ height: 250, width: 250 }}
          source={{ uri: auction?.image_with_url?.md?.url }}
        >
          <HStack
            p={4}
            h={250}
            justifyContent={"space-between"}
            bg={{
              linearGradient: {
                end: [1, 0],
                start: [1, 1],
                colors: [
                  "transparent",
                  "transparent",
                  "transparent",
                  "rgba(0, 0, 0, 0.6)",
                ],
              },
            }}
          >
            {auction?.is_upcoming ? (
              <Header
                bg={"secondary.500"}
                fontColor={"secondary.500"}
                value={translate("upcoming")}
                image={require("assets/images/biddz-theme/biddz-logo-black.png")}
              />
            ) : auction?.is_over ? (
              <Header bg={"darkBackground"} value={translate("past")} />
            ) : (
              <Header
                bg={"rgba(132, 132, 132, 0.6)"}
                value={auction?.available_units}
              />
            )}
          </HStack>
          <AuctionBookmark auction={auction} color="lightText" />
        </ImageBackground>

        <Box
          top={200}
          width={250}
          height={250}
          position={"absolute"}
          bg={{
            linearGradient: {
              end: [0, 1],
              start: [0, 0],
              colors: ["transparent", "lightBackground"],
            },
          }}
        />
        {!isBasicCollectible(auction?.type) && (
          <Box
            p={1}
            top={232}
            right={5}
            zIndex={1}
            position={"absolute"}
            borderRadius={"full"}
            bg={"darkBackground"}
          >
            <PlayAudioSnippetButton
              p={0}
              size={"3xl"}
              borderRadius={"full"}
              playIcon={"play-circle-outline"}
              pauseIcon={"pause-circle-outline"}
              id={auction?.products[0]?.license_product?.id}
              uri={auction?.products[0]?.license_product?.audio}
            />
          </Box>
        )}

        <HStack p={3} width={250} bg={"darkBackground"}>
          <VStack flex={1} px={2}>
            <Box w={160}>
              <RunningText
                maxLength={16}
                style={{
                  fontSize: 18,
                  fontWeight: "600",
                  color: "#fff",
                }}
                text={auction?.title}
              />
            </Box>
            <RunningText
              maxLength={32}
              text={auction?.subtitle}
              style={{ color: "#fff" }}
            />
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};

const Header = ({
  bg,
  value,
  fontColor = "lightText",
  image = require("assets/images/biddz-theme/biddz-logo-white.png"),
}) => {
  return (
    <HStack h={8} space={2} alignItems={"center"}>
      <Box
        bg={bg}
        size={8}
        rounded={"full"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image
          size={4}
          source={image}
          alt={"biddz-logo"}
          resizeMode={"contain"}
        />
      </Box>
      <Text color={fontColor} fontWeight={500}>
        {value}
      </Text>
    </HStack>
  );
};
