import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { PayoutListScreen } from "features/royalties/payouts/screens/payout-list.screen";

const Stack = createStackNavigator();

export const PayoutsNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="PayoutList" component={PayoutListScreen} />
    </Stack.Navigator>
  );
};
