import React, { useContext } from "react";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { FirebaseContext } from "services/firebase.context";

export const usePosts = (auctionID, isPublic = true) => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { firestore } = useContext(FirebaseContext);
  React.useEffect(() => {
    if (auctionID) {
      let q = query(
        collection(firestore, `auctions/${auctionID}/posts`),
        orderBy("created_at", "desc")
      );
      if (isPublic) q = query(q, where("is_private", "==", false));
      const cleanUp = onSnapshot(
        q,
        (snapshot) => {
          const postsQuery = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          setPosts(postsQuery);
          setLoading(false);
          console.debug(`🏤 Posts | Auction ${auctionID}`);
        },
        (error) => {
          console.error(error);
        }
      );
      return () => cleanUp();
    }
  }, [auctionID]);
  return { data: posts, isLoading: loading };
};
