import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateMasterRight = (trackID, masterRightID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateMasterRight: useMutation(
      (data) =>
        client
          .patch(`master_rights/${masterRightID}/`, data)
          .then((res) => res.data),
      {
        onSuccess: () => queryClient.invalidateQueries(`track-${trackID}`),
      }
    ),
    error: error,
  };
};

export default useUpdateMasterRight;
