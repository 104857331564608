import React from "react";
import { VStack, Skeleton } from "native-base";

export const SkeletonTextVertical = ({
  numberOfLines = 2,
  color = "skeleton.dark",
  ...props
}) => (
  <VStack {...props} flex={1} h={"100%"} w={"100%"} justifyContent={"center"}>
    {Array(numberOfLines)
      .fill("")
      .map((_unique, i) => (
        <Skeleton
          key={i}
          startColor={color}
          variant="text"
          height={2}
          width={i !== numberOfLines - 1 ? "100%" : "65%"}
          marginY={1}
        />
      ))}
  </VStack>
);
