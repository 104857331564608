import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useReleases = (existing = false) => {
  const { client } = useContext(AxiosContext);

  const params = existing ? { status__not_in: "draft" } : {};

  return useQuery(`releases-${existing ? "existing" : ""}`, () =>
    client.get("/me/releases/", { params }).then((resp) => {
      console.debug("🎵 Releases");
      return resp.data;
    })
  );
};
