import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { TrackEditScreen } from "features/royalties/tracks/screens/track-edit.screen";
import { TrackEditAudioScreen } from "features/royalties/tracks/screens/track-edit-audio.screen";
import { TrackEditContentScreen } from "features/royalties/tracks/screens/track-edit-content.screen";
import { TrackEditArtistsScreen } from "features/royalties/tracks/screens/track-edit-artists.screen";
import { TrackEditShareScreen } from "features/royalties/tracks/screens/track-edit-share.screen";

const Stack = createStackNavigator();

export const TracksNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="TrackEdit" component={TrackEditScreen} />
      <Stack.Screen name="TrackEditAudio" component={TrackEditAudioScreen} />
      <Stack.Screen
        name="TrackEditContent"
        component={TrackEditContentScreen}
      />
      <Stack.Screen
        name="TrackEditArtists"
        component={TrackEditArtistsScreen}
      />
      <Stack.Screen name="TrackEditShare" component={TrackEditShareScreen} />
    </Stack.Navigator>
  );
};
