import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useBenefitTypes = (completeList = false) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `benefit-types`,
    () => {
      return client
        .get(`enums/benefit_types/?is_complete=${completeList}`)
        .then((resp) => {
          console.debug("🌎 Auction Benefits");
          return resp.data;
        });
    },
    {
      enabled: true,
    }
  );
};
