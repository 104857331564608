import React from "react";
import { Divider, VStack, Text, HStack, Image } from "native-base";
import { openBrowserAsync } from "expo-web-browser";
// internal components
import { HorizontalIconButton } from "theme/buttons/horizontal-icon-button.component";
import { TicketCollectibleQRCodeButton } from "features/tickets/ticket-collectibles/components/ticket-collectible-qr-code-button.component";
import { TicketCollectibleDetailAddToCalender } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-event-add-to-calender.component";
import { TicketCollectibleDetailTransferCreateButton } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-event-transfer-button.component";
// helpers
import { translate } from "utils/helpers";

const TicketItem = ({ collectible }) => (
  <HStack
    p={3}
    w={"full"}
    justifyContent="space-between"
    borderWidth={1}
    borderColor="gray.300"
    borderRadius="lg"
  >
    <HStack space={3} flex={1}>
      <Image
        h={6}
        w={6}
        alt="Ticket Symbol"
        source={require("assets/images/features/ticket.png")}
      />
      <Text fontSize={["sm", "md"]} fontWeight="semibold">
        {collectible?.product?.subtitle}
      </Text>
    </HStack>
    {collectible?.ticket?.has_access && (
      <Image
        h={6}
        w={6}
        alt="QR Code Symbol"
        source={require("assets/images/icons/tickets/qr-code.png")}
      />
    )}
  </HStack>
);

export const TicketCollectibleDetailMyTickets = ({
  isDardan,
  collectible = null,
  collectibles,
  event,
}) => (
  <VStack
    space={5}
    minW={{ base: "100%", lg: 450 }}
    maxW={{ base: "100%", lg: 450 }}
  >
    <VStack p={8} space={5} shadow={5} bg={"light.50"} borderRadius={"xl"}>
      <Text fontSize={["md", "lg"]} bold>
        {`${translate("my_tickets")} ${
          collectibles ? `(${collectibles?.length})` : ``
        }`}
      </Text>

      {collectibles?.map((c, index) => (
        <TicketCollectibleQRCodeButton
          key={index}
          activeSlide={index}
          collectibles={collectibles}
          pressableChildren={<TicketItem key={c.id} collectible={c} />}
        />
      ))}

      <Divider />

      {!!collectible &&
        !collectible?.is_mint_requested &&
        !isDardan &&
        collectible?.category === "ticket" && (
          <TicketCollectibleDetailTransferCreateButton
            collectible={collectible}
          />
        )}

      {!isDardan && <TicketCollectibleDetailAddToCalender event={event} />}
      {!!collectible && collectible?.category === "ticket" && (
        <HorizontalIconButton
          fontSize={"sm"}
          fontWeight={"semibold"}
          text={translate("support")}
          iconName={"help-circle-outline"}
          onPress={() => openBrowserAsync("mailto:support@biddz.io")}
        />
      )}
    </VStack>
    {collectible?.category != "external_ticket" && (
      <HStack p={6} space={4} borderRadius="xl" bg="gray.100">
        <Image
          h={6}
          w={6}
          alt="Mobile Phone Symbol"
          source={require("assets/images/features/mobile.png")}
          resizeMode="contain"
        />
        <VStack space={1} flex={1}>
          <Text fontSize={["xs", "sm"]} fontWeight="semibold">
            {translate("ticket_instruction_title")}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {translate("ticket_instruction_description")}
          </Text>
        </VStack>
      </HStack>
    )}
  </VStack>
);
