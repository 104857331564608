import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useOrder = (id, initialData = {}, refetchInterval = 10000) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `order-${id}`,
    () =>
      client.get(`orders/${id}/`).then((resp) => {
        console.debug(`🚀 Order ${id}`);
        return resp.data;
      }),
    {
      refetchInterval: refetchInterval,
      initialData: initialData,
    }
  );
};
