import React, { useState } from "react";
import { FormControl, Text, VStack } from "native-base";
// utility functions
import { translate } from "utils/helpers";
//components
import { Alert } from "theme/feedback/alert.component";
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { ProfileEditImage } from "features/accounts/profiles/components/profile-edit-image.component";
import { ProfileEditSocials } from "features/accounts/profiles/components/profile-edit-socials.component";
import { ProfileEditToggleIsPublic } from "features/accounts/profiles/components/profile-edit-visibility-toggles.component";
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
import { FormControlTextarea } from "theme/forms/form-control-textarea.component";

export const ProfileEditForm = ({ control, profile }) => {
  const [showAlert, setShowAlert] = useState(false);

  return (
    <VStack
      alignSelf={"center"}
      maxWidth={992}
      space={10}
      borderRadius={"xl"}
      p={5}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      {showAlert && (
        <Alert
          title={translate("wallet_connection_error")}
          text={translate("wallet_connection_error_desc")}
        />
      )}
      <FormControl>
        <Text pb={2} fontSize={["xl", "2xl"]} fontWeight={"semibold"}>
          {translate("username")}
        </Text>
        {!profile.isFetched ? (
          <SkeletonRounded my={2} height={"45%"} width={"100%"} />
        ) : (
          <FormControlInput
            name="username"
            control={control}
            rules={{
              pattern: {
                value: /^(?!.*(?:biddz|admin))[a-zA-Z0-9]+$/i,
                message: translate("username_incorrect_format"),
              },
            }}
            value={profile.data?.username}
            placeholder={translate("username").toLowerCase()}
          />
        )}
        <FormControl.HelperText>
          {translate("username_helper_text")}
        </FormControl.HelperText>
      </FormControl>
      <ProfileEditImage
        isLoading={!profile.isFetched}
        heading={translate("profile_image")}
        url={profile.data?.image_with_url?.xs?.url}
        title={translate("upload_profile_img")}
        description={translate("profile_img_desc")}
      />
      <ProfileEditImage
        isLoading={!profile.isFetched}
        heading={translate("banner_image")}
        isCover={true}
        url={profile.data?.cover_image_with_url?.md?.url}
        title={translate("upload_banner_img")}
        description={translate("banner_img_desc")}
      />
      <ProfileEditSocials
        isLoading={!profile.isFetched}
        profile={profile}
        control={control}
      />
      <SkeletonFormControlInput
        label={translate("biography")}
        isLoading={!profile.isFetched}
        height={96}
      >
        <FormControlTextarea
          name={"bio"}
          label={translate("biography")}
          control={control}
          placeholder={translate("biography_placeholder_text")}
          value={profile.data?.bio}
        />
      </SkeletonFormControlInput>
      <ProfileEditToggleIsPublic
        control={control}
        name={"is_public"}
        isLoading={!profile.isFetched}
        value={profile.data?.is_public}
      />
    </VStack>
  );
};
