import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateTrackMusician = (trackID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createTrackMusician: useMutation(
      (params) =>
        client
          .post(`tracks/${trackID}/musicians/`, params)
          .then((res) => res.data),
      {
        onSuccess: () => queryClient.invalidateQueries(`track-${trackID}`),
      }
    ),
    error: error,
  };
};

export default useCreateTrackMusician;
