import { useContext, useEffect, useState } from "react";
import {
  query,
  collection,
  where,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { FirebaseContext } from "services/firebase.context";

export const usePolygonWalletUserID = (userID) => {
  const { firestore } = useContext(FirebaseContext);
  const [wallet, setWallet] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userID) {
      setIsLoading(true);
      console.log(`👾 Fetching wallet for user`, userID);
      const walletQuery = query(
        collection(firestore, "polygon_wallets"),
        where("user_uid", "==", userID),
        limit(1)
      );

      const unsub = onSnapshot(
        walletQuery,
        (snapshot) => {
          snapshot.docs?.map((doc) => setWallet(doc.data()));
          setIsLoading(false);
        },
        (error) => {
          console.error(`❌ ${error}`);
          setIsLoading(false);
        }
      );
      return unsub;
    }
  }, [userID]);

  return { data: wallet, isLoading: isLoading };
};
