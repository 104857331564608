import React, { useEffect } from "react";
import {
  HStack,
  VStack,
  Image,
  Text,
  Progress,
  Stack,
  Button,
} from "native-base";
import Constants from "expo-constants";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { CopyTextButton } from "theme/buttons/copy-text-button.component";
// utility functions
import { translate, getLocalizedNumber } from "utils/helpers";
// internal hooks
import useCreateLicenseProduct from "features/royalties/license-products/hooks/useCreateLicenseProduct";
import useCreateAuction from "features/general/auctions/hooks/useCreateAuction";

const Item = ({ title, value, ...props }) => (
  <HStack justifyContent="space-between" {...props}>
    <Text fontSize={["md", "lg"]} fontWeight="semibold">
      {title}
    </Text>
    <Text fontSize={["md", "lg"]}>{value}</Text>
  </HStack>
);

const statuses = {
  draft: "draft",
  under_review: "under_review",
  action_required: "action_required",
  offer: "action_required",
  accepted_offer: "upcoming",
  rejected_offer: "cancelled",
  rejected: "cancelled",
};

export const AuctionInfoPerformance = ({ auction }) => {
  const navigation = useNavigation();
  const product = auction?.products[0];
  const release = auction?.releases[0];
  const soldBiddzAvg = (
    (product?.locked_units / auction?.units) *
    100
  )?.toFixed(2);
  const releaseID = auction?.releases[0]?.id ?? "";
  const { createLicenseProduct } = useCreateLicenseProduct(releaseID);
  const { createAuction } = useCreateAuction(releaseID);

  const now = dayjs(new Date());

  const STATUS =
    auction?.status === "accepted_offer"
      ? now.isBefore(auction?.upcoming_at)
        ? "pending"
        : auction?.is_upcoming
        ? "upcoming"
        : auction?.is_active
        ? "active"
        : "over"
      : statuses[auction?.status];

  useEffect(() => {
    if (createLicenseProduct.isSuccess) createAuction.mutate();
  }, [createLicenseProduct.isSuccess]);

  useEffect(() => {
    if (createAuction.isSuccess) {
      navigation.navigate("Auctions", {
        screen: "AuctionEditInfo",
        params: {
          id: createAuction.data?.id,
          auctionPartials: createAuction.data,
        },
      });
    }
  }, [createAuction.isSuccess]);

  return (
    <VStack space={10}>
      <HStack
        mx={5}
        p={3}
        space={4}
        borderRadius="xl"
        borderWidth={1}
        borderColor="light.800"
        alignItems="center"
      >
        <Image
          size="sm"
          borderRadius="lg"
          resizeMode="contain"
          alt={auction?.title + " Cover"}
          source={
            auction?.image_with_url?.sm?.url
              ? { uri: auction?.image_with_url?.sm?.url }
              : require("assets/images/features/release-banner.jpeg")
          }
        />
        <VStack space={1}>
          <Text fontSize={["md", "lg"]} bold>
            {release?.title}
          </Text>
          <Text fontSize={["sm", "md"]}>
            {translate("auction")}{" "}
            {dayjs(auction?.start_date).format("DD.MM.YYYY")} -{" "}
            {dayjs(auction?.end_date).format("DD.MM.YYYY")}
          </Text>
          <Text fontSize={["sm", "md"]} color="light.800">
            {translate("release_date")}{" "}
            {dayjs(release?.date).format("DD.MM.YYYY")}
          </Text>
        </VStack>
      </HStack>

      <VStack mx="5" space="3">
        <Text fontSize={["md", "lg"]} fontWeight="semibold">
          {translate("deal_share_link_title")}
        </Text>
        <Text fontSize={["sm", "md"]}>
          {translate("deal_share_link_description")}
        </Text>
        <HStack space="3" alignItems="center">
          <CopyTextButton
            nativeID={"copy-fan-deal-link"}
            text={
              Constants.expoConfig.extra?.APP_BASE_URL + `/deals/${auction.id}`
            }
          />
          <Button
            px="6"
            variant="outline"
            borderRadius="xl"
            _text={{
              fontSize: ["md", "lg"],
              fontWeight: "semibold",
              color: "light.700",
            }}
            onPress={() =>
              navigation.navigate("Auctions", {
                screen: "AuctionDetail",
                params: { id: auction.id, initialData: auction },
              })
            }
          >
            {translate("to_deal")}
          </Button>
        </HStack>
      </VStack>

      <VStack
        p={7}
        space={3}
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
      >
        <Text mb={5} fontSize={["lg", "xl"]} bold>
          {translate("auction_detail")}
        </Text>
        <Item
          title={translate("contract_start_date")}
          value={dayjs(auction?.start_date).format("DD.MM.YYYY")}
        />
        <Item
          title={translate("contract_end_start")}
          value={dayjs(auction?.end_date).format("DD.MM.YYYY")}
        />
        <Item
          title={translate("version")}
          value={product?.license_product?.tracks[0]?.version}
        />
        {["offer", "accepted_offer", "rejected_offer"].includes(
          auction?.status
        ) && (
          <Item
            title={translate("price_per_biddz")}
            value={`${getLocalizedNumber(product?.price_per_unit)} €`}
          />
        )}
        <VStack mt={5} space={2}>
          <Item title={translate("status")} value={translate(STATUS)} />
          <Progress
            h={5}
            value={soldBiddzAvg}
            rounded="0"
            bg="light.500"
            _filledTrack={{ rounded: 0, bg: "primary.700" }}
          />
          <Text fontSize={["md", "lg"]} textAlign="right">
            {getLocalizedNumber(product?.locked_units)} von{" "}
            {getLocalizedNumber(auction?.units)} verkauft {`(${soldBiddzAvg}%)`}
          </Text>
        </VStack>
        {auction?.status === "offer" && (
          <Stack justifyContent={"flex-end"}>
            <PrimaryButton
              onPress={() =>
                navigation.navigate("Auctions", {
                  screen: "AuctionOffer",
                  params: { id: auction.id, auctionPartials: auction },
                })
              }
              text={translate("see_deal_offer")}
            />
          </Stack>
        )}
      </VStack>
      {auction?.is_over && (
        <PrimaryButton
          p={[5, 0]}
          onPress={() => createLicenseProduct.mutate()}
          text={translate("start_new_fan_deal")}
        />
      )}
    </VStack>
  );
};
