import { HStack, VStack, useMediaQuery } from "native-base";

export const ResponsiveStack = ({ children, ...props }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const Container = isDesktop ? HStack : VStack;

  return (
    <Container key={"responsive-stack"} my={{ lg: "16" }} {...props}>
      {children}
    </Container>
  );
};
