import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { TransferDetailScreen } from "./screens/transfer-detail.screen";

const Stack = createStackNavigator();

export const TransfersNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="TransferDetail" component={TransferDetailScreen} />
    </Stack.Navigator>
  );
};
