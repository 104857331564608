import React, { memo } from "react";
import { ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  HStack,
  Pressable,
  Text,
  Image,
  Divider,
  Box,
  VStack,
  useMediaQuery,
} from "native-base";
// utility functions
import { getRankImage } from "utils/helpers";

export const AuctionCollectibleDetail = memo(
  ({ rank, holder, isCollectible = false }) => {
    const [isDesktop] = useMediaQuery({ minWidth: 1200 });
    const navigation = useNavigation();
    return (
      holder !== undefined && (
        <VStack>
          <HStack my={5} alignItems={"center"} justifyContent="space-between">
            <Box w={"15%"} justifyContent="flex-start">
              <ImageBackground
                source={getRankImage(rank)}
                style={{
                  width: 30,
                  height: 35,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                resizeMode="cover"
              >
                <Text
                  textAlign="center"
                  color={[1, 2, 3].includes(rank) ? "#000" : undefined}
                >
                  {rank}
                </Text>
              </ImageBackground>
            </Box>

            <Pressable
              w="30%"
              onPress={() =>
                navigation.navigate("Profiles", {
                  screen: "ProfileDetail",
                  params: { profileID: holder?.profile_id },
                })
              }
            >
              <HStack
                justifyContent={"flex-start"}
                alignItems={"center"}
                space={[2, 3]}
              >
                <Image
                  alt="profile-image"
                  size={[8, 12]}
                  borderRadius={[5, 10]}
                  source={
                    holder?.image?.xs?.url
                      ? {
                          uri: holder?.image?.xs?.url,
                        }
                      : require("assets/images/features/profile-picture.png")
                  }
                />
                <Text
                  fontSize={["sm", "md"]}
                  fontWeight={"semibold"}
                  noOfLines={1}
                >
                  {holder?.username
                    ? holder?.username
                    : `user-${holder?.profile_id?.slice(-4)}`}
                </Text>
              </HStack>
            </Pressable>

            <Box alignItems="flex-end" w="15%">
              {!isCollectible ? (
                <HStack
                  maxWidth={100}
                  space={2}
                  padding={2}
                  height={"100%"}
                  borderRadius={"md"}
                  bg={"secondary.600"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Text color="dark.900" fontWeight={"semibold"}>
                    {holder.units < 10 && 0}
                    {holder.units}
                  </Text>
                  <Image
                    size={5}
                    alt={"@biddz"}
                    resizeMode={"contain"}
                    source={require("assets/images/biddz-theme/biddz-logo-black.png")}
                  />
                </HStack>
              ) : (
                <Text fontWeight="semibold">
                  {holder.units < 10 && 0}
                  {holder.units}
                </Text>
              )}
            </Box>
          </HStack>
          {!isDesktop && <Divider />}
        </VStack>
      )
    );
  }
);
