import React from "react";
import dayjs from "dayjs";
import { Dimensions, Platform } from "react-native";
import { VStack, useMediaQuery, useTheme } from "native-base";
import { LogBox } from "react-native";
import {
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryLabel,
} from "victory";
import { LicenseCollectibleAnalyticsEmpty } from "features/royalties/license-collectibles/components/license-collectible-analytics-empty.component";
import { getLocalizedNumber, translate } from "utils/helpers";

LogBox.ignoreLogs([
  'Warning: Each child in a list should have a unique "key" prop.',
]);

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

export const LicenseCollectibleAnalyticsChart = ({
  setScrollEnabled,
  streams,
  yAxisLabel = "value",
  cumValueBeforeReleaseDate,
  dates,
}) => {
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 992 });

  const timeframe = "day";
  const windowWidth = Dimensions.get("window").width;

  const subtractCumulativeValue = (arr) => {
    return arr.map((item) => ({
      ...item,
      cum_value:
        yAxisLabel === "cum_value"
          ? item.cum_value - cumValueBeforeReleaseDate
          : item.cum_value,
    }));
  };

  const dataAfterReleaseDate = dates?.release
    ? subtractCumulativeValue(
        streams[timeframe].filter(
          (item) =>
            dayjs(item["day"], "DD.MM.YYYY").isSameOrAfter(
              dayjs(dates?.release, "YYYY-MM-DD")
            ) && item
        )
      )
    : subtractCumulativeValue(streams[timeframe]);

  const dataAfterLicenseDate = dataAfterReleaseDate.filter(
    (item) =>
      dayjs(item["day"], "DD.MM.YYYY").isSameOrAfter(
        dayjs(dates?.licenseStart, "YYYY-MM-DD")
      ) && item
  );

  const pastData = subtractCumulativeValue(
    dataAfterReleaseDate.filter(
      (item) =>
        dayjs(item["day"], "DD.MM.YYYY").isBefore(
          dayjs(dates?.licenseStart, "YYYY-MM-DD")
        ) && item
    )
  );

  const props = {
    x: timeframe,
    y: yAxisLabel,
    labels: ({ datum }) =>
      `${getLocalizedNumber(datum[yAxisLabel])}\n ${datum[timeframe]}`,
    labelComponent: (
      <VictoryTooltip
        style={{
          fontSize: isSmallScreen ? "18px" : "40px",
        }}
        cornerRadius={5}
        key={({ datum }) => datum.x}
        flyoutStyle={{
          stroke: "none",
          fill: "#DBDEFF",
        }}
      />
    ),
  };

  const scatterChartProps = {
    size: isSmallScreen ? 3 : 5,
    style: {
      data: { fill: "#66F5E9" },
    },
  };

  return (
    <VStack alignItems={"center"} mb={5} bg={"gray.100"}>
      <VictoryChart
        padding={{
          top: isSmallScreen ? 20 : 50,
          bottom: isSmallScreen ? 20 : 50,
          right: isSmallScreen ? 50 : 130,
          left: isSmallScreen ? 20 : 130,
        }}
        height={isSmallScreen ? 300 : 500}
        style={isSmallScreen ? { parent: { width: windowWidth } } : {}}
        width={windowWidth}
        domainPadding={isSmallScreen ? { x: 25, y: 25 } : { x: 65, y: 65 }}
        containerComponent={
          <VictoryVoronoiContainer
            style={{
              touchAction: "auto",
            }}
            events={
              Platform.OS !== "web"
                ? {
                    onPressIn: () => {
                      setScrollEnabled(false);
                    },
                    onPressOut: () => {
                      setScrollEnabled(true);
                    },
                  }
                : null
            }
          />
        }
      >
        <VictoryAxis
          fixLabelOverlap
          style={{
            tickLabels: {
              fontSize: isSmallScreen ? "10px" : "30px",
              fontWeight: "semibold",
              fill: colors.dark["700"],
            },
            axis: { stroke: "transparent", display: "none" },
          }}
          tickFormat={(data) => {
            if (dataAfterReleaseDate?.length > 0) {
              return dayjs(data, "DD.MM.YYYY").format(
                isSmallScreen ? "MMM" : "MMM.YY"
              );
            } else return "";
          }}
          tickCount={10}
        />
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          orientation="right"
          style={{
            axis: { stroke: "transparent", display: "none" },
            ticks: { stroke: "transparent", display: "none" },
            tickLabels: {
              fontSize: isSmallScreen ? "10px" : "25px",
              fontWeight: "semibold",
              fill: colors.dark["700"],
            },
            grid: {
              stroke: colors?.light["600"],
              strokeDasharray: "3 10",
            },
          }}
        />

        <VictoryLine
          {...props}
          data={pastData}
          style={{
            data: {
              stroke: "gray",
              strokeWidth: isSmallScreen ? 2 : 4,
            },
          }}
        />
        <VictoryLine
          {...props}
          data={
            dates?.licenseStart ? dataAfterLicenseDate : dataAfterReleaseDate
          }
          style={{
            data: {
              stroke: colors.secondary["700"],
              strokeWidth: isSmallScreen ? 2 : 4,
            },
          }}
        />
        {pastData.length === 1 && (
          <VictoryScatter {...props} {...scatterChartProps} data={pastData} />
        )}
        {((dataAfterLicenseDate && dataAfterLicenseDate.length === 1) ||
          (dataAfterReleaseDate && dataAfterReleaseDate.length === 1)) && (
          <VictoryScatter
            {...props}
            {...scatterChartProps}
            data={
              dates?.licenseStart ? dataAfterLicenseDate : dataAfterReleaseDate
            }
          />
        )}

        {pastData.length > 0 && dataAfterLicenseDate.length > 0 && (
          <VictoryLine
            labelComponent={<VictoryLabel y={120} />}
            style={{
              data: {
                stroke: colors.secondary["700"],
                strokeWidth: isSmallScreen ? 2 : 4,
                strokeDasharray: 3,
              },
              labels: {
                angle: 90,
                fill: colors.secondary["700"],
                fontSize: isSmallScreen ? 10 : 30,
              },
            }}
            labels={[translate("license_start_date")]}
            x={() =>
              dayjs(dates?.licenseStart, "YYYY-MM-DD").format("DD.MM.YYYY")
            }
          />
        )}
      </VictoryChart>
      {streams && streams["day"].length === 0 && (
        <LicenseCollectibleAnalyticsEmpty />
      )}
    </VStack>
  );
};
