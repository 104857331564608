import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { ProfileEditScreen } from "features/accounts/profiles/screens/profile-edit.screen";
import { ProfileDetailScreen } from "features/accounts/profiles/screens/profile-detail.screen";
import { ProfileEditPersonalDataScreen } from "features/accounts/profiles/screens/profile-edit-personal-data.screen";

const Stack = createStackNavigator();

export const ProfilesNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ProfileEdit" component={ProfileEditScreen} />
      <Stack.Screen name="ProfileDetail" component={ProfileDetailScreen} />
      <Stack.Screen
        name="ProfileEditPersonalData"
        component={ProfileEditPersonalDataScreen}
      />
    </Stack.Navigator>
  );
};
