import React from "react";
import { HStack, Skeleton, VStack, ZStack } from "native-base";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";

export const SkeletonAuctionCard = ({ isCompact = false, ...props }) => (
  <HStack {...props} h={isCompact ? 160 : 200} w={"100%"} overflow={"hidden"}>
    {Array(isCompact ? 2 : 5)
      .fill("")
      .map((_unique, i) => (
        <HStack
          mr={isCompact ? 7 : 2}
          key={i}
          rounded={"xl"}
          flexDirection={"row"}
          justifyContent={isCompact ? "center" : "flex-end"}
          w={isCompact ? ["40%", "30%", "20%"] : ["80%", "65%", "45%"]}
        >
          {isCompact ? (
            <ZStack flex={1}>
              <Skeleton
                rounded={15}
                h={160}
                w={160}
                overflow={"hidden"}
                startColor={"skeleton.dark"}
              />
              <HStack mt={"120px"} justifyContent={"center"} w={"75%"} mx={4}>
                <SkeletonTextVertical
                  numberOfLines={2}
                  color={"skeleton.light"}
                />
              </HStack>
            </ZStack>
          ) : (
            <ZStack flex={1}>
              <Skeleton
                h={"100%"}
                w={"100%"}
                startColor={"skeleton.dark"}
                rounded={15}
                overflow={"hidden"}
              />
              <HStack mt={1} h={"100%"} w={"100%"} px={3} py={5}>
                <Skeleton
                  startColor="skeleton.light"
                  width={150}
                  height={150}
                  rounded={15}
                  overflow={"hidden"}
                />
                <VStack flex={1} ml={2}>
                  <Skeleton startColor="skeleton.light" h={"20%"} w={"30%"} />
                  <SkeletonTextVertical
                    numberOfLines={3}
                    color={"skeleton.light"}
                  />
                  <Skeleton
                    startColor="skeleton.light"
                    alignSelf={"flex-end"}
                    size={8}
                    rounded={"full"}
                    overflow={"hidden"}
                  />
                </VStack>
              </HStack>
            </ZStack>
          )}
        </HStack>
      ))}
  </HStack>
);
