import React from "react";
import { HStack, VStack, Text, Pressable } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal
import { translate } from "utils/helpers";
import { AppIconButtonWithOutline } from "theme/buttons/app-icon-button-with-outline";

export const OrderPreviewAddPaymentMethodButton = () => {
  const navigation = useNavigation();
  const onAdd = () => navigation.navigate("PaymentMethods");

  return (
    <Pressable onPress={onAdd}>
      <HStack
        p={5}
        rounded={"xl"}
        bg={"gray.100"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <VStack space={2}>
          <Text bold fontSize={["md", "lg"]} maxW={[56, 72]} noOfLines={2}>
            {translate("add_payment_methods")}
          </Text>
          <Text fontSize={["sm", "md"]} color={"gray.500"}>
            VISA, Mastercard, etc.
          </Text>
        </VStack>
        <AppIconButtonWithOutline
          p={0}
          size={"sm"}
          name={"plus"}
          onPress={onAdd}
          borderRadius={"full"}
          borderColor={"darkText"}
        />
      </HStack>
    </Pressable>
  );
};
