import React, { useContext } from "react";
import { VStack, Text, Pressable, HStack } from "native-base";
// internal components
import { AppIconButtonWithText } from "theme/buttons/app-icon-button-with-text.component";
import { TrackMasterRightStatus } from "features/royalties/tracks/components/track-master-right-status.component";
// internal hooks
import { useMasterRightRoles } from "src/features/royalties/master-rights/hooks/useMasterRightRoles";
// helpers
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";

export const TrackMasterRights = ({ navigation, track, release }) => {
  const { userDetails } = useContext(AuthenticationContext);
  const { data: masterRightRoles } = useMasterRightRoles();
  const splittedRightsTotal = track?.master_rights
    ?.map((m) =>
      m?.owner_id !== userDetails?.id
        ? ["draft", "pending"].includes(m?.status)
          ? m?.share
          : null
        : null
    )
    .reduce((total, val) => total + val);

  return (
    <VStack p="5" _light={{ bg: "light.100" }} _dark={{ bg: "dark.900" }}>
      <HStack mb="10" px={[0, 3]} space={[2, 5]} justifyContent="space-between">
        <Text flex={1} fontSize="md" bold>
          {translate("artists")}
        </Text>
        <Text flex={1} fontSize="md" textAlign="center" bold>
          {translate("role")}
        </Text>
        <Text flex={1} fontSize="md" textAlign="center" bold>
          {translate("status")}
        </Text>
        <Text flex={1} fontSize="md" textAlign={["center"]} bold>
          {translate("share")}
        </Text>
      </HStack>
      {track.master_rights?.map((masterRight, i) => {
        const role =
          masterRightRoles?.find((r) => r.value === masterRight.role)?.label ||
          "";
        return (
          <Pressable
            key={masterRight.id}
            onPress={() =>
              navigation.navigate("MasterRights", {
                screen: "CreateTrackMasterRight",
                params: {
                  trackID: track.id,
                  releaseID: release?.id,
                  masterRightID: masterRight.id,
                  trackPartials: track,
                },
              })
            }
          >
            <HStack
              key={masterRight.id}
              px={[0, 3]}
              py="3"
              space={[2, 5]}
              alignItems="center"
              justifyContent="space-between"
              borderTopWidth={1}
              borderColor="dark.600"
              borderBottomWidth={i === track.master_rights.length - 1 ? 1 : 0}
            >
              <Text flex={1} fontSize={["md", "lg"]}>
                {masterRight.owner_id !== userDetails?.id
                  ? masterRight.name
                  : translate("me")}
              </Text>

              <Text
                flex={1}
                fontSize={["md", "lg"]}
                fontWeight="semibold"
                textAlign="center"
              >
                {role}
              </Text>

              <TrackMasterRightStatus flex={1} masterRight={masterRight} />

              <HStack
                flex={1}
                space="1"
                alignItems="center"
                justifyContent={["flex-start", "flex-end"]}
              >
                <Text
                  py="1"
                  px="4"
                  borderRadius="lg"
                  fontSize={["sm", "md"]}
                  _light={{ bg: "light.200" }}
                  _dark={{ bg: "darkBackground" }}
                >
                  {masterRight?.owner_id !== userDetails?.id
                    ? Math.round(masterRight?.share * 1000) / 10
                    : Math.round(
                        (masterRight?.share - splittedRightsTotal) * 1000
                      ) / 10}
                </Text>
                <Text fontSize={["sm", "md"]}> %</Text>
              </HStack>
            </HStack>
          </Pressable>
        );
      })}
      <HStack mt={3} justifyContent="flex-end">
        <AppIconButtonWithText
          nativeID="add-track-master-right"
          _dark={{ bg: "light.100" }}
          _light={{ bg: "dark.900" }}
          iconName="plus"
          text={translate("add_person")}
          onPress={() => {
            navigation.navigate("MasterRights", {
              screen: "CreateTrackMasterRight",
              params: { trackID: track.id, releaseID: release?.id },
            });
          }}
        />
      </HStack>
    </VStack>
  );
};
