import React, { createContext, useState, useRef, useEffect } from "react";
import { Audio } from "expo-av";
import { Toast } from "native-base";
import { translate } from "utils/helpers";
// internal components
import { ToastAlert } from "theme/feedback/toast-alert.component";

interface AudioSnippetContextInterface {
  loading: boolean;
  playing: boolean;
  snippetID: string;
  playSnippet: (uri: string, snippetID: string) => Promise<void>;
  pauseSnippet: () => Promise<void>;
  stopSnippet: () => Promise<void>;
  unloadSnippet: () => Promise<void>;
}

const showError = (error) => {
  console.debug("Loading snippet error: ", error);
  Toast.show({
    placement: "top",
    render: ({ id }) => (
      <ToastAlert
        closable
        id={id}
        status="error"
        description={translate("something_went_wrong")}
      />
    ),
  });
};

export const AudioSnippetContext =
  createContext<AudioSnippetContextInterface | null>(null);

export const AudioSnippetContextProvider = ({ children }): any => {
  const sound = useRef(new Audio.Sound());

  const [id, setID] = useState("");
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const playSnippet = async (uri: string, snippetID: string) => {
    setLoading(true);
    const currentSnippetID = id;
    setID(snippetID);

    try {
      // User playing the paused snippet
      if (currentSnippetID === snippetID) {
        // Load sound if not already loaded.
        if (!sound.current._loaded) await sound.current.loadAsync({ uri });
        if (finished) {
          await sound.current.replayAsync();
          setFinished(false);
        } else await sound.current.playAsync();
      }
      // User playing a new snippet
      if (currentSnippetID !== snippetID) {
        // Unload previous sound
        if (sound.current._loaded) {
          await sound.current.unloadAsync();
        }
        // Load and play new sound
        await sound.current.loadAsync({ uri });
        await sound.current.playAsync();
      }
    } catch (error) {
      showError(error);
    }
  };

  const pauseSnippet = async () => {
    await sound.current.pauseAsync();
  };

  const stopSnippet = async () => {
    await sound.current.stopAsync();
  };

  const unloadSnippet = async () => {
    if (sound.current._loaded) await sound.current.unloadAsync();
  };

  const onPlaybackStatusUpdate = (playbackStatus) => {
    if (!playbackStatus.isLoaded) {
      if (playbackStatus.error) {
        showError(playbackStatus.error);
      }
    } else {
      if (playbackStatus.isPlaying) {
        setLoading(false);
      }
      setPlaying(playbackStatus.isPlaying);

      if (
        (playbackStatus.didJustFinish && !playbackStatus.isLooping) ||
        playbackStatus.positionMillis === playbackStatus.durationMillis
      ) {
        setFinished(true);
      }
    }
  };

  useEffect(() => {
    sound.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);

    return () => {
      unloadSnippet();
    };
  }, []);

  return (
    <AudioSnippetContext.Provider
      value={{
        loading,
        playing,
        snippetID: id,
        playSnippet,
        pauseSnippet,
        stopSnippet,
        unloadSnippet,
      }}
    >
      {children}
    </AudioSnippetContext.Provider>
  );
};
