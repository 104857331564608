import React, { useState, useEffect } from "react";
import { Text, VStack, HStack, Radio } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// helpers
import { translate } from "utils/helpers";

export const MusicianEditPayoutsForm = ({
  profileData,
  payoutData,
  control,
  isLoading,
  isComplete,
  taxable,
  setTaxable,
}) => {
  // TODO enter both but need one
  const isGermanResident = profileData.country_of_residence === `DE`;

  const sortedPayoutData = payoutData?.sort((a, b) =>
    a.is_default_payout_method > b.is_default_payout_method ? -1 : 1
  );

  useEffect(() => {
    setTaxable(!profileData.is_small_business_owner);
  }, [profileData.is_small_business_owner]);

  return (
    <VStack
      alignSelf="center"
      p="5"
      w="100%"
      space="5"
      borderRadius="xl"
      maxWidth={992}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      <SkeletonFormControlInput label="IBAN" isLoading={isLoading}>
        <FormControlInput
          name="iban"
          control={control}
          rules={{ required: true }}
          label="IBAN"
          placeholder="DE1234..."
          infoText={translate("iban_info_text")}
          value={
            payoutData && sortedPayoutData[0]?.public?.iban
              ? `****${sortedPayoutData[0]?.public?.iban}`
              : undefined
          }
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput label="BIC" isLoading={isLoading}>
        <FormControlInput
          name="bic"
          control={control}
          label="BIC"
          placeholder="BIC..."
          value={payoutData && sortedPayoutData[0]?.public?.bic}
        />
      </SkeletonFormControlInput>

      {isGermanResident ? (
        <>
          <HStack
            space="5"
            _light={{ bg: "light.100" }}
            _dark={{ bg: "dark.900" }}
            justifyContent="space-between"
          >
            <Text fontSize={["md", "lg"]}>
              {translate("subject_to_sales_tax")}
            </Text>
            <IconModal
              iconColor="primary.900"
              icon={<AntDesign name="questioncircleo" />}
              contentText={translate("subject_to_sales_tax_info_text")}
            />
          </HStack>
          <Radio.Group
            name="taxable"
            value={taxable}
            onChange={(nextValue) => {
              setTaxable(nextValue);
            }}
          >
            <HStack space="5">
              <Radio isDisabled={isComplete} value={true} size="sm">
                {translate("yes")}
              </Radio>
              <Radio isDisabled={isComplete} value={false} size="sm">
                {`${translate("no")} (${translate("small_company")})`}
              </Radio>
            </HStack>
          </Radio.Group>

          {taxable && (
            <>
              <Text fontSize={["sm", "lg"]} bold>
                {translate("provide_tax_or_ustid")}
              </Text>
              <SkeletonFormControlInput
                label={translate("tax_number")}
                isLoading={isLoading}
              >
                <FormControlInput
                  name="tax_number"
                  label={translate("tax_number")}
                  control={control}
                  value={profileData?.tax_number}
                  infoText={translate("tax_number_info_text")}
                  isDisabled={isComplete}
                />
              </SkeletonFormControlInput>
            </>
          )}
        </>
      ) : (
        <Text fontSize={["sm", "lg"]} bold>
          {translate("enter_ustid")}
        </Text>
      )}
      {(!isGermanResident || (isGermanResident && taxable)) && (
        <SkeletonFormControlInput label="UST ID" isLoading={isLoading}>
          <FormControlInput
            name="vat_id"
            label="UST ID"
            control={control}
            rules={{ required: !isGermanResident }}
            infoText={translate("ust_id_info_text")}
            value={profileData?.vat_id}
            isDisabled={isComplete}
          />
        </SkeletonFormControlInput>
      )}
      {isComplete && (
        <Text>{translate("contact_biddz_to_change_payout_info")}</Text>
      )}
    </VStack>
  );
};
