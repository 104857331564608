import React from "react";
import { useForm } from "react-hook-form";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Checkbox, Icon, Text, VStack, Divider, Modal, Box } from "native-base";
import { FormControlInput } from "theme/forms/form-control-input.component";
// internal
import { PrimaryButton } from "theme/buttons/primary-button.component";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
// utils
import { translate } from "utils/helpers";

export const TicketProductOrderCreateAddMerch = ({
  isCollectible = false,
  isDelivery,
  setIsDelivery,
  showForm,
  setShowForm,
  onSave = undefined,
  fieldsRequired = undefined,
  isFreeMerch = false,
}) => {
  const profile = useUserProfile();
  const { updateProfile } = useUpdateProfile(profile.data?.id);
  const { control, handleSubmit } = useForm();

  const onPress = (data) => {
    updateProfile.mutate(data);
  };

  React.useEffect(() => {
    if (updateProfile?.isSuccess) {
      setShowForm(false);
      setIsDelivery(false);
      if (onSave) onSave();
    }
  }, [updateProfile?.isSuccess]);

  if (fieldsRequired === undefined) {
    fieldsRequired = isCollectible;
  }

  return (
    <VStack space={5}>
      <Modal
        isOpen={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <Modal.Content bg={"light.100"}>
          <Modal.Body>
            <Modal.CloseButton />
            <VStack space={5}>
              <Text fontSize={"xl"} fontWeight="bold">
                {translate("shipping_address")}
              </Text>
              <VStack space={3}>
                <FormControlInput
                  label={translate("first_name")}
                  control={control}
                  name="shipping_address.first_name"
                  rules={{ required: !!fieldsRequired }}
                  value={profile.data?.shipping_address?.first_name}
                />
                <FormControlInput
                  label={translate("last_name")}
                  control={control}
                  name="shipping_address.last_name"
                  rules={{ required: !!fieldsRequired }}
                  value={profile.data?.shipping_address?.last_name}
                />
                <FormControlInput
                  label={`${translate("street")} and ${translate(
                    "house_number"
                  )}`}
                  control={control}
                  name="shipping_address.street"
                  rules={{ required: !!fieldsRequired }}
                  value={
                    profile.data?.shipping_address?.address ||
                    profile.data?.shipping_address?.street
                  }
                />
                <FormControlInput
                  label={translate("address_comment")}
                  control={control}
                  name="shipping_address.street_annex"
                  value={
                    profile.data?.shipping_address?.comment ||
                    profile.data?.shipping_address?.street_annex
                  }
                />
                <FormControlInput
                  width="49%"
                  label={translate("postal_code")}
                  control={control}
                  name="shipping_address.zip_code"
                  rules={{ required: !!fieldsRequired }}
                  value={
                    profile.data?.shipping_address?.postal_code ||
                    profile.data?.shipping_address?.zip_code
                  }
                />
                <FormControlInput
                  width="49%"
                  label={translate("city")}
                  control={control}
                  name="shipping_address.city"
                  rules={{ required: !!fieldsRequired }}
                  value={profile.data?.shipping_address?.city}
                />
                <PrimaryButton
                  text={translate("save")}
                  onPress={handleSubmit(onPress)}
                  disabled={updateProfile.isLoading}
                  isLoading={updateProfile.isLoading}
                />
              </VStack>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      {isFreeMerch && (
        <>
          <Divider />
          <Checkbox
            alignItems={"center"}
            colorScheme={"secondary"}
            flexDirection={"row-reverse"}
            onChange={(value) => {
              setIsDelivery(value);
            }}
            isChecked={isDelivery}
          >
            <Box w="83%">
              <Text>{translate("free_physical_ticket_text")}</Text>
            </Box>
            <Icon
              name={"gift-outline"}
              color={"secondary.600"}
              as={MaterialCommunityIcons}
            />
          </Checkbox>
        </>
      )}
    </VStack>
  );
};
