import React from "react";
import { HStack, Text, VStack } from "native-base";
// internal functions
import { getLocalizedDateTime, translate } from "utils/helpers";
// internal components
import { CountdownTimer } from "common/components/misc/countdown-timer.component";

export const AuctionDetailDateInfo = ({ auction, isDardan = false }) => {
  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };
  const now = new Date();

  return (
    <VStack space={5}>
      <HStack space={2} justifyContent={"space-between"}>
        {Date.parse(auction.start_date) > now ? (
          <>
            <Text fontSize={["sm", "md", "lg"]} color={"gray.500"}>
              {isDardan ? translate("end") : translate("sale_start")}
            </Text>
            {Date.parse(auction.start_date) < now.addHours(6) ? (
              <CountdownTimer endDate={auction?.start_date} />
            ) : (
              <Text fontSize={["sm", "md", "lg"]} bold>
                {getLocalizedDateTime(auction?.start_date)}
              </Text>
            )}
          </>
        ) : (
          <>
            <Text fontSize={["sm", "md", "lg"]} color={"gray.500"}>
              {isDardan ? translate("end") : translate("sale_end")}
            </Text>
            {Date.parse(auction.end_date) < now.addHours(6) ? (
              <CountdownTimer endDate={auction?.end_date} />
            ) : (
              <Text fontSize={["sm", "md", "lg"]} bold>
                {new Date(Date.parse(auction?.end_date)).toLocaleString("de", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
            )}
          </>
        )}
      </HStack>
    </VStack>
  );
};
