import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthenticationContext } from "services/authentication.context";
import { AxiosContext } from "services/axios.context";

export const useReleaseAuctions = (releaseID) => {
  const { client } = useContext(AxiosContext);

  return useQuery(
    `release-${releaseID}-auctions`,
    () =>
      client.get(`me/auctions/?release_id=${releaseID}`).then((resp) => {
        console.debug(`Release ${releaseID} Auctions`);
        return resp.data;
      }),
    {
      enabled: !!releaseID,
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
