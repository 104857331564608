// external libraries
import React from "react";
import { Pressable, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal hooks
import { AuthenticationContext } from "services/authentication.context";
import useBookmarkAuction from "features/general/auctions/hooks/useBookmarkAuction";

export const AuctionBookmark = ({
  auction,
  color = undefined,
  invert = false,
  size = 6,
}) => {
  const [bookmarked, setBookmarked] = React.useState(
    auction?.is_bookmarked?.length > 0
  );
  const { addBookmark, removeBookmark } = useBookmarkAuction();
  const { isAuthenticated, setShowAuthModal } = React.useContext(
    AuthenticationContext
  );

  return (
    <Pressable
      maxH={12}
      nativeID="bookmark-auction"
      onPress={() => {
        if (isAuthenticated) {
          if (bookmarked) {
            removeBookmark.mutate(auction);
          } else {
            addBookmark.mutate(auction);
          }
          setBookmarked(!bookmarked);
        } else setShowAuthModal(true);
      }}
    >
      <Icon
        size={size}
        name={bookmarked ? "bookmark" : "bookmark-outline"}
        _light={{ color: color ? color : invert ? "lightText" : "darkText" }}
        as={MaterialCommunityIcons}
      />
    </Pressable>
  );
};
