import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Pressable,
  FlatList,
  ChevronLeftIcon,
} from "native-base";
// internal hooks
import { useBenefitTypes } from "features/general/auctions/hooks/useBenefitTypes.js";
import { useProductBenefits } from "features/general/auctions/hooks/useProductBenefits";
// internal components
import { Loading } from "common/components/loading/loading.component";
import {
  BenefitCard,
  BenefitCollectableCard,
} from "features/general/auctions/components/auction-benefits-list-card.component";
// utility functions
import { translate } from "utils/helpers";

export const AuctionBenefitsList = ({ auction }) => {
  const [selectedBenefit, setSelectedBenefit] = useState(undefined);
  const product = auction?.products[0];

  const benefitTypes = useBenefitTypes(true);
  const { data: benefits, isFetched: benefitsFetched } = useProductBenefits(
    product?.id
  );

  return (
    <VStack space={2}>
      <Text bold fontSize={["lg", "xl"]} textAlign="center">
        {selectedBenefit ? translate("entry_list") : translate("benefit_list")}
      </Text>

      {selectedBenefit ? (
        <VStack p={7} space={4}>
          <Pressable onPress={() => setSelectedBenefit(undefined)}>
            <HStack space={2} alignItems="center">
              <ChevronLeftIcon size="5" color="white" />
              <Text fontSize={["md", "lg"]}>
                {selectedBenefit.title || translate(selectedBenefit.tier)}
              </Text>
            </HStack>
          </Pressable>

          <FlatList
            p={5}
            showsVerticalScrollIndicator={false}
            data={selectedBenefit.collectible_benefits}
            renderItem={({ item }) => <BenefitCollectableCard benefit={item} />}
            keyExtractor={(item, index) => item.id}
          />
        </VStack>
      ) : !benefitsFetched ? (
        <Loading />
      ) : (
        <FlatList
          p={5}
          data={benefits}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => {
            const benefitType = benefitTypes?.data?.find(
              (b) => b.value === item?.type
            );
            return (
              <BenefitCard
                type={benefitType}
                count={item.number_of_delivered_collectible_benefits}
                total={item.number_of_collectible_benefits}
                title={
                  item.type === "custom"
                    ? item.title || translate(item.tier)
                    : translate(item.type)
                }
                onPress={() => {
                  if (
                    item?.type !== "custom" &&
                    benefitType?.requires_user_input
                  )
                    setSelectedBenefit(
                      item.collectible_benefits.length ? item : undefined
                    );
                }}
              />
            );
          }}
          ListEmptyComponent={
            <Text textAlign="center" fontSize={["md", "lg"]}>
              {translate("no_benefits")}
            </Text>
          }
          keyExtractor={(item, index) => item.id}
        />
      )}
    </VStack>
  );
};
