import React, { useEffect } from "react";
import { VStack } from "native-base";
// internal components
import { ReleaseList } from "features/royalties/releases/components/release-list.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ModalFrame } from "navigation/components/modal-frame.component";
// helper functions
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
import { navigateBack } from "utils/helpers";

export const ExistingReleaseListScreen = ({ navigation }) => {
  const isFirstRouteInParent = useIsFirstRouteInParent();

  useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.navigate("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        h="90%"
        borderTopRadius={20}
        overflow="hidden"
        shadow={9}
        _light={{ bg: "light.200" }}
        _dark={{ bg: "dark.900" }}
      >
        <NavigationHeader
          bg="transparent"
          headerRight={{
            iconName: "close",
            onPress: () => navigateBack(navigation),
          }}
        />
        <ReleaseList existing />
      </VStack>
    </ModalFrame>
  );
};
