import React from "react";
import { Box, HStack, Icon, Text } from "native-base";
import { SimpleLineIcons } from "@expo/vector-icons";
import { translate } from "utils/helpers";

export const ProfileDetailPrivate = () => (
  <Box mt="30%" mx={10} alignItems="center">
    <HStack space={5} maxW={200} alignItems="center">
      <Icon
        size={10}
        color="secondary.600"
        as={<SimpleLineIcons name="lock" />}
      />
      <Text fontSize={["xl", "2xl"]} textTransform="capitalize">
        {translate("account_is_private")}
      </Text>
    </HStack>
  </Box>
);
