import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useMyAuctions = (size = 50) => {
  const { client } = useContext(AxiosContext);

  return useInfiniteQuery(
    "user-auctions",
    ({ pageParam = 1 }) =>
      client
        .get(`/me/auctions/?page=${pageParam}&size=${size}`)
        .then((resp) => {
          console.debug(`💰 Auctions `);
          return resp.data;
        }),
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
