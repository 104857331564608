import { useContext } from "react";
import { Toast } from "native-base";
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";
import { translate } from "utils/helpers";

export const useDeleteUser = () => {
  const { client } = useContext(AxiosContext);
  return {
    deleteUser: useMutation(
      () => client.delete(`/me/`).then((res) => res.data),
      {
        onSuccess: () => {
          Toast.show({
            placement: "top",
            render: ({ id }) => (
              <ToastAlert
                closable
                id={id}
                status="success"
                description={translate("account_deleted_alert_body")}
              />
            ),
          });
        },
      }
    ),
  };
};
