import React from "react";
import { HStack, Box, Text } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

export const PageTitle = ({
  title,
  helpText = null,
  isDisabled = false,
  onSave = undefined,
  nativeID = "",
  ...props
}) => (
  <HStack
    space="2"
    p="5"
    alignItems="center"
    justifyContent="space-between"
    {...props}
  >
    {onSave ? (
      <AppIconButton
        nativeID={nativeID}
        size="xl"
        iconName="content-save-outline"
        disabled={isDisabled}
        onPress={onSave}
      />
    ) : (
      <Box w={7} />
    )}
    <Text flex={1} fontSize={["xl", "2xl"]} textAlign={"center"} bold>
      {title}
    </Text>
    {helpText ? (
      <IconModal
        size={7}
        iconColor="primary.600"
        icon={<AntDesign name="questioncircleo" />}
        contentText={helpText}
      />
    ) : (
      <Box w={7} />
    )}
  </HStack>
);
