import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack } from "native-base";
// utility functions
import { translate, navigateBack } from "utils/helpers";
// internal hooks
import { AuthenticationContext } from "services/authentication.context";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
// internal components
import { Alert } from "theme/feedback/alert.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ProfileEditPersonalDataForm } from "features/accounts/profiles/components/profile-edit-personal-data-form.component";

export const ProfileEditPersonalDataScreen = ({ navigation, route }) => {
  const { goToProfileSwitch = false } = route.params;

  const scrollViewRef = useRef();
  const profileTypeRef = useRef();
  const profile = useUserProfile();
  const { handleSubmit, control } = useForm();
  const { updateProfile } = useUpdateProfile(profile.data?.id);
  const { userDetails } = useContext(AuthenticationContext);

  const [isArtist, setIsArtist] = useState(false);

  const onSave = (data) => {
    data["type"] = isArtist ? "artist" : "fan";
    updateProfile.mutate(data);
  };

  useEffect(() => {
    if (updateProfile.isSuccess) navigateBack(navigation);
  }, [updateProfile.isSuccess]);

  useEffect(() => {
    if (profile.isFetched) {
      setIsArtist(profile.data?.type === "artist");
    }
  }, [profile.isFetched]);

  useEffect(() => {
    if (goToProfileSwitch && isWeb) {
      setTimeout(() => {
        var profileSwitch = profileTypeRef.current;
        if (profileSwitch)
          profileSwitch?.scrollIntoView({ behavior: "smooth" });
      }, 800);
    }
  }, []);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("personal_data")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        ref={scrollViewRef}
        p="5"
        flex={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <VStack safeAreaBottom space={5} pb={5}>
          {!(profile.isLoading || profile.isFetching) &&
            !profile.data?.is_complete && (
              <Alert
                title={translate("action_required")}
                text={translate("complete_personal_information_alert_message")}
                buttonText={translate("complete_information")}
                buttonOnPress={() => {
                  navigation.navigate("Profiles", {
                    screen: "ProfileEditPersonalData",
                    params: { id: userDetails?.id },
                  });
                }}
              />
            )}

          <ProfileEditPersonalDataForm
            scrollViewRef={scrollViewRef}
            goToProfileSwitch={goToProfileSwitch}
            profileTypeRef={profileTypeRef}
            isLoading={!profile.isFetched}
            profileData={profile.data}
            control={control}
            isArtist={isArtist}
            setIsArtist={setIsArtist}
          />

          <PrimaryButton
            nativeID="edit-personal-data"
            disabled={updateProfile.isLoading || profile.isLoading}
            isLoading={updateProfile.isLoading}
            text={translate("save")}
            onPress={handleSubmit(onSave)}
          />
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
