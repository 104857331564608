import React, { useContext, useEffect } from "react";
import { Icon, Pressable } from "native-base";
import { Ionicons } from "@expo/vector-icons";
// internal
import { AudioSnippetContext } from "services/audio-snippet.context";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

export const PlayAudioSnippetButton = ({
  id,
  uri,
  p = 2,
  size = "lg",
  disabled = false,
  autoPlay = false,
  playIcon = "play",
  variant = "filled",
  pauseIcon = "pause",
  borderRadius = "xl",
}) => {
  const { loading, playing, snippetID, playSnippet, pauseSnippet } =
    useContext(AudioSnippetContext);

  const isSameSnippet = snippetID === id;

  const playAudioSnippet = () => {
    playSnippet(uri, id);
  };

  const pauseAudioSnippet = () => {
    pauseSnippet();
  };

  useEffect(() => {
    if (autoPlay) {
      playAudioSnippet();
    }
  }, []);

  return variant === "filled" ? (
    <AppIconButton
      p={p}
      size={size}
      nativeID={"play-audio"}
      borderRadius={borderRadius}
      iconName={playing && isSameSnippet ? pauseIcon : playIcon}
      disabled={uri ? (disabled || loading) && isSameSnippet : true}
      onPress={playing && isSameSnippet ? pauseAudioSnippet : playAudioSnippet}
    />
  ) : (
    <Pressable
      onPress={playing && isSameSnippet ? pauseAudioSnippet : playAudioSnippet}
    >
      <Icon
        size={size}
        as={Ionicons}
        color={"gray.400"}
        name={
          playing && isSameSnippet
            ? `${pauseIcon}-outline`
            : `${playIcon}-outline`
        }
      />
    </Pressable>
  );
};
