import { useContext, useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { FirebaseContext } from "services/firebase.context";

export const useFeaturedAuctions = () => {
  const { firestore } = useContext(FirebaseContext);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(firestore, `app/featured_section/items`),
        orderBy("priority", "desc")
      ),
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        console.debug(`🌟 Featured Items`);
        setItems(items);
        setIsLoading(false);
      },
      (error) => {
        console.debug(`❌ Firestore Error: ${error}`);
        setIsLoading(false);
      }
    );
    return unsub;
  }, []);
  return { data: items, isLoading };
};
