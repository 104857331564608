import React from "react";
import { HStack, VStack, WarningIcon, Text } from "native-base";
import { Link } from "theme/typography/link.component";

export const TermsDescriptionNewUser = () => {
  return (
    <VStack mt={2} space={3}>
      <HStack alignItems="center" space={3}>
        <WarningIcon color={"secondary.600"} size={5} />
        <Text fontSize={["md", "lg"]} fontWeight={"bold"}>
          Fast geschafft!
        </Text>
      </HStack>
      <Text fontSize={["sm", "md"]}>
        Bitte akzeptiere unsere Nutzungsbedingungen und nehme die{" "}
        <Link
          text="Datenschutzerklärung"
          url="https://biddz.io/de/privacy-notice"
        />{" "}
        zur Kenntnis
      </Text>
    </VStack>
  );
};
