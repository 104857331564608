import React from "react";
import {
  VStack,
  HStack,
  Progress as NBProgress,
  Text,
  Box,
  CheckIcon,
} from "native-base";

const Progress = ({ title, active, completed = false, index }) => (
  <VStack flex={1} space={2}>
    <Text
      _light={{ color: active || completed ? "primary.600" : "darkText" }}
      _dark={{ color: active || completed ? "primary.600" : "lightText" }}
      bold={active || completed}
      fontSize={["md", "lg"]}
      textAlign="center"
    >
      {title}
    </Text>
    <Box position="relative">
      <NBProgress
        h={6}
        rounded="0"
        value={completed || active ? 100 : 0}
        _filledTrack={{ rounded: 0 }}
      />
      <Text
        w="100%"
        h="100%"
        lineHeight="sm"
        _light={{ color: active ? "lightText" : "darkText" }}
        _dark={{ color: "lightText" }}
        fontSize={["md", "lg"]}
        textAlign="center"
        position="absolute"
      >
        {completed ? <CheckIcon size="5" color="white" /> : index}
      </Text>
    </Box>
  </VStack>
);

export const CouponDetailProgressBar = ({ activeIndex }) => (
  <HStack space="2" pb="7">
    {["Anmelden", "Einlösen", "Gewinnen"].map((title, index) => (
      <Progress
        key={title}
        title={title}
        index={index + 1}
        active={activeIndex === index}
        completed={index < activeIndex}
      />
    ))}
  </HStack>
);
