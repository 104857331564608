import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateAuctionTrack = (licenseID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateAuctionTrack: useMutation(
      (params) =>
        client
          .patch(`/license_products/${licenseID}/audio/`, params)
          .then((resp) => resp.data),
      {
        onSuccess: () => queryClient.invalidateQueries(`auction`),
      }
    ),
    error: error,
  };
};

export default useUpdateAuctionTrack;
