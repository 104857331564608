import React, { useContext } from "react";
import { Box } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";

export const LicenseProductOrderCreateButton = ({
  px = 10,
  auction,
  isDisabled,
  borderRadius = "xl",
}) => {
  const profile = useUserProfile();
  const navigation = useNavigation();
  const { userDetails, setShowAuthModal } = useContext(AuthenticationContext);

  const onBuyPress = () => {
    if (!!userDetails) {
      if (profile.data?.is_complete) {
        navigation.navigate("Auctions", {
          screen: "LicenseProductOrderCreate",
          params: { auctionID: auction?.id, auctionPartials: auction },
        });
      } else {
        navigation.navigate("Profiles", {
          screen: "ProfileEditPersonalData",
          params: { id: userDetails?.id },
        });
      }
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <Box py={3} px={px} width={"100%"} bg={"neutral.100"}>
      <PrimaryButton
        rounded={"full"}
        onPress={onBuyPress}
        borderRadius={borderRadius}
        text={translate("buy_biddz")}
        nativeID={"buy-license-product"}
        disabled={isDisabled || profile.isFetching}
        logoLeft={require("assets/images/biddz-theme/biddz-logo-white.png")}
      />
    </Box>
  );
};
