import React from "react";
import { Divider, HStack } from "native-base";
import * as Animatable from "react-native-animatable";

export const LoadingSmall = ({
  color = "light.100",
  maxHeight = 35,
  width = "4px",
  pt = 10,
}) => {
  const minHeight = 5;
  const heightChange = {
    0: {
      height: minHeight,
    },
    0.5: {
      height: maxHeight,
    },
    1: {
      height: minHeight,
    },
  };
  return (
    <HStack
      justifyContent={"center"}
      alignItems={"center"}
      height={maxHeight}
      space={1}
      pt={pt}
    >
      {[0, 250, 500, 750, 1000].map((delay) => (
        <Animatable.View
          key={delay}
          animation={heightChange}
          delay={delay}
          duration={1500}
          iterationCount="infinite"
        >
          <Divider
            bg={color}
            orientation="vertical"
            width={width}
            borderRadius={"xl"}
          />
        </Animatable.View>
      ))}
    </HStack>
  );
};
