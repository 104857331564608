import React from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { ScrollView, VStack, Text } from "native-base";
// internal hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateRelease from "features/royalties/releases/hooks/useUpdateRelease";
import { useRetailers } from "features/royalties/releases/hooks/useRetailers";
import { useTerritories } from "features/royalties/releases/hooks/useTerritories";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlDatePicker } from "theme/forms/form-control-date-picker.component";
import { FormControlMultiSelect } from "theme/forms/form-control-multi-select.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const ReleaseEditDistributionScreen = ({ navigation, route }) => {
  const { id: releaseID, releasePartial = {} } = route.params;
  const { control, handleSubmit } = useForm();
  const { data: release, isLoading: releaseLoading } = useRelease(
    releaseID,
    releasePartial
  );
  const { updateRelease } = useUpdateRelease({ id: releaseID });

  const retailers = useRetailers();
  const territories = useTerritories();

  const onPress = async (data) => {
    delete data.label;
    await updateRelease.mutateAsync({ id: release.id, params: data });
    navigation.navigate("ReleaseEditSummary", {
      id: release.id,
      releasePartial: release,
    });
  };

  const onSave = (data) => {
    delete data.label;
    updateRelease.mutate({ id: release.id, params: data });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("release")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        profileProgress={{ type: "release", activeIndex: 3 }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <PageTitle
          nativeID="save-distribution-details"
          onSave={handleSubmit(onSave)}
          title={translate("distribution_details")}
          isDisabled={updateRelease.isLoading || release?.is_delivered}
        />
        {releaseLoading || retailers.isLoading || territories.isLoading ? (
          <Loading />
        ) : (
          <VStack mb={5} space={10}>
            <ResponsiveStack space="10">
              <VStack p="5" space="5" bg={"light.100"} flex={{ lg: 1 }}>
                <FormControlMultiSelect
                  optionsDisabled
                  hideValue="YouTube (Content ID)"
                  name="retailers"
                  label={translate("music_stores")}
                  control={control}
                  rules={{ required: true }}
                  value={
                    release?.retailers ||
                    retailers.data.map(({ value }) => value)
                  }
                  query={retailers}
                />
                <FormControlMultiSelect
                  optionsDisabled
                  name="countries"
                  label={translate("countries")}
                  control={control}
                  rules={{ required: true }}
                  value={
                    release.countries ||
                    territories.data.map(({ value }) => value)
                  }
                  query={territories}
                />
                <FormControlDatePicker
                  name="date"
                  isDisabled={release?.is_delivered}
                  label={translate("proposed_release_date")}
                  control={control}
                  minYear={
                    release?.is_delivered ? dayjs(release?.date).year() : 1990
                  }
                  rules={{ required: true }}
                  value={release.date}
                />
              </VStack>
              <VStack
                p="5"
                space="5"
                _dark={{ bg: "dark.900" }}
                _light={{ bg: "light.100" }}
                flex={{ lg: 1 }}
              >
                <FormControlInput
                  isReadOnly
                  name="label"
                  control={control}
                  label={translate("label")}
                  rules={{ required: true }}
                  placeholder={translate("label")}
                  isDisabled={release?.is_delivered}
                  value={release.label || translate("biddz_records")}
                  infoText={translate("label_info_text")}
                />
                <FormControlInput
                  name="c_line"
                  control={control}
                  value={release.c_line || ""}
                  label={translate("copyright")}
                  placeholder={translate("c_line")}
                  isDisabled={release?.is_delivered}
                  infoText={translate("copyright_info_text")}
                />
                <FormControlInput
                  name="p_line"
                  control={control}
                  label={translate("p_line")}
                  value={release.p_line || ""}
                  placeholder={translate("p_line")}
                  isDisabled={release?.is_delivered}
                  infoText={translate("p_line_info_text")}
                />
              </VStack>
            </ResponsiveStack>

            <Text px="5" fontSize="sm" color="light.700" textAlign="center">
              {translate("release_date_help_text")}
            </Text>

            <PrimaryButton
              px={5}
              showNextIcon
              showBackButton
              text={translate("next")}
              disabled={updateRelease.isLoading}
              isLoading={updateRelease.isLoading}
              onPress={handleSubmit(onPress)}
            />
          </VStack>
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
