import React from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack, HStack, Box, Text, Image } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateTrack from "features/royalties/tracks/hooks/useUpdateTrack";
import { useExplicitTypes } from "features/royalties/tracks/hooks/useExplicitTypes";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";
import { TrackEditHeader } from "features/royalties/tracks/components/track-edit-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditContentScreen = ({ navigation, route }) => {
  const {
    trackID,
    releaseID,
    releasePartials = {},
    trackPartial = {},
  } = route.params;
  const { data: track, isFetched } = useTrack(trackID, trackPartial);
  const { data: release } = useRelease(releaseID, releasePartials);
  const { updateTrack } = useUpdateTrack(release, track);
  const explicitTypes = useExplicitTypes();

  const { control, handleSubmit } = useForm();

  const onPress = async (data) => {
    await updateTrack.mutateAsync(data);
    navigation.navigate("Tracks", {
      screen: "TrackEditArtists",
      params: {
        trackID: track.id,
        trackPartial: track,
        releaseID: release.id,
        releasePartials: release,
      },
    });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <TrackEditHeader step="3" release={release} track={track} />
      <VStack mb={[0, 5]} flex={1}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <PageTitle
            nativeID="save-track-content"
            isDisabled={updateTrack.isLoading}
            title="Content"
          />
          {!isFetched && !explicitTypes.isFetched ? (
            <Loading />
          ) : (
            <VStack
              p="5"
              space="5"
              mt={{ base: 0, lg: 16 }}
              _light={{ bg: "light.100" }}
              _dark={{ bg: "dark.900" }}
            >
              <FormControlSelect
                translateOptions
                name="explicit"
                control={control}
                query={explicitTypes}
                rules={{ required: true }}
                value={track?.explicit || ""}
                isDisabled={track?.is_submitted}
                label={translate("explicit_content")}
                helperText={translate("explicit_content_helper_text")}
                infoText={translate("explicit_content_info_text")}
              />
              <FormControlSelectWithDict
                name="share_policy"
                control={control}
                rules={{ required: true }}
                label={translate("content_id")}
                isDisabled={track?.is_submitted}
                selectedValue={
                  ["Monetize", "Ignore"].includes(track?.share_policy)
                    ? track?.share_policy
                    : ""
                }
                dict={[
                  { label: "-", value: "" },
                  { label: translate("yes"), value: "Monetize" },
                  { label: translate("no"), value: "Ignore" },
                ]}
                infoText={translate("share_policy_info_text")}
                helperText={translate("share_policy_helper_text")}
              />
              <VStack>
                <HStack justifyContent="space-between" alignItems="center">
                  <Text fontSize="md">{translate("pre_listening_start")}</Text>
                  <IconModal
                    iconColor="primary.600"
                    icon={<AntDesign name="questioncircleo" />}
                    contentText={translate("pre_listening_start_help_text")}
                  />
                </HStack>
                <HStack space="2" alignItems="center">
                  <Image
                    source={require("assets/images/features/auction-music-waves-blue.png")}
                    alt="Music Sound Waves"
                    resizeMode="contain"
                    width={35}
                    height={20}
                  />
                  <Text fontSize={["sm", "md"]} color="light.600">
                    {translate("start")}
                  </Text>
                  <Box w={100}>
                    <FormControlInput
                      control={control}
                      name="prelistening_start"
                      isDisabled={track?.is_submitted}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^\d+$/,
                          message: translate("invalid_input"),
                        },
                      }}
                      placeholder="1"
                      value={
                        track?.prelistening_start
                          ? String(track?.prelistening_start)
                          : null
                      }
                    />
                  </Box>
                </HStack>
                <Text fontSize="xs" color="light.700">
                  {translate("prelisting_help_text")}
                </Text>
              </VStack>
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        showBackButton
        disabled={updateTrack.isLoading}
        isLoading={updateTrack.isLoading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
