import React, { memo } from "react";
import { Pressable, Text, VStack, Box, Image } from "native-base";

export const MusicianListCard = memo(({ musician, onPress }) => {
  return (
    <Box mr={[3]} mb={1} py={1} flex={1}>
      <Pressable onPress={onPress}>
        <VStack space={2} alignItems={"center"}>
          <Box
            p={2}
            rounded={"full"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image
              rounded={"full"}
              size={[130, 170]}
              alt={musician?.alias || "musician"}
              source={
                musician?.image_with_url?.md?.url
                  ? { uri: musician?.image_with_url?.md?.url }
                  : require("assets/images/features/profile-picture.png")
              }
            />
          </Box>
          <Text fontSize={["md", "lg"]} fontWeight="semibold">
            {musician.alias}
          </Text>
        </VStack>
      </Pressable>
    </Box>
  );
});
