import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useLanguages = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`languages`, () => {
    console.debug(`languages retrieved`);
    return client.get(`enums/languages/`).then((resp) => resp.data);
  });
};
