export const theme = {
    config: {
        useSystemColorMode: false,
        initialColorMode: "light",
    },
    components: {
        Divider: {
            baseStyle: {
                _dark: {
                    background: "dark.900",
                },
                _light: {
                    background: "dark.100",
                },
            },
        },
        Icon: {
            baseStyle: {
                _dark: {
                    color: "lightText",
                },
                _light: {
                    color: "darkText",
                },
            },
        },
    },
    shadows: {
        0: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
        },
        "-0": {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: -4,
            },
            shadowOpacity: 0.08,
            shadowRadius: 12,
            elevation: 1,
        },
        1: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,
            elevation: 2,
        },
        2: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.22,
            shadowRadius: 2.22,
            elevation: 3,
        },
        3: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
            elevation: 4,
        },
        4: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
        },
        5: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.12,
            shadowRadius: 12,
            elevation: 6,
        },
        6: {
            shadowColor: "#000",
            shadowOffset: {
                width: 2,
                height: 6,
            },
            shadowOpacity: 0.22,
            shadowRadius: 16,
            elevation: 7,
        },
        7: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,
            elevation: 8,
        },
        8: {
            shadowColor: "#000",
            shadowOffset: {
                width: 2,
                height: 4,
            },
            shadowOpacity: 0.64,
            shadowRadius: 12,
            elevation: 9,
        },
        9: {
            shadowColor: "#000",
            shadowOffset: {
                width: 14,
                height: 18,
            },
            shadowOpacity: 0.4,
            shadowRadius: 20,
            elevation: 10,
        },
    },
    colors: {
        lightBackground: "#FDFDFD",
        darkBackground: "#131219",
        // We use lightText for all "white" text on a dark screen + icons
        // We use darkText for all "black" text on a white screen + icons
        lightText: "#FFFFFF",
        darkText: "#27272a",
        primary: {
            50: "#ebebff",
            100: "#cccdfe",
            200: "#a8acfe",
            300: "#828afe",
            400: "#656dfc",
            500: "#4f51ef",
            600: "#4a48e4",
            700: "#413cd6",
            800: "#3b30c8",
            900: "#3217b0",
        },
        secondary: {
            50: "#dffcfb",
            100: "#acf8f1",
            200: "#66f5e9",
            300: "#00eedd",
            400: "#00e4d0",
            500: "#00dbc3",
            600: "#00cbb3",
            700: "#00b89f",
            800: "#00a78f",
            900: "#00886e",
        },
        // We use light.50 to highlight components in the light theme
        light: {
            50: "#FDFDFD",
            100: "#F9FAFB",
            200: "#F1F3F6",
            300: "#EDF1F6",
            400: "#E8ECF1",
            500: "#E4E7EC",
            600: "#D0D5DD",
            700: "#9DA2AA",
            800: "#585D65",
            900: "#30353D",
        },
        // We use dark.900 to highlight components in the dark theme
        // For example the main auction card has the background light.50 & dark.900
        dark: {
            50: "#faf7ff",
            100: "#f3f0ff",
            200: "#eae7f9",
            300: "#dad7e9",
            400: "#b6b3c5",
            500: "#9693a4",
            600: "#6e6b7b",
            700: "#5a5767",
            800: "#3c3948",
            900: "#1b1926",
        },
        spotify: {
            green: "#1DB954",
            light_green: "#1ED760",
            white: "#FFFFFF",
            black: "#191414",
        }
    },
};
