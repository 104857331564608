import React from "react";
import { VStack, ScrollView } from "native-base";
import { useForm } from "react-hook-form";
// internal hooks
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
import useUpdateMusician from "features/accounts/musicians/hooks/useUpdateMusician";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { MusicianEditProfileForm } from "features/accounts/musicians/components/musician-edit-profile-form.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const MusicianEditProfileScreen = ({ navigation }) => {
  const { control, handleSubmit } = useForm();
  const profile = useUserProfile();
  const musician = useUserMusician();
  const { updateProfile } = useUpdateProfile(profile.data?.id);
  const { updateMusician } = useUpdateMusician(musician.data?.id);

  const getParams = (data) => ({
    profile: {
      bio: data.bio,
      socials: {
        instagram: { username: data.instagram },
        tiktok: { username: data.tiktok },
        twitter: { username: data.twitter },
      },
    },
    musician: {
      alias: data.alias,
      apple_id: data.apple_id,
      spotify_id: data.spotify_id,
      is_splits_only_registration: data.is_splits_only_registration,
    },
  });

  const onPress = async (data) => {
    const params = getParams(data);
    await updateProfile.mutateAsync(params.profile);
    await updateMusician.mutateAsync(params.musician);
    navigation.push("Musicians", { screen: "MusicianEditSummary" });
  };

  const onSave = async (data) => {
    const params = getParams(data);
    await updateProfile.mutateAsync(params.profile);
    await updateMusician.mutateAsync(params.musician);
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("profile")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        profileProgress={{ type: "musician", activeIndex: 1 }}
      />
      <ScrollView mt={{ base: 0, lg: 10 }} showsVerticalScrollIndicator={false}>
        <PageTitle
          isDisabled={updateProfile.isLoading || updateMusician.isLoading}
          title={translate("artist_info")}
          helpText={translate("artist_profile_info_text")}
          onSave={handleSubmit(onSave)}
        />
        <VStack space="10">
          <MusicianEditProfileForm
            profileData={profile.data}
            musicianData={musician.data}
            control={control}
            isLoading={profile.isFetching || musician.isFetching}
          />
          <PrimaryButton
            p="5"
            showBackButton
            showNextIcon
            isLoading={updateProfile.isLoading || updateMusician.isLoading}
            text={translate("next")}
            onPress={handleSubmit(onPress)}
          />
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
