import React, { useEffect, useContext, useRef } from "react";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { AlertDialog } from "native-base";
import { setUserId, logEvent } from "firebase/analytics";
import {
  NavigationContainer,
  createNavigationContainerRef,
  getActionFromState,
  getStateFromPath,
} from "@react-navigation/native";
// Config
import { AppNavigator } from "navigation/app.navigator";
// import { navigationRef } from "navigation";

import { appRoutesConfig } from "navigation/route.config";
// Context Providers
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";
import { FirebaseContext } from "services/firebase.context";
import { useAppVersion } from "features/accounts/users/hooks/useTermsVersion";
// Internal Components
import { ScreenLoading } from "navigation/components/screen-loading.component";
//helpers
import { isWeb, translate } from "utils/helpers";

export const navigationRef = createNavigationContainerRef();

export const dispatch = (action) => {
  navigationRef.current?.dispatch(action);
};

export const linkTo = (path, config = null) => {
  if (path !== "/" && path) {
    const state = getStateFromPath(path, config);
    if (state) {
      const action = getActionFromState(state);
      if (action !== undefined) {
        dispatch(action);
      }
    }
  }
};

export const Navigation = ({ queryClient }) => {
  const { data: version } = useAppVersion();
  const { initialized } = useContext(AxiosContext);
  const { userDetails } = useContext(AuthenticationContext);
  const { analytics } = useContext(FirebaseContext);

  //hooks for screen tracking
  const routeNameRef = useRef();

  // Set User Analytics
  useEffect(() => {
    if (userDetails && isWeb) {
      console.debug(`🧮 Setting User Analytics`);
      dataLayer.push({
        user_id: userDetails.id,
      });
      setUserId(analytics, userDetails.id);
    }
  }, [userDetails]);

  // Play intro then navigate to app or auth
  if (!initialized) {
    return <ScreenLoading />;
  } else if (
    version &&
    Constants?.expoConfig?.version < version[Platform.OS]?.version
  ) {
    return (
      <AlertDialog
        isOpen={true}
        maxW={{ base: "90%", md: 400 }}
        alignSelf="center"
      >
        <AlertDialog.Content>
          <AlertDialog.Header>Attention!</AlertDialog.Header>
          <AlertDialog.Body>{translate("update_app_alert")}</AlertDialog.Body>
        </AlertDialog.Content>
      </AlertDialog>
    );
  } else {
    return (
      <NavigationContainer
        ref={navigationRef}
        documentTitle={{
          enabled: false,
          formatter: "biddz",
        }}
        linking={{
          prefixes: [
            Linking.createURL("/"),
            "https://app.biddz.dev/",
            "https://app.biddz.io/",
          ],
          config: appRoutesConfig,
          enabled: true,
        }}
        onReady={() => {
          routeNameRef.current = navigationRef.getCurrentRoute().name;
          if (isWeb) {
            logEvent(analytics, "screen_view", {
              currentScreen: routeNameRef.current,
            });
          }
        }}
        onStateChange={async () => {
          if (isWeb) {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute().name;
            if (previousRouteName !== currentRouteName && isWeb) {
              //   Update Firebase with the name of current screen
              logEvent(analytics, "screen_view", {
                currentScreen: currentRouteName,
              });
            }
            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName;
          }
        }}
      >
        <AppNavigator />
      </NavigationContainer>
    );
  }
};
