import React from "react";
import { HStack, Text, Pressable, Image, Divider, VStack } from "native-base";
import { translate } from "utils/helpers";
import { useRoute } from "@react-navigation/native";

const ReleaseItem = ({ title, onPress, focused }) => (
  <HStack mt={5} alignItems={"center"}>
    <Image
      size={5}
      ml={-0.5}
      alt="Curve"
      source={require("assets/images/features/curve.png")}
    />
    <Pressable mb={-4} p={3} onPress={onPress}>
      <Text
        fontSize={"lg"}
        fontWeight={500}
        color={focused ? "darkText" : "gray.400"}
      >
        {title}
      </Text>
    </Pressable>
  </HStack>
);

export const ReleaseDrawerList = ({ navigation }) => {
  const route = useRoute().name;
  return (
    <HStack flex={1} mb={5}>
      <Divider bg={"gray.200"} w={0.5} orientation={"vertical"} h={"90%"} />
      <VStack flex={1}>
        <ReleaseItem
          title={translate("releases")}
          onPress={() => {
            navigation.navigate("ReleaseTabs", {
              screen: "ReleaseList",
            });
          }}
          focused={route === "AllReleases"}
        />
        <ReleaseItem
          title={translate("deals")}
          onPress={() => {
            navigation.navigate("ReleaseTabs", {
              screen: "ReleaseDeals",
            });
          }}
          focused={route === "AllDeals"}
        />
        <ReleaseItem
          title={translate("insights")}
          onPress={() => {
            navigation.navigate("ReleaseTabs", {
              screen: "ReleaseInsight",
            });
          }}
          focused={route === "ReleaseInsight"}
        />
        <ReleaseItem
          title={"Splits"}
          onPress={() => {
            navigation.navigate("ReleaseTabs", {
              screen: "ReleaseSplits",
            });
          }}
          focused={route === "ReleaseSplits"}
        />
      </VStack>
    </HStack>
  );
};

export const releasesRoutesList = [
  "AllReleases",
  "AllDeals",
  "ReleaseInsight",
  "ReleaseSplits",
];
