import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useStreamingAnalytics = (musicianId) => {
  const { client } = useContext(AxiosContext);

  return useQuery(
    "musician-streaming-analytics",
    () =>
      client
        .get(`musicians/${musicianId}/streaming_analytics/`)
        .then((resp) => resp.data),
    {
      enabled: !!musicianId,
    }
  );
};
