import React from "react";
import { Icon, Spinner, Image, Pressable } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// internal hooks
import useDeletePaymentMethod from "features/general/payment-methods/hooks/useDeletePaymentMethod";

export const DeletePaymentMethodButton = ({
  paymentMethodID,
  orderPreview = false,
}) => {
  const detachPaymentMethod = useDeletePaymentMethod();
  const onPress = () => {
    detachPaymentMethod.mutate(paymentMethodID);
  };
  return detachPaymentMethod.isLoading ? (
    <Spinner
      _light={{ color: "darkText" }}
      _dark={{ color: "lightText" }}
      size={"sm"}
    />
  ) : orderPreview ? (
    <Pressable onPress={onPress}>
      <Image
        alt="delete-payment"
        size={5}
        source={require("assets/images/features/delete-payment.png")}
      />
    </Pressable>
  ) : (
    <Icon
      onPress={onPress}
      as={MaterialCommunityIcons}
      size={5}
      name="delete"
    />
  );
};
