import React from "react";
import { VStack, Text, HStack, Switch } from "native-base";
//internal hooks
import { useCountries } from "features/royalties/releases/hooks/useCountries";
// utility functions
import { translate, isWeb } from "utils/helpers";
// form Components
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
import { FormControlDatePicker } from "theme/forms/form-control-date-picker.component";

interface IProps {
  profileData: any;
  control: any;
  isLoading: boolean;
  isArtist: boolean;
  profileTypeRef: any;
  goToProfileSwitch: boolean;
  scrollViewRef: any;
  setIsArtist: (value: boolean) => void;
}

export const ProfileEditPersonalDataForm = ({
  profileData,
  control,
  isLoading,
  isArtist,
  profileTypeRef,
  setIsArtist,
  goToProfileSwitch,
  scrollViewRef,
}: IProps) => {
  return (
    <VStack
      alignSelf={"center"}
      maxWidth={992}
      space={10}
      borderRadius={"xl"}
      p={5}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      <SkeletonFormControlInput
        label={translate("first_name")}
        helperText={translate("first_name_helper_text")}
        isLoading={isLoading}
      >
        <FormControlInput
          name="first_name"
          label={translate("first_name")}
          helperText={translate("first_name_helper_text")}
          rules={{ required: true }}
          control={control}
          placeholder={translate("first_name")}
          value={profileData.first_name}
        />
      </SkeletonFormControlInput>

      <SkeletonFormControlInput
        label={translate("last_name")}
        helperText={translate("last_name_helper_text")}
        isLoading={isLoading}
      >
        <FormControlInput
          name="last_name"
          label={translate("last_name")}
          helperText={translate("last_name_helper_text")}
          control={control}
          rules={{ required: true }}
          placeholder={translate("last_name")}
          value={profileData.last_name}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("email")}
        isLoading={isLoading}
      >
        <FormControlInput
          label={translate("email")}
          name="email"
          control={control}
          rules={{ required: true, pattern: /\S+\@\S+\.\S+/ }}
          placeholder={translate("email")}
          value={profileData.email}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("birthdate")}
        isLoading={isLoading}
        splitSkeleton
      >
        <FormControlDatePicker
          label={translate("birthdate")}
          name="birthdate"
          control={control}
          maxYear={2022}
          rules={{ required: true }}
          value={profileData.birthdate}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("nationality")}
        isLoading={isLoading}
      >
        <FormControlSelect
          name="nationality"
          label={translate("nationality")}
          rules={{ required: true }}
          control={control}
          query={useCountries()}
          value={profileData.nationality}
        />
      </SkeletonFormControlInput>

      <SkeletonFormControlInput
        label={translate("street")}
        isLoading={isLoading}
      >
        <FormControlInput
          name="street_of_residence"
          label={translate("street")}
          rules={{ required: true }}
          control={control}
          placeholder={translate("street_placeholder_text")}
          value={profileData.street_of_residence}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("house_number")}
        isLoading={isLoading}
      >
        <FormControlInput
          name="house_number_of_residence"
          label={translate("house_number")}
          rules={{ required: true }}
          control={control}
          placeholder={translate("house_number_placeholder_text")}
          value={profileData.house_number_of_residence}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("postal_code")}
        isLoading={isLoading}
      >
        <FormControlInput
          name="postal_code_of_residence"
          label={translate("postal_code")}
          rules={{ required: true, pattern: /^\d+$/ }}
          control={control}
          placeholder={translate("postal_code_placeholder_text")}
          value={profileData.postal_code_of_residence}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput label={translate("city")} isLoading={isLoading}>
        <FormControlInput
          name="city_of_residence"
          label={translate("city")}
          rules={{ required: true }}
          control={control}
          placeholder={translate("city_placeholder_text")}
          value={profileData.city_of_residence}
        />
      </SkeletonFormControlInput>
      <SkeletonFormControlInput
        label={translate("country_of_residence")}
        isLoading={isLoading}
      >
        <FormControlSelectWithDict
          selectedValue={profileData.country_of_residence}
          name="country_of_residence"
          label={translate("country_of_residence")}
          control={control}
          rules={{ required: true }}
          dict={[
            { label: "Germany", value: "DE" },
            { label: "Austria", value: "AT" },
          ]}
          infoText={translate("country_help_text")}
        />
      </SkeletonFormControlInput>

      <HStack
        ref={profileTypeRef}
        onLayout={(event) => {
          if (goToProfileSwitch) {
            const layout = event.nativeEvent.layout;
            setTimeout(() => {
              if (layout?.y && !isWeb) {
                scrollViewRef.current?.scrollTo({
                  y: layout.y + 300,
                  animated: true,
                  behavior: "smooth",
                });
              }
            }, 800);
          }
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize={["md", "lg"]}>{translate("are_you_artist")}</Text>
        <Switch
          isChecked={isArtist}
          onTrackColor="secondary.600"
          _hover={{ onTrackColor: "secondary.600" }}
          onToggle={(value) => setIsArtist(value)}
        />
      </HStack>

      <BooleanCheckbox
        name="send_marketing_emails"
        control={control}
        value={profileData.send_marketing_emails}
      >
        <Text>{translate("email_subscription")}</Text>
      </BooleanCheckbox>
    </VStack>
  );
};
