import React from "react";
import { HStack, VStack, Text, Divider } from "native-base";
import { translate } from "utils/helpers";

export const AuctionSummaryAlbumDetail = ({ release, tracks }) => {
  return (
    <VStack space="4">
      <HStack justifyContent="space-between">
        <Text fontSize={["md", "lg"]}>{translate("album_title")}</Text>
        <Text fontSize={["md", "lg"]} bold>
          {release?.title}
        </Text>
      </HStack>

      {tracks?.map((track, index) => (
        <VStack key={index} space="3">
          <HStack
            py="2"
            pl="5"
            pr="4"
            _light={{ bg: "light.100" }}
            _dark={{ bg: "dark.900" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize={["md", "lg"]} fontWeight="semibold">
              • {translate("track")} {index + 1}.{" "}
              {track.title || translate("untitled_track")}
            </Text>
          </HStack>
          <HStack pl="8" justifyContent="space-between" space="4">
            <Text fontSize={["md", "lg"]}>{translate("artists")}</Text>
            <Text fontSize={["md", "lg"]} fontWeight="semibold">
              {[...track.primary_musicians, ...track.featured_musicians]
                .map((musician) => musician.alias)
                .join(", ")}
            </Text>
          </HStack>
        </VStack>
      ))}
      <Divider />
    </VStack>
  );
};
