import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useMasterRights = (size = 5) => {
  const { client } = useContext(AxiosContext);

  return useInfiniteQuery(
    `master-rights`,
    ({ pageParam = 1 }) =>
      client
        .get(`me/master_rights/?page=${pageParam}&size=${size}`)
        .then((resp) => {
          return resp.data;
        }),
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
