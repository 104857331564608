import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { VStack, HStack, Text, Icon } from "native-base";
// internal functions
import { translate } from "utils/helpers";

export const LicenseProductDetailDisclaimer = ({ auction }) => {
  return (
    <VStack space={5}>
      <VStack
        p={7}
        space={3}
        shadow={2}
        width={"100%"}
        bg={"gray.100"}
        borderRadius={"xl"}
      >
        <HStack space={2} alignItems={"center"}>
          <Icon size={5} as={Ionicons} name={"musical-notes-outline"} />
          <Text bold fontSize={["sm", "md"]} color={"darkText"}>
            {translate("new_comer_disclaimer_title")}
          </Text>
        </HStack>
        <Text fontSize={["sm", "md"]} color={"gray.500"}>
          {translate("new_comer_disclaimer", {
            artistName: auction?.subtitle,
          })}
        </Text>
      </VStack>
    </VStack>
  );
};
