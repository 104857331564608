import React from "react";
import { Text, Pressable, Box } from "native-base";

export const TertiaryButton = ({
  text,
  width = null,
  disabled = false,
  borderRadius = 10,
  onPress,
  ...props
}) => (
  <Pressable onPress={onPress} width={width} disabled={disabled} {...props}>
    <Box
      py={2}
      px={4}
      bg="transparent"
      borderColor="secondary.600"
      alignItems="center"
      borderRadius={borderRadius}
    >
      <Text
        fontWeight="semibold"
        fontSize={["md", "lg"]}
        color="secondary.600"
        textTransform="uppercase"
      >
        {text}
      </Text>
    </Box>
  </Pressable>
);
