import { useContext } from "react";
import { useQueries } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useArtistTransactions = (
  masterRightId,
  dueDate,
  type = "deposit",
  deposit_type = "royalties_actual"
) => {
  const { client } = useContext(AxiosContext);
  const params = { master_right_id: masterRightId, response_type: "series" };

  return useQueries([
    {
      queryKey: `transactions-${masterRightId}`,
      queryFn: () =>
        client
          .get(`/me/artist_transactions/`, {
            params: {
              ...params,
              type: type,
              deposit_type: deposit_type,
            },
          })
          .then((resp) => resp.data),
      staleTime: 300000,
    },
    {
      queryKey: `transactions-${masterRightId}-${dueDate}`,
      queryFn: () =>
        client
          .get(`/me/artist_transactions/`, {
            params: {
              ...params,
              due_date__gt: dueDate,
              type: type,
              deposit_type: deposit_type,
            },
          })
          .then((resp) => resp.data),
      staleTime: 300000,
    },
  ]);
};
