import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  Icon,
  HStack,
  Divider,
  WarningOutlineIcon,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Modal } from "theme/feedback/modal.component";
// internal
import { translate } from "utils/helpers";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";

export const DeleteButton = ({
  modalTitle,
  modalDescription,
  isLoading,
  onPress,
  nativeID = "",
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <Modal
      pressableChildren={
        <Icon
          nativeID={nativeID}
          size="xl"
          name="trash-can"
          as={MaterialCommunityIcons}
        />
      }
      modalChildren={
        <Box
          p="5"
          mx="2"
          borderRadius="xl"
          w={[275, 300, 350]}
          _dark={{ bg: "dark.900" }}
          _light={{ bg: "light.100" }}
        >
          <VStack space="6">
            <HStack space="3">
              <WarningOutlineIcon mt="1" size="5" color="secondary.900" />
              <VStack space="3" flex={1}>
                <Text bold fontSize={["md", "lg"]} numberOfLines={1}>
                  {modalTitle}
                </Text>
                <Text fontSize={["sm", "md"]}>{modalDescription}</Text>
              </VStack>
            </HStack>
            <Divider _light={{ bg: "light.100" }} _dark={{ bg: "dark.900" }} />
            <HStack space="5" justifyContent="flex-end">
              <SecondaryButton
                text={translate("ignore")}
                onPress={toggleModal}
              />
              <PrimaryButton
                disabled={isLoading}
                text={translate("delete")}
                textSize={["lg", "xl"]}
                onPress={onPress}
              />
            </HStack>
          </VStack>
        </Box>
      }
      modalStyles={{
        alignItems: "center",
        bg: "transparent",
      }}
      onPress={toggleModal}
      onClose={toggleModal}
      isOpen={showModal}
    />
  );
};
