import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useAuctions = (heading, params, size = 4) => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const { client } = useContext(AxiosContext);
  const axios = isAuthenticated ? client : client;

  return useInfiniteQuery(
    ["auctions", heading, params],
    ({ pageParam = 1 }) =>
      axios
        .get(`auctions/?page=${pageParam}&size=${size}`, {
          params: params,
        })
        .then((resp) => {
          console.debug(`💰 Auctions | ${JSON.stringify(params)}`);
          return resp.data;
        }),
    {
      enabled: true,
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
