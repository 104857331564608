import React, { memo } from "react";
import {
  HStack,
  VStack,
  Icon,
  Pressable,
  Text,
  Button,
  Image,
} from "native-base";
import { openBrowserAsync } from "expo-web-browser";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal
import { translate } from "utils/helpers";

export const TicketProductDetailSupportButton = memo(() => (
  <VStack
    h={179}
    p={5}
    borderRadius={"xl"}
    bg={"darkBackground"}
    position={"relative"}
    justifyContent={"space-between"}
  >
    <HStack alignItems={"center"} justifyContent={"space-between"}>
      <Text color={"lightText"} fontSize={["lg", "xl"]} fontWeight={"semibold"}>
        {translate("need_help")}
      </Text>
      <Pressable
        zIndex={1}
        onPress={() => openBrowserAsync("https://biddz.io/fan-en/")}
      >
        <Icon
          size={"lg"}
          color={"lightText"}
          as={MaterialCommunityIcons}
          name={"help-circle-outline"}
        />
      </Pressable>
    </HStack>

    <Image
      w={135}
      h={135}
      top={0}
      right={-10}
      zIndex={-1}
      resizeMode={"contain"}
      position={"absolute"}
      alt={"support-logo-outline"}
      source={require("assets/images/features/support-logo-outline.png")}
    />

    <Button
      py={3}
      px={5}
      maxW={95}
      borderRadius={"xl"}
      bg={"secondary.300"}
      _hover={{ bg: "secondary.300" }}
      _pressed={{ bg: "secondary.300" }}
      _text={{ color: "darkText", fontWeight: "semibold" }}
      onPress={() => openBrowserAsync("https://biddz.io/fan-en/")}
    >
      {translate("support")}
    </Button>
  </VStack>
));
