import React from "react";
import {
  Text,
  Box,
  Button,
  Image,
  HStack,
  VStack,
  Icon,
  IconButton,
  useTheme,
  useColorMode,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { navigationRef } from "navigation";
// utility functions
import { navigateBack } from "utils/helpers";

export const PrimaryButton = ({
  text,
  onPress,
  space = 2,
  caption = "",
  nativeID = "",
  logoLeft = null,
  fontSize = "lg",
  disabled = false,
  isLoading = false,
  borderRadius = "xl",
  showNextIcon = false,
  rightComponent = null,
  showBackButton = false,
  logoLeftComponent = null,
  logoLeftStyle = { width: 20, height: 20 },
  ...props
}) => {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  return (
    <HStack direction="row" justifyContent="center" space={3} {...props}>
      {showBackButton && (
        <IconButton
          nativeID="go-back"
          _dark={{
            borderColor: "light.400",
            colorScheme: "light",
            _focus: {
              borderColor: "light.700",
              bg: "transparent",
            },
            _pressed: {
              borderColor: "light.700",
              bg: "transparent",
            },
            _hover: {
              borderColor: "light.700",
              bg: "transparent",
            },
          }}
          _light={{
            borderColor: "dark.900",
            colorScheme: "dark",
            _focus: {
              borderColor: "dark.900",
              bg: "transparent",
            },
            _pressed: {
              borderColor: "dark.900",
              bg: "transparent",
            },
            _hover: {
              borderColor: "dark.900",
              bg: "transparent",
            },
          }}
          borderRadius="xl"
          variant="outline"
          icon={
            <MaterialCommunityIcons
              name="chevron-left"
              size={40}
              color={
                colorMode === "dark" ? colors?.light[400] : colors?.dark[900]
              }
            />
          }
          onPress={() => navigateBack(navigationRef)}
        />
      )}
      <HStack alignItems="center" maxWidth={500} flex={1}>
        <Button
          height={"100%"}
          width={"100%"}
          maxWidth={500}
          onPress={onPress}
          nativeID={nativeID}
          colorScheme={"dark"}
          borderRadius={borderRadius}
          _hover={{ bg: "dark.700" }}
          _pressed={{ bg: "dark.700" }}
          _disabled={{ background: "light.100" }}
          isDisabled={disabled || isLoading}
          bg={"dark.900"}
        >
          <Box alignItems="center">
            {!isLoading ? (
              <VStack alignItems="center">
                {caption && (
                  <Text
                    _dark={{
                      color: "darkText",
                    }}
                    _light={{
                      color: "lightText",
                    }}
                    fontSize="md"
                    fontWeight="light"
                  >
                    {caption}
                  </Text>
                )}
                <HStack alignItems="center" space={space}>
                  {logoLeftComponent
                    ? logoLeftComponent
                    : logoLeft && (
                        <Image
                          style={logoLeftStyle}
                          alt="Button Logo"
                          source={logoLeft}
                          resizeMode="contain"
                        />
                      )}
                  {text && (
                    <Text
                      flexShrink={1}
                      _dark={{
                        color: "darkText",
                      }}
                      _light={{
                        color: "lightText",
                      }}
                      fontSize={fontSize}
                      fontWeight="medium"
                    >
                      {text}
                    </Text>
                  )}
                  {rightComponent}
                </HStack>
              </VStack>
            ) : (
              <Loading pt="0" />
            )}
          </Box>
        </Button>
        {showNextIcon && (
          <Icon
            ml="-7"
            as={MaterialCommunityIcons}
            name="chevron-right"
            size="lg"
            _dark={{ color: "darkText" }}
            _white={{ color: "white" }}
            zIndex="1"
          />
        )}
      </HStack>
    </HStack>
  );
};
