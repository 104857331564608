import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateCoupon = (code) => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation(() =>
    client.patch(`coupons/${code}/`).then((_) => {
      queryClient.invalidateQueries(`collectibles`);
      //queryClient.invalidateQueries(`coupon-${code}`);
    })
  );
};

export default useUpdateCoupon;
