import React from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useCollectibles = (params) => {
  const { isAuthenticated } = React.useContext(AuthenticationContext);
  const { client } = React.useContext(AxiosContext);
  const axios = isAuthenticated ? client : client;

  return useInfiniteQuery(
    [`collectibles`, params],
    ({ pageParam = 1 }) => {
      params["page"] = pageParam;
      params["size"] = 8;
      return axios
        .get(`collectibles/`, {
          params: params,
        })
        .then((resp) => {
          console.debug(`💙 Collectibles |  ${JSON.stringify(params)} |`);
          return resp.data;
        });
    },
    {
      enabled: !!params["owner_id"],
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
