import { useContext, useState } from "react";
import { Platform } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateProfileImage = (isCover = false) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateProfileImage: useMutation(
      (image) => {
        let params = {
          base64: image.base64,
        };
        if (Platform.OS === "web") {
          params["type"] = image.uri.split(";")[0].split("/")[1];
        } else {
          const uri_split = image.uri.split(".");
          params["type"] = uri_split[uri_split.length - 1];
        }
        console.debug(image.uri.split("."));
        console.debug(params.type);
        const url = isCover ? `profiles/cover_image/` : "profiles/image/";
        return client
          .post(url, params)
          .then((res) => res.data)
          .catch((error) => console.debug(error.response.data?.detail));
      },
      {
        onSuccess: () => queryClient.invalidateQueries("profile"),
      }
    ),
    error: error,
  };
};

export default useUpdateProfileImage;
