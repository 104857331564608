import React, { useContext } from "react";
import {
  VStack,
  ScrollView,
  Box,
  Pressable,
  HStack,
  Text,
  useMediaQuery,
} from "native-base";
// internal hooks
import { useMusician } from "features/accounts/musicians/hooks/useMusician";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
// utility functions
import { isWeb, translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { MusicianDetailSekeleton } from "features/accounts/musicians/skeletons/musician-detail.skeleton";
import { MusicianDetailHeader } from "features/accounts/musicians/components/musician-detail-header.component";
import { MusicianDetailProfile } from "features/accounts/musicians/components/musician-detail-profile.component";

export const MusicianDetailScreen = ({ navigation, route }) => {
  const { id: musicianID } = route?.params;
  const { userDetails } = useContext(AuthenticationContext);

  const profile = useUserProfile();
  const userMusician = useUserMusician();
  const musician = useMusician(musicianID);
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  return (
    <ScreenFrame safeAreaBottom={0}>
      <NavigationPanel goesBack={!isWeb} />
      <ScrollView
        height={1}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        {!musician.isLoading ? (
          <VStack space={5} px={isDesktop ? 24 : 0}>
            {profile.data?.type === "artist" &&
              (userMusician.data
                ? musician?.data?.user_id === userDetails?.id &&
                  userMusician?.data?.status === "draft"
                : true) && (
                <HStack
                  flex={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Pressable
                    onPress={() =>
                      navigation.navigate("Profiles", {
                        screen: "ProfileEditPersonalData",
                        params: {
                          id: profile?.data?.id,
                          goToProfileSwitch: true,
                        },
                      })
                    }
                  >
                    <Box p={3} rounded={"sm"} bg={"dark.900"}>
                      <Text color={"lightText"}>
                        {translate("not_an_artist")}
                      </Text>
                    </Box>
                  </Pressable>
                </HStack>
              )}
            <MusicianDetailHeader musician={musician?.data} />
            <MusicianDetailProfile musician={musician.data} />
          </VStack>
        ) : (
          <MusicianDetailSekeleton />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
