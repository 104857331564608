import React, { useContext, useEffect, useRef, useState } from "react";
import {
  VStack,
  HStack,
  ScrollView,
  useMediaQuery,
  Stack,
  Divider,
} from "native-base";
// utility functions
import { isWeb } from "utils/helpers";
// internal hooks
import { AuthenticationContext } from "services/authentication.context";
import { useProduct } from "features/general/products/hooks/useProduct";
import { useCollectible } from "features/general/collectibles/hooks/useCollectible";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { DownloadVideoButton } from "common/components/buttons/download-video-button.component";
import { AuctionCollectibleList } from "features/general/collectibles/components/auction-collectible-list.component";
import { LicenseProductDetailAlbumList } from "features/royalties/license-products/components/license-product-detail-album-list.component";
import { LicenseDetailBenefitList } from "features/royalties/license-benefits/components/license-detail-benefit-list.component";
import { LicenseCollectibleDetailMainInfo } from "features/royalties/license-collectibles/components/license-collectible-detail-main-info.component";
import { LicenseCollectibleDetailExtraInfo } from "features/royalties/license-collectibles/components/license-collectible-detail-extra-info.component";
import { ProductDetailCustomerInfoSheet } from "features/general/products/components/product-detail-customer-info-sheet.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { LicenseProductDetailButtons } from "features/royalties/license-products/components/license-product-detail-buttons.component";
import { AuctionMusiciansList } from "features/general/auctions/components/auction-musicians-list.component";
import { LicenseCollectibleAnalytics } from "../components/license-collectible-analytics.component";

export const LicenseCollectibleDetailScreen = ({ navigation, route }) => {
  const { userDetails } = useContext(AuthenticationContext);
  const { id, initialData = {}, scrollToBenefits = false } = route.params;
  const collectible = useCollectible(id, initialData);
  const product = useProduct(collectible?.data?.product_id);

  const paddingX = 20;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const scrollViewRef = useRef();
  const [layout, setLayout] = useState(undefined);

  const getLicenseReleaseDate = () => {
    if (!product?.isLoading) {
      return product?.data?.license_product?.tracks?.length === 1
        ? product?.data?.license_product?.release?.date
        : undefined;
    } else return undefined;
  };

  useEffect(() => {
    if (scrollToBenefits && isWeb) {
      setTimeout(() => {
        var benefitView = document.getElementById("collectible-benefits");
        if (benefitView) benefitView?.scrollIntoView({ behavior: "smooth" });
      }, 800);
    }
  }, []);

  useEffect(() => {
    if (scrollToBenefits && !isWeb) {
      setTimeout(() => {
        if (layout?.y) {
          scrollViewRef.current?.scrollTo({
            y: layout.y + 300,
            animated: true,
            behavior: "smooth",
          });
        }
      }, 800);
    }
  }, [layout]);

  useEffect(() => {
    if (userDetails && collectible?.isFetched) {
      if (userDetails?.id !== collectible?.data?.owner_id) {
        console.debug(`❌ User is not the Collectible's Owner`);
        navigation.navigate("BottomNavigator", {
          screen: "Home",
        });
      }
    }
  }, [userDetails, collectible?.isFetched]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationPanel goesBack={!isWeb} />
      <ScrollView
        flex={1}
        ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
      >
        <VStack px={isDesktop ? paddingX : 0} space={5}>
          <VStack
            px={5}
            py={10}
            space={10}
            bg={"gray.100"}
            alignItems={"center"}
            mx={isDesktop ? -paddingX : 0}
          >
            <AuctionDetailImage
              url={collectible?.data?.image_with_url?.lg?.url}
              justifyContent={isDesktop ? "flex-end" : "center"}
            />
            <HStack px={isDesktop ? 32 : 5} alignSelf={"flex-end"}>
              <DownloadVideoButton
                videoURL={collectible?.data?.license?.video}
              />
            </HStack>
          </VStack>
          <Stack
            space={isDesktop ? 20 : 5}
            direction={isDesktop ? "row" : "column"}
            justifyContent={isDesktop ? "space-between" : undefined}
          >
            <VStack p={5} space={5}>
              <LicenseProductDetailButtons product={product?.data} />
              {product?.data?.license_product?.release?.primary_musicians && (
                <AuctionMusiciansList
                  musicians={
                    product?.data?.license_product?.release?.primary_musicians
                  }
                />
              )}
              {!isDesktop && (
                <LicenseCollectibleStickyMetadata
                  isDesktop={isDesktop}
                  product={product?.data}
                  collectible={collectible?.data}
                />
              )}
              <LicenseProductDetailAlbumList product={product?.data} />
              {collectible?.data?.benefits?.length > 0 && (
                <VStack
                  nativeID={"collectible-benefits"}
                  onLayout={(event) => {
                    setLayout(event.nativeEvent.layout);
                  }}
                >
                  <LicenseDetailBenefitList
                    collectibleBenefits
                    benefits={collectible?.data?.benefits}
                  />
                </VStack>
              )}
            </VStack>
            {/* Sticky */}
            {isDesktop && (
              <VStack w="40%" space={20}>
                <LicenseCollectibleStickyMetadata
                  isDesktop={isDesktop}
                  product={product?.data}
                  collectible={collectible?.data}
                />
                <AuctionCollectibleList auctionID={product.data?.auction_id} />
              </VStack>
            )}
          </Stack>
          {isDesktop && <Divider />}
          <VStack px={5} space={5}>
            {collectible?.data?.license?.id && (
              <VStack mb={5}>
                <LicenseCollectibleAnalytics
                  showsHeader={false}
                  releaseDate={getLicenseReleaseDate()}
                  title={collectible?.data?.title}
                  params={{ id: collectible?.data?.license?.id }}
                  licenseStartDate={collectible?.data?.license?.start_date}
                />
              </VStack>
            )}
            {!isDesktop && (
              <AuctionCollectibleList auctionID={product.data?.auction_id} />
            )}
            <ProductDetailCustomerInfoSheet
              url={product.data?.customer_info_sheet}
            />
          </VStack>
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};

const LicenseCollectibleStickyMetadata = ({
  product,
  isDesktop,
  collectible,
}) => {
  return (
    <VStack space={5} minW={isDesktop ? 400 : undefined}>
      <LicenseCollectibleDetailMainInfo
        collectible={collectible}
        license={collectible?.license}
      />
      <LicenseCollectibleDetailExtraInfo
        collectible={collectible}
        license={collectible?.license}
        release={product?.license_product?.release}
      />
    </VStack>
  );
};
