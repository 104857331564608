import React from "react";
import { openBrowserAsync } from "expo-web-browser";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Icon, VStack, Text, Pressable } from "native-base";
import { translate } from "utils/helpers";

export const OrderDetailAuthRequired = ({ message }) => {
  return (
    <VStack alignItems={"center"} space={5}>
      <HStack space={3} alignItems="center" justifyContent={"center"}>
        <Icon
          as={MaterialCommunityIcons}
          color="secondary.600"
          name="window-restore"
          size={"sm"}
        />
        <Text fontSize={["lg", "xl"]}>
          {translate("order_payment_authorization_required_title")}
        </Text>
      </HStack>
      <Pressable onPress={async () => await openBrowserAsync(message)}>
        <Text fontSize={["sm", "md"]} maxWidth={400}>
          {translate("order_payment_authorization_required_open_window_first")}{" "}
          <Text fontSize={["sm", "md"]} underline color="secondary.600">
            {translate(
              "order_payment_authorization_required_open_window_second"
            )}
          </Text>
        </Text>
      </Pressable>
      <Text fontSize={["sm", "md"]} maxWidth={400}>
        {translate("order_payment_authorization_required_message")}
      </Text>
    </VStack>
  );
};
