import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useRetailers = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`retailers`, () => {
    console.debug(`retailers retrieved`);
    return client.get(`enums/retailers/`).then((resp) => resp.data);
  });
};
