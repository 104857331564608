import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { CreateTrackMasterRightScreen } from "features/royalties/master-rights/screens/create-track-master-right.screen";

const Stack = createStackNavigator();

export const MasterRightsNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CreateTrackMasterRight"
        component={CreateTrackMasterRightScreen}
      />
    </Stack.Navigator>
  );
};
