import React, { memo } from "react";
import { Stack, VStack, HStack, Text, Image } from "native-base";

const characters = [
  [
    {
      title: "Häufig",
      subtitle: "48 Stück",
      image: require("assets/images/features/jan-tanner/often-rarity.png"),
    },
    {
      title: "Selten",
      subtitle: "36 Stück",
      image: require("assets/images/features/jan-tanner/rare-rarity.png"),
    },
  ],
  [
    {
      title: "Episch",
      subtitle: "12 Stück",
      image: require("assets/images/features/jan-tanner/epic-rarity.png"),
    },
    {
      title: "Legendär",
      subtitle: "4 Stück",
      image: require("assets/images/features/jan-tanner/legendary-rarity.png"),
    },
  ],
];

export const CollectibleRarityList = memo(() => (
  <VStack space="8" p="6" bg="#F4F4F4">
    <Text color="darkText" fontSize={["xl", "2xl"]} fontWeight="black">
      Seltenheiten
    </Text>

    <Stack direction={{ base: "column", lg: "row" }}>
      {characters.map((list, i) => (
        <HStack key={i} flex={1}>
          {list.map((character) => (
            <VStack
              key={character.name}
              space="2"
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                alt="Character Image"
                source={character.image}
                height={60}
                width={60}
                resizeMode="cover"
              />
              <VStack>
                <Text
                  color="darkText"
                  fontSize={["sm", "md"]}
                  fontWeight="semibold"
                  textAlign="center"
                >
                  {character.title}
                </Text>
                <Text
                  color="light.700"
                  fontSize={["sm", "md"]}
                  fontWeight="semibold"
                  textAlign="center"
                >
                  {character.subtitle}
                </Text>
              </VStack>
            </VStack>
          ))}
        </HStack>
      ))}
    </Stack>
  </VStack>
));
