import React from "react";
import { VStack, Text } from "native-base";
// internal
import { PostList } from "features/general/posts/components/post-list.component";
import { CommentList } from "features/general/comments/components/comment-list.component";

export const TicketCollectibleDetailCommunity = ({ auctionID }) => {
  return (
    <VStack p={5} space={10}>
      <PostList isPublic={false} auctionID={auctionID} />
      <VStack space={5}>
        <Text
          fontWeight="black"
          fontSize={["xl", "2xl"]}
          textTransform={"capitalize"}
        >
          Community Chat
        </Text>
        <CommentList maxHeight={250} auctionID={auctionID} />
      </VStack>
    </VStack>
  );
};
