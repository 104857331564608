import React from "react";
import { Stack, Text } from "native-base";
//helper functions
import { translate } from "utils/helpers";
//internal components
import { ProfileEditInputField } from "features/accounts/profiles/components/profile-edit-input-field.component";

export const ProfileEditSocials = ({ profile, control, isLoading }) => (
  <Stack space={10}>
    <Text fontSize={["xl", "2xl"]} fontWeight={"semibold"}>
      {translate("socials")}
    </Text>
    <ProfileEditInputField
      name="socials.tiktok.username"
      control={control}
      value={profile.data?.socials?.tiktok?.username}
      placeholderKey="tiktok_placeholder_text"
      labelKey="tiktok"
      helperKey="tiktok_helper_text"
      isLoading={isLoading}
    />
    <ProfileEditInputField
      name="socials.instagram.username"
      control={control}
      value={profile.data?.socials?.instagram?.username}
      placeholderKey="instagram_placeholder_text"
      labelKey="instagram"
      helperKey="instagram_helper_text"
      isLoading={isLoading}
    />
    <ProfileEditInputField
      name="socials.twitter.username"
      control={control}
      value={profile.data?.socials?.twitter?.username}
      placeholderKey="twitter_placeholder_text"
      labelKey="twitter"
      helperKey="twitter_helper_text"
      isLoading={isLoading}
    />
    <ProfileEditInputField
      name="socials.discord.username"
      control={control}
      value={profile.data?.socials?.discord?.username}
      placeholderKey="discord_placeholder_text"
      labelKey="discord"
      helperKey="discord_helper_text"
      isLoading={isLoading}
    />
    <ProfileEditInputField
      name="socials.youtube.url"
      control={control}
      value={profile.data?.socials?.youtube?.url}
      placeholderKey="youtube_url_placeholder_text"
      labelKey="youtube_url"
      helperKey="youtube_url_helper_text"
      isLoading={isLoading}
    />
  </Stack>
);
