import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useEstimatedEarnings = (musicianId) => {
  const { client } = useContext(AxiosContext);

  return useQuery(
    "musician-estimated-earnings",
    () =>
      client
        .get(`musicians/${musicianId}/estimated_earnings/`, {
          params: {
            detailed: true,
          },
        })
        .then((resp) => resp.data),
    {
      enabled: !!musicianId,
    }
  );
};
