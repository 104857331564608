import React from "react";
import { ImageBackground } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { Box, FlatList, Text, Stack, HStack, useMediaQuery } from "native-base";
//internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { DealCard } from "features/royalties/releases/components/deal-card.component";
import { AppIconButtonWithText } from "theme/buttons/app-icon-button-with-text.component";
import { AuctionCalculatorCard } from "features/general/auctions/components/auction-calculator-card.component";
//internal hooks
import { useMyAuctions } from "../hooks/useMyAuctions";
import { translate } from "utils/helpers";
import { IconModal } from "theme/feedback/icon-modal.component";

const ListHeaderComponent = ({ dealsLength }) => {
  return (
    <HStack justifyContent="space-between" alignItems={"center"} mb={5}>
      <Text fontSize={["md", "lg"]} fontWeight="bold">
        {`${dealsLength ? dealsLength : 0}`} Deals
      </Text>
      <IconModal
        size={6}
        iconColor="primary.600"
        icon={<AntDesign name="questioncircleo" />}
        contentText={translate("deal_information_help_text")}
      />
    </HStack>
  );
};

export const UserAuctionListScreen = ({ navigation }) => {
  const deals = useMyAuctions();
  const [isDesktop] = useMediaQuery({ minWidth: 1280 });

  const dealsLength = deals.data?.pages
    ?.map((page) => page.items)
    .flat().length;

  return (
    <ScreenFrame safeAreaTopWithTopBar={false} isAuthenticationRequired>
      <FlatList
        keyExtractor={(item, index) => item.id}
        p={5}
        key={isDesktop ? "horizontal-collection" : "vertical-collection"}
        numColumns={isDesktop ? 3 : 1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={deals.data?.pages?.map((page) => page.items).flat()}
        ListHeaderComponent={<ListHeaderComponent dealsLength={dealsLength} />}
        ListEmptyComponent={
          <Box my={3}>
            <ImageBackground
              source={require("assets/images/features/no-deals.png")}
              resizeMode="stretch"
            >
              <Box h={[135, 170]} justifyContent="center">
                <Text fontSize={["md", "lg"]} bold textAlign="center">
                  {translate("no_deals_yet")}
                </Text>
              </Box>
            </ImageBackground>
          </Box>
        }
        renderItem={({ item }) => <DealCard item={item} />}
        // ListFooterComponent={
        //   <Stack my="3" mx={{ lg: "3" }}>
        //     <AuctionCalculatorCard />
        //     <Stack
        //       mt="5"
        //       my="0"
        //       alignItems="flex-end"
        //       justifyContent="flex-end"
        //       direction={["column", "column", "row"]}
        //     >
        //       <Box m="2.5">
        //         <AppIconButtonWithText
        //           nativeID="create-new-release-and-deal"
        //           iconName="plus"
        //           text={translate("create_release_and_deal")}
        //           disabled={false}
        //           onPress={() =>
        //             navigation.navigate("ReleaseTabs", {
        //               screen: "ReleaseList",
        //             })
        //           }
        //         />
        //       </Box>
        //       <Box m="2.5">
        //         <AppIconButtonWithText
        //           nativeID="create-new-fan-deal"
        //           iconName="plus"
        //           text={translate("create_fan_deal")}
        //           disabled={false}
        //           onPress={() =>
        //             navigation.navigate("ReleaseDeals", {
        //               screen: "ExistingReleaseList",
        //             })
        //           }
        //         />
        //       </Box>
        //     </Stack>
        //   </Stack>
        // }
      />
    </ScreenFrame>
  );
};
