import React, { useState } from "react";
import { useController } from "react-hook-form";
import { AntDesign } from "@expo/vector-icons";
import {
  Checkbox,
  Text,
  Modal,
  HStack,
  Pressable,
  VStack,
  FormControl,
  ChevronDownIcon,
} from "native-base";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate } from "utils/helpers";

export const FormControlMultiSelect = ({
  control,
  name,
  label,
  value = [],
  rules = {},
  isDisabled = false,
  optionsDisabled = false,
  helperText = null,
  infoText = null,
  query,
  hideValue = "",
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
    rules,
    defaultValue: value,
  });

  const selectAll = () => {
    let allValues = query.data?.map((item) => item.value);
    field.onChange(allValues);
  };

  const clear = () => field.onChange([]);

  const filteredData = query.data.filter(
    (row, index) => hideValue !== row.value
  );
  const data = hideValue ? filteredData : query?.data;

  return (
    <>
      <FormControl
        isRequired={rules?.required}
        isInvalid={invalid}
        isDisabled={isDisabled}
      >
        {label && (
          <HStack alignItems="center" justifyContent="space-between">
            <FormControl.Label
              _text={{
                _light: { color: "darkText" },
                _dark: { color: "lightText" },
              }}
            >
              {label}
            </FormControl.Label>
            {infoText && (
              <IconModal
                iconColor="primary.600"
                icon={<AntDesign name="questioncircleo" />}
                contentText={infoText}
              />
            )}
          </HStack>
        )}
        <Pressable
          disabled={isDisabled}
          onPress={toggleModal}
          borderRadius="lg"
        >
          <HStack
            px="3"
            py="1.5"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize={["md", "lg"]}>
              {translate("multi_select_selection_text", {
                valueLength: hideValue
                  ? field.value.length - 1
                  : field.value.length,
                dataLength: hideValue
                  ? filteredData.length
                  : query.data?.length,
              })}
            </Text>
            <ChevronDownIcon size="lg" />
          </HStack>
        </Pressable>
        <FormControl.ErrorMessage>
          {error?.type == "required" && translate("is_required")}
        </FormControl.ErrorMessage>
        {helperText && (
          <FormControl.HelperText>{helperText}</FormControl.HelperText>
        )}
      </FormControl>

      <Modal isOpen={showModal} onClose={toggleModal}>
        <Modal.Content
          borderRadius={"xl"}
          _dark={{
            bg: "dark.900",
          }}
          _light={{
            bg: "light.100",
          }}
        >
          <Modal.Body pt={5}>
            <VStack space={5}>
              <HStack justifyContent={"flex-end"}>
                <Pressable onPress={toggleModal}>
                  <Text fontSize={["md", "lg"]}>{translate("done")}</Text>
                </Pressable>
              </HStack>

              <HStack justifyContent={"space-between"}>
                <Pressable disabled={optionsDisabled} onPress={selectAll}>
                  <Text fontSize={["md", "lg"]}>{translate("select_all")}</Text>
                </Pressable>
                <Pressable disabled={optionsDisabled} onPress={clear}>
                  <Text fontSize={["md", "lg"]}>{translate("clear")}</Text>
                </Pressable>
              </HStack>
              <Checkbox.Group
                value={field.value}
                onChange={(values) => field.onChange(values || [])}
              >
                <VStack space={3} alignItems={"flex-start"}>
                  {!query.isLoading &&
                  query.isSuccess &&
                  query.data &&
                  query.data.length > 0 ? (
                    data.map((row, index) => (
                      <Checkbox
                        isDisabled={optionsDisabled}
                        key={index}
                        value={row.value}
                      >
                        {row.label}
                      </Checkbox>
                    ))
                  ) : (
                    <Checkbox>{translate("loading")}</Checkbox>
                  )}
                </VStack>
              </Checkbox.Group>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};
