import React, { Children } from "react";
import { Box, Pressable, useTheme } from "native-base";
import Swiper from "react-native-web-swiper";

export const Carousel = ({ children, timeout = 0, ...props }) => {
  const { colors } = useTheme();
  const itemsLength = Children.count(children);
  return (
    <Swiper
      loop
      {...props}
      timeout={timeout}
      controlsEnabled={itemsLength > 1}
      controlsProps={{
        dotsTouchable: true,
        dotActiveStyle: {
          backgroundColor: colors?.secondary[100],
        },
        DotComponent: ({ isActive, onPress }) => (
          <DotComponent onPress={onPress} isActive={isActive} />
        ),
        prevPos: false,
        nextPos: false,
      }}
    >
      {children}
    </Swiper>
  );
};

const DotComponent = ({ isActive, onPress }) => {
  return (
    <Pressable onPress={onPress} disabled={isActive}>
      <Box
        mr={3}
        size={isActive ? 4 : 3}
        rounded={isActive ? "xl" : "full"}
        bg={isActive ? "secondary.600" : "gray.300"}
      />
    </Pressable>
  );
};
