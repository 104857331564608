import { useContext, useState } from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import { translate } from "utils/helpers";
import { ToastAlert } from "theme/feedback/toast-alert.component";

const useDeleteAuctionBenefit = (benefitID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteBenefit: useMutation(() => client.delete(`/benefits/${benefitID}/`), {
      onSuccess: () => {
        console.debug(`benefit-${benefitID} deleted`);
        queryClient.invalidateQueries(`auction-benefits`);
        Toast.show({
          placement: "top",
          render: ({ id }) => (
            <ToastAlert
              id={id}
              status="info"
              description={translate("benefit_removed")}
            />
          ),
        });
      },
    }),
    error: error,
  };
};

export default useDeleteAuctionBenefit;
