import React from "react";
import { useMediaQuery, useTheme } from "native-base";
import { createStackNavigator } from "@react-navigation/stack";
// internal
import { isWeb } from "utils/helpers";
import { BottomNavigator } from "navigation/bottom.navigator";
import { DrawerNavigator } from "navigation/drawer.navigator";
import { AccountNavigator } from "common/account.navigator";
import { UsersNavigator } from "features/accounts/users/users.navigator";
import { OrdersNavigator } from "features/general/orders/orders.navigator";
import { TracksNavigator } from "features/royalties/tracks/tracks.navigator";
import { PayoutsNavigator } from "features/royalties/payouts/payouts.navigator";
import {
  ReleaseListNavigator,
  ReleaseNavigator,
} from "features/royalties/releases/release.navigator";
import { AuctionsNavigator } from "features/general/auctions/auctions.navigator";
import { ProfilesNavigator } from "features/accounts/profiles/profiles.navigator";
import { TransfersNavigator } from "features/general/transfers/transfers.navigator";
import { MusiciansNavigator } from "features/accounts/musicians/musicians.navigator";
import { MasterRightsNavigator } from "features/royalties/master-rights/master-rights.navigator";
import { ContributorsNavigator } from "features/royalties/track-contributors/contributors.navigator";
import { PaymentMethodsNavigator } from "features/general/payment-methods/payment-methods.navigator";
import { TicketCollectiblesNavigator } from "features/tickets/ticket-collectibles/ticket-collectibles.navigator";
import { LicenseCollectiblesNavigator } from "features/royalties/license-collectibles/license-collectibles.navigator";
import { CollectiblesNavigator } from "features/general/collectibles/collectibles.navigator";

const Stack = createStackNavigator();

export const AppNavigator = () => {
  const { colors } = useTheme();

  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        cardStyle: {
          backgroundColor: colors?.black[900],
        },
      }}
    >
      <Stack.Screen
        component={isDesktop ? DrawerNavigator : BottomNavigator}
        name="BottomNavigator"
      />
      <Stack.Screen component={UsersNavigator} name="Users" />
      <Stack.Screen component={AccountNavigator} name="Account" />
      <Stack.Screen component={ProfilesNavigator} name="Profiles" />
      <Stack.Screen component={MusiciansNavigator} name="Musicians" />
      {/* General Navigators */}
      <Stack.Screen component={OrdersNavigator} name="Orders" />
      <Stack.Screen component={TransfersNavigator} name="Transfers" />

      <Stack.Screen component={PayoutsNavigator} name="Payouts" />
      <Stack.Screen component={AuctionsNavigator} name="Auctions" />
      <Stack.Screen component={PaymentMethodsNavigator} name="PaymentMethods" />
      {/* Royalties Navigators */}
      <Stack.Screen component={TracksNavigator} name="Tracks" />
      <Stack.Screen component={ReleaseNavigator} name="Releases" />
      <Stack.Screen component={MasterRightsNavigator} name="MasterRights" />
      <Stack.Screen component={ContributorsNavigator} name="Contributors" />
      <Stack.Screen
        component={LicenseCollectiblesNavigator}
        name="LicenseCollectibles"
      />
      {/* Tickets Navigator */}
      <Stack.Screen
        component={TicketCollectiblesNavigator}
        name="TicketCollectibles"
      />
      {!isWeb && (
        <Stack.Screen
          component={ReleaseListNavigator}
          name="NativeReleaseNavigator"
        />
      )}
      {/* Collectibles Navigator */}
      <Stack.Screen component={CollectiblesNavigator} name="Collectibles" />
    </Stack.Navigator>
  );
};
