import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteTrackFromRelease = (releaseId) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteTrackFromRelease: useMutation(
      (trackId) => client.delete(`releases/${releaseId}/tracks/${trackId}/`),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("releases");
          queryClient.invalidateQueries(`release-${releaseId}`);
        },
      }
    ),
    error: error,
  };
};

export default useDeleteTrackFromRelease;
