import React, { useContext, useRef } from "react";
import { Platform } from "react-native";
import {
  Modal,
  KeyboardAvoidingView,
  VStack,
  Text,
  Box,
  Pressable,
  Input,
} from "native-base";
import { useNavigation } from "@react-navigation/native";

import { Loading } from "common/components/loading/loading.component";
import { AuthenticationContext } from "services/authentication.context";
//helpers
import { translate } from "utils/helpers";

export const CodeVerificationModal = () => {
  const ref = useRef(null);
  const navigation = useNavigation();

  const {
    showVerificationModal,
    setShowVerificationModal,
    setShowLoginModal,
    isLoading,
    isUserLoading,
    onValidateOTP,
    phoneNumber,
  } = useContext(AuthenticationContext);

  return (
    <Modal
      isOpen={showVerificationModal}
      onClose={() => setShowVerificationModal(false)}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <VStack
          p={6}
          mx={6}
          space={3}
          maxW={96}
          rounded={"2xl"}
          alignItems={"center"}
          bg={"lightBackground"}
        >
          <Text fontSize={"2xl"} alignItems={"center"} fontWeight={"semibold"}>
            {translate("verification_code")}
          </Text>
          <Text _dark={{ color: "black" }} fontSize={"xl"}>
            {translate("verification_code_subtitle")}{" "}
          </Text>
          <Box borderRadius={"xl"} padding={2} bg={"darkText"}>
            <Text px={5} fontSize={"xl"} color={"lightText"}>
              {phoneNumber}
            </Text>
          </Box>
          <Pressable
            bottom={0}
            onPress={() => {
              setShowVerificationModal(false);
              setShowLoginModal(true);
            }}
          >
            <Text color={"darkText"} fontSize={["md", "lg"]}>
              {translate("wrong_number")}
            </Text>
          </Pressable>
          {isLoading || isUserLoading ? (
            <Loading color={"darkText"} />
          ) : (
            <Input
              ref={ref}
              size={"xl"}
              autoFocus={true}
              color={"primary"}
              textAlign={"center"}
              justifyContent={"center"}
              _focus={{ bg: "lightText" }}
              keyboardType={"phone-pad"}
              focusOutlineColor={"darkText"}
              onChangeText={(OTP) => {
                if (OTP.length >= 6) {
                  onValidateOTP(OTP, navigation, false);
                }
              }}
            />
          )}
          <Box p={5} />
        </VStack>
      </KeyboardAvoidingView>
    </Modal>
  );
};
