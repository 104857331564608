import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteAuction = (id) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteAuction: useMutation(() => client.delete(`auctions/${id}/`), {
      onSuccess: () => {
        console.debug(`deal-${id} deleted`);
        queryClient.invalidateQueries(`user-auctions`);
      },
    }),
    error: error,
  };
};

export default useDeleteAuction;
