import React, { useEffect, useContext } from "react";
import { Box, Image, Text, VStack } from "native-base";
import { BlurView } from "expo-blur";
import { Platform } from "react-native";
import {
  createBottomTabNavigator,
  BottomTabBar,
} from "@react-navigation/bottom-tabs";
// internal hooks
import { isWeb } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
// internal Components
import { HomeScreen } from "common/screens/home.screen";
import { SharesScreen } from "common/screens/shares.screen";
import { EventsScreen } from "common/screens/events.screen";
import { UserAvatar } from "theme/media/user-profile-avatar.component";
import { ReleaseTopBarNavigator } from "features/royalties/releases/release.navigator";
import { AccountDetailScreen } from "features/accounts/users/screens/account-detail.screen";

const Tab = createBottomTabNavigator();

const TabIcon = ({ focused, icon, profileImage, badge }) => {
  return (
    <VStack space={2} alignItems={"center"} borderRadius={"full"}>
      {badge > 0 && !focused && (
        <Box
          top={1}
          size={3}
          right={-2}
          zIndex={1}
          bg={"red.500"}
          rounded={"full"}
          position={"absolute"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={8} color={"lightText"}>
            {badge >= 10 ? `9+` : badge}
          </Text>
        </Box>
      )}
      {icon ? (
        <Image
          size={7}
          alt={"tab"}
          source={icon}
          opacity={focused ? 1 : 0.3}
          _alt={{ color: "darkText" }}
        />
      ) : (
        <UserAvatar p={0} size={8} uri={profileImage} alt={"profile"} />
      )}
      <Box
        size={2}
        rounded={"full"}
        bg={focused ? "secondary.600" : "transparent"}
      />
    </VStack>
  );
};

const MyTabBar = ({ profile, profileType, updateProfile, ...props }) => {
  useEffect(() => {
    if (profile?.isFetched) {
      if (!profile?.data?.type) {
        console.debug(`🧙 Adding new user profile type: ${profileType}`);
        updateProfile.mutate({ type: profileType });
      } else if (profile?.data?.type === "fan" && profileType === "artist") {
        console.debug("🧙 Converting existing fan profile to artist");
        updateProfile.mutate({ type: profileType });
      }
    }
  }, [profile?.isFetched]);

  return <BottomTabBar {...props} />;
};

export const BottomNavigator = () => {
  const profile = useUserProfile();
  const { profileType } = useContext(AuthenticationContext);
  const { updateProfile } = useUpdateProfile(profile?.data?.id);

  const screenOptions = ({}) => ({
    tabBarBackground: () => (
      <Box
        flex={1}
        shadow={"-0"}
        bg={"light.100"}
        overflow={"hidden"}
        style={{ backdropFilter: "blur(4px)" }}
      >
        <BlurView flex={1} intensity={100} tint={"light"}>
          <Box flex={1} opacity={"0.7"} bg={"light.100"} />
        </BlurView>
      </Box>
    ),
    tabBarShowLabel: false,
    headerShown: false,
    tabBarStyle: {
      height: isWeb ? 70 : 75,
      backgroundColor: "transparent",
      borderRadius: 20,
      borderTopWidth: 0,
      overflow: "visible",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      elevation: 10,
      paddingVertical: Platform.OS === "ios" ? 25 : 0,
    },
  });

  return (
    <Tab.Navigator
      screenOptions={screenOptions}
      tabBar={(props) => (
        <MyTabBar
          {...props}
          profile={profile}
          profileType={profileType}
          updateProfile={updateProfile}
        />
      )}
    >
      {/* <Tab.Screen
        name={"Marketplace"}
        component={HomeScreen}
        options={{
          tabBarIcon: (props) => (
            <TabIcon
              {...props}
              icon={require("assets/images/icons/navigation/home.png")}
            />
          ),
        }}
      /> */}
      {!profile?.isLoading && profile?.data?.type === "artist" ? (
        <Tab.Screen
          name={"ReleaseTabs"}
          component={ReleaseTopBarNavigator}
          options={{
            tabBarIcon: (props) => (
              <TabIcon
                {...props}
                icon={require("assets/images/icons/navigation/shares.png")}
              />
            ),
          }}
        />
      ) : (
        <>
          <Tab.Screen
            name={"Events"}
            component={EventsScreen}
            options={{
              tabBarIcon: (props) => (
                <TabIcon
                  {...props}
                  icon={require("assets/images/icons/navigation/events.png")}
                />
              ),
            }}
          />
          <Tab.Screen
            name={"Shares"}
            component={SharesScreen}
            options={{
              tabBarIcon: (props) => (
                <TabIcon
                  {...props}
                  icon={require("assets/images/icons/navigation/shares.png")}
                />
              ),
            }}
          />
        </>
      )}
      <Tab.Screen
        name={"AccountSettings"}
        component={AccountDetailScreen}
        options={{
          tabBarIcon: (props) => (
            <TabIcon
              {...props}
              profileImage={profile?.data?.image_with_url?.md?.url}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
