import React, { useContext } from "react";
import {
  Divider,
  Stack,
  HStack,
  VStack,
  useMediaQuery,
  Box,
  Icon,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import Constants from "expo-constants";
// internal components
import { AuctionMusiciansList } from "features/general/auctions/components/auction-musicians-list.component";
import { AuctionCollectibleList } from "features/general/collectibles/components/auction-collectible-list.component";
import { ProductDetailCustomerInfoSheet } from "features/general/products/components/product-detail-customer-info-sheet.component";
import { AuctionDetailDateInfo } from "features/general/auctions/components/auction-detail-date-info.component";
import { LicenseProductDetailMainInfo } from "features/royalties/license-products/components/license-product-detail-main-info.component";
import { LicenseProductDetailAlbumList } from "features/royalties/license-products/components/license-product-detail-album-list.component";
import { LicenseProductDetailExtraInfo } from "features/royalties/license-products/components/license-product-detail-extra-info.component";
import { LicenseProductDetailDisclaimer } from "features/royalties/license-products/components/license-product-detail-disclaimer.component";
import { LicenseProductDetailDescription } from "features/royalties/license-products/components/license-product-detail-description.component";
import { LicenseDetailBenefitList } from "features/royalties/license-benefits/components/license-detail-benefit-list.component";
import { LicenseProductDetailButtons } from "features/royalties/license-products/components/license-product-detail-buttons.component";
import { LicenseProductOrderCreateButton } from "features/royalties/license-product-orders/components/license-product-order-create-button.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { AuthenticationContext } from "services/authentication.context";
import { AppIconButton } from "theme/buttons/app-icon-button.component";
import { AuctionBookmark } from "features/general/auctions/components/auction-bookmark.component";
import { IconModal } from "theme/feedback/icon-modal.component";
import { translate } from "utils/helpers";
import { onCopy } from "theme/buttons/copy-text-button.component";
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";

export const LicenseProductDetail = ({ auction, auctionFetched }) => {
  const paddingX = 10;
  const product = auction?.products[0];
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <VStack px={isDesktop ? paddingX : 0} space={5}>
      <VStack
        py={10}
        space={10}
        bg={"gray.100"}
        alignItems={"center"}
        mx={isDesktop ? -paddingX : 0}
      >
        <AuctionDetailImage
          justifyContent={{ base: "center", lg: "flex-end" }}
          url={
            product?.picasso_generated_images?.wrapped_platinum?.image_with_url
              ?.md?.url
          }
        />
        <HStack px={isDesktop ? 16 : 5} space={3} alignSelf={"flex-end"}>
          <Box p={3} bg={"dark.900"} rounded={"full"}>
            <IconModal
              size={7}
              color={"lightText"}
              contentText={translate("auction_general_info")}
              icon={<Icon as={Ionicons} name={"help-circle-outline"} />}
            />
          </Box>
          <AppIconButton
            p={3}
            size={7}
            as={Ionicons}
            borderRadius={"full"}
            iconName={"arrow-redo-outline"}
            onPress={() =>
              onCopy({
                toast: translate("link_copied"),
                text:
                  Constants.expoConfig.extra?.APP_BASE_URL +
                  `/deals/${auction.id}`,
              })
            }
          />
          <Box p={3.5} bg={"dark.900"} rounded={"full"}>
            <AuctionBookmark auction={auction} invert />
          </Box>
        </HStack>
      </VStack>

      <Stack
        p={5}
        space={isDesktop ? 20 : 10}
        direction={isDesktop ? "row" : "column"}
      >
        <VStack flex={1} space={5}>
          <LicenseProductDetailButtons
            product={product}
            musicians={auction?.musicians}
          />
          <AuctionMusiciansList musicians={auction?.musicians} />
          {!isDesktop && (
            <LicenseProductStickyMetadata
              product={product}
              auction={auction}
              isDesktop={isDesktop}
              auctionFetched={auctionFetched}
            />
          )}
          <LicenseProductDetailAlbumList product={product} />
          <Divider my={5} />
          <LicenseProductDetailDescription description={product?.description} />
          <Divider my={5} />
          {product?.benefits?.length > 0 && (
            <LicenseDetailBenefitList benefits={product?.benefits} />
          )}
        </VStack>
        {/* Sticky */}
        {isDesktop && (
          <VStack w="40%" space={20}>
            <LicenseProductStickyMetadata
              product={product}
              auction={auction}
              isDesktop={isDesktop}
              auctionFetched={auctionFetched}
            />
            <AuctionCollectibleList auctionID={auction.id} />
          </VStack>
        )}
      </Stack>
      {isDesktop && <Divider />}
      <VStack p={5}>
        {!isDesktop && <AuctionCollectibleList auctionID={auction.id} />}
        {product?.customer_info_sheet && isAuthenticated && (
          <VStack space={10}>
            <ProductDetailCustomerInfoSheet
              url={product?.customer_info_sheet}
            />
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export const LicenseProductStickyMetadata = ({
  product,
  auction,
  isDesktop,
  auctionFetched,
}) => (
  <VStack flex={1} space={5} minW={isDesktop ? 400 : undefined}>
    <VStack p={5} shadow={5} bg={"light.50"} borderRadius={"2xl"}>
      <LicenseProductDetailMainInfo product={product} />
      <LicenseProductOrderCreateButton
        px={0}
        auction={auction}
        product={product}
        borderRadius={"full"}
        isDisabled={!(auction?.is_active && auctionFetched)}
      />
    </VStack>
    <VStack p={5} space={5} shadow={5} bg={"light.50"} borderRadius={"2xl"}>
      <AuctionDetailDateInfo auction={auction} />
      <Divider />
      <LicenseProductDetailExtraInfo product={product} />
    </VStack>
    {product?.license_product?.is_default_offer && (
      <LicenseProductDetailDisclaimer auction={auction} product={product} />
    )}
  </VStack>
);
