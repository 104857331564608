import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VStack, Text, Pressable, HStack, CheckIcon } from "native-base";
//components
import { Link } from "theme/typography/link.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { CouponDetailProgressBar } from "./coupon-detail-progress-bar.component";

const options = [
  { value: "Habibi Remix", selected: true },
  { value: "Komm zu Hypnotize", selected: false },
  { value: "Ma Bae", selected: false },
];

export const CouponWin = ({ setStatus, url }) => {
  const { handleSubmit, control } = useForm();

  const [songs, setSongs] = useState(options);
  const [error, setError] = useState("");

  const onPress = () => {
    if (songs.map((item) => item.selected).includes(true)) {
      setStatus("completed");
    } else {
      setError("Bitte wählen Sie einen Wert");
    }
  };

  useEffect(() => {
    if (songs.map((item) => item.selected).includes(true)) {
      setError("");
    }
  }, [songs]);

  return (
    <VStack space={5}>
      <CouponDetailProgressBar activeIndex={2} />
      <Text fontSize={["md", "lg", "xl"]}>
        Sichere dir die Chance auf einen von 100 tollen Gewinnen indem du die
        folgende Frage beantwortest. Die Gewinner werden nach der Show
        bekanntgegeben und können die Gewinne noch heute am biddz Stand abholen.
      </Text>

      <Text bold fontSize={"lg"} textTransform={"uppercase"} textAlign="center">
        MIT WELCHEM SONG SOLL DARDAN HEUTE DIE SHOW BEENDEN?
      </Text>
      {!!error && (
        <Text my={-2} fontSize={"xs"} color="#EF4444">
          {error}
        </Text>
      )}
      {songs.map((item, index) => (
        <Pressable
          key={index}
          flex={1}
          p="5"
          my="1"
          mx={{ lg: "3" }}
          _light={{ backgroundColor: "light.100" }}
          _dark={{ backgroundColor: "gray.800" }}
          //  bg="gray.800"
          borderRadius="xl"
          onPress={() => {
            const updatedOptions = options.map((i) =>
              i.value === item.value
                ? { ...i, selected: true }
                : { ...i, selected: false }
            );
            setSongs(updatedOptions);
          }}
        >
          <HStack justifyContent="space-between" alignItems="center">
            <Text fontSize={["md", "lg"]} fontWeight="semibold">
              {item.value}
            </Text>
            {item.selected && <CheckIcon size="5" />}
          </HStack>
        </Pressable>
      ))}
      <BooleanCheckbox
        rules={{ required: true }}
        control={control}
        name="privacy"
      >
        <Text fontSize={["xs"]}>
          Ich akzeptiere die{" "}
          {url ? (
            <Link fontSize="xs" text="Gewinnspielbedingungen" url={url} />
          ) : (
            "Gewinnspielbedingungen"
          )}
        </Text>
      </BooleanCheckbox>
      <PrimaryButton text={"Teilnehmen"} onPress={handleSubmit(onPress)} />
    </VStack>
  );
};
