import React, { memo } from "react";
import { Stack, VStack, HStack, Text, Image } from "native-base";
import { translate } from "utils/helpers";

const characters = [
  [
    {
      name: "Jan Tenner",
      image: require("assets/images/features/jan-tanner/jan-tenner.png"),
    },
    {
      name: "Laura",
      image: require("assets/images/features/jan-tanner/laura.png"),
    },
    {
      name: "Prof. Futura",
      image: require("assets/images/features/jan-tanner/prof-futura.png"),
    },
  ],
  [
    {
      name: "General Forbett",
      image: require("assets/images/features/jan-tanner/gen-forbett.png"),
    },
    {
      name: "Memo",
      image: require("assets/images/features/jan-tanner/memo.png"),
    },
    {
      name: "Raumschiff Silbervogel",
      image: require("assets/images/features/jan-tanner/raumschiff-silbervogel.png"),
    },
  ],
];

export const CollectibleCharacterList = memo(() => (
  <VStack space="8" p="6" bg="#F4F4F4">
    <Text color="darkText" fontSize={["xl", "2xl"]} fontWeight="black">
      {translate("jan_tenner_characters")}
    </Text>

    <Stack direction={{ base: "column", lg: "row" }}>
      {characters.map((list, i) => (
        <HStack key={i} flex={1}>
          {list.map((character) => (
            <VStack key={character.name} space="5" flex={1}>
              <Image
                alt="Jan Tenner Character"
                source={character.image}
                height={[150, 250]}
                width="100%"
                resizeMode="contain"
              />
              <Text
                color="darkText"
                fontSize={["sm", "md"]}
                fontWeight="semibold"
                textAlign="center"
              >
                {character.name}
              </Text>
            </VStack>
          ))}
        </HStack>
      ))}
    </Stack>
  </VStack>
));
