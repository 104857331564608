import React from "react";
import { ImageBackground } from "react-native";
import { VStack, HStack, Box, Icon, Text, Button, Image } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// internal components
import { RunningText } from "theme/typography/running-text.component";
import { TicketCollectibleQRCodeButton } from "features/tickets/ticket-collectibles/components/ticket-collectible-qr-code-button.component";
// helpers
import { getLocalizedDateHour, translate } from "utils/helpers";

export const EventCollectibleListCard = ({
  event,
  width,
  height,
  gridView = true,
}) => {
  const navigation = useNavigation();

  const countries = { DE: "Germany", AT: "Austria" };

  const viewTicket = () => {
    navigation.navigate("TicketCollectibles", {
      screen: "TicketCollectibleDetail",
      params: {
        id: event?.collectibles?.[0]?.id,
        initialData: event?.collectibles?.[0],
      },
    });
  };

  return !gridView ? (
    <VStack
      p={4}
      my={4}
      space={5}
      shadow={5}
      _light={{ bg: "light.100" }}
      borderRadius={"xl"}
      mx={{ base: 5, lg: 32 }}
    >
      <HStack alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={["lg", "xl"]} bold>
          {event?.title}
        </Text>
        {event?.collectibles?.[0]?.ticket?.has_access && (
          <TicketCollectibleQRCodeButton
            pressableChildren={
              <Image
                size={6}
                alt={"QR Code Symbol"}
                source={require("assets/images/icons/tickets/qr-code.png")}
              />
            }
            collectibles={event?.collectibles}
          />
        )}
      </HStack>
      <VStack space={1}>
        <Text color={"gray.500"} fontSize={"sm"} numberOfLines={1}>
          <Text color={"secondary.300"} fontWeight={500}>
            {getLocalizedDateHour(
              event?.collectibles?.[0]?.ticket?.event?.start_time
            )}
          </Text>{" "}
          . {event?.collectibles?.[0]?.ticket?.event?.location}
        </Text>
        <HStack space={1} alignItems={"center"}>
          <Icon size={4} as={MaterialCommunityIcons} name={"map-marker"} />
          <Text fontSize={"sm"}>
            {event?.collectibles?.[0]?.ticket?.event?.city},{" "}
            {countries[event?.collectibles?.[0]?.ticket?.event?.country]}
          </Text>
        </HStack>
      </VStack>
      <Button
        py={3}
        variant={"outline"}
        onPress={viewTicket}
        borderRadius={"full"}
        borderColor={"light.400"}
        _text={{ color: "darkText", fontWeight: 500 }}
      >
        {translate("view_ticket")}
      </Button>
    </VStack>
  ) : (
    <Box m={2} w={width} h={height} borderRadius={"xl"} overflow={"hidden"}>
      <ImageBackground
        source={{
          uri: event?.collectibles?.[0]?.ticket?.event?.image_with_url?.md?.url,
        }}
        style={{ height: "100%", width: "100%" }}
      >
        <VStack flex={1} justifyContent={"space-between"}>
          <HStack p={5} w={"full"} justifyContent={"flex-end"}>
            {event?.collectibles?.[0]?.ticket?.has_access && (
              <TicketCollectibleQRCodeButton
                pressableChildren={
                  <Image
                    h={50}
                    w={50}
                    alt="QR Code Symbol"
                    source={require("assets/images/features/event-qr-code.png")}
                  />
                }
                collectibles={event?.collectibles}
              />
            )}
          </HStack>

          <VStack
            p={5}
            space={2}
            bg={{
              linearGradient: {
                end: [0, 1],
                start: [0, 0],
                colors: ["transparent", "rgba(0,0,0, 0.6)", "rgba(0,0,0, 0.8)"],
              },
            }}
          >
            <RunningText
              text={event?.title}
              style={{
                fontSize: 20,
                color: "#fff",
                fontWeight: "bold",
              }}
            />
            <Text mt={3} color={"gray.300"} fontSize={"sm"} numberOfLines={1}>
              <Text color={"secondary.300"}>
                {getLocalizedDateHour(
                  event?.collectibles?.[0]?.ticket?.event?.start_time
                )}
              </Text>{" "}
              . {event?.collectibles?.[0]?.ticket?.event?.location}
            </Text>
            <HStack space={1} alignItems={"center"}>
              <Icon
                size={4}
                name={"map-marker"}
                color={"lightText"}
                as={MaterialCommunityIcons}
              />
              <Text color="lightText" fontSize="sm">
                {event?.collectibles?.[0]?.ticket?.event?.city},{" "}
                {countries[event?.collectibles?.[0]?.ticket?.event?.country]}
              </Text>
            </HStack>
            <Button
              py={3}
              variant={"outline"}
              onPress={viewTicket}
              borderRadius={"full"}
              borderColor={"gray.500"}
              _text={{ color: "lightText", fontWeight: 500 }}
            >
              {translate("view_ticket")}
            </Button>
          </VStack>
        </VStack>
      </ImageBackground>
    </Box>
  );
};
