import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useCountries = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`countries`, () => {
    return client.get(`enums/countries/`).then((resp) => {
      console.debug("🌎 Countries");
      return resp.data;
    });
  });
};
