import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateTracksOrder = (release) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateTracksOrder: useMutation(
      (data) => client.patch(`releases/${release.id}/tracklisting/`, data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`release-${release.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateTracksOrder;
