import React, { memo } from "react";
import { Box, Image } from "native-base";

export const UserAvatar = memo(({ uri, alt = "", ...props }) => (
  <Box
    rounded={"full"}
    alignItems={"center"}
    justifyContent={"center"}
    p={2}
    {...props}
  >
    <Image
      alt={alt}
      h={"full"}
      w={"full"}
      rounded={"full"}
      source={
        uri
          ? { uri: uri + "?key=" + Math.random() }
          : require("assets/images/features/profile-picture.png")
      }
    />
  </Box>
));
