import React, { useContext, useEffect } from "react";
import {
  FormControl,
  VStack,
  HStack,
  ScrollView,
  Pressable,
  Icon,
} from "native-base";
import { useForm } from "react-hook-form";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal hooks
import useCreateTrackMasterRight from "features/royalties/master-rights/hooks/useCreateTrackMasterRight";
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useMasterRightRoles } from "src/features/royalties/master-rights/hooks/useMasterRightRoles";
import useDeleteTrackMasterRight from "features/royalties/master-rights/hooks/useDeleteTrackMasterRight";
import useUpdateMasterRight from "features/royalties/master-rights/hooks/useUpdateMasterRight";
// internal components
import { Slider } from "theme/forms/slider.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { Loading } from "common/components/loading/loading.component";
import { AuthenticationContext } from "services/authentication.context";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";
import { PhoneNumberInput } from "features/accounts/users/components/phone-number-input.component";

export const CreateTrackMasterRightScreen = ({ navigation, route }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { trackID, masterRightID, trackPartials = {} } = route.params;
  const { data: track, isFetched } = useTrack(trackID, trackPartials);
  const masterRight = track?.master_rights?.find(
    (masterRight) => masterRight.id === masterRightID
  );
  const { userDetails } = useContext(AuthenticationContext);
  const isUser = masterRight?.owner_id === userDetails?.id;
  const { createTrackMasterRight } = useCreateTrackMasterRight(trackID);
  const { deleteTrackMasterRight } = useDeleteTrackMasterRight(track);
  const { updateMasterRight } = useUpdateMasterRight(trackID, masterRightID);
  const masterRightRoles = useMasterRightRoles();

  const onPress = (data) => {
    if (isUser) {
      navigateBack(navigation);
    } else {
      if (!!masterRightID) {
        updateMasterRight.mutate({
          ...data,
        });
      } else {
        createTrackMasterRight.mutate({
          ...data,
          phone_number: "+" + data.country_code + data.phone_number,
        });
      }
    }
  };

  useEffect(() => {
    if (
      createTrackMasterRight.isSuccess ||
      deleteTrackMasterRight.isSuccess ||
      updateMasterRight.isSuccess
    )
      navigateBack(navigation);
  }, [
    createTrackMasterRight.isSuccess,
    deleteTrackMasterRight.isSuccess || updateMasterRight.isSuccess,
  ]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        customHeaderRight
        headerRight={
          ["draft", "pending"].includes(masterRight?.status) &&
          masterRight?.owner_id !== userDetails?.id ? (
            <Pressable
              nativeID="delete-track-master-right"
              ml="2"
              onPress={() => deleteTrackMasterRight.mutate(masterRight)}
            >
              <Icon as={MaterialCommunityIcons} name="trash-can" size="xl" />
            </Pressable>
          ) : null
        }
      />
      <VStack flex={1}>
        <ScrollView showsVerticalScrollIndicator={false}>
          {!isFetched ? (
            <Loading />
          ) : (
            <VStack p={5} space={5}>
              {!isUser && (
                <>
                  <FormControlInput
                    name="name"
                    label={translate("name")}
                    control={control}
                    rules={{ required: true }}
                    value={masterRight?.name}
                  />
                  <FormControlInput
                    name="email"
                    label={translate("email")}
                    control={control}
                    rules={{ required: true }}
                    value={masterRight?.email}
                    keyboardType="email-address"
                    helperText={translate("valid_email_accepted_text")}
                  />
                </>
              )}
              <FormControl isRequired>
                <FormControl.Label
                  _text={{
                    _light: { color: "darkText" },
                    _dark: { color: "lightText" },
                  }}
                >
                  {translate("phone_number")}
                </FormControl.Label>
                <PhoneNumberInput
                  value={
                    isUser
                      ? userDetails?.phone_number?.replace("+49", "")
                      : masterRight?.phone_number?.replace("+49", "")
                  }
                  control={control}
                  isDisabled={!!masterRightID}
                />
                <FormControl.HelperText>
                  {translate("include_country_code_text")}
                </FormControl.HelperText>
              </FormControl>

              <FormControlSelect
                name="role"
                label={translate("role")}
                rules={{ required: true }}
                control={control}
                query={masterRightRoles}
                value={masterRight?.role}
              />

              <FormControl isRequired isInvalid={errors?.share}>
                <FormControl.Label
                  _text={{
                    _light: { color: "darkText" },
                    _dark: { color: "lightText" },
                  }}
                >
                  {translate("share")}
                </FormControl.Label>
                <HStack px={10} py={5}>
                  <Slider
                    name="share"
                    control={control}
                    isDisabled={isUser}
                    rules={{ min: 0.001 }}
                    minValue={0}
                    maxValue={1}
                    step={0.005}
                    value={masterRight?.share}
                  />
                </HStack>
                <FormControl.ErrorMessage>
                  {translate("is_required")}
                </FormControl.ErrorMessage>
              </FormControl>
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        nativeID="create-track-master-right"
        p="5"
        showBackButton
        disabled={createTrackMasterRight.isLoading || !isFetched}
        isLoading={createTrackMasterRight.isLoading}
        text={translate("save")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
