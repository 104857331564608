import React, { useEffect } from "react";
import { openBrowserAsync } from "expo-web-browser";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Icon, Text, Toast, VStack, Stack } from "native-base";
// utility functions
import { isWeb, translate } from "utils/helpers";
// internal hooks
import { useOrder } from "features/general/orders/hooks/useOrder";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { OrderDetailSuccess } from "features/general/orders/components/order-detail-success.component";
import { OrderDetailProcessing } from "features/general/orders/components/order-detail-processing.component";
import { OrderDetailAuthRequired } from "features/general/orders/components/order-detail-auth-required.component";
import { ToastAlert } from "theme/feedback/toast-alert.component";

export const OrderDetailScreen = ({ navigation, route }) => {
  const { orderID } = route.params;
  const order = useOrder(orderID);

  useEffect(() => {
    (async () => {
      if (order?.data?.status === "payment_authorization_required") {
        await openBrowserAsync(order?.data?.message);
      } else if (order?.data?.status === "successful") {
        const orderTypes = new Set();
        order?.data?.items?.forEach((item) => {
          item?.collectibles?.forEach((collectible) => {
            if (collectible?.category) {
              orderTypes.add(collectible?.category);
            }
          });
        });
        Toast.show({
          placement: "top",
          render: ({ id }) => (
            <ToastAlert
              id={id}
              status="success"
              description={
                orderTypes.size === 1
                  ? translate(
                      "order_successful_" + orderTypes.values()?.next()?.value
                    )
                  : translate("order_successful")
              }
            />
          ),
        });
      } else if (order?.data?.status === "failed") {
        Toast.show({
          placement: "top",
          render: ({ id }) => (
            <ToastAlert
              id={id}
              status="error"
              description={translate(order?.data?.message)}
            />
          ),
        });
        navigation.goBack();
      }
    })();
  }, [order?.data?.status]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <VStack safeAreaTop flex={1} p={5}>
        {order.data?.status === "successful" ||
        order.data?.status === "pending" ? (
          <OrderDetailSuccess
            actionId={order.data?.auction_id}
            isTicketCollectible={
              order.data?.items[0]?.collectibles[0]?.category === "ticket"
            }
            imageURL={
              order.data?.items?.length > 0 &&
              order.data?.items[0]?.collectibles?.length > 0
                ? order.data?.items[0]?.collectibles[0]?.image_with_url?.lg?.url
                : undefined
            }
            videoURL={
              order.data?.items?.length > 0 &&
              order.data?.items[0]?.collectibles?.length > 0
                ? order.data?.items[0]?.collectibles[0]?.metadata_json
                    ?.animation_url
                : undefined
            }
          />
        ) : (
          <Stack flex={1}>
            <HStack
              p={5}
              space={5}
              borderRadius={"xl"}
              alignItems={"center"}
              _dark={{ bg: "dark.900" }}
              _light={{ bg: "light.100" }}
            >
              <Icon
                color="secondary.600"
                as={MaterialCommunityIcons}
                name="information-outline"
              />
              <Text fontSize={["sm", "md"]}>
                {isWeb
                  ? translate("order_top_alert_message")
                  : translate("order_top_alert_message_native")}
              </Text>
            </HStack>
            <VStack
              mt={-24}
              flex={1}
              space={10}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CenteredSpinner color="secondary.600" />
              {(!order?.data?.status ||
                order.data?.status === "processing" ||
                order.data?.status === "payment_authorized") && (
                <OrderDetailProcessing />
              )}
              {order.data?.status === "payment_authorization_required" && (
                <OrderDetailAuthRequired message={order.data?.message} />
              )}
            </VStack>
          </Stack>
        )}
      </VStack>
    </ScreenFrame>
  );
};
