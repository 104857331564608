import React, { useContext } from "react";
import { VStack, useMediaQuery } from "native-base";
// internal
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { UserProfileBadgeList } from "features/accounts/users/components/user-profile-badge-list.component";
import { AuthenticationContext } from "services/authentication.context";

export const UserProfileBadgesListScreen = ({ route }) => {
  const { userID } = route.params;
  const { user } = useContext(AuthenticationContext);
  const [isDesktop] = useMediaQuery({ minWidth: 992 });
  const isUserProfile = userID === user?.uid;

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationPanel goesBack={true} />
      <VStack flex={1} px={isDesktop ? 24 : 0}>
        <UserProfileBadgeList
          userID={userID}
          screenList={true}
          isUserProfile={isUserProfile}
        />
      </VStack>
    </ScreenFrame>
  );
};
