import React, { useContext } from "react";
import { VStack } from "native-base";
// internal
import { isWeb } from "utils/helpers";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { CollectibleListImages } from "features/general/collectibles/components/collectible-list-images.component";
import { AuthenticationContext } from "services/authentication.context";

export const CollectiblesListScreen = ({ navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationPanel goesBack={!isWeb} />
      <VStack flex={1} p={5}>
        <CollectibleListImages
          navigation={navigation}
          userID={userDetails?.id}
        />
      </VStack>
    </ScreenFrame>
  );
};
