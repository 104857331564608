import React from "react";
import { HStack, Text, Pressable, Checkbox, Image } from "native-base";
import { DeletePaymentMethodButton } from "features/general/payment-methods/components/delete-payment-method-button.component";

export const OrderPreviewPaymentMethod = ({
  paymentMethod,
  isChecked = false,
  isApplePay = false,
  onPress = () => {},
}) => {
  const getPaymentMethodName = (str: string) => {
    if (str && str.length > 6) {
      return `${str.slice(0, 6)}..`;
    } else return str;
  };

  return (
    <Pressable m={2} borderRadius={"xl"} onPress={onPress}>
      <HStack
        p={3}
        space={2}
        borderRadius={"xl"}
        alignItems={"center"}
        borderWidth={isChecked ? 1 : 0}
        justifyContent={"space-between"}
        borderColor={isChecked ? "secondary.500" : ""}
      >
        <HStack space={5} alignItems={"center"}>
          <Checkbox
            borderRadius={"full"}
            isReadOnly={true}
            isChecked={isChecked}
            value={"payment_method"}
            colorScheme={"secondary"}
            onChange={(v) => onPress()}
            accessibilityLabel={"Select Payment Method"}
            background={isChecked ? "darkText" : "lightText"}
          />
          {isApplePay ? (
            <Image
              h={10}
              w={16}
              alt={"apple-pay"}
              resizeMode={"contain"}
              source={require("assets/images/brand-theme/apple-pay-black.png")}
            />
          ) : (
            <Text
              textAlign={"left"}
              fontSize={["md", "lg"]}
              fontWeight={"semibold"}
              textTransform={"uppercase"}
            >
              {getPaymentMethodName(paymentMethod?.public?.issuer) ||
                getPaymentMethodName(paymentMethod?.public?.bankname)}{" "}
              ****{paymentMethod?.public?.pan || paymentMethod?.public?.iban}
            </Text>
          )}
        </HStack>
        {!isApplePay && (
          <DeletePaymentMethodButton
            orderPreview
            paymentMethodID={paymentMethod.id}
          />
        )}
      </HStack>
    </Pressable>
  );
};
