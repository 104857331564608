import { Text, HStack, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// helpers
import { translate } from "utils/helpers";

const statuses = {
  draft: "transparent",
  pending: "light.900",
  accepted: "success.500",
  rejected: "error.500",
};

export const TrackMasterRightStatus = ({ masterRight, ...props }) => (
  <HStack alignItems="center" justifyContent="center" {...props}>
    {masterRight.status === "draft" && (
      <Icon
        nativeID="edit-track-master-right"
        as={MaterialCommunityIcons}
        name="pencil-circle-outline"
        size="lg"
      />
    )}
    <Text
      px="2"
      fontSize="sm"
      borderRadius="md"
      color="lightText"
      bg={statuses[masterRight.status]}
    >
      {translate(masterRight.status)}
    </Text>
  </HStack>
);
