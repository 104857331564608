import React, { useContext, useEffect, useState } from "react";
import { Magic } from "magic-sdk";
import { Box, Text, Toast } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import {
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  PhoneAuthProvider,
  signInWithCredential,
  useDeviceLanguage,
  RecaptchaVerifier,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { FirebaseContext } from "services/firebase.context";
// internal
import { linkTo } from "navigation";
import { navigationRef } from "navigation";
import { appRoutesConfig } from "navigation/route.config";
import { getErrorMessage, navigateBack, translate, isWeb } from "utils/helpers";
// internal components
import { Modal } from "theme/feedback/modal.component";
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { AuthenticationRequired } from "navigation/components/auth-required.component";
import { useUser } from "features/accounts/users/hooks/useUser";

export const AuthenticationContext = React.createContext(null);

export const AuthenticationContextProvider = ({ children, queryClient }) => {
  const { auth, functions } = useContext(FirebaseContext);

  const [user, setUser] = useState(null);
  const [remoteURL, setRemoteURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [profileType, setProfileType] = useState("fan");
  const [polygonMetadata, setPolygonMetadata] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showJoinCircleModal, setShowJoinCircleModal] = useState(false);

  const { data: userDetails, isLoading: isUserLoading } = useUser(!!user);

  const network = {
    rpcUrl: Constants.expoConfig.extra.POLYGON_RPC, // Polygon RPC URL
    chainId: Constants.expoConfig.extra.CHAIN_ID, // Polygon chain ID
  };
  const magic = new Magic(Constants.expoConfig.extra.MAGIC_API_KEY, {
    network: network,
  });

  React.useEffect(() => {
    console.debug("👂 Auth Listener");

    const unsubscribe = onIdTokenChanged(auth, (user) => {
      console.debug("🔊 Auth Listening...");
      if (user) {
        setUser(user?.toJSON());
        queryClient.setDefaultOptions({
          queries: {
            enabled: true,
            retry: false,
          },
        });
        // Set Magic Metadata
        (async () => {
          if (isWeb) {
            try {
              const metadata = await magic?.user?.getMetadata();
              setPolygonMetadata(metadata);
            } catch {
              console.debug(`❌ Magic User Error`);
            }
          }
        })();
      } else {
        setUser(null);
        queryClient.setDefaultOptions({
          queries: {
            enabled: false,
            retry: false,
          },
        });
      }
    });

    setIsLoading(false);
    // Return the cleanup function to unsubscribe from the listener
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userDetails) AsyncStorage.setItem("@user_id", userDetails?.id);
  }, [userDetails]);

  useEffect(() => {
    const unsub = async () => {
      if (!!user && !isUserLoading && !userDetails) {
        console.debug("🛑 No monolith user, blocking new registration");
        await onDeleteFirebaseUser();
      }
    };
    unsub();
  }, [user, isUserLoading, userDetails]);

  const showErrorMessage = (e, status, duration) => {
    Toast.show({
      duration,
      placement: "top",
      render: ({ id }) => (
        <ToastAlert
          closable
          id={id}
          status={status}
          description={getErrorMessage(e)}
        />
      ),
    });
  };

  const onCheckMagicAuthState = async () => {
    const isLoggedIn = await magic?.user?.isLoggedIn();
    console.debug(`👾 Magic user logged in: ${isLoggedIn}`);
    const isAdminLogin =
      (await AsyncStorage.getItem("@admin_login")) === "true";
    if (!isLoggedIn && !isAdminLogin) {
      console.debug(`🏌️‍♀️ Logging out Firebase user`);
      await onLogout("/account");
      Toast.show({
        duration: 8000,
        placement: "top",
        render: ({ id }) => (
          <Box key={id} p={3} bg={"gray.200"} rounded={"xl"} mx={5} maxW={300}>
            <Text color={"darkText"}>{translate("polygon_login_error")}</Text>
          </Box>
        ),
      });
    }
    return { isLoggedIn, isAdminLogin };
  };

  const onChangePhoneNumber = async (phoneNumber) => {
    const response = await httpsCallable(
      functions,
      "updateUser"
    )({ phoneNumber });
    console.debug(`📞 Firebase Phone number updated: ${response}`);
    // if response has token, sign in with token
    if (response?.data?.token) {
      await signInWithCustomToken(auth, response?.data?.token);
      console.debug(`🔑 Firebase user signed in with token`);
      queryClient.invalidateQueries("user");
    }
  };

  const onLoginOrRegister = async (phoneNumber, type, navigation, isCoupon) => {
    console.debug(`🔑 Login with phoneNumber: ${phoneNumber}`);
    setIsLoading(true);
    try {
      if (isWeb) await magic?.user?.logout();
      const didToken = await magic.auth.loginWithSMS({ phoneNumber });
      console.debug("👾 DID Token:", didToken);
      const cloudAuth = httpsCallable(functions, "auth");
      /* DID token is passed into the auth callable function */
      const result = (await cloudAuth({ didToken })).data;
      /* Firebase user access token is used to authenticate */
      await signInWithCustomToken(auth, result.token);
      queryClient.invalidateQueries("user");
      setPhoneNumber(phoneNumber);
      await AsyncStorage.setItem("@profile_type", type);
      setProfileType(type);
      setIsLoading(false);
      if (navigation) {
        if (isCoupon) {
          navigateBack(navigation);
        } else {
          if (remoteURL && remoteURL !== "/") {
            linkTo(remoteURL, appRoutesConfig);
          } else {
            navigation.navigate("BottomNavigator", {
              screen: "Marketplace",
            });
          }
        }
      }
      return true;
    } catch (e) {
      console.error(e);
      showErrorMessage(e, "error", 5000);
      setIsLoading(false);
      return false;
    }
  };

  const onFirebaseLoginOrRegister = async (
    phoneNumber,
    type,
    navigation,
    isCoupon = false
  ) => {
    console.debug(`🔑 Web Login with phoneNumber: ${phoneNumber}`);
    setIsLoading(true);
    try {
      useDeviceLanguage(auth);
      const phoneProvider = new PhoneAuthProvider(auth);
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
        );
      }
      window.recaptchaVerifier?.render();
      phoneProvider
        .verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
        .then((confirmationResult) => {
          setProfileType(type);
          setPhoneNumber(phoneNumber);
          setConfirmationResult(confirmationResult);
          setIsLoading(false);
          // navigation.navigate("Users", {
          //   screen: "CodeVerification",
          //   params: { is_coupon: isCoupon },
          // });
          setShowVerificationModal(true);
          setShowLoginModal(false);
        })
        .catch((e) => {
          console.error(e);
          showErrorMessage(e, "error", 5000);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      showErrorMessage(error, "error", 5000);
      setIsLoading(false);
    }
  };

  const onValidateOTP = (OTP, navigation, isCoupon = false) => {
    console.debug(`🔑 Validating OTP for: ${phoneNumber}`);
    setIsLoading(true);
    const credential = PhoneAuthProvider.credential(confirmationResult, OTP);
    signInWithCredential(auth, credential)
      .then((result) => {
        queryClient.invalidateQueries();
        setShowVerificationModal(false);
        if (isCoupon) {
          navigateBack(navigation);
        } else {
          if (remoteURL && remoteURL !== "/") {
            isWeb
              ? linkTo(remoteURL, appRoutesConfig)
              : navigation.navigate(remoteURL);
          } else {
            navigation.navigate("BottomNavigator", {
              screen: "Marketplace",
            });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(error, "error", 5000);
      });
  };

  const onAdminLoginOrRegister = async (
    adminNumber,
    phoneNumber,
    OTP,
    type,
    navigation
  ) => {
    setIsLoading(true);

    console.debug(`🤫 Admin number: ${adminNumber}`);
    console.debug(`🔑 Impersonation number: ${phoneNumber}`);
    try {
      if (isWeb) await magic?.user?.logout();
      const adminAuth = httpsCallable(functions, "adminAuth");
      const payload = {
        password: OTP,
        adminPhoneNumber: adminNumber,
        impersonatePhoneNumber: phoneNumber,
      };
      const privToken = (await adminAuth(payload)).data;
      console.debug(`🥸 Admin Token: ${privToken?.token}`);
      await signInWithCustomToken(auth, privToken?.token);
      queryClient.invalidateQueries();
      setPhoneNumber(phoneNumber);
      setProfileType(type);
      if (isWeb) AsyncStorage.setItem("@admin_login", true);
      navigation.navigate("BottomNavigator", {
        screen: "Marketplace",
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showErrorMessage(error, "error", 5000);
      setIsLoading(false);
    }
  };

  const onNativeLoginOrRegister = async (
    phoneNumber,
    type,
    navigation,
    recaptchaVerifier,
    isCoupon
  ) => {
    console.debug(`🔑 Native Login with phoneNumber: ${phoneNumber}`);
    setIsLoading(true);
    try {
      const phoneProvider = new PhoneAuthProvider(auth);
      phoneProvider
        .verifyPhoneNumber(phoneNumber, recaptchaVerifier.current)
        .then((confirmationResult) => {
          setPhoneNumber(phoneNumber);
          setProfileType(type);
          setConfirmationResult(confirmationResult);
          setIsLoading(false);
          setShowLoginModal(false);
          setShowVerificationModal(true);

          // navigation.navigate("Users", {
          //   screen: "CodeVerification",
          //   params: { is_coupon: isCoupon },
          // });
        })
        .catch((e) => {
          showErrorMessage(e, "error", 5000);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      showErrorMessage(error, "error", 5000);
      setIsLoading(false);
    }
  };

  const onDeleteFirebaseUser = async (user) => {
    console.debug(`🫡 Deleting Firebase user`);
    await onLogout((screen = "/login"));
    // user?.delete().then(() => {
    Toast.show({
      duration: 5000,
      placement: "top",
      render: ({ id }) => (
        <ToastAlert
          id={id}
          closable
          status={"error"}
          description={translate("registration_blocked")}
        />
      ),
    });
    // });
  };

  const onLogout = async (screen = "/home") => {
    console.debug(`🔑 Logging out: ${phoneNumber}`);
    setIsLoading(true);
    // if (isWeb) await magic?.user?.logout();
    AsyncStorage.removeItem("@admin_login");
    signOut(auth)
      .then(() => {
        queryClient.removeQueries();
        setRemoteURL("/");
        if (window.solana?.isConnected) {
          window.solana?.disconnect();
        }
        setIsLoading(false);
        linkTo(screen, appRoutesConfig);
      })
      .catch((e) => {
        setIsLoading(false);
        showErrorMessage(e, "error", 5000);
      });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        // Auth API calls
        magic,
        onLogout,
        onValidateOTP,
        onLoginOrRegister,
        onNativeLoginOrRegister,
        onChangePhoneNumber,
        onCheckMagicAuthState,
        onAdminLoginOrRegister,
        onFirebaseLoginOrRegister,
        // Auth helpers
        showAuthModal,
        setShowAuthModal,
        showJoinCircleModal,
        setShowJoinCircleModal,
        // User Object + Info
        user,
        setUser,
        userDetails,
        polygonMetadata,
        isLoading,
        isUserLoading,
        remoteURL,
        phoneNumber,
        setRemoteURL,
        isAuthenticated: !!user,
        profileType,
        setProfileType,
        //screenHelpers
        showLoginModal,
        showVerificationModal,
        setShowLoginModal,
        setShowVerificationModal,
      }}
    >
      {children}
      <Modal
        isOpen={showAuthModal}
        onPress={() => {
          setShowAuthModal(!showAuthModal);
        }}
        onClose={() => setShowAuthModal(false)}
        pressableChildren={<></>}
        modalStyles={{
          p: 0,
          bg: "light.100",
        }}
        modalChildren={
          <AuthenticationRequired
            navigation={navigationRef}
            setRemoteURL={setRemoteURL}
            setShowAuthModal={setShowAuthModal}
          />
        }
      />
    </AuthenticationContext.Provider>
  );
};
