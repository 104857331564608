import React from "react";
import { VStack, ScrollView, Box, Text } from "native-base";
// internal hooks
import { useTransactions } from "features/accounts/musicians/hooks/useTransactions";
import { useStreamingAnalytics } from "features/accounts/musicians/hooks/useStreamingAnalytics";
import { useEstimatedEarnings } from "features/accounts/musicians/hooks/useEstimatedEarnings";
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { EarningSwiper } from "features/royalties/releases/components/earning-swiper.component";
import { EstimatedEarningSwiper } from "features/royalties/releases/components/estimated-earnings-swiper.component";
import { TopChartsSwiper } from "features/royalties/releases/components/top-charts-swiper.component";
import { ListenersStatsSwiper } from "features/royalties/releases/components/listeners-stats-swiper.component";
import { Loading } from "common/components/loading/loading.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
import { translate } from "utils/helpers";

export const ArtistInsightsScreen = ({ navigation }) => {
  const musician = useUserMusician();
  const transactions = useTransactions();
  const estimatedEarnings = useEstimatedEarnings(musician.data?.id);
  const analytics = useStreamingAnalytics(musician.data?.id);

  return (
    <ScreenFrame safeAreaTopWithTopBar={false} isAuthenticationRequired>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {!transactions.isFetched ||
        !analytics.isFetched ||
        !estimatedEarnings.isFetched ? (
          <Loading />
        ) : (
          <VStack py={5} px={5}>
            <Text fontSize={["lg", "xl"]} textAlign={"center"} bold>
              {translate("insights")}
            </Text>
            <ResponsiveStack mt={2} space={6}>
              <VStack space={6} flex={{ lg: 1 }}>
                <EarningSwiper earnings={transactions.data} />
                <EstimatedEarningSwiper
                  earnings={
                    estimatedEarnings?.data || { day: [], week: [], month: [] }
                  }
                />
              </VStack>
              <Box flex={{ lg: 1 }}>
                <TopChartsSwiper
                  analytics={
                    analytics.data || {
                      top_songs: [],
                      top_playlist: [],
                      top_stores: [],
                      country: [],
                    }
                  }
                />
              </Box>
              <Box flex={{ lg: 1 }}>
                <ListenersStatsSwiper
                  analytics={
                    analytics.data || {
                      age: [],
                      gender: [],
                      device: [],
                      subscription: [],
                    }
                  }
                />
              </Box>
            </ResponsiveStack>
          </VStack>
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
