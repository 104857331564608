import React from "react";
import { VStack, Text, HStack, Divider } from "native-base";
// utility functions
import { getLocalizedDate, getLocalizedNumber, translate } from "utils/helpers";

const InformationItem = ({ title, value }) => (
  <HStack justifyContent={"space-between"}>
    <Text color={"gray.500"} fontSize={["md", "lg"]}>
      {title}
    </Text>
    <Text bold fontSize={["md", "lg"]}>
      {value}
    </Text>
  </HStack>
);

export const LicenseProductOrderCreateConfirmationPreview = ({
  units,
  product,
}) => {
  return (
    <VStack space={5}>
      <VStack space={5}>
        <InformationItem
          title={translate("order_type")}
          value={translate("proceeds")}
        />
        <Divider />
        <InformationItem title={"Release"} value={product?.title} />
        <HStack justifyContent={"space-between"}>
          <Text color={"gray.500"} fontSize={["md", "lg"]}>
            {translate("biddz")}
          </Text>
          <VStack space={1}>
            <Text bold fontSize={["md", "lg"]} textAlign={"right"}>
              {units}
            </Text>
            <Text color={"gray.500"} fontSize={"xs"} fontWeight={500}>
              {`${getLocalizedNumber(units / 100)}% of total royalty`}
            </Text>
          </VStack>
        </HStack>
        <InformationItem
          title={translate("license_end_date")}
          value={getLocalizedDate(product?.license_product?.end_date)}
        />
        <Divider />
        <InformationItem
          title={translate("total_price")}
          value={
            <Text bold fontSize={["md", "lg"]}>
              {" "}
              {getLocalizedNumber(
                Math.round(units * product?.price_per_unit * 100) / 100
              )}{" "}
              EUR
            </Text>
          }
        />
        <Divider />
      </VStack>
    </VStack>
  );
};
