import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUpdateProduct = (productID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateProduct: useMutation(
      (params) =>
        client
          .patch(`/products/${productID}/`, params)
          .then((resp) => resp.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`product-${productID}`);
          queryClient.invalidateQueries(`user-products`);
        },
        error: error,
      }
    ),
  };
};
