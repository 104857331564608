import React from "react";
import { HStack, Text, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal
import { LoadingSmall } from "common/components/loading/loading-small.component";

export const NewButtonBody = ({
  text,
  textStyles,
  iconStyles,
  isLoading = false,
  fontWeight = "bold",
  fontSize = ["xl", "2xl"],
  iconName = "arrow-right",
  rightComponent = undefined,
}) => {
  return (
    <HStack justifyContent="space-between" alignItems="center" space={10}>
      <Text fontSize={fontSize} fontWeight={fontWeight} {...textStyles}>
        {text}
      </Text>
      {isLoading ? (
        <LoadingSmall pt={0} {...iconStyles} maxHeight={20} />
      ) : rightComponent ? (
        rightComponent
      ) : (
        <Icon
          size="lg"
          name={iconName}
          as={MaterialCommunityIcons}
          {...iconStyles}
        />
      )}
    </HStack>
  );
};
