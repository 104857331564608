import React from "react";
import { Checkbox, FormControl, VStack, Text } from "native-base";
// internal components
import { Link } from "theme/typography/link.component";

export const OrderCreateAgreeTermsForm = ({
  url,
  setDisabled,
  isTicketOrder = false,
}) => {
  const licenseSpecialTerms =
    "https://storage.googleapis.com/app-public-europe-west3-1d3f335b/terms/20230904_special_terms_shares_ec607db2-aed9-487a-8b97-4a451d6e7309.pdf";
  const ticketSpecialTerms =
    "https://storage.googleapis.com/app-public-europe-west3-1d3f335b/terms/20230904_special_terms_tickets_ec607db2-aed9-487a-8b97-4a451d6e7309.pdf";

  return (
    <FormControl alignItems={"center"}>
      <VStack space={3} width={"90%"}>
        <Checkbox.Group
          colorScheme={"secondary"}
          onChange={(checked) => {
            setDisabled(
              !(
                checked.includes("right_of_withdrawal") &&
                checked.includes("special_terms")
              )
            );
          }}
        >
          <Checkbox
            mb={3}
            value={"special_terms"}
            accessibilityLabel={"Checkbox"}
          >
            <Text fontSize={["2xs", "xs"]}>
              Ich akzeptiere die
              <Link
                fontSize={["2xs", "xs"]}
                color={"darkText"}
                text={"Kaufsonderbedingungen"}
                url={isTicketOrder ? ticketSpecialTerms : licenseSpecialTerms}
              />
              {!isTicketOrder && (
                <Text fontSize={["2xs", "xs"]}>
                  und nehme das
                  <Link
                    url={url}
                    fontSize={["2xs", "xs"]}
                    color={"darkText"}
                    text={"Verbraucherinformationsblatt"}
                  />
                  zur Kenntnis
                </Text>
              )}
            </Text>
          </Checkbox>
          <Checkbox
            mb={1}
            value={"right_of_withdrawal"}
            accessibilityLabel={"Checkbox"}
          >
            <Text flexShrink={1} fontSize={["2xs", "xs"]} color={"darkText"}>
              Ich stimme zu, dass biddz mit der Ausführung des Vertrags vor Ende
              der Widerrufsfrist beginnt. Ich habe Kenntnis davon, dass ich mein
              Widerrufsrecht bei vollständiger Vertragserfüllung durch biddz
              verliere.
            </Text>
          </Checkbox>
        </Checkbox.Group>
      </VStack>
    </FormControl>
  );
};
