import React, { useState, useEffect, useContext } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  VStack,
  Text,
  FlatList,
  HStack,
  Box,
  Pressable,
  Image,
  Icon,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";

// internal
import { getLocalizedNumber, translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { usePolygonWalletUserID } from "features/accounts/profiles/hooks/usePolygonWalletUserID";
import { LoadingSmall } from "common/components/loading/loading-small.component";

const TicketCard = ({
  item,
  index,
  isActive,
  onlyItem,
  setBasketForProduct,
}) => {
  const [count, setCount] = useState(
    onlyItem && item?.category === "basic" ? 1 : 0
  );
  const [isTextVisible, setIsTextVisible] = useState(true);

  const price = getLocalizedNumber(item?.price_per_unit, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const increaseCount = () => setCount(count + 1);

  const decreaseCount = () => setCount(count - 1);

  useEffect(() => {
    setBasketForProduct(item, count);
  }, [count]);

  return (
    <Box mt={index == 0 ? 0 : 5}>
      <VStack
        p={5}
        space={4}
        bg={"gray.100"}
        borderRadius={"2xl"}
        justifyContent={"space-between"}
      >
        <HStack
          space={10}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <HStack flex={1} space={4} alignItems={"center"}>
            <Image
              size={6}
              alt={"ticket-vip"}
              source={require("assets/images/features/ticket-vip.png")}
            />
            <VStack space={1} flex={1}>
              <Text fontSize={"sm"} fontWeight={500}>
                {item?.subtitle}
              </Text>
              <Text fontSize={"md"} fontWeight={600}>
                {`${price} EUR`}
              </Text>
            </VStack>
          </HStack>
          {item?.description && (
            <Pressable onPress={() => setIsTextVisible(!isTextVisible)}>
              <Icon
                size={5}
                as={Ionicons}
                color={"darkText"}
                name={`chevron-${isTextVisible ? `up` : `down`}`}
              />
            </Pressable>
          )}
        </HStack>
        {isTextVisible && item?.description && (
          <Text fontSize={"xs"} pr={5} pl={10}>
            {item?.description}
          </Text>
        )}
        {item?.category != "basic" && (
          <HStack
            py={3}
            px={6}
            borderRadius={"full"}
            justifyContent={"space-between"}
            bg={
              item.available_units && isActive ? "darkBackground" : "gray.300"
            }
          >
            <Pressable
              w={10}
              onPress={decreaseCount}
              disabled={count <= 0 || !isActive}
            >
              <Text
                fontSize={"xl"}
                selectable={false}
                color={"lightText"}
                textAlign={"center"}
              >
                -
              </Text>
            </Pressable>
            <Text color={"lightText"} fontSize={"md"}>
              {item.available_units ? count : translate("sold_out")}
            </Text>
            <Pressable
              w={10}
              onPress={increaseCount}
              disabled={count >= item?.available_units || !isActive}
            >
              <Text
                fontSize={"xl"}
                selectable={false}
                color={"lightText"}
                textAlign={"center"}
              >
                +
              </Text>
            </Pressable>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export const TicketProductDetailOptions = ({
  auction,
  maxW = 450,
  isCollectible,
}) => {
  const profile = useUserProfile();
  const navigation = useNavigation();

  const [basket, setBasket] = useState({});

  const { user, setShowAuthModal, onLoginOrRegister, isLoading } = useContext(
    AuthenticationContext
  );
  const wallet = usePolygonWalletUserID(user?.uid);

  const onPress = async () => {
    if (!!user) {
      if (isCollectible && !wallet.data) {
        const isSuccess = await onLoginOrRegister(user.phoneNumber, "fan");
        if (!isSuccess) return;
      }
      if (profile.data?.is_complete) {
        navigation.navigate("OrderCreate", {
          auctionID: auction?.id,
          auctionInitialData: auction,
          existingBasket: basket,
        });
      } else {
        navigation.navigate("Profiles", {
          screen: "ProfileEditPersonalData",
          params: { id: user.id },
        });
      }
    } else {
      setShowAuthModal(true);
    }
  };

  const setBasketForProduct = (product, units, isAddOn = false) => {
    let newBasket = Object.assign({}, basket);

    if (units > 0) {
      newBasket[product.id] = {
        units: units,
        isAddOn: isAddOn,
        title: product?.title,
        add_ons: product?.add_ons,
        category: product?.category,
        pricePerUnit: product?.price_per_unit,
        totalPrice: units * product?.price_per_unit,
      };
    } else {
      delete newBasket[product.id];
    }
    setBasket(newBasket);
  };

  const calculateTotalPrice = () => {
    return getLocalizedNumber(
      Object.values(basket).reduce(
        (sum, product) => sum + product.totalPrice,
        0
      ),
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  };

  const checkoutInactive = !auction?.is_active || !Object.keys(basket).length;
  const hasStock = auction?.products.some((product) => product.available_units);

  return (
    <VStack
      p={5}
      space={5}
      shadow={5}
      maxW={maxW}
      bg={"light.50"}
      borderRadius={"2xl"}
    >
      <Text fontSize={"xl"} fontWeight={700}>
        {isCollectible
          ? translate("buy_collectible")
          : translate("ticket_options")}
      </Text>
      <FlatList
        data={auction?.products}
        renderItem={({ item, index }) => (
          <TicketCard
            item={item}
            index={index}
            isActive={auction?.is_active}
            setBasketForProduct={setBasketForProduct}
            onlyItem={auction?.products.length === 1}
          />
        )}
      />
      <Pressable
        p={3}
        onPress={onPress}
        borderRadius={"full"}
        bg={
          checkoutInactive
            ? "gray.100"
            : isLoading
            ? "secondary.100"
            : "secondary.300"
        }
        disabled={checkoutInactive || wallet.isLoading || isLoading}
      >
        {isLoading ? (
          <LoadingSmall pt={0} maxHeight={26} />
        ) : (
          <Text
            textAlign={"center"}
            fontSize={["md", "lg"]}
            color={checkoutInactive ? "gray.400" : "darkText"}
          >
            {!hasStock
              ? translate("sold_out")
              : checkoutInactive
              ? translate("checkout")
              : `${calculateTotalPrice()} EUR - ${translate("checkout")}`}
          </Text>
        )}
      </Pressable>
    </VStack>
  );
};
