import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Factory,
  Pressable,
  Box,
  Icon,
  VStack,
  Modal,
} from "native-base";
import { Video as ExpoVideo, ResizeMode } from "expo-av";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

const Video = Factory(ExpoVideo);

export const MultiMedia = ({
  type,
  source,
  onPress,
  showsVideoButton = true,
  posterSource = "",
  enableExpandImage = false,
  imgExpandStyle = { bottom: 0, right: 5 },
  ...props
}) => {
  const videoRef = useRef(null);
  const [videoStatus, setVideoStatus] = useState({});
  const [resizeMode, setResizeMode] = useState(ResizeMode.COVER);
  const [expandImage, setExpandImage] = useState(false);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });

  const toggleVideo = () => {
    videoStatus.isPlaying
      ? videoRef?.current?.pauseAsync()
      : videoRef?.current?.playAsync();
  };

  useEffect(() => {
    if (!type?.includes("video")) {
      if (source) {
        Image.getSize(source, (width, height) => {
          setImgSize({ width: width, height: height });
        });
      }
    }
  }, [source]);

  return type?.toLowerCase()?.includes("video") ? (
    <Pressable justifyContent={"center"} onPress={onPress || toggleVideo}>
      <Video
        {...props}
        p={2}
        ref={videoRef}
        source={{ uri: source }}
        resizeMode={resizeMode}
        videoStyle={{ height: "100%", width: "100%" }}
        onPlaybackStatusUpdate={(status) => setVideoStatus(status)}
        usePoster={true}
        posterSource={{ uri: posterSource }}
        posterStyle={{ resizeMode: ResizeMode.COVER }}
        onFullscreenUpdate={(e) =>
          e.fullscreenUpdate === 3 && setResizeMode(ResizeMode.COVER)
        }
      />
      {enableExpandImage && (
        <Pressable
          zIndex={99}
          position={"absolute"}
          style={imgExpandStyle}
          onPress={async () => {
            setResizeMode(ResizeMode.CONTAIN);
            await videoRef.current.presentFullscreenPlayer();
          }}
        >
          <Icon
            size={10}
            as={MaterialCommunityIcons}
            color={"light.400"}
            name={"fullscreen"}
          />
        </Pressable>
      )}
      {showsVideoButton && !videoStatus.isPlaying && (
        <Box
          position={"absolute"}
          alignSelf={"center"}
          justifyContent={"center"}
        >
          <Icon size={10} as={Ionicons} color={"light.400"} name={"play"} />
        </Box>
      )}
    </Pressable>
  ) : (
    // type?.includes("image") && (
    <VStack>
      <Image
        {...props}
        resizeMode={"cover"}
        source={
          source
            ? { uri: source }
            : require("assets/images/features/release-banner.jpeg")
        }
        alt={props?.alt || "image"}
      />
      {enableExpandImage && (
        <Pressable
          zIndex={99}
          pointerEvents="box-none"
          position={"absolute"}
          style={imgExpandStyle}
          onPress={() => setExpandImage(true)}
        >
          <Icon
            size={10}
            as={MaterialCommunityIcons}
            color={"light.400"}
            name={"fullscreen"}
          />
        </Pressable>
      )}
      <Modal isOpen={expandImage} onClose={() => setExpandImage(false)}>
        <Modal.Content
          p={5}
          w={imgSize.width}
          justifyContent={"center"}
          _light={{ bg: "light.400" }}
          _dark={{ bg: "dark.900" }}
        >
          <Pressable onPress={() => setExpandImage(false)}>
            <Image
              width={imgSize.width}
              height={imgSize.height > 350 ? 350 : imgSize.height}
              resizeMode={"contain"}
              source={
                source
                  ? { uri: source }
                  : require("assets/images/features/release-banner.jpeg")
              }
              alt={props?.alt || "image"}
            />
          </Pressable>
        </Modal.Content>
      </Modal>
    </VStack>
  );
};
