import {
  HStack,
  Icon,
  Text,
  Avatar,
  Pressable,
  Box,
  ScrollView,
  Modal,
  CloseIcon,
} from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

export const MusicianListModal = ({
  title,
  showModal,
  toggleModal,
  musicians,
}) => {
  const navigation = useNavigation();

  const goToMusician = (m) => {
    toggleModal();
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: { id: m?.id, initialData: m },
    });
  };

  return (
    <Modal isOpen={showModal} onClose={toggleModal} borderRadius={"xl"}>
      <Modal.Content pb={4} borderRadius={"xl"}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Modal.Body p={0}>
            <HStack
              p={4}
              alignItems={"center"}
              borderBottomWidth={1}
              justifyContent={"flex-end"}
              borderBottomColor={"light.300"}
            >
              <Text flex={1} fontWeight={500} textAlign={"center"}>
                {title}
              </Text>
              <Pressable onPress={toggleModal}>
                <CloseIcon size={3} />
              </Pressable>
            </HStack>

            {musicians?.map((m, i) => (
              <Pressable key={i} p={4} onPress={() => goToMusician(m)}>
                <HStack alignItems={"center"} justifyContent={"space-between"}>
                  <HStack space={4} alignItems={"center"}>
                    <Avatar
                      size={"sm"}
                      bg={"green.500"}
                      source={{
                        uri: m?.image_with_url?.xs?.url,
                      }}
                    >
                      {m?.alias}
                    </Avatar>
                    <Text
                      numberOfLines={1}
                      color={"darkText"}
                      fontSize={["sm", "md"]}
                    >
                      {m?.alias}
                    </Text>
                  </HStack>

                  <Box
                    p={2}
                    borderWidth={1}
                    borderRadius={"full"}
                    borderColor={"gray.300"}
                  >
                    <Icon size={4} as={AntDesign} name={"arrowright"} />
                  </Box>
                </HStack>
              </Pressable>
            ))}
          </Modal.Body>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};
