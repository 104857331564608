import React from "react";
import { Image, HStack, VStack } from "native-base";
//internal hooks
import useAcceptTerms from "features/accounts/users/hooks/useAcceptTerms";
import { useTermsVersion } from "features/accounts/users/hooks/useTermsVersion";
//internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { TermsOfUse } from "features/accounts/users/components/terms-of-use";
import { TermsDescription } from "features/accounts/users/components/terms-descriptions";
import { TermsDescriptionNewUser } from "features/accounts/users/components/terms-descriptions_new";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
// utility functions
import { useUser } from "../hooks/useUser";
import { navigateBack, translate } from "utils/helpers";

const UserEditTermsOfAgreementBody = ({ navigation, user, termsVersion }) => {
  const { acceptTerms } = useAcceptTerms();

  const acceptTermsOfUse = (accepted: boolean) => {
    const data: any = {
      version: termsVersion?.version,
      accepted: accepted,
    };
    acceptTerms.mutate(data);
  };

  React.useEffect(() => {
    if (acceptTerms.isSuccess)
      navigation.navigate("BottomNavigator", {
        screen: "Marketplace",
      });
  }, [acceptTerms.isSuccess]);

  return (
    <VStack p={5} flex={1} space={"lg"}>
      <Image
        source={require("assets/images/biddz-theme/biddz-logo-extended-white.png")}
        width={[121, 121 * 1.5]}
        height={[50, 50.5 * 1.5]}
        resizeMode="contain"
        alt={"Biddz logo"}
      />
      {user?.accepted_terms_and_conditions_version ? (
        <TermsDescription />
      ) : (
        <TermsDescriptionNewUser />
      )}

      <TermsOfUse />
      <HStack m={8} space={5} justifyContent="center">
        {user?.accepted_terms_and_conditions_version ? (
          <SecondaryButton
            text={translate("not_now")}
            onPress={() => acceptTermsOfUse(false)}
          />
        ) : null}
        <PrimaryButton
          _ios={{ width: "100%" }}
          text={translate("accept")}
          onPress={() => acceptTermsOfUse(true)}
        />
      </HStack>
    </VStack>
  );
};

export const UserEditTermsOfAgreementScreen = ({ navigation }) => {
  const user = useUser(true);
  const termsVersion = useTermsVersion();

  React.useEffect(() => {
    if (
      user?.isSuccess &&
      termsVersion?.isSuccess &&
      user?.data?.accepted_terms_and_conditions_version >=
        termsVersion?.data?.version
    ) {
      navigateBack(navigation);
    }
  }, [termsVersion?.isSuccess, user?.isSuccess, user?.isLoading]);

  return (
    <ScreenFrame safeAreaTop>
      {user?.isSuccess &&
      termsVersion?.isSuccess &&
      user?.data?.accepted_terms_and_conditions_version <
        termsVersion?.data?.version ? (
        <UserEditTermsOfAgreementBody
          user={user?.data}
          navigation={navigation}
          termsVersion={termsVersion?.data}
        />
      ) : (
        <CenteredSpinner />
      )}
    </ScreenFrame>
  );
};
