import React from "react";
import { VStack, Box, useMediaQuery } from "native-base";
// internal components
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { AuctionBookmark } from "features/general/auctions/components/auction-bookmark.component";
import { TicketProductDetailEvent } from "features/tickets/ticket-products/components/ticket-product-detail-event.component";
// internal hooks
import { useProduct } from "features/general/products/hooks/useProduct";

export const TicketProductDetail = ({ auction, isDardan }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const product = useProduct(auction?.products[0].id);

  return (
    <VStack space={10} mb={5}>
      <Box py={10} bg={"gray.100"} alignItems={"center"} position={"relative"}>
        <Box position={"absolute"} top={6} right={isDesktop ? 24 : 5}>
          <AuctionBookmark auction={auction} size="xl" />
        </Box>
        <AuctionDetailImage
          justifyContent={{ base: "center", lg: "flex-end" }}
          url={
            product.data?.picasso_generated_images?.wrapped?.image_with_url?.lg
              ?.url
          }
        />
      </Box>
      <VStack mx={isDesktop ? 24 : 5}>
        <TicketProductDetailEvent isDardan={isDardan} auction={auction} />
      </VStack>
    </VStack>
  );
};
