import React, { useContext, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { VStack, Text, HStack, Pressable, Avatar } from "native-base";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
// internal
import { getWindowWidth } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
// internal components
import { CommentDetailReport } from "features/general/comments/components/comment-detail-report.component";
import { CommentDetailDelete } from "features/general/comments/components/comment-detail-delete.component";
import { useUserFirebaseProfile } from "features/accounts/profiles/hooks/useUserFirebaseProfile";

export const CommentDetail = ({ comment, auctionID }) => {
  const navigation = useNavigation();
  const { userDetails } = useContext(AuthenticationContext);
  const isUserComment = userDetails?.id === comment?.user_id;

  const [showIcons, setShowIcons] = useState(false);
  const profile = useUserFirebaseProfile(comment.user_uid);
  const { data: auction } = useAuction(auctionID);

  const navigateToProfile = () => {
    navigation.navigate("Profiles", {
      screen: "ProfileDetail",
      params: { profileID: profile.data?.profile_id },
    });
  };

  const navigateToMusicianProfile = () => {
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: { id: profile.data?.musician_id },
    });
  };

  const isCreator = auction?.musicians?.some(
    (musician) => musician.profile.user_id === profile.data?.user_id
  );

  return (
    <HStack mb={5} space={5}>
      <Pressable
        borderRadius={"xl"}
        onPress={() => setShowIcons(!showIcons)}
        _light={{ bg: isCreator ? "primary.300" : "dark.100" }}
        _dark={{ bg: isCreator ? "primary.600" : "dark.900" }}
        bg={isCreator ? "primary.600" : "dark.900"}
      >
        <HStack space={2} padding={2}>
          <Pressable
            onPress={isCreator ? navigateToMusicianProfile : navigateToProfile}
          >
            <Avatar
              size="sm"
              borderRadius={"full"}
              source={
                {
                  uri: profile.data?.image?.sm?.url,
                } || require("assets/images/features/profile-picture.png")
              }
            />
          </Pressable>
          <VStack space={1}>
            <HStack
              space={3}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Text fontSize={["xs", "sm"]} fontWeight={"bold"}>
                {isCreator && profile.data?.musician_alias
                  ? `${profile.data?.musician_alias} `
                  : `@${
                      profile.data?.username ||
                      `user-` + comment.user_uid?.slice(-4)
                    } `}
              </Text>
              {showIcons && (
                <HStack alignItems="center" space={1}>
                  {!isUserComment && (
                    <CommentDetailReport
                      auctionID={auctionID}
                      commentID={comment?.id}
                    />
                  )}
                  {(isUserComment || userDetails?.is_admin) && (
                    <CommentDetailDelete
                      auctionID={auctionID}
                      commentID={comment?.id}
                    />
                  )}
                </HStack>
              )}
            </HStack>

            <Text maxWidth={getWindowWidth() * 0.75} fontSize={["xs", "sm"]}>
              {comment?.body}
            </Text>
          </VStack>
        </HStack>
      </Pressable>
    </HStack>
  );
};
