import React from "react";
import { Image, Box, Text, VStack } from "native-base";
import { translate } from "utils/helpers";

export const LicenseCollectibleAnalyticsEmpty = () => {
  return (
    <VStack
      mt={6}
      mr={12}
      space={4}
      maxW={300}
      height={150}
      _ios={{ top: 25 }}
      alignSelf={"center"}
      alignItems={"center"}
      position={"absolute"}
      justifyContent={"center"}
    >
      <Image
        size={[8, 12]}
        alt={"activity-logo"}
        source={require("assets/images/features/activity.png")}
      />
      <Box flex={1}>
        <Text textAlign={"center"} fontSize={{ base: "md", lg: "lg" }}>
          <Text bold>{translate("empty_analytics_title")}</Text>
          {`\n${translate("empty_analytics_desc")}`}
        </Text>
      </Box>
    </VStack>
  );
};
