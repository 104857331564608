import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useReleaseEstimatedEarnings = (id) => {
  const { client } = useContext(AxiosContext);
  return useQuery(`release-${id}-estimated-earnings`, () => {
    console.debug(`release-${id}-estimated-earnings retrieved`);

    return client
      .get(`releases/${id}/estimated_earnings/`, { params: { detailed: true } })
      .then((resp) => resp.data);
  });
};
