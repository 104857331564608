import React from "react";
import { useController } from "react-hook-form";
import {
  FormControl,
  Select as NBSelect,
  HStack,
  Text,
  VStack,
} from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { IconModal } from "theme/feedback/icon-modal.component";
import { translate } from "utils/helpers";

export const FormControlSelect = ({
  name,
  query,
  control,
  rules = {},
  value = null,
  label = null,
  infoText = null,
  nullable = false,
  helperText = null,
  isDisabled = false,
  placeholder = null,
  useValues = false,
  translateOptions = false,
  setShowInput = (val) => {},
}) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });

  return (
    <FormControl
      isRequired={rules?.required}
      isInvalid={invalid}
      isDisabled={isDisabled}
    >
      <HStack flex={1}>
        <VStack w="100%" space={1} pl={1}>
          {label && (
            <HStack alignItems="center" justifyContent="space-between">
              <HStack space="2" alignItems="center">
                <FormControl.Label
                  _text={{
                    _light: { color: "darkText" },
                    _dark: { color: "lightText" },
                  }}
                >
                  {label}
                </FormControl.Label>
                {!rules?.required && (
                  <Text color="primary.600">{`(${translate(
                    "optional"
                  )})`}</Text>
                )}
              </HStack>
              {infoText && (
                <IconModal
                  iconColor="primary.600"
                  icon={<AntDesign name="questioncircleo" />}
                  contentText={infoText}
                />
              )}
            </HStack>
          )}
          <NBSelect
            size="xl"
            borderRadius="lg"
            placeholder={placeholder}
            selectedValue={field.value}
            onValueChange={(val) => {
              field.onChange(val);
              //logic to show input if requires_artist_input = true
              const item = query.data?.find((obj) => obj.value === val);
              setShowInput(item?.requires_artist_input);
            }}
          >
            {!query.isLoading && nullable ? (
              <NBSelect.Item label="-" value={""} />
            ) : (
              <></>
            )}
            {!query.isLoading &&
            query.isSuccess &&
            query.data &&
            query.data.length > 0 ? (
              query.data.map((row, index) => (
                <NBSelect.Item
                  key={name + index}
                  label={
                    translateOptions
                      ? translate(row.value.toLowerCase())
                      : useValues
                      ? row.value
                      : row.label
                  }
                  value={row.value}
                  _web={{ size: ["md", "lg"] }}
                />
              ))
            ) : (
              <NBSelect.Item label={"Loading"} />
            )}
          </NBSelect>
          {helperText && (
            <FormControl.HelperText>{helperText}</FormControl.HelperText>
          )}
          {error && (
            <FormControl.ErrorMessage>
              {error.type == "required" && translate("is_required")}
            </FormControl.ErrorMessage>
          )}
        </VStack>
      </HStack>
    </FormControl>
  );
};
