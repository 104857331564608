import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Box, HStack, Icon, VStack, Text } from "native-base";
// utility functions
import { navigateBack, translate } from "utils/helpers";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
// internal hooks
import { useCollectible } from "features/general/collectibles/hooks/useCollectible";
// internal components
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { CollectibleDetailMint } from "features/general/collectibles/components/collectible-detail-mint.component";
import { ModalFrame } from "navigation/components/modal-frame.component";

export const TicketCollectibleDetailMintScreen = ({ navigation, route }) => {
  const { collectibleID, collectibleInitialData } = route.params;
  const collectible = useCollectible(collectibleID, collectibleInitialData);

  const isFirstRouteInParent = useIsFirstRouteInParent();
  React.useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.replace("TicketCollectibles", {
        screen: "TicketCollectibleDetail",
        params: { id: collectibleID },
      });
    }
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        shadow={-0}
        bg={"light.100"}
        overflow={"hidden"}
        borderTopRadius={20}
      >
        <Box mt={3} />
        <NavigationHeader
          title={translate("mint_nft")}
          headerRight={{
            iconName: "close",
            onPress: () => navigateBack(navigation),
          }}
          bg="transparent"
        />
        <VStack p={5}>
          <VStack space={5} p={5} mb={5}>
            <HStack space={2}>
              <Icon
                size={7}
                color={"secondary.600"}
                name="information-outline"
                as={MaterialCommunityIcons}
              />
              <Text flex={1}>{translate("moment_mint_description")}</Text>
            </HStack>
          </VStack>
          <CollectibleDetailMint collectible={collectible?.data} />
        </VStack>
      </VStack>
    </ModalFrame>
  );
};
