import React from "react";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { Linking, Platform, Alert } from "react-native";
// utility functions
import { translate } from "utils/helpers";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

interface IProps {
  imageURL: string;
  size?: string;
}

export const DownloadImageButton = ({ imageURL, size = "lg" }: IProps) => {
  const [downloading, setDownloading] = React.useState(false);

  const downloadOnNative = async (url: string) => {
    try {
      const uri = FileSystem.cacheDirectory + Date.now() + ".png";
      const photosPermissions = await MediaLibrary.requestPermissionsAsync();

      if (photosPermissions.granted) {
        setDownloading(true);
        FileSystem.downloadAsync(url, uri)
          .then((savedImage) => {
            MediaLibrary.saveToLibraryAsync(savedImage.uri)
              .then(() => {
                Alert.alert(
                  translate("video_saved_alert_title"),
                  translate("video_saved_alert_body")
                );
              })
              .catch((error) => console.debug(error));
          })
          .catch((error) => console.debug(error))
          .finally(() => setDownloading(false));
      } else {
        Alert.alert(
          translate("library_permission_alert_title"),
          translate("library_permission_alert_body")
        );
      }
    } catch (error) {
      console.debug(error);
    }
  };

  const downloadImage = async () => {
    if (imageURL) {
      if (Platform.OS === "web") {
        Linking.openURL(imageURL);
      } else if (Platform.OS === "android" || Platform.OS === "ios") {
        downloadOnNative(imageURL);
      }
    }
  };

  return (
    <AppIconButton
      nativeID="download-image"
      size={size}
      onPress={downloadImage}
      iconName="export-variant"
      disabled={!imageURL || downloading}
    />
  );
};
