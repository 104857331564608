import { Image, Pressable, Text, VStack } from "native-base";
import React from "react";

export const ListEmptyComponent = ({
  text,
  subText,
  onPress,
  buttonText,
  height = [150, 200],
  image = require("assets/images/features/benefits/benefits-list-empty.png"),
}) => {
  return (
    <VStack alignItems={"center"}>
      <Image
        h={height}
        w={[400, 500]}
        source={image}
        alt={"empty_list"}
        resizeMode={"cover"}
      />
      <VStack space={4} maxW={350} mt={[-16, -20]} alignItems={"center"}>
        <Text fontSize={"lg"} fontWeight={600} textAlign={"center"}>
          {text}
        </Text>
        <Text fontSize={"sm"} textAlign={"center"}>
          {subText}
        </Text>
        {buttonText && (
          <Pressable
            py={3}
            px={6}
            rounded={"full"}
            onPress={onPress}
            bg={"secondary.300"}
            alignItems={"center"}
          >
            <Text>{buttonText}</Text>
          </Pressable>
        )}
      </VStack>
    </VStack>
  );
};
