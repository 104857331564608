import React from "react";
import { FlatList, VStack, Text, HStack, Divider, Box } from "native-base";
// internal
import { usePosts } from "features/general/posts/hooks/usePosts";
import { PostDetail } from "features/general/posts/components/post-detail.component";
import { PostListSkeleton } from "../skeletons/post-list.skeleton";
// helpers
import { translate } from "utils/helpers";

export const PostList = ({ auctionID, isPublic = true }) => {
  const posts = usePosts(auctionID, isPublic);
  return !posts?.isLoading && posts?.data?.length > 0 ? (
    <VStack space={10}>
      <VStack space={5}>
        <Text
          fontWeight={"black"}
          fontSize={["xl", "2xl"]}
          textTransform={"capitalize"}
        >
          {translate("artist_feed")}
        </Text>
        <HStack justifyContent={"center"}>
          {posts.isLoading ? (
            <PostListSkeleton />
          ) : (
            <FlatList
              horizontal
              data={posts?.data}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item }) => <PostDetail post={item} />}
            />
          )}
        </HStack>
      </VStack>
      <Divider />
    </VStack>
  ) : (
    <Box my={-5}></Box>
  );
};
