import React from "react";
import { VStack, Skeleton, Box, HStack } from "native-base";
import { SkeletonTextVertical } from "theme/skeletons/skeleton.text-vertical";

export const OrderListSkeleton = () => (
  <VStack flex={1} m={2}>
    {Array(5)
      .fill("")
      .map((_unique, i) => {
        return (
          <VStack key={i} h={24} my={[1, 3]}>
            <HStack h={["25%", "30%"]} justifyContent={"space-between"}>
              <Skeleton w={["40%", "30%"]} startColor={"skeleton.dark"} />
              <Skeleton w={["15%", "10%"]} startColor={"skeleton.dark"} />
            </HStack>
            <Box h={"40%"} w={"70%"} mt={2}>
              <SkeletonTextVertical numberOfLines={2} />
            </Box>
          </VStack>
        );
      })}
  </VStack>
);
