import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteComment = (auctionID, commentID, setLoading) => {
  const { client } = useContext(AxiosContext);
  return {
    deleteComment: useMutation(() => {
      return client.delete(`auctions/${auctionID}/comments/${commentID}/`);
    }),
  };
};

export default useDeleteComment;
