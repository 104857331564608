import React, { useEffect } from "react";
import { Platform } from "react-native";
import { VStack, Text } from "native-base";
import { translate } from "utils/helpers";
import { LoginForm } from "features/accounts/users/components/login-form.component";
import { CouponDetailProgressBar } from "features/general/coupons/components/coupon-detail-progress-bar.component";
import { useContext } from "react";
import { AuthenticationContext } from "services/authentication.context";

export const CouponLogin = ({ isDardan }) => {
  const { setRemoteURL } = useContext(AuthenticationContext);
  useEffect(() => {
    if (Platform.OS === "web") {
      if (window.location.pathname !== "/") {
        const search = window.location.search ? window.location.search : "";
        setRemoteURL(window.location.pathname + search);
      }
    }
  }, []);
  return (
    <VStack space={5}>
      <CouponDetailProgressBar activeIndex={0} />
      <Text
        bold
        fontSize={["xl", "2xl", "3xl"]}
        textAlign="center"
        textTransform={"uppercase"}
      >
        {translate("redeem_coupon_header")}
      </Text>
      <Text fontSize={["md", "lg", "xl"]}>
        {isDardan
          ? "Hol dir kostenfrei deinen exklusiven Mister Dardy Tour Collectible und werde Teil der Dardan Community. \nSichere dir außerdem die Chance auf einen von 100 tollen Gewinnen."
          : translate("redeem_coupon_description")}
      </Text>
      <LoginForm />
    </VStack>
  );
};
