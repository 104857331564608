import React, { useContext } from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
//internal
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { translate } from "utils/helpers";

export const useUpdateMusicianStatus = (id) => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();

  return {
    updateMusicianStatus: useMutation(
      (params) =>
        client
          .post(`musicians/${id}/status/`, params)
          .then((resp) => resp.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("musician");
          queryClient.invalidateQueries(`musician-${id}`);
          Toast.show({
            placement: "top",
            render: ({ id }) => (
              <ToastAlert
                closable
                id={id}
                status="success"
                description={translate("musician_application_submitted")}
              />
            ),
          });
        },
      }
    ),
  };
};
