import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { VStack, Text, HStack, Image } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
// internal hooks
import useDeleteAuctionBenefit from "features/general/auctions/hooks/useDeleteAuctionBenefit";
import { useBenefitTypes } from "features/general/auctions/hooks/useBenefitTypes";

import { translate } from "utils/helpers";

export const AuctionEditBenefitCard = ({
  benefit,
  control,
  tier,
  title,
  biddz,
  disabled,
  disableCheckbox,
  infoText,
}) => {
  const { deleteBenefit } = useDeleteAuctionBenefit(benefit?.id);
  const { field } = useController({
    control,
    name: tier,
    defaultValue: benefit?.tier === tier,
  });
  const benefits = useBenefitTypes();
  const [showInput, setShowInput] = useState(benefit?.artist_input);

  useEffect(() => {
    if (benefit?.id && field.value === false) {
      deleteBenefit.mutate();
    }
  }, [field.value]);

  return (
    <VStack
      p={5}
      space={2}
      borderRadius="xl"
      borderWidth={1}
      borderColor="dark.700"
    >
      <HStack justifyContent="space-between" alignItems="center">
        <HStack space={2}>
          <Image
            source={{
              uri: `https://storage.googleapis.com/app-public-europe-west3-1d3f335b/app_assets/biddz_${tier}_tier.png`,
            }}
            height={50}
            width={50}
            alt=""
            resizeMode="contain"
          />
          <VStack space={1}>
            <Text fontSize={["md", "lg"]} bold>
              {title}
            </Text>
            <Text fontSize={["sm", "md"]}>{biddz}</Text>
          </VStack>
        </HStack>
        <VStack space={3}>
          {infoText && (
            <IconModal
              iconColor="primary.600"
              icon={<AntDesign name="questioncircleo" />}
              contentText={infoText}
            />
          )}
          <BooleanCheckbox
            isDisabled={disableCheckbox}
            width="auto"
            control={control}
            name={tier}
            value={benefit?.tier === tier || false}
          />
        </VStack>
      </HStack>
      {field.value && (
        <>
          <FormControlSelect
            translateOptions
            isDisabled={disabled}
            setShowInput={setShowInput}
            name={`${tier}_benefits`}
            control={control}
            query={benefits}
            value={benefit?.type}
          />
          {showInput && (
            <FormControlInput
              isDisabled={disabled}
              name={`${tier}_benefit_artist_input`}
              control={control}
              rules={{ required: true }}
              placeholder={translate("artist_input_placeholder")}
              value={benefit?.artist_input}
            />
          )}
        </>
      )}
    </VStack>
  );
};

export const AuctionEditBenefitGoldenCard = ({ control }) => {
  const { field } = useController({ control, name: "golden_egg" });

  const benefits = useBenefitTypes();
  return (
    <VStack
      p={5}
      mx={5}
      space={4}
      borderWidth={1}
      borderColor="yellow.500"
      borderRadius="xl"
    >
      <Text fontSize={["md", "lg"]} bold>
        Golden Benefit (rename)
      </Text>
      <Text fontSize={["sm", "md"]}>
        Add a Golden RENAME Benefits to one random winning fan in order to
        increase sales.
      </Text>
      <HStack alignItems="center" justifyContent="space-between">
        <HStack space={2} alignItems="center">
          <Image
            source={require("assets/images/biddz-theme/gold-star.png")}
            height={12}
            width={12}
            alt=""
          />
          <VStack>
            <Text fontSize={["sm", "md"]} bold>
              RENAME
            </Text>
            <Text fontSize={["sm", "md"]}>100+ biddz</Text>
          </VStack>
        </HStack>

        <BooleanCheckbox
          control={control}
          name="golden_egg"
          value={false}
          flexDirection="row-reverse"
          width="auto"
        >
          <Text mr={2} fontSize="md" color="primary.600">
            Add Golden Egg
          </Text>
        </BooleanCheckbox>
      </HStack>
      {field.value && (
        <FormControlSelect
          name="golden_benefit"
          control={control}
          query={benefits}
          value=""
        />
      )}
    </VStack>
  );
};
