import bs58 from "bs58";
import i18n from "i18n-js";
import { Toast } from "native-base";
import Constants from "expo-constants";
import { Dimensions, Platform } from "react-native";
import { openBrowserAsync } from "expo-web-browser";
import { TextEncoder, TextDecoder } from 'text-encoding';

export const translate = (key: string, config?: any) => i18n.t(key, config);

export const randomLetter = () => String.fromCharCode(97 + Math.floor(Math.random() * 26));

export const getLocalizedNumber = (num: string | number, options = {}) => {
    if (num || num == 0) {
        return typeof num === "string"
            ? parseFloat(num).toLocaleString(i18n.locale, options)
            : num.toLocaleString(i18n.locale, options);
    } else {
        return null;
    }
};

export const capsWords = (str: string, splitChar = " ") => {
    return str.split(splitChar)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(splitChar)
}

export const getLocalizedDateTime = (date: string) =>
    new Date(Date.parse(date)).toLocaleString(i18n.locale);

export const getLocalizedDateHour = (date: string) =>
    new Date(Date.parse(date)).toLocaleString(i18n.locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });

export const getLocalizedDate = (date: string) =>
    new Date(Date.parse(date)).toLocaleDateString(i18n.locale);

export const getLocalizedDateWithDay = (date: string) =>
    new Date(Date.parse(date)).toLocaleDateString(i18n.locale, {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
    });

export const getErrorMessage = (error: any) => {
    let message = translate("something_went_wrong");
    if (error && error.response && error.response.data) {
        const { data } = error.response;
        if (typeof data.detail === "string") {
            message = translate(data.detail);
        } else if (Array.isArray(data.detail)) {
            message = "";
            data?.detail?.forEach((el) => {
                if (el.loc[0] === "tracklisting") el.loc[1] = el.loc[1] + 1;
                const locs = el.loc.filter((item) => item != "__root__").join(", ");
                message += "- " + locs + "\n\t" + translate(el.msg) + "\n";
            });
        }
    }
    return message;
};

export const navigateBack = (
    navigation: any,
    screen: string = "BottomNavigator"
) => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation?.replace(screen);
};

export const navigateToProfile = (navigation: any, profileID: string) => {
    navigation.navigate("Profiles", {
        screen: "ProfileDetail",
        params: { profileID: profileID },
    });
};

export const getTier = (value: number) => {
    if (value >= 100) return "diamond";
    else if (value >= 10) return "platinum";
    else return "gold";
};

export const getSliderColor = (tier: string) => {
    const sliderColor = {
        gold: "#D4AF37",
        platinum: "#aaa9ad",
        diamond: "#e5e4e2",
    };

    return sliderColor[tier];
};

export const randomColor = () =>
    ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(0, 7);

export const getRandomColorArray = (length: number) => {
    const colors = [];
    for (let i = 0; i < length; i++) {
        colors.push(randomColor());
    }
    return colors;
};

export const getRandomString = function (length: number) {
    var text = "";
    var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const getWindowWidth = () => {
    return Math.min(Dimensions.get("window").width, 992);
};

export const getProvider = () => {
    if ("phantom" in window) {
        const provider = window?.phantom?.solana;
        if (provider?.isPhantom) {
            return provider;
        }
    }
    openBrowserAsync("https://phantom.app/");
};

export const signMessage = async (accessToken: string, provider: any) => {
    const message = `Sign this message for authenticating with your wallet. Nonce: ${accessToken.slice(
        -24
    )}`;
    const encodedMessage = new TextEncoder().encode(message);
    const { signature, publicKey } = await provider.signMessage(encodedMessage);
    return {
        publicKey,
        signature: bs58.encode(signature),
    };
};

export const connectWallet = async (accessToken: string) => {
    const provider = getProvider();
    if (provider) {
        try {
            const response = await provider.connect();
            if (response) {
                const signedMessage = await signMessage(accessToken, provider);
                return signedMessage;
            }
        } catch (error) {
            await window.solana?.disconnect();
            Toast.show({
                placement: "top",
                description:
                    error.code === 4001
                        ? translate("connection_skip_error")
                        : translate("try_connection_again"),
            });
            return null;
        }
    }
};

export const getProgressData = (profile: any) => {
    let percentage = 100;
    let title = "";
    if (!profile?.username) {
        percentage = percentage - 30;
        title = "Set your Username";
    }
    if (
        !profile?.image_with_url ||
        JSON.stringify(profile?.image_with_url) === "{}"
    ) {
        percentage = percentage - 30;
        title = "Update your profile picture";
    }
    if (
        !profile?.socials_with_url ||
        JSON.stringify(profile?.socials_with_url) === "{}"
    ) {
        percentage = percentage - 40;
        title = "Connect your social accounts";
    }

    if (percentage === 100) {
        title = "Congrats. Your profile is complete!";
    }

    return { title, percentage };
};

export const uniqueArray = (arr: any[]) => {
    var temp = {};

    arr.forEach((item) => {
        temp[item] = item;
    });

    return Object.keys(temp).map((item) => {
        return temp[item];
    });
};

export const getDefaultUnitsFromProduct = (product: any) => {

    // Change default FREE biddz value here
    if (product?.price_per_unit === 0) return product?.max_units_per_buyer

    let defaultUnits = Math.floor(10 / product?.price_per_unit)
    defaultUnits = defaultUnits - (defaultUnits % 5)

    if (product?.available_units && product?.available_units < defaultUnits) {
        defaultUnits = product?.available_units;
    }
    if (
        product?.min_units_per_order &&
        product?.min_units_per_order > defaultUnits &&
        product?.available_units >= product?.min_units_per_order
    ) {
        console.debug("👶 Setting Default Units to Min");
        defaultUnits = product?.min_units_per_order;
    } else if (
        product?.max_units_per_buyer &&
        product?.max_units_per_buyer < defaultUnits
    ) {
        defaultUnits = product?.max_units_per_buyer;
    }
    return isNaN(defaultUnits) ? 1 : defaultUnits
}

export const getAvailableUnitsListFromProduct = (product: any) => {
    const units = new Set()
    const min_mod = 5
    const mod = 25
    const targets = [10, 20, 30, 50, 100, product?.max_units_per_buyer ? product?.max_units_per_buyer * product?.price_per_unit : 200]
    targets.map((item) => {
        let target_rate = Math.floor(item / product?.price_per_unit)
        if (target_rate >= mod * 2) {
            const normalise_diff = target_rate % mod
            target_rate = target_rate - normalise_diff
        } else if (target_rate >= min_mod) {
            const normalise_diff = target_rate % min_mod
            target_rate = target_rate - normalise_diff
        }
        const lower_bound = Math.max(target_rate, product?.min_units_per_order)
        const upper_bound = product?.max_units_per_buyer
        const unit = Math.min(lower_bound, upper_bound)
        if (!isNaN(unit) && unit !== 0)
            units.add(unit)
    })
    return Array.from(units)
}

export const getBenefitIconFromTier = (tier: "gold" | "platinum" | "diamond") => {
    switch (tier) {
        case "gold": return require("assets/images/features/benefits/gold-benefits-icon.png")
        case "platinum": return require("assets/images/features/benefits/platinum-benefits-icon.png")
        case "diamond": return require("assets/images/features/benefits/diamond-benefits-icon.png")
        default: return require("assets/images/features/benefits/gold-benefits-icon.png")
    }
}

export const getBadgeColorFromType = (type: string) => {
    switch (type) {
        case "enthusiast": return "#05F0C6"
        case "fan": return "#05F0C6"
        case "super_fan": return "#05F0C6"
        // case "stalwart_supporter": return "#05F0C6"
        case "evergreen_listener": return "#3DE391"
        case "early_bird": return "#FFB2FF"
        case "song_hoarder": return "#F1533C"
        case "early_riser": return "#7091F5"
        case "modern_joe": return "#FFB2FF"
        case "night_owl": return "#6ADFEA"
        case "headphones": return "#FEDA00"
        default: return "#FF7B23"
    }
}

export const getRandomBadgeColor = () => {
    const colors = [
        "#05F0C6",
        "#3DE391",
        "#FFB2FF",
        "#F1533C",
        "#7091F5",
        "#6ADFEA",
        "#FEDA00",
        "#FF7B23",
        "#685DDC",
        "#289961"
    ];
    return colors[Math.floor(Math.random() * colors.length)];
}

export const getRandomBadgeBackground = (forceBackground: boolean = false) => {
    const backgrounds = [
        require("assets/images/features/badges/badge-background-1.png"),
        require("assets/images/features/badges/badge-background-2.png"),
        require("assets/images/features/badges/badge-background-3.png")

    ]
    if (forceBackground) return backgrounds[2]
    return backgrounds[Math.floor(Math.random() * backgrounds.length)];
}


export const getBenefitTitleParsed = (benefit: any) => {
    // if custom return title
    if (benefit?.type === "custom") return benefit?.title || `custom benefit`
    // if title is present return title else return default title
    return benefit?.title || translate(benefit?.type)
}

export const getBenefitDescriptionParsed = (benefit: any) => {
    // if custom return descripiton
    if (benefit?.type === "custom") return benefit?.description || `custom benefit`
    // if description is present return description else return default description
    return benefit?.description || translate(`${benefit?.type}_desc`)
}

export const getFlagFromCountryCode = (country_code: string) => {
    switch (country_code) {
        case "49": return "🇩🇪"
        case "43": return "🇦🇹"
        case "41": return "🇨🇭"
        case "91": return "🇮🇳"
        case "1": return "🇺🇸"
        case "44": return "🇬🇧"
        default: return "🌐"
    }
}

export const getRankImage = (index: number) => {
    switch (index) {
        case 1:
            return require("assets/images/features/poly.png");
        case 2:
            return require("assets/images/features/poly_silver.png");
        case 3:
            return require("assets/images/features/poly_brown.png");
        default:
            return "";
    }
};

export const getStateFromLikes = (reactions: number, liked: boolean) => {
    return reactions > 1 && liked
        ? translate("liked_by_you_and_others", { reactions: reactions })
        : reactions === 1 && liked
            ? translate("liked_by_you_and_someone")
            : reactions > 1
                ? translate("liked_by_others", { reactions: reactions })
                : reactions === 1
                    ? translate("liked_by_someone")
                    : liked && translate("liked_by_you");
};

export const getPostDetailsFromType = (type: string) => {
    // Use expo icon Entypo only
    switch (type) {
        case "poll":
            return { title: translate("fan_voting_button"), icon: "inbox" };
        case "spotify":
            return {
                title: translate("spotify_playlist_text_button"),
                icon: "spotify",
            };
        case "coupon":
            return { title: translate("coupon_code_button"), icon: "price-tag" };
        default:
            return { title: translate("exclusive_content_button"), icon: "star" };
    }
};

export const getDynamicPostWidth = () => {
    const windowWidth = getWindowWidth();
    const widthRatioSm = windowWidth / 1.1;
    const widthRatioMd = windowWidth / 1.4;
    const widthRatioLg = windowWidth / 1.8;
    return [widthRatioSm, widthRatioMd, widthRatioLg];
};

export const getAuctionListFilterTitle = (filter: string) => {
    switch (filter) {
        case "all": return translate("all")
        case "is_active": return translate("active")
        case "is_upcoming": return translate("upcoming")
        case "is_over": return translate("past")
    }

}

export const getFastGasPrice = async () => {
    console.debug(
        "⛽️ Requesting gas price from:",
        Constants.expoConfig.extra.GAS_STATION_URL
    );
    const resp = await fetch(Constants.expoConfig.extra.GAS_STATION_URL);
    const gasPrices = await resp?.json();
    console.debug("⛽️ Current gas prices:", gasPrices, "gwei");
    const gasPrice = gasPrices?.fast;
    console.debug("⛽️ Current fast gas price:", gasPrice, "gwei");
    return gasPrice;
};

export const isWeb = Platform.OS === 'web'
export const isBasicCollectible = (type: string | undefined) => type === "single_basic";

export const hexToRGB = (hex: string, alpha: number) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const getMoreText = (arr: Array<string>, maxLength = 11, showOne: boolean = false) => {
    let toShow = "";
    let moreCount = 0;
    let prefix = "";

    if (arr?.length === 1) {
        return { text: arr[0], moreCount: moreCount };
    } else if (arr?.length > 0) {
        for (let i = 0; i < arr.length; i++) {
            if ((toShow + arr[i]).length < maxLength) {
                if (i > 0) {
                    prefix = ", "
                    if (showOne) {
                        moreCount++;
                        continue
                    }
                }
                toShow += prefix + arr[i];
            } else {
                moreCount++;
            }
        }

        if (toShow.length === 0) {
            toShow = arr[0].slice(0, maxLength) + "..";
            moreCount--;
        }

        return { text: toShow, moreCount: moreCount };
    }

    return null;
};
