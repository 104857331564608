import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, HStack, Text, Icon, Pressable, Box, Image } from "native-base";

const AlertButton = ({
  onPress,
  text,
  width = null,
  disabled = false,
  showLogo = false,
  fontSize = 20,
}) => (
  <Pressable onPress={onPress} width={width} disabled={disabled}>
    <Box bg="secondary.600" alignItems="center" borderRadius={"xl"} padding={3}>
      <HStack alignItems="center" space={2}>
        {showLogo && (
          <Image
            width={6}
            height={6}
            alt="Biddz Logo"
            resizeMode="contain"
            source={require("assets/images/biddz-theme/biddz-logo-white.png")}
          />
        )}
        <Text bold fontSize={fontSize} color="dark.900">
          {text}
        </Text>
      </HStack>
    </Box>
  </Pressable>
);

export const Alert = ({
  text,
  title,
  buttonText = null,
  buttonOnPress = null,
  p = 5,
}) => (
  <VStack
    space={3}
    _light={{ bg: "light.100" }}
    _dark={{ bg: "dark.900" }}
    p={p}
    borderRadius={"xl"}
    width="100%"
  >
    {title && (
      <HStack alignItems={"center"} space={2}>
        <Icon
          size={"sm"}
          color="secondary.600"
          name="information-outline"
          as={MaterialCommunityIcons}
        />
        <Text fontWeight={"bold"} fontSize={["md", "lg"]}>
          {title}
        </Text>
      </HStack>
    )}

    <Text fontSize={["md", "lg"]}>{text}</Text>
    {(buttonText || buttonOnPress) && (
      <AlertButton
        text={buttonText}
        onPress={buttonOnPress}
        fontSize={["sm", "md"]}
      />
    )}
  </VStack>
);
