import React, { useState } from "react";
import { ScrollView, VStack, Stack, Hidden, Box } from "native-base";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
// utility functions
import { navigateBack, translate } from "utils/helpers";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { Loading } from "common/components/loading/loading.component";
import { AuctionInfoPerformance } from "features/general/auctions/components/auction-info-performance.component";
import { AuctionBenefitsList } from "features/general/auctions/components/auction-benefits-list.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { TicketProductDetailHeader } from "features/tickets/ticket-products/components/ticket-product-detail-header.component";
import { TicketCollectibleDetailEvent } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-event.component";
import { TicketCollectibleDetailCommunity } from "features/tickets/ticket-collectibles/components/ticket-collectible-detail-community.component";

export const AuctionInfoScreen = ({ navigation, route }) => {
  const { id: auctionID, initialData = {} } = route.params;
  const { data: auction, isFetched } = useAuction(auctionID, initialData);

  const [activeTab, setActiveTab] = useState(0);
  const isTicket = ["single_ticket", "multi_ticket"].includes(auction?.type);

  return (
    <ScreenFrame safeAreaBottom={0}>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      {!isFetched ? (
        <Loading />
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          {isTicket && (
            <VStack px={3} space={10}>
              <Stack
                space={10}
                px={{ lg: 5 }}
                alignItems={{ lg: "center" }}
                justifyContent={"space-between"}
                direction={{ base: "column", lg: "row-reverse" }}
              >
                <AuctionDetailImage
                  url={
                    auction?.products[0]?.picasso_generated_images?.wrapped
                      ?.image_with_url?.lg?.url
                  }
                  justifyContent={{ base: "center", lg: "flex-end" }}
                />
                <VStack flex={{ lg: 1 }} space={{ lg: 5 }}>
                  <Hidden till={"lg"}>
                    <TicketProductDetailHeader
                      isDardan={false}
                      auction={auction}
                      event={
                        auction?.events?.length > 0
                          ? auction?.events[0]
                          : undefined
                      }
                    />
                  </Hidden>
                </VStack>
              </Stack>
              <TabNavigator
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                options={["Event", "Community"]}
              />
              {activeTab === 0 && (
                <TicketCollectibleDetailEvent
                  isDardan={false}
                  auction={auction}
                  event={
                    auction?.events?.length > 0 ? auction?.events[0] : undefined
                  }
                />
              )}
              {activeTab === 1 && (
                <TicketCollectibleDetailCommunity auctionID={auction?.id} />
              )}
            </VStack>
          )}
          {auction?.type === "single_license" && (
            <VStack mt={10} space={10}>
              <Box mx={5}>
                <TabNavigator
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  options={[
                    translate("performance"),
                    translate("fan_benefits"),
                  ]}
                />
              </Box>
              {activeTab === 0 ? (
                <AuctionInfoPerformance auction={auction} />
              ) : (
                <AuctionBenefitsList auction={auction} />
              )}
            </VStack>
          )}
        </ScrollView>
      )}
    </ScreenFrame>
  );
};
