import React from "react";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { PaymentMethodListScreen } from "features/general/payment-methods/screens/payment-method-list.screen";
import { PaymentMethodPayoutsScreen } from "features/general/payment-methods/screens/payment-method-payouts.screen";
import { PaymentMethodSetupIntentCardScreen } from "features/general/payment-methods/screens/payment-method-setup-card.screen";
import { PaymentMethodSetupIntentSofortScreen } from "features/general/payment-methods/screens/payment-method-setup-intent-sofort.screen";

const Stack = createStackNavigator();

export const PaymentMethodsNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="PaymentMethodList"
        component={PaymentMethodListScreen}
      />
      <Stack.Screen
        name="PaymentMethodPayouts"
        component={PaymentMethodPayoutsScreen}
      />
      <Stack.Screen
        name="PaymentMethodSetupIntentSofort"
        options={{
          ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
        component={PaymentMethodSetupIntentSofortScreen}
      />
      <Stack.Screen
        name="PaymentMethodSetupIntentCard"
        options={{
          ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
        component={PaymentMethodSetupIntentCardScreen}
      />
    </Stack.Navigator>
  );
};
