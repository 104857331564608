import React, { useState } from "react";
import {
  Box,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
// internal
import { useAuction } from "features/general/auctions/hooks/useAuction";
import { useProduct } from "features/general/products/hooks/useProduct";
import {
  getBenefitDescriptionParsed,
  getBenefitIconFromTier,
  getBenefitTitleParsed,
  translate,
} from "utils/helpers";
import {
  ClaimLicenseBenefitModal,
  IgnoreLicenseBenefitModal,
} from "features/royalties/license-benefits/components/license-benefit-modal.component.js";
import { CopyTextButton } from "theme/buttons/copy-text-button.component.js";
import { AuctionMusiciansList } from "features/general/auctions/components/auction-musicians-list.component";

export const LicenseCollectibleBenefitListCard = ({ benefit, benefitType }) => {
  const product = useProduct(benefit?.benefit?.product_id);
  const auction = useAuction(product?.data?.auction_id);

  const [openClaim, setOpenClaim] = useState(false);
  const [openIgnore, setOpenIgnore] = useState(false);

  return (
    <>
      <VStack
        p={5}
        mx={1}
        my={3}
        flex={1}
        space={4}
        shadow={2}
        maxW={500}
        rounded={"xl"}
        bg={"light.50"}
      >
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <HStack space={2} alignItems={"center"}>
            <Image
              size={8}
              alt={"tier"}
              source={getBenefitIconFromTier(benefit?.tier)}
            />
            <Text fontWeight={600} textTransform={"capitalize"} fontSize={"md"}>
              {benefit?.tier}
            </Text>
          </HStack>
          <Pressable onPress={() => setOpenClaim(true)}>
            <Text fontSize={"xs"} color={"gray.500"}>
              {translate("view_details")}
            </Text>
          </Pressable>
        </HStack>
        <HStack space={4} alignItems={"center"}>
          <Box
            w={24}
            h={32}
            rounded={"xl"}
            bg={"gray.100"}
            justifyContent={"center"}
          >
            <Image
              w={100}
              h={120}
              alt={"collectible"}
              resizeMode={"contain"}
              source={{ uri: benefit?.collectible?.image_with_url?.md?.url }}
            />
          </Box>
          <VStack flex={1} space={2} justifyContent={"flex-end"}>
            <Text fontWeight={600} textTransform={"capitalize"}>
              {getBenefitTitleParsed(benefit?.benefit)}
            </Text>
            <Text color={"gray.500"} noOfLines={2} isTruncated>
              {getBenefitDescriptionParsed(benefit?.benefit)}
            </Text>
            {benefit?.claimed_at ? (
              benefit?.type === "bonus_content" ? (
                <VStack space={2}>
                  <Text
                    p={2}
                    isTruncated
                    noOfLines={1}
                    rounded={"xl"}
                    borderWidth={2}
                    borderColor={"gray.100"}
                    textTransform={"uppercase"}
                  >
                    {benefit?.benefit?.artist_input}
                  </Text>
                  <CopyTextButton
                    borderRadius={"full"}
                    text={benefit?.benefit?.artist_input}
                  />
                </VStack>
              ) : (
                <Box
                  p={2}
                  bg={"gray.100"}
                  rounded={"full"}
                  alignItems={"center"}
                >
                  <Text fontWeight={500} color={"gray.500"} selectable={false}>
                    {translate("benefit_claimed")}
                  </Text>
                </Box>
              )
            ) : (
              <Stack space={2} direction={{ base: "column", lg: "row" }}>
                <Pressable
                  p={2}
                  flex={1}
                  rounded={"full"}
                  bg={"secondary.300"}
                  alignItems={"center"}
                  onPress={() => setOpenClaim(true)}
                >
                  <Text fontWeight={500}>{translate("redeem")}</Text>
                </Pressable>
                <Pressable
                  p={2}
                  flex={1}
                  bg={"gray.100"}
                  rounded={"full"}
                  alignItems={"center"}
                  onPress={() => setOpenIgnore(true)}
                >
                  <Text fontWeight={500}>{translate("no_thanks")}</Text>
                </Pressable>
              </Stack>
            )}
          </VStack>
        </HStack>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          {auction?.data?.musicians && (
            <ScrollView
              maxW={40}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              <HStack alignItems={"center"} space={1}>
                <Text color={"gray.500"} fontSize={"xs"} fontWeight={500}>
                  {`${translate("from_von")}: `}
                </Text>
                <AuctionMusiciansList
                  size={6}
                  fontColor={"gray.500"}
                  showsContainer={false}
                  musicians={auction?.data?.musicians}
                />
              </HStack>
            </ScrollView>
          )}
          {benefit?.benefit?.expiry_date && (
            <Text color={"gray.500"}>
              {`${translate("expires_in")}: ${benefit?.benefit?.expiry_date}`}
            </Text>
          )}
        </HStack>
      </VStack>
      <ClaimLicenseBenefitModal
        benefit={benefit}
        isOpen={openClaim}
        setIsOpen={setOpenClaim}
        benefitType={benefitType}
      />
      <IgnoreLicenseBenefitModal
        benefit={benefit}
        isOpen={openIgnore}
        setIsOpen={setOpenIgnore}
      />
    </>
  );
};
