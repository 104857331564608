import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useSearchSpotifyMusicians = (
  alias,
  enabled = false,
  limit = 10
) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `spotify-musicians-${alias}`,
    () =>
      client
        .get(`musicians/search/`, {
          params: {
            alias,
            store: "spotify",
            limit: limit,
          },
        })
        .then((resp) => {
          return resp.data;
        }),
    { enabled: enabled && !!alias, staleTime: 1000 }
  );
};
