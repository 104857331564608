import React, { useContext, useState, useRef } from "react";
import { Platform } from "react-native";
import {
  Box,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
  KeyboardAvoidingView,
  Icon,
  useMediaQuery,
} from "native-base";
import { useForm } from "react-hook-form";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
// internal
import { getFlagFromCountryCode, translate, isWeb } from "utils/helpers";
import { FirebaseContext } from "services/firebase.context";
import { AuthenticationContext } from "services/authentication.context";
import { Loading } from "common/components/loading/loading.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { CodeVerificationModal } from "features/accounts/users/components/code-verification-modal.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";

export const LoginScreen = ({ navigation }) => {
  const {
    isLoading,
    isUserLoading,
    onFirebaseLoginOrRegister,
    onNativeLoginOrRegister,
  } = useContext(AuthenticationContext);
  const { app } = useContext(FirebaseContext);

  const { control, handleSubmit } = useForm();
  const recaptchaVerifier = useRef(null);
  const [countryCode, setCountryCode] = useState("49");
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const onLogin = (data, type) => {
    isWeb
      ? onFirebaseLoginOrRegister(
        "+" + data.country_code + data.phone_number,
        type,
        navigation,
        false
      )
      : onNativeLoginOrRegister(
        "+" + data.country_code + data.phone_number,
        type,
        navigation,
        recaptchaVerifier
      );
  };

  return (
    <ScreenFrame>
      <NavigationPanel />
      <VStack
        flex={1}
        alignItems={"center"}
        px={isDesktop ? 24 : 0}
        justifyContent={isDesktop ? "center" : "space-between"}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <VStack p={5} space={3} maxW={375}>
            <HStack space={3} alignItems={"center"}>
              <Icon
                size={8}
                as={MaterialCommunityIcons}
                name={"account-arrow-right-outline"}
              />
              <Text fontWeight={700} fontSize={"2xl"}>
                {translate("sign_in_register")}
              </Text>
            </HStack>
            <Text color={"gray.400"} fontSize={"sm"}>
              {translate("login_subtitle")}
            </Text>
            <HStack space={1} width={"100%"}>
              <Box flex={2} maxW={32}>
                <FormControlInput
                  height={10}
                  size={"lg"}
                  value={"49"}
                  control={control}
                  placeholder={"49"}
                  name={"country_code"}
                  keyboardType={"phone-pad"}
                  onChangeText={(text) => setCountryCode(text)}
                  rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                  }}
                  InputLeftElement={
                    <Text pl={2} fontSize={["md", "lg"]}>
                      {`${getFlagFromCountryCode(countryCode)}  +`}
                    </Text>
                  }
                />
              </Box>
              <Box flex={4} maxW={64}>
                <FormControlInput
                  height={10}
                  size={"lg"}
                  //autoFocus={true}
                  name={"phone_number"}
                  keyboardType={"phone-pad"}
                  placeholder={"176 456 87 910"}
                  control={control}
                  rules={{
                    required: true,
                    minLength: 9,
                  }}
                />
              </Box>
            </HStack>

            {isWeb && (
              <HStack justifyContent={"center"}>
                <div id={"recaptcha-container"}></div>
              </HStack>
            )}
            {!isWeb && (
              <FirebaseRecaptchaVerifierModal
                ref={recaptchaVerifier}
                firebaseConfig={app.options}
                attemptInvisibleVerification={true}
              />
            )}
          </VStack>
        </KeyboardAvoidingView>
        <CodeVerificationModal />
        <VStack
          m={4}
          mt={12}
          space={4}
          alignItems={isDesktop ? "flex-start" : "center"}
        >
          <LoginButton
            bg={"darkBackground"}
            fontColor={"lightText"}
            text={translate("sign_in")}
            isLoading={isLoading || isUserLoading}
            onPress={handleSubmit((data) => onLogin(data, "fan"))}
            icon={
              <Image
                size={4}
                alt={"Button Logo"}
                resizeMode={"contain"}
                source={require("assets/images/biddz-theme/biddz-logo-white.png")}
              />
            }
          />
          <LoginButton
            bg={"lightBackground"}
            fontColor={"darkText"}
            isLoading={isLoading || isUserLoading}
            text={translate("continue_as_artist")}
            onPress={handleSubmit((data) => onLogin(data, "artist"))}
            icon={
              <Image
                size={4}
                alt={"Button Logo"}
                resizeMode={"contain"}
                source={require("assets/images/biddz-theme/biddz-logo-black.png")}
              />
            }
          />
        </VStack>
      </VStack>
    </ScreenFrame>
  );
};

const LoginButton = ({ text, bg, icon, fontColor, isLoading, onPress }) => (
  <Pressable
    p={3}
    w={350}
    bg={bg}
    rounded={"xl"}
    borderWidth={1}
    onPress={onPress}
    disabled={isLoading}
    alignItems={"center"}
    borderColor={"darkText"}
  >
    {isLoading ? (
      <Loading maxHeight={25} pt={0} />
    ) : (
      <HStack space={2} alignItems={"center"}>
        {icon}
        <Text color={fontColor} fontSize={"md"} bold>
          {text}
        </Text>
      </HStack>
    )}
  </Pressable>
);
