import React, { useState } from "react";
import {
  ScrollView,
  VStack,
  HStack,
  Text,
  AlertDialog,
  Icon,
  Pressable,
} from "native-base";
import i18n from "i18n-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { isoCountry } from "iso-country";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
// utility functions
import { translate, navigateBack, isWeb } from "src/utils/helpers";

const languages = { en: "English", de: "German" };
const countries = { en: "gbr", de: "de" };

const Item = ({ title, onSave }) => {
  const { emoji } = isoCountry(countries[title]);
  return (
    <Pressable onPress={onSave}>
      <HStack
        p="3.5"
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={1}
        borderBottomColor="dark.500"
      >
        <HStack space="3" alignItems="center">
          <Text fontSize={["xl", "2xl"]}>{emoji}</Text>
          <Text bold fontSize={["md", "lg", "xl"]}>
            {languages[title]}
          </Text>
        </HStack>
        {i18n.locale === title && (
          <Icon as={MaterialCommunityIcons} name="check" size="lg" />
        )}
      </HStack>
    </Pressable>
  );
};

export const LanguageSelectionScreen = ({ navigation }) => {
  const [alert, setAlert] = useState({ show: false, title: "", message: "" });

  const onSave = async (value) => {
    await AsyncStorage.setItem("@language", value);
    setAlert({
      show: true,
      message: translate("request_to_restart_native_app"),
    });
  };

  return (
    <ScreenFrame>
      <NavigationHeader
        title={translate("language")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        p="5"
        flex={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <AlertDialog isOpen={alert.show} maxW={300} alignSelf="center">
          <AlertDialog.Content>
            <AlertDialog.Header>{alert.title}</AlertDialog.Header>
            <AlertDialog.Body>{alert.message}</AlertDialog.Body>
          </AlertDialog.Content>
        </AlertDialog>

        <VStack space="6" pb="10">
          <VStack space="4">
            {["en", "de"].map((lang) => (
              <Item key={lang} title={lang} onSave={() => onSave(lang)} />
            ))}
          </VStack>
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
