import React, { useEffect } from "react";
import { ScrollView, useColorMode, useTheme } from "native-base";
import { useSafeAreaInsets } from "react-native-safe-area-context";
// internal hooks
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { ReleaseList } from "features/royalties/releases/components/release-list.component";
import { MusicianCompleteApplication } from "features/accounts/musicians/components/musician-complete-application.component";
import { Loading } from "common/components/loading/loading.component";
import useCreateMusician from "features/accounts/musicians/hooks/useCreateMusician";

export const ReleaseListScreen = ({ navigation, tabNavigation }) => {
  const insets = useSafeAreaInsets();
  const { isFetching, data: musician } = useUserMusician();
  const { createMusician } = useCreateMusician();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();
  const lightMode = colorMode === "light";

  const navigateToEditPersonalData = () => {
    navigation.navigate("Musicians", {
      screen: "MusicianEditPersonalData",
    });
  };

  const musicianStatus = musician?.status;

  useEffect(() => {
    if (!isFetching) {
      if (
        !musician ||
        !["verified", "verified_restricted", "under_review"].includes(
          musicianStatus
        )
      ) {
        tabNavigation.setOptions({ tabBarStyle: { display: "none" } });
      } else {
        tabNavigation.setOptions({
          tabBarStyle: {
            display: "flex",
            paddingTop: insets.top,
            backgroundColor: lightMode
              ? colors["light"]["200"]
              : colors["dark"]["900"],
          },
        });
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (createMusician.isSuccess) {
      navigateToEditPersonalData();
    }
  }, [createMusician.isSuccess]);

  return (
    <ScreenFrame safeAreaTopWithTopBar={false} isAuthenticationRequired>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {isFetching ? (
          <Loading />
        ) : ["verified", "verified_restricted", "under_review"].includes(
            musicianStatus
          ) ? (
          <ReleaseList />
        ) : (
          <MusicianCompleteApplication
            musician={musician}
            navigateToEditPersonalData={navigateToEditPersonalData}
            createMusician={() => createMusician.mutate()}
          />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
