import React, { useState, useRef } from "react";
import { Text, VStack, HStack, Box, Progress, ScrollView } from "native-base";
import { PieChart } from "react-native-svg-charts";
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { Swiper } from "common/components/layout/swiper.component";
import { translate, getRandomColorArray } from "utils/helpers";

const getTotal = (data) => data.reduce((acc, curr) => acc + curr.count, 0);

const getPercentage = (value, total) => Math.round((value / total) * 100);

const Slide = ({ isFirstSlide = false, data }) => {
  const colors = getRandomColorArray(data.length);
  return (
    <ScrollView
      py="3"
      mb="6"
      nestedScrollEnabled
      borderRadius="xl"
      showsVerticalScrollIndicator={false}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      {data.length === 0 ? (
        <Text mt="20" textAlign="center">
          {translate("not_available")}
        </Text>
      ) : isFirstSlide ? (
        <VStack flex={1} space={2} justifyContent="center">
          {data.map(({ value, count }, i) => (
            <VStack key={i} space={2}>
              <HStack justifyContent="space-between">
                <Text>{value}</Text>
                <Text>{getPercentage(count, getTotal(data))}%</Text>
              </HStack>
              <Box w="100%">
                <Progress
                  rounded="0"
                  size="lg"
                  _filledTrack={{ rounded: 0 }}
                  value={getPercentage(count, getTotal(data))}
                />
              </Box>
            </VStack>
          ))}
        </VStack>
      ) : (
        <VStack
          space="3"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <VStack space="2" justifyContent="center">
            {data.map(({ count, value }, i) => (
              <HStack key={i} space="2" alignItems="center">
                <Box h="3" w="3" borderRadius="6" bg={colors[i]} />
                <Text>{`${getPercentage(count, getTotal(data))}% ${translate(
                  value
                )}`}</Text>
              </HStack>
            ))}
          </VStack>
          <PieChart
            style={{ height: 200, width: 200 }}
            valueAccessor={({ item }) => item.count}
            data={data.map((item, i) => ({
              key: i,
              ...item,
              svg: { fill: colors[i] },
            }))}
            innerRadius="85%"
            padAngle={0}
          />
        </VStack>
      )}
    </ScrollView>
  );
};

export const ListenersStatsSwiper = ({ analytics }) => {
  const { age, gender, device, subscription } = analytics;
  const title = [
    "listeners_age",
    "listeners_gender",
    "listeners_device",
    "listeners_subscription",
  ];
  const type = ["age", "gender", "device", "subscription"];

  const swiper = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <VStack
      px="5"
      pt="5"
      space="4"
      minH={550}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      borderRadius="xl"
      borderWidth={1}
      borderColor="dark.800"
    >
      <VStack>
        <Text fontSize="2xl" bold>
          {translate(title[activeTab])}
        </Text>
        <Text fontSize={["md", "lg"]} color="primary.600">
          {translate("last_days", { days: 30 })}
        </Text>
      </VStack>
      <TabNavigator
        activeTab={activeTab}
        setActiveTab={(idx) => {
          swiper.current?.goTo(idx);
          setActiveTab(idx);
        }}
        options={type.map((t) => translate(t))}
      />
      <Swiper ref={swiper} onIndexChanged={(idx) => setActiveTab(idx)}>
        <Slide isFirstSlide data={age} />
        <Slide data={gender} />
        <Slide data={device} />
        <Slide data={subscription} />
      </Swiper>
    </VStack>
  );
};
