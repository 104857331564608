import React, { useState } from "react";
import { VStack, Text, Box } from "native-base";
// internal functions + hooks
import { translate } from "utils/helpers";
import { useMusicians } from "features/accounts/musicians/hooks/useMusicians";
// internal compontents
import { MusicianListAnimated } from "features/accounts/musicians/components/musician-list-animated.component";

export const CollectibleListImagesEmpty = () => {
  const musicians = useMusicians(10);

  const [index, setIndex] = useState(0);

  return (
    <VStack flex={1} alignItems={"center"} space={[90, 120]} pb={10}>
      <Box _web={{ left: { base: "-30%", lg: "-33%" } }}>
        <MusicianListAnimated
          musicians={musicians?.data?.pages.map((page) => page.items).flat()}
          index={index}
          setIndex={setIndex}
        />
      </Box>

      <VStack alignItems={"center"} space={2}>
        <Text textAlign="center" fontSize={["xl", "2xl"]} fontWeight="bold">
          {musicians?.data?.pages.map((page) => page.items).flat()[index].alias}
        </Text>
        <Text fontSize={["md", "xl"]}>{translate("next_drop_text")}</Text>
      </VStack>
    </VStack>
  );
};
