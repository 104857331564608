import React from "react";
import { Box, useColorMode } from "native-base";
import { ImageBackground } from "react-native";

export const ProfileDetailHeaderBackgroundImage = ({ url }) => {
  const { colorMode } = useColorMode();
  return (
    <Box overflow={"hidden"} height={[160, 210, 280]}>
      <ImageBackground
        alt="cover-image"
        source={
          url
            ? { uri: url }
            : colorMode === "light"
            ? require("assets/images/features/profile-banner-white.png")
            : require("assets/images/features/profile-banner-black.png")
        }
        style={{
          height: "100%",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
        }}
        resizeMode="cover"
      />
    </Box>
  );
};
