import React, { useState } from "react";
import {
  HStack,
  VStack,
  Icon,
  Text,
  Avatar,
  Pressable,
  Box,
} from "native-base";
import { Feather, AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// internal components
import { MusicianListModal } from "features/accounts/musicians/components/musician-list-modal.component";
// helpers
import { translate } from "utils/helpers";

export const TicketProductDetailLineup = ({ musicians }) => {
  const navigation = useNavigation();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const goToMusician = (m) => {
    setShowModal(false);
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: { id: m?.id, initialData: m },
    });
  };

  return (
    <>
      <Pressable
        onPress={
          musicians.length > 1
            ? toggleModal
            : () => goToMusician(musicians?.[0])
        }
      >
        <HStack
          h={24}
          px={5}
          space={5}
          borderWidth={1}
          borderRadius={"lg"}
          alignItems={"center"}
          borderColor={"gray.200"}
          justifyContent={"space-between"}
        >
          <HStack flex={1} alignItems={"center"} space={5}>
            <Icon
              size={6}
              as={Feather}
              name={musicians?.length > 1 ? "users" : "user"}
            />
            <VStack flex={1} space={1}>
              <Text fontSize={["lg", "xl"]} fontWeight={500}>
                {translate("event_lineup")}
              </Text>
              {musicians?.length > 1 ? (
                <Text
                  numberOfLines={1}
                  color={"light.800"}
                  fontSize={["md", "lg"]}
                >
                  {musicians?.map((m) => m.alias)?.join(", ")}
                </Text>
              ) : (
                <HStack space={2} alignItems={"center"}>
                  <Avatar
                    size={"sm"}
                    bg={"green.500"}
                    source={{ uri: musicians?.[0]?.image_with_url?.xs?.url }}
                  >
                    {musicians?.[0]?.alias}
                  </Avatar>
                  <Text
                    numberOfLines={1}
                    color={"light.800"}
                    fontSize={["md", "lg"]}
                  >
                    {musicians?.map((m) => m.alias)?.join(", ")}
                  </Text>
                </HStack>
              )}
            </VStack>
          </HStack>

          {musicians?.length > 1 ? (
            <Avatar.Group _avatar={{ size: "md" }} max={3}>
              {musicians?.map((m) => (
                <Avatar
                  key={m.alias}
                  bg={"green.500"}
                  source={{ uri: m?.image_with_url?.xs?.url }}
                >
                  {m.alias}
                </Avatar>
              ))}
            </Avatar.Group>
          ) : (
            <Box
              p={2}
              borderWidth={1}
              borderRadius={"full"}
              borderColor={"light.700"}
            >
              <Icon size={5} as={AntDesign} name={"arrowright"} />
            </Box>
          )}
        </HStack>
      </Pressable>

      <MusicianListModal
        title={translate("event_lineup")}
        showModal={showModal}
        toggleModal={toggleModal}
        musicians={musicians}
      />
    </>
  );
};
