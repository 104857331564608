import React, { useState } from "react";
import { Box, Text, VStack } from "native-base";
import { Modal } from "theme/feedback/modal.component";
import Constants from "expo-constants";
// internal
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
// internal hooks
import { useDeleteUser } from "src/features/accounts/users/hooks/useDeleteUser";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NewButtonBody } from "theme/buttons/new-button-body.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { Link } from "theme/typography/link.component";

export const DeleteAccountButton = () => {
  const toggleModal = () => setShowModal(!showModal);
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const { onLogout } = React.useContext(AuthenticationContext);
  const { deleteUser } = useDeleteUser();

  const onDeleteUser = async () => {
    await deleteUser.mutateAsync();
    onLogout();
  };

  return (
    <Modal
      pressableChildren={
        <NewButtonBody
          textStyles={{
            fontSize: ["md", "lg", "xl"],
            fontWeight: "semibold",
            color: "danger.300",
          }}
          iconName={"delete"}
          iconStyles={{ color: "danger.300" }}
          text={translate("delete_account")}
        />
      }
      modalChildren={
        <Box p={5} pt={12} mx={2} bg={"light.100"} borderRadius={"xl"}>
          {confirm ? (
            <VStack space={6}>
              <Text bold fontSize={["md", "lg"]} numberOfLines={2}>
                {translate("delete_account_alert_title")}
              </Text>
              <Text fontSize={["sm", "md"]}>
                {translate("delete_account_confirm")}
              </Text>
              <PrimaryButton
                onPress={onDeleteUser}
                textSize={["lg", "xl"]}
                text={translate("confirm")}
                nativeID={"delete-account-confirm"}
              />
            </VStack>
          ) : (
            <VStack space={6}>
              <Text bold fontSize={["md", "lg"]} numberOfLines={2}>
                {translate("delete_account_alert_title")}
              </Text>
              <Text fontSize={["sm", "md"]}>
                {translate("delete_account_alert_body")}
              </Text>
              <Link
                text={Constants.expoConfig.extra.CLAIM_WALLET_LINK}
                url={Constants.expoConfig.extra.CLAIM_WALLET_LINK}
              />
              <VStack mx={5} space={2}>
                <SecondaryButton
                  textSize={["lg", "xl"]}
                  text={translate("delete")}
                  nativeID={"delete-account"}
                  onPress={() => setConfirm(true)}
                />
                <PrimaryButton
                  onPress={toggleModal}
                  textSize={["lg", "xl"]}
                  text={translate("abort")}
                  nativeID={"abort-delete-account"}
                />
              </VStack>
            </VStack>
          )}
        </Box>
      }
      modalStyles={{
        bg: "light.100",
        alignItems: "center",
      }}
      onPress={toggleModal}
      onClose={toggleModal}
      isOpen={showModal}
    />
  );
};
