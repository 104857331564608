import React from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack, HStack, Divider, Text, Box } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal hooks
import useRequestOffer from "features/general/auctions/hooks/useRequestOffer";
import { useGenres } from "features/royalties/releases/hooks/useGenres";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const MainInfoDetail = ({ title, subtitle, ...props }) => (
  <VStack
    flex={1}
    space="2"
    alignItems="center"
    justifyContent="space-between"
    {...props}
  >
    <Text fontSize={["xl", "2xl"]} bold>
      {title}
    </Text>
    <Text fontSize={["sm", "md"]} textAlign="center">
      {subtitle}
    </Text>
  </VStack>
);

export const AuctionCalculatorScreen = ({ navigation }) => {
  const { getEstimation } = useRequestOffer();
  const { control, handleSubmit } = useForm();

  const onPress = (data) => {
    getEstimation.mutate({ ...data });
  };

  return (
    <ScreenFrame safeAreaBottom={true} isAuthenticationRequired>
      <NavigationHeader
        headerLeft={{
          iconName: "close",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        h={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <VStack flex={1} mt="4" _ios={{ mb: 8 }} space="10">
          <VStack flex={1}>
            <PageTitle
              title={translate("deal_calculator")}
              helpText={translate("deal_calculator_help_text")}
            />
            <Text fontSize="md" textAlign="center">
              {translate("input_to_see_estimation")}
            </Text>
          </VStack>
          <VStack
            w="100%"
            py="6"
            px="10"
            space="6"
            _light={{ bg: "light.100" }}
            _dark={{ bg: "dark.900" }}
          >
            <FormControlSelect
              useValues
              control={control}
              name="genre"
              label={translate("genre")}
              infoText={translate("genre_helper_text")}
              rules={{ required: true }}
              placeholder={translate("genre")}
              query={useGenres()}
            />
            <FormControlInput
              control={control}
              keyboardType="numeric"
              name="streams"
              label={translate("expected_streams")}
              infoText={translate("expected_streams_helper_text")}
              rules={{ required: true }}
              placeholder={"0"}
            />
            <FormControlInput
              control={control}
              name="units"
              keyboardType="numeric"
              label={translate("share_offered")}
              infoText={translate("giveaway_share_info_text")}
              helperText={translate("giveaway_share_helper_text")}
              rules={{ required: true, min: 500, max: 8000, pattern: /^\d+$/ }}
              placeholder={"500 - 8000"}
            />
          </VStack>
          <VStack p="5" space="4">
            <HStack justifyContent="flex-end">
              <IconModal
                icon={<AntDesign name="questioncircleo" />}
                contentText={translate("deal_calculator_estimates_help_text")}
              />
            </HStack>
            <HStack
              p="5"
              space="3"
              alignItems="center"
              justifyContent="space-evenly"
              borderRadius={15}
              overflow="hidden"
              borderColor="white"
              borderWidth="1"
            >
              <MainInfoDetail
                title={`${getEstimation.data?.expected_artist_advance || 0} €`}
                subtitle={translate("advance")}
              />
              <Divider orientation="vertical" bg="white" />
              <MainInfoDetail
                title={`${
                  getEstimation.data?.expected_artist_royalties || 0
                } €`}
                subtitle={translate("revenue_over_time")}
                flex={1.5}
              />
              <Divider orientation="vertical" bg="white" />
              <MainInfoDetail
                title={`${getEstimation.data?.price_per_unit || 0} €`}
                subtitle={translate("price_per_biddz")}
              />
            </HStack>
          </VStack>
          <Box flex={1} mx="5">
            <PrimaryButton
              nativeID="calculate-deal-estimates"
              text={translate("calculate")}
              onPress={handleSubmit(onPress)}
            />
          </Box>
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
