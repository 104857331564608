import React, { useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { ScrollView, VStack, HStack, Text, Box } from "native-base";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateTrack from "features/royalties/tracks/hooks/useUpdateTrack";
// internal components
import { UploadAudio } from "features/royalties/tracks/components/upload-audio.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { FormControlTextarea } from "theme/forms/form-control-textarea.component";
import { TrackEditHeader } from "features/royalties/tracks/components/track-edit-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditAudioScreen = ({ navigation, route }) => {
  const {
    trackID,
    releaseID,
    releasePartials = {},
    trackPartial = {},
  } = route.params;
  const { data: track, isFetched } = useTrack(trackID, trackPartial);
  const { data: release } = useRelease(releaseID, releasePartials);
  const { updateTrack } = useUpdateTrack(release, track);

  const { control, handleSubmit } = useForm();
  const { field: lyrics } = useController({ control, name: "lyrics" });

  const [invalid, setInvalid] = useState(false);
  const [audioUploading, setAudioUploading] = useState(false);

  const onPress = async (data) => {
    if (!track.signed_url_audio) setInvalid(true);
    else {
      await updateTrack.mutateAsync(data);
      setInvalid(false);
      navigation.navigate("Tracks", {
        screen: "TrackEditContent",
        params: {
          trackID: track.id,
          trackPartial: track,
          releaseID: release.id,
          releasePartials: release,
        },
      });
    }
  };

  useEffect(() => {
    if (track?.signed_url_lyrics) {
      fetch(track?.signed_url_lyrics)
        .then((resp) => resp.text())
        .then((data) => {
          lyrics.onChange(data);
        });
    }
  }, [track?.signed_url_lyrics]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <TrackEditHeader step="2" release={release} track={track} />
      <VStack mb={[0, 5]} flex={1}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <PageTitle
            nativeID="upload-track-audio"
            isDisabled={updateTrack.isLoading || audioUploading}
            title={translate("upload_song")}
          />
          {!isFetched ? (
            <Loading />
          ) : (
            <ResponsiveStack space="10">
              <Box flex={{ lg: 1 }}>
                <UploadAudio
                  invalid={invalid}
                  setInvalid={setInvalid}
                  setAudioUploading={setAudioUploading}
                  track={track}
                />
              </Box>

              <VStack p="5" space="5" flex={1} bg={"light.100"}>
                <HStack space="2" alignItems="center">
                  <Text fontSize="md">{translate("lyrics")}</Text>
                  <Text color="primary.600">{`(${translate(
                    "optional"
                  )})`}</Text>
                </HStack>
                <FormControlTextarea
                  name="lyrics"
                  control={control}
                  value={lyrics.value}
                  isDisabled={track?.is_submitted}
                  placeholder={translate("lyrics_placeholder_text")}
                />
              </VStack>
            </ResponsiveStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        showBackButton
        disabled={updateTrack.isLoading || audioUploading}
        isLoading={updateTrack.isLoading || audioUploading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
