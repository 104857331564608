import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, Text, Box, IconButton, Icon, HStack } from "native-base";
//internal component
import { EventMapModal } from "features/tickets/archive/event-map-modal.component";
// utility functions
import { translate } from "utils/helpers";

const Marker = ({ lat, lng }) => (
  <Icon
    as={MaterialCommunityIcons}
    name="google-maps"
    color="secondary.300"
    size={"lg"}
  />
);

const darkTheme = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];

export const EventDetailLocation = ({ location, lat, lng }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <VStack space={5}>
      <Text fontSize={["xl", "2xl"]} fontWeight={"black"}>
        {translate("location")}
      </Text>
      <HStack space={1} alignItems={"center"}>
        <Icon size={5} as={MaterialCommunityIcons} name="map-marker-outline" />
        <Text fontSize={["sm", "md"]} fontWeight={500}>
          {location}
        </Text>
      </HStack>
      <Box>
        <Box
          style={{
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowOpacity: 0.3,
            shadowRadius: 10,
            elevation: 10,
          }}
          p={2}
          mx={2}
          top={2}
          left={0}
          flex={1}
          zIndex={1}
          borderRadius={"xs"}
          position={"absolute"}
          alignItems={"center"}
          justify-content={"center"}
          backgroundColor={"light.100"}
        >
          <IconButton
            p={0}
            alignSelf="center"
            onPress={() => setShowModal(true)}
            icon={
              <Icon
                color="#666666"
                as={MaterialCommunityIcons}
                size={6}
                name="dots-horizontal-circle-outline"
              />
            }
          />
        </Box>
        <Box
          width={"100%"}
          height={[200, 300]}
          borderRadius="xl"
          overflow="hidden"
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBMQhSn7sKlF_ntSktoOjCnRsJKQPx2MTY",
            }}
            defaultCenter={{ lat, lng }}
            defaultZoom={11}
            options={{
              styles: darkTheme,
            }}
          >
            <Marker lat={lat} lng={lng} />
          </GoogleMapReact>
        </Box>
      </Box>
      <EventMapModal
        address={{
          location: location,
          lat: lat,
          lng: lng,
        }}
        showModal={showModal}
        toggleModal={() => setShowModal(!showModal)}
      />
    </VStack>
  );
};
