import React from "react";
import { HStack, Box, Text } from "native-base";
// internal
import { FormControlInput } from "theme/forms/form-control-input.component";

export const PhoneNumberInput = ({ control, isDisabled = false, ...props }) => {
  return (
    <HStack width="100%" space={1}>
      <Box flex={2}>
        <FormControlInput
          _ios={{ h: 10 }}
          isDisabled={isDisabled}
          name="country_code"
          keyboardType="phone-pad"
          placeholder="49"
          control={control}
          rules={{
            required: true,
            minLength: 1,
            maxLength: 2,
          }}
          value={"49"}
          InputLeftElement={
            <Text pl={1} fontSize={["md", "lg"]}>
              +
            </Text>
          }
        />
      </Box>
      <Box flex={7}>
        <FormControlInput
          {...props}
          _ios={{ h: 10 }}
          name="phone_number"
          isDisabled={isDisabled}
          keyboardType="phone-pad"
          placeholder="176 456 87 910"
          control={control}
          rules={{
            required: true,
            minLength: 10,
          }}
        />
      </Box>
    </HStack>
  );
};
