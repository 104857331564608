import { useState } from "react";
import { useQueryClient } from "react-query";

export const useInvalidateAuction = (id) => {
  const queryClient = useQueryClient();
  const [isRefreshing, setIsRefreshing] = useState(false);
  return {
    invalidateAuction: () => {
      setIsRefreshing(true);
      queryClient.invalidateQueries(`auction-${id}`);
      setTimeout(() => setIsRefreshing(false), 2000);
    },
    isRefreshing: isRefreshing,
  };
};
