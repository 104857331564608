import React from "react";
import { HStack, Skeleton, Stack, VStack } from "native-base";

export const ProfileDetailHeaderSkeleton = () => (
  <VStack space={3}>
    {/* TODO: change height if we remove progress alert */}
    <Skeleton
      borderRadius={[0, 0, 15]}
      startColor={"skeleton.dark"}
      overflow={"hidden"}
      h={[160, 220]}
      w="100%"
    />

    <HStack space={5} px={5} alignItems="center" mt={-50}>
      <Skeleton
        h={65}
        w={65}
        borderRadius={"xl"}
        overflow={"hidden"}
        startColor={"skeleton.light"}
      />
      <Stack space={2}>
        <Skeleton h={3} w={70} startColor={"skeleton.light"} />
        <Skeleton h={3} w={40} startColor={"skeleton.light"} />
      </Stack>
    </HStack>
    <HStack px={5}>
      {Array(3)
        .fill("")
        .map((_unique, i) => (
          <Skeleton
            key={i}
            w={150}
            h={50}
            borderRadius={"xl"}
            overflow="hidden"
            startColor="skeleton.dark"
            mr={2}
          />
        ))}
    </HStack>
    <HStack px={5}>
      {Array(5)
        .fill("")
        .map((_unique, i) => (
          <Skeleton
            key={i}
            w={45}
            h={45}
            borderRadius={"xl"}
            overflow="hidden"
            startColor="skeleton.dark"
            mr={2}
          />
        ))}
    </HStack>
  </VStack>
);
