import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useMasterRightRoles = () => {
  const { client } = useContext(AxiosContext);

  return useQuery("master-right-roles", () =>
    client.get("enums/master_right_roles/").then((resp) => resp.data)
  );
};
