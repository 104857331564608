import React, { useEffect, useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Text, Icon, VStack, Spinner, useTheme } from "native-base";
import { useNavigation } from "@react-navigation/native";
//internal components

import { PlayAudioSnippetButton } from "common/components/buttons/play-audio-snippet-button.component";
//internal hooks
import { translate } from "utils/helpers";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useSaveSpotifyTrack from "features/royalties/license-products/hooks/useSaveSpotifyTrack";

export const LicenseProductDetailButtons = ({ product }) => {
  const navigation = useNavigation();
  const { data: profile } = useUserProfile();
  const { saveSpotifyTrack } = useSaveSpotifyTrack();
  const { colors } = useTheme();

  const [isLiked, setIsLiked] = useState(false);
  const disableSpotifySave = !!!profile?.spotify_access_token;

  useEffect(() => {
    if (saveSpotifyTrack.isSuccess) {
      setIsLiked(true);
    }
  }, [saveSpotifyTrack.isSuccess]);

  return (
    <VStack space={5}>
      <HStack space={"3"}>
        {/*  <VStack
        alignContent={"center"}
        justifyContent={"center"}
        px={2}
        py={2}
        borderRadius={"2xl"}
        _dark={{ bg: "dark.800" }}
        _light={{ bg: "dark.900" }}
      >
       {saveSpotifyTrack.isLoading ? (
          <Spinner color={"lightText"} size={"sm"} />
        ) : (
          <Pressable
            onPress={() => {
              if (disableSpotifySave) {
                navigation.navigate("BottomNavigator", { screen: "Lounge" });
              } else if (!isLiked) {
                const params = {
                  track_ids: product.license_product?.tracks.map(
                    (item) => item?.id
                  ),
                  is_saver: true,
                  is_playlister: false,
                };
                saveSpotifyTrack.mutate(params);
              }
            }}
          >
            <HStack justifyContent={"center"} space={2} alignItems="center">
              <Icon
                p={0}
                size={"lg"}
                color={colors.spotify.light_green}
                as={MaterialCommunityIcons}
                name={isLiked ? "checkbox-marked-circle" : "spotify"}
              />
              <Text
                bold
                mr={1}
                fontSize={["sm", "md"]}
                _light={{ color: "lightText" }}
              >
                {isLiked ? translate("saved") : translate("save")}
              </Text>
            </HStack>
          </Pressable>
        )}
      </VStack> */}
        {product?.license_product?.audio && (
          <PlayAudioSnippetButton
            size={"30"}
            id={product?.id}
            uri={product?.license_product?.audio}
          />
        )}
      </HStack>
      <VStack>
        <Text color={"muted.400"} fontSize={["sm", "md"]} fontWeight={500}>
          <Text color={"darkText"}>
            {product?.license_product?.release?.fake_type.toUpperCase()}
          </Text>
          {" • "}
          {product?.license_product?.tracks?.length}{" "}
          {product?.license_product?.tracks?.length === 1
            ? translate("track")
            : translate("tracks")}
        </Text>
        <Text fontSize={"3xl"} fontWeight={800}>
          {product?.title}
        </Text>
      </VStack>
    </VStack>
  );
};
