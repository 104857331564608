import React from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack } from "native-base";
// utility functions
import { translate, navigateBack } from "utils/helpers";

// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";

import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
// internal components
import { Alert } from "theme/feedback/alert.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ProfileEditForm } from "features/accounts/profiles/components/profile-edit-form.component";
import { ProfilePublicModal } from "features/accounts/profiles/components/profile-edit-modal.component";

export const ProfileEditScreen = ({ navigation }) => {
  const { control, handleSubmit } = useForm();
  const profile = useUserProfile();
  const { updateProfile } = useUpdateProfile(profile.data?.id);
  const [showModal, setShowModal] = React.useState(false);
  // submission logic
  const onPress = (data) => {
    !data?.is_public ? setShowModal(true) : updateProfile.mutate(data);
  };
  // switch profile public logic
  const setProfileStatus = (data, isPublic) => {
    toggleModal();
    data.is_public = isPublic;
    updateProfile.mutate(data);
  };
  // refresh logic
  React.useEffect(() => {
    if (updateProfile.isSuccess) navigateBack(navigation);
  }, [updateProfile.isSuccess]);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("edit_profile")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <VStack p={5} flex={1} space={5}>
        <ScrollView
          flex={1}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <VStack space={5}>
            {profile.isFetched && !profile.data.username && (
              <Alert
                title={translate("action_required")}
                text={translate("missing_username_alert_message")}
              />
            )}
            <ProfileEditForm profile={profile} control={control} />
            <ProfilePublicModal
              showModal={showModal}
              setProfilePublic={handleSubmit((data) =>
                setProfileStatus(data, true)
              )}
              setProfilePrivate={handleSubmit((data) =>
                setProfileStatus(data, false)
              )}
              toggleModal={toggleModal}
            />
          </VStack>
        </ScrollView>
        <PrimaryButton
          isLoading={updateProfile.isLoading}
          disabled={updateProfile.isLoading}
          text={translate("save")}
          onPress={handleSubmit(onPress)}
        />
      </VStack>
    </ScreenFrame>
  );
};
