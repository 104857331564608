import React from "react";
import { HStack, VStack, WarningIcon, Text } from "native-base";

export const TermsDescription = () => {
  return (
    <VStack mt={2} space={3}>
      <HStack alignItems="center" space={3}>
        <WarningIcon color={"secondary.600"} size={5} />
        <Text fontSize={["md", "lg"]} fontWeight={"bold"}>
          Unsere Nutzungsbedingungen wurden akualisiert.
        </Text>
      </HStack>
      <Text fontSize={["sm", "md"]}>
        Um biddz für unsere Fans und Artists stätig zu verbessern mussten wir
        unsere Nutzungsbedingungen aktualisieren. Du musst diesen zustimmen um
        alle Funktionalitäten auf biddz nutzten zu können.
      </Text>
    </VStack>
  );
};
