import React from "react";
import { Divider, HStack } from "native-base";
import * as Animatable from "react-native-animatable";

export const Loading = ({
  maxHeight = 35,
  width = "4px",
  pt = 10,
  _light = { bg: "light.400" },
  _dark = { bg: "light.100" },
  ...props
}) => {
  const minHeight = 5;
  const heightChange = {
    0: {
      height: minHeight,
    },

    0.5: {
      height: maxHeight,
    },
    1: {
      height: minHeight,
    },
  };
  return (
    <HStack
      justifyContent={"center"}
      alignItems={"center"}
      height={maxHeight}
      space={1}
      pt={pt}
    >
      {[0, 250, 500, 750, 1000, 1250, 1500].map((delay) => (
        <Animatable.View
          key={delay}
          animation={heightChange}
          delay={delay}
          duration={1500}
          iterationCount="infinite"
        >
          <Divider
            {...props}
            _light={_light}
            _dark={_dark}
            orientation="vertical"
            width={width}
            borderRadius={"xl"}
          />
        </Animatable.View>
      ))}
    </HStack>
  );
};
