import React from "react";
import { Divider, VStack, Text, HStack, useMediaQuery } from "native-base";
import { useIsFocused } from "@react-navigation/native";
// internal components
import { PostList } from "features/general/posts/components/post-list.component";
import { TicketProductDetailHeader } from "features/tickets/ticket-products/components/ticket-product-detail-header.component";
import { TicketProductDetailOptions } from "features/tickets/ticket-products/components/ticket-product-detail-options.component";
import { TicketProductDetailLineup } from "features/tickets/ticket-products/components/ticket-product-detail-lineup.component";
import { TicketProductDetailVenue } from "features/tickets/ticket-products/components/ticket-product-detail-venue.component";
// import { EventDetailLocation } from "features/tickets/events/components/event-detail-location.component";
import { TicketProductDetailSupportButton } from "features/tickets/ticket-products/components/ticket-product-detail-support-button.component";
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";
// helpers
import { translate } from "src/utils/helpers";

export const TicketProductDetailEvent = ({ auction, isDardan }) => {
  const isFocused = useIsFocused();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const event = auction?.events?.length > 0 ? auction.events[0] : null;

  return (
    <HStack>
      <VStack flex={1} space={10}>
        <TicketProductDetailHeader
          isDardan={isDardan}
          auction={auction}
          event={event}
        />
        {/*TODO: Remove this check after product is attached*/}
        {!isDesktop &&
          !isDardan &&
          auction?.products?.some((product) => product?.is_purchasable) && (
            <TicketProductDetailOptions auction={auction} />
          )}
        <VStack space={10}>
          <ExpandableTextReadMore
            text={event?.description}
            title={isDardan ? translate("about") : translate("about_event")}
            noOfLines={6}
          />

          <Divider bg={"gray.200"} />

          <VStack space={6}>
            <Text fontSize={["xl", "2xl"]} fontWeight={"black"}>
              {translate("details")}
            </Text>
            <TicketProductDetailLineup musicians={auction?.musicians} />

            <TicketProductDetailVenue
              entryTime={auction?.events?.[0]?.entry_time}
            />
          </VStack>

          <Divider bg={"gray.200"} />

          <PostList auctionID={auction?.id} />

          {/* {isFocused && !isDardan && (
            <EventDetailLocation
              location={event?.location}
              lat={event?.latitude}
              lng={event?.longitude}
            />
          )}

          <TicketProductDetailSupportButton /> */}
        </VStack>
      </VStack>

      {isDesktop && (
        <VStack ml={24} flex={1}>
          {/*TODO: Remove this check after product is attached*/}
          {!isDardan &&
            auction?.products?.some((product) => product?.is_purchasable) && (
              <TicketProductDetailOptions auction={auction} />
            )}
        </VStack>
      )}
    </HStack>
  );
};
