import React, { useContext, useEffect, useRef } from "react";
import { VStack, Box, Text, Pressable, Input } from "native-base";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
// utility functions
import { AuthenticationContext } from "services/authentication.context";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
import { translate, navigateBack } from "utils/helpers";

export const CodeVerificationScreen = ({ navigation, route }) => {
  const { is_coupon } = route.params ? route.params : { is_coupon: false };
  const isFirstRoute = useIsFirstRouteInParent();
  const { onValidateOTP, phoneNumber, isLoading } = useContext(
    AuthenticationContext
  );
  const ref = useRef(null);

  useEffect(() => {
    if (isFirstRoute) navigateBack(navigation);
    ref.current.focus();
  }, []);

  return (
    <ScreenFrame bg="transparent" showDrawer={false}>
      <Pressable flex={2} onPress={() => navigateBack(navigation)}></Pressable>
      <VStack
        p="5"
        space="7"
        shadow={2}
        safeAreaBottom
        borderWidth="1"
        bg="light.100"
        alignItems="center"
        borderTopRadius={20}
      >
        <Text
          _dark={{ color: "black" }}
          alignItems="center"
          fontSize={["xl", "2xl", "3xl"]}
          fontWeight={"semibold"}
        >
          {translate("verification_code")}
        </Text>
        <Text _dark={{ color: "black" }} fontSize={["md", "lg", "xl"]}>
          {translate("verification_code_subtitle")}{" "}
        </Text>
        <Box
          borderRadius={"xl"}
          padding={2}
          _light={{ bg: "darkText" }}
          _dark={{ bg: "primary.600" }}
        >
          <Text
            px={5}
            fontSize={["md", "lg", "xl"]}
            _light={{ color: "lightText" }}
            _dark={{ color: "darkText" }}
          >
            {phoneNumber}
          </Text>
        </Box>
        <Pressable
          bottom="0"
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Text
            _dark={{ color: "primary.600" }}
            _light={{ color: "darkText" }}
            fontSize={["md", "lg"]}
          >
            {translate("wrong_number")}
          </Text>
        </Pressable>
        {isLoading ? (
          <Loading color={"darkText"} />
        ) : (
          <Input
            ref={ref}
            size="xl"
            color="primary"
            autoFocus={true}
            textAlign="center"
            variant="underlined"
            justifyContent="center"
            _light={{
              _hover: { borderColor: "darkText" },
              _focus: { bg: "light.100" },
              focusOutlineColor: "darkText",
            }}
            keyboardType="phone-pad"
            onChangeText={(OTP) => {
              if (OTP.length >= 6) {
                onValidateOTP(OTP, navigation, is_coupon);
              }
            }}
          />
        )}
        <Box p={5} />
      </VStack>
    </ScreenFrame>
  );
};
