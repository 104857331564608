import React from "react";
import { Pressable } from "native-base";
import { NewButtonBody } from "theme/buttons/new-button-body.component";

export const HorizontalIconButton = ({
  text,
  iconName,
  onPress,
  textStyles,
  iconStyles,
  fontSize,
  fontWeight,
  isLoading = false,
  rightComponent = undefined,
  ...props
}) => {
  return (
    <Pressable {...props} onPress={onPress} disabled={isLoading}>
      <NewButtonBody
        text={text}
        iconName={iconName}
        isLoading={isLoading}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textStyles={textStyles}
        iconStyles={iconStyles}
        rightComponent={rightComponent}
      />
    </Pressable>
  );
};
