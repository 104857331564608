import React, { useEffect, useState } from "react";
import { HStack, Text, VStack, Icon, Pressable, FlatList } from "native-base";
import { Ionicons } from "@expo/vector-icons";
// internal
import { getBenefitTitleParsed, translate } from "utils/helpers";
import { LicenseBenefitModal } from "features/royalties/license-benefits/components/license-benefit-modal.component";

export const LicenseProductOrderCreateBenefits = ({
  units,
  productBenefits,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    if (units === 0) setIsOpen(false);
  }, [units]);

  useEffect(() => {
    setBenefits(
      productBenefits?.filter((benefit) => units >= benefit?.min_units)
    );
  }, [productBenefits, units]);

  return (
    <VStack>
      <Pressable
        onPress={() => setIsOpen(!isOpen)}
        isDisabled={benefits?.length <= 0}
      >
        <HStack justifyContent={"space-between"}>
          <Text px={2} fontSize={["md", "lg"]} bold>
            {translate("your_benefits")}
          </Text>
          <HStack alignItems={"center"}>
            <Text px={2} fontSize={["md", "lg"]} bold>
              {benefits?.length}
            </Text>
            {benefits?.length > 0 && (
              <Icon
                size={4}
                as={Ionicons}
                name={`chevron-${isOpen ? `up` : `down`}`}
              />
            )}
          </HStack>
        </HStack>
      </Pressable>
      {isOpen && (
        <FlatList
          px={4}
          mt={4}
          rounded={"xl"}
          bg={"light.300"}
          data={benefits}
          renderItem={({ item, index }) => (
            <BenefitModal
              key={index}
              benefit={item}
              showsDivider={index === benefits?.length - 1}
            />
          )}
        />
      )}
    </VStack>
  );
};

const BenefitModal = ({ benefit, showsDivider }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Pressable
        px={2}
        py={5}
        borderBottomColor={"gray.300"}
        onPress={() => setModalOpen(true)}
        borderBottomWidth={showsDivider ? 0 : 0.8}
      >
        <HStack
          space={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            bold
            isTruncated
            maxW={"75%"}
            fontSize={"md"}
            textTransform={"capitalize"}
          >
            {`${benefit?.tier} - ${getBenefitTitleParsed(benefit)}`}
          </Text>
          <Text fontWeight={"light"} color={"gray.500"}>
            {translate("details")}
          </Text>
        </HStack>
      </Pressable>
      <LicenseBenefitModal
        benefit={benefit}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
      />
    </>
  );
};
