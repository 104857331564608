import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack } from "native-base";
// utility functions
import { translate, navigateBack } from "utils/helpers";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { MusicianEditPersonalDataForm } from "features/accounts/musicians/components/musician-edit-personal-data-form";
import { PageTitle } from "common/components/layout/page-title.component";

export const MusicianEditPersonalDataScreen = ({ navigation }) => {
  const profile = useUserProfile();
  const musician = useUserMusician();
  const { handleSubmit, control } = useForm();
  const { updateProfile } = useUpdateProfile(profile.data?.id);

  const onPress = async (data) => {
    await updateProfile.mutateAsync(data);
    navigation.push("Musicians", { screen: "MusicianEditProfile" });
  };

  const onSave = (data) => updateProfile.mutate(data);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("profile")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        profileProgress={{ type: "musician", activeIndex: 0 }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        flex={1}
      >
        <PageTitle
          isDisabled={updateProfile.isLoading || profile.isLoading}
          title={translate("personal_data")}
          onSave={handleSubmit(onSave)}
        />
        <VStack space="10" pb={[0, 5]}>
          <MusicianEditPersonalDataForm
            isLoading={profile.isFetching || musician.isFetching}
            profileData={profile.data}
            musicianData={musician.data}
            control={control}
          />

          <PrimaryButton
            p={[5, 0]}
            showNextIcon
            disabled={updateProfile.isLoading || profile.isLoading}
            isLoading={updateProfile.isLoading}
            text={translate("next")}
            onPress={handleSubmit(onPress)}
          />
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
