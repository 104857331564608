import React, { useEffect, useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
// internal Components
import { HomeScreen } from "common/screens/home.screen";
import { ReleaseNavigator } from "features/royalties/releases/release.navigator";
import { AuthenticationContext } from "services/authentication.context";
// external components
import { SharesScreen } from "common/screens/shares.screen";
import { EventsScreen } from "common/screens/events.screen";

const Drawer = createStackNavigator();

export const DrawerNavigator = () => {
  const profile = useUserProfile();
  const { profileType } = useContext(AuthenticationContext);
  const { updateProfile } = useUpdateProfile(profile?.data?.id);

  useEffect(() => {
    if (profile?.isFetched) {
      if (!profile?.data?.type) {
        console.debug(`:mage: Adding new user profile type: ${profileType}`);
        updateProfile.mutate({ type: profileType });
      } else if (profile?.data?.type === "fan" && profileType === "artist") {
        console.debug(":mage: Converting existing fan profile to artist");
        updateProfile.mutate({ type: profileType });
      }
    }
  }, [profile?.isFetched]);

  return (
    <Drawer.Navigator screenOptions={{ headerShown: false }}>
      {/* <Drawer.Screen name="Marketplace" component={HomeScreen} /> */}
      {!profile?.isLoading && profile?.data?.type === "artist" ? (
        <Drawer.Screen name="ReleaseTabs" component={ReleaseNavigator} />
      ) : (
        <>
          <Drawer.Screen name="Events" component={EventsScreen} />
          <Drawer.Screen name="Shares" component={SharesScreen} />
        </>
      )}
    </Drawer.Navigator>
  );
};
