import React from "react";
import { HStack, Skeleton } from "native-base";

export const SkeletonTextHorizontal = ({
  numberOfWords = 2,
  color = "skeleton.dark",
  ...props
}) => (
  <HStack {...props} w={"100%"}>
    {Array(numberOfWords)
      .fill("")
      .map((_unique, i) => (
        <Skeleton
          key={i}
          startColor={color}
          h={5}
          w={["20%", "15%"]}
          mx={2}
          borderRadius={"xl"}
          overflow={"hidden"}
        />
      ))}
  </HStack>
);
