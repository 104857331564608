import { Skeleton } from "native-base";
import React from "react";

export const SkeletonRounded = ({
  height = "100%",
  width = "100%",
  color = "skeleton.light",
  borderRadius = 10,
  ...props
}) => (
  <Skeleton
    {...props}
    h={height}
    w={width}
    startColor={color}
    rounded={borderRadius}
    overflow={"hidden"}
  />
);
