import React from "react";
import { HStack, Icon, VStack, Text } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { translate } from "utils/helpers";

export const OrderDetailProcessing = () => {
  return (
    <VStack alignItems={"center"} space={5}>
      <HStack space={3} alignItems={"center"} justifyContent={"center"}>
        <Icon
          as={MaterialCommunityIcons}
          color="secondary.600"
          name="progress-clock"
          size={"sm"}
        />
        <Text fontSize={["lg", "xl"]}>
          {translate("order_processing_title")}
        </Text>
      </HStack>

      <Text fontSize={["sm", "md"]} maxWidth={400} textAlign={"center"}>
        {translate("order_processing_message")}
      </Text>
    </VStack>
  );
};
