import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useAuctionBenefits = (productID) => {
  const { client } = useContext(AxiosContext);

  return useQuery(
    `auction-benefits-${productID}`,
    () =>
      client.get(`/products/${productID}/benefits/`).then((resp) => resp.data),
    {
      enabled: !!productID,
      staleTime: 5000,
    }
  );
};
