import React from "react";
import { ScrollView, VStack, Box } from "native-base";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { TrackEditHeader } from "features/royalties/tracks/components/track-edit-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { TrackMusicians } from "features/royalties/tracks/components/track-musicians.components";
import { TrackContributors } from "features/royalties/tracks/components/track-contributors.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditArtistsScreen = ({ navigation, route }) => {
  const {
    trackID,
    releaseID,
    releasePartials = {},
    trackPartial = {},
  } = route.params;
  const { data: track, isFetched: trackFetched } = useTrack(
    trackID,
    trackPartial
  );
  const { data: release, isFetched: releaseFetched } = useRelease(
    releaseID,
    releasePartials
  );

  const onPress = () => {
    navigation.navigate("Tracks", {
      screen: "TrackEditShare",
      params: {
        trackID: track.id,
        trackPartial: track,
        releaseID: release.id,
        releasePartials: release,
      },
    });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <TrackEditHeader step="4" release={release} track={track} />
      <VStack mb={[0, 5]} flex={1}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <PageTitle title={translate("artist_contributors")} />
          {!trackFetched && !releaseFetched ? (
            <Loading />
          ) : (
            <ResponsiveStack space="10">
              <Box flex={{ lg: 1 }}>
                <TrackMusicians
                  navigation={navigation}
                  track={track}
                  release={release}
                />
              </Box>
              <Box flex={{ lg: 1 }}>
                <TrackContributors
                  navigation={navigation}
                  track={track}
                  release={release}
                />
              </Box>
            </ResponsiveStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        showBackButton
        text={translate("next")}
        onPress={onPress}
      />
    </ScreenFrame>
  );
};
