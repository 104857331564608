import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useAcceptTerms = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return {
    acceptTerms: useMutation(
      (params) =>
        client.patch(`/me/terms_and_conditions/`, params).then((res) => {
          queryClient.invalidateQueries("user");
          return res.data;
        }),
      {
        onSuccess: () => {},
      }
    ),
  };
};

export default useAcceptTerms;
