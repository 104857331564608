import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useExplicitTypes = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`explicit-types`, () => {
    console.debug(`explicit types retrieved`);
    return client.get(`enums/explicit_types/`).then((resp) => resp.data);
  });
};
