import React from "react";
import { Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export const AppIcon = ({
  name,
  size = "md" | 5,
  bg = "dark.900",
  borderRadius = "xl",
  ...props
}) => (
  <Icon
    name={name}
    size={size}
    as={MaterialCommunityIcons}
    borderRadius={borderRadius}
    {...props}
  />
);
