import React from "react";
import { FlatList, useMediaQuery, VStack } from "native-base";
// internal hooks
import { useMusicians } from "features/accounts/musicians/hooks/useMusicians";
// internal components
import { MusicianListCard } from "features/accounts/musicians/components/musician-list-card.component";
import { Loading } from "common/components/loading/loading.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const MusicianListScreen = ({ navigation }) => {
  const musicians = useMusicians();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    <ScreenFrame>
      <NavigationHeader
        title={translate("artists")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      {musicians.isLoading && <Loading maxHeight={20} width="3px" />}
      {musicians.isFetched && (
        <FlatList
          key={isDesktop ? "desktop-musician-list" : "musician-list"}
          numColumns={isDesktop ? 4 : 2}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          data={musicians?.data?.pages.map((page) => page.items).flat()}
          onEndReached={() =>
            musicians.hasNextPage && !musicians.isFetching
              ? musicians.fetchNextPage()
              : null
          }
          onEndReachedThreshold={0.3}
          renderItem={({ item }) => (
            <MusicianListCard
              key={item.id}
              musician={item}
              onPress={() => {
                navigation.navigate("Musicians", {
                  screen: "MusicianDetail",
                  params: { id: item.id, initialData: item },
                });
              }}
            />
          )}
          ListFooterComponent={
            musicians.isFetching && (
              <VStack flex={1} alignItems={"center"}>
                <CenteredSpinner />
              </VStack>
            )
          }
        />
      )}
    </ScreenFrame>
  );
};
