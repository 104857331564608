import { HStack, Text, ScrollView, Pressable } from "native-base";
import { getAuctionListFilterTitle } from "utils/helpers";

export const FILTERS = {
  is_active: { is_active: true, category: "license" },
  is_upcoming: { is_upcoming: true, category: "license" },
  is_over: { is_over: true, category: "license" },
};

export const HEADER = {
  is_active: "share",
  is_upcoming: "upcoming",
  is_over: "past",
};

export const AuctionLicenseFilters = ({ filter, setFilter }) => (
  <HStack px={3} space={3}>
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {Object.keys(FILTERS).map((item, index) => (
        <Pressable
          mr={3}
          py={3}
          px={6}
          key={index}
          rounded={"full"}
          bg={filter === item ? "secondary.300" : "gray.100"}
          onPress={() => setFilter(item)}
        >
          <Text fontWeight={500}>{getAuctionListFilterTitle(item)}</Text>
        </Pressable>
      ))}
    </ScrollView>
  </HStack>
);
