import React from "react";
import { VStack } from "native-base";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
// internal hooks
import { useGenres } from "features/royalties/releases/hooks/useGenres";
import { useLanguages } from "features/royalties/releases/hooks/useLanguages";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseForm = ({ control, release, isLoading, onPress }) => (
  <VStack space={10}>
    <VStack py={5} px={10} space={7}>
      <FormControlInput
        name="title"
        control={control}
        rules={{ required: true }}
        label={translate("release_title")}
        isDisabled={release?.is_delivered}
        placeholder={translate("release_title")}
        value={release?.title}
        infoText={translate("release_title_info_text")}
        helperText={translate("release_title_helper_text")}
      />
      <FormControlSelect
        name="language"
        control={control}
        query={useLanguages()}
        rules={{ required: true }}
        value={release?.language || "de"}
        isDisabled={release?.is_delivered}
        label={translate("release_language")}
        placeholder={translate("release_language")}
        infoText={translate("release_language_info_text")}
        helperText={translate("release_language_helper_text")}
      />
      <FormControlSelect
        useValues
        name="genre"
        control={control}
        query={useGenres()}
        value={release?.genre}
        label={translate("genre")}
        rules={{ required: true }}
        placeholder={translate("genre")}
        isDisabled={release?.is_delivered}
        helperText={translate("genre_helper_text")}
      />
    </VStack>
    <PrimaryButton
      px={5}
      pb={[5, 0]}
      showNextIcon
      disabled={isLoading}
      isLoading={isLoading}
      text={translate("next")}
      onPress={onPress}
    />
  </VStack>
);
