import React from "react";
import { Dimensions, Platform } from "react-native";
import { VStack, useMediaQuery, useTheme, useColorMode } from "native-base";
import dayjs from "dayjs";
//important to get de locale
import { de } from "dayjs/locale/de";
import {
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryBar,
  VictoryContainer,
} from "victory";
import { LicenseCollectibleAnalyticsEmpty } from "features/royalties/license-collectibles/components/license-collectible-analytics-empty.component";
import { getLocalizedNumber } from "utils/helpers";

export const LicenseCollectibleAnalyticsBarChart = ({
  setScrollEnabled,
  earnings,
  estimatedEarnings,
  yAxisLabel = "value",
  timeFrame,
}) => {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 992 });

  const windowWidth = Dimensions.get("window").width;
  const lastEarnings =
    earnings[timeFrame].length > 0
      ? earnings[timeFrame][earnings[timeFrame].length - 1]?.cum_value
      : 0;

  const earningsColor = earnings[timeFrame].map((item) => ({
    ...item,
    color: colors.secondary["700"],
  }));
  const estimatedEarningsColor = estimatedEarnings[timeFrame].map((item) => ({
    ...item,
    color: "grey",
    cum_value:
      yAxisLabel === "cum_value"
        ? item.cum_value + lastEarnings
        : item.cum_value,
  }));

  return (
    <VStack alignItems="center" mb={5}>
      <VictoryChart
        padding={{
          top: isSmallScreen ? 20 : 50,
          bottom: isSmallScreen ? 20 : 50,
          right: isSmallScreen ? 40 : 120,
          left: 0,
        }}
        height={isSmallScreen ? 300 : 500}
        style={isSmallScreen ? { parent: { width: windowWidth } } : {}}
        width={windowWidth}
        domainPadding={isSmallScreen ? { x: 25, y: 25 } : { x: 125, y: 125 }}
        containerComponent={
          <VictoryContainer
            style={{
              touchAction: "auto",
            }}
            events={
              Platform.OS !== "web"
                ? {
                    onPressIn: () => {
                      setScrollEnabled(false);
                    },
                    onPressOut: () => {
                      setScrollEnabled(true);
                    },
                  }
                : null
            }
          />
        }
      >
        <VictoryAxis
          fixLabelOverlap
          style={{
            tickLabels: {
              fontSize: isSmallScreen ? "10px" : "30px",
              fontWeight: "semibold",
              fill:
                colorMode === "dark" ? colors["lightText"] : colors.dark["700"],
            },
            axis: {
              stroke:
                colorMode === "dark"
                  ? colors?.dark["800"]
                  : colors?.light["600"],
              strokeDasharray: "3 10",
            },
          }}
          tickFormat={(data) => {
            if ([...earningsColor, ...estimatedEarningsColor].length > 0) {
              return dayjs(data, "DD.MM.YYYY").format("MMM.YY");
            } else return "";
          }}
        />
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          orientation="right"
          style={{
            axis: { stroke: "transparent" },
            tickLabels: {
              fontSize: isSmallScreen ? "10px" : "25px",
              fontWeight: "semibold",
              fill:
                colorMode === "dark" ? colors["lightText"] : colors.dark["700"],
            },
            grid: {
              stroke:
                colorMode === "dark"
                  ? colors?.dark["800"]
                  : colors?.light["600"],
              strokeDasharray: "3 10",
            },
          }}
          tickFormat={(data) => {
            return `${getLocalizedNumber(data, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}€`;
          }}
        />
        <VictoryBar
          barWidth={
            [...earningsColor, ...estimatedEarningsColor].length === 1
              ? 100
              : null
          }
          data={[...earningsColor, ...estimatedEarningsColor]}
          x={timeFrame}
          y={yAxisLabel}
          style={{
            data: {
              fill: (d) => d.datum.color,
              strokeWidth: isSmallScreen ? 2 : 4,
              stroke: (d) => d.datum.color,
            },
          }}
          cornerRadius={{ topLeft: 5, topRight: 5 }}
          labels={({ datum }) =>
            `${getLocalizedNumber(datum[yAxisLabel], {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}€\n ${dayjs(datum[timeFrame], "DD.MM.YYYY").format("MMM.YY")}`
          }
          labelComponent={
            <VictoryTooltip
              style={{
                fontSize: isSmallScreen ? "18px" : "30px",
              }}
              cornerRadius={5}
              key={({ datum }) => datum.x}
              flyoutStyle={{ stroke: "none", fill: "#DBDEFF" }}
            />
          }
        />
      </VictoryChart>
      {[...earningsColor, ...estimatedEarningsColor].length === 0 && (
        <LicenseCollectibleAnalyticsEmpty />
      )}
    </VStack>
  );
};
