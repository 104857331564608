import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCollectibleMint = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation((collectibleID) =>
    client
      .get(`collectibles/${collectibleID}/mint/`)
      .then((_) =>
        queryClient.invalidateQueries(`collectible-${collectibleID}`)
      )
  );
};

export default useCollectibleMint;
