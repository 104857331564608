import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { CollectiblesListScreen } from "features/general/collectibles/screens/collectibles-list.screen";
import { LicenseCollectibleDetailScreen } from "features/royalties/license-collectibles/screens/license-collectible-detail.screen";
import { LicenseCollectibleBenefitsListScreen } from "features/royalties/license-benefits/screens/license-collectible-benefits-list.screen";

const Stack = createStackNavigator();

export const LicenseCollectiblesNavigator = ({ route, navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CollectiblesList"
        component={CollectiblesListScreen}
      />
      <Stack.Screen
        name="LicenseCollectibleDetail"
        component={LicenseCollectibleDetailScreen}
      />
      <Stack.Screen
        name="LicenseCollectibleBenefitsList"
        component={LicenseCollectibleBenefitsListScreen}
      />
    </Stack.Navigator>
  );
};
