import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useMusicians = (size = 4) => {
  const { client } = useContext(AxiosContext);
  return useInfiniteQuery(
    `musicians`,
    ({ pageParam = 1 }) =>
      client.get(`musicians/?page=${pageParam}&size=${size}`).then((resp) => {
        console.debug(`👩‍🎤 Musicians | Page ${pageParam} `);
        return resp.data;
      }),
    {
      enabled: true,
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
