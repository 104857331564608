import React from "react";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  HStack,
  Icon,
  VStack,
  Text,
  Stack,
  Pressable,
  Image,
  Box,
  useMediaQuery,
} from "native-base";
// internal
import { getWindowWidth, translate } from "utils/helpers";
import { ExpandableTextReadMore } from "theme/typography/expandable-text-read-more.component";

export const MusicianListSummary = ({ musicians, imgStyle }) => {
  const navigation = useNavigation();
  const windowWidth = getWindowWidth();
  const [isMobile] = useMediaQuery({ maxWidth: 425 });

  const [chosenMusicianIndex, setChosenMusicianIndex] = React.useState(0);
  const [chosenMusician, setChosenMusician] = React.useState(
    musicians?.length > 0 ? musicians[chosenMusicianIndex] : null
  );

  const goToMusicianDetail = () => {
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: {
        id: chosenMusician?.id,
        initialData: chosenMusician,
      },
    });
  };

  const setChosenMusicianIndexWrapper = (index, num) => {
    let newIndex = index % num;
    if (newIndex < 0) {
      newIndex = num + newIndex;
    }
    setChosenMusicianIndex(newIndex);
  };

  React.useEffect(() => {
    if (musicians?.length) {
      setChosenMusician(musicians[chosenMusicianIndex]);
    }
  }, [chosenMusicianIndex]);

  if (musicians?.length > 0) {
    return (
      <VStack space={5}>
        <HStack justifyContent={"space-between"}>
          <Text
            fontWeight="black"
            fontSize={["xl", "2xl"]}
            textTransform={"capitalize"}
          >
            {translate("about_artist", {
              artistName: chosenMusician?.alias,
            })}
          </Text>
          {musicians?.length > 1 ? (
            <HStack space={5}>
              <Icon
                as={MaterialCommunityIcons}
                name="chevron-left"
                size="md"
                onPress={() =>
                  setChosenMusicianIndexWrapper(
                    chosenMusicianIndex - 1,
                    musicians?.length
                  )
                }
              />
              <Icon
                as={MaterialCommunityIcons}
                name="chevron-right"
                size="md"
                onPress={() =>
                  setChosenMusicianIndexWrapper(
                    chosenMusicianIndex + 1,
                    musicians?.length
                  )
                }
              />
            </HStack>
          ) : (
            <></>
          )}
        </HStack>
        <Stack space={5} direction={{ base: "column", lg: "row" }}>
          <VStack space={5}>
            <Pressable onPress={goToMusicianDetail}>
              <Image
                resizeMode={"cover"}
                alt={chosenMusician?.alias + " Profile Image"}
                source={{ uri: chosenMusician?.image_with_url?.md?.url }}
                height={
                  imgStyle
                    ? imgStyle.height
                    : { base: 200, lg: windowWidth * 0.35 }
                }
                width={
                  imgStyle
                    ? imgStyle.width
                    : { base: windowWidth * 0.9, lg: windowWidth * 0.35 }
                }
                borderRadius={"xl"}
              />
            </Pressable>
          </VStack>
          <Box style={isMobile ? {} : { flex: 1 }}>
            <ExpandableTextReadMore
              noOfLines={6}
              text={chosenMusician?.profile?.bio}
            />
          </Box>
        </Stack>
      </VStack>
    );
  } else {
    return null;
  }
};
