import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
  useMediaQuery,
} from "native-base";
import { FlatList } from "react-native";
// internal
import { translate } from "utils/helpers";
import { ListEmptyComponent } from "common/components/layout/list-empty.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { LicenseCollectibleBenefitListCard } from "features/royalties/license-benefits/components/license-collectible-benefit-list-card.component";
import { useUserBenefits } from "features/royalties/license-benefits/hooks/useUserBenefits";
import { useBenefitTypes } from "features/general/auctions/hooks/useBenefitTypes";

export const LicenseCollectibleBenefitList = ({
  size = 4,
  navigation,
  paginated = true,
}) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const benefitTypes = useBenefitTypes(true);

  const filters = ["unclaimed", "bonus content", "claimed"];
  const [filter, setFilter] = useState(filters[0]);
  const [params, setParams] = useState({
    is_expired: false,
    status: "pending",
    order_by: "-created_at",
  });

  const benefits = useUserBenefits(params, size);

  useEffect(() => {
    switch (filter) {
      case "bonus content":
        setParams({
          type: "bonus_content",
          status: "accepted",
          order_by: "-created_at",
        });
        return;
      case "claimed":
        setParams({
          type__not: "bonus_content",
          status: "accepted",
          order_by: "-created_at",
        });
        return;
      default:
        setParams({
          is_expired: false,
          status: "pending",
          order_by: "-created_at",
        });
    }
  }, [filter]);

  return (
    <FlatList
      mb={8}
      key={isDesktop ? "desktop-license-benefits" : "license-benefits"}
      numColumns={isDesktop ? 2 : 1}
      showsVerticalScrollIndicator={false}
      data={benefits?.data?.pages
        .map((page) => page.items)
        .flat()
        .slice(0, !paginated ? 4 : undefined)}
      onEndReached={() => {
        paginated && benefits.hasNextPage && !benefits.isFetching
          ? benefits.fetchNextPage()
          : undefined;
      }}
      onEndReachedThreshold={0.5}
      ListHeaderComponent={
        <VStack flex={1} space={3} mb={5}>
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"2xl"} fontWeight={600}>
              {translate("benefits")}
            </Text>
            {!paginated && (
              <Pressable
                onPress={() =>
                  navigation?.navigate("LicenseCollectibles", {
                    screen: "LicenseCollectibleBenefitsList",
                  })
                }
              >
                <Text fontSize={"sm"} color={"gray.500"}>
                  {translate("view_all")}
                </Text>
              </Pressable>
            )}
          </HStack>
          <Text fontSize={["sm", "md"]} color={"gray.400"}>
            {translate("benefits_section_subtitle")}
          </Text>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {filters.map((item, index) => {
              const selected = filter === item;
              return (
                <Pressable
                  mr={3}
                  py={3}
                  px={6}
                  key={index}
                  rounded={"full"}
                  bg={selected ? "secondary.300" : "gray.100"}
                  onPress={() => (selected ? undefined : setFilter(item))}
                >
                  <Text textTransform={"capitalize"} fontSize={["sm", "md"]}>
                    {item}
                  </Text>
                </Pressable>
              );
            })}
          </ScrollView>
        </VStack>
      }
      renderItem={({ item, index }) => (
        <LicenseCollectibleBenefitListCard
          key={index}
          benefit={item}
          benefitType={benefitTypes?.data?.find(
            (type) => type.value === item.type
          )}
        />
      )}
      ListEmptyComponent={
        <Box p={5} justifyContent={"center"} alignItems={"center"}>
          {benefits?.isLoading ? (
            <CenteredSpinner />
          ) : (
            <ListEmptyComponent
              text={translate("no_benefits_found")}
              subText={translate("no_benefits_found_desc")}
              buttonText={`Explore biddz Shares`}
              onPress={() => {
                navigation?.navigate("BottomNavigator", {
                  screen: "Marketplace",
                });
              }}
            />
          )}
        </Box>
      }
      ListFooterComponent={
        benefits.isFetching && (
          <Box m={2}>
            <CenteredSpinner />
          </Box>
        )
      }
    />
  );
};
