import React from "react";
import { useTheme } from "native-base";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useSafeAreaInsets } from "react-native-safe-area-context";
// screens
import { ReleaseCreateInfoScreen } from "features/royalties/releases/screens/release-create-info.screen";
import { ReleaseListScreen } from "features/royalties/releases/screens/release-list.screen";
import { ExistingReleaseListScreen } from "features/royalties/releases/screens/existing-release-list.screen";
import { ReleaseEditScreen } from "features/royalties/releases/screens/release-edit.screen";
import { ReleaseEditCoverArtScreen } from "features/royalties/releases/screens/release-edit-cover-art.screen";
import { ReleaseDetailScreen } from "features/royalties/releases/screens/release-detail.screen";
import { ReleaseEditTracksScreen } from "features/royalties/releases/screens/release-edit-tracks.screen";
import { ReleaseEditUserTracksScreen } from "features/royalties/releases/screens/release-edit-user-tracks.screen";
import { ReleaseEditSummaryScreen } from "features/royalties/releases/screens/release-edit-summary.screen";
import { ReleaseEditDistributionScreen } from "features/royalties/releases/screens/release-edit-distribution.screen";
import { MasterRightsListScreen } from "features/royalties/master-rights/screens/master-rights-list.screen";
import { UserAuctionListScreen } from "features/general/auctions/screens/user-auction-list.screen";
import { ArtistInsightsScreen } from "features/general/auctions/screens/artist-insights.screen";
// utility functions
import { translate } from "utils/helpers";
import { NavigationPanel } from "navigation/components/navigation-panel.component";

const Stack = createStackNavigator();

export const ReleaseListNavigator = ({ route, navigation }) => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name="AllReleases">
      {(props) => <ReleaseListScreen {...props} tabNavigation={navigation} />}
    </Stack.Screen>
    <Stack.Screen
      name="ReleaseCreateInfo"
      component={ReleaseCreateInfoScreen}
      options={{
        ...TransitionPresets.ModalTransition,
        presentation: "transparentModal",
      }}
    />
  </Stack.Navigator>
);

const AuctionListNavigator = ({ route, navigation }) => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name="AllDeals" component={UserAuctionListScreen} />
    <Stack.Screen
      name="ExistingReleaseList"
      component={ExistingReleaseListScreen}
      options={{
        ...TransitionPresets.ModalTransition,
        presentation: "transparentModal",
      }}
    />
  </Stack.Navigator>
);

const Tab = createMaterialTopTabNavigator();

export const ReleaseTopBarNavigator = ({ route, navigation }) => {
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();
  return (
    <>
      <NavigationPanel />
      <Tab.Navigator
        screenOptions={{
          swipeEnabled: false,
          animationEnabled: false,
          tabBarLabelStyle: {
            color: colors["darkText"],
            fontSize: 16,
            fontWeight: "semibold",
            textTransform: "none",
          },
          tabBarIndicatorStyle: {
            fontWeight: "semibold",
            backgroundColor: colors["darkText"],
          },
          tabBarStyle: {
            paddingTop: insets.top,
            backgroundColor: colors["gray"]["100"],
          },
        }}
      >
        <Tab.Screen
          name="ReleaseList"
          component={ReleaseListNavigator}
          options={{ title: translate("releases") }}
        />
        <Tab.Screen
          name="ReleaseDeals"
          component={AuctionListNavigator}
          options={{ title: "Deals" }}
        />
        <Tab.Screen
          name="ReleaseInsight"
          component={ArtistInsightsScreen}
          options={{ title: "Insights" }}
        />
        <Tab.Screen
          name="ReleaseSplits"
          component={MasterRightsListScreen}
          options={{ title: "Splits" }}
        />
      </Tab.Navigator>
    </>
  );
};

const ReleaseStack = createStackNavigator();

export const ReleaseNavigator = ({ route, navigation }) => (
  <ReleaseStack.Navigator
    initialRouteName={"ReleaseList"}
    screenOptions={{ headerShown: false }}
  >
    <ReleaseStack.Screen name="ReleaseDetail" component={ReleaseDetailScreen} />
    <ReleaseStack.Screen
      name="ReleaseEdit"
      component={ReleaseEditScreen}
      options={{ gestureEnabled: false }}
    />
    <ReleaseStack.Screen
      name="ReleaseEditCoverArt"
      component={ReleaseEditCoverArtScreen}
    />
    <ReleaseStack.Screen
      name="ReleaseEditTracks"
      component={ReleaseEditTracksScreen}
    />
    <ReleaseStack.Screen
      name="ReleaseEditUserTracks"
      component={ReleaseEditUserTracksScreen}
      options={{
        ...TransitionPresets.ModalTransition,
        presentation: "transparentModal",
      }}
    />
    <ReleaseStack.Screen
      name="ReleaseEditDistribution"
      component={ReleaseEditDistributionScreen}
    />
    <ReleaseStack.Screen
      name="ReleaseEditSummary"
      component={ReleaseEditSummaryScreen}
    />
    <ReleaseStack.Screen name="ReleaseList" component={ReleaseListNavigator} />
    <ReleaseStack.Screen name="ReleaseDeals" component={AuctionListNavigator} />
    <ReleaseStack.Screen
      name="ReleaseInsight"
      component={ArtistInsightsScreen}
    />
    <ReleaseStack.Screen
      name="ReleaseSplits"
      component={MasterRightsListScreen}
    />
  </ReleaseStack.Navigator>
);
