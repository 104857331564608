import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { VStack, Text, Image, HStack, Box } from "native-base";
// internal hooks
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";

export const LoginForm = () => {
  const navigation = useNavigation();
  const { control, handleSubmit } = useForm();
  const { onLoginOrRegister, isLoading } = useContext(AuthenticationContext);

  const fanLogin = (data) => {
    onLoginOrRegister(
      "+" + data.country_code + data.phone_number,
      "fan",
      navigation,
      true
    );
  };
  const artistLogin = (data) => {
    onLoginOrRegister(
      "+" + data.country_code + data.phone_number,
      "artist",
      navigation,
      true
    );
  };

  return (
    <VStack flex={1} space={7}>
      <VStack
        alignItems={["flex-start"]}
        justifyContent={["flex-start"]}
        space={7}
        flex={1}
      >
        <VStack space={3} alignItems={["flex-start"]}>
          <Text fontWeight={"light"} fontSize={["md", "lg"]}>
            {translate("login_subtitle")}
          </Text>
        </VStack>
        <HStack space={1} width="100%">
          <Box flex={2}>
            <FormControlInput
              size="lg"
              height="10"
              value={"49"}
              placeholder="49"
              control={control}
              name="country_code"
              keyboardType="phone-pad"
              rules={{
                required: true,
                minLength: 1,
                maxLength: 2,
              }}
              InputLeftElement={
                <Text pl={2} fontSize={["md", "lg"]}>
                  +
                </Text>
              }
            />
          </Box>
          <Box flex={7}>
            <FormControlInput
              size="lg"
              height="10"
              name="phone_number"
              keyboardType="phone-pad"
              placeholder="176 456 87 910"
              control={control}
              rules={{
                required: true,
                minLength: 10,
              }}
            />
          </Box>
        </HStack>
      </VStack>
      <VStack space="3" mb="3">
        <PrimaryButton
          text={translate("next")}
          onPress={handleSubmit(fanLogin)}
          disabled={isLoading}
          isLoading={isLoading}
        />
        {Platform.OS === "web" && (
          <SecondaryButton
            fontSize={{ sm: "md", lg: "lg" }}
            logoLeftComponent={
              <Image
                width={50}
                height={10}
                resizeMode="contain"
                alt="Button Logo"
                _light={{
                  src: require("assets/images/biddz-theme/biddz-for-artists-logo-black.png"),
                }}
                _dark={{
                  src: require("assets/images/biddz-theme/biddz-for-artists-logo-white.png"),
                }}
              />
            }
            space={{ base: 3, sm: 5, md: 10 }}
            isLoading={isLoading}
            disabled={isLoading}
            p={0}
            text={translate("continue_as_artist")}
            onPress={handleSubmit(artistLogin)}
          />
        )}
      </VStack>
    </VStack>
  );
};
