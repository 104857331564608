import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Box, Text, HStack, VStack, Icon, Stack, Pressable } from "native-base";
// internal components
import { ProfileProgress } from "common/components/layout/profile-progress.component";

const Header = (props) => {
  return (
    <HStack
      p="5"
      _web={{ pt: 5 }}
      space="2"
      alignItems="center"
      justifyContent="space-between"
      _dark={{ bg: props.bg ? props.bg : "darkBackground" }}
      _light={{ bg: props.bg ? props.bg : "lightBackground" }}
    >
      <Box>
        {props.headerLeft && (
          <Pressable
            p={3}
            bg={"gray.100"}
            rounded={"full"}
            onPress={props.headerLeft.onPress}
          >
            <Icon
              size={5}
              as={MaterialCommunityIcons}
              name={props.headerLeft.iconName}
              color={"darkText"}
            />
          </Pressable>
        )}
      </Box>
      <Box flex={1} alignItems="center">
        {props.title && (
          <Text
            bold
            noOfLines={1}
            fontSize="lg"
            _light={{ color: props.lightContent ? "lightText" : "darkText" }}
          >
            {props.title}
          </Text>
        )}
        {props.iconName && (
          <Icon as={MaterialCommunityIcons} name={props.iconName} size="xl" />
        )}
      </Box>
      <Box>
        {props.headerRight && !props.customHeaderRight && (
          <Pressable onPress={props.headerRight.onPress}>
            <Icon
              size="xl"
              as={MaterialCommunityIcons}
              name={props.headerRight.iconName}
              _light={{
                color: props.headerRight.color || "darkText",
              }}
              _dark={{
                color: props.headerRight.color || "lightText",
              }}
            />
          </Pressable>
        )}
        {props.customHeaderRight && props.headerRight && (
          <Stack>{props.headerRight}</Stack>
        )}
      </Box>
    </HStack>
  );
};

export const NavigationHeader = ({
  bg = null,
  title = null,
  iconName = null,
  headerLeft = null,
  headerRight = null,
  customHeaderRight = false,
  profileProgress = null,
  lightContent = false,
}) => (
  <VStack>
    <Header
      bg={bg}
      title={title}
      iconName={iconName}
      headerLeft={headerLeft}
      headerRight={headerRight}
      customHeaderRight={customHeaderRight}
      lightContent={lightContent}
    />
    {profileProgress && <ProfileProgress bg={bg} {...profileProgress} />}
  </VStack>
);
