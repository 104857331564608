import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateRelease = () => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createRelease: useMutation(
      (params = {}) => client.post("releases/", params).then((res) => res.data),
      {
        onSuccess: () => queryClient.invalidateQueries("releases"),
      }
    ),
    error: error,
  };
};

export default useCreateRelease;
