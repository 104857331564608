import React, { useState, useEffect } from "react";
import {
  Text,
  Image,
  VStack,
  HStack,
  Box,
  Icon,
  ScrollView,
  Modal,
  Pressable,
  Divider,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import dayjs from "dayjs";
// internal hooks
import { useTicketCollectibleQRCode } from "features/tickets/ticket-collectibles/hooks/useTicketCollectibleQRCode";
// internal functions
import { translate } from "utils/helpers";
// internal components
// import { Modal } from "theme/feedback/modal.component";
import { Carousel } from "theme/media/caraousel.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";

export const TicketCollectibleQRCodeButton = ({
  collectibles,
  showTicket = false,
  activeSlide = undefined,
  pressableChildren,
  pressableStyles = {},
}) => {
  const [isOpen, setIsOpen] = useState(showTicket);
  const [slideIndex, setSlideIndex] = useState(0);

  const selectedTicketId = collectibles?.[slideIndex]?.ticket?.id;

  const ticketCollectibleQRCode = useTicketCollectibleQRCode(
    selectedTicketId,
    collectibles?.[slideIndex]?.ticket?.has_access && isOpen
  );

  const ticketHasAccess = collectibles?.[slideIndex]?.ticket?.has_access;
  const event = collectibles?.[0]?.ticket?.event;
  const product = collectibles?.[0]?.product;
  const collectible = collectibles?.[0];

  useEffect(() => {
    if (activeSlide) setSlideIndex(activeSlide);
  }, [activeSlide]);

  return (
    <>
      <Pressable
        onPress={() => setIsOpen(true)}
        disabled={!event || !product || !collectible || !ticketHasAccess}
      >
        {pressableChildren}
      </Pressable>
      <Modal isOpen={isOpen} onClose={setIsOpen}>
        <Box mx={5} maxW={400} borderRadius={"2xl"} bg={"lightBackground"}>
          <HStack p={5} alignItems={"center"} justifyContent={"space-between"}>
            <Text fontSize={"2xl"} bold>
              {translate("my_tickets")}
            </Text>
            <Pressable p={2} onPress={() => setIsOpen(false)}>
              <Icon as={MaterialCommunityIcons} name={"close"} size={6} />
            </Pressable>
          </HStack>
          <Divider bg={"gray.300"} />
          <ScrollView maxH={600} p={5} showsVerticalScrollIndicator={false}>
            <VStack space={2}>
              <Text
                fontWeight={500}
                fontSize={["sm", "md"]}
                color={"secondary.600"}
              >
                {dayjs(event?.start_time).format("dddd, MMM D, YYYY, HH:mm")}
              </Text>
              <Text fontSize={["xl", "2xl"]} bold>
                {product?.title}
              </Text>
              <Text fontSize={["md", "lg"]}>{product?.subtitle}</Text>
              <HStack my={4} space={1} alignItems={"center"}>
                <Icon
                  size={5}
                  as={MaterialCommunityIcons}
                  name={"map-marker-outline"}
                />
                <Text fontSize={["md", "lg"]}>{event?.location}</Text>
              </HStack>
            </VStack>
            <VStack height={340}>
              {/* Change key to rerender carousel */}
              <Carousel
                key={ticketCollectibleQRCode.isFetched ? selectedTicketId : ""}
                from={slideIndex}
                onIndexChanged={(index: number) => setSlideIndex(index)}
              >
                {collectibles.map((c: any) => (
                  <VStack
                    key={c.id}
                    p={5}
                    mx={2}
                    space={3}
                    bg={"gray.100"}
                    borderRadius={"xl"}
                    position={"relative"}
                    alignItems={"center"}
                  >
                    {ticketCollectibleQRCode?.isLoading ? (
                      <Box size={200} justifyContent={"center"}>
                        <CenteredSpinner />
                      </Box>
                    ) : ticketCollectibleQRCode?.isError ? (
                      <VStack space={2} alignItems={"center"}>
                        <Icon
                          size={8}
                          color={"secondary.600"}
                          as={MaterialCommunityIcons}
                          name={"emoticon-sad-outline"}
                        />
                        <Text maxW={40} textAlign={"center"}>
                          {translate("ticket_error")}
                        </Text>
                      </VStack>
                    ) : (
                      <Image
                        width={200}
                        height={200}
                        alt={"QR Code"}
                        borderRadius={"xl"}
                        resizeMode={"contain"}
                        source={{ uri: ticketCollectibleQRCode?.data }}
                      />
                    )}
                    <HStack w={"full"} justifyContent={"center"}>
                      <Text
                        py={1}
                        px={3}
                        bg={"gray.300"}
                        fontSize={"2xs"}
                        borderRadius={"full"}
                      >{`${slideIndex + 1}/${collectibles?.length}`}</Text>
                    </HStack>
                  </VStack>
                ))}
              </Carousel>
            </VStack>
            <Text mt={2} fontSize={"xs"}>
              {product?.description}
            </Text>
          </ScrollView>
        </Box>
      </Modal>
    </>
  );
};
