import React, { useState, useRef } from "react";
import { Text, VStack, HStack, Image, FlatList } from "native-base";
import { compactInteger } from "humanize-plus";
import { isoCountry } from "iso-country";
// internal components
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { Swiper } from "common/components/layout/swiper.component";
// utility functions
import { translate } from "utils/helpers";

const logos = {
  amazonmp3: require("assets/images/brand-theme/amazonmp3.png"),
  apple: require("assets/images/brand-theme/apple.png"),
  deezer: require("assets/images/brand-theme/deezer.png"),
  junodownload: require("assets/images/brand-theme/junodownload.png"),
  napster: require("assets/images/brand-theme/napster.png"),
  pandora: require("assets/images/brand-theme/pandora.png"),
  shazam: require("assets/images/brand-theme/shazam.png"),
  spotify: require("assets/images/brand-theme/spotify.png"),
  tdc: require("assets/images/brand-theme/tdc.png"),
  tiktok: require("assets/images/brand-theme/tiktok-white.png"),
  youtubemusic: require("assets/images/brand-theme/youtubemusic.png"),
};

const SlideListItem = ({ title, count, image, isEmoji = false }: any) => (
  <HStack
    p="2"
    space="2"
    minH={50}
    justifyContent="space-between"
    alignItems="center"
    borderBottomWidth={1}
    borderBottomColor="dark.400"
  >
    <HStack space={3} alignItems="center">
      {isEmoji ? (
        <Text>{image}</Text>
      ) : image ? (
        <Image w="8" h="8" resizeMode="contain" source={image} alt="" />
      ) : null}
      <Text fontSize={["sm", "md"]}>{title}</Text>
    </HStack>
    <Text fontSize={["xs", "sm"]} color="dark.400">
      {compactInteger(count)} {translate("streams")}
    </Text>
  </HStack>
);

const Slide = ({ type, data }) => (
  <VStack
    flex={1}
    px="2"
    mb="6"
    _light={{ bg: "light.100" }}
    _dark={{ bg: "dark.900" }}
  >
    <HStack
      p="2"
      justifyContent="space-between"
      borderBottomWidth={1}
      borderBottomColor="light.600"
    >
      <Text fontSize={["md", "lg"]} color="light.600" bold>
        {translate(type)}
      </Text>
      <Text fontSize={["md", "lg"]} color="light.600" bold>
        {translate("streams")}
      </Text>
    </HStack>
    {data.length === 0 ? (
      <Text mt="20" textAlign="center">
        {translate("not_available")}
      </Text>
    ) : (
      <FlatList
        mb="2"
        data={data}
        nestedScrollEnabled
        showsVerticalScrollIndicator={false}
        renderItem={({ item }: any) => <SlideListItem {...item} />}
        keyExtractor={(item, i) => i.toString()}
      />
    )}
  </VStack>
);

export const TopChartsSwiper = ({ analytics, image = null }) => {
  const { top_songs, top_playlist, top_stores, country } = analytics;
  const title = ["top_songs", "top_playlist", "top_stores", "top_countries"];
  const type = ["tracks", "playlists", "stores", "countries"];

  const swiper = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <VStack
      px="5"
      pt="5"
      h={500}
      space="4"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      borderRadius="xl"
      borderWidth={1}
      borderColor="dark.800"
    >
      <VStack>
        <Text fontSize="2xl" bold>
          {translate(title[activeTab])}
        </Text>
        <Text fontSize={["md", "lg"]} color="primary.600">
          {translate("last_days", { days: 30 })}
        </Text>
      </VStack>
      <TabNavigator
        activeTab={activeTab}
        setActiveTab={(idx) => {
          swiper.current?.goTo(idx);
          setActiveTab(idx);
        }}
        options={type.map((t) => translate(t))}
      />
      <Swiper ref={swiper} onIndexChanged={(idx) => setActiveTab(idx)}>
        <Slide
          type={type[activeTab]}
          data={top_songs.map(({ value, count }) => ({
            title: value,
            count,
            image: !!image ? { uri: image } : null,
          }))}
        />
        <Slide
          type={type[activeTab]}
          data={top_playlist.map(({ value, count }) => ({
            title: value,
            count,
            image: !!image ? { uri: image } : null,
          }))}
        />
        <Slide
          type={type[activeTab]}
          data={top_stores.map(({ value, count }) => ({
            title: value,
            count,
            image: logos[value.toLowerCase()],
          }))}
        />
        <Slide
          type={type[activeTab]}
          data={country.map(({ value, count }) => {
            const { name, emoji } = isoCountry(value);
            return { title: name, count, image: emoji, isEmoji: true };
          })}
        />
      </Swiper>
    </VStack>
  );
};
