import React, { memo } from "react";
import { ITextProps } from "native-base";
import TextTicker from "react-native-text-ticker";
import { Easing, TextStyle } from "react-native";

interface Props extends ITextProps {
  text: string;
  style: TextStyle;
  maxLength: number;
  disabled?: boolean;
  overflow?: "visible" | "hidden";
}

export const RunningText = memo(
  ({
    style,
    text = "",
    maxLength = 20,
    disabled = false,
    overflow = "hidden",
    ...props
  }: Props) => {
    const duration = Math.min(500 * text?.length, 30000);
    return (
      <TextTicker
        {...props}
        loop
        style={style}
        bounce={false}
        marqueeDelay={0}
        repeatSpacer={20}
        disabled={disabled}
        duration={duration}
        easing={Easing.linear}
      >
        {text}
      </TextTicker>
    );
  }
);
