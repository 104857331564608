import React, { useEffect, useState } from "react";
import {
  Text,
  VStack,
  ScrollView,
  Divider,
  Stack,
  Checkbox,
} from "native-base";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
import useUpdateAuction from "features/general/auctions/hooks/useUpdateAuction";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ProfileSummary } from "features/accounts/profiles/components/profile-summary.component";
import { AuctionSummaryInfo } from "features/general/auctions/components/auction-summary-info.component";
import { AuctionSummaryAlbumDetail } from "features/general/auctions/components/auction-summary-album-detail.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";
import { Link } from "theme/typography/link.component";

const SummaryCheckbox = ({ name, checkboxDisabled = false, setDisabled }) => {
  return (
    <Checkbox
      p={0}
      isDisabled={checkboxDisabled}
      onChange={(isChecked) => setDisabled(!isChecked)}
      colorScheme="violet"
      value="confirm_ticket_transfer"
      accessibilityLabel="Checkbox"
      name={name}
    >
      <Link
        fontSize={["md", "lg"]}
        textBefore={translate("deal_policy_agreement_text_first")}
        text={translate("special_terms")}
        textAfter={translate("deal_policy_agreement_text_last")}
        url="https://storage.googleapis.com/app-public-europe-west3-1d3f335b/term-deal/20230707_term_deal_45cf7a7a-e6e8-44c6-ac84-46bd2bd1223e.pdf"
      />
    </Checkbox>
  );
};

export const AuctionOfferScreen = ({ navigation, route }) => {
  const [disabled, setDisabled] = useState(true);

  const { id: auctionID, auctionPartials = {} } = route.params;
  const { data: auction, isFetched } = useAuction(auctionID, auctionPartials);
  const product = auction?.products && auction.products[0];
  const release = product?.license_product?.release;

  const { updateAuction } = useUpdateAuction(auction?.id);

  const onPress = (val) => {
    updateAuction.mutate({ status: val });
  };

  useEffect(() => {
    updateAuction.isSuccess && navigateBack(navigation);
  }, [updateAuction.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        profileProgress={{ type: "deal", activeIndex: 2 }}
      />
      <PageTitle title={translate("summary")} />

      <VStack flex={1} justifyContent="space-between">
        {!isFetched ? (
          <Loading />
        ) : (
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <VStack py={10} px={5} space={10}>
              <Stack
                justifyContent={"space-between"}
                direction={{ base: "column", lg: "row" }}
                space={10}
              >
                <VStack space={10} flex={{ lg: 1 }}>
                  <AuctionSummaryAlbumDetail
                    release={release}
                    tracks={product?.license_product?.tracks}
                  />

                  <AuctionSummaryInfo
                    isAuctionOffer
                    auction={auction}
                    product={product}
                  />
                </VStack>

                <VStack flex={{ lg: 1 }} space={5}>
                  <Text fontSize={["md", "lg"]}>{translate("invoice")}</Text>
                  <Text ml="4" fontSize={["md", "lg"]} fontWeight="semibold">
                    {translate("invoice_description")}
                  </Text>
                  <ProfileSummary />
                  <Divider />
                  <SummaryCheckbox
                    name="agree_license"
                    setDisabled={setDisabled}
                  />
                </VStack>
              </Stack>
            </VStack>
          </ScrollView>
        )}

        <Stack
          mx={5}
          my={5}
          space={2}
          direction={{ base: "column", lg: "row" }}
          justifyContent={{ base: "center", lg: "flex-end" }}
        >
          <SecondaryButton
            width={{ lg: 300 }}
            disabled={disabled}
            onPress={() => onPress("rejected_offer")}
            text={translate("reject")}
          />
          <PrimaryButton
            width={{ lg: 300 }}
            justifyContent={{ base: "center", lg: "flex-end" }}
            disabled={updateAuction.isLoading || !isFetched || disabled}
            text={translate("accept")}
            onPress={() => onPress("accepted_offer")}
          />
        </Stack>
      </VStack>
    </ScreenFrame>
  );
};
