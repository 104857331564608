import React from "react";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";

import { TicketProductOrderCreateScreen } from "features/tickets/ticket-product-orders/screens/ticket-product-order-create.screen";
import { LicenseProductOrderCreateScreen } from "features/royalties/license-product-orders/screens/license-product-order-create.screen";
import { AuctionListScreen } from "features/general/auctions/screens/auction-list.screen";
import { AuctionDetailScreen } from "features/general/auctions/screens/auction-detail.screen";
import { AuctionEditFinancialsScreen } from "features/general/auctions/screens/auction-edit-financials.screen";
import { AuctionEditBenefitsScreen } from "./screens/auction-edit-benefits.screen";
import { AuctionEditCardScreen } from "./screens/auction-edit-card.screen";
import { AuctionEditSummaryScreen } from "features/general/auctions/screens/auction-edit-summary.screen";
import { AuctionEditInfoScreen } from "features/general/auctions/screens/auction-edit-info.screen";
import { AuctionEditPromotionalScreen } from "features/general/auctions/screens/auction-edit-promotional.screen";
import { AuctionCalculatorScreen } from "features/general/auctions/screens/auction-calculator.screen";
import { AuctionInfoScreen } from "./screens/auction-info.screen";
import { AuctionOfferScreen } from "./screens/auction-offer.screen";
import { TourAuctionListScreen } from "features/tickets/tours/screens/tour-auction-list.screen";

const Stack = createStackNavigator();

export const AuctionsNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="AuctionList" component={AuctionListScreen} />
      <Stack.Screen name="TourAuctionList" component={TourAuctionListScreen} />
      <Stack.Screen name="AuctionDetail" component={AuctionDetailScreen} />
      <Stack.Screen name="AuctionEditInfo" component={AuctionEditInfoScreen} />
      <Stack.Screen
        name="AuctionEditFinancials"
        component={AuctionEditFinancialsScreen}
      />
      <Stack.Screen
        name="AuctionEditBenefits"
        component={AuctionEditBenefitsScreen}
      />
      <Stack.Screen name="AuctionEditCard" component={AuctionEditCardScreen} />
      <Stack.Screen
        name="AuctionEditPromotional"
        component={AuctionEditPromotionalScreen}
      />
      <Stack.Screen
        name="AuctionEditSummary"
        component={AuctionEditSummaryScreen}
      />
      <Stack.Screen
        name="AuctionCalculator"
        component={AuctionCalculatorScreen}
      />
      <Stack.Screen name="AuctionInfo" component={AuctionInfoScreen} />
      <Stack.Screen
        name="OrderCreate"
        options={{
          ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
        component={TicketProductOrderCreateScreen}
      />

      <Stack.Screen
        name="LicenseProductOrderCreate"
        options={{
          // ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
        component={LicenseProductOrderCreateScreen}
      />
      <Stack.Screen name="AuctionOffer" component={AuctionOfferScreen} />
    </Stack.Navigator>
  );
};
