import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateAuction = (releaseID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createAuction: useMutation(
      (params = {}) =>
        client
          .post(`releases/${releaseID}/auctions/`, params)
          .then((res) => res.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`release-${releaseID}`);
          queryClient.invalidateQueries(`user-auctions`);
        },
      }
    ),
    error: error,
  };
};

export default useCreateAuction;
