import React from "react";
import Constants from "expo-constants";
import { RefreshControl } from "react-native";
import { ScrollView, VStack, Hidden, Box } from "native-base";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
import { useInvalidateAuction } from "features/general/auctions/hooks/useInvalidateAuction";
// utility functions
import { isBasicCollectible, isWeb } from "utils/helpers";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { LicenseProductDetail } from "features/royalties/license-products/components/license-product-detail.component";
import { TicketProductDetail } from "features/tickets/ticket-products/components/ticket-product-detail.component";
import { CollectibleDetail } from "features/general/collectibles/components/collectible-detail.component";
import { LicenseProductDetailSkeleton } from "features/royalties/license-products/skeletons/license-product-detail.skeleton";
import { LicenseProductOrderCreateButton } from "features/royalties/license-product-orders/components/license-product-order-create-button.component";

export const AuctionDetailScreen = ({ navigation, route }) => {
  const { id: auctionID } = route.params;
  const { data: auction, isFetched, isLoading } = useAuction(auctionID);
  const { invalidateAuction, isRefreshing } = useInvalidateAuction(auctionID);

  const isLicenseProduct = auction?.type === "single_license";
  const isDardan = auction?.id === Constants.expoConfig.extra.DARDAN_ID;

  return (
    <ScreenFrame safeAreaBottom={0}>
      <NavigationPanel goesBack={!isWeb} />
      <ScrollView
        height={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            tintColor={"white"}
            refreshing={isRefreshing}
            onRefresh={invalidateAuction}
          />
        }
      >
        {isLoading || !isFetched ? (
          <LicenseProductDetailSkeleton />
        ) : (
          <VStack>
            {isLicenseProduct && (
              <LicenseProductDetail
                auction={auction}
                auctionFetched={isFetched}
              />
            )}
            {auction?.type?.includes("ticket") && (
              <TicketProductDetail isDardan={isDardan} auction={auction} />
            )}
            {isBasicCollectible(auction?.type) && (
              <CollectibleDetail auction={auction} auctionFetched={isFetched} />
            )}
          </VStack>
        )}
      </ScrollView>
      {/*TODO: Remove this check after product is attached*/}
      {!isDardan && (
        <Hidden from={"lg"}>
          <Box safeAreaBottom>
            {isLicenseProduct && (
              <LicenseProductOrderCreateButton
                auction={auction}
                product={auction?.products[0]}
                isDisabled={!(auction?.is_active && isFetched)}
              />
            )}
          </Box>
        </Hidden>
      )}
    </ScreenFrame>
  );
};
