import React from "react";
import { Icon, VStack, Text } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal functions
import { translate } from "utils/helpers";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";

export const TransferDetailFail = ({ navigation }) => (
  <VStack flex={1}>
    <VStack flex={1} space={10} justifyContent="center" alignItems={"center"}>
      <Icon as={MaterialCommunityIcons} name="qrcode-remove" size={300} />
      <Text>
        Etwas ist schief gelaufen. Bitte kontaktiere support@biddz.io für Hilfe.
      </Text>
    </VStack>
    <PrimaryButton
      text={translate("next")}
      onPress={() => navigation.navigate("BottomNavigator")}
    />
  </VStack>
);
