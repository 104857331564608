import React from "react";
import { Pressable, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export const AppIconButtonWithOutline = ({
  onPress,
  name,
  as = MaterialCommunityIcons,
  disabled = false,
  size = "lg",
  p = 2,
  borderRadius = "xl",
  borderColor = "dark.800",
  iconColor = "darkText",
  ...props
}) => (
  <Pressable
    p={p}
    borderWidth={1}
    onPress={onPress}
    alignItems={"center"}
    isDisabled={disabled}
    justifyContent={"center"}
    borderRadius={borderRadius}
    borderColor={disabled ? "gray.200" : borderColor}
  >
    <Icon
      {...props}
      as={as}
      name={name}
      size={size}
      color={disabled ? "gray.200" : iconColor}
    />
  </Pressable>
);
