import React, { useEffect, useState } from "react";
import {
  VStack,
  Pressable,
  HStack,
  KeyboardAvoidingView,
  ScrollView,
  Divider,
  Icon,
  Text,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { Dimensions, Platform } from "react-native";
// internal
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
import { navigateBack, isBasicCollectible, translate } from "utils/helpers";
import { useAuction } from "features/general/auctions/hooks/useAuction";
import { TicketProductOrderCreateConfirmation } from "features/tickets/ticket-product-orders/components/ticket-product-order-create-confirmation.component";

export const TicketProductOrderCreateScreen = ({ navigation, route }) => {
  const windowHeight = Dimensions.get("window").height;
  const { auctionID, auctionInitialData, existingBasket } = route.params;
  const { data: auction } = useAuction(auctionID, auctionInitialData);

  const [totalPrice, setTotalPrice] = useState(0);
  const [isDelivery, setIsDelivery] = useState(false);
  const [basket, setBasket] = useState(existingBasket || {});

  const setBasketForProduct = (product, units, isAddOn = false) => {
    var newBasket = Object.assign({}, basket);

    if (units > 0) {
      newBasket[product.id] = {
        units: units,
        isAddOn: isAddOn,
        title: product?.title,
        add_ons: product?.add_ons,
        subtitle: product?.subtitle,
        pricePerUnit: product?.price_per_unit,
        totalPrice: units * product?.price_per_unit,
      };
    } else {
      delete newBasket[product.id];
    }
    setBasket(newBasket);
  };

  // sum all units in basket
  var totalUnits = 0;
  for (var productID in basket) {
    totalUnits += basket[productID]["units"];
  }

  const isCollectible = isBasicCollectible(auction?.type);
  const isFirstRouteInParent = useIsFirstRouteInParent();

  useEffect(() => {
    var totalPrice = 0;
    var anyIsDelivery = false;
    for (var productID in basket) {
      totalPrice += basket[productID]["totalPrice"];
      if (basket[productID]["isAddOn"]) {
        anyIsDelivery = true;
      }
    }
    setTotalPrice(totalPrice);
    setIsDelivery(anyIsDelivery);
  }, [basket]);

  useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.replace("Auctions", {
        screen: "AuctionDetail",
        params: { id: auctionID },
      });
    }
  }, []);

  return (
    <VStack flex={1}>
      <KeyboardAvoidingView
        flex={1}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <VStack
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"rgba(0, 0, 0, 0.2)"}
        >
          <Pressable size={"full"} onPress={() => navigateBack(navigation)} />
          <VStack
            mx={4}
            rounded={"2xl"}
            bg={"light.100"}
            alignSelf={"center"}
            position={"absolute"}
          >
            <HStack
              p={5}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"2xl"} bold>
                {translate("order_summary")}
              </Text>
              <Pressable p={2} onPress={() => navigateBack(navigation)}>
                <Icon as={Ionicons} name={"close-outline"} size={6} />
              </Pressable>
            </HStack>
            <Divider bg={"gray.300"} />
            <VStack p={5} maxW={450}>
              <ScrollView
                maxH={windowHeight * 0.6}
                showsVerticalScrollIndicator={false}
              >
                <TicketProductOrderCreateConfirmation
                  basket={basket}
                  auction={auction}
                  isDelivery={isDelivery}
                  totalPrice={totalPrice}
                  setIsDelivery={setIsDelivery}
                  isCollectible={isCollectible}
                  setBasketForProduct={setBasketForProduct}
                  infoSheetURL={
                    auction?.products &&
                    auction?.products[0]?.customer_info_sheet
                  }
                  musician={auction?.musicians?.find(
                    (item) => item.user_id === auction?.products[0]?.creator_id
                  )}
                />
              </ScrollView>
            </VStack>
          </VStack>
        </VStack>
      </KeyboardAvoidingView>
    </VStack>
  );
};
