import React, { useEffect, useState, useRef } from "react";
import * as Animatable from "react-native-animatable";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Icon, Pressable, ScrollView, VStack } from "native-base";
import { isWeb } from "utils/helpers";

export const ScrollViewWithToggle = ({
  mainChildren,
  toggleChildren,
  disabled = false,
  scrollToBenefits,
  layout,
}) => {
  const scrollViewRef = useRef();
  const [showDetails, setShowDetails] = useState(scrollToBenefits);
  const iconName = showDetails ? "chevron-up" : "chevron-down";

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    setTimeout(() => {
      scrollViewRef.current?.scrollTo({ y: 500, animated: true });
    }, 500);
  };

  useEffect(() => {
    if (scrollToBenefits && !isWeb) {
      setTimeout(() => {
        if (layout?.y) {
          scrollViewRef.current?.scrollTo({
            y: layout.y + 300,
            animated: true,
            behavior: "smooth",
          });
        }
      }, 800);
    }
  }, [layout]);

  useEffect(() => {
    if (scrollToBenefits && isWeb) {
      setTimeout(() => {
        var benefitView = document.getElementById("collectible-benefits");
        if (benefitView) benefitView?.scrollIntoView({ behavior: "smooth" });
      }, 800);
    }
  }, []);

  return (
    <ScrollView
      h={1}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      ref={scrollViewRef}
      contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
    >
      <VStack justifyContent={"space-between"} space={showDetails ? 8 : 20}>
        {mainChildren}
        <HStack justifyContent="center">
          <Pressable
            onPress={toggleDetails}
            isDisabled={disabled}
            _disabled={{ opacity: 0.3 }}
          >
            <Icon as={MaterialCommunityIcons} size={"16"} name={iconName} />
          </Pressable>
        </HStack>
        {showDetails && (
          <Animatable.View animation={"slideInUp"} width="100%">
            {toggleChildren}
          </Animatable.View>
        )}
      </VStack>
    </ScrollView>
  );
};
