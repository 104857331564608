import React from "react";
import { ImageBackground } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  FlatList,
  VStack,
  Flex,
  Text,
  HStack,
  useMediaQuery,
} from "native-base";
// utility functions
import { translate } from "utils/helpers";
// internal hooks
import { useReleases } from "features/royalties/releases/hooks/useReleases";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { AppIconButtonWithText } from "theme/buttons/app-icon-button-with-text.component";
import {
  ReleaseListCard,
  ExistingReleaseListCard,
} from "features/royalties/releases/components/release-list-card.component";
import { IconModal } from "theme/feedback/icon-modal.component";

const EmptyListCard = () => (
  <ImageBackground
    resizeMode={"stretch"}
    style={{ margin: 20 }}
    source={require("assets/images/features/no-deals.png")}
  >
    <Flex h={[135, 170]} justifyContent="center">
      <Text fontSize={["md", "lg"]} bold textAlign="center">
        {translate("no_releases")}
      </Text>
    </Flex>
  </ImageBackground>
);

const ListHeaderComponent = ({ releaseLength }) => {
  return (
    <HStack mb={5} justifyContent="space-between" alignItems="center">
      <Text fontSize={["md", "lg"]} fontWeight="bold">
        {`${releaseLength}`} Releases
      </Text>
      <IconModal
        size={6}
        iconColor="primary.600"
        icon={<AntDesign name="questioncircleo" />}
        contentText={translate("release_information_help_text")}
      />
    </HStack>
  );
};

export const ReleaseList = ({ existing = false }) => {
  const { data: releases, isFetching, isRefetching } = useReleases(existing);
  const { navigate } = useNavigation();
  const [isDesktop] = useMediaQuery({ minWidth: 1280 });

  return (
    <VStack flex={1} px="5" py={existing ? 0 : 5} space="5">
      {existing && (
        <Text fontWeight="bold" fontSize={["lg", "xl"]}>
          {translate("create_fan_deal")}
        </Text>
      )}
      {isFetching && !isRefetching ? (
        <Loading />
      ) : !existing ? (
        <FlatList
          numColumns={isDesktop ? 3 : 1}
          showsVerticalScrollIndicator={false}
          data={releases?.items?.filter((item) => item.is_delivered)}
          key={isDesktop ? "horizontal-collection" : "vertical-collection"}
          ListHeaderComponent={
            <ListHeaderComponent releaseLength={releases?.items?.length || 0} />
          }
          renderItem={({ item }) => (
            <ReleaseListCard
              release={item}
              onPress={() => {
                if (item?.status === "draft") {
                  navigate("Releases", {
                    screen: "ReleaseEdit",
                    params: { id: item.id, releasePartial: item },
                  });
                } else {
                  navigate("Releases", {
                    screen: "ReleaseDetail",
                    params: { id: item.id, releasePartial: item },
                  });
                }
              }}
            />
          )}
          ListEmptyComponent={<EmptyListCard />}
        />
      ) : (
        <FlatList
          data={releases?.items}
          numColumns={isDesktop ? 3 : 1}
          showsVerticalScrollIndicator={false}
          key={isDesktop ? "horizontal-collection" : "vertical-collection"}
          renderItem={({ item }) => <ExistingReleaseListCard release={item} />}
          ListEmptyComponent={<EmptyListCard />}
        />
      )}
    </VStack>
  );
};
