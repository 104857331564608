import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUpdateUserTopBadges = () => {
  const { client } = useContext(AxiosContext);
  return {
    updateUserTopBadges: useMutation(
      async (params) => {
        await client
          .patch(`me/profiles/top_badges/`, params)
          .then(async (resp) => {
            // wait for component list to update
            await new Promise((resolve) => setTimeout(resolve, 100));
            resp.data;
          });
      },
      {
        onSuccess: () => console.debug(`🏅 Updated User Top Badges`),
      }
    ),
  };
};
