import React, { useState } from "react";
import {
  Image,
  VStack,
  Text,
  HStack,
  Pressable,
  ScrollView,
  Icon,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal
import { getBenefitDescriptionParsed, getBenefitTitleParsed, translate } from "utils/helpers";
import {
  ClaimLicenseBenefitModal,
  IgnoreLicenseBenefitModal,
} from "features/royalties/license-benefits/components/license-benefit-modal.component";

export const LicenseDetailBenefitListCard = ({
  benefit,
  benefitType,
  collectibleBenefits,
  isBasicCollectible = false,
}) => {
  const [openClaim, setOpenClaim] = useState(false);
  const [openIgnore, setOpenIgnore] = useState(false);

  return (
    <VStack
      m={4}
      ml={1}
      w={250}
      flex={1}
      space={2}
      maxW={250}
      shadow={2}
      bg={"light.100"}
      borderRadius={"xl"}
      overflow={"hidden"}
      _ios={{ minH: 230, overflow: "none" }}
    >
      <HStack py={3} px={5} bg={"dark.900"} _ios={{ borderTopRadius: "xl" }}>
        <Image
          size={25}
          alt={"biddz-logo"}
          resizeMode={"contain"}
          source={require("assets/images/biddz-theme/biddz-logo-white.png")}
        />
        <Text
          flex={1}
          fontWeight={900}
          color={"lightText"}
          textAlign={"center"}
          fontSize={["md", "lg"]}
          textTransform={"uppercase"}
        >
          {isBasicCollectible ? translate("benefit_title") : translate(benefit?.tier)}
        </Text>
      </HStack>
      <HStack px={5}>
        <VStack space={1}>
          <Text fontSize={["sm", "md"]} numberOfLines={1}>
            <Text fontWeight="bold" fontSize={["sm", "md"]}>
              {benefit?.min_units ? benefit?.min_units : 1}+
            </Text>{" "}
            {isBasicCollectible ? `Collectibles` : `biddz`}
          </Text>
        </VStack>
      </HStack>
      <VStack flex={1} px={5} pb={5} space={2}>
        <ScrollView maxH={180} showsVerticalScrollIndicator={false}>
          <Text bold fontSize={["md", "lg"]}>
            {getBenefitTitleParsed(collectibleBenefits ? benefit?.benefit : benefit)}
          </Text>
          <Text fontSize={["sm", "md"]}>
            {getBenefitDescriptionParsed(collectibleBenefits ? benefit?.benefit : benefit)}
          </Text>
          {benefit?.image_with_url?.md?.url && (
            <Image
              height={180}
              width={"auto"}
              alt={"benefit-image"}
              resizeMode={"contain"}
              source={{ uri: benefit.image_with_url.md.url }}
            />
          )}
        </ScrollView>
      </VStack>
      {collectibleBenefits && (
        <VStack pt={2} px={5} space={2}>
          {benefit?.claimed_at ? (
            <Pressable
              p={2}
              mb={3}
              flex={1}
              rounded={"xl"}
              bg={"gray.100"}
              alignItems={"center"}
              onPress={() => setOpenClaim(true)}
            >
              <Text fontWeight={500} color={"gray.500"} selectable={false}>
                Claimed
              </Text>
            </Pressable>
          ) : (
            <>
              <Pressable
                p={2}
                flex={1}
                rounded={"xl"}
                bg={"dark.900"}
                alignItems={"center"}
                onPress={() => setOpenClaim(true)}
              >
                <HStack space={2} alignItems={"center"}>
                  <Text color={"lightText"}>{translate("redeem")}</Text>
                  <Icon
                    size={4}
                    color={"secondary.300"}
                    name={"arrow-top-right"}
                    as={MaterialCommunityIcons}
                  />
                </HStack>
              </Pressable>
              <Pressable
                p={2}
                flex={1}
                rounded={"xl"}
                bg={"gray.200"}
                alignItems={"center"}
                onPress={() => setOpenIgnore(true)}
              >
                <Text>{translate("no_thanks")}</Text>
              </Pressable>
              <IgnoreLicenseBenefitModal
                benefit={benefit}
                isOpen={openIgnore}
                setIsOpen={setOpenIgnore}
              />
            </>
          )}
          <ClaimLicenseBenefitModal
            benefit={benefit}
            isOpen={openClaim}
            setIsOpen={setOpenClaim}
            benefitType={benefitType}
          />
        </VStack>
      )}
    </VStack>
  );
};
