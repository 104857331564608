import React from "react";
import { useForm } from "react-hook-form";
import { VStack, ScrollView, Text } from "native-base";
// internal hooks
import { useUserAuction } from "features/general/auctions/hooks/useUserAuction";
import useCreateAuctionBenefit from "features/general/auctions/hooks/useCreateAuctionBenefit";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import {
  AuctionEditBenefitCard,
  AuctionEditBenefitGoldenCard,
} from "features/general/auctions/components/auction-edit-benefit-card.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";
import { useAuctionBenefits } from "../hooks/useAuctionBenefits";

export const AuctionEditBenefitsScreen = ({ navigation, route }) => {
  const { id: auctionID, auctionPartials = {} } = route.params;
  const { control, handleSubmit } = useForm();
  const { data: auction, isFetched } = useUserAuction(
    auctionID,
    auctionPartials
  );
  const product = auction?.products && auction?.products[0];
  const { data: auctionBenefits, isSuccess: auctionBenefitsSuccess } =
    useAuctionBenefits(product?.id);
  const { createAuctionBenefit } = useCreateAuctionBenefit(
    product?.id,
    auctionID
  );

  const min_units_set = auctionBenefits?.map((item) => item.min_units);

  const navigateForward = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditCard",
      params: { id: auctionID, auctionPartials: auction },
    });
  };

  const getBenefits = (data) => {
    let arr = [];
    if (data.gold && !min_units_set.includes(1)) {
      const gold = {
        type: data.gold_benefits,
        min_units: 1,
        artist_input: data.gold_benefit_artist_input,
      };
      arr = [...arr, gold];
    }
    if (data.platinum && !min_units_set.includes(10)) {
      const platinum = {
        type: data.platinum_benefits,
        min_units: 10,
        artist_input: data.platinum_benefit_artist_input,
      };
      arr = [...arr, platinum];
    }
    if (data.diamond && !min_units_set.includes(100)) {
      const diamond = {
        type: data.diamond_benefits,
        min_units: 100,
        artist_input: data.diamond_benefit_artist_input,
      };
      arr = [...arr, diamond];
    }
    return arr;
  };

  const onPress = async (data) => {
    const benefits = getBenefits(data);
    if (
      !["draft", "action_required"].includes(auction?.status) ||
      benefits.length === 0
    )
      navigateForward();
    else {
      await createAuctionBenefit.mutateAsync(benefits);
      navigateForward();
    }
  };

  const onSave = (data) => {
    const benefits = getBenefits(data);
    if (
      ["draft", "action_required"].includes(auction?.status) &&
      benefits.length > 0
    )
      createAuctionBenefit.mutate(benefits);
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: {
                screen: "ReleaseDeals",
                params: { screen: "ReleaseDeals" },
              },
            }),
        }}
        profileProgress={{ type: "deal", activeIndex: 2 }}
      />
      <VStack flex={1}>
        <ScrollView
          mt={{ base: 0, lg: 10 }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <PageTitle
            nativeID="save-deal-fan-benefits"
            isLoading={createAuctionBenefit.isLoading || !isFetched}
            title={translate("fan_benefits")}
            helpText={translate("fan_benefits_help_text")}
            onSave={handleSubmit(onSave)}
          />
          {!isFetched ? (
            <Loading />
          ) : (
            <VStack pb="10" space="10">
              <VStack
                p="5"
                space="5"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <Text fontSize={["md", "lg"]}>
                  {translate("fan_benefits_title")}
                </Text>
                {auctionBenefitsSuccess && (
                  <>
                    <AuctionEditBenefitCard
                      infoText={translate("gold_benefit_info_text")}
                      disableCheckbox={
                        !["draft", "action_required"].includes(auction?.status)
                      }
                      disabled={
                        !["draft", "action_required"].includes(
                          auction?.status
                        ) || min_units_set.includes(1)
                      }
                      control={control}
                      tier="gold"
                      title="Gold Tier"
                      biddz="1-9 biddz"
                      benefit={auctionBenefits?.find(
                        (item) => item.min_units === 1
                      )}
                    />
                    <AuctionEditBenefitCard
                      infoText={translate("platinum_benefit_info_text")}
                      disableCheckbox={
                        !["draft", "action_required"].includes(auction?.status)
                      }
                      disabled={
                        !["draft", "action_required"].includes(
                          auction?.status
                        ) || min_units_set.includes(10)
                      }
                      control={control}
                      tier="platinum"
                      title="Platinum Tier"
                      biddz="10-99 biddz"
                      benefit={auctionBenefits?.find(
                        (item) => item.min_units === 10
                      )}
                    />
                    <AuctionEditBenefitCard
                      infoText={translate("diamond_benefit_info_text")}
                      disableCheckbox={
                        !["draft", "action_required"].includes(auction?.status)
                      }
                      disabled={
                        !["draft", "action_required"].includes(
                          auction?.status
                        ) || min_units_set.includes(100)
                      }
                      control={control}
                      tier="diamond"
                      title="Diamond Tier"
                      biddz="100+ biddz"
                      benefit={auctionBenefits?.find(
                        (item) => item.min_units === 100
                      )}
                    />
                  </>
                )}
              </VStack>

              {/* <AuctionEditBenefitGoldenCard control={control} /> */}
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        showNextIcon
        disabled={createAuctionBenefit.isLoading || !isFetched}
        isLoading={createAuctionBenefit.isLoading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
