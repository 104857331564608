import React, { useContext } from "react";
import * as MailComposer from "expo-mail-composer";
import { openBrowserAsync } from "expo-web-browser";
import { useMediaQuery, VStack, ScrollView, Box, Toast } from "native-base";
// utility functions
import { translate, isWeb } from "utils/helpers";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { useUserEvents } from "features/accounts/profiles/hooks/useUserEvents";
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
// internal components
import { Alert } from "theme/feedback/alert.component";
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NewButton } from "theme/buttons/new-button.component";
import { UserLogoutButton } from "features/accounts/users/components/user-logout-button.component";
import { Footer } from "navigation/components/footer.component";
import { AuthenticationContext } from "services/authentication.context";
import { DeleteAccountButton } from "features/accounts/users/components/delete-account-button.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";

const textStyles = {
  fontSize: ["md", "lg", "xl"],
  fontWeight: "semibold",
};

export const AccountDetailScreen = ({ navigation }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const profile = useUserProfile();
  const events = useUserEvents({ isValidate: true });

  const bankAccount = usePaymentMethods(
    "bank_account",
    !!profile.data?.is_complete || !!profile.data?.is_artist_complete
  );
  const { onLogout, setRemoteURL } = useContext(AuthenticationContext);

  return (
    <ScreenFrame showFooter isAuthenticationRequired>
      <NavigationPanel />
      <ScrollView
        px={isDesktop ? 24 : 0}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <VStack>
          {!profile.data?.is_complete && profile.isFetched && (
            <Alert
              title={translate("action_required")}
              text={translate("complete_personal_information_alert_message")}
              buttonText={translate("complete_information")}
              buttonOnPress={() => {
                navigation.navigate("Profiles", {
                  screen: "ProfileEditPersonalData",
                  params: { id: profile.data?.id },
                });
              }}
            />
          )}
          {profile.isFetched &&
            profile.data?.type === "artist" &&
            (!profile.data?.is_artist_payout_complete ||
              bankAccount.data?.length == 0) && (
              <Alert
                title={translate("action_required")}
                text={translate("complete_payout_information_alert_message")}
                buttonText={translate("complete_information")}
                buttonOnPress={() => {
                  navigation.push("Musicians", {
                    screen: "MusicianEditPayouts",
                  });
                }}
              />
            )}
          {events.isSuccess && !isWeb && events.data?.length > 0 && (
            <NewButton
              textStyles={textStyles}
              iconName={"ticket"}
              text={translate("events")}
              onPress={() => {
                navigation.navigate("TicketCollectibles", {
                  screen: "TicketCollectibleDetailValidate",
                });
              }}
            />
          )}
          <NewButton
            textStyles={textStyles}
            iconName={"account"}
            text={translate("personal_data")}
            onPress={() => {
              navigation.navigate("Profiles", {
                screen: "ProfileEditPersonalData",
                params: { id: profile?.data?.id },
              });
            }}
          />
          {!isDesktop && (
            <NewButton
              textStyles={textStyles}
              iconName={"pencil"}
              text={translate("edit_profile")}
              onPress={() => {
                navigation.navigate("Profiles", {
                  screen: "ProfileEdit",
                  params: { id: profile?.data?.id },
                });
              }}
            />
          )}
          <NewButton
            textStyles={textStyles}
            iconName={"swap-horizontal"}
            text={translate("transactions")}
            onPress={() => {
              navigation.navigate("Account", { screen: "HistoryList" });
            }}
          />
          {profile.data?.type === "artist" && (
            <NewButton
              textStyles={textStyles}
              text={translate("artist_payout")}
              iconName="hand-coin"
              onPress={() => {
                if (profile.data?.is_artist_complete) {
                  navigation.navigate("Musicians", {
                    screen: "MusicianEditPayouts",
                  });
                } else {
                  navigation.navigate("Musicians", {
                    screen: "MusicianEditPersonalData",
                  });
                }
              }}
            />
          )}
          <NewButton
            textStyles={textStyles}
            text={translate("payment_methods")}
            iconName="credit-card-outline"
            onPress={() => {
              if (profile.data?.is_complete) {
                navigation.navigate("PaymentMethods", {
                  screen: "PaymentMethodList",
                });
              } else {
                navigation.navigate("Profiles", {
                  screen: "ProfileEditPersonalData",
                  params: { id: profile?.data?.id },
                });
              }
            }}
          />

          {isWeb && (
            <NewButton
              textStyles={{
                fontSize: ["md", "lg", "xl"],
                fontWeight: "semibold",
              }}
              text={translate("payouts_history")}
              iconName={"bank-transfer-in"}
              onPress={() => {
                if (profile.data?.is_complete) {
                  navigation.navigate("PaymentMethods", {
                    screen: "PaymentMethodPayouts",
                  });
                } else {
                  navigation.navigate("Profiles", {
                    screen: "ProfileEditPersonalData",
                    params: { id: profile?.data?.id },
                  });
                }
              }}
            />
          )}

          <NewButton
            textStyles={textStyles}
            text={translate("language")}
            iconName="earth"
            onPress={() => {
              navigation.navigate("Account", { screen: "LanguageSelection" });
            }}
          />
          <NewButton
            textStyles={textStyles}
            iconName="gavel"
            text={translate("legal")}
            onPress={() => {
              navigation.navigate("Account", {
                screen: "LegalList",
              });
            }}
          />
          <NewButton
            textStyles={textStyles}
            iconName="newspaper-variant"
            text={translate("impressum")}
            onPress={async () =>
              openBrowserAsync("https://www.biddz.io/de/imprint")
            }
          />
          <NewButton
            textStyles={textStyles}
            text={translate("support")}
            iconName="help-circle-outline"
            onPress={async () => {
              const isAvailable = await MailComposer.isAvailableAsync();
              if (isAvailable) {
                await MailComposer.composeAsync({
                  recipients: ["support@biddz.io"],
                });
              } else {
                Toast.show({
                  placement: "top",
                  render: ({ id }) => (
                    <ToastAlert
                      id={id}
                      closable
                      status={"error"}
                      description={translate("email_not_available")}
                    />
                  ),
                });
              }
            }}
          />
          <VStack p={5} space={10}>
            <UserLogoutButton onLogout={onLogout} />
            <DeleteAccountButton />
          </VStack>
        </VStack>
        <Box mx={isDesktop ? -24 : 0}>
          <Footer />
        </Box>
      </ScrollView>
    </ScreenFrame>
  );
};
