import React from "react";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
// internal components
import { TicketCollectibleDetailValidateResponseScreen } from "./screens/ticket-collectible-detail-validate-response.screen";
import { TicketCollectibleDetailValidateScreen } from "./screens/ticket-collectible-detail-validate.screen";
import { TicketCollectibleDetailScreen } from "features/tickets/ticket-collectibles/screens/ticket-collectible-detail.screen";
import { TicketCollectibleDetailMintScreen } from "features/tickets/ticket-collectibles/screens/ticket-collectible-detail-mint.screen";
import { TransferCreateScreen } from "features/general/transfers/screens/transfer-create.screen";

const Stack = createStackNavigator();

export const TicketCollectiblesNavigator = ({ route, navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="TicketCollectibleDetail"
        component={TicketCollectibleDetailScreen}
      />
      <Stack.Group
        screenOptions={{
          ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
      >
        <Stack.Screen
          name="TicketCollectibleDetailTransferCreate"
          component={TransferCreateScreen}
        />
        <Stack.Screen
          name="TicketCollectibleDetailMint"
          component={TicketCollectibleDetailMintScreen}
        />
        <Stack.Screen
          name="TicketCollectibleDetailValidate"
          component={TicketCollectibleDetailValidateScreen}
        />
        <Stack.Screen
          name="TicketCollectibleDetailValidateResponse"
          component={TicketCollectibleDetailValidateResponseScreen}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};
// Jonas was here and he is a ping pong king
