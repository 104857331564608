import React, { useState } from "react";
import { VStack, Image } from "native-base";
import * as Animatable from "react-native-animatable";
// internal functions
import { getWindowWidth } from "utils/helpers";

export const RevealCardComponent = ({ cardImageURL }) => {
  const width = getWindowWidth() / 1.5;
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <VStack justifyContent={"center"} alignItems="center" flex={1}>
      <Image
        right={"20%"}
        width={width}
        height={width}
        top={["20%", "40%"]}
        resizeMode="contain"
        position={"absolute"}
        alt="Decorative Lights"
        source={require("assets/images/features/common-light-lights.png")}
      />
      <Image
        left={"20%"}
        width={width}
        height={width}
        resizeMode="contain"
        position={"absolute"}
        alt="Decorative Lights"
        bottom={["20%", "40%"]}
        source={require("assets/images/features/common-light-lights.png")}
      />
      <Image
        source={{ uri: cardImageURL }}
        width={0}
        height={0}
        onLoadEnd={() => setImageLoaded(true)}
        alt="Card image"
      />
      <Animatable.View
        animation="bounceInDown"
        duration={5000}
        flex={1}
        style={{ display: imageLoaded ? null : "hidden" }}
        justifyContent="center"
        alignItems="center"
      >
        <VStack justifyContent="center" alignItems="center" flex={1}>
          <Image
            source={{ uri: cardImageURL }}
            width={width}
            height={width}
            alt="Card image"
          />
        </VStack>
      </Animatable.View>
    </VStack>
  );
};
