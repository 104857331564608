import React, { useEffect, useState } from "react";
import {
  FlatList,
  Text,
  VStack,
  HStack,
  Box,
  Pressable,
  ScrollView,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal
import { getAuctionListFilterTitle, translate } from "utils/helpers";
import { useAuctions } from "features/general/auctions/hooks/useAuctions";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { AuctionCardCompact } from "features/general/auctions/components/auction-list-card-compact.component";
import { AuctionListCardLicense } from "features/general/auctions/components/auction-list-card-license.component";
import { ListEmptyComponent } from "common/components/layout/list-empty.component";

export const MusicianDetailAuctionList = ({ auctions, header }) => {
  const navigation = useNavigation();
  return (
    auctions?.length > 0 && (
      <VStack>
        <Text bold p={5} fontSize={["xl", "2xl"]}>
          {header}
        </Text>
        <HStack ml={5} justifyContent="center">
          <FlatList
            data={auctions}
            horizontal={true}
            initialNumToRender={3}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <AuctionCardCompact
                key={item.id}
                showBookmark={false}
                auction={item}
                onPress={() => {
                  navigation.push("Auctions", {
                    screen: "AuctionDetail",
                    params: { id: item.id, initialData: item },
                  });
                }}
              />
            )}
          />
        </HStack>
      </VStack>
    )
  );
};

export const MusicianDetaiLicenseProductsList = ({ musician }) => {
  const musicianLicenses = musician?.auctions?.filter((item) =>
    item.type.includes("license")
  );

  const filters = ["all", "is_active", "is_upcoming", "is_over"];
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    musician_id: musician?.id,
    category: "license",
  });
  const [filter, setFilter] = useState(filters[0]);

  const navigation = useNavigation();
  const licenses = useAuctions({ id: musician?.id, filter: params }, params);

  useEffect(() => {
    const pages = licenses?.data?.pages?.map((page) => page.items).flat();
    setData(pages);
  }, [licenses?.data?.pages]);

  useEffect(() => {
    const filteredParams = params;
    filteredParams[filter] = true;
    setParams(filteredParams);
  }, [filter]);

  return (
    musicianLicenses?.length > 0 && (
      <VStack p={5}>
        <Text fontSize={"2xl"} fontWeight={500}>
          {translate("auctions")}
        </Text>
        <Text fontSize={"sm"} color={"gray.400"}>
          {`${data?.length || 0} ${
            data?.length === 1 ? translate("auction") : translate("auctions")
          }`}
        </Text>
        <HStack my={5} space={3}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {filters.map((item, index) => {
              const selected = filter === item;
              return (
                <Pressable
                  mr={3}
                  py={3}
                  px={6}
                  key={index}
                  rounded={"full"}
                  bg={selected ? "secondary.300" : "gray.100"}
                  onPress={() => {
                    delete params[filter];
                    selected ? undefined : setFilter(item);
                  }}
                >
                  <Text fontWeight={500}>
                    {getAuctionListFilterTitle(item)}
                  </Text>
                </Pressable>
              );
            })}
          </ScrollView>
        </HStack>
        <FlatList
          data={data}
          horizontal={true}
          initialNumToRender={4}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <Box mr={5}>
              <AuctionListCardLicense
                key={item.id}
                showBookmark={false}
                auction={item}
                onPress={() => {
                  navigation.push("Auctions", {
                    screen: "AuctionDetail",
                    params: { id: item.id, initialData: item },
                  });
                }}
              />
            </Box>
          )}
          onEndReached={() =>
            licenses.hasNextPage && !licenses.isFetching
              ? licenses.fetchNextPage()
              : null
          }
          ListEmptyComponent={
            <Box size={300} justifyContent={"center"} alignItems={"center"}>
              {licenses?.isLoading ? (
                <CenteredSpinner />
              ) : (
                <ListEmptyComponent text={translate("no_results")} />
              )}
            </Box>
          }
        />
      </VStack>
    )
  );
};
