import React, { useEffect } from "react";
import { VStack } from "native-base";
import { useController } from "react-hook-form";
// internal components
import { FormControlInput } from "theme/forms/form-control-input.component";
// helper functions
import { translate } from "utils/helpers";

interface IProps {
  auction: any;
  control: any;
}

export const AuctionEditFinancialForm = ({ auction, control }: IProps) => {
  const product = auction?.products[0];
  const { field: units } = useController({ control, name: "units" });
  const { field: maxUnits } = useController({
    control,
    name: "max_units_per_buyer",
  });

  useEffect(() => {
    if (!!units.value) {
      maxUnits.onChange(Math.round(Number(units.value) / 2));
    } else {
      maxUnits.onChange(0);
    }
  }, [units.value]);

  useEffect(() => {
    if (!!product?.units) {
      units.onChange(product.units);
      maxUnits.onChange(Math.round(Number(product.units) / 2));
    }
  }, []);

  const isAuctionEditable = !["draft", "action_required"].includes(
    auction?.status
  );

  return (
    <VStack
      p="5"
      space="5"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      <FormControlInput
        control={control}
        isDisabled={isAuctionEditable}
        keyboardType="numeric"
        name="expected_total_streams"
        label={translate("expected_streams_label")}
        rules={{ required: true, pattern: /^\d+$/ }}
        placeholder="z.B. 250000"
        value={product?.license_product?.expected_total_streams?.toString()}
        infoText={translate("expected_streams_info")}
      />
      <FormControlInput
        control={control}
        isDisabled={isAuctionEditable}
        keyboardType="numeric"
        name="units"
        label={translate("share_offered")}
        rules={{
          required: true,
          min: 500,
          max: 8000,
          maxLength: 4,
          pattern: /^\d+$/,
        }}
        placeholder={"500 - 8000"}
        value={product?.units}
        infoText={translate("share_offered_info")}
      />
      <FormControlInput
        control={control}
        isDisabled={isAuctionEditable}
        keyboardType="numeric"
        name="max_units_per_buyer"
        label={translate("max_share_per_fan")}
        rules={{ required: true, maxLength: 4, pattern: /^\d+$/ }}
        placeholder={translate("max_share_per_fan_placeholder")}
        value={product?.max_units_per_buyer || 0}
        infoText={translate("max_share_per_fan_info")}
        helperText={translate("max_share_per_fan_help_text")}
      />
    </VStack>
  );
};
