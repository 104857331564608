import React from "react";
import { compactInteger } from "humanize-plus";
import {
  Text,
  Pressable,
  Avatar,
  HStack,
  VStack,
  Box,
  Icon,
  FlatList,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal components
import { Loading } from "common/components/loading/loading.component";
// utility functions
import { translate } from "utils/helpers";

export const SpotifyArtistInfoCard = ({
  artist,
  selectedArtist,
  setSelectedArtist,
}) => {
  const { spotify_id, apple_id, image, alias, followers } = artist;
  const isSelected =
    (!!spotify_id && spotify_id === selectedArtist?.spotify_id) ||
    (!!apple_id && apple_id === selectedArtist?.apple_id);

  const selectArtist = () => {
    if (!isSelected) {
      setSelectedArtist(artist);
    } else {
      setSelectedArtist(null);
    }
  };

  return (
    <Pressable p="2" onPress={selectArtist}>
      <HStack justifyContent="space-between" alignItems="center">
        <HStack space="5" alignItems="center">
          <Box>
            {image && image?.sm?.url ? (
              <Avatar source={{ uri: image?.sm?.url }} size="md" />
            ) : (
              <Avatar icon="person" size="md" />
            )}
          </Box>
          <VStack space="1">
            <Text bold>{alias}</Text>
            {!!spotify_id && !apple_id && (
              <Text fontSize={["md", "lg"]}>
                {translate("followers_count", {
                  count: compactInteger(followers),
                })}
              </Text>
            )}
          </VStack>
        </HStack>
        <Box>
          {isSelected && (
            <Icon
              as={MaterialCommunityIcons}
              name="check-circle-outline"
              size="lg"
              _light={{ color: "darkText" }}
              _dark={{ color: "lightText" }}
            />
          )}
        </Box>
      </HStack>
    </Pressable>
  );
};

export const ArtistList = ({
  data,
  isLoading,
  selectedArtist,
  setSelectedArtist,
}) =>
  isLoading ? (
    <Loading />
  ) : (
    <FlatList
      p="5"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      data={data}
      style={{ maxHeight: 300 }}
      showsVerticalScrollIndicator={false}
      renderItem={({ item }) => (
        <SpotifyArtistInfoCard
          artist={item}
          selectedArtist={selectedArtist}
          setSelectedArtist={setSelectedArtist}
        />
      )}
    />
  );
