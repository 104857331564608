import React from "react";
import { VStack, HStack, Pressable, Text, Image } from "native-base";
import dayjs from "dayjs";
import { useNavigation } from "@react-navigation/native";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { AppIcon } from "theme/media/app-icon.component";
// utility functions
import { translate } from "utils/helpers";

const statuses = {
  draft: {
    icon: <AppIcon name="plus-circle-outline" />,
    title: "draft",
    color: undefined,
  },
  under_review: {
    icon: <AppIcon name="dots-horizontal-circle-outline" />,
    title: "under_review",
    color: "primary.600",
  },
  action_required: {
    icon: <AppIcon name="alert-outline" />,
    title: "action_required",
    color: "primary.600",
  },
  offer: {
    icon: <AppIcon name="alert-outline" />,
    title: "action_required",
    color: "secondary.900",
  },
  accepted_offer: {
    icon: <AppIcon name="check-circle-outline" />,
    title: "upcoming",
    color: "primary.900",
  },
  rejected_offer: {
    icon: <AppIcon name="close-circle-outline" />,
    title: "cancelled",
    color: "error.900",
  },
  rejected: {
    icon: <AppIcon name="close-circle-outline" />,
    title: "cancelled",
    color: "error.900",
  },
};

export const DealCard = ({ item }) => {
  const navigation = useNavigation();
  const isSingleLicense = item?.type === "single_license";
  const isTicket = ["single_ticket", "multi_ticket"].includes(item?.type);

  const onPress = () => {
    if (isSingleLicense) {
      if (["draft", "action_required"].includes(item?.status)) {
        navigation.navigate("Auctions", {
          screen: "AuctionEditInfo",
          params: { id: item.id, auctionPartials: item },
        });
      } else {
        navigation.navigate("Auctions", {
          screen: "AuctionInfo",
          params: { id: item.id, initialData: item },
        });
      }
    } else if (isTicket && item?.status === "accepted_offer") {
      navigation.navigate("Auctions", {
        screen: "AuctionInfo",
        params: { id: item.id, initialData: item },
      });
    }
  };

  const TITLE =
    item?.status === "accepted_offer"
      ? item?.is_upcoming
        ? "upcoming"
        : item?.is_active
        ? "active"
        : "over"
      : statuses[item?.status]?.title;

  return (
    <Pressable flex={1} my="3" mx={{ lg: "3" }} onPress={onPress}>
      <VStack
        p="4"
        space="3"
        borderRadius="xl"
        _dark={{ backgroundColor: "dark.900" }}
        _light={{ backgroundColor: "light.100" }}
      >
        <HStack justifyContent="space-between">
          <Text numberOfLines={1} fontWeight="semibold">
            {item.title ? item.title : translate("untitled_track")}
          </Text>
          {(isSingleLicense ||
            (isTicket && item?.status === "accepted_offer")) && (
            <AppIcon name="chevron-right" />
          )}
        </HStack>

        <HStack space={4} alignItems="center">
          <Image
            source={
              item?.image_with_url?.sm?.url
                ? { uri: item?.image_with_url?.sm?.url }
                : require("assets/images/features/release-banner.jpeg")
            }
            resizeMode="contain"
            height={86}
            width={86}
            alt={item?.title + " Cover"}
            borderRadius="xl"
          />
          <VStack space={2} justifyContent="center" flexShrink={1}>
            <Text fontSize={["md", "lg"]}>
              {item?.start_date &&
                `Auction ${dayjs(item?.start_date).format(
                  "DD.MM.YYYY"
                )} - ${dayjs(item?.end_date).format("DD.MM.YYYY")}`}
            </Text>

            <HStack space={1} alignItems="center">
              <IconModal
                iconColor={statuses[item?.status]?.color}
                icon={statuses[item?.status]?.icon}
                contentText={`${translate("status")}: ${translate(TITLE)}`}
              />
              <Text
                fontSize={["sm", "md"]}
                color={statuses[item?.status]?.color}
              >
                {translate(TITLE)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};
