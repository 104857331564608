import React, { useEffect, useRef, useState } from "react";
import { HStack, VStack } from "native-base";
// internal hooks
import useCreatePaymentMethod from "features/general/payment-methods/hooks/useCreatePaymentMethod";
// internal functions
import { translate, navigateBack } from "utils/helpers";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { Loading } from "common/components/loading/loading.component";
import { ModalFrame } from "navigation/components/modal-frame.component";

export const PaymentMethodSetupIntentCardScreen = ({ navigation }) => {
  const addPaymentMethod = useCreatePaymentMethod();
  const [isLoadingIFrame, setIsLoadingIframe] = useState(true);
  const [cardDetails, setCardDetails] = useState({ allFieldsAreValid: false });
  const IFrameElement = useRef(null);
  const IFRAME_ORIGIN = "https://cc.secupay.com";

  const addCard = () => {
    IFrameElement.current.contentWindow.postMessage(
      { action: "get-cc-data" },
      IFRAME_ORIGIN
    );
  };

  useEffect(() => {
    if (cardDetails?.allFieldsAreValid === true) {
      const data = {
        type: "credit_card",
        private: {
          owner: cardDetails.encryptedData.masked.card_owner,
          pan: cardDetails.encryptedData.masked.card_number,
          expiration_date: new Date(
            Date.UTC(cardDetails.yearExp.value, cardDetails.monthExp.value - 1)
          ).toISOString(),
          issuer: cardDetails.cardNumber.company,
          transact_container: cardDetails.encryptedData.crypted_container,
          transact_skey_pubkey: cardDetails.encryptedData.crypted_skey,
          transact_skey_keyname: cardDetails.encryptedData.key_filename,
          transact_hash: cardDetails.encryptedData.container_hash,
        },
      };
      addPaymentMethod.mutate(data);
    }
  }, [cardDetails]);

  useEffect(() => {
    if (addPaymentMethod.isSuccess) navigateBack(navigation);
  }, [addPaymentMethod.isSuccess]);

  useEffect(() => {
    [0, 100, 250, 500, 1000, 2000].forEach((timeout) => {
      setTimeout(() => {
        if (IFrameElement?.current) {
          IFrameElement.current.contentWindow.postMessage(
            {
              action: "set-theme",
              theme: 1, // 0 = secupay, 1 = custom
            },
            IFRAME_ORIGIN
          );
        }
      }, timeout);
    });
    window.addEventListener(
      "message",
      function (e) {
        if (e.origin !== IFRAME_ORIGIN) {
          return;
        }

        switch (e.data.action) {
          // Iframe height changed. This is raised on load, and after validation.
          case "cc-iframe-height":
            console.debug("new iframe height " + e.data.documentHeight);
            try {
              IFrameElement.current.height = e.data.documentHeight + "px";
              setTimeout(() => setIsLoadingIframe(false), 500);
            } catch (error) {
              console.debug(error);
            }
            break;

          // Iframe sends data. These may be valid or not.
          case "prepared-cc-data":
            setCardDetails(e.data.data);
            break;

          default:
            console.warn("unknown message " + e.data.action, e.data);
            break;
        }
      },
      false
    );
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        shadow={-0}
        bg={"light.100"}
        overflow={"hidden"}
        borderTopRadius={20}
      >
        <NavigationHeader
          headerRight={{
            iconName: "close",
            onPress: () => navigateBack(navigation),
            color: "#5c5c5c",
          }}
          bg="transparent"
        />
        <VStack space={5} p={5}>
          {isLoadingIFrame && (
            <Loading
              pt={0}
              _light={{ bg: "#5c5c5c" }}
              _dark={{ bg: "#5c5c5c" }}
            />
          )}
          <HStack display={isLoadingIFrame ? "none" : null} maxWidth={"700px"}>
            <iframe
              frameBorder="0"
              ref={IFrameElement}
              src="https://cc.secupay.com?bg=transparent"
              width="100%"
            />
          </HStack>
          <PrimaryButton
            text={translate("save")}
            onPress={addCard}
            disabled={addPaymentMethod.isLoading}
            isLoading={addPaymentMethod.isLoading}
          />
        </VStack>
      </VStack>
    </ModalFrame>
  );
};
