import React, { memo } from "react";
import { VStack, Text } from "native-base";
import ReadMore from "react-native-read-more-text";
// helpers
import { translate } from "utils/helpers";

export const ExpandableTextReadMore = memo(({ title, text, noOfLines = 2 }) => (
  <VStack space={5}>
    {title && (
      <Text fontSize={["xl", "2xl"]} fontWeight={"black"}>
        {title}
      </Text>
    )}
    {text && (
      <ReadMore
        numberOfLines={noOfLines}
        renderTruncatedFooter={(handlePress) => (
          <Text fontSize={["sm", "md"]} bold onPress={handlePress}>
            {translate("read_more")}
          </Text>
        )}
        renderRevealedFooter={(handlePress) => (
          <Text fontSize={["sm", "md"]} bold onPress={handlePress}>
            {translate("read_less")}
          </Text>
        )}
      >
        <Text fontSize={["sm", "md"]}>{text}</Text>
      </ReadMore>
    )}
  </VStack>
));
