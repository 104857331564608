import React, { useState } from "react";
import { HStack, Text, VStack, Pressable, Image, Box } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal components
import { MusicianListModal } from "features/accounts/musicians/components/musician-list-modal.component";
// internal
import { translate } from "utils/helpers";

export const AuctionMusiciansList = ({
  musicians,
  size = 10,
  showsContainer = true,
  fontColor = "darkText",
}) => {
  const navigation = useNavigation();

  const slice = 2;
  const smallRender = size < 10;
  const slicedMusicians = musicians?.slice(0, slice);
  const remainingMusicians = Math.max(musicians?.length - slice, 0);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const goToMusician = () => {
    navigation.navigate("Musicians", {
      screen: "MusicianDetail",
      params: { id: musicians[0]?.id },
    });
  };

  return (
    <>
      {musicians?.length > 0 && (
        <VStack space={2}>
          {showsContainer && (
            <Text color={"gray.400"} fontSize={"md"} bold>
              {translate("created_by")}
            </Text>
          )}
          <Pressable
            maxW={450}
            rounded={"xl"}
            borderColor={"gray.200"}
            p={showsContainer ? 5 : 0}
            borderWidth={showsContainer ? 1 : 0}
            onPress={musicians?.length > 1 ? toggleModal : goToMusician}
          >
            <HStack space={3} alignItems={"center"}>
              <HStack alignItems={"center"}>
                {slicedMusicians.map((musician, index) => {
                  return (
                    <Image
                      size={size}
                      key={index}
                      borderWidth={1}
                      rounded={"full"}
                      alt={"musician"}
                      borderColor={"lightText"}
                      ml={index !== 0 ? -2 : undefined}
                      source={{ uri: musician?.image_with_url?.md?.url }}
                    />
                  );
                })}
                {remainingMusicians > 0 && (
                  <Box
                    p={2}
                    ml={-2}
                    size={size}
                    bg={"gray.100"}
                    borderWidth={1}
                    rounded={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderColor={"lightText"}
                  >
                    <Text
                      bold
                      color={"gray.500"}
                      fontSize={smallRender ? "2xs" : "sm"}
                    >{`+${remainingMusicians}`}</Text>
                  </Box>
                )}
              </HStack>
              <HStack alignItems={"center"}>
                <Text
                  isTruncated
                  color={fontColor}
                  maxW={[56, undefined]}
                  fontSize={smallRender ? "xs" : "md"}
                  fontWeight={smallRender ? 500 : 600}
                >
                  {slicedMusicians?.map((musician, index) => {
                    return (
                      <Text key={index}>
                        {`${musician?.alias}${
                          index !== slicedMusicians?.length - 1 ? `, ` : ``
                        }`}
                      </Text>
                    );
                  })}
                  {remainingMusicians > 0 && (
                    <Text>{`, and ${remainingMusicians} more`}</Text>
                  )}
                </Text>
              </HStack>
            </HStack>
          </Pressable>
        </VStack>
      )}

      <MusicianListModal
        title={translate("musicians_list")}
        showModal={showModal}
        toggleModal={toggleModal}
        musicians={musicians}
      />
    </>
  );
};
