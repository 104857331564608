import React from "react";
import { useController } from "react-hook-form";
import { Slider as NBSlider, Text, useColorMode } from "native-base";

export const Slider = ({
  control,
  name,
  value = null,
  rules = {},
  minValue,
  maxValue,
  step,
  ...props
}) => {
  const { field } = useController({
    control,
    name,
    rules,
    defaultValue: value ? value : 0,
  });
  const { colorMode } = useColorMode();
  return (
    <NBSlider
      minValue={minValue}
      maxValue={maxValue}
      step={step}
      value={field.value}
      onChange={field.onChange}
      colorScheme={colorMode == "dark" ? "primary" : "secondary"}
      {...props}
    >
      <NBSlider.Track>
        <NBSlider.FilledTrack />
      </NBSlider.Track>
      <NBSlider.Thumb
        height="50px"
        width="50px"
        justifyContent="center"
        alignItems="center"
        _focus={{
          outlineColor: colorMode == "dark" ? "#4a48e4" : "#00dbc3",
        }}
        _hover={{
          outlineColor: colorMode == "dark" ? "#4a48e4" : "#00dbc3",
        }}
      >
        <Text
          fontSize={["xs", "sm"]}
          fontWeight="semibold"
          _light={{ color: "lightText" }}
        >
          {Math.round(field.value * 1000) / 10} %
        </Text>
      </NBSlider.Thumb>
    </NBSlider>
  );
};
