import React from "react";
import {
  Box,
  Text,
  Stack,
  VStack,
  HStack,
  ScrollView,
  useMediaQuery,
} from "native-base";
import { useNavigation } from "@react-navigation/native";

//internal components
import {
  MusicianDetaiLicenseProductsList,
  MusicianDetailAuctionList,
} from "features/accounts/musicians/components/musician-detail-auctions-list.component";
import { MusicianDetailReleaseList } from "features/accounts/musicians/components/musician-detail-release-list.component";
import { MusicianDetailTrackList } from "features/accounts/musicians/components/musician-detail-track-list.component";
import { ExpandableText } from "theme/typography/expandable-text.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";
// internal hooks
import { useAuctions } from "features/general/auctions/hooks/useAuctions";
import { useUserMusician } from "features/accounts/musicians/hooks/useUserMusician";
//internal helpers
import { translate } from "utils/helpers";

export const MusicianDetailProfile = ({ musician }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1192 });
  const navigation = useNavigation();
  const { data: userMusician } = useUserMusician();
  const auctions = useAuctions(musician?.id, {
    musician_id: musician?.id,
  });

  return (
    <Box pb={24}>
      <Stack flex={1} direction={isDesktop ? "row" : "column"}>
        {musician?.profile?.bio && (
          <VStack
            m={5}
            space={5}
            p={[5, 8]}
            shadow={5}
            rounded={"xl"}
            bg={"light.300"}
            flex={[undefined, 1]}
            maxH={isDesktop ? 500 : undefined}
          >
            <HStack justifyContent="space-between" alignItems="center">
              <Text fontSize={["xl", "2xl"]} bold>
                {`${translate("about")} ${musician?.alias}`}
              </Text>
              {musician?.id === userMusician?.id && (
                <AppIconButton
                  nativeID="edit-profile"
                  iconName="pencil"
                  onPress={() =>
                    navigation.navigate("Profiles", {
                      screen: "ProfileEdit",
                      params: { id: userMusician?.user_id },
                    })
                  }
                />
              )}
            </HStack>
            {isDesktop ? (
              <ScrollView showsVerticalScrollIndicator={false}>
                <Text fontSize="md">{musician?.profile?.bio}</Text>
              </ScrollView>
            ) : (
              <ExpandableText
                numberOfLines={[3, 5, 9]}
                fontSize={["md"]}
                text={musician?.profile?.bio}
              />
            )}
          </VStack>
        )}
      </Stack>
      <MusicianDetailAuctionList
        header={translate("moments")}
        auctions={musician?.auctions?.filter((item) =>
          item.type.includes("ticket")
        )}
      />
      <MusicianDetailAuctionList
        header={translate("collectibles")}
        auctions={musician?.auctions?.filter((item) =>
          item.type.includes("basic")
        )}
      />
      <MusicianDetaiLicenseProductsList musician={musician} />
      <MusicianDetailReleaseList
        musician={musician}
        releases={musician?.primary_in_releases.slice(0, 20)}
      />
      {/* <MusicianDetailTrackList tracks={musician?.primary_in_tracks} /> */}
    </Box>
  );
};
