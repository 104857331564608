import React, { useState, useEffect } from "react";
import { Modal as NBModal, Pressable, ScrollView } from "native-base";

export const Modal = ({
  pressableChildren,
  pressableStyles = {},
  modalChildren,
  modalStyles = {},
  isOpen = undefined,
  onClose = undefined,
  onPress = undefined,
  disabled = false,
  showScrollIndicator = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    if (onClose) onClose(!showModal);
    setShowModal(!showModal);
  };
  useEffect(() => {
    if (isOpen != undefined) setShowModal(isOpen);
  }, [isOpen]);

  return (
    <>
      <Pressable
        disabled={disabled}
        {...pressableStyles}
        onPress={onPress || toggleModal}
      >
        {pressableChildren}
      </Pressable>
      <NBModal isOpen={showModal} onClose={toggleModal} borderRadius={"xl"}>
        <NBModal.Content {...modalStyles} borderRadius={"xl"}>
          <ScrollView showsVerticalScrollIndicator={showScrollIndicator}>
            <NBModal.Body p={0}>
              <NBModal.CloseButton />
              {modalChildren}
            </NBModal.Body>
          </ScrollView>
        </NBModal.Content>
      </NBModal>
    </>
  );
};
