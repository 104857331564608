import React from "react";
import { useController } from "react-hook-form";
import { TextArea as NBTextArea } from "native-base";

export const TextArea = ({
  control,
  name,
  value = null,
  rules = {},
  ...props
}) => {
  const { field } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });
  return (
    <NBTextArea
      {...props}
      minHeight={100}
      maxHeight={100}
      numberOfLines={4}
      value={field.value}
      onChangeText={field.onChange}
    />
  );
};
