import React, { memo } from "react";
import { FlatList } from "native-base";
import { CommentDetail } from "features/general/comments/components/comment-detail.component";
import { useComments } from "features/general/comments/hooks/useComments";

export const CommentList = memo(({ auctionID, maxHeight }) => {
  const comments = useComments(auctionID);
  return (
    <FlatList
      h={maxHeight ? "100%" : 1}
      maxHeight={maxHeight ? maxHeight : null}
      showsVerticalScrollIndicator={false}
      data={comments}
      renderItem={({ item }) => (
        <CommentDetail auctionID={auctionID} comment={item} />
      )}
    />
  );
});
