import React from "react";
import {
  FlatList,
  Text,
  HStack,
  VStack,
  useMediaQuery,
  Box,
} from "native-base";
// utility functions
import { translate } from "utils/helpers";

export const ProfileDetailHeaderStats = ({ profile }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const list = [
    {
      title: translate("supported_artists"),
      data: profile?.number_of_artists_supported,
    },
    {
      title: "Drops",
      data: profile?.number_of_drops_supported,
    },
    { title: "biddz", data: profile?.number_of_biddz_bought },
  ];

  return (
    <VStack
      mt={5}
      space={5}
      w={isDesktop ? "50%" : "100%"}
      alignItems={isDesktop ? "flex-end" : undefined}
    >
      <FlatList
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ alignItems: "center" }}
        horizontal
        data={list}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <Box pl={3}>
            <HStack
              p={3}
              space={1}
              bg={"light.200"}
              borderRadius={"xl"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontWeight={"medium"} fontSize={["xs", "sm"]}>
                {item.title}:
              </Text>
              <Text fontSize={["md", "lg"]} fontWeight="bold">
                {item.data}
              </Text>
            </HStack>
          </Box>
        )}
      />
    </VStack>
  );
};
