import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useSearchAppleMusicians = (alias, enabled = false) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `apple-musicians-${alias}`,
    () =>
      client
        .get(`musicians/search/`, {
          params: {
            alias,
            store: "apple",
            limit: 10,
          },
        })
        .then((resp) => {
          return resp.data;
        }),
    { enabled: enabled && !!alias, staleTime: 1000 }
  );
};
