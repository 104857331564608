import React from "react";
import {
  HStack,
  VStack,
  Pressable,
  Text,
  Stack,
  Divider,
  Switch,
} from "native-base";
import dayjs from "dayjs";
// internal
import { translate, getLocalizedNumber } from "utils/helpers";

const Tab = ({ title, selected, onChange }) => {
  return (
    <Pressable
      py={3}
      px={6}
      rounded={"full"}
      onPress={selected ? undefined : onChange}
      bg={selected ? "secondary.300" : "gray.200"}
    >
      <Text textTransform={"capitalize"} fontSize={["sm", "md"]}>
        {title}
      </Text>
    </Pressable>
  );
};

export const LicenseCollectibleAnalyticsChartHeader = ({
  data,
  title,
  streams,
  earnings,
  onChange,
  timeFrame,
  showEarnings,
  isAccumulative,
  estimatedEarnings,
}) => {
  const actualData = estimatedEarnings
    ? data
      ? [...data[timeFrame], ...estimatedEarnings[timeFrame]]
      : estimatedEarnings[timeFrame]
    : data
    ? data[timeFrame]
    : [];
  const startDate = actualData.length > 0 && actualData[0][timeFrame];

  return (
    <VStack flex={1}>
      <Text fontSize={["lg", "xl"]} fontWeight={600}>
        {title}
      </Text>
      <Divider bg={"gray.200"} my={5} />
      <HStack space={3} alignItems={"center"} justifyContent={"space-between"}>
        <HStack space={3} alignItems={"center"}>
          <Tab title={"streams"} selected={!showEarnings} onChange={onChange} />
          <Tab title={"earnings"} selected={showEarnings} onChange={onChange} />
        </HStack>
        <Stack
          space={1}
          alignItems={"center"}
          justifyContent={"center"}
          direction={{ base: "column", lg: "row" }}
        >
          <Text mr={2} fontWeight={500} fontSize={["xs", "sm"]}>
            {translate("accumulative")}
          </Text>
          <Switch
            colorScheme={"secondary"}
            value={isAccumulative?.value}
            onValueChange={(value) => {
              isAccumulative?.onChange(value);
            }}
          />
        </Stack>
      </HStack>
      <VStack my={4} space={2}>
        <Text fontSize={"3xl"} fontWeight={500}>
          {showEarnings
            ? `${getLocalizedNumber(earnings.toFixed(2))} €`
            : getLocalizedNumber(streams)}
        </Text>
        {showEarnings && (
          <Text color={"gray.500"} fontSize={["xs", "sm"]}>
            {getLocalizedNumber(
              estimatedEarnings?.total
                ? estimatedEarnings?.total?.toFixed(2)
                : 0
            )}
            € (inkl. Prognose)
          </Text>
        )}
        <Text color={"gray.500"} fontSize={["xs", "sm"]}>
          {startDate ? `${startDate}-` : ""}
          {dayjs().format("DD.MM.YYYY")}
        </Text>
      </VStack>
    </VStack>
  );
};
