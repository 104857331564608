import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { AddContributorToTrackScreen } from "features/royalties/track-contributors/screens/add-contributor-to-track.screen";

const Stack = createStackNavigator();

export const ContributorsNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="AddContributorToTrack"
        component={AddContributorToTrackScreen}
      />
    </Stack.Navigator>
  );
};
