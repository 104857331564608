import React, { useState } from "react";
import { Checkbox, Text, HStack, VStack } from "native-base";
// utility functions

import { AppIconButton } from "theme/buttons/app-icon-button.component";
import { AppIconButtonWithOutline } from "theme/buttons/app-icon-button-with-outline";

export const OrderCreateConfirmationPreviewBreakdownAddOn = ({
  addOn,
  maxUnits,
  setBasketForProduct,
}) => {
  const [units, setUnits] = useState(0);
  const [checked, setChecked] = useState(false);

  const unitsChanged = (units) => {
    setBasketForProduct(addOn, units, true);
    setChecked(!!units);
    setUnits(units);
  };

  const onCheckboxChecked = (checked) => {
    if (checked) {
      unitsChanged(maxUnits);
    } else {
      unitsChanged(0);
    }
    setChecked(checked);
  };

  return (
    <HStack justifyContent={"space-between"} alignItems={"center"}>
      <HStack w="48%" space={2} alignItems={"center"}>
        <Checkbox
          isChecked={checked}
          colorScheme={"secondary"}
          onChange={onCheckboxChecked}
        />
        <VStack>
          <Text bold color={"secondary.600"} fontSize={["md", "md"]}>
            {addOn?.title}
          </Text>
          <HStack space={1}>
            <Text bold color={"light.700"} fontSize={["md", "md"]}>
              {`${addOn?.price_per_unit} EUR`}
            </Text>
            <Text color={"light.700"} fontSize={["md", "md"]}>
              {"inkl. MwSt."}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      <HStack w="48%" justifyContent={"flex-end"} alignItems={"center"}>
        <AppIconButtonWithOutline
          size={"xs"}
          name={"minus"}
          borderRadius={"full"}
          disabled={units === 0}
          onPress={() => unitsChanged(Math.max(units - 1, 0))}
        />
        <Text
          bold
          minWidth={30}
          fontSize={"md"}
          textAlign={"center"}
          color={"secondary.600"}
        >
          {units}
        </Text>
        <AppIconButton
          size={"xs"}
          iconName={"plus"}
          borderRadius={"full"}
          disabled={units >= maxUnits}
          onPress={() => unitsChanged(units + 1)}
        />
      </HStack>
    </HStack>
  );
};
