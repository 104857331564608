import React from "react";
import { VStack, Text, HStack, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal
import { getLocalizedDateTime } from "utils/helpers";

export const TicketProductDetailHeader = ({ auction, event, isDardan }) => (
  <VStack space={2}>
    {!isDardan && (
      <Text color="secondary.600" fontSize={["sm", "md"]} fontWeight="semibold">
        {getLocalizedDateTime(event?.start_time)}
      </Text>
    )}
    <Text fontSize={["2xl", "4xl"]} bold>
      {auction?.title}
    </Text>
    <Text mt="2" fontSize={["sm", "md"]} fontWeight="semibold">
      {auction?.subtitle}
    </Text>
    {!isDardan && (
      <>
        <HStack mt="4" space={1} alignItems="center">
          <Icon
            size={5}
            as={MaterialCommunityIcons}
            name="map-marker-outline"
          />
          <Text fontSize={["sm", "md"]}>{event?.location}</Text>
        </HStack>
      </>
    )}
  </VStack>
);
