import { useContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { FirebaseContext } from "services/firebase.context";

export const useUserFirebaseProfile = (userID) => {
  const { firestore } = useContext(FirebaseContext);
  const [profile, setProfile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, `users/${userID}`),
      (doc) => {
        console.debug(`😎 Firestore User Profile ${userID}`);
        setProfile(doc.data());
        setIsLoading(false);
      },
      (error) => {
        console.debug(`❌ ${error}`);
        setIsLoading(false);
      }
    );
    return unsub;
  }, [userID]);

  return { data: profile, isLoading: isLoading };
};
