import React, { useContext, useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { useForm } from "react-hook-form";
import { VStack, Image, useColorMode } from "native-base";

// internal components

import { PrimaryButton } from "theme/buttons/primary-button.component";
import { CouponWin } from "features/general/coupons/components/coupon-win.component";
import { CouponLogin } from "features/general/coupons/components/coupon-login.component";
import { CouponRedeem } from "features/general/coupons/components/coupon-redeem.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
//internal hooks
import useUpdateCoupon from "features/general/coupons/hooks/useUpdateCoupon";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import useUpdateProfile from "features/accounts/profiles/hooks/useUpdateProfile";
//services
import { AuthenticationContext } from "services/authentication.context";

export const CouponDetailSuccess = ({ isDardan, product, coupon }) => {
  const { colorMode } = useColorMode();
  const { handleSubmit, control } = useForm();
  const navigation = useNavigation();

  const { isAuthenticated } = useContext(AuthenticationContext);

  const profile = useUserProfile();
  const updateCoupon = useUpdateCoupon(coupon?.code);
  const { updateProfile } = useUpdateProfile(profile.data?.id);

  const [status, setStatus] = useState(
    isAuthenticated ? "redeem" : "need_login"
  );

  const navigateToLounge = () => {
    navigation.navigate("BottomNavigator", {
      screen: "Lounge",
    });
  };
  const onPress = (data) => {
    const params = {
      email: data?.email,
      username: data?.username,
      send_marketing_emails: data?.send_marketing_emails,
    };
    updateProfile.mutate(params);
  };

  useEffect(() => {
    if (updateProfile.isSuccess) updateCoupon.mutate();
  }, [updateProfile.isSuccess]);

  useEffect(() => {
    if (updateCoupon.isSuccess) {
      if (isDardan) {
        setStatus("win");
      } else setStatus("completed");
    }
  }, [updateCoupon.isSuccess]);

  const DardanComponent =
    status === "need_login" ? (
      <CouponLogin isDardan={isDardan} />
    ) : status === "redeem" ? (
      <CouponRedeem
        isDardan={isDardan}
        profile={profile}
        control={control}
        product={product}
        redeemButton={
          <PrimaryButton
            isLoading={updateProfile.isLoading || updateCoupon.isLoading}
            disabled={updateProfile.isLoading}
            text={"Collectible Einlösen"}
            onPress={handleSubmit(onPress)}
          />
        }
      />
    ) : status === "win" ? (
      <CouponWin url={coupon?.url} setStatus={setStatus} />
    ) : (
      <></>
    );

  return (
    <VStack space={10} flex={1} justifyContent="space-between">
      <AuctionDetailImage
        justifyContent={"center"}
        interactiveImageTop={isDardan ? 16 : 0}
        isDardan={isDardan}
        url={
          product?.category === "ticket"
            ? ["need_login", "redeem"].includes(status)
              ? product?.picasso_generated_images?.wrapped?.image_with_url?.md
                  ?.url
              : product?.picasso_generated_images?.unused?.image_with_url?.md
                  ?.url
            : product?.picasso_generated_images?.wrapped_platinum
                ?.image_with_url?.md?.url
        }
      />
      {isDardan && (
        <Image
          alt="dardan_image"
          mt={16}
          mx="auto"
          resizeMode="contain"
          h={[35, 40]}
          w={["70%", "40%"]}
          source={
            colorMode === "light"
              ? require("assets/images/features/dardan-x-biddz-light.png")
              : require("assets/images/features/dardan.png")
          }
        />
      )}
      <VStack flex={1} justifyContent={"space-between"}>
        {DardanComponent}
        {status === "completed" && (
          <PrimaryButton text="Zu Collections" onPress={navigateToLounge} />
        )}
      </VStack>
    </VStack>
  );
};
