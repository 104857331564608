import React, { useContext } from "react";
import { ScrollView, VStack, useMediaQuery } from "native-base";
// internal
import { AuthenticationContext } from "services/authentication.context";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { CollectibleListImages } from "features/general/collectibles/components/collectible-list-images.component";
import { LicenseCollectibleBenefitList } from "features/royalties/license-benefits/components/license-collectible-benefit-list.component";
import { LicenseCollectibleAnalytics } from "features/royalties/license-collectibles/components//license-collectible-analytics.component";

export const SharesScreen = ({ navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    <ScreenFrame safeAreaTop={true} isAuthenticationRequired>
      <NavigationPanel />
      <ScrollView
        flex={1}
        pt={isDesktop ? 5 : 0}
        px={isDesktop ? 24 : 5}
        showsVerticalScrollIndicator={false}
      >
        <VStack space={5} pb={8}>
          <CollectibleListImages
            paginated={false}
            navigation={navigation}
            size={isDesktop ? 8 : 6}
            userID={userDetails?.id}
          />
          <LicenseCollectibleBenefitList
            paginated={false}
            navigation={navigation}
            size={isDesktop ? 4 : 3}
          />
          <LicenseCollectibleAnalytics />
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
