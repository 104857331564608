import React, { useContext } from "react";
// internal
import { AuthenticationContext } from "services/authentication.context";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationPanel } from "navigation/components/navigation-panel.component";
import { EventCollectibleList } from "features/general/collectibles/components/event-collectible-list.component";

export const EventsScreen = ({ navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);

  return (
    <ScreenFrame safeAreaTop={true} isAuthenticationRequired>
      <NavigationPanel />
      <EventCollectibleList userID={userDetails?.id} />
    </ScreenFrame>
  );
};
