import React, { useState } from "react";
import {
  HStack,
  VStack,
  Image,
  Text,
  Pressable,
  Divider,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
} from "native-base";
import dayjs from "dayjs";
// internal components
import { Loading } from "common/components/loading/loading.component";
// internal hooks
import { useArtistTransactions } from "features/royalties/master-rights/hooks/useArtistTransactions";
import useUpdateMasterRightStatus from "features/royalties/master-rights/hooks/useUpdateMasterRightStatus";
// utility functions
import { translate } from "utils/helpers";

const Item = ({ title, value }) => (
  <HStack justifyContent="space-between">
    <Text fontSize="md">{title}</Text>
    <Text fontSize="md" fontWeight="semibold">
      {value}
    </Text>
  </HStack>
);

const MasterRightDetails = ({ title, item }) => {
  const artistTransactions = useArtistTransactions(
    item.id,
    dayjs().subtract(1, "M").format("YYYY-MM-DD")
  );
  const { updateMasterRightStatus } = useUpdateMasterRightStatus(item.id);

  return !artistTransactions[0].isFetched &&
    !artistTransactions[1].isFetched ? (
    <Loading />
  ) : (
    <VStack my="5" space="3">
      <Item title={translate("track_title")} value={title} />
      <Item
        title={translate("release_date")}
        value={dayjs(item.track?.release_date).format("DD.MM.YYYY")}
      />
      <Item title={`ISRC`} value={item.track?.isrc} />
      <Divider bg="dark.800" />
      <Item
        title={translate("your_splits")}
        value={`${(item.share * 100).toFixed(2)}% ${item.track?.royalties_model === "v1"
          ? translate("of_net_revenue")
          : translate("of_license_fee")
          }`}
      />
      <Item title={translate("status")} value={translate(item.status)} />
      <Divider bg="dark.800" />
      <Item
        title={translate("revenue_last_30_days")}
        value={artistTransactions[1].data?.total}
      />
      <Item
        title={translate("revenue_total")}
        value={artistTransactions[0].data?.total}
      />
      {["draft", "pending"].includes(item.status) && (
        <HStack mt="5" space="5" justifyContent="flex-end">
          <Button
            px="7"
            py="2"
            variant="outline"
            borderRadius="md"
            _text={{ fontSize: "md", fontWeight: "bold" }}
            onPress={() => updateMasterRightStatus.mutate("rejected")}
          >
            {translate("reject")}
          </Button>
          <Button
            px="7"
            py="2"
            borderRadius="md"
            _text={{ fontSize: "md", fontWeight: "bold" }}
            onPress={() => updateMasterRightStatus.mutate("accepted")}
          >
            {translate("accept")}
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export const MasterRightsListCard = ({ item }) => {
  const [show, setShow] = useState(false);
  const TITLE = item.track?.full_title || item.track?.title || translate("untitled_track");

  return (
    <VStack flex={1} my="3" mx={{ lg: "3" }} space="10">
      <Pressable onPress={() => setShow(!show)}>
        <HStack
          p="3"
          _light={{ bg: "light.100" }}
          _dark={{ bg: "dark.900" }}
          borderRadius="md"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space="4" alignItems="center">
            <Image
              size="xs"
              borderRadius="md"
              source={
                item.track?.image_with_url?.sm?.url
                  ? { uri: item.track?.image_with_url?.sm?.url }
                  : require("assets/images/features/release-banner.jpeg")
              }
              resizeMode="contain"
              alt={item.track?.title + " Cover"}
            />
            <Text numberOfLines={1} fontWeight="semibold">
              {TITLE}
            </Text>
          </HStack>
          {show ? (
            <ChevronUpIcon size="md" color="white" />
          ) : (
            <ChevronDownIcon size="md" color="white" />
          )}
        </HStack>
      </Pressable>

      {show && <MasterRightDetails title={TITLE} item={item} />}
    </VStack>
  );
};
