import { useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  startAfter,
} from "firebase/firestore";
import { FirebaseContext } from "services/firebase.context";
import { useUserFirebaseProfile } from "features/accounts/profiles/hooks/useUserFirebaseProfile";

export const useUserBadges = (userID, filters) => {
  const user = useUserFirebaseProfile(userID);
  const { firestore } = useContext(FirebaseContext);

  const Filters = {
    biddz: { key: "object", condition: "==", value: null },
    artist: { key: "object.type", condition: "==", value: "artist" },
    track: { key: "object.type", condition: "==", value: "track" },
    ...(user.data?.top_badges && {
      featured: { key: "id", condition: "in", value: user.data?.top_badges },
    }),
  };

  const [badges, setBadges] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);

  const fetchData = (fetchNextPage = false) => {
    setLoading(true);

    if (filters?.includes("featured") && user.data?.top_badges?.length < 1) {
      setBadges([]);
      setLoading(false);
    } else {
      // Initialize an array to store query conditions
      let conditions = [collection(firestore, `users/${userID}/badges`), limit(10)];

      // Order by "earned_at" in descending order
      conditions.push(orderBy("earned_at", "desc"));

      // Applying filters
      if (filters?.length > 0) {
        filters.map((filter) => {
          const f = Filters[filter];
          if (f) conditions.push(where(f.key, f.condition, f.value));
        });
      }

      // Setting query cursor
      if (fetchNextPage && lastVisible) {
        conditions.push(startAfter(lastVisible));
      }

      // Create the final query using all the conditions
      let q = query(...conditions);
      // Getting docs
      getDocs(q)
        .then((snapshot) => {
          setHasNextPage(!snapshot.empty);
          // Get the last visible document
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.debug(`🏆 User ${userID} Badges`);
          if (fetchNextPage) setBadges([...badges, ...data]);
          else setBadges(data);
          setLoading(false);
        })
        .catch((error) => {
          console.debug(`❌ ${error}`);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [userID, user.data?.top_badges, ...filters]);

  return { data: badges, isLoading: loading, fetchData, hasNextPage };
};
