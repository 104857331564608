import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateLicenseProduct = (releaseID) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createLicenseProduct: useMutation(
      (params = {}) =>
        client
          .post(`releases/${releaseID}/license_products/`, params)
          .then((resp) => resp.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`release-${releaseID}`);
          queryClient.invalidateQueries(`user-license-products`);
        },
      }
    ),
    error: error,
  };
};

export default useCreateLicenseProduct;
