import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTransactions = () => {
  const { client } = useContext(AxiosContext);

  return useQuery("musician-transactions", () =>
    client
      .get("me/artist_transactions/", {
        params: {
          deposit_type: "royalties_actual",
          type: "deposit",
          response_type: "series",
        },
      })
      .then((resp) => resp.data)
  );
};
