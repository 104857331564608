import React from "react";
import {
  HStack,
  Icon,
  Image,
  Pressable,
  ScrollView,
  Text,
  useMediaQuery,
} from "native-base";
import { Ionicons, Entypo } from "@expo/vector-icons";

export const TabNavigatorLite = ({
  tabs,
  selected,
  setSelected,
  iconTabs = [],
  imageTabs = [],
  lockedTabs = [],
  displayAsIcon = false,
  displayAsImage = false,
}) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  const showIcon = iconTabs?.length === tabs?.length && displayAsIcon;
  const showImage = imageTabs?.length === tabs?.length && displayAsImage;
  return (
    <HStack
      borderBottomWidth={1}
      px={isDesktop ? 24 : 0}
      borderBottomColor={"gray.200"}
      justifyContent={isDesktop ? "flex-start" : "center"}
    >
      <ScrollView
        horizontal
        maxW={isDesktop ? 800 : undefined}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: isDesktop ? "flex-start" : "center",
        }}
      >
        {tabs?.map((item, index) => {
          const isLocked = lockedTabs.includes(item);
          const focused = !isLocked && item === selected;
          return (
            item && (
              <Pressable
                px={2}
                py={4}
                flex={1}
                maxW={210}
                key={index}
                disabled={isLocked}
                alignItems={"center"}
                minW={isDesktop ? 150 : 50}
                onPress={() => setSelected(item)}
                borderBottomWidth={focused ? 1.5 : 0}
                borderBottomColor={focused ? "darkText" : ""}
              >
                <HStack space={2} alignItems={"center"}>
                  {showIcon ? (
                    <Icon
                      size={6}
                      name={iconTabs[index]}
                      color={focused ? "darkText" : "gray.400"}
                      as={item === "spotify" ? Entypo : Ionicons}
                    />
                  ) : showImage ? (
                    <Image
                      size={6}
                      alt={item}
                      source={imageTabs[index]}
                      opacity={focused ? 1 : 0.3}
                      _alt={{ textTransform: "capitalize" }}
                    />
                  ) : (
                    <Text
                      key={item}
                      selectable={false}
                      fontSize={["md", "xl"]}
                      textTransform={"capitalize"}
                      fontWeight={isDesktop ? 700 : 500}
                      color={focused ? "darkText" : "gray.400"}
                    >
                      {item}
                    </Text>
                  )}
                </HStack>
              </Pressable>
            )
          );
        })}
      </ScrollView>
    </HStack>
  );
};
