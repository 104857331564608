import React, { useState } from "react";
import { Box, ScrollView, VStack, useMediaQuery } from "native-base";
//internal hooks
import { useProfile } from "features/accounts/profiles/hooks/useProfile";
//helper functions
import { navigateBack } from "utils/helpers";
//internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ProfileDetailHeader } from "features/accounts/profiles/components/profile-detail-header.component";
import { ProfileDetailPrivate } from "features/accounts/profiles/components/profile-detail-private.component";
import { ProfileDetailHeaderSkeleton } from "features/accounts/profiles/skeletons/profile-detail-header.skeleton";
import { CollectibleListImages } from "features/general/collectibles/components/collectible-list-images.component";
import { TabNavigatorLite } from "common/components/misc/tab-navigator-lite.component";

export const ProfileDetailScreen = ({ navigation, route }) => {
  const { profileID } = route.params;
  const profile = useProfile(profileID);
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const tabs = ["events", "shares"];
  const imageTabs = [
    require("assets/images/icons/navigation/events.png"),
    require("assets/images/icons/navigation/shares.png"),
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <ScreenFrame safeAreaBottom>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      {profile.isLoading ? (
        <ProfileDetailHeaderSkeleton />
      ) : profile?.data?.is_public ? (
        <ScrollView flex={1} showsVerticalScrollIndicator={false}>
          <ProfileDetailHeader profile={profile} isDesktop={isDesktop} />
          <Box mt={5}>
            <TabNavigatorLite
              tabs={tabs}
              imageTabs={imageTabs}
              selected={selectedTab}
              displayAsImage={!isDesktop}
              setSelected={setSelectedTab}
            />
          </Box>

          <VStack px={isDesktop ? 24 : 3}>
            {selectedTab === "events" && (
              <CollectibleListImages
                navigation={navigation}
                eventCollectibles={true}
                userID={profile.data?.user_id}
              />
            )}
            {selectedTab === "shares" && (
              <CollectibleListImages
                navigation={navigation}
                userID={profile.data?.user_id}
              />
            )}
          </VStack>
        </ScrollView>
      ) : (
        <ProfileDetailPrivate />
      )}
    </ScreenFrame>
  );
};
