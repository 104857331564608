import React from "react";
import { HStack, Text } from "native-base";
import { AppIconButton } from "./app-icon-button.component";

export const AppIconButtonWithText = ({
  bg = undefined,
  text,
  onPress,
  iconName,
  disabled,
  ...props
}) => {
  return (
    <HStack space={2} alignItems="center">
      <Text fontSize="xl" fontWeight="semibold">
        {text}
      </Text>
      <AppIconButton
        {...props}
        bg={bg}
        iconName={iconName}
        disabled={disabled}
        onPress={onPress}
      />
    </HStack>
  );
};
