import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useGenres = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`genres`, () => {
    console.debug(`genres retrieved`);
    return client.get(`enums/genres/`).then((resp) => resp.data);
  });
};
