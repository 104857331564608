import React, { useState } from "react";
import { Platform } from "react-native";
import { Image, VStack, Text, Pressable, Icon, HStack } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
// internal component

import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { AuctionCalculatorCard } from "features/general/auctions/components/auction-calculator-card.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate, navigateBack, isWeb } from "utils/helpers";

const getStatus = (status) => {
  switch (status) {
    case "draft":
      return {
        desc: translate("incomplete_artist_registration_process"),
        btn: translate("complete_artist_profile"),
        helpText: "",
      };

    case "rejected":
      return {
        desc: translate("artist_profile_action_required"),
        btn: translate("review_application"),
        helpText: translate("profile_rejected_help_text"),
      };

    case "under_review":
      return {
        desc: translate("artist_profile_under_review"),
        btn: translate("review_application"),
        helpText: translate("profile_under_review_help_text"),
      };

    case "verified_restricted":
      return {
        desc: translate("artist_profile_verification_restricted"),
        btn: "",
        helpText: translate("profile_verified_restricted_help_text"),
      };

    default:
      return {
        desc: translate("register_as_artist_text"),
        btn: translate("create_artist_profile"),
        helpText: "",
      };
  }
};

export const MusicianCompleteApplication = ({
  musician,
  createMusician,
  navigateToEditPersonalData,
}) => {
  const navigation = useNavigation();
  const [showRejectionReason, setShowRejectionReason] = useState(false);

  const actionRequired =
    !!musician?.status_history?.length &&
    musician?.status_history[0]?.new === "draft";

  const musicianStatus = getStatus(musician?.status);

  const onPress = () => {
    if (!musician) {
      createMusician();
    } else if (musicianStatus === "rejected" || actionRequired) {
      setShowRejectionReason(true);
    } else if (musicianStatus === "under_review") {
      navigate("Musicians", { screen: "MusicianEditSummary" });
    } else {
      navigateToEditPersonalData();
    }
  };

  return showRejectionReason ? (
    <VStack
      p="5"
      flex={1}
      w="100%"
      maxW="700"
      alignSelf="center"
      justifyContent="space-between"
    >
      <VStack space="10">
        <Pressable onPress={() => setShowRejectionReason(false)}>
          <Icon
            size="xl"
            as={MaterialCommunityIcons}
            name="close"
            color="white"
          />
        </Pressable>
        <VStack space="5" p="10" justifyContent="center">
          <Text fontSize={["md", "xl"]} bold color="white">
            {translate("verification_reason_failed")}
          </Text>
          <Text fontSize={["sm", "md"]}>
            {musician?.status_history[0]?.message}
          </Text>
        </VStack>
      </VStack>
      {musicianStatus !== "rejected" && actionRequired && (
        <PrimaryButton
          text={translate("start_again")}
          onPress={navigateToEditPersonalData}
        />
      )}
    </VStack>
  ) : (
    <VStack
      p="5"
      w="100%"
      space={"10"}
      maxW="700"
      alignSelf="center"
      justifyContent="space-between"
    >
      {!isWeb && (
        <NavigationHeader
          headerLeft={{
            iconName: "arrow-left",
            onPress: () => navigateBack(navigation),
          }}
        />
      )}
      <HStack space="3" alignItems="center" justifyContent="center">
        <Text
          flexShrink={1}
          bold
          fontSize={["xl", "2xl"]}
          textAlign="center"
          lineHeight={33}
        >
          {musicianStatus.desc}
        </Text>
        {(musicianStatus.helpText || actionRequired) && (
          <IconModal
            size={7}
            iconColor="primary.600"
            icon={<AntDesign name="questioncircleo" />}
            contentText={
              actionRequired
                ? translate("profile_action_required_help_text")
                : musicianStatus.helpText
            }
          />
        )}
      </HStack>
      {(!musician || !!musicianStatus.btn) && (
        <PrimaryButton
          showNextIcon
          text={musicianStatus.btn}
          onPress={onPress}
        />
      )}
      {isWeb && <AuctionCalculatorCard />}
      <Image
        width={179}
        height={244}
        source={require("assets/images/biddz-theme/biddz-logo-gradient-outline.png")}
        alt="Biddz Logo"
        alignSelf="center"
      />
    </VStack>
  );
};
