import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import {
  Box,
  FormControl,
  HStack,
  Input,
  VStack,
  Popover,
  ScrollView,
  Pressable,
  Button,
  Text,
  Menu,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  useColorMode,
  useTheme,
  Icon,
} from "native-base";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { Calendar } from "react-native-calendars";
import dayjs from "dayjs";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
// helpers
import { translate } from "utils/helpers";

const localeData = require("dayjs/plugin/localeData");
dayjs.extend(localeData);

const DropdownButton = ({ triggerProps, value, disabled, onPress }) => (
  <Pressable
    {...triggerProps}
    {...(onPress && { onPress })}
    px="3"
    py="2"
    borderRadius="lg"
    borderWidth="1"
    borderColor="light.600"
    isDisabled={disabled}
    _hover={{ borderColor: "primary.700" }}
    _light={{ borderColor: "light.600" }}
    _dark={{ borderColor: "dark.800" }}
  >
    <HStack justifyContent="space-between" alignItems="center">
      <Text
        fontSize="md"
        _light={{ color: disabled ? "dark.700" : "darkText" }}
        _dark={{ color: disabled ? "dark.700" : "lightText" }}
      >
        {value || translate("select_date")}
      </Text>
      <ChevronDownIcon size="lg" color="light.800" />
    </HStack>
  </Pressable>
);

const TimeOption = ({ value, selected, onPress }) => (
  <Pressable
    py="1.5"
    px="3"
    onPress={onPress}
    _light={{ bg: selected ? "secondary.700" : "transparent" }}
    _dark={{ bg: selected ? "primary.700" : "transparent" }}
  >
    <Text
      fontSize="md"
      _light={{ color: selected ? "lightText" : "darkText" }}
      _dark={{ color: "lightText" }}
    >
      {value}
    </Text>
  </Pressable>
);

export const FormControlDatePicker = ({
  control,
  name,
  value = null,
  minYear = 1950,
  maxYear = 2030,
  rules = {},
  infoText = null,
  includeTime = false,
  isDisabled = false,
  label = null,
  helperText = null,
}) => {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const textColor = isDarkMode ? colors?.lightText : colors?.darkText;

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [time, setTime] = useState("00:00");

  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    control,
    name,
    defaultValue: null,
    rules,
  });

  const range = (start, end) =>
    Array(end - start + 1)
      .fill()
      .map((_, idx) => String(start + idx));

  useEffect(() => {
    if (includeTime) {
      const parsedDate = selectedDate + " " + time;
      field.onChange(dayjs(parsedDate, "YYYY-MM-DD HH:mm").format());
    } else {
      field.onChange(selectedDate);
    }
  }, [selectedDate, time]);

  useEffect(() => {
    if (value) {
      const date = dayjs(value);
      setSelectedDate(date.format("YYYY-MM-DD"));
      if (includeTime) {
        setTime(date.format("HH:mm"));
      }
    }
  }, []);

  const dateFormat = includeTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";

  return (
    <FormControl
      isRequired={rules?.required}
      isInvalid={invalid}
      isDisabled={isDisabled}
    >
      <HStack flex={1}>
        <VStack w={"100%"} space={1}>
          {label && (
            <HStack alignItems="center" justifyContent="space-between">
              <FormControl.Label
                _text={{
                  _light: { color: "darkText" },
                  _dark: { color: "lightText" },
                }}
              >
                {label}
              </FormControl.Label>
              {infoText && (
                <IconModal
                  iconColor="primary.600"
                  icon={<AntDesign name="questioncircleo" />}
                  contentText={infoText}
                />
              )}
            </HStack>
          )}

          <HStack space="4">
            <Box flex={[6, 7]}>
              <Popover
                isOpen={isCalendarOpen}
                onClose={() => setIsCalendarOpen(false)}
                trigger={(triggerProps) => (
                  <DropdownButton
                    disabled={isDisabled}
                    triggerProps={triggerProps}
                    value={selectedDate}
                    onPress={() => setIsCalendarOpen(true)}
                  />
                )}
              >
                <Popover.Content accessibilityLabel="" minW="300">
                  <Box
                    _dark={{ bg: "darkBackground" }}
                    _light={{ bg: "lightBackground" }}
                  >
                    <Calendar
                      initialDate={selectedDate}
                      renderArrow={(dir) =>
                        dir === "left" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      }
                      renderHeader={() => (
                        <HStack>
                          <Menu
                            maxH="300"
                            trigger={(triggerProps) => (
                              <Pressable
                                px="1.5"
                                accessibilityLabel=""
                                {...triggerProps}
                              >
                                <HStack space={0.5} alignItems={"center"}>
                                  <Text fontSize="sm" fontWeight="semibold">
                                    {dayjs(selectedDate).format("MMMM")}
                                  </Text>
                                  <Icon
                                    mt="2px"
                                    as={MaterialIcons}
                                    name="arrow-drop-down"
                                    size="sm"
                                  />
                                </HStack>
                              </Pressable>
                            )}
                          >
                            {dayjs.months().map((month, index) => (
                              <Menu.Item
                                key={month}
                                onPress={() =>
                                  setSelectedDate(
                                    dayjs(selectedDate)
                                      .set("M", index)
                                      .format(dateFormat)
                                  )
                                }
                              >
                                {month}
                              </Menu.Item>
                            ))}
                          </Menu>
                          <Menu
                            maxH="300"
                            trigger={(triggerProps) => (
                              <Pressable
                                px="1.5"
                                accessibilityLabel=""
                                {...triggerProps}
                              >
                                <HStack space={0.5} alignItems={"center"}>
                                  <Text fontSize="sm" fontWeight="semibold">
                                    {dayjs(selectedDate).format("YYYY")}
                                  </Text>
                                  <Icon
                                    mt="2px"
                                    as={MaterialIcons}
                                    name="arrow-drop-down"
                                    size="sm"
                                  />
                                </HStack>
                              </Pressable>
                            )}
                          >
                            {range(minYear, maxYear).map((val) => (
                              <Menu.Item
                                key={val}
                                onPress={() =>
                                  setSelectedDate(
                                    dayjs(selectedDate)
                                      .set("y", val)
                                      .format(dateFormat)
                                  )
                                }
                              >
                                {val}
                              </Menu.Item>
                            ))}
                          </Menu>
                        </HStack>
                      )}
                      markedDates={{
                        [selectedDate]: {
                          selected: true,
                          selectedColor: isDarkMode
                            ? colors?.primary[700]
                            : colors?.secondary[700],
                        },
                      }}
                      minDate={`${minYear}-01-01`}
                      maxDate={`${maxYear}-01-01`}
                      onPressArrowLeft={(subtractMonth) => subtractMonth()}
                      onPressArrowRight={(addMonth) => addMonth()}
                      onDayPress={(date) => setSelectedDate(date.dateString)}
                      theme={{
                        calendarBackground: isDarkMode
                          ? colors?.darkBackground
                          : colors?.lightBackground,
                        dayTextColor: textColor,
                        selectedDayTextColor: "white",
                        textMonthFontWeight: "bold",
                        monthTextColor: textColor,
                      }}
                    />
                    <HStack p="4" space="3" justifyContent="space-between">
                      <Button
                        flex={1}
                        size="sm"
                        borderRadius="full"
                        _text={{ fontWeight: "bold" }}
                        _dark={{
                          bg: "dark.700",
                          _pressed: { bg: "dark.700" },
                          _hover: { bg: "dark.700" },
                        }}
                        _light={{
                          bg: "light.700",
                          _pressed: { bg: "light.700" },
                          _hover: { bg: "light.700" },
                        }}
                        onPress={() =>
                          setSelectedDate(dayjs().format("YYYY-MM-DD"))
                        }
                      >
                        {translate("remove")}
                      </Button>
                      <Button
                        flex={1}
                        size="sm"
                        borderRadius="full"
                        _text={{ fontWeight: "bold" }}
                        _dark={{
                          bg: "primary.700",
                          _hover: { bg: "primary.700" },
                          _pressed: { bg: "primary.700" },
                        }}
                        _light={{
                          bg: "secondary.700",
                          _hover: { bg: "secondary.700" },
                          _pressed: { bg: "secondary.700" },
                        }}
                        onPress={() => setIsCalendarOpen(false)}
                      >
                        {translate("done")}
                      </Button>
                    </HStack>
                  </Box>
                </Popover.Content>
              </Popover>
            </Box>
            {includeTime && (
              <Box flex={[4, 3]}>
                <Popover
                  trigger={(triggerProps) => (
                    <DropdownButton
                      disabled={isDisabled}
                      triggerProps={triggerProps}
                      value={time}
                    />
                  )}
                >
                  <Popover.Content accessibilityLabel="" minW="100" maxH="300">
                    <ScrollView showsVerticalScrollIndicator={false}>
                      <VStack
                        p="3"
                        _light={{ bg: "lightBackground" }}
                        _dark={{ bg: "darkBackground" }}
                      >
                        {range(0, 23).map((val) => {
                          const timeValue = `${val < 10 ? "0" : ""}${val}:00`;
                          return (
                            <TimeOption
                              key={timeValue}
                              value={timeValue}
                              selected={time === timeValue}
                              onPress={() => setTime(timeValue)}
                            />
                          );
                        })}
                      </VStack>
                    </ScrollView>
                  </Popover.Content>
                </Popover>
              </Box>
            )}
          </HStack>

          <Input display="none" value={field.value} onChange={field.onChange} />
          {helperText && (
            <FormControl.HelperText>{helperText}</FormControl.HelperText>
          )}

          <FormControl.ErrorMessage>
            {error?.type == "required" && translate("is_required")}
          </FormControl.ErrorMessage>
        </VStack>
      </HStack>
    </FormControl>
  );
};
