import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUser = (enabled = false) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `user`,
    () =>
      client.get(`me/`).then((resp) => {
        console.debug(`🤖 Me`);
        return resp.data;
      }),
    { enabled }
  );
};
