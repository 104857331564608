import React, { useContext, useEffect, useState } from "react";
import { VStack, HStack, Stack, Divider, useMediaQuery } from "native-base";
import { Linking } from "react-native";
import Constants from "expo-constants";
// utility functions
import { navigateBack, getWindowWidth, translate } from "utils/helpers";
// internal hooks
import { AuthenticationContext } from "services/authentication.context";
import { useProduct } from "features/general/products/hooks/useProduct";
import { useCollectible } from "features/general/collectibles/hooks/useCollectible";
import { useAuction } from "features/general/auctions/hooks/useAuction";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScrollViewWithToggle } from "common/components/layout/scroll-view-with-toggle.component";
import { DownloadImageButton } from "common/components/buttons/download-image-button.component";
import { MusicianListSummary } from "features/accounts/musicians/components/musician-list-summary.component";
import { AuctionCollectibleList } from "features/general/collectibles/components/auction-collectible-list.component";
import { LicenseDetailBenefitList } from "features/royalties/license-benefits/components/license-detail-benefit-list.component";
import { LicenseBasicCollectibleDetailExtraInfo } from "features/royalties/license-collectibles/components/license-collectible-detail-extra-info.component";
import { AuctionDetailImage } from "features/general/auctions/components/auction-detail-image.component";
import { LicenseProductDetailDescription } from "features/royalties/license-products/components/license-product-detail-description.component";
import { CollectibleDetailSection } from "features/general/collectibles/components/collectible-detail.component";
import { CollectibleCharacterList } from "features/general/collectibles/components/collectible-character-list.component";
import { CollectibleRarityList } from "features/general/collectibles/components/collectible-rarity-list.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

// Note: If collectible is not Jan Tenner then remove the hard coded sections.

export const CollectibleDetailBasicScreen = ({ route, navigation }) => {
  const { userDetails } = useContext(AuthenticationContext);
  const { id, initialData = {}, scrollToBenefits = false } = route.params;
  const collectible = useCollectible(id, initialData);
  const product = useProduct(collectible?.data?.product_id);
  const auction = useAuction(product.data?.auction_id);
  const isJanTenner =
    auction.data?.id === Constants.expoConfig.extra.JAN_TENNER_ID;

  const [layout, setLayout] = useState(null);

  const windowWidth = getWindowWidth();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  useEffect(() => {
    if (userDetails && collectible?.isFetched) {
      if (userDetails?.id !== collectible?.data?.owner_id) {
        console.debug(`❌ User is not the Collectible's Owner`);
        navigation.navigate("BottomNavigator", {
          screen: "Home",
        });
      }
    }
  }, [userDetails, collectible?.isFetched]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollViewWithToggle
        layout={layout}
        scrollToBenefits={scrollToBenefits}
        mainChildren={
          <VStack flex={1} space={10} justifyContent={"center"}>
            <AuctionDetailImage
              justifyContent={{ base: "center" }}
              videoUrl={collectible.data?.metadata_json?.animation_url}
              url={collectible.data?.image_with_url?.md?.url}
            />
            <HStack justifyContent={"center"} space={"3"}>
              {isJanTenner && (
                <AppIconButton
                  size={30}
                  iconName={"link-variant"}
                  nativeID={"basic-collectible-community-link"}
                  onPress={() => Linking.openURL("https://bio.to/JanTenner")}
                />
              )}
              <DownloadImageButton
                size={30}
                imageURL={collectible?.data?.image_with_url?.md?.url}
              />
            </HStack>
          </VStack>
        }
        toggleChildren={
          <VStack flex={1} space={5} p={5}>
            <VStack space={5}>
              <LicenseBasicCollectibleDetailExtraInfo
                token_id={collectible.data?.token_id}
                attributes={collectible.data?.metadata_json?.attributes}
                contract_address={collectible.data?.contract_address}
              />
              <Divider />
            </VStack>
            <VStack px={5} space={16}>
              <Stack
                space={{ base: 10, lg: 20 }}
                direction={{ base: "column", lg: "column-reverse" }}
              >
                {collectible?.data?.benefits?.length > 0 && (
                  <VStack
                    onLayout={(event) => {
                      setLayout(event.nativeEvent.layout);
                    }}
                    space={10}
                    nativeID={"collectible-benefits"}
                  >
                    <LicenseDetailBenefitList
                      collectibleBenefits
                      isBasicCollectible={true}
                      benefits={collectible?.data?.benefits}
                    />
                    {!isDesktop && <Divider />}
                  </VStack>
                )}
                <Stack
                  space={10}
                  justifyContent={"space-between"}
                  direction={{ base: "column", lg: "row" }}
                >
                  <Stack flex={{ lg: 0.5 }}>
                    <MusicianListSummary
                      imgStyle={{
                        height: { base: 200, lg: windowWidth * 0.12 },
                        width: {
                          base: windowWidth * 0.9,
                          lg: windowWidth * 0.12,
                        },
                      }}
                      musicians={auction.data?.musicians}
                    />
                  </Stack>
                  <Divider
                    orientation={isDesktop ? "vertical" : "horizontal"}
                  />
                  <Stack flex={{ lg: 0.48 }}>
                    <LicenseProductDetailDescription
                      isCollectible
                      description={product.data?.description}
                    />
                  </Stack>
                </Stack>
              </Stack>
              {!isDesktop && <Divider />}
              <CollectibleDetailSection
                title={translate("about_collection")}
                description={auction.data?.description}
                image={require("assets/images/features/jan-tanner/group.png")}
              />

              {isJanTenner && (
                <>
                  <CollectibleCharacterList />

                  <CollectibleDetailSection
                    title={translate("structure_of_collectibles")}
                    description={translate(
                      "structure_of_collectibles_description"
                    )}
                    image={require("assets/images/features/jan-tanner/card-detail.png")}
                    invert
                  />

                  <CollectibleRarityList />
                </>
              )}

              <AuctionCollectibleList
                isCollectible
                auctionID={product.data?.auction_id}
              />
            </VStack>
          </VStack>
        }
        disabled={!collectible.isFetched}
      />
    </ScreenFrame>
  );
};
