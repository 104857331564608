import React from "react";
import { VStack, FlatList, Text, useMediaQuery } from "native-base";
// internal hooks
import { useMasterRights } from "features/royalties/master-rights/hooks/useMasterRights";
// internal components
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { MasterRightsListCard } from "features/royalties/master-rights/components/master-rights-list-card.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate } from "utils/helpers";

export const MasterRightsListScreen = ({ navigation }) => {
  const { data, isFetched, isFetching, hasNextPage, fetchNextPage } =
    useMasterRights();
  const [isDesktop] = useMediaQuery({ minWidth: 1280 });
  return (
    <ScreenFrame safeAreaTopWithTopBar={false} isAuthenticationRequired>
      <VStack flex={1}>
        <PageTitle
          title={translate("your_splits")}
          helpText={translate("your_splits_help_text")}
        />
        {!isFetched ? (
          <Loading />
        ) : (
          <FlatList
            px="5"
            key={isDesktop ? "horizontal-collection" : "vertical-collection"}
            numColumns={isDesktop ? 3 : 1}
            showsVerticalScrollIndicator={false}
            onEndReached={() =>
              hasNextPage && !isFetching ? fetchNextPage() : null
            }
            onEndReachedThreshold={0.3}
            data={data?.pages.map((page) => page.items).flat()}
            renderItem={({ item }) => <MasterRightsListCard item={item} />}
            ListFooterComponent={
              isFetching && (
                <VStack flex={1} alignItems="center">
                  <CenteredSpinner />
                </VStack>
              )
            }
            ListEmptyComponent={
              <Text textAlign="center" fontSize={["md", "lg"]}>
                {translate("no_splits")}
              </Text>
            }
          />
        )}
      </VStack>
    </ScreenFrame>
  );
};
