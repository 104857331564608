import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useProduct = (productID, initialData = {}) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `product-${productID}`,
    () => {
      console.debug(`🎼 Product ${productID} `);
      return client.get(`/products/${productID}/`).then((resp) => resp.data);
    },
    {
      enabled: !!productID,
      placeholderData: initialData,
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
