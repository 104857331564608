import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useOrderDetails = () => {
  const { client } = useContext(AxiosContext);

  return {
    orderDetails: useMutation(
      (params) => {
        return client.post(`orders/details/`, params).then((resp) => resp.data);
      },
      {
        onSuccess: () => console.debug(`✅ Retrieved Order Details`),
        onError: (error) => console.error(error), // need to cancel ApplePaySession at this point
      }
    ),
  };
};
