import { useForm } from "react-hook-form";
import React, { useContext } from "react";
import { VStack, Text, HStack, Box, ScrollView, Image } from "native-base";
// internal hooks
import { translate, navigateBack } from "utils/helpers";
// internal components
import { Footer } from "navigation/components/footer.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { FormControlInput } from "theme/forms/form-control-input.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { AuthenticationContext } from "services/authentication.context";

export const AdminPhoneLoginScreen = ({ navigation }) => {
  const { control, handleSubmit } = useForm();
  const { onAdminLoginOrRegister, isLoading } = useContext(
    AuthenticationContext
  );

  const fanLogin = (data) => {
    onAdminLoginOrRegister(
      "+" + data.admin_country_code + data.admin_phone_number,
      "+" + data.country_code + data.phone_number,
      data.password,
      "fan",
      navigation
    );
  };
  const artistLogin = (data) => {
    onAdminLoginOrRegister(
      "+" + data.admin_country_code + data.admin_phone_number,
      "+" + data.country_code + data.phone_number,
      data.password,
      "artist",
      navigation
    );
  };

  return (
    <ScreenFrame safeAreaTop={true}>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <VStack space={8} flex={1} px={5} pt={5}>
          <VStack
            flex={1}
            space={5}
            alignItems={["flex-start"]}
            justifyContent={["flex-start"]}
          >
            <Image
              h={16}
              w={40}
              resizeMode={"contain"}
              alt={"Biddz Black Logo"}
              source={require("assets/images/biddz-theme/biddz-extended-black.png")}
            />
            <VStack space={3} alignItems={["flex-start"]}>
              <Text fontWeight={"bold"} fontSize={["2xl", "3xl"]}>
                {"Los geht's"}
              </Text>
              <Text fontWeight={"light"} fontSize={["md", "lg"]}>
                {translate("login_subtitle")}
              </Text>
            </VStack>
            <PhoneNumberInput
              title={"Admin Number"}
              extensionName={"admin_country_code"}
              name={"admin_phone_number"}
              control={control}
            />
            <PhoneNumberInput
              title={"Impersonation Number"}
              extensionName={"country_code"}
              name={"phone_number"}
              control={control}
            />
            <VStack>
              <Text bold mb={2}>
                Admin Password
              </Text>
              <Box flex={1}>
                <FormControlInput
                  size="xl"
                  value={""}
                  justifyContent="center"
                  keyboardType="phone-pad"
                  autoComplete={"off"}
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </Box>
            </VStack>
          </VStack>
          <VStack space={3} mb={3}>
            <PrimaryButton
              disabled={isLoading}
              isLoading={isLoading}
              nativeID={"login-as-fan"}
              onPress={handleSubmit(fanLogin)}
              space={{ base: 3, sm: 5, md: 10 }}
              fontSize={{ base: "md", lg: "lg" }}
              text={translate("continue_as_fan")}
              logoLeftStyle={{ width: 60, height: 30 }}
              logoLeft={require("assets/images/biddz-theme/biddz-extended-white.png")}
            />
            <SecondaryButton
              nativeID="login-as-artist"
              fontSize={{ base: "md", lg: "lg" }}
              logoLeftComponent={
                <Image
                  width={50}
                  height={10}
                  resizeMode="contain"
                  alt="Button Logo"
                  _dark={{
                    source: require("assets/images/biddz-theme/biddz-for-artists-logo-white.png"),
                  }}
                  _light={{
                    source: require("assets/images/biddz-theme/biddz-for-artists-logo-black.png"),
                  }}
                />
              }
              space={{ base: 3, sm: 5, md: 10 }}
              isLoading={isLoading}
              disabled={isLoading}
              p={0}
              text={translate("continue_as_artist")}
              onPress={handleSubmit(artistLogin)}
            />
          </VStack>
        </VStack>
        <Footer py={2} />
      </ScrollView>
    </ScreenFrame>
  );
};

const PhoneNumberInput = ({ title, extensionName, name, control }) => {
  return (
    <VStack>
      <Text bold mb={2}>
        {title}
      </Text>
      <HStack space={1} width="100%">
        <Box flex={2}>
          <FormControlInput
            size="lg"
            height="10"
            value={"49"}
            placeholder="49"
            control={control}
            name={extensionName}
            keyboardType={"phone-pad"}
            rules={{
              required: true,
              minLength: 1,
              maxLength: 2,
            }}
            InputLeftElement={
              <Text pl={2} fontSize={["md", "lg"]}>
                +
              </Text>
            }
          />
        </Box>
        <Box flex={7}>
          <FormControlInput
            size="lg"
            height="10"
            value={""}
            autoComplete={"off"}
            name={name}
            keyboardType={"phone-pad"}
            placeholder={"176 456 87 910"}
            control={control}
            rules={{
              required: true,
              minLength: 9,
            }}
          />
        </Box>
      </HStack>
    </VStack>
  );
};
