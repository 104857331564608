import React, { useState, useRef } from "react";
import { Text, VStack, HStack, Center } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import dayjs from "dayjs";
import { IconModal } from "theme/feedback/icon-modal.component";
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { Swiper } from "common/components/layout/swiper.component";
import { translate, getLocalizedNumber } from "utils/helpers";

const Slide = ({ value }) => (
  <Center
    py="4"
    _light={{ bg: "light.100" }}
    _dark={{ bg: "dark.800" }}
    borderRadius="xl"
  >
    <Text fontSize={["xl", "2xl"]} fontWeight="semibold">
      {`${value} EUR`}
    </Text>
  </Center>
);

export const EstimatedEarningSwiper = ({ earnings }) => {
  const swiper = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const { day, week, month } = earnings;

  const getLastDay = () =>
    day && day.length ? dayjs(day[day.length - 1].day).format("DD MMMM") : "";

  const getLastMonth = () => {
    if (month && month.length) {
      const lastMonthObj = dayjs().subtract(1, "month");
      const lastMonth = month.find(
        (obj) =>
          dayjs(obj.month, "YYYY.MM.DD").month() === lastMonthObj.month() &&
          dayjs(obj.month, "YYYY.MM.DD").year() === lastMonthObj.year()
      );
      return lastMonth
        ? {
            date: dayjs(lastMonth.month, "YYYY.MM.DD").format("MMMM YYYY"),
            value: getLocalizedNumber(lastMonth.value),
          }
        : { date: "", value: 0 };
    }
    return { date: "", value: 0 };
  };

  const getCurrentMonthEarnings = () => {
    if (month && month.length) {
      const currentMonth = month.find(
        (obj) =>
          dayjs(obj.month, "YYYY.MM.DD").month() === dayjs().month() &&
          dayjs(obj.month, "YYYY.MM.DD").year() === dayjs().year()
      );
      return currentMonth ? getLocalizedNumber(currentMonth.value) : 0;
    }
    return 0;
  };

  const getLatestEarnings = (data) =>
    data && data.length ? getLocalizedNumber(data[data.length - 1].value) : 0;

  return (
    <VStack
      px="5"
      py="7"
      h="300"
      space="4"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      borderRadius="xl"
      borderWidth={1}
      borderColor="dark.800"
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Text fontSize="2xl" bold>
          {translate("estimated_income")}
        </Text>
        <IconModal
          iconColor="primary.600"
          icon={<AntDesign name="questioncircleo" />}
          contentText={translate("estimated_income_help_text")}
        />
      </HStack>

      <TabNavigator
        activeTab={activeTab}
        setActiveTab={(idx) => {
          swiper.current?.goTo(idx);
          setActiveTab(idx);
        }}
        options={[translate("week"), translate("month")]}
      />

      <Swiper ref={swiper} onIndexChanged={(idx) => setActiveTab(idx)}>
        <Slide value={getLatestEarnings(week)} />
        <Slide value={getCurrentMonthEarnings()} />
      </Swiper>
    </VStack>
  );
};
