import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { VStack, ScrollView, Toast, Text } from "native-base";
import dayjs from "dayjs";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
import useUpdateAuction from "features/general/auctions/hooks/useUpdateAuction";
import { useUpdateProduct } from "features/royalties/license-products/hooks/useUpdateProduct";
import useDeleteAuction from "features/general/auctions/hooks/useDeleteAuction";
// internal components
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { FormControlTextarea } from "theme/forms/form-control-textarea.component";
import { FormControlDatePicker } from "theme/forms/form-control-date-picker.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { DeleteButton } from "common/components/buttons/delete-button.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// internal functions
import { translate } from "utils/helpers";

export const AuctionEditInfoScreen = ({ navigation, route }) => {
  const { id: auctionID, auctionPartials = {} } = route.params;
  const { control, handleSubmit } = useForm();
  const { data: auction, isFetched } = useAuction(auctionID, auctionPartials);
  const product = auction?.products && auction?.products[0];
  const { updateAuction } = useUpdateAuction(auctionID);
  const { updateProduct } = useUpdateProduct(product?.id);
  const { deleteAuction } = useDeleteAuction(auctionID);
  const releaseDate = product?.license_product?.release?.date;
  const auctionStart =
    auction?.start_date ||
    dayjs(releaseDate).subtract(7, "d").hour(18).toString();
  const auctionStartDay = dayjs(auctionStart).get("d");
  const dayToSubtract =
    auctionStartDay === 3 ? 2 : auctionStartDay === 2 ? 4 : 3;
  const upcomingDate =
    auction?.upcoming_at || auctionStart
      ? dayjs(auctionStart).subtract(dayToSubtract, "d").toString()
      : null;

  const navigateForward = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditFinancials",
      params: { id: auctionID, auctionPartials: auction },
    });
  };

  const validate = (data) => {
    const { upcoming_at, release_date, start_date, end_date } = data;
    let error = null;

    if (dayjs(upcoming_at).isAfter(release_date)) {
      error = translate("upcoming_date_validation");
    } else if (dayjs(upcoming_at).isAfter(start_date)) {
      error = translate("sale_start_date_validation");
    } else if (dayjs(end_date).isBefore(start_date)) {
      error = translate("invalid_auction_end_date");
    } else if (
      dayjs(end_date).diff(start_date, "month", true) < 0 ||
      dayjs(end_date).diff(start_date, "month", true) > 3
    ) {
      error = translate("auction_period_validation");
    }
    if (!!error) {
      Toast.show({
        placement: "top",
        render: ({ id }) => (
          <ToastAlert closable id={id} status="error" description={error} />
        ),
      });
      return false;
    } else return true;
  };

  const getParams = (data) => ({
    auction: {
      upcoming_at: dayjs(data.upcoming_at).format(),
      start_date: dayjs(data.start_date).format(),
      end_date: dayjs(data.end_date).format(),
    },
    product: {
      description: data.description,
      license_product: {
        // set as start date the release date or the auction start date depending on which is later
        start_date: dayjs(data.release_date).isAfter(data.start_date)
          ? data.release_date
          : dayjs(data.start_date).format("YYYY-MM-DD"),
        duration_in_years: 4,
      },
    },
  });

  const onPress = async (data) => {
    const valid = validate(data);
    if (valid) {
      if (["draft", "action_required"].includes(auction?.status)) {
        const params = getParams(data);
        await updateAuction.mutateAsync(params.auction);
        await updateProduct.mutateAsync(params.product);
        navigateForward();
      } else navigateForward();
    }
  };

  const onSave = (data) => {
    const valid = validate(data);
    if (valid && ["draft", "action_required"].includes(auction?.status)) {
      const params = getParams(data);
      updateAuction.mutate(params.auction);
      updateProduct.mutate(params.product);
    }
  };

  useEffect(() => {
    if (deleteAuction.isSuccess) {
      navigation.replace("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, [deleteAuction.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: { screen: "ReleaseDeals" },
            }),
        }}
        customHeaderRight={["draft", "action_required"].includes(
          auction?.status
        )}
        headerRight={
          <DeleteButton
            nativeID="delete-auction"
            isLoading={deleteAuction.isLoading}
            modalTitle={translate("delete_deal_modal_title")}
            modalDescription={translate("delete_deal_modal_description")}
            onPress={() => {
              deleteAuction.mutate();
            }}
          />
        }
        profileProgress={{ type: "deal", activeIndex: 0 }}
      />
      <VStack flex={1}>
        <ScrollView
          flex={1}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <PageTitle
            nativeID="save-deal-info"
            isDisabled={updateAuction.isLoading || !isFetched}
            title={translate("deal_information")}
            onSave={handleSubmit(onSave)}
          />
          {!isFetched ? (
            <Loading />
          ) : (
            <ResponsiveStack space="10">
              <VStack
                p="5"
                space="5"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
                flex={{ lg: 1 }}
              >
                <FormControlDatePicker
                  isDisabled
                  name="release_date"
                  label={translate("release_date")}
                  rules={{ required: true }}
                  value={releaseDate}
                  control={control}
                />
                <FormControlDatePicker
                  isDisabled={
                    !["draft", "action_required"].includes(auction?.status)
                  }
                  name="upcoming_at"
                  label={translate("upcoming_date")}
                  includeTime={true}
                  rules={{ required: true }}
                  value={upcomingDate}
                  control={control}
                  minYear={2023}
                  infoText={translate("upcoming_date_info_text")}
                />
                <FormControlDatePicker
                  isDisabled={
                    !["draft", "action_required"].includes(auction?.status)
                  }
                  name="start_date"
                  label={translate("sale_start_date")}
                  includeTime={true}
                  value={auctionStart}
                  control={control}
                  minYear={2023}
                  rules={{ required: true }}
                  infoText={translate("auction_duration_info_text")}
                />
                <FormControlDatePicker
                  isDisabled={
                    !["draft", "action_required"].includes(auction?.status)
                  }
                  name="end_date"
                  label={translate("auction_end_date")}
                  includeTime={true}
                  value={
                    auction?.end_date ||
                    dayjs(auctionStart).add(12, "w").toString()
                  }
                  control={control}
                  minYear={2023}
                  rules={{ required: true }}
                />
              </VStack>
              <VStack
                p="5"
                flex={{ lg: 1 }}
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <FormControlTextarea
                  isDisabled={
                    !["draft", "action_required"].includes(auction?.status)
                  }
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  value={product?.description}
                  label={translate("description")}
                  placeholder={translate(
                    "auction_description_placeholder_text"
                  )}
                />
              </VStack>
            </ResponsiveStack>
          )}
          <Text m="10" p="5" fontSize="md" color="light.700" textAlign="center">
            {translate("auction_info_help_text_bottom")}
          </Text>
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        disabled={updateAuction.isLoading || !isFetched}
        isLoading={updateAuction.isLoading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
