import React, { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Image,
  Input,
  FlatList,
  Pressable,
  View,
} from "native-base";
import {
  getAvailableUnitsListFromProduct,
  getLocalizedNumber,
  translate,
} from "utils/helpers";
import { LicenseProductOrderCreateBenefits } from "features/royalties/license-product-orders/components/license-product-order-create-benefits.component";

export const LicenseProductOrderCreateFormInfo = ({
  units,
  product,
  setUnits,
}) => {
  const [error, setError] = useState(false);
  const minUnits =
    product?.min_units_per_order &&
    product?.min_units_per_order <= product?.available_units
      ? product.min_units_per_order
      : 1;
  const maxUnits = Math.min(
    product?.max_units_per_buyer,
    product?.available_units
  );

  const availableUnitsList = getAvailableUnitsListFromProduct(product);
  const isFreeLicense = product?.price_per_unit === 0;

  const parseUnits = (units) => {
    if (!units || isNaN(units)) {
      units = 0;
    }
    return parseInt(units);
  };

  useEffect(() => {
    setError(!units || units < minUnits || (maxUnits && units > maxUnits));
  }, [units]);

  return (
    <VStack space={3}>
      <Text fontSize={["md", "lg"]} bold>
        {translate("choose_number_of_biddz")}
      </Text>
      <VStack space={2} p={1}>
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Input
            p={3}
            mx={0}
            flex={1}
            value={units}
            // maxLength={4}
            rounded={"xl"}
            borderWidth={1}
            fontSize={"lg"}
            bgColor={"light.100"}
            returnKeyType={"done"}
            keyboardType={"numeric"}
            isDisabled={isFreeLicense}
            defaultValue={units.toString()}
            color={error ? "danger.400" : "darkText"}
            borderColor={error ? "danger.400" : "gray.300"}
            onChangeText={(val) => setUnits(parseUnits(val))}
            // placeholder={translate("biddz_input_placeholder")}
            placeholder={translate("enter_amount")}
            focusOutlineColor={error ? "danger.400" : "secondary.700"}
            InputLeftElement={
              <View style={{ paddingLeft: 12 }}>
                <Image
                  alt="icon"
                  size={6}
                  rounded={"full"}
                  source={require("assets/images/icons/biddz-secondary-icon.png")}
                />
              </View>
            }
          />
          <Text mx={3} fontSize={["xs", "sm"]} lineHeight={10}>
            {`=`}
          </Text>
          <AmountDisplayItem
            leftImage={require("assets/images/icons/euro-purple-icon.png")}
            value={
              getLocalizedNumber(
                Math.round(units * product?.price_per_unit * 100) / 100
              ) || 0
            }
          />
        </HStack>
        <Text color={"gray.500"} fontSize={"xs"}>{`${getLocalizedNumber(
          (units / 10_000) * 100
        )}${translate("pct_of_track_royalties")}`}</Text>
      </VStack>
      {product?.max_units_per_buyer !== 1 && availableUnitsList?.length > 0 && (
        <VStack space={2}>
          <Text px={2} fontSize={["sm", "md"]} bold>
            {translate("choose_other_amount_text")}
          </Text>
          <FlatList
            numColumns={3}
            data={availableUnitsList}
            renderItem={({ item, index }) => {
              const selected = units === item;
              return (
                <Pressable
                  p={4}
                  m={2}
                  flex={1}
                  key={index}
                  rounded={"2xl"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onPress={() => setUnits(parseUnits(selected ? 0 : item))}
                  bg={selected ? "secondary.500" : "light.300"}
                >
                  <Text bold>{item}</Text>
                </Pressable>
              );
            }}
          />
        </VStack>
      )}
      <LicenseProductOrderCreateBenefits
        units={units}
        productBenefits={product?.benefits}
      />
    </VStack>
  );
};

const AmountDisplayItem = ({ error, value, leftImage, rightCompnent }) => {
  return (
    <HStack
      p={3}
      flex={1}
      rounded={"xl"}
      borderWidth={0.5}
      alignItems={"center"}
      justifyContent={"space-between"}
      borderColor={error ? "danger.400" : "gray.300"}
    >
      <HStack space={3} alignItems={"center"}>
        {leftImage && (
          <Image alt="icon" size={6} rounded={"full"} source={leftImage} />
        )}
        <Text
          fontSize={"md"}
          fontWeight={500}
          color={error ? "danger.400" : "darkText"}
        >
          {value}
        </Text>
      </HStack>
      {rightCompnent}
    </HStack>
  );
};
