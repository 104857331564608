import React, { useState, useEffect } from "react";
import { VStack, HStack, Text } from "native-base";
import { useForm, useController } from "react-hook-form";
import dayjs from "dayjs";
// internal hooks
import { useUserLicenseCollectibleEarnings } from "features/royalties/license-collectibles/hooks/useUserLicenseCollectibleEarnings";
import { useUserLicenseCollectibleStreams } from "features/royalties/license-collectibles/hooks/useUserLicenseCollectibleStreams";
import { useUserLicenseCollectibleEstimatedEarnings } from "features/royalties/license-collectibles/hooks/useUserLicenseCollectibleEstimatedEarnings";
// internal components
import { LicenseCollectibleAnalyticsChartHeader } from "features/royalties/license-collectibles/components/license-collectible-analytics-chart-header.component";
import { LicenseCollectibleAnalyticsChart } from "features/royalties/license-collectibles/components/license-collectible-analytics-chart.component";
import { SkeletonLicenseEarningsChart } from "features/royalties/license-collectibles/skeletons/license-earnings-chart.skeleton";
import { LicenseCollectibleAnalyticsBarChart } from "features/royalties/license-collectibles/components/license-collectible-analytics-bar-chart.component";
// utility functions
import { translate } from "utils/helpers";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const LicenseCollectibleAnalytics = ({
  params = {},
  showsHeader = true,
  releaseDate = undefined,
  licenseStartDate = undefined,
  title = translate("shares_chart_title"),
}) => {
  const { control } = useForm();
  const { field: isAccumulative } = useController({
    control,
    name: "is-accumulative",
  });

  const streams = useUserLicenseCollectibleStreams();
  const earnings = useUserLicenseCollectibleEarnings();
  const estimatedEarnings = useUserLicenseCollectibleEstimatedEarnings();

  const [timeFrame, setTimeFrame] = useState("month");
  const [showEarnings, setShowEarnings] = useState(false);

  const dates = { licenseStart: licenseStartDate, release: releaseDate };

  const streamsBeforeReleaseDate =
    streams.data &&
    dates?.release &&
    streams.data["day"].filter(
      (item) =>
        dayjs(item["day"], "DD.MM.YYYY").isBefore(
          dayjs(dates?.release, "YYYY-MM-DD")
        ) && item
    );

  const sumOfStreams =
    streamsBeforeReleaseDate?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value;
    }, 0) || 0;

  const streamsAtReleaseDate =
    streams.data &&
    dates?.release &&
    streams.data["day"].find((item) =>
      dayjs(item["day"], "DD.MM.YYYY").isSame(
        dayjs(dates?.release, "YYYY-MM-DD")
      )
    );

  const indexOfStreamsAtReleaseDate =
    streams.data &&
    dates?.release &&
    streams.data["day"].indexOf(streamsAtReleaseDate);

  useEffect(() => {
    earnings.mutate(params);
    streams.mutate(params);
  }, []);

  useEffect(() => {
    if (earnings.isSuccess) {
      const end = dayjs().format("YYYY-MM-DD");
      let start;
      if (earnings.data["month"].length > 0) {
        const lastMonth = dayjs(
          earnings.data["month"][earnings.data["month"].length - 1]["month"],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        start = dayjs(lastMonth).add(1, "M").format("YYYY-MM-DD");
      }
      estimatedEarnings.mutate({ ...params, start, end });
    }
  }, [earnings.isSuccess]);

  return (
    <VStack space={3}>
      {showsHeader && (
        <Text fontSize={"2xl"} fontWeight={600}>
          {translate("statistics")}
        </Text>
      )}
      <VStack flex={1} p={5} space={5} bg={"gray.100"} rounded={"xl"}>
        <HStack justifyContent={"space-between"}>
          <LicenseCollectibleAnalyticsChartHeader
            title={title}
            timeFrame={timeFrame}
            showEarnings={showEarnings}
            isAccumulative={isAccumulative}
            earnings={earnings.data?.total || 0}
            estimatedEarnings={estimatedEarnings.data}
            onChange={() => setShowEarnings(!showEarnings)}
            streams={streams.data?.total - sumOfStreams || 0}
            data={showEarnings ? earnings.data : streams.data}
          />
        </HStack>
        {showEarnings ? (
          earnings.isLoading ? (
            <SkeletonLicenseEarningsChart />
          ) : (
            <LicenseCollectibleAnalyticsBarChart
              timeFrame={timeFrame}
              setScrollEnabled={() => {}}
              yAxisLabel={isAccumulative.value ? "cum_value" : "value"}
              earnings={
                earnings.data
                  ? earnings.data
                  : { day: [], week: [], month: [], year: [] }
              }
              estimatedEarnings={
                estimatedEarnings.data && timeFrame === "month"
                  ? estimatedEarnings.data
                  : { day: [], week: [], month: [], year: [] }
              }
            />
          )
        ) : streams.isLoading ? (
          <SkeletonLicenseEarningsChart />
        ) : (
          <LicenseCollectibleAnalyticsChart
            dates={dates}
            setScrollEnabled={() => {}}
            yAxisLabel={isAccumulative.value ? "cum_value" : "value"}
            cumValueBeforeReleaseDate={
              (streams.data &&
                indexOfStreamsAtReleaseDate > 0 &&
                streams.data["day"][indexOfStreamsAtReleaseDate - 1]
                  .cum_value) ||
              0
            }
            streams={
              streams.data
                ? streams.data
                : { day: [], week: [], month: [], year: [] }
            }
          />
        )}
      </VStack>
    </VStack>
  );
};
