import React from "react";
import { Text, VStack, Divider, HStack } from "native-base";
import { getLocalizedDate, getLocalizedNumber, translate } from "utils/helpers";

interface IProps {
  item: any;
}

export const PayoutDetailCard = ({ item }: IProps) => (
  <VStack space={1} justifyContent="space-evenly" width="100%">
    <HStack space={1} alignContent="center" justifyContent="space-between">
      <Text
        textTransform="uppercase"
        fontWeight="bold"
        fontSize={["md", "lg"]}
        width="50%"
        noOfLines={1}
      >
        {translate("payout")}
      </Text>
      <Text fontWeight="bold" fontSize={["md", "lg"]}>
        + {getLocalizedNumber(item?.transferred_amount?.toFixed(2))} €
      </Text>
    </HStack>
    <Text fontWeight="normal">{getLocalizedDate(item.payout_timestamp)}</Text>
    <Divider my={5} />
  </VStack>
);
