import React from "react";
import {
  FormControl,
  Input as NBInput,
  HStack,
  VStack,
  Text,
} from "native-base";
import { useController } from "react-hook-form";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate } from "utils/helpers";

export const FormControlInput = ({
  name,
  control,
  label = "",
  rules = {},
  placeholder = "",
  isRequired = true,
  isDisabled = false,
  value = undefined,
  width = undefined,
  infoText = undefined,
  helperText = undefined,
  onChangeText = undefined,
  InputLeftElement = undefined,
  borderRadius = "lg",
  ...props
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });

  return (
    <FormControl
      isRequired={rules?.required}
      isInvalid={invalid}
      isDisabled={isDisabled}
    >
      <HStack flex={1}>
        <VStack w={"100%"} space={1} pl={1}>
          {label && (
            <HStack alignItems="center" justifyContent="space-between">
              <HStack space="2" alignItems="center">
                <FormControl.Label
                  maxW={{ base: "270px", md: "100%" }}
                  _text={{
                    _light: { color: "darkText" },
                    _dark: { color: "lightText" },
                  }}
                >
                  {label}
                </FormControl.Label>
                {!rules?.required && (
                  <Text color="primary.600">{`(${translate(
                    "optional"
                  )})`}</Text>
                )}
              </HStack>

              {infoText && (
                <IconModal
                  iconColor="primary.600"
                  icon={<AntDesign name="questioncircleo" />}
                  contentText={infoText}
                />
              )}
            </HStack>
          )}
          <NBInput
            size={"xl"}
            width={width}
            value={field.value}
            autoCapitalize={"none"}
            placeholder={placeholder}
            borderRadius={borderRadius}
            onChangeText={(text) => {
              field.onChange(text);
              onChangeText && onChangeText(text);
            }}
            InputLeftElement={InputLeftElement}
            isRequired={!!rules?.required && isRequired}
            {...props}
          />
          <FormControl.ErrorMessage>
            {error?.type == "custom" ||
              (error?.type === "pattern" && error?.message)
              ? error["message"]
              : ""}
            {error?.type == "required" && translate("is_required")}
            {!["required", "min", "max", "custom"].includes(error?.type) &&
              translate("invalid_input")}

            {rules["min"] &&
              rules["max"] &&
              ["min", "max"].includes(error?.type) &&
              translate("min_max_invalid_input", {
                min: rules?.min,
                max: rules?.max,
              })}
          </FormControl.ErrorMessage>
          {helperText && (
            <FormControl.HelperText>{helperText}</FormControl.HelperText>
          )}
        </VStack>
      </HStack>
    </FormControl>
  );
};
