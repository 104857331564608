import React from "react";
// internal components
import { AuctionListCardLicense } from "./auction-list-card-license.component";
import { AuctionListCardTicket } from "./auction-list-card-ticket.component";
// helpers
import { isBasicCollectible } from "utils/helpers";

export const AuctionCard = ({ auction, onPress }) => {
  const isLicense = auction?.type?.includes("license");
  const isCollectible = isBasicCollectible(auction?.type);

  if (isLicense || isCollectible) {
    return <AuctionListCardLicense auction={auction} onPress={onPress} />;
  } else {
    return <AuctionListCardTicket auction={auction} onPress={onPress} />;
  }
};
