import React from "react";
import { Center, HStack, Pressable, Text, ScrollView } from "native-base";

export const TabNavigator = ({ options, activeTab, setActiveTab }) => (
  <HStack maxW={"100%"} justifyContent={"center"}>
    <Center maxW={"100%"}>
      <HStack
        p={2.5}
        space={2}
        maxW={"100%"}
        borderRadius={"full"}
        bg={"darkBackground"}
      >
        <ScrollView
          horizontal
          nestedScrollEnabled
          borderRadius={"full"}
          showsHorizontalScrollIndicator={false}
        >
          {options.map((option, index) => (
            <Pressable
              key={index}
              borderRadius={"full"}
              onPress={() => setActiveTab(index)}
              bg={index === activeTab ? "dark.700" : "transparent"}
            >
              <Text
                px={4}
                py={2}
                color={"lightText"}
                fontSize={["md", "lg"]}
                bold={index === activeTab}
              >
                {option}
              </Text>
            </Pressable>
          ))}
        </ScrollView>
      </HStack>
    </Center>
  </HStack>
);
