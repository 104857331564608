import React from "react";
import {
  HStack,
  VStack,
  Box,
  Text,
  Icon,
  Pressable,
  Checkbox,
  ChevronRightIcon,
} from "native-base";
import { Feather } from "@expo/vector-icons";
// utility functions
import { translate } from "utils/helpers";
// internal hooks
import { useUpdateBenefitCollectable } from "features/general/auctions/hooks/useUpdateBenefitCollectable";

export const BenefitCard = ({ title, count, total, onPress, type = "" }) => {
  return (
    <Pressable my={2} borderRadius={"xl"} onPress={onPress}>
      <HStack p={3} space={3} justifyContent={"sace-between"}>
        <HStack space={3}>
          {count === total && total !== 0 ? (
            <Icon
              mt={1}
              size={"md"}
              as={Feather}
              name={"check"}
              color={"primary.900"}
            />
          ) : (
            <Box w={2.5}></Box>
          )}
          <VStack space={1}>
            <Text fontSize={["mg", "lg"]} bold>
              {title}
            </Text>
            <HStack space={1}>
              <Text fontSize={["sm", "md"]} color={"primary.600"}>
                {type?.value !== "custom" ? `${count}/${total}` : total}
              </Text>
              <Text fontSize={["sm", "md"]}>
                {translate("fans_receive_benefits")}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        {total !== 0 &&
          type?.value !== "custom" &&
          type?.requires_user_input && (
            <ChevronRightIcon size={5} color={"white"} />
          )}
      </HStack>
    </Pressable>
  );
};

export const BenefitCollectableCard = ({ benefit }) => {
  const { updateBenefitCollectable } = useUpdateBenefitCollectable(benefit);
  return (
    <Box px={3} my={1.5} bg={"light.100"} borderRadius={"md"}>
      <Checkbox
        my={2}
        value={""}
        size={"md"}
        flexDirection={"row-reverse"}
        justifyContent={"space-between"}
        defaultIsChecked={!!benefit.delivered_at}
        onChange={(v) => updateBenefitCollectable.mutate({ is_delivered: v })}
      >
        <Text fontSize={["md", "lg"]}>{benefit.user_input}</Text>
      </Checkbox>
    </Box>
  );
};
