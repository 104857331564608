import React from "react";
import { Text, VStack, FlatList } from "native-base";
// internal hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useDeleteReleaseTrack from "features/royalties/tracks/hooks/useDeleteReleaseTrack";
import useUpdateTracksOrder from "features/royalties/tracks/hooks/useUpdateTracksOrder";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { TrackCard } from "features/royalties/releases/components/track-card.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseEditTracksScreen = ({ navigation, route }) => {
  const { id: releaseID, releasePartial = {} } = route.params;
  const { data: release, isLoading: releaseLoading } = useRelease(
    releaseID,
    releasePartial
  );
  const { deleteReleaseTrack } = useDeleteReleaseTrack(release);
  const { updateTracksOrder } = useUpdateTracksOrder(release);

  const swapElements = (arr, index1, index2) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  };

  const moveTrack = (trackId, direction = "down") => {
    const tracks = release?.tracklisting?.map((item) => item.track);
    const trackIndex = release?.tracklisting?.findIndex(
      (item) => item.track?.id === trackId
    );
    const otherIndex = direction === "down" ? trackIndex + 1 : trackIndex - 1;
    swapElements(tracks, otherIndex, trackIndex);
    updateTracksOrder.mutate({ track_ids: tracks.map((track) => track.id) });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("track_list")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => {
            navigation.navigate("Releases", {
              screen: "ReleaseEditCoverArt",
              params: { id: release?.id, releasePartial: release },
            });
          },
        }}
        profileProgress={{ type: "release", activeIndex: 2 }}
      />
      <VStack flex={1} px={5} space={5}>
        <FlatList
          key={"vertical-collection"}
          showsVerticalScrollIndicator={false}
          data={release?.tracklisting}
          renderItem={({ item, index }) => (
            <TrackCard
              key={item.id}
              disabled={
                updateTracksOrder.isLoading || deleteReleaseTrack.isLoading
              }
              navigation={navigation}
              index={index + 1}
              track={item?.track}
              release={release}
              deleteTrack={deleteReleaseTrack}
              moveTrack={moveTrack}
            />
          )}
          ListHeaderComponent={
            <PageTitle
              title={translate("track_list")}
              helpText={translate("select_existing_track_helper_text")}
            />
          }
          ListEmptyComponent={
            <Text flex={1} mt={10} fontSize={["md", "lg"]} textAlign="center">
              {translate("no_tracks")}
            </Text>
          }
        />
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        showBackButton
        disabled={!release?.tracklisting?.length}
        text={translate("next")}
        onPress={() => {
          navigation.navigate("Releases", {
            screen: "ReleaseEditDistribution",
            params: { id: release?.id, releasePartial: release },
          });
        }}
      />
    </ScreenFrame>
  );
};
