import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useUserMusician = (initialData = {}) => {
  const { client } = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery(
    `musician`,
    () =>
      client.get(`me/musicians/`).then((resp) => {
        console.debug(`🎸 Musician Profile`);
        return resp.data;
      }),
    {
      staleTime: 300000,
      enabled: isAuthenticated,
      placeholder: initialData,
      refetchOnWindowFocus: false,
    }
  );
};
