import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useProductBenefits = (productId, initialData = {}) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    `product-benefits-${productId}`,
    () =>
      client.get(`products/${productId}/benefits/`).then((resp) => resp.data),
    {
      enabled: !!productId,
      placeholderData: initialData,
      staleTime: 5000,
    }
  );
};
