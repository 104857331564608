import React from "react";
import { FormControl } from "native-base";
//internal components
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
//helper functions
import { translate } from "utils/helpers";
import { FormControlInput } from "theme/forms/form-control-input.component";

interface IProps {
  name: string;
  control: any;
  value: any;
  labelKey: string;
  placeholderKey: string;
  helperKey: string;
  isLoading: boolean;
}

export const ProfileEditInputField = ({
  control,
  value,
  labelKey,
  placeholderKey,
  helperKey,
  name,
  isLoading,
}: IProps) => (
  <SkeletonFormControlInput
    isLoading={isLoading}
    label={translate(labelKey)}
    helperText={translate(helperKey)}
  >
    <FormControl>
      <FormControl.Label>{translate(labelKey)}</FormControl.Label>
      <FormControlInput
        name={name}
        control={control}
        value={value}
        placeholder={translate(placeholderKey)}
      />
      <FormControl.HelperText>{translate(helperKey)}</FormControl.HelperText>
    </FormControl>
  </SkeletonFormControlInput>
);
