import React from "react";
import { HStack } from "native-base";
import { openBrowserAsync } from "expo-web-browser";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { AppIconButtonWithOutline } from "theme/buttons/app-icon-button-with-outline";

export const MusicianDetailHeaderSocials = ({
  socials,
  spotifyURL,
  justifyContent = undefined,
  iconSize = 4,
}) => {
  return (
    socials &&
    Object.keys(socials).length > 0 && (
      <HStack maxH={10} flex={1} space={1} justifyContent={justifyContent}>
        {spotifyURL && (
          <AppIconButtonWithOutline
            size={iconSize}
            name={"spotify"}
            onPress={() => openBrowserAsync(spotifyURL)}
          />
        )}
        {Object.keys(socials).map((social, index) => {
          return (
            <AppIconButtonWithOutline
              size={iconSize}
              key={index}
              name={social}
              onPress={() => openBrowserAsync(socials[social]?.url)}
              as={social === "tiktok" ? FontAwesome5 : MaterialCommunityIcons}
            />
          );
        })}
      </HStack>
    )
  );
};
