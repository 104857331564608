import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTourAuctions = (params, size = 8) => {
  const { client } = useContext(AxiosContext);

  return useInfiniteQuery(
    ["tour-auctions", params?.tour_id],
    ({ pageParam = 1 }) =>
      client
        .get(`auctions/?page=${pageParam}&size=${size}`, {
          params: params,
        })
        .then((resp) => {
          console.debug(`💰 Auctions | ${JSON.stringify(params)}`);
          return resp.data;
        }),
    {
      enabled: true,
      staleTime: 300000,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
