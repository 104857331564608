import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

const useRequestOffer = () => {
  const { client } = useContext(AxiosContext);

  return {
    getEstimation: useMutation((params) =>
      client.get(`deal_calculator/`, { params }).then((resp) => resp.data)
    ),
  };
};

export default useRequestOffer;
