import React, { useState, useContext } from "react";
import {
  Text,
  VStack,
  HStack,
  Pressable,
  ChevronDownIcon,
  ChevronUpIcon,
} from "native-base";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
// internal components
import { TrackMasterRightStatus } from "features/royalties/tracks/components/track-master-right-status.component";
// utility functions
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";

const parseMS = (ms: number) => {
  const m = Math.floor(ms / 60000);
  const s = Number(((ms % 60000) / 1000).toFixed(0));
  return m + ":" + (s < 10 ? "0" : "") + s;
};

const Item = ({ title, value }) => (
  <HStack justifyContent="space-between" space="4">
    <Text fontSize={["md", "lg"]}>{title}</Text>
    <Text fontSize={["md", "lg"]} fontWeight="semibold">
      {value}
    </Text>
  </HStack>
);

export const ReleaseEditSummaryTrackCard = ({ index, track, release }) => {
  const [show, setShow] = useState(false);
  const { userDetails } = useContext(AuthenticationContext);
  const { data: trackDetails } = useTrack(track.id, track);

  return (
    <VStack space="3">
      <Pressable onPress={() => setShow(!show)}>
        <HStack
          py="2"
          pl="5"
          pr="4"
          _light={{ bg: "light.100" }}
          _dark={{ bg: "dark.900" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize={["md", "lg"]} fontWeight="semibold">
            • {translate("track")} {index}.{" "}
            {track.title || translate("untitled_track")}
          </Text>
          {show ? <ChevronUpIcon size="4" /> : <ChevronDownIcon size="4" />}
        </HStack>
      </Pressable>
      <VStack pl="8" pr="4" space="4">
        <Text fontSize={["md", "lg"]}>{translate("license_fee")}</Text>
        <Text ml="4" fontSize={["md", "lg"]} fontWeight="semibold">
          {translate("license_fee_description", {
            percentage:
              release?.tracklisting?.length > 0
                ? release.tracklisting[0].track.user_contract_royalties_share *
                  100
                : "0",
          })}
        </Text>
        {show && (
          <>
            <Item
              title={translate("artists")}
              value={[
                ...trackDetails.primary_musicians,
                ...trackDetails.featured_musicians,
              ]
                .map((m) => m.alias)
                .join(", ")}
            />
            <Item
              title={translate("composer")}
              value={trackDetails.contributors
                .filter((c) => c.type === "composer")
                .map((m) => m.name)
                .join(", ")}
            />
            <Item
              title={translate("lyricist")}
              value={trackDetails.contributors
                .filter((c) => c.type === "lyricist")
                .map((m) => m.name)
                .join(", ")}
            />
            <Item title="ISRC" value={trackDetails.isrc} />
            <Item
              title={translate("length")}
              value={parseMS(trackDetails.duration_ms)}
            />
            <Text fontSize={["md", "lg"]}>{translate("split_share")}</Text>
            {track.master_rights.map((mr) => (
              <HStack
                key={mr.id}
                justifyContent="space-between"
                alignItems="center"
              >
                <VStack space="2">
                  <HStack space="2">
                    <Text
                      py="2"
                      px="3"
                      _light={{ bg: "light.100" }}
                      _dark={{ bg: "dark.900" }}
                      borderRadius="md"
                      fontSize={["md", "lg"]}
                      fontWeight="semibold"
                      color="secondary.700"
                    >
                      {Math.round(mr.share * 1000) / 10} %
                    </Text>
                    <TrackMasterRightStatus masterRight={mr} />
                  </HStack>
                  <Text fontSize={["md", "lg"]}>
                    {track.royalties_model === "v1"
                      ? translate("of_net_revenue")
                      : translate("of_license_fee")}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize={["md", "lg"]} fontWeight="semibold">
                    {mr.owner_id !== userDetails?.id
                      ? mr.name
                      : translate("me")}
                  </Text>
                  {mr.owner_id !== userDetails?.id && (
                    <Text fontSize={["md", "lg"]} fontWeight="semibold">
                      {mr.phone_number}
                    </Text>
                  )}
                </VStack>
              </HStack>
            ))}
          </>
        )}
      </VStack>
    </VStack>
  );
};
