import React from "react";
import { compactInteger } from "humanize-plus";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, HStack, Text, Divider } from "native-base";
// internal functions
import { translate, getLocalizedDate, getLocalizedNumber } from "utils/helpers";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";

const InformationItem = ({ title, value, modalContent }) => {
  return (
    <HStack justifyContent={"space-between"}>
      <HStack alignItems={"center"} space={1}>
        <Text fontSize={["md", "lg"]} color={"gray.500"}>
          {title}
        </Text>
        {modalContent && (
          <IconModal
            size={4}
            color={"gray.500"}
            contentText={modalContent}
            icon={<MaterialCommunityIcons name={"information-outline"} />}
          />
        )}
      </HStack>
      <Text fontSize={["md", "lg"]} bold>
        {value}
      </Text>
    </HStack>
  );
};

export const LicenseProductDetailExtraInfo = ({ product }) => (
  <VStack space={5}>
    <InformationItem
      title={translate("contract_start_date")}
      modalContent={translate("contract_start_date_info")}
      value={getLocalizedDate(product?.license_product?.start_date)}
    />
    <InformationItem
      title={translate("contract_end_start")}
      modalContent={translate("contract_end_start_info")}
      value={getLocalizedDate(product?.license_product?.end_date)}
    />
    <Divider />
    <InformationItem
      title={translate("est_release_date")}
      modalContent={translate("est_release_date_info")}
      value={getLocalizedDate(product?.license_product?.release?.date)}
    />
    {!product?.license_product?.is_default_offer && (
      <VStack space={5}>
        <InformationItem
          title={translate("projected_streams")}
          modalContent={translate("projected_streams_info")}
          value={
            <Text fontSize={["sm", "md", "lg"]} bold>
              {compactInteger(
                product?.license_product?.projected_streams_total_min,
                1
              )?.replace(".", ",")}{" "}
              -
              {" " +
                compactInteger(
                  product?.license_product.projected_streams_total_max,
                  1
                )?.replace(".", ",")}
            </Text>
          }
        />
        <InformationItem
          title={translate("projected_revenues_per_bz")}
          modalContent={translate("projected_revenues_per_bz_info")}
          value={
            <Text fontSize={["sm", "md", "lg"]} bold>
              {getLocalizedNumber(
                product?.license_product.projected_revenue_per_unit_min
              )}
              € -{" "}
              {getLocalizedNumber(
                product?.license_product.projected_revenue_per_unit_max
              )}
              €
            </Text>
          }
        />
      </VStack>
    )}
  </VStack>
);
