import React, { useEffect, useState } from "react";
import {
  VStack,
  ScrollView,
  Text,
  HStack,
  Flex,
  CheckIcon,
  Pressable,
  Image,
} from "native-base";
// internal hooks
import { useUserAuction } from "features/general/auctions/hooks/useUserAuction";
import { useUpdateProduct } from "features/royalties/license-products/hooks/useUpdateProduct";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

const COLORS = [
  [
    { name: "white", value: "#FFFFFF" },
    { name: "pink", value: "#FFC0CB" },
    { name: "blue", value: "#0000FF" },
    { name: "yellow", value: "#FFFF00" },
  ],
  [
    { name: "lila", value: "#AF00FF" },
    { name: "green", value: "#008000" },
    { name: "teal", value: "#008080" },
    { name: "red", value: "#FF0000" },
  ],
];

export const AuctionEditCardScreen = ({ navigation, route }) => {
  const { id: auctionID, auctionPartials = {} } = route.params;
  const { data: auction, isFetched } = useUserAuction(
    auctionID,
    auctionPartials
  );
  const product = auction?.products && auction?.products[0];
  const { updateProduct } = useUpdateProduct(product?.id);

  const [cardColor, setCardColor] = useState({
    color: COLORS[0][0],
    index: 1,
  });

  const navigateForward = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditPromotional",
      params: { id: auctionID, auctionPartials: auction },
    });
  };

  const onPress = async () => {
    if (!["draft", "action_required"].includes(auction?.status))
      navigateForward();
    else {
      await updateProduct.mutateAsync({ main_color: cardColor.color.name });
      navigateForward();
    }
  };

  const onSave = () =>
    updateProduct.mutate({ main_color: cardColor.color.name });

  useEffect(() => {
    if (isFetched && product?.main_color) {
      setCardColor({
        color: COLORS.flat().find((item) => item.name === product?.main_color),
        index:
          COLORS.flat().findIndex((item) => item.name === product?.main_color) +
          1,
      });
    }
  }, [isFetched]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: { screen: "ReleaseDeals" },
            }),
        }}
        profileProgress={{ type: "deal", activeIndex: 3 }}
      />
      <VStack flex={1}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <PageTitle
            nativeID="save-deal-card-preview"
            isDisabled={updateProduct.isLoading || !isFetched}
            title={translate("card_preview")}
            onSave={onSave}
          />
          {!isFetched ? (
            <Loading />
          ) : (
            <VStack pb="10" space="10">
              <Text mx="5" fontSize={["md", "lg"]}>
                {translate("card_preview_help_text")}
              </Text>
              <VStack
                mx="5"
                px="5"
                py="10"
                space="5"
                alignItems="center"
                borderRadius="xl"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <Image
                  source={{ uri: auction?.image?.sm?.url }}
                  h={200}
                  w={200}
                  alt=""
                  position="absolute"
                  zIndex={1}
                  top={20}
                  borderTopLeftRadius="lg"
                  borderTopRightRadius="lg"
                />
                <Image
                  source={{
                    uri: `https://storage.googleapis.com/picasso-public-europe-west3-development-326115/cards/0000000${cardColor?.index}-92b1-42f1-a8e3-a57988c7ae8f_card_gold_320x320.png`,
                  }}
                  h={320}
                  w={320}
                  mt="2"
                  alt=""
                />
              </VStack>
              <VStack
                p="7"
                space="5"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <Text fontSize={["md", "lg"]} bold>
                  {translate("choose_card_color")}
                </Text>
                <Flex
                  direction={["column", "row"]}
                  justifyContent="center"
                  alignItems="center"
                >
                  {COLORS.map((colors, i) => (
                    <HStack key={i} p={2} space={4}>
                      {colors.map((color, idx) => {
                        if (color) {
                          return (
                            <Pressable
                              isDisabled={
                                !["draft", "action_required"].includes(
                                  auction?.status
                                )
                              }
                              key={color.name}
                              h={30}
                              w={30}
                              bg={color.value}
                              borderRadius="full"
                              borderWidth={color === cardColor?.color ? 2 : 1}
                              borderColor="white"
                              onPress={() => {
                                setCardColor({
                                  color,
                                  index: i === 0 ? idx + 1 : idx + 5,
                                });
                              }}
                            >
                              {color === cardColor?.color && (
                                <Flex
                                  flex={1}
                                  borderRadius="full"
                                  bg="rgba(0,0,0,.2)"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <CheckIcon color="white" size={3} />
                                </Flex>
                              )}
                            </Pressable>
                          );
                        }
                      })}
                    </HStack>
                  ))}
                </Flex>
              </VStack>
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        showNextIcon
        disabled={updateProduct.isLoading || !isFetched}
        isLoading={updateProduct.isLoading}
        text={translate("next")}
        onPress={onPress}
      />
    </ScreenFrame>
  );
};
