import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  FlatList,
  Box,
  Pressable,
  Icon,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";

// internal components
import { translate, isWeb } from "utils/helpers";
import { ViewAllButton } from "theme/buttons/view-all-button";
import { LicenseProductDetailAlbumListTrack } from "features/royalties/license-products/components/license-product-detail-album-list-track.component";

export const LicenseProductDetailAlbumList = ({ product }) => {
  const [showAllTracks, setShowAllTracks] = useState(false);
  const isBigAlbum = product?.license_product?.tracks?.length > 4;
  return (
    product?.license_product?.tracks?.length > 1 && (
      <VStack px={2} py={5} space={5}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <VStack space={1}>
            <Text bold fontSize={["xl", "2xl"]}>
              {translate("track_list")}
            </Text>
            <Text color={"muted.400"} fontSize={["sm", "md"]}>
              {product?.license_product?.release?.fake_type.toUpperCase()}
              {" • "}
              {product?.license_product?.tracks?.length}{" "}
              {product?.license_product?.tracks?.length === 1
                ? translate("track")
                : translate("tracks")}
            </Text>
          </VStack>
          {showAllTracks && (
            <Pressable onPress={() => setShowAllTracks(false)}>
              <Text>{translate("hide")}</Text>
            </Pressable>
          )}
        </HStack>
        {isBigAlbum && !showAllTracks && (
          <Box
            left={0}
            right={0}
            bottom={0}
            height={100}
            position={"absolute"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            bg={{
              linearGradient: {
                colors: [
                  "rgba(253, 253, 253, 0.8)",
                  "rgba(253, 253, 253, 0.8)",
                  "rgba(253, 253, 253, 0.8)",
                  isWeb ? "transparent" : "rgba(249, 250, 251, 0.4)",
                ],
                start: [1, 1],
                end: [1, 0],
              },
            }}
          >
            <Pressable
              p={4}
              mb={2}
              w={32}
              shadow={4}
              bg={"light.100"}
              rounded={"full"}
              alignItems={"center"}
              onPress={() => setShowAllTracks(true)}
            >
              <HStack space={2} alignItems={"center"}>
                <Text fontSize={"md"} fontWeight={500}>
                  Show All
                </Text>
                <Icon as={Ionicons} name={"add"} size={5} />
              </HStack>
            </Pressable>
          </Box>
        )}
        <HStack space={10}>
          <Text fontSize={"lg"} color={"gray.500"}>
            #
          </Text>
          <Text fontSize={"lg"} color={"gray.500"}>
            Title
          </Text>
        </HStack>
        <FlatList
          zIndex={-1}
          maxH={showAllTracks ? "100%" : 300}
          showsVerticalScrollIndicator={false}
          data={
            showAllTracks
              ? product?.license_product?.tracks
              : product?.license_product?.tracks?.slice(0, 4)
          }
          renderItem={({ item, index }) => (
            <LicenseProductDetailAlbumListTrack
              key={index}
              track={item}
              index={index}
            />
          )}
        />
      </VStack>
    )
  );
};
