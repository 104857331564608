import React from "react";
import { Text, VStack, HStack, Pressable, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { AppIconButton } from "theme/buttons/app-icon-button.component";
import { translate } from "utils/helpers";

export const TrackCard = React.memo(
  ({ disabled, navigation, release, index, track, deleteTrack, moveTrack }) => (
    <Pressable
      flex={1}
      py="5"
      px={3}
      my="3"
      mx={{ lg: "3" }}
      borderRadius="xl"
      isDisabled={disabled}
      _dark={{ bg: "dark.800" }}
      _light={{ bg: "light.200" }}
      onPress={() =>
        navigation.navigate("Tracks", {
          screen: "TrackEdit",
          params: {
            trackID: track.id,
            trackPartial: track,
            releaseID: release?.id,
            releasePartials: release,
          },
        })
      }
    >
      <HStack
        flex={1}
        space="2"
        alignItems="center"
        justifyContent="space-between"
      >
        <VStack space={1}>
          <AppIconButton
            nativeID="move-track-up"
            iconName="chevron-up"
            disabled={index === 1}
            onPress={() => moveTrack(track.id, "up")}
          />
          <AppIconButton
            nativeID="move-track-down"
            iconName="chevron-down"
            onPress={() => moveTrack(track.id)}
            disabled={index === release.tracklisting.length}
          />
        </VStack>
        <HStack flex={1} space="5" alignItems="center">
          <Text fontSize={["2xl", "3xl"]}>{index}</Text>
          <VStack flex={1} space="2">
            <Text fontSize={["md", "lg"]} fontWeight="semibold">
              {track.title ? track.title : translate("untitled_track")}
            </Text>
            <Text fontSize={["sm", "md"]}>
              {translate("missing_info_text")}
            </Text>
          </VStack>
        </HStack>
        <Pressable
          nativeID="delete-track"
          disabled={deleteTrack.isLoading}
          onPress={() => {
            deleteTrack.mutate(track);
          }}
        >
          <Icon as={MaterialCommunityIcons} size="lg" name="trash-can" />
        </Pressable>
      </HStack>
    </Pressable>
  )
);
