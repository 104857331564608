import React from "react";
import { HStack, Text, VStack } from "native-base";
//internal hooks
import { useCountries } from "features/royalties/releases/hooks/useCountries";
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";

import { translate } from "utils/helpers";

const ProfileSummaryItem = ({ title, value, valueTextAlign = "right" }) => (
  <HStack justifyContent="space-between" space="4">
    <Text fontSize={["md", "lg"]}>{title}</Text>
    <Text
      maxW={"50%"}
      fontSize={["md", "lg"]}
      fontWeight="semibold"
      textAlign={valueTextAlign}
    >
      {value}
    </Text>
  </HStack>
);

export const ProfileSummary = () => {
  const { data: profile } = useUserProfile();
  const { data } = usePaymentMethods("bank_account");
  const bankAccount = data?.length ? data[0] : undefined;
  const IBAN = bankAccount?.public?.iban
    ? "***" + bankAccount?.public?.iban
    : "";
  const BIC = bankAccount?.public?.bic
    ? "***" + bankAccount?.public?.bic?.slice(-4)
    : "";
  const countries = useCountries();

  const country = countries.data?.find(
    (item) => item.value === profile?.country_of_residence
  );

  const address = `${profile?.street_of_residence || ""} ${
    profile?.house_number_of_residence || ""
  }, ${profile?.postal_code_of_residence || ""}, ${
    profile?.city_of_residence || ""
  }, ${country?.label || ""}`;

  return (
    <VStack
      p="4"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      space="4"
    >
      <Text mb="4" fontSize={["md", "lg"]}>
        {translate("bill_address")}
      </Text>
      <VStack px={3} space="2">
        <ProfileSummaryItem
          title={translate("name")}
          value={profile?.last_name}
        />
        <ProfileSummaryItem
          title={translate("first_name")}
          value={profile?.first_name}
        />
      </VStack>
      <VStack px={3} space="2">
        {profile?.company_name && (
          <ProfileSummaryItem
            title={translate("company_name")}
            value={profile?.company_name}
          />
        )}
        <ProfileSummaryItem title={translate("address")} value={address} />
      </VStack>
      <VStack px={3} space="2">
        {profile?.country_of_residence === "DE" && (
          <ProfileSummaryItem
            title={translate("tax_number")}
            value={profile?.tax_number}
          />
        )}
        {IBAN && <ProfileSummaryItem title="IBAN" value={IBAN} />}
        {BIC && <ProfileSummaryItem title="BIC" value={BIC} />}
      </VStack>
    </VStack>
  );
};
