import React from "react";
import { VStack, HStack, Skeleton } from "native-base";

export const CollectibleListImagesCardSkeleton = ({ width, ...props }) => (
  <VStack
    {...props}
    p={5}
    height={width}
    width={width}
    justifyContent="center"
    alignItems="center"
  >
    <HStack flex={1}>
      <Skeleton
        startColor={"skeleton.dark"}
        width={width * 0.6}
        rounded={10}
        overflow={"hidden"}
      />
      <Skeleton
        startColor={"skeleton.dark"}
        size={6}
        rounded={2}
        ml={2}
        overflow={"hidden"}
      />
    </HStack>
  </VStack>
);
