import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useTrackVersions = () => {
  const { client } = useContext(AxiosContext);
  return useQuery(`track-versions`, () => {
    console.debug(`track versions retrieved`);
    return client.get(`enums/track_versions/`).then((resp) => resp.data);
  });
};
