import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteTrackContributor = (track) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteTrackContributor: useMutation(
      (contributor) =>
        client.delete(
          `tracks/${contributor.track_id}/contributors/${contributor.id}/`
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`track-${track.id}`);
          console.debug(`invalidated query track-${track.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useDeleteTrackContributor;
