import React from "react";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import { LegalListScreen } from "features/accounts/users/screens/legal-list.screen";
import { HistoryListScreen } from "features/accounts/users/screens/history-list.screen";
import { AccountDetailScreen } from "features/accounts/users/screens/account-detail.screen";
import { LanguageSelectionScreen } from "features/accounts/users/screens/select-language.screen";

const Stack = createStackNavigator();

export const AccountNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="AccountSettings"
        component={AccountDetailScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        }}
      />
      <Stack.Screen
        name="LanguageSelection"
        component={LanguageSelectionScreen}
      />
      <Stack.Screen name="LegalList" component={LegalListScreen} />
      <Stack.Screen name="HistoryList" component={HistoryListScreen} />
    </Stack.Navigator>
  );
};
