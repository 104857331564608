import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useUpdateMusician = (id) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    updateMusician: useMutation(
      (params) =>
        client.patch(`musicians/${id}/`, params).then((resp) => resp.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("musician");
          queryClient.invalidateQueries(`musician-${id}`);
        },
      }
    ),
    error: error,
  };
};

export default useUpdateMusician;
