import React, { useState } from "react";
import {
  Avatar,
  VStack,
  HStack,
  Text,
  Box,
  ScrollView,
  Pressable,
} from "native-base";
// internal
import { MultiMedia } from "theme/media/multi-media.component";
import { useUserFirebaseProfile } from "features/accounts/profiles/hooks/useUserFirebaseProfile";
import { translate } from "utils/helpers";
//  relative time
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const PostDetail = ({ post }) => {
  const musician = useUserFirebaseProfile(post?.user_uid);
  const [expanded, setExpanded] = useState(false);
  return (
    <VStack mr={5} space={3} overflow={"hidden"} borderRadius={"xl"}>
      <Box flex={1}>
        <MultiMedia
          type={post?.media_type}
          source={post?.media_url}
          height={300}
          width={320}
        />
        <Box
          p={3}
          top={0}
          w={"full"}
          position={"absolute"}
          bg={{
            linearGradient: {
              colors: ["transparent", "rgba(0,0,0,0.4)"],
              start: [1, 1],
              end: [1, 0],
            },
          }}
        >
          <HStack alignItems={"center"} space={2}>
            <Avatar
              size={"sm"}
              borderWidth={1}
              borderColor={"light.50"}
              borderRadius={"full"}
              source={{
                uri: musician?.data?.image?.xs?.url,
              }}
            />
            <Text color={"lightText"} fontSize="sm" fontWeight={"semibold"}>
              {musician?.data?.alias}
            </Text>
          </HStack>
        </Box>
        <Box
          p={3}
          bottom={0}
          position={"absolute"}
          width={"100%"}
          bg={{
            linearGradient: {
              colors: ["transparent", "rgba(27, 25, 38, 0.9)"],
              start: [0, 0],
              end: [0, 1],
            },
          }}
        >
          <VStack space={2}>
            <Text color={"lightText"} fontSize="sm" fontWeight={"semibold"}>
              {musician?.data?.alias} .{" "}
              <Text fontSize={"xs"} color={"gray.300"} fontWeight={"normal"}>
                {dayjs(post?.created_at?.toDate()).fromNow()}
              </Text>
            </Text>
            <ScrollView maxH={20}>
              {
                post?.body && (
                  <Pressable onPress={() => setExpanded(!expanded)}>
                    <Text
                      color={"lightText"}
                      fontSize="sm"
                      numberOfLines={expanded ? undefined : 2}
                    >
                      {post?.body}
                    </Text>
                    {/* Making sure to not show read more if there is small amount of text */}
                    {!expanded && post?.body.length > 60 && (
                      <Text color={"gray.300"} fontSize="sm" bold>
                        {translate("read_more")}
                      </Text>
                    )}
                  </Pressable>)
              }
            </ScrollView>
          </VStack>
        </Box>
      </Box>
    </VStack>
  );
};
