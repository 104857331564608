import React from "react";
import { AddToCalendarButton } from "common/components/buttons/add-to-calendar-button.component";

export const TicketCollectibleDetailAddToCalender = ({ event }) => {
  return (
    <AddToCalendarButton
      startDate={event?.start_time}
      endDate={event?.end_time}
      location={event?.location}
      eventName={event?.title}
    />
  );
};
