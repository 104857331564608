import React from "react";
import * as MailComposer from "expo-mail-composer";
import { openBrowserAsync } from "expo-web-browser";
import {
  Stack,
  VStack,
  HStack,
  Text,
  Pressable,
  Image,
  Icon,
  useColorMode,
  Hidden,
} from "native-base";
import { Linking } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// internal
import { translate } from "utils/helpers";

export const FooterLink = ({ text, url = undefined, onPress = undefined }) => (
  <Pressable
    onPress={onPress ? onPress : async () => await openBrowserAsync(url)}
  >
    <Text color="lightText" fontSize={["sm", "md"]} fontWeight="semibold">
      {text}
    </Text>
  </Pressable>
);

export const SocialIcon = ({ icon, url }) => (
  <Pressable onPress={async () => await openBrowserAsync(url)}>
    <Icon
      as={MaterialCommunityIcons}
      name={icon}
      size="md"
      color={"lightText"}
    />
  </Pressable>
);

export const Footer = ({ py = "10" }) => {
  const { navigate }: any = useNavigation();
  const { colorMode } = useColorMode();

  const ICONS = [
    { icon: "instagram", url: "https://www.instagram.com/biddz.io/" },
    { icon: "twitter", url: "https://mobile.twitter.com/biddz_io" },
    { icon: "linkedin", url: "https://www.linkedin.com/company/biddz/" },
    { icon: "discord", url: "https://discord.com/invite/TAfm56B62G" },
  ];

  const LINKS = [
    [
      { title: "how_it_work", url: "http://biddz.io/" },
      { title: "artists", url: "http://biddz.io/" },
      {
        title: "lounge",
        onPress: () => navigate("BottomNavigator", { screen: "Lounge" }),
      },
      {
        title: "account",
        onPress: () =>
          navigate("BottomNavigator", { screen: "AccountSettings" }),
      },
    ],
    [
      { title: "privacy_policy", url: "https://biddz.io/de/privacy-notice" },
      { title: "impressum", url: "https://biddz.io/de/imprint" },
      { title: "terms_of_use", url: "https://biddz.io/de/terms-of-use-de" },
      {
        title: "contact",
        onPress: async () => {
          await MailComposer.composeAsync({
            recipients: ["support@biddz.io"],
          });
        },
      },
    ],
  ];

  const openAppLink = (platform = "android") => {
    const appStoreURL =
      platform === "ios"
        ? "https://apps.apple.com/app/1608522898"
        : "https://play.google.com/store/apps/details?id=com.biddz";

    Linking.canOpenURL(appStoreURL)
      .then((supported) => {
        if (supported) {
          Linking.openURL(appStoreURL);
        }
      })
      .catch((err) => console.error("An error occurred", err));
  };

  return (
    <Hidden till="lg">
      <Stack
        mt={4}
        px={5}
        py={py}
        space="3"
        bg="darkBackground"
        justifyContent="space-between"
        flexDirection={["column-reverse", "column-reverse", "row"]}
      >
        <Stack
          alignItems="center"
          justifyContent={["space-between"]}
          flexDirection={["row", "row", "column"]}
        >
          <Pressable
            onPress={() =>
              navigate("BottomNavigator", { screen: "Marketplace" })
            }
          >
            <Image
              width={"167px"}
              height={"50px"}
              resizeMode="contain"
              alt="Biddz White Logo"
              source={require("assets/images/biddz-theme/biddz-extended-white.png")}
            />
          </Pressable>
          <HStack space="4" justifyContent="center">
            {ICONS.map(({ icon, url }, index) => (
              <SocialIcon key={index} icon={icon} url={url} />
            ))}
          </HStack>
        </Stack>

        <HStack space={16}>
          <VStack>
            <Pressable onPress={() => openAppLink("android")}>
              <Image
                alt="Available on Playstore"
                height={60}
                width={150}
                source={require("assets/images/features/playstore.png")}
              />
            </Pressable>
            <Pressable onPress={() => openAppLink("ios")}>
              <Image
                alt="Available on App Store"
                height={60}
                width={150}
                source={require("assets/images/features/appstore.png")}
              />
            </Pressable>
          </VStack>

          <HStack
            space="16"
            alignItems="center"
            justifyContent={["space-between"]}
          >
            {LINKS.map((items, index) => (
              <VStack key={index} space="2">
                {items.map(({ title, url, onPress }) => (
                  <FooterLink
                    key={title}
                    text={translate(title)}
                    url={url}
                    onPress={onPress}
                  />
                ))}
              </VStack>
            ))}
          </HStack>
        </HStack>
      </Stack>
    </Hidden>
  );
};
