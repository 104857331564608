import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserLicenseCollectibleEstimatedEarnings = () => {
  const { client } = useContext(AxiosContext);

  return useMutation(
    (params = {}) => {
      return client
        .get(`me/estimated_earnings/`, {
          params: {
            decimals: 2,
            ...(params.start && { start_date: params.start }),
            ...(params.end && { end_date: params.end }),
            ...(params.id && { id: params.id }),
          },
        })
        .then((resp) => resp.data);
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
