import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { OrderListScreen } from "features/general/orders/screens/orders-list.screen";
import { OrderDetailScreen } from "features/general/orders/screens/order-detail.screen";

const Stack = createStackNavigator();

export const OrdersNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="OrderDetail" component={OrderDetailScreen} />
      <Stack.Screen name="OrderList" component={OrderListScreen} />
    </Stack.Navigator>
  );
};
