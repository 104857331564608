import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ScrollView, VStack } from "native-base";
// internal hooks
import { useTrack } from "features/royalties/tracks/hooks/useTrack";
import { useTrackTypes } from "features/royalties/tracks/hooks/useTrackTypes";
import { useTrackVersions } from "features/royalties/tracks/hooks/useTrackVersions";
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateTrack from "features/royalties/tracks/hooks/useUpdateTrack";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { Loading } from "common/components/loading/loading.component";
import { TrackDetailsForm } from "features/royalties/tracks/components/track-details-form.component";
import { TrackEditHeader } from "features/royalties/tracks/components/track-edit-header.component";
// utility functions
import { translate } from "utils/helpers";

export const TrackEditScreen = ({ navigation, route }) => {
  const {
    trackID,
    releaseID,
    releasePartials = {},
    trackPartial = {},
  } = route.params;
  const { data: track, isFetched } = useTrack(trackID, trackPartial);
  const { data: release } = useRelease(releaseID, releasePartials);
  const { updateTrack } = useUpdateTrack(release, track);
  const trackVersions = useTrackVersions();
  const trackTypes = useTrackTypes();

  const { control, handleSubmit } = useForm();

  const onPress = async (data) => {
    await updateTrack.mutateAsync(data);
    navigation.navigate("Tracks", {
      screen: "TrackEditAudio",
      params: {
        trackID: track.id,
        trackPartial: track,
        releaseID: release.id,
        releasePartials: release,
      },
    });
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <TrackEditHeader step="1" release={release} track={track} />

      <VStack mb={[0, 5]} flex={1}>
        {isFetched && trackVersions.isFetched && trackTypes.isFetched ? (
          <ScrollView showsVerticalScrollIndicator={false}>
            <TrackDetailsForm
              control={control}
              track={track}
              trackVersions={trackVersions}
              trackTypes={trackTypes}
              isLoading={updateTrack.isLoading}
            />
          </ScrollView>
        ) : (
          <Loading />
        )}
      </VStack>
      <PrimaryButton
        p="5"
        showNextIcon
        showBackButton
        disabled={updateTrack.isLoading}
        isLoading={updateTrack.isLoading}
        text={translate("next")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
