import React, { useState } from "react";
import {
  VStack,
  HStack,
  Image,
  Pressable,
  FlatList,
  useMediaQuery,
  Icon,
  Text,
  ScrollView,
  Modal,
} from "native-base";
import { Dimensions } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal components
import { isWeb } from "utils/helpers";
import { CollectibleListImagesCardSkeleton } from "features/general/collectibles/skeletons/collectible-list-images-card.skeleton";

const CollectibleListImagesCardBody = ({
  width,
  count,
  images,
  onPress,
  showClaimBenefit,
  onPressClaimBenefit,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <VStack>
      <VStack my={5} alignItems={"center"} justifyContent={"center"}>
        <HStack justifyContent={"center"} alignItems={"center"}>
          {/* iterate count times and rotate by 10 * index */}
          <Pressable
            onPress={onPress}
            width={width * 0.95}
            height={width * 0.95}
            disabled={!onPress || isLoading}
          >
            {Array.from(Array(Math.min(count ? count : 1, 3)).keys()).map(
              (index) => (
                <Image
                  key={index}
                  zIndex={-index}
                  position="absolute"
                  style={{
                    transform: [
                      {
                        rotate: -index * 5 + "deg",
                      },
                    ],
                  }}
                  source={{
                    uri: images?.md?.url,
                  }}
                  width={width * 0.95}
                  height={width * 0.95}
                  resizeMode={"contain"}
                  alt={"Collectible Card"}
                  onLoadEnd={() => setIsLoading(false)}
                />
              )
            )}
          </Pressable>
          {(images?.isLoading || isLoading) && (
            <CollectibleListImagesCardSkeleton width={width} ml={-width} />
          )}
        </HStack>
      </VStack>
      {showClaimBenefit && (
        <Pressable
          p={2}
          mx={3}
          bg={"darkText"}
          rounded={"full"}
          onPress={onPressClaimBenefit}
        >
          <HStack justifyContent={"center"} alignItems={"center"} space={2}>
            <Text color={"lightText"} fontSize={["xs", "sm"]}>
              Claim Benefits
            </Text>
            <Icon
              size={4}
              color={"lightText"}
              name={"arrow-top-right"}
              as={MaterialCommunityIcons}
            />
          </HStack>
        </Pressable>
      )}
    </VStack>
  );
};

export const CollectibleListImagesCard = ({
  collectibles,
  navigation,
  width = 200,
  height = 200,
  hasUnclaimedBenefits,
  isOtherUserProfile = false,
}) => {
  const collectible = collectibles[0];
  const [showModal, setShowModal] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const goToDetails = (collectible, scrollToBenefits = false) => {
    if (!isOtherUserProfile) {
      setShowModal(false);
      if (collectible.category === "license") {
        navigation?.navigate("LicenseCollectibles", {
          screen: "LicenseCollectibleDetail",
          params: {
            id: collectible?.id,
            initialData: collectible,
            scrollToBenefits: scrollToBenefits,
          },
        });
      } else if (collectible.category === "basic") {
        navigation?.navigate("Collectibles", {
          screen: "CollectibleDetailBasic",
          params: {
            id: collectible?.id,
            initialData: collectible,
            scrollToBenefits: scrollToBenefits,
          },
        });
      }
    }
  };

  return (
    <>
      <Pressable
        onPress={() =>
          collectibles.length === 1
            ? goToDetails(collectible)
            : setShowModal(true)
        }
      >
        <VStack
          m={3}
          py={4}
          bg={"gray.100"}
          borderRadius={"xl"}
          height={height * 1.2}
          justifyContent={"center"}
        >
          <CollectibleListImagesCardBody
            width={width}
            height={height}
            count={collectibles.length}
            images={collectible?.image_with_url}
            showClaimBenefit={!isOtherUserProfile && hasUnclaimedBenefits}
            onPressClaimBenefit={() =>
              collectibles.length === 1
                ? goToDetails(collectible, true)
                : setShowModal(true)
            }
          />
        </VStack>
      </Pressable>
      <Modal isOpen={showModal} onClose={setShowModal}>
        <VStack py={5} mx={5} maxW={600} bg={"light.100"} rounded={"2xl"}>
          <Modal.CloseButton />
          <ScrollView
            p={5}
            pt={0}
            mt={8}
            maxH={0.6 * windowHeight}
            showsVerticalScrollIndicator={false}
          >
            <FlatList
              key={
                isDesktop || !isWeb
                  ? "desktop-collections-modal"
                  : "collections-modal"
              }
              data={collectibles}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              numColumns={2}
              contentContainerStyle={{
                justifyContent: "center",
                alignItems: "center",
              }}
              renderItem={({ item }) => (
                <CollectibleListImagesCardBody
                  width={width}
                  height={height}
                  images={item?.image_with_url}
                  onPress={() => {
                    goToDetails(item);
                    setShowModal(false);
                  }}
                  onPressClaimBenefit={() => goToDetails(collectible, true)}
                  showClaimBenefit={!isOtherUserProfile && hasUnclaimedBenefits}
                />
              )}
            />
          </ScrollView>
        </VStack>
      </Modal>
    </>
  );
};
