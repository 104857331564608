import i18n from "i18n-js";
import React, { useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { Platform } from "react-native";
import { NativeBaseProvider } from "native-base";
import { QueryClientProvider } from "react-query";
import { Navigation } from "navigation";

import AsyncStorage from "@react-native-async-storage/async-storage";
// Internal components
import { AppFrame } from "navigation/components/app-frame.component";
// Context Providers
import { AxiosContextProvider } from "services/axios.context";
import { FirebaseContextProvider } from "services/firebase.context";
import { AudioSnippetContextProvider } from "services/audio-snippet.context";
import { AuthenticationContextProvider } from "services/authentication.context";
// Using smart app banner for web.
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";

export const AppContainer = ({ customTheme, config, queryClient }) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      document.documentElement.lang = i18n.locale;
    }
    // Connect wallet automatically if app is trusted once
    (async () => {
      try {
        const language = await AsyncStorage.getItem("@language");
        i18n.locale = language || "de";
        await window.solana?.connect({ onlyIfTrusted: true });
      } catch (error) {
        console.debug(error);
      }
    })();
  }, []);

  return (
    <>
      <SmartBanner title={"Biddz"} daysReminder={0} />
      <FirebaseContextProvider>
        <NativeBaseProvider theme={customTheme} config={config}>
          <QueryClientProvider client={queryClient}>
            <AxiosContextProvider>
              <AuthenticationContextProvider queryClient={queryClient}>
                <AudioSnippetContextProvider>
                  <AppFrame>
                    <Navigation queryClient={queryClient} />
                  </AppFrame>
                </AudioSnippetContextProvider>
              </AuthenticationContextProvider>
            </AxiosContextProvider>
          </QueryClientProvider>
          <StatusBar style="dark" />
        </NativeBaseProvider>
      </FirebaseContextProvider>
    </>
  );
};
