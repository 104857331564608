import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";
import { AuthenticationContext } from "services/authentication.context";

export const useUserProfile = (initialData = {}) => {
  const { client } = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery(
    "profile",
    () =>
      client.get(`me/profiles/`).then((resp) => {
        console.debug(`👽 Profile`);
        return resp.data;
      }),
    {
      staleTime: 300000,
      placeholderData: initialData,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );
};
