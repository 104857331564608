import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUpdateBenefitCollectable = (benefit) => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return {
    updateBenefitCollectable: useMutation(
      (params) =>
        client
          .patch(
            `collectibles/${benefit.collectible_id}/benefits/${benefit.id}/`,
            params
          )
          .then((resp) => resp.data),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`me-benefits`);
          queryClient.invalidateQueries(
            `collectible-${benefit.collectible_id}`
          );
        },
      }
    ),
  };
};
