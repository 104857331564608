import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useSaveSpotifyTrack = () => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    saveSpotifyTrack: useMutation(
      (params) =>
        client.post(`tracks/presave/`, params).then((res) => res.data),
      {
        onSuccess: () => {},
        // queryClient.invalidateQueries(`musician-${musicianID}`),
      }
    ),
    error: error,
  };
};

export default useSaveSpotifyTrack;
