import React from "react";
import { HStack, Icon, Text, Pressable } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { DeletePaymentMethodButton } from "features/general/payment-methods/components/delete-payment-method-button.component";

export const PaymentMethod = ({
  paymentMethod,
  onPress = null,
  isChecked = false,
}) => (
  <Pressable mx={2} borderRadius={"xl"} onPress={onPress}>
    <HStack
      p={2}
      px={4}
      space={5}
      borderWidth={1}
      borderRadius="xl"
      alignItems="center"
      borderColor="dark.800"
      justifyContent={"space-between"}
    >
      {isChecked && (
        <Icon
          size={"sm"}
          name="check-circle"
          color="secondary.600"
          as={MaterialCommunityIcons}
        />
      )}
      <Text
        maxWidth={75}
        noOfLines={1}
        fontWeight="semibold"
        fontSize={["md", "lg"]}
        textTransform={"uppercase"}
      >
        {paymentMethod?.public?.issuer || paymentMethod?.public?.bankname}
      </Text>
      <Text fontSize={["md", "lg"]}>
        ****{paymentMethod?.public?.pan || paymentMethod?.public?.iban}
      </Text>
      <DeletePaymentMethodButton paymentMethodID={paymentMethod.id} />
    </HStack>
  </Pressable>
);
