import React from "react";
import { Text, Button, HStack, Image } from "native-base";
// internal
import { Loading } from "common/components/loading/loading.component";

export const SecondaryButton = ({
  onPress,
  text,
  p = 2,
  space = 2,
  size = "md",
  nativeID = "",
  fontSize = "lg",
  disabled = false,
  isLoading = false,
  fontWeight = "medium",
  logoLeft = undefined,
  borderColor = "#d4d4d4",
  rightComponent = undefined,
  loadingMaxHeight = undefined,
  logoLeftComponent = undefined,
  width = "100%",
  ...props
}) => (
  <HStack justifyContent="center" {...props}>
    <Button
      maxW={500}
      size={size}
      transparent
      width={width}
      onPress={onPress}
      borderRadius={"xl"}
      variant={"outline"}
      nativeID={nativeID}
      borderColor={borderColor}
      disabled={disabled || isLoading}
      _dark={{
        bg: "transparent",
        colorScheme: "primary",
        _focus: {
          bg: "primary.200",
        },
        _pressed: {
          bg: "primary.200",
        },
        _hover: {
          bg: "primary.200",
        },
      }}
      _light={{
        bg: "transparent",
        colorScheme: "dark",
        _focus: {
          bg: "dark.200",
        },
        _pressed: {
          bg: "dark.200",
        },
        _hover: {
          bg: "dark.200",
        },
      }}
    >
      {!isLoading ? (
        <HStack space={space} alignItems={"center"}>
          {logoLeftComponent
            ? logoLeftComponent
            : logoLeft && (
                <Image
                  size={5}
                  alt="Button Logo"
                  source={logoLeft}
                  resizeMode="contain"
                />
              )}
          <Text fontSize={fontSize} fontWeight={fontWeight}>
            {text}
          </Text>
          {rightComponent}
        </HStack>
      ) : (
        <Loading maxHeight={loadingMaxHeight} pt={0} color="primary.600" />
      )}
    </Button>
  </HStack>
);
