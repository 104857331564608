import React, { useRef, useState } from "react";
import {
  HStack,
  Icon,
  Image,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  Toast,
  VStack,
} from "native-base";
import { ImageBackground } from "react-native";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import domtoimage from "dom-to-image";
import ViewShot from "react-native-view-shot";
import * as MediaLibrary from "expo-media-library";
import * as Sharing from "expo-sharing";
// internal
import { useUpdateUserTopBadges } from "features/accounts/users/hooks/useUpdateUserTopBadges";
import {
  isWeb,
  translate,
  getRandomBadgeColor,
  getRandomBadgeBackground,
} from "utils/helpers";

export const UserProfileBadgeListCard = ({
  badge,
  topBadges,
  isUserProfile,
}) => {
  const [badgeColor] = useState(getRandomBadgeColor());
  const [badgeBackground] = useState(
    getRandomBadgeBackground(!badge?.object || !badge?.image?.md?.url)
  );
  const [badgeURI, setBadgeURI] = useState(undefined);
  const [badgeURILoading, setBadgeURILoading] = useState(false);
  const isTopBadge = topBadges?.includes(badge.id);

  const { updateUserTopBadges } = useUpdateUserTopBadges();
  const onAddTopBadge = (newTopBadge) => {
    let newTopBadges = [newTopBadge, ...topBadges?.slice(0, 2)];
    updateUserTopBadges.mutate({ top_badges: newTopBadges });
  };

  const onRemoveTopBadge = (badgeId) => {
    let newTopBadges = topBadges?.filter((badge) => badge !== badgeId);
    updateUserTopBadges.mutate({ top_badges: newTopBadges });
  };

  const badgeRef = useRef();
  const ShareableBadge = () => (
    <VStack
      w={300}
      h={520}
      bg={badgeColor}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <UserProfileBadgeCard
        badge={badge}
        isShareable={true}
        color={badgeColor}
        background={badgeBackground}
      />
    </VStack>
  );

  const ShareableBadgeReference = () =>
    isWeb ? (
      <VStack ref={badgeRef}>
        <ShareableBadge />
      </VStack>
    ) : (
      <ViewShot
        ref={badgeRef}
        options={{
          format: "png",
          width: 1080,
          height: 1920,
          fileName: `${translate(`badge_${badge?.type}_title`)}.png`,
        }}
      >
        <ShareableBadge />
      </ViewShot>
    );

  const getBadgeURI = async () => {
    if (badgeURI) {
      console.debug(`🎇 Returned existing Badge URI`);
      return badgeURI;
    }
    let dataURI = undefined;
    if (isWeb) {
      dataURI = await domtoimage.toPng(badgeRef.current);
    } else {
      dataURI = await badgeRef.current?.capture();
    }
    console.debug(`🎆 Created Badge URI for web`);
    setBadgeURI(dataURI);
    return dataURI;
  };

  const onShareBadge = async () => {
    // wait for repaint
    setBadgeURILoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      const dataURI = await getBadgeURI();
      if (dataURI) {
        if (isWeb) {
          const badgeURL = document.createElement("a");
          badgeURL.href = dataURI;
          badgeURL.setAttribute(
            `download`,
            `${translate(`badge_${badge?.type}_title`)}.png`
          );
          badgeURL.click();
          URL.revokeObjectURL(dataURI);
        } else {
          const photosPermissions =
            await MediaLibrary.requestPermissionsAsync();
          Sharing.shareAsync(`file://${dataURI}`);
          if (photosPermissions.granted)
            await MediaLibrary.saveToLibraryAsync(dataURI);
          else throw new Error("Insufficient Media Permissions");
        }
      }
    } catch (error) {
      console.error(`❌ Error Sharing Badge`, error);
      Toast.show({
        placement: "top",
        duration: 5000,
        render: () => (
          <VStack bg={"red.500"} m={4} px={6} py={2} rounded={"xl"}>
            <Text color={"lightText"}>{translate("could_not_save_image")}</Text>
          </VStack>
        ),
      });
    }
    setBadgeURILoading(false);
  };

  return (
    <>
      <VStack mb={5} ml={5} bg={badgeColor} rounded={"2xl"} overflow={"hidden"}>
        {isUserProfile && (
          <HStack pt={5} px={5} space={5} justifyContent={"space-between"}>
            {badgeURILoading ? (
              <Spinner size={"sm"} color={"lightText"} />
            ) : (
              <Pressable onPress={async () => await onShareBadge()}>
                <Icon
                  size={6}
                  as={Ionicons}
                  color={"lightText"}
                  name={"share-outline"}
                />
              </Pressable>
            )}
            {updateUserTopBadges.isLoading ? (
              <Spinner size={"sm"} color={"lightText"} />
            ) : (
              <Pressable
                onPress={() => {
                  if (isTopBadge) onRemoveTopBadge(badge?.id);
                  else onAddTopBadge(badge?.id);
                }}
              >
                <Icon
                  size={6}
                  as={AntDesign}
                  color={"lightText"}
                  name={isTopBadge ? "star" : "staro"}
                />
              </Pressable>
            )}
          </HStack>
        )}
        <UserProfileBadgeCard
          badge={badge}
          color={badgeColor}
          background={badgeBackground}
        />
      </VStack>
      <VStack position={"absolute"} zIndex={-10} opacity={0}>
        <ShareableBadgeReference />
      </VStack>
    </>
  );
};

const UserProfileBadgeCard = ({
  badge,
  color,
  background,
  isShareable = false,
}) => (
  <VStack
    p={5}
    pt={0}
    w={280}
    h={380}
    bg={color}
    alignItems={"center"}
    justifyContent={"space-between"}
  >
    <VStack space={2} mb={5} alignItems={"center"}>
      <ImageBackground
        resizeMode={"contain"}
        alt={"badge background"}
        style={{
          width: 220,
          height: 220,
          alignItems: "center",
          justifyContent: "center",
        }}
        source={background}
      >
        <Image
          size={24}
          alt={"badge"}
          resizeMode={"contain"}
          source={
            badge?.image?.md?.url
              ? { uri: badge?.image?.md?.url }
              : require("assets/images/features/badges/badges-empty.png")
          }
          rounded={badge?.object?.type === "track" ? 0 : "2xl"}
        />
      </ImageBackground>
      <Text mt={-2} fontSize={"lg"} fontWeight={600}>
        {translate(`badge_${badge?.type}_title`)}
      </Text>
      <Text
        maxW={48}
        fontSize={"sm"}
        fontWeight={600}
        textAlign={"center"}
        noOfLines={isShareable ? undefined : 1}
      >
        {badge?.object?.name}
      </Text>
      <ScrollView
        maxH={isShareable ? undefined : 10}
        showsVerticalScrollIndicator={false}
      >
        <Text maxW={48} fontSize={"xs"} textAlign={"center"}>
          {translate(`badge_${badge?.type}_desc`)}
        </Text>
      </ScrollView>
    </VStack>
    <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
      <Image
        h={4}
        w={16}
        alt={"biddz"}
        resizeMode={"contain"}
        source={require("assets/images/biddz-theme/biddz-logo-extended-black-no-padding.png")}
      />
      <Text fontSize={"2xs"} fontWeight={600} textTransform={"uppercase"}>
        {badge?.object ? `#${badge?.object?.type}badge` : `#biddzbadge`}
      </Text>
    </HStack>
  </VStack>
);
