import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { VStack, Text, HStack } from "native-base";
// internal hooks
import useDeleteTrackContributor from "features/royalties/tracks/hooks/useDeleteTrackContributor";
// internal components
import { AppIconButtonWithText } from "theme/buttons/app-icon-button-with-text.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// helpers
import { translate } from "utils/helpers";

const Contributor = ({ contributor, isDisabled, onPress, onDelete }) => (
  <HStack space="2" alignItems="center">
    <Text
      p="3"
      flex={6}
      fontSize="md"
      color="dark.600"
      bg="light.700"
      borderRadius="xl"
    >
      {contributor.name}
    </Text>
    <Text
      p="3"
      flex={4}
      fontSize="md"
      color="dark.600"
      bg="light.700"
      borderRadius="xl"
    >
      {translate(contributor.type)}
    </Text>
    <HStack space="2">
      <AppIconButton
        nativeID="edit-track-contributor"
        onPress={onPress}
        iconName="pencil"
        disabled={isDisabled}
      />
      <AppIconButton
        nativeID="delete-track-contributor"
        onPress={onDelete}
        iconName="trash-can"
        disabled={isDisabled}
      />
    </HStack>
  </HStack>
);

export const TrackContributors = ({ navigation, track, release }) => {
  const { deleteTrackContributor } = useDeleteTrackContributor(track);

  const editContributor = (contributorID) => {
    navigation.navigate("Contributors", {
      screen: "AddContributorToTrack",
      params: { releaseID: release?.id, trackID: track.id, contributorID },
    });
  };

  return (
    <VStack
      p="5"
      space="3"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Text fontSize="md">{translate("contributors")}</Text>
        <IconModal
          iconColor="primary.600"
          icon={<AntDesign name="questioncircleo" />}
          contentText={translate("contributors_help_text")}
        />
      </HStack>
      <Text fontSize="sm" color="light.700">
        {translate("contributors_info_text")}
      </Text>
      {track?.contributors?.map((contributor) => (
        <Contributor
          key={contributor.id}
          contributor={contributor}
          onPress={() => editContributor(contributor.id)}
          onDelete={() => deleteTrackContributor.mutate(contributor)}
          isDisabled={deleteTrackContributor.isLoading || track.is_submitted}
        />
      ))}
    </VStack>
  );
};
