import React from "react";
import { Platform } from "react-native";
import { Image, Pressable, Flex, Box, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as ExpoImagePicker from "expo-image-picker";
import { translate } from "utils/helpers";

export const ImagePicker = ({ image, setImage, isDisabled = false }) => {
  const pickImage = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert(translate("camera_permission_denied_text"));
      }
    }
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      base64: true,
      aspect: [4, 3],
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
    });
    if (!result.canceled && result.assets?.length > 0) {
      setImage(result.assets[0]);
    }
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      {image?.uri && (
        <Box mr={-5} mb={-5} zIndex={1} w={160} alignItems="flex-end">
          <Pressable
            isDisabled={isDisabled}
            _disabled={{ opacity: 0.5 }}
            onPress={() => setImage({ uri: null, validate: false })}
          >
            <Icon
              size={"xl"}
              name={"close-circle"}
              color={"primary.800"}
              as={MaterialCommunityIcons}
            />
          </Pressable>
        </Box>
      )}
      {image?.uri && (
        <Image
          borderRadius={"xl"}
          alt={"Release Cover"}
          source={{ uri: image.uri }}
          style={{ width: 160, height: 160 }}
        />
      )}
      {!image?.uri && (
        <Pressable isDisabled={isDisabled} onPress={pickImage}>
          <Box
            h={160}
            w={160}
            borderStyle="dashed"
            borderWidth="1"
            borderRadius="xl"
            bg="#15141c"
            alignItems="center"
            justifyContent="center"
            borderColor={!image.uri && image.validate ? "red.700" : undefined}
          >
            <Icon
              as={MaterialCommunityIcons}
              name="plus"
              size="4xl"
              color="white"
            />
          </Box>
        </Pressable>
      )}
    </Flex>
  );
};
