import React, { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, initializeAuth } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getReactNativePersistence } from "firebase/auth/react-native";
import Constants from "expo-constants";
import { isWeb } from "utils/helpers";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const FirebaseContext = createContext(null);

const getFirebaseAuth = (app) => {
  if (isWeb) {
    return getAuth(app);
  }
  try {
    return initializeAuth(app, {
      persistence: getReactNativePersistence(AsyncStorage),
    });
  } catch {
    return getAuth(app);
  }
};

export const FirebaseContextProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState(null);
  const firebaseConfig = Constants?.expoConfig?.extra?.FIREBASE_CONFIG;
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const functions = getFunctions(app, "europe-west3");
  const auth = getFirebaseAuth(app);

  useEffect(() => {
    (async () => {
      const value = await isSupported();
      if (value) {
        const analyticsInstance = getAnalytics(app);
        setAnalytics(analyticsInstance);
      }
    })();
  }, [app]);

  return (
    <FirebaseContext.Provider
      value={{
        app,
        auth,
        analytics,
        firestore,
        functions,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
