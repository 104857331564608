import React from "react";
import {
  Alert,
  VStack,
  HStack,
  Text,
  IconButton,
  CloseIcon,
  Toast,
} from "native-base";

export const ToastAlert = ({
  id,
  status = "info",
  variant = "solid",
  title = "",
  description,
  closable = false,
  showIcon = false,
  ...props
}) => {
  const color =
    variant === "solid"
      ? "lightText"
      : variant !== "outline"
      ? "darkText"
      : null;

  return (
    <Alert
      mx={5}
      {...props}
      maxWidth={320}
      status={status}
      variant={variant}
      alignSelf={"center"}
      flexDirection={"row"}
    >
      <HStack w="100%" space="1" flexShrink={1} justifyContent="space-between">
        <VStack flexShrink={1} space="1" justifyContent="center">
          <HStack space={2} flexShrink={1} alignItems="center">
            {showIcon && <Alert.Icon />}
            {title && (
              <Text
                fontSize="md"
                fontWeight="medium"
                flexShrink={1}
                color={color}
              >
                {title}
              </Text>
            )}
          </HStack>
          <Text color={color}>{description}</Text>
        </VStack>

        {closable ? (
          <IconButton
            variant="unstyled"
            icon={<CloseIcon size="4" />}
            _icon={{
              color: variant === "solid" ? "lightText" : "darkText",
            }}
            onPress={() => Toast.close(id)}
          />
        ) : null}
      </HStack>
    </Alert>
  );
};
