import React, { useEffect } from "react";
import { Text, FlatList, VStack } from "native-base";
// utility functions
import { translate } from "utils/helpers";
// internal
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
import { OrderPreviewPaymentMethod } from "features/general/orders/components/order-preview-payment-method.component";
import { OrderPreviewAddPaymentMethodButton } from "features/general/orders/components/order-preview-add-payment-method.component";

export const ChoosePaymentMethod = ({
  chosenPaymentMethodID,
  setChosenPaymentMethodID,
  setChosenPaymentMethodType,
}) => {
  const paymentMethods = usePaymentMethods();
  const ApplePaySession = window.ApplePaySession;
  let isSupported = false;
  //   try {
  //     isSupported = ApplePaySession?.canMakePayments();
  //   } catch (error) {
  //     console.error(error);
  //   }

  const toggleChosenPaymentMethod = (paymentMethodID, paymentMethodType) => {
    if (paymentMethodID == chosenPaymentMethodID) {
      setChosenPaymentMethodID(null);
      setChosenPaymentMethodType(null);
    } else {
      setChosenPaymentMethodID(paymentMethodID);
      setChosenPaymentMethodType(paymentMethodType);
    }
  };

  useEffect(() => {
    if (paymentMethods.isFetched) {
      if (paymentMethods?.data?.length === 1) {
        setChosenPaymentMethodID(paymentMethods.data[0].id);
        setChosenPaymentMethodType(paymentMethods.data[0].type);
      }
      if (paymentMethods?.data?.length < 1) {
        setChosenPaymentMethodID(null);
        setChosenPaymentMethodType(null);
      }
    }
  }, [paymentMethods]);

  return (
    <VStack space={5}>
      <Text fontSize={["md", "lg"]} bold>
        {translate("pay_with")}
      </Text>

      {!paymentMethods.isLoading && (
        <VStack p={3} bg={"gray.100"} rounded={"xl"}>
          <FlatList
            data={paymentMethods.data}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) => (
              <OrderPreviewPaymentMethod
                paymentMethod={item}
                isChecked={item.id == chosenPaymentMethodID}
                onPress={() => toggleChosenPaymentMethod(item.id, item.type)}
              />
            )}
          />
          {isSupported && (
            <OrderPreviewPaymentMethod
              isApplePay
              paymentMethod={null}
              isChecked={chosenPaymentMethodID == "applepay"}
              onPress={() => toggleChosenPaymentMethod("applepay", "applepay")}
            />
          )}
        </VStack>
      )}
      <OrderPreviewAddPaymentMethodButton />
    </VStack>
  );
};
