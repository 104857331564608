import React, { memo } from "react";
import { ImageBackground } from "react-native";
import { HStack, Image, Pressable, Text, VStack, Icon } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import dayjs from "dayjs";
// internal components
import { RunningText } from "theme/typography/running-text.component";
// helpers
import { getLocalizedNumber, getMoreText, translate } from "utils/helpers";

const MoreText = memo(({ icon, alt, data, maxLength, showOne = false }) => {
  const moreText = getMoreText(data, maxLength, showOne);
  return (
    <HStack space={1} alignItems={"center"}>
      <Image size={5} source={icon} alt={alt} />
      <Text
        fontSize={"xs"}
        fontWeight={500}
        numberOfLines={1}
        color={"lightText"}
      >
        {moreText?.text}
      </Text>
      {!!moreText?.moreCount && (
        <Text
          fontSize={"xs"}
          color={"gray.300"}
          selectable={false}
        >{`+${moreText?.moreCount} more`}</Text>
      )}
    </HStack>
  );
});

export const AuctionListCardTicket = ({ auction, onPress }) => {
  const price =
    auction?.auctions?.[0]?.ticket_products?.[0]?.price_per_unit || 0;
  const locations = auction?.events?.map((e) => e.city);
  const dates = auction?.events?.map((e) =>
    dayjs(e.start_time).format("DD MMM YYYY")
  );

  return (
    <Pressable borderRadius={"lg"} overflow={"hidden"} mb={3} onPress={onPress}>
      <ImageBackground
        alt={`${auction?.title} Cover`}
        style={{ height: 312, width: 250 }}
        source={{ uri: auction?.image_with_url?.md?.url }}
      >
        <VStack flex={1} justifyContent={"flex-end"}>
          <VStack
            p={3}
            space={4}
            bg={{
              linearGradient: {
                colors: [
                  "rgba(0,0,0,0.5)",
                  "rgba(0,0,0,0.5)",
                  "rgba(0,0,0,0.5)",
                  "transparent",
                ],
                start: [1, 1],
                end: [1, 0],
              },
            }}
          >
            <RunningText
              text={`${auction?.title} - ${auction?.subtitle}`}
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#fff",
              }}
            />

            <HStack space={2}>
              <VStack flex={0.8} space={2}>
                <HStack space={2} alignItems={"center"}>
                  <Image
                    size={5}
                    alt={"coin-white"}
                    source={require("assets/images/icons/coin-white.png")}
                  />
                  <HStack space={1} alignItems={"center"}>
                    <Text color={"lightText"} fontWeight={500}>
                      {translate("from")}
                    </Text>
                    <Text color={"secondary.300"} fontWeight={500}>
                      {getLocalizedNumber(price)} €
                    </Text>
                  </HStack>
                </HStack>

                <MoreText
                  data={dates}
                  maxLength={12}
                  showOne={true}
                  alt={"calendar-white"}
                  icon={require("assets/images/icons/calendar-white.png")}
                />
                <MoreText
                  maxLength={12}
                  data={locations}
                  alt={"location-white"}
                  icon={require("assets/images/icons/location-white.png")}
                />
              </VStack>
              <VStack
                flex={0.2}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Pressable
                  p={2}
                  borderWidth={1}
                  onPress={onPress}
                  borderRadius={"full"}
                  borderColor={"gray.500"}
                >
                  <Icon
                    size={5}
                    as={Ionicons}
                    color={"lightText"}
                    name={"arrow-forward"}
                  />
                </Pressable>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </ImageBackground>
    </Pressable>
  );
};
