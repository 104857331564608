import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteTrackMusician = (track) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteTrackMusician: useMutation(
      (data) =>
        client.delete(
          `tracks/${track.id}/musicians/${data.id}/?is_primary=${data.primary}`
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`track-${track.id}`);
          console.debug(`invalidated query track-${track.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useDeleteTrackMusician;
