import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, ScrollView, Text, Icon, HStack, Divider } from "native-base";
// utility functions
import { getLocalizedDateTime, navigateBack, translate } from "utils/helpers";
// internal hooks
import { useTicketCollectibleValidate } from "features/tickets/ticket-collectibles/hooks/useTicketCollectibleValidate";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";

export const TicketCollectibleDetailValidateResponseScreen = ({
  route,
  navigation,
}) => {
  const { ticketCollectibleID, userID, setScanAvailable } = route.params;
  const collectible = useTicketCollectibleValidate(ticketCollectibleID, userID);
  const statusCodeMap = {
    201: {
      key: "ticket_validation_success",
      color: "success.300",
      iconName: "account-check",
    },
    200: {
      key: "ticket_validation_multiple_use",
      color: "warning.300",
      iconName: "account-alert",
    },
    210: {
      key: "ticket_validation_different_user",
      color: "danger.300",
      iconName: "account-multiple-remove",
    },
  };

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => {
            if (!!setScanAvailable) {
              setScanAvailable(true);
            }
            navigateBack(navigation);
          },
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1, flex: 1 }}
      >
        {collectible?.isFetched ? (
          <VStack p={5} justifyContent="center" flex={1}>
            {collectible?.isSuccess ? (
              <VStack space={4} alignItems={"center"}>
                <Icon
                  size={"4xl"}
                  color={statusCodeMap[collectible?.data?.status]?.color}
                  name={statusCodeMap[collectible?.data?.status]?.iconName}
                  as={MaterialCommunityIcons}
                />
                <Divider />
                <Text color={"success.300"} fontWeight={"semibold"}>
                  {translate(statusCodeMap[collectible?.data?.status]?.key)}
                </Text>
                <Text
                  fontWeight={"semibold"}
                  color={statusCodeMap[collectible?.data?.status]?.color}
                >
                  {`Code: ${ticketCollectibleID}`}
                </Text>
                <Divider />
                <HStack justifyContent="space-between">
                  <Text fontWeight={"semibold"} flex={1}>
                    {translate("fan")}
                  </Text>
                  <Text flex={1}>
                    {collectible?.data?.data?.used_by?.profile?.full_name ||
                      collectible?.data?.data?.collectible?.owner?.profile
                        ?.full_name}
                  </Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text fontWeight={"semibold"} flex={1}>
                    {translate("age")}
                  </Text>
                  <Text flex={1}>
                    {collectible?.data?.data?.collectible?.owner?.profile?.age}
                  </Text>
                </HStack>
                <Divider />
                <HStack justifyContent="space-between">
                  <Text fontWeight={"semibold"} flex={1}>
                    {"Ticket"}
                  </Text>
                  <Text flex={1}>
                    {collectible?.data?.data?.collectible?.description}
                  </Text>
                </HStack>

                <Divider />
                <HStack justifyContent="space-between">
                  <Text fontWeight={"semibold"} flex={1}>
                    {translate("validator")}
                  </Text>
                  <Text flex={1}>
                    {collectible?.data?.data?.validated_by?.profile?.full_name}
                  </Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text fontWeight={"semibold"} flex={1}>
                    {translate("validation_time")}
                  </Text>
                  <Text flex={1}>
                    {getLocalizedDateTime(collectible?.data?.data?.used_at)}
                  </Text>
                </HStack>
                <Divider />
              </VStack>
            ) : (
              <VStack space={10} alignItems="center">
                <Icon
                  size={"4xl"}
                  color={"danger.300"}
                  name={"account-cancel"}
                  as={MaterialCommunityIcons}
                />
                <Text
                  p={2}
                  borderWidth={1}
                  color={"danger.300"}
                  borderRadius={"xl"}
                  borderColor={"danger.300"}
                  fontWeight={"semibold"}
                >
                  {translate("ticket_validation_error")}
                </Text>
              </VStack>
            )}
          </VStack>
        ) : (
          <Loading />
        )}
      </ScrollView>
    </ScreenFrame>
  );
};
