import React, { useEffect } from "react";
import { Text, VStack, HStack, ScrollView } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { Loading } from "common/components/loading/loading.component";
//internal hooks
import useCreateAuction from "features/general/auctions/hooks/useCreateAuction";
import useCreateLicenseProduct from "features/royalties/license-products/hooks/useCreateLicenseProduct";
import { useReleaseAuctions } from "features/royalties/releases/hooks/useReleaseAuctions";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseSuccess = ({ navigation, releaseID }) => {
  const { createLicenseProduct } = useCreateLicenseProduct(releaseID);
  const { createAuction } = useCreateAuction(releaseID);
  const { data: auctions, isFetched } = useReleaseAuctions(releaseID);

  const onPress = () => {
    createLicenseProduct.mutate();
  };

  const navigateToReleaseTabs = () =>
    navigation.navigate("BottomNavigator", { screen: "ReleaseTabs" });

  useEffect(() => {
    if (createLicenseProduct.isSuccess) createAuction.mutate();
  }, [createLicenseProduct.isSuccess]);

  useEffect(() => {
    if (createAuction.isSuccess) {
      navigation.navigate("Auctions", {
        screen: "AuctionEditInfo",
        params: {
          id: createAuction.data?.id,
          auctionPartials: createAuction.data,
        },
      });
    }
  }, [createAuction.isSuccess]);

  return (
    <ScrollView flex={1}>
      <VStack p={5} flex={1} space={10} justifyContent="space-evenly">
        <VStack space="5" alignItems="center">
          <Text
            fontSize={["xl", "2xl"]}
            fontWeight="semibold"
            textAlign="center"
          >
            {translate("release_success_title")}
          </Text>
          <VStack
            p="5"
            space="5"
            maxW="700"
            borderRadius="md"
            justifyContent="center"
            _light={{ bg: "dark.900" }}
            _dark={{ bg: "primary.600" }}
          >
            <Text fontSize={["md", "lg"]} bold color={"lightText"}>
              {translate("release_success_question")}
            </Text>
            <Text fontSize={["sm", "md"]} color={"lightText"}>
              {translate("release_success_answer")}
            </Text>
          </VStack>
          {/* <Image
            mt={10}
            width={179}
            height={244}
            source={require("assets/images/biddz-theme/biddz-logo-white-gradient.png")}
            alt="Biddz Logo"
          /> */}
        </VStack>

        {!isFetched ? (
          <Loading />
        ) : auctions?.items?.length > 0 ? (
          <PrimaryButton
            showNextIcon
            text={translate("finish")}
            onPress={navigateToReleaseTabs}
          />
        ) : (
          <VStack space={4}>
            <HStack space="3" alignItems="center" justifyContent="center">
              <Text
                fontSize={["md", "lg"]}
                _dark={{ color: "secondary.700" }}
                _light={{ color: "primary.900" }}
              >
                {translate("continue_with_without_deal")}
              </Text>
              <IconModal
                iconColor="primary.600"
                icon={<AntDesign name="questioncircleo" />}
                contentText={translate("continue_with_without_deal_help_text")}
              />
            </HStack>
            <SecondaryButton
              text={translate("distribute_without_deal")}
              onPress={navigateToReleaseTabs}
            />
            <PrimaryButton
              showNextIcon
              text={translate("next")}
              onPress={onPress}
            />
          </VStack>
        )}
      </VStack>
    </ScrollView>
  );
};
