import React from "react";
import { Image, VStack, Text, HStack, ScrollView } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// internal component
import { PageTitle } from "common/components/layout/page-title.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
// utility functions
import { translate } from "utils/helpers";
import { IconModal } from "theme/feedback/icon-modal.component";

export const AuctionSubmitSuccess = ({ auction }) => {
  const navigation: any = useNavigation();

  const onPress = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditInfo",
      params: { id: auction?.id, auctionPartials: auction },
    });
  };

  return (
    <VStack flex={1} px="5" pb="5" justifyContent="space-between">
      <ScrollView
        h={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <PageTitle title={translate("summary")} />
        <VStack mb="10" space="6" alignItems="center">
          <HStack space="5" alignItems="center">
            <Text
              bold
              fontSize={["xl", "2xl"]}
              textAlign="center"
              lineHeight={33}
            >
              {translate("deal_submit_success")}
            </Text>
            <IconModal
              iconColor="primary.600"
              icon={<AntDesign name="questioncircleo" />}
              contentText={translate("deal_info_text")}
            />
          </HStack>
          <Text textAlign={"center"} fontSize={"md"}>
            {translate("deal_submit_success_description")}
          </Text>
          <Image
            mt="10"
            width={179}
            height={244}
            source={require("assets/images/biddz-theme/biddz-logo-gradient-outline.png")}
            alt="Biddz Logo"
          />
        </VStack>
      </ScrollView>
      <PrimaryButton text={translate("review_application")} onPress={onPress} />
    </VStack>
  );
};
