import React, { memo } from "react";
import { Flex, HStack, VStack, Text, Button, Icon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import dayjs from "dayjs";
import i18n from "i18n-js";
// utility functions
import { translate } from "utils/helpers";

export const TourEventCard = memo(({ auction, navigation }) => (
  <Flex
    p={5}
    mb={8}
    shadow={5}
    bg={"light.50"}
    borderRadius={"lg"}
    direction={["column", "row"]}
    justifyContent={"space-between"}
    alignItems={["flex-start", "center"]}
  >
    <VStack space={1}>
      <Text fontSize={["md", "lg"]} fontWeight={700}>
        {`${dayjs(auction.events?.[0]?.start_time)
          .locale(i18n.locale)
          .format("dddd, DD MMM")} at ${dayjs(
          auction.events?.[0]?.start_time
        ).format("HH:mm")}`}
      </Text>
      <HStack w="100%" space={1} alignItems={"center"}>
        <Icon
          size={4}
          as={MaterialCommunityIcons}
          name={"map-marker-outline"}
        />
        <Text pr={3} fontSize={"sm"} fontWeight={500}>
          {auction.events[0].location}
        </Text>
      </HStack>
    </VStack>

    <Button
      p={3}
      mt={[5, 0]}
      w={["100%", 200]}
      borderRadius={"full"}
      bg={auction?.is_over ? "gray.400" : "darkBackground"}
      _hover={{ bg: "darkBackground" }}
      _pressed={{ bg: "darkBackground" }}
      disabled={auction?.is_over}
      onPress={() => {
        navigation.navigate("Auctions", {
          screen: "AuctionDetail",
          params: { id: auction.id, initialData: auction },
        });
      }}
    >
      {auction?.is_over ? translate("sold_out") : translate("buy_now")}
    </Button>
  </Flex>
));
