import React, { useEffect, useRef } from "react";
import { Text } from "native-base";
import { getLocalizedDateTime } from "utils/helpers";

export const CountdownTimer = ({ endDate }) => {
  const timerRef = useRef(null);
  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [ended, setEnded] = React.useState(false);

  const calculateTimeRemaining = (date: string) => {
    if (date) {
      const parsedDate = Date.parse(date);
      const difference = parsedDate - Date.now();
      if (difference > 0) {
        const timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
        setTimeLeft(timeLeft);
      } else if (difference <= 0) {
        setEnded(true);
        clearInterval(timerRef.current);
      }
    }
  };

  const getPaddedValue = (value: number) => value.toString().padStart(2, "0");

  useEffect(() => {
    calculateTimeRemaining(endDate);
  }, [endDate]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      calculateTimeRemaining(endDate);
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return ended ? (
    <Text fontSize={["sm", "md", "lg"]} bold>
      {getLocalizedDateTime(endDate)}
    </Text>
  ) : (
    <Text bold color="error.600">
      {getPaddedValue(timeLeft.hours)}:{getPaddedValue(timeLeft.minutes)}:
      {getPaddedValue(timeLeft.seconds)}
    </Text>
  );
};
