import React from "react";
import { Platform } from "react-native";
import { Divider, Text, Stack, VStack, Image } from "native-base";
// internal components
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PaymentMethodList } from "features/general/payment-methods/components/payment-method-list.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const PaymentMethodListScreen = ({ navigation }) => {
  const cardPaymentMethods = usePaymentMethods("credit_card");
  const sofortPaymentMethods = usePaymentMethods("bank_account");
  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("payment_methods")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <VStack p={5} space={5}>
        <PaymentMethodList
          title={translate("cards")}
          navigation={navigation}
          query={cardPaymentMethods}
          onAddButtonPress={() => {
            navigation.navigate("PaymentMethods", {
              screen: "PaymentMethodSetupIntentCard",
            });
          }}
        />

        <PaymentMethodList
          title={translate("bank_accounts")}
          onPress={navigation}
          query={sofortPaymentMethods}
          onAddButtonPress={() => {
            navigation.navigate("PaymentMethods", {
              screen: "PaymentMethodSetupIntentSofort",
            });
          }}
        />

        <Text fontSize={["xl", "2xl"]} fontWeight="bold">
          {translate("accepted_payment_methods")}
        </Text>
        <Stack space={2} alignItems="flex-start">
          <Image
            _light={{
              source: require("assets/images/brand-theme/sepa-visa-black.png"),
            }}
            _dark={{
              source: require("assets/images/brand-theme/sepa-visa-white.png"),
            }}
            alt="Payment logos"
            height="50px"
            width="230px"
            resizeMode="contain"
          />
        </Stack>
      </VStack>
    </ScreenFrame>
  );
};
