import React from "react";
import { HStack, Icon, Image, Pressable, useMediaQuery } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// internal
import { navigateBack } from "utils/helpers";
import { AccountDropdown } from "navigation/components/account-dropdown.component";

export const NavigationPanel = ({ goesBack = false }) => {
  const navigation = useNavigation();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return isDesktop ? (
    <HStack
      py={2}
      px={24}
      shadow={"-0"}
      alignItems={"center"}
      bg={"lightBackground"}
      justifyContent={"space-between"}
    >
      <Pressable
        onPress={() =>
          navigation.navigate("BottomNavigator", { screen: "Marketplace" })
        }
      >
        <Image
          h={"50px"}
          w={"100px"}
          alt="Biddz Logo"
          resizeMode={"contain"}
          source={require("assets/images/biddz-theme/biddz-extended-black.png")}
        />
      </Pressable>
      <AccountDropdown navigation={navigation} />
    </HStack>
  ) : (
    <HStack
      p={5}
      alignItems={"center"}
      bg={"lightBackground"}
      justifyContent={goesBack ? "flex-start" : "center"}
    >
      <Pressable
        onPress={() =>
          goesBack
            ? navigateBack(navigation)
            : navigation.navigate("BottomNavigator", { screen: "Marketplace" })
        }
      >
        {goesBack ? (
          <HStack p={3} bg={"gray.100"} rounded={"full"}>
            <Icon as={Ionicons} size={5} name={"arrow-back-outline"} />
          </HStack>
        ) : (
          <Image
            h={"45px"}
            w={"100px"}
            alt={"biddz-logo"}
            resizeMode={"contain"}
            source={require("assets/images/biddz-theme/biddz-extended-black.png")}
          />
        )}
      </Pressable>
    </HStack>
  );
};
