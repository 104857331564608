import { useContext } from "react";
import { Toast } from "native-base";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";
import { translate } from "utils/helpers";
import { ToastAlert } from "theme/feedback/toast-alert.component";

const useSetDefaultPayoutMethod = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return useMutation(
    (paymentMethodID) =>
      client.patch(
        `/payment_methods/${paymentMethodID}/set_default_payout_method/`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`payment-methods`);
        Toast.show({
          placement: "top",
          render: ({ id }) => (
            <ToastAlert
              id={id}
              status="info"
              description={translate("payouts_default_payment_method_success")}
            />
          ),
        });
      },
    }
  );
};

export default useSetDefaultPayoutMethod;
