import React, { useEffect } from "react";
import { openBrowserAsync } from "expo-web-browser";
import * as DocumentPicker from "expo-document-picker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, HStack, Icon, Text, Pressable } from "native-base";
// internal hooks
import useUpdateTrackAudio from "features/royalties/tracks/hooks/useUpdateTrackAudio";
import useUpdateAuctionAudio from "features/general/auctions/hooks/useUpdateAuctionAudio";
// internal components
import { AppIconButton } from "theme/buttons/app-icon-button.component";
// utility functions
import { translate } from "utils/helpers";

export const UploadAudio = ({
  invalid,
  setInvalid,
  setAudioUploading,
  track,
  isAuctionAudio = false,
}) => {
  const { updateTrackAudio } = useUpdateTrackAudio(track.id);
  const { updateAuctionAudio } = useUpdateAuctionAudio(
    track.products && track.products[0]?.license_product?.id
  );
  const audio = isAuctionAudio
    ? track.products[0]?.license_product?.audio
    : track.signed_url_audio;

  const pickDocument = async () => {
    let audio = await DocumentPicker.getDocumentAsync({});
    if (isAuctionAudio) updateAuctionAudio.mutate(audio);
    else updateTrackAudio.mutate(audio);
  };

  useEffect(() => {
    if (updateAuctionAudio.isSuccess) setInvalid(false);
  }, [updateTrackAudio.isSuccess]);

  useEffect(() => {
    setAudioUploading(updateTrackAudio.isLoading);
  }, [updateTrackAudio.isLoading]);

  return (
    <VStack
      p={5}
      space={2}
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      borderWidth={invalid ? 1 : 0}
      borderColor="error.700"
    >
      <Text fontSize="md" mb={5}>
        {translate("upload_audio_file")}
      </Text>
      <HStack space={3}>
        {audio ? (
          <Pressable
            flex={1}
            bg="light.700"
            borderRadius="lg"
            onPress={() => openBrowserAsync(audio)}
          >
            <HStack flex={1} pl={3} space={5} alignItems="center">
              <Icon as={<MaterialCommunityIcons name="file-music" />} />
              <Text fontSize={["md", "lg"]}>
                {translate("open_audio_file")}
              </Text>
            </HStack>
          </Pressable>
        ) : (
          <Text
            p={3}
            flex={1}
            color="dark.600"
            bg="light.700"
            borderRadius="lg"
          >
            Final-Song-File.wav
          </Text>
        )}
        <AppIconButton
          bg={"dark.900"}
          disabled={
            track?.is_submitted ||
            updateTrackAudio.isLoading ||
            updateAuctionAudio.isLoading
          }
          onPress={pickDocument}
          iconName={audio ? "pencil" : "plus"}
        />
      </HStack>
      <Text fontSize={["xs", "sm"]} color="muted.400">
        {translate("upload_audio_file_helper_text")}
      </Text>
    </VStack>
  );
};
