import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useAnalytics = (id) => {
  const { client } = useContext(AxiosContext);
  return useQuery(`release-${id}-analytics`, () => {
    console.debug(`release-${id}-analytics retrieved`);

    return client.get(`releases/${id}/analytics/`).then((resp) => resp.data);
  });
};
