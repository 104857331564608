import React from "react";
import { FlatList, HStack, ScrollView, Text, VStack } from "native-base";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
// internal functions
import { translate, navigateBack } from "utils/helpers";
// internal hooks
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
import useSetDefaultPayoutMethod from "features/general/payment-methods/hooks/useSetDefaultPayoutMethod";
// internal components
import { PaymentMethod } from "features/general/payment-methods/components/payment-method.component";
import { Alert } from "theme/feedback/alert.component";
import { AddPaymentMethodButton } from "features/general/payment-methods/components/add-payment-method-button.component";
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";

export const PaymentMethodPayoutsScreen = ({ navigation }) => {
  const bankPaymentMethods = usePaymentMethods("bank_account");
  const setDefaultPayoutMethod = useSetDefaultPayoutMethod();
  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("payouts")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <VStack p={5} space={5}>
          <HStack>
            <Alert
              title={translate("payouts")}
              text={translate("payouts_info")}
            />
          </HStack>
          <Text fontSize={["xl", "2xl"]} fontWeight="bold">
            {translate("bank_accounts")}
          </Text>
          {bankPaymentMethods.isLoading || setDefaultPayoutMethod.isLoading ? (
            <HStack h={9} w={"100%"} my={2} space={2} overflow={"hidden"}>
              <SkeletonRounded width={["15%", "10%", "7%"]} />
              <SkeletonRounded width={["65%", "50%", "35%"]} />
            </HStack>
          ) : (
            <>
              <FlatList
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                horizontal={true}
                data={bankPaymentMethods.data}
                contentContainerStyle={{
                  alignItems: "center",
                }}
                ListHeaderComponent={
                  <AddPaymentMethodButton
                    onPress={() =>
                      navigation.navigate("PaymentMethods", {
                        screen: "PaymentMethodList",
                      })
                    }
                  />
                }
                renderItem={({ item }) => (
                  <PaymentMethod
                    isChecked={item.is_default_payout_method}
                    paymentMethod={item}
                    onPress={() => setDefaultPayoutMethod.mutate(item.id)}
                  />
                )}
              />
            </>
          )}
        </VStack>
      </ScrollView>
    </ScreenFrame>
  );
};
