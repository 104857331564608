import React from "react";
import { ImageBackground } from "react-native";
import { Box, VStack, Pressable, useTheme } from "native-base";
// internal components
import { RunningText } from "theme/typography/running-text.component";

export const AuctionCardCompact = ({ onPress, auction }) => (
  <Pressable mb={5} mr={5} onPress={onPress}>
    <Box borderRadius={"xl"} overflow={"hidden"}>
      <ImageBackground
        source={{
          uri: auction?.image_with_url?.sm?.url,
        }}
        alt={auction?.title + " Cover"}
        style={{ width: 160, height: 200 }}
        resizeMode="cover"
      >
        <VStack
          p={2}
          flex={1}
          justifyContent={"flex-end"}
          bg={{
            linearGradient: {
              colors: ["transparent", "rgba(0,0,0,0.15)"],
              start: [0, 0],
              end: [0, 1],
            },
          }}
          alt={auction?.title + " Cover"}
          style={{ width: 160, height: 200 }}
          resizeMode="cover"
        >
          <RunningText
            text={auction?.title}
            style={{ color: "#fff", fontSize: 14 }}
          />
          <RunningText
            text={auction?.subtitle}
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#fff",
            }}
          />
        </VStack>
      </ImageBackground>
    </Box>
  </Pressable>
);
