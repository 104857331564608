import React from "react";
import { Platform } from "react-native";
import * as ExpoImagePicker from "expo-image-picker";
import { VStack, Stack, Image, Pressable } from "native-base";
// utility functions
import { translate } from "utils/helpers";
// internal hooks
import useUpdateProfileImage from "features/accounts/profiles/hooks/useUpdateProfileImage";
// internal components
import { AppIcon } from "theme/media/app-icon.component";
import { Loading } from "common/components/loading/loading.component";
import { AppIconButton } from "theme/buttons/app-icon-button.component";

interface IProps {
  url?: string;
  isCover?: boolean;
}

export const ProfileEditImageBody = ({ url, isCover }: IProps) => {
  const { updateProfileImage } = useUpdateProfileImage(isCover);

  const pickImage = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert(translate("camera_permission_denied_text"));
      }
    }
    const { status } = await ExpoImagePicker.getMediaLibraryPermissionsAsync();
    if (status === "granted") {
      let result: any = await ExpoImagePicker.launchImageLibraryAsync({
        // presentationStyle: 0,
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
        base64: true,
      });
      if (!result.cancelled) {
        updateProfileImage.mutate(result);
      }
    }
  };

  return (
    <VStack>
      {updateProfileImage.isLoading ? (
        <Loading pt={0} />
      ) : url ? (
        <Stack position="relative">
          <Pressable onPress={pickImage}>
            <Image
              size="sm"
              borderRadius={"xl"}
              alt={"profile image"}
              source={{
                uri: url + "?key=" + Math.random(),
              }}
            />
            <AppIcon
              size={5}
              right={0}
              bottom={0}
              name="plus"
              position="absolute"
            />
          </Pressable>
        </Stack>
      ) : (
        <AppIconButton iconName={"plus"} onPress={pickImage} />
      )}
    </VStack>
  );
};
