import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useCreateMusician = () => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    createMusician: useMutation(
      (params = {}) =>
        client.post("musicians/", params).then((res) => res.data),
      {
        onSuccess: () => {
          // queryClient.invalidateQueries("profile");
          queryClient.invalidateQueries("musician");
        },
      }
    ),
    error: error,
  };
};

export default useCreateMusician;
