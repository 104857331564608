import React, { useEffect, useState } from "react";
import { VStack, Pressable, Box, HStack, Text, ScrollView } from "native-base";
// internal functions
import { translate } from "utils/helpers";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
// internal hooks
import useCreateRelease from "features/royalties/releases/hooks/useCreateRelease";
import { ModalFrame } from "navigation/components/modal-frame.component";

export const ReleaseCreateInfoScreen = ({ navigation }) => {
  const isFirstRouteInParent = useIsFirstRouteInParent();
  const [secondStep, setSecondStep] = useState(false);
  const { createRelease } = useCreateRelease();

  useEffect(() => {
    if (createRelease.isSuccess) {
      navigation.replace("Releases", {
        screen: "ReleaseEdit",
        params: {
          id: createRelease.data?.id,
          releasePartial: createRelease.data,
        },
      });
    }
  }, [createRelease.isSuccess]);

  useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.navigate("BottomNavigator", { screen: "ReleaseTabs" });
    }
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        h={"100%"}
        bg={"light.100"}
        overflow={"hidden"}
        borderTopRadius={20}
      >
        <ScrollView borderTopRadius={20} showsVerticalScrollIndicator={false}>
          <Box mt={3} />
          <VStack m={5} space={10}>
            {secondStep ? (
              <HStack justifyContent="space-between">
                <Pressable
                  isDisabled={createRelease.isLoading}
                  onPress={() => setSecondStep(false)}
                >
                  <Text
                    fontWeight={500}
                    color={"darkText"}
                    fontSize={["md", "lg"]}
                  >
                    {translate("previous")}
                  </Text>
                </Pressable>
                <Pressable
                  isDisabled={createRelease.isLoading}
                  onPress={() => createRelease.mutate()}
                >
                  <Text
                    fontWeight={500}
                    color={"darkText"}
                    fontSize={["md", "lg"]}
                  >
                    {translate("finish")}
                  </Text>
                </Pressable>
              </HStack>
            ) : (
              <HStack justifyContent={"space-between"}>
                <Box />
                <Pressable onPress={() => setSecondStep(true)}>
                  <Text
                    fontSize={["md", "lg"]}
                    fontWeight="semibold"
                    _dark={{ color: "lightText" }}
                    _light={{ color: "darkText" }}
                  >
                    {translate("next")}
                  </Text>
                </Pressable>
              </HStack>
            )}
            <Text fontSize={["md", "lg"]} bold textAlign="center">
              {secondStep
                ? translate("how_much_release_cost")
                : translate("create_a_release")}
            </Text>
            <Text fontSize={["sm", "md"]}>
              {secondStep
                ? translate("create_a_release_description")
                : translate("how_much_release_cost_description")}
            </Text>
          </VStack>
        </ScrollView>
      </VStack>
    </ModalFrame>
  );
};
