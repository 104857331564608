import React from "react";
import { Box, VStack, useMediaQuery } from "native-base";
import * as Animatable from "react-native-animatable";
import { Video, ResizeMode } from "expo-av";
import { useNavigation } from "@react-navigation/native";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { InteractiveImage } from "theme/media/interactive-image.component";
// internal functions
import { getWindowWidth, translate } from "utils/helpers";

export const OrderDetailSuccess = ({
  imageURL,
  videoURL,
  isTicketCollectible,
}) => {
  const navigation = useNavigation();
  const width = getWindowWidth() / 1.5;
  const imageWidth = isDesktop ? width / 1.6 : width;
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    <VStack flex={1}>
      <VStack justifyContent={"center"} alignItems={"center"} flex={1}>
        <Animatable.View
          animation="bounceInDown"
          duration={5000}
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <VStack flex={1} justifyContent={"center"} alignItems={"center"}>
            {videoURL ? (
              <Video
                isMuted
                isLooping
                shouldPlay
                useNativeControls={false}
                source={{ uri: videoURL }}
                resizeMode={ResizeMode.CONTAIN}
                style={{ height: imageWidth, width: imageWidth }}
                videoStyle={{ height: "100%", width: imageWidth }}
              />
            ) : (
              <InteractiveImage
                width={imageWidth}
                height={imageWidth}
                imageURL={imageURL}
              />
            )}
          </VStack>
        </Animatable.View>
      </VStack>
      <Box safeAreaBottom>
        <PrimaryButton
          text={translate("my_cards")}
          onPress={() =>
            navigation.navigate("BottomNavigator", {
              screen: isTicketCollectible ? "Events" : "Shares",
            })
          }
        />
      </Box>
    </VStack>
  );
};
