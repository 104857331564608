import React from "react";
import { useController } from "react-hook-form";
import { HStack, Checkbox, FormControl } from "native-base";
// internal
import { translate } from "utils/helpers";

export const BooleanCheckbox = ({
  control,
  name,
  value = false,
  rules = {},
  width = "100%",
  children = null,
  ...props
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ control, defaultValue: value, name, rules });

  return (
    <FormControl width={width} isInvalid={invalid}>
      <HStack space="2">
        <Checkbox
          {...props}
          value={field.value}
          colorScheme="primary"
          accessibilityLabel="Checkbox"
          isChecked={field.value}
          onChange={field.onChange}
        />
        {children}
      </HStack>
      <FormControl.ErrorMessage>
        {error?.type == "required" && translate("please_check_box")}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};
