import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Box, HStack, Icon, VStack, Text } from "native-base";
// utility functions
import { navigateBack, translate } from "utils/helpers";
import { useIsFirstRouteInParent } from "utils/useIsFirstRouteInParent";
// internal hooks
import useCreateTransfer from "features/general/transfers/hooks/useCreateTransfer";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { PhoneNumberInput } from "features/accounts/users/components/phone-number-input.component";
import { ModalFrame } from "navigation/components/modal-frame.component";

export const TransferCreateScreen = ({ navigation, route }) => {
  const { collectibleID } = route.params;
  const { control, handleSubmit } = useForm();
  const collectibleTransfer = useCreateTransfer(collectibleID);
  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false);

  React.useEffect(() => {
    if (collectibleTransfer?.isSuccess) {
      navigation.replace("BottomNavigator", {
        screen: "Lounge",
      });
    }
  }, [collectibleTransfer.isSuccess]);

  const isFirstRouteInParent = useIsFirstRouteInParent();
  React.useEffect(() => {
    if (isFirstRouteInParent) {
      navigation.replace("TicketCollectibles", {
        screen: "TicketCollectibleDetail",
        params: { id: collectibleID },
      });
    }
  }, []);

  return (
    <ModalFrame transparentFlex={1} navigation={navigation}>
      <VStack
        shadow={-0}
        bg={"light.100"}
        overflow={"hidden"}
        borderTopRadius={20}
      >
        <Box mt={3} />
        <NavigationHeader
          title={translate("transfer_ticket")}
          headerRight={{
            iconName: "close",
            onPress: () => navigateBack(navigation),
          }}
          headerLeft={
            showConfirmTransfer
              ? {
                  iconName: "chevron-left",
                  onPress: () => setShowConfirmTransfer(false),
                }
              : null
          }
          bg="transparent"
        />
        <VStack space={10} p={5}>
          <VStack space={10}>
            <HStack space={2}>
              <Icon
                size={7}
                color={"secondary.600"}
                name="information-outline"
                as={MaterialCommunityIcons}
              />
              <Text flex={1}>
                {showConfirmTransfer
                  ? translate("create_transfer_post_confirm")
                  : translate("create_transfer_pre_confirm")}
              </Text>
            </HStack>

            <VStack _ios={{ h: 10 }}>
              <PhoneNumberInput
                control={control}
                isDisabled={showConfirmTransfer}
              />
            </VStack>
          </VStack>

          <PrimaryButton
            text={translate("transfer_ticket")}
            isLoading={collectibleTransfer?.isLoading}
            disabled={collectibleTransfer?.isLoading || !collectibleID}
            onPress={handleSubmit((data) => {
              if (showConfirmTransfer) {
                collectibleTransfer.mutate({
                  phone_number: "+" + data.country_code + data.phone_number,
                });
              } else {
                setShowConfirmTransfer(true);
              }
            })}
          />
        </VStack>
      </VStack>
    </ModalFrame>
  );
};
