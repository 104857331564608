import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useUserCollectibles = ({
  params,
  size = 8,
  enabled = true,
  key = "",
  filter = "",
}) => {
  const { client } = useContext(AxiosContext);
  return useInfiniteQuery(
    [
      `user-collectibles`,
      key,
      params.event_start_time__lt || params.event_start_time__gte
        ? filter
        : params,
    ],
    ({ pageParam = 1 }) =>
      client
        .get(`me/collectibles/?page=${pageParam}&size=${size}`, {
          params,
        })
        .then((resp) => {
          console.debug(
            `💙 Collectibles | Page ${pageParam} | By Product ${params.by_product} `
          );
          return resp.data;
        }),
    {
      enabled,
      staleTime: 300000,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) =>
        lastPage.total / lastPage.size > lastPage.page
          ? lastPage.page + 1
          : null,
    }
  );
};
