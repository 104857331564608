import React, { useEffect, useState } from "react";
import { Text, Box, VStack, HStack, ScrollView } from "native-base";
import { useForm, useController } from "react-hook-form";
// internal hooks
import { useRelease } from "features/royalties/releases/hooks/useRelease";
import useUpdateReleaseStatus from "features/royalties/releases/hooks/useUpdateReleaseStatus";
// internal components
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ReleaseDistributionDetails } from "features/royalties/releases/components/release-distribution-details.component";
import { ReleaseSuccess } from "features/royalties/releases/components/release-success.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { ReleaseEditSummaryTrackCard } from "features/royalties/releases/components/release-edit-summary-track-card.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { Link } from "theme/typography/link.component";
import { ResponsiveStack } from "common/components/layout/responsive-stack.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const ReleaseEditSummaryScreen = ({ navigation, route }) => {
  const { id: releaseID, releasePartial = {} } = route.params;
  const { data: release, isLoading } = useRelease(releaseID, releasePartial);
  const { updateReleaseStatus } = useUpdateReleaseStatus(release?.id);
  const { control, handleSubmit } = useForm();
  const { field: agreedLicense } = useController({
    name: "agree_license",
    control,
  });

  const [openLink, setOpenLink] = useState(false);

  const onPress = (data) => {
    updateReleaseStatus.mutate("under_review");
  };

  useEffect(() => {
    if (agreedLicense.value) setOpenLink(true);
  }, [agreedLicense.value]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={!updateReleaseStatus.isSuccess ? translate("release") : ""}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", { screen: "ReleaseTabs" }),
        }}
        profileProgress={{ type: "release", activeIndex: 4 }}
      />
      {updateReleaseStatus.isSuccess ? (
        <ReleaseSuccess releaseID={release?.id} navigation={navigation} />
      ) : isLoading ? (
        <Loading />
      ) : (
        <ScrollView
          pb={8}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <PageTitle title={translate("summary_correct")} />
          <VStack space="10" mt="5">
            <ResponsiveStack space="10">
              <VStack flex={{ lg: 1 }} space="4">
                <HStack px="4" justifyContent="space-between">
                  <Text fontSize={["md", "lg"]}>
                    {translate("album_title")}
                  </Text>
                  <Text fontSize={["md", "lg"]} bold>
                    {release?.title}
                  </Text>
                </HStack>

                {release?.tracklisting?.map((listing, index) => (
                  <ReleaseEditSummaryTrackCard
                    key={index}
                    index={index + 1}
                    track={listing?.track}
                    release={release}
                  />
                ))}
              </VStack>
              <Box flex={{ lg: 1 }}>
                <ReleaseDistributionDetails release={release} />

                <VStack space="2" px="4">
                  <Box mt="5">
                    <BooleanCheckbox
                      mt="1"
                      name="agree_license"
                      control={control}
                      rules={{ required: true }}
                    >
                      <Link
                        openLink={openLink}
                        fontSize={["md", "lg"]}
                        textBefore={translate(
                          "distribution_policy_agreement_text_first"
                        )}
                        text={translate("special_terms")}
                        textAfter={translate(
                          "distribution_policy_agreement_text_last"
                        )}
                        url="https://storage.googleapis.com/app-public-europe-west3-1d3f335b/term-distribution/20230707_term_distribution_45cf7a7a-e6e8-44c6-ac84-46bd2bd1223e.pdf"
                      />
                    </BooleanCheckbox>
                  </Box>
                </VStack>
              </Box>
            </ResponsiveStack>
            <PrimaryButton
              nativeID="submit-release"
              p="5"
              showBackButton
              disabled={
                updateReleaseStatus.isLoading || release?.status !== "draft"
              }
              isLoading={updateReleaseStatus.isLoading}
              text={translate("submit")}
              onPress={handleSubmit(onPress)}
            />
          </VStack>
        </ScrollView>
      )}
    </ScreenFrame>
  );
};
