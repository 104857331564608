import React from "react";
import { Stack, FlatList, HStack, Box, Text } from "native-base";
// internal components
import { PayoutDetailCard } from "features/royalties/payouts/components/payout-detail-card.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
// utility functions
import { navigateBack, translate } from "utils/helpers";
//internal hooks
import { useUserPayouts } from "features/royalties/payouts/hooks/useUserPayouts";
import { OrderListSkeleton } from "features/general/orders/skeletons/order-list-skeleton.component";

export const PayoutListScreen = ({ navigation }) => {
  const payouts = useUserPayouts();

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("payouts_history")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
      />
      <Stack mt={5} px={5} flex={1}>
        {payouts.isLoading ? (
          <OrderListSkeleton />
        ) : (
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            onEndReached={() =>
              payouts.hasNextPage ? payouts.fetchNextPage() : null
            }
            onEndReachedThreshold={0.3}
            data={payouts?.data?.pages.map((page: any) => page.items).flat()}
            renderItem={({ item }) => <PayoutDetailCard item={item} />}
            ListEmptyComponent={
              <Stack
                marginTop={"40%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontWeight={600} fontSize={["md", "lg"]}>
                  {translate("no_payouts")}
                </Text>
              </Stack>
            }
            ListFooterComponent={
              <Box m={2}>
                {payouts.isFetching && (
                  <HStack justifyContent={"center"}>
                    <CenteredSpinner />
                  </HStack>
                )}
              </Box>
            }
          />
        )}
      </Stack>
    </ScreenFrame>
  );
};
