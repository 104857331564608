import React from "react";
import { useController } from "react-hook-form";
import { Input as NBInput } from "native-base";

export const Input = ({
  control,
  rules = {},
  name,
  value = null,
  variant = "filled",
  ...props
}) => {
  const { field } = useController({
    control,
    defaultValue: value,
    name,
    rules,
  });
  return (
    <NBInput
      {...props}
      autoCapitalize="none"
      value={field.value}
      onChangeText={field.onChange}
      variant={variant}
      size={["md", "lg"]}
    />
  );
};
