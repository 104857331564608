import React, { useContext, useState } from "react";
import { openBrowserAsync } from "expo-web-browser";
import { HStack, Modal, ScrollView, Stack, Text } from "native-base";
import { useNavigation } from "@react-navigation/native";
//internal components
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { AuthenticationContext } from "services/authentication.context";
import { AppIconButtonWithOutline } from "theme/buttons/app-icon-button-with-outline";
import { translate } from "utils/helpers";

const socialIcons = ["twitter", "instagram", "discord", "youtube"];

export const ProfileDetailHeaderSocials = ({ profile }) => {
  const navigation = useNavigation();
  const { user, userDetails } = useContext(AuthenticationContext);

  const socials = profile?.socials_with_url;
  const isUserProfile =
    userDetails?.id === profile?.user_id && profile?.user_id;

  const [showModal, setShowModal] = useState(false);

  return (
    <ScrollView mt={4} horizontal showsHorizontalScrollIndicator={false}>
      <HStack
        space={3}
        px={{ base: 3 }}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        {isUserProfile && (
          <SecondaryButton
            w={150}
            p={3}
            fontSize={"md"}
            _ios={{ maxW: 150 }}
            fontWeight={"medium"}
            borderColor={"#B8BABA"}
            nativeID={"edit-profile"}
            text={translate("edit_profile")}
            onPress={() =>
              navigation.navigate("Profiles", {
                screen: "ProfileEdit",
                params: { id: userDetails?.id },
              })
            }
          />
        )}
        {socialIcons.map((social, i) => {
          const item = Object.keys(socials || []).find((s) => s === social);
          return (
            <Stack key={i}>
              <AppIconButtonWithOutline
                p={2.5}
                borderColor={"#B8BABA"}
                name={social}
                disabled={!!!item}
                onPress={() => {
                  if (social === "discord") {
                    setShowModal(!showModal);
                  } else {
                    openBrowserAsync(socials[item].url);
                  }
                }}
              />
              <Modal
                isOpen={showModal}
                onClose={() => setShowModal(!showModal)}
                borderRadius={"xl"}
                alignItems={["center", "center", "center"]}
              >
                <Modal.Content
                  maxWidth={992}
                  bg={"light.100"}
                  borderRadius={"xl"}
                >
                  <Modal.Body>
                    <Text fontSize={["md", "lg"]}>
                      {socials?.discord?.username}
                    </Text>
                  </Modal.Body>
                </Modal.Content>
              </Modal>
            </Stack>
          );
        })}
      </HStack>
    </ScrollView>
  );
};
