import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { CollectibleDetailBasicScreen } from "features/general/collectibles/screens/collectible-detail-basic.screen";

const Stack = createStackNavigator();

export const CollectiblesNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="CollectibleDetailBasic"
        component={CollectibleDetailBasicScreen}
      />
    </Stack.Navigator>
  );
};
