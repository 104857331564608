import React, { useContext } from "react";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { CodeVerificationScreen } from "features/accounts/users/screens/code-verification.screen";
import { UserDetailScreen } from "features/accounts/users/screens/user-detail.screen";
import { AdminPhoneLoginScreen } from "features/accounts/users/screens/admin-phone-login.screen";
import { CouponDetailScreen } from "features/general/coupons/screens/coupon-detail.screen";
import { UserEditTermsOfAgreementScreen } from "features/accounts/users/screens/user-edit-terms-of-agreement.screen";
import { LoginScreen } from "features/accounts/users/screens/login.screen";
import { UserProfileBadgesListScreen } from "features/accounts/users/screens/user-profile-badges-list.screen";

const Stack = createStackNavigator();

export const UsersNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen
        name="CodeVerification"
        component={CodeVerificationScreen}
        options={{
          ...TransitionPresets.ModalTransition,
          presentation: "transparentModal",
        }}
      />

      <Stack.Screen name="AdminLogin" component={AdminPhoneLoginScreen} />
      <Stack.Screen name="UserDetail" component={UserDetailScreen} />
      <Stack.Screen name="UserBadges" component={UserProfileBadgesListScreen} />
      <Stack.Screen
        name="UserEditTermsOfAgreement"
        component={UserEditTermsOfAgreementScreen}
      />
      <Stack.Screen name="CouponDetail" component={CouponDetailScreen} />
    </Stack.Navigator>
  );
};
