import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
// internal
import { AxiosContext } from "services/axios.context";

const useCreateOrder = () => {
  const { client } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return {
    createOrder: useMutation((params) => client.post(`/orders/`, params), {
      onSuccess: (resp) => {
        queryClient.invalidateQueries("collectibles");
        return resp.data;
      },
    }),
  };
};

export default useCreateOrder;
