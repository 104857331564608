import React, { useEffect, useState } from "react";
import {
  Pressable,
  Text,
  VStack,
  ScrollView,
  Toast,
  Divider,
  Stack,
  HStack,
} from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { useForm, useController } from "react-hook-form";
import { openBrowserAsync } from "expo-web-browser";
// internal hooks
import { useAuction } from "features/general/auctions/hooks/useAuction";
import useUpdateRelease from "features/royalties/releases/hooks/useUpdateRelease";
import useUpdateAuction from "features/general/auctions/hooks/useUpdateAuction";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { Loading } from "common/components/loading/loading.component";
import { BooleanCheckbox } from "theme/forms/boolean-checkbox.component";
import { ToastAlert } from "theme/feedback/toast-alert.component";
import { AuctionSubmitSuccess } from "features/general/auctions/components/auction-submit-success.component";
import { PageTitle } from "common/components/layout/page-title.component";
import { Link } from "theme/typography/link.component";
import { AuctionSummaryAlbumDetail } from "features/general/auctions/components/auction-summary-album-detail.component";
import { ProfileSummary } from "features/accounts/profiles/components/profile-summary.component";
import { AuctionSummaryInfo } from "features/general/auctions/components/auction-summary-info.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

const Checkbox = ({
  name,
  control,
  text,
  isDisabled,
  link = "",
  rules = {},
}) => (
  <BooleanCheckbox
    mt="1"
    rules={rules}
    isDisabled={isDisabled}
    name={name}
    control={control}
  >
    <Pressable
      maxW={{ base: "65%", sm: "100%", lg: "80%" }}
      disabled={!link}
      onPress={() => openBrowserAsync(link)}
    >
      <Text ml="2" fontSize={["md", "lg"]}>
        {text}
      </Text>
    </Pressable>
  </BooleanCheckbox>
);

export const AuctionEditSummaryScreen = ({ navigation, route }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [openLink, setOpenLink] = useState(false);

  const { id: auctionID, auctionPartials = {} } = route.params;
  const { data: auction, isFetched } = useAuction(
    auctionID,
    auctionPartials,
    false
  );
  const product = auction?.products && auction.products[0];
  const release = product?.license_product?.release;
  const { control, handleSubmit } = useForm();
  const { field: agreedLicense } = useController({
    name: "agree_license",
    control,
  });

  const { updateRelease } = useUpdateRelease({
    id: release?.id,
  });
  const { updateAuction } = useUpdateAuction(auction?.id);

  const onPress = (data) => {
    const params = {
      is_deliver_without_deal: data.is_deliver_without_deal,
    };
    //cannot submit deal if release is in draft
    if (release?.status === "draft") {
      Toast.show({
        placement: "top",
        render: ({ id }) => (
          <ToastAlert
            closable
            id={id}
            status="error"
            description={"Cannot Submit deal if your release is still in draft"}
          />
        ),
      });
    } else {
      updateAuction.mutate({ status: "under_review" });
      updateRelease.mutate({
        id: release?.id,
        params,
      });
    }
  };

  useEffect(() => {
    if (agreedLicense.value) setOpenLink(true);
  }, [agreedLicense.value]);

  useEffect(() => {
    setIsSuccess(updateAuction.isSuccess && updateRelease.isSuccess);
  }, [updateAuction.isSuccess, updateRelease.isSuccess]);

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: { screen: "ReleaseDeals" },
            }),
        }}
        profileProgress={{ type: "deal", activeIndex: 5 }}
      />
      {!isSuccess ? (
        <VStack flex={1} justifyContent="space-between">
          <ScrollView
            h={1}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <PageTitle title={translate("summary")} />
            {!isFetched ? (
              <Loading />
            ) : (
              <VStack py="10" px="5" space="10">
                <Stack
                  justifyContent={"space-between"}
                  direction={{ base: "column", lg: "row" }}
                  space="10"
                >
                  <VStack space="10" flex={{ lg: 1 }}>
                    <AuctionSummaryAlbumDetail
                      release={release}
                      tracks={product?.license_product?.tracks}
                    />
                    <AuctionSummaryInfo auction={auction} product={product} />
                  </VStack>

                  <VStack flex={{ lg: 1 }} space="5">
                    <HStack justifyContent={"space-between"}>
                      <Text fontSize={["md", "lg"]}>
                        {translate("contract_term")}
                      </Text>
                      <Text mr="2" fontSize={["md", "lg"]}>
                        4 {translate("years")}
                      </Text>
                    </HStack>
                    <Text ml="4" fontSize={["md", "lg"]} fontWeight="semibold">
                      {translate("contract_term_deal_description")}
                    </Text>
                    <Text fontSize={["md", "lg"]}>{translate("invoice")}</Text>
                    <Text ml="4" fontSize={["md", "lg"]} fontWeight="semibold">
                      {translate("invoice_description")}
                    </Text>
                    <ProfileSummary />
                    <Divider />
                    <VStack space="5">
                      <BooleanCheckbox
                        mt="1"
                        name="agree_license"
                        control={control}
                        rules={{ required: true }}
                      >
                        <Link
                          openLink={openLink}
                          fontSize={["md", "lg"]}
                          textBefore={translate(
                            "deal_policy_agreement_text_first"
                          )}
                          text={translate("special_terms")}
                          textAfter={translate(
                            "deal_policy_agreement_text_last"
                          )}
                          url="https://storage.googleapis.com/app-public-europe-west3-1d3f335b/term-deal/20230707_term_deal_45cf7a7a-e6e8-44c6-ac84-46bd2bd1223e.pdf"
                        />
                      </BooleanCheckbox>

                      <HStack mr={5} justifyContent={"space-between"}>
                        <Checkbox
                          isDisabled={
                            !["draft", "action_required"].includes(
                              auction?.status
                            )
                          }
                          name="is_deliver_without_deal"
                          control={control}
                          text={translate("distribute_anyway_text")}
                        />
                        <IconModal
                          iconColor="primary.600"
                          icon={<AntDesign size={5} name="questioncircleo" />}
                          contentText={translate("distribute_anyway_info_text")}
                        />
                      </HStack>
                    </VStack>
                  </VStack>
                </Stack>
              </VStack>
            )}
          </ScrollView>

          {["draft", "action_required"].includes(auction?.status) && (
            <Stack justifyContent="flex-end">
              <PrimaryButton
                nativeID="submit-deal"
                p="5"
                justifyContent={{ base: "center", lg: "flex-end" }}
                showBackButton
                disabled={
                  updateRelease.isLoading ||
                  updateAuction.isLoading ||
                  !isFetched
                }
                text={translate("submit")}
                onPress={handleSubmit(onPress)}
              />
            </Stack>
          )}
        </VStack>
      ) : (
        <AuctionSubmitSuccess auction={auction} />
      )}
    </ScreenFrame>
  );
};
