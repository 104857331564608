import React, { useEffect, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import { VStack, ScrollView, Text, HStack } from "native-base";
import { useForm } from "react-hook-form";
// internal hooks
import { useUserAuction } from "features/general/auctions/hooks/useUserAuction";
import useUpdateAuctionTrack from "features/general/auctions/hooks/useUpdateAuctionTrack";
import { useUpdateAuctionPromoImage } from "features/general/auctions/hooks/useUpdateAuctionPromoImg";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { Loading } from "common/components/loading/loading.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ScreenFrame } from "navigation/components/screen-frame.component";
import { NavigationHeader } from "navigation/components/navigation-header.component";
import { ImagePicker } from "features/royalties/releases/components/image-picker.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";
import { PageTitle } from "common/components/layout/page-title.component";
// utility functions
import { translate, navigateBack } from "utils/helpers";

export const AuctionEditPromotionalScreen = ({ navigation, route }) => {
  const { id: auctionID, auctionPartials = {} } = route.params;
  const { data: auction, isFetched } = useUserAuction(
    auctionID,
    auctionPartials
  );
  const product = auction?.products && auction?.products[0];
  const { updateAuctionTrack } = useUpdateAuctionTrack(
    product?.license_product?.id
  );
  const { updateAuctionPromoImage } = useUpdateAuctionPromoImage(auctionID);
  const promotionalImage = auction?.promotional_image_with_url?.sm?.url;

  const { control, handleSubmit } = useForm();
  const [image, setImage] = useState({ uri: "" });

  const navigateForward = () => {
    navigation.navigate("Auctions", {
      screen: "AuctionEditSummary",
      params: { id: auctionID, auctionPartials: auction },
    });
  };

  const onPress = async (data) => {
    if (!["draft", "action_required"].includes(auction?.status))
      navigateForward();
    else {
      await updateAuctionTrack.mutateAsync(data);
      if (image.uri && image.uri !== promotionalImage) {
        await updateAuctionPromoImage.mutateAsync(image);
      }
      navigateForward();
    }
  };

  const onSave = async (data) => {
    if (["draft", "action_required"].includes(auction?.status)) {
      await updateAuctionTrack.mutateAsync(data);
      if (image.uri && image.uri !== promotionalImage) {
        await updateAuctionPromoImage.mutateAsync(image);
      }
    }
  };

  useEffect(() => {
    if (isFetched && auction) {
      setImage({ uri: promotionalImage });
    }
  }, [isFetched]);

  const isAuctionEditable = !["draft", "action_required"].includes(
    auction?.status
  );

  return (
    <ScreenFrame isAuthenticationRequired>
      <NavigationHeader
        title={translate("deal")}
        headerLeft={{
          iconName: "arrow-left",
          onPress: () => navigateBack(navigation),
        }}
        headerRight={{
          iconName: "close",
          onPress: () =>
            navigation.navigate("BottomNavigator", {
              screen: "ReleaseTabs",
              params: { screen: "ReleaseDeals" },
            }),
        }}
        profileProgress={{ type: "deal", activeIndex: 4 }}
      />
      <VStack flex={1}>
        <ScrollView h={1} showsVerticalScrollIndicator={false}>
          <PageTitle
            nativeID="save-deal-promotion"
            isDisabled={
              updateAuctionTrack.isLoading ||
              !isFetched ||
              updateAuctionPromoImage.isLoading
            }
            title={translate("promotional")}
            onSave={handleSubmit(onSave)}
          />
          <Text mx="10" fontSize="sm" mb="5" textAlign="center">
            {translate("promotional_help_text")}
          </Text>
          {!isFetched ? (
            <Loading />
          ) : (
            <VStack pb="10" space="10">
              <VStack
                px="5"
                py="10"
                space="10"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <HStack alignItems="center" justifyContent="space-between">
                  <Text fontSize={["md", "lg"]}>
                    {translate("upload_photo")}
                  </Text>
                  <IconModal
                    iconColor="primary.600"
                    icon={<AntDesign size={5} name="questioncircleo" />}
                    contentText={translate("promo_image_info_text")}
                  />
                </HStack>

                <VStack justifyContent="center" alignItems="center">
                  <ImagePicker
                    isDisabled={isAuctionEditable}
                    image={image}
                    setImage={setImage}
                  />
                </VStack>
              </VStack>
              <VStack
                p="5"
                flex={1}
                space="5"
                _light={{ bg: "light.100" }}
                _dark={{ bg: "dark.900" }}
              >
                <HStack justifyContent={"space-between"} alignItems="center">
                  <Text fontSize={["md", "lg"]}>
                    15s Pre-Listening Snippet Track
                  </Text>
                  <IconModal
                    iconColor="primary.600"
                    icon={<AntDesign size={5} name="questioncircleo" />}
                    contentText={translate("audio_snippet_info_text")}
                  />
                </HStack>

                <FormControlSelectWithDict
                  helperText={translate("audio_snippet_helper_text")}
                  infoText={translate("audio_snippet_info_text")}
                  isDisabled={isAuctionEditable}
                  selectedValue={product.license_product?.tracks[0]?.id}
                  name="track_id"
                  control={control}
                  rules={{ required: true }}
                  dict={product.license_product?.tracks?.map((item) => {
                    return { label: item.title, value: item.id };
                  })}
                />
              </VStack>
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <PrimaryButton
        p="5"
        showBackButton
        showNextIcon
        disabled={
          updateAuctionTrack.isLoading ||
          !isFetched ||
          updateAuctionPromoImage.isLoading
        }
        isLoading={
          updateAuctionTrack.isLoading || updateAuctionPromoImage.isLoading
        }
        text={translate("summary")}
        onPress={handleSubmit(onPress)}
      />
    </ScreenFrame>
  );
};
