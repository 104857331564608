import React from "react";
import * as Linking from "expo-linking";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  FlatList,
  VStack,
  HStack,
  Text,
  Pressable,
  Image,
  Icon,
} from "native-base";
import { translate } from "utils/helpers";

export const MusicianDetailReleaseList = ({ musician, releases }) => {
  return (
    releases?.length > 0 && (
      <VStack>
        <Text p={5} bold fontSize={["xl", "2xl"]}>
          {translate("releases")}
        </Text>
        <FlatList
          ml={5}
          horizontal
          data={releases}
          initialNumToRender={3}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <Pressable
              mr={5}
              maxW={250}
              key={index}
              onPress={() => Linking.openURL(item.spotify_url)}
            >
              <VStack space={2} flex={1}>
                <Image
                  w={250}
                  h={250}
                  alt={item.title}
                  resizeMode={"contain"}
                  source={{ uri: item.image_with_url?.sm?.url }}
                />
                <HStack space={1} alignItems={"center"}>
                  <Icon
                    size={5}
                    name={"spotify"}
                    color={"darkText"}
                    as={MaterialCommunityIcons}
                  />
                  <Text fontSize={"md"} noOfLines={1} isTruncated maxW={240}>
                    {item.title}
                  </Text>
                </HStack>
              </VStack>
            </Pressable>
          )}
          ListFooterComponent={
            releases?.length
              ? () => (
                  <Pressable
                    mr={5}
                    w={250}
                    h={250}
                    onPress={() =>
                      Linking.openURL(
                        `https://open.spotify.com/artist/${musician?.spotify_id}`
                      )
                    }
                  >
                    <VStack
                      p={2}
                      flex={1}
                      space={2}
                      bgColor={"darkText"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon
                        size={16}
                        color={"white"}
                        name={"spotify"}
                        as={MaterialCommunityIcons}
                      />
                      <Text
                        bold
                        fontSize={"sm"}
                        color={"white"}
                        textTransform={"uppercase"}
                      >
                        {translate("open_spotify")}
                      </Text>
                    </VStack>
                  </Pressable>
                )
              : null
          }
        />
      </VStack>
    )
  );
};
