import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosContext } from "services/axios.context";

const useDeleteTrackMasterRight = (track) => {
  const { client } = useContext(AxiosContext);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  return {
    deleteTrackMasterRight: useMutation(
      (masterRight) => {
        return client.delete(
          `tracks/${track.id}/master_rights/${masterRight.id}/`
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`track-${track.id}`);
          console.debug(`invalidated query track-${track.id}`);
        },
      }
    ),
    error: error,
  };
};

export default useDeleteTrackMasterRight;
