import React from "react";
import { VStack, HStack, Stack, Flex, FormControl } from "native-base";
//internal hooks
import { useCountries } from "features/royalties/releases/hooks/useCountries";
// utility functions
import { translate } from "utils/helpers";
// form Components
import { FormControlInput } from "theme/forms/form-control-input.component";
import { FormControlSelect } from "theme/forms/form-control-select.component";
import { SkeletonFormControlInput } from "theme/skeletons/skeleton.form-control-input";
import { FormControlDatePicker } from "theme/forms/form-control-date-picker.component";
import { FormControlSelectWithDict } from "theme/forms/form-control-select-with-dict.component";

interface IProps {
  profileData: any;
  musicianData: any;
  control: any;
  isLoading: boolean;
}

export const HorizontalInputs = ({ leftComponent, rightComponent }) => (
  <HStack justifyContent="space-between" alignItems="center">
    <Stack w="49%">{leftComponent}</Stack>
    <Stack w="49%">{rightComponent}</Stack>
  </HStack>
);

export const MusicianEditPersonalDataForm = ({
  profileData,
  musicianData,
  control,
  isLoading,
}: IProps) => {
  const isDraftApplication = musicianData?.status === `draft`;
  return (
    <Flex my={{ lg: "16" }} direction={{ base: "column", lg: "row" }}>
      <VStack
        p="5"
        space="5"
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
        flex={{ lg: 1 }}
      >
        <FormControl>
          <HorizontalInputs
            leftComponent={
              <SkeletonFormControlInput
                label={translate("first_name")}
                isLoading={isLoading}
              >
                <FormControlInput
                  name="first_name"
                  label={translate("first_name")}
                  rules={{ required: true }}
                  isDisabled={!isDraftApplication}
                  control={control}
                  placeholder={translate("first_name")}
                  value={profileData.first_name}
                />
              </SkeletonFormControlInput>
            }
            rightComponent={
              <SkeletonFormControlInput
                label={translate("last_name")}
                isLoading={isLoading}
              >
                <FormControlInput
                  name="last_name"
                  label={translate("last_name")}
                  control={control}
                  rules={{ required: true }}
                  isDisabled={!isDraftApplication}
                  placeholder={translate("last_name")}
                  value={profileData.last_name}
                />
              </SkeletonFormControlInput>
            }
          />

          <FormControl.HelperText>
            {translate("full_name_helper_text")}
          </FormControl.HelperText>
        </FormControl>
        <SkeletonFormControlInput
          label={translate("company_name")}
          isLoading={isLoading}
        >
          <FormControlInput
            label={translate("company_name")}
            name="company_name"
            control={control}
            isDisabled={!isDraftApplication}
            placeholder={translate("company_name")}
            value={profileData.company_name}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("email")}
          isLoading={isLoading}
        >
          <FormControlInput
            label={translate("email")}
            name="email"
            control={control}
            rules={{ required: true, pattern: /\S+\@\S+\.\S+/ }}
            isDisabled={!isDraftApplication}
            placeholder={translate("email")}
            value={profileData.email}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("birthdate")}
          isLoading={isLoading}
          splitSkeleton
        >
          <FormControlDatePicker
            label={translate("birthdate")}
            name="birthdate"
            control={control}
            maxYear={2022}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            value={profileData.birthdate}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("nationality")}
          isLoading={isLoading}
        >
          <FormControlSelect
            name="nationality"
            label={translate("nationality")}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            control={control}
            query={useCountries()}
            value={profileData.nationality}
          />
        </SkeletonFormControlInput>
      </VStack>
      <VStack
        p="5"
        space="5"
        _light={{ bg: "light.100" }}
        _dark={{ bg: "dark.900" }}
        ml={{ lg: "10" }}
        mt={{ base: "10", lg: "0" }}
        flex={{ lg: 1 }}
      >
        <SkeletonFormControlInput
          label={translate("street")}
          isLoading={isLoading}
        >
          <FormControlInput
            name="street_of_residence"
            label={translate("street")}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            control={control}
            placeholder={translate("street")}
            value={profileData.street_of_residence}
          />
        </SkeletonFormControlInput>
        <SkeletonFormControlInput
          label={translate("house_number")}
          isLoading={isLoading}
        >
          <FormControlInput
            name="house_number_of_residence"
            label={translate("house_number")}
            control={control}
            rules={{ required: true }}
            isDisabled={!isDraftApplication}
            placeholder={translate("house_number_placeholder_text")}
            value={profileData.house_number_of_residence}
          />
        </SkeletonFormControlInput>
        <HorizontalInputs
          leftComponent={
            <SkeletonFormControlInput
              label={translate("postal_code")}
              isLoading={isLoading}
            >
              <FormControlInput
                name="postal_code_of_residence"
                label={translate("postal_code")}
                rules={{ required: true, pattern: /^\d+$/ }}
                isDisabled={!isDraftApplication}
                control={control}
                placeholder={translate("postal_code_placeholder_text")}
                value={profileData.postal_code_of_residence}
              />
            </SkeletonFormControlInput>
          }
          rightComponent={
            <SkeletonFormControlInput
              label={translate("city")}
              isLoading={isLoading}
            >
              <FormControlInput
                name="city_of_residence"
                label={translate("city")}
                rules={{ required: true }}
                isDisabled={!isDraftApplication}
                control={control}
                placeholder={translate("city_placeholder_text")}
                value={profileData.city_of_residence}
              />
            </SkeletonFormControlInput>
          }
        />
        <SkeletonFormControlInput
          label={translate("country_of_residence")}
          isLoading={isLoading}
        >
          <FormControlSelectWithDict
            selectedValue={profileData.country_of_residence}
            name="country_of_residence"
            label={translate("country_of_residence")}
            isDisabled={!isDraftApplication}
            control={control}
            rules={{ required: true }}
            dict={[
              { label: "Germany", value: "DE" },
              { label: "Austria", value: "AT" },
            ]}
            infoText={translate("country_help_text")}
          />
        </SkeletonFormControlInput>
      </VStack>
    </Flex>
  );
};
