import React from "react";
import { openBrowserAsync } from "expo-web-browser";
import { VStack, HStack, Text } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// internal functions
import { translate } from "utils/helpers";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";

import useCollectibleMint from "features/general/collectibles/hooks/useCollectibleMint";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { NewButtonBody } from "theme/buttons/new-button-body.component";
import { SecondaryButton } from "theme/buttons/secondary-button.component";

export const CollectibleDetailMint = ({ collectible }) => {
  const profile = useUserProfile();
  const navigation = useNavigation();
  const mintCollectible = useCollectibleMint();

  if (collectible?.mint_key) {
    return (
      <VStack space={5}>
        <HStack justifyContent="space-between" my="4">
          <Text flex={1} fontSize={["sm", "md"]} color="muted.400">
            {translate("sol_address")}
          </Text>
          <HStack alignItems={"center"} space={1}>
            <Text fontSize={["sm", "md"]} bold>
              {`${collectible.mint_key?.slice(
                0,
                7
              )}...${collectible.mint_key?.slice(-7)}`}
            </Text>
            <IconModal
              icon={<MaterialCommunityIcons name="information-outline" />}
              contentText={translate("see_on_solscan_info")}
            />
          </HStack>
        </HStack>

        <SecondaryButton
          logoLeft={require("assets/images/brand-theme/solana.png")}
          text={translate("see_on_solscan")}
          onPress={async () => openBrowserAsync(collectible?.solscan_url)}
        />
      </VStack>
    );
  } else if (collectible?.is_mint_requested) {
    return (
      <NewButtonBody
        fontSize={"xs"}
        fontWeight={"medium"}
        isLoading={true}
        text={translate("nft_being_minted")}
      />
    );
  } else {
    return (
      <PrimaryButton
        nativeID="mint-nft"
        disabled={
          profile.isLoading || mintCollectible.isLoading || !collectible
        }
        isLoading={mintCollectible.isLoading}
        text={translate("mint_nft")}
        onPress={() => {
          if (
            profile?.data?.user?.wallets?.length > 0 &&
            profile?.data?.user?.wallets[0]?.public_key
          ) {
            mintCollectible.mutate(collectible?.id);
          } else {
            navigation.navigate("Profiles", {
              screen: "ProfileEdit",
            });
          }
        }}
      />
    );
  }
};
