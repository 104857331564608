import React, { useState, useEffect } from "react";
import { VStack } from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal hooks
import { translate } from "utils/helpers";
import useCreateOrder from "features/general/orders/hooks/useCreateOrder";
import { useCreateApplePaySession } from "features/general/payment-methods/hooks/useCreateApplePaySession";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ChoosePaymentMethod } from "features/general/payment-methods/components/choose-payment-method.component";
import { OrderCreateAgreeTermsForm } from "features/general/orders/components/order-create-agree-terms-form.component";
import { LicenseProductOrderCreateConfirmationPreview } from "features/royalties/license-product-orders/components/license-product-order-create-confirmation-preview.component";

export const LicenseProductOrderCreateConfirmation = ({
  units,
  product,
  musicians,
}) => {
  const { createOrder } = useCreateOrder();

  const [chosenPaymentMethodID, setChosenPaymentMethodID] = useState(null);
  const [chosenPaymentMethodType, setChosenPaymentMethodType] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const items = [
    {
      product_id: product.id,
      units: units,
    },
  ];
  const totalPrice = units * product?.price_per_unit;
  const isPaidLicense = product?.price_per_unit === 0;

  const { createApplePaySession } = useCreateApplePaySession(items, totalPrice);

  const navigation = useNavigation();

  useEffect(() => {
    if (createOrder.isSuccess) {
      navigation.navigate("Orders", {
        screen: "OrderDetail",
        params: {
          orderID: createOrder.data?.data?.id,
        },
      });
    }
  }, [createOrder.isSuccess]);
  //need to add profile.spotify id check here too
  return (
    <VStack px={2} my={5} space={5}>
      <LicenseProductOrderCreateConfirmationPreview
        units={units}
        product={product}
      />
      {!isPaidLicense && (
        <ChoosePaymentMethod
          items={items}
          totalPrice={totalPrice}
          createOrder={createOrder}
          chosenPaymentMethodID={chosenPaymentMethodID}
          setChosenPaymentMethodID={setChosenPaymentMethodID}
          setChosenPaymentMethodType={setChosenPaymentMethodType}
        />
      )}
      <OrderCreateAgreeTermsForm
        musician={musicians[0]}
        setDisabled={setDisabled}
        url={product.customer_info_sheet}
      />
      <PrimaryButton
        fontSize={"md"}
        borderRadius={"full"}
        text={translate("buy_now")}
        isLoading={createOrder.isLoading}
        disabled={
          (!isPaidLicense && !chosenPaymentMethodID) ||
          (!isPaidLicense && !chosenPaymentMethodType) ||
          createOrder.isLoading ||
          disabled
        }
        onPress={async () => {
          if (chosenPaymentMethodType === "applepay") {
            createApplePaySession.mutate([
              chosenPaymentMethodID,
              chosenPaymentMethodType,
            ]);
          } else {
            createOrder.mutate({
              items: items,
              secupay_payment_method_id: chosenPaymentMethodID,
              secupay_payment_method_type: chosenPaymentMethodType,
            });
          }
        }}
      />
    </VStack>
  );
};
