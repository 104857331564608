import React, { useEffect } from "react";
import { Text, Link as NBLink } from "native-base";
import { openBrowserAsync } from "expo-web-browser";

export const Link = ({
  text,
  url,
  color = "primary.300",
  fontSize = "sm",
  openLink = false,
  textBefore = "",
  textAfter = "",
}) => {
  const openUrl = async () => await openBrowserAsync(url);

  useEffect(() => {
    if (openLink) openUrl();
  }, [openLink]);

  return (
    <Text fontSize={fontSize}>
      {textBefore}{" "}
      <NBLink
        _ios={{ mb: 0 }}
        _text={{ fontSize: fontSize, color: color }}
        isUnderlined
        isExternal
        color={color}
        onPress={openUrl}
      >
        {text}
      </NBLink>{" "}
      {textAfter}
    </Text>
  );
};
