import React from "react";
import { VStack, Text } from "native-base";
// internal functions
import { isWeb, translate } from "utils/helpers";
// internal components
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { RevealCardComponent } from "common/components/misc/reveal-card.component";
import { useCollectible } from "features/general/collectibles/hooks/useCollectible";

export const TransferDetailSuccess = ({ transfer, navigation }) => {
  const collectible = useCollectible(transfer?.collectible_id);
  return (
    <VStack flex={1}>
      <VStack flex={1} justifyContent="center">
        {collectible.isSuccess && (
          <RevealCardComponent
            cardImageURL={collectible.data?.image_with_url?.md?.url}
          />
        )}
      </VStack>
      <VStack space={[10]}>
        <VStack space={5}>
          <Text
            bold
            fontSize={["xl", "2xl", "3xl"]}
            textAlign="center"
            textTransform={"uppercase"}
          >
            {translate("transfer_success_ticket_receiver")}
          </Text>
        </VStack>
        <PrimaryButton
          isLoading={collectible.isLoading}
          text={isWeb ? translate("my_cards") : translate("my_tickets")}
          disabled={collectible.isLoading || !collectible.isSuccess}
          onPress={() =>
            navigation.navigate("BottomNavigator", {
              screen: "Lounge",
            })
          }
        />
      </VStack>
    </VStack>
  );
};
