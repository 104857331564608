import React, { useContext } from "react";
import { FormControl, HStack, Text } from "native-base";
import { useController } from "react-hook-form";
import { Switch } from "react-native";
import { useNavigation } from "@react-navigation/native";
//internal components
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";
//internal hooks
import { translate } from "utils/helpers";
import { AuthenticationContext } from "services/authentication.context";

export const ProfileEditToggleIsPublic = ({
  value,
  control,
  name,
  isLoading,
}) => {
  const navigation = useNavigation();
  const { userDetails } = useContext(AuthenticationContext);
  const { field } = useController({
    control,
    defaultValue: value,
    name,
  });
  const onValueChange = (value) => {
    if (userDetails?.accepted_terms_and_conditions_version >= 2) {
      field.onChange(value);
    } else {
      navigation.navigate("Users", { screen: "UserEditTermsOfAgreement" });
    }
  };
  return (
    <FormControl>
      <HStack flex={1}>
        <HStack w="100%" alignItems="center" justifyContent="space-between">
          <Text fontSize={["md", "lg"]} fontWeight="bold">
            {translate("make_profile_public")}
          </Text>
          {isLoading ? (
            <SkeletonRounded width="15%" height="100%" />
          ) : (
            <Switch onValueChange={onValueChange} value={field.value} />
          )}
        </HStack>
      </HStack>
    </FormControl>
  );
};
