import React from "react";
import { Text, VStack, HStack, Image, Pressable } from "native-base";
import dayjs from "dayjs";
import { useNavigation } from "@react-navigation/native";
// internal hooks
import useCreateLicenseProduct from "features/royalties/license-products/hooks/useCreateLicenseProduct";
import useCreateAuction from "features/general/auctions/hooks/useCreateAuction";
// internal functions
import { translate } from "utils/helpers";
// internal components
import { AppIcon } from "theme/media/app-icon.component";
import { IconModal } from "theme/feedback/icon-modal.component";

const statuses = {
  draft: {
    icon: <AppIcon name="plus-circle-outline" />,
    title: "draft",
    color: undefined,
  },
  under_review: {
    icon: <AppIcon name="dots-horizontal-circle-outline" />,
    title: "under_review",
    color: "primary.600",
  },
  delivering: {
    icon: <AppIcon name="dots-horizontal-circle-outline" />,
    title: "under_review",
    color: "primary.600",
  },
  delivery_success: {
    icon: <AppIcon name="check-circle-outline" />,
    title: "approved",
    color: "secondary.900",
  },
  delivery_error: {
    icon: <AppIcon name="dots-horizontal-circle-outline" />,
    title: "under_review",
    color: "primary.600",
  },
};

const ReleaseCard = ({ existing = false, release, onPress }) => (
  <Pressable flex={1} my="3" mx={{ lg: "3" }} onPress={onPress}>
    <VStack
      p="4"
      space="3"
      borderRadius="xl"
      _light={{ bg: existing ? "lightBackground" : "light.100" }}
      _dark={{ bg: existing ? "darkBackground" : "dark.900" }}
    >
      <HStack space={3}>
        <Image
          size="lg"
          borderRadius="xl"
          resizeMode="contain"
          alt={release.title + " Cover"}
          source={
            release?.image_with_url?.sm?.url
              ? { uri: release?.image_with_url?.sm?.url }
              : require("assets/images/features/release-banner.jpeg")
          }
        />
        <VStack space={3} justifyContent="center" flex={1}>
          <HStack
            width={"100%"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text numberOfLines={1} fontWeight="semibold" fontSize="md">
              {release.title ? release.title : translate("untitled_release")}
            </Text>
            <AppIcon name="chevron-right" />
          </HStack>
          <Text fontSize="sm" fontWeight="bold">
            {release.fake_type}
          </Text>
          <Text fontSize="md">{release.total_tracks} Tracks</Text>
          <Text fontSize="sm">
            {release?.date
              ? `${translate("release")} ${dayjs(release.date).format(
                  "DD.MM.YYYY"
                )}`
              : translate("no_release_date")}
          </Text>
        </VStack>
      </HStack>
      <HStack space={1} alignItems="center">
        <IconModal
          icon={statuses[release.status]?.icon}
          contentText={`${translate("status")}: ${translate(release.status)}`}
        />
        <Text
          fontSize="sm"
          fontWeight="semibold"
          color={statuses[release.status]?.color}
        >
          {translate(statuses[release.status]?.title)}
        </Text>
      </HStack>
    </VStack>
  </Pressable>
);

export const ReleaseListCard = React.memo(({ release, onPress }) => (
  <ReleaseCard release={release} onPress={onPress} />
));

export const ExistingReleaseListCard = React.memo(({ release }) => {
  const { navigate } = useNavigation();
  const { createLicenseProduct } = useCreateLicenseProduct(release?.id);
  const { createAuction } = useCreateAuction(release?.id);

  const createDeal = async () => {
    await createLicenseProduct.mutateAsync();
    const auction = await createAuction.mutateAsync();
    if (!!auction?.id) {
      navigate("Auctions", {
        screen: "AuctionEditInfo",
        params: { id: auction?.id, auctionPartials: auction },
      });
    }
  };

  return <ReleaseCard existing release={release} onPress={createDeal} />;
});
