import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const useAuctionCollectibles = (auctionID) => {
  const { client } = useContext(AxiosContext);

  return useQuery(
    `auction-collectibles-${auctionID}`,
    () => {
      console.debug(`💙 Collectibles | Auction ${auctionID} `);
      return client.get(`auctions/${auctionID}/users/`).then((response) => {
        return response.data;
      });
    },
    {
      enabled: !!auctionID,
      placeholderData: undefined,
      staleTime: 300000,
      refetchOnWindowFocus: true,
    }
  );
};
