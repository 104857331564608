import React, { useState } from "react";
import {
  FlatList,
  HStack,
  Pressable,
  ScrollView,
  Text,
  VStack,
  useMediaQuery,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
// internal
import { translate } from "utils/helpers";
import { useUserBadges } from "features/accounts/profiles/hooks/useUserBadges";
import { UserProfileBadgeListCard } from "features/accounts/users/components/user-profile-badge-list-card.component";
import { useUserFirebaseProfile } from "features/accounts/profiles/hooks/useUserFirebaseProfile";
import { ListEmptyComponent } from "common/components/layout/list-empty.component";
import { CenteredSpinner } from "common/components/loading/centered-spinner.component";

export const UserProfileBadgeList = ({
  userID,
  screenList = false,
  isUserProfile = false,
}) => {
  const navigation = useNavigation();
  const user = useUserFirebaseProfile(userID);
  const [isDesktop] = useMediaQuery({ minWidth: 992 });

  const filters = ["all", "featured", "artist", "track"];
  const [filter, setFilter] = useState(filters[0]);
  const getFilterNameFromKey = (filter) => {
    switch (filter) {
      case "biddz":
        return "biddz";
      case "featured":
        return "featured";
      case "artist":
        return "artist";
      case "track":
        return "track";
      default:
        return "all";
    }
  };

  const badges = useUserBadges(userID, [filter]);
  const topBadges = user?.data?.top_badges || [];

  return (
    <VStack flex={1} px={3} space={5}>
      <VStack space={3}>
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Text fontWeight={600} fontSize={"xl"}>
            {translate("badges_title")}
          </Text>
          {!screenList && badges.data?.length > 0 && (
            <Pressable
              onPress={() =>
                navigation.navigate("Users", {
                  screen: "UserBadges",
                  params: { userID: userID },
                })
              }
            >
              <Text fontSize={"sm"} color={"gray.500"}>
                {translate("view_all")}
              </Text>
            </Pressable>
          )}
        </HStack>
        <Text fontSize={["sm", "md"]} color={"gray.400"}>
          {translate("badges_description")}
        </Text>
        <ScrollView
          pr={3}
          mx={-3}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {filters.map((item, index) => {
            const selected = filter === item;
            return (
              <Pressable
                ml={3}
                py={3}
                px={6}
                key={index}
                rounded={"full"}
                bg={selected ? "secondary.300" : "gray.100"}
                onPress={() => (selected ? undefined : setFilter(item))}
              >
                <Text fontSize={["sm", "md"]}>
                  {translate(getFilterNameFromKey(item))}
                </Text>
              </Pressable>
            );
          })}
        </ScrollView>
      </VStack>
      {!!badges.data?.length ? (
        <FlatList
          pr={5}
          mx={-3}
          data={badges.data}
          horizontal={!screenList}
          numColumns={screenList && isDesktop ? 3 : undefined}
          key={isDesktop ? "badges-list-desktop" : "badges-list"}
          contentContainerStyle={{
            alignItems: "center",
            justifyContent: "center",
          }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <UserProfileBadgeListCard
              key={index}
              badge={item}
              topBadges={topBadges}
              isUserProfile={isUserProfile}
            />
          )}
          onEndReachedThreshold={0.3}
          onEndReached={() => {
            badges.hasNextPage && !badges.isLoading
              ? badges.fetchData(true)
              : null;
          }}
          ListFooterComponent={
            badges.isLoading && (
              <VStack alignItems={"center"}>
                <CenteredSpinner />
              </VStack>
            )
          }
        />
      ) : (
        <VStack h={250} w={400} alignItems={"center"} justifyContent={"center"}>
          {badges?.isLoading ? (
            <CenteredSpinner />
          ) : (
            <ListEmptyComponent
              text={
                isUserProfile
                  ? filter === "featured"
                    ? translate("featured_badges_is_empty")
                    : translate("badges_is_empty")
                  : translate("profile_badges_is_empty")
              }
            />
          )}
        </VStack>
      )}
    </VStack>
  );
};
