import React from "react";
import { Text, VStack, FlatList, useMediaQuery } from "native-base";
// internal components
import { LicenseDetailBenefitListCard } from "features/royalties/license-benefits/components/license-detail-benefit-list-card.component";
//internal hooks
import { useBenefitTypes } from "features/general/auctions/hooks/useBenefitTypes";
// utility functions
import { translate } from "utils/helpers";

export const LicenseDetailBenefitList = ({
  benefits,
  collectibleBenefits = false,
  isBasicCollectible = false,
}) => {
  const benefitTypes = useBenefitTypes(true);
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  return (
    benefits?.length > 0 && (
      <VStack space={5}>
        <Text fontSize={["xl", "2xl"]} fontWeight="black">
          {translate("your_benefits")}
        </Text>
        <Text fontSize={["md", "lg"]}>
          {translate("benefits_when_buying_biddz")}
        </Text>
        <FlatList
          data={benefits}
          onEndReachedThreshold={0.3}
          showsVerticalScrollIndicator={false}
          horizontal={isDesktop ? false : true}
          numColumns={isDesktop ? 2 : undefined}
          showsHorizontalScrollIndicator={false}
          key={isDesktop ? "desktop-benfits" : "benefits"}
          renderItem={({ item }) => {
            const benefitType = benefitTypes?.data?.find(
              (b) => b.value === item?.type
            );
            return (
              <LicenseDetailBenefitListCard
                benefit={item}
                benefitType={benefitType}
                isBasicCollectible={isBasicCollectible}
                collectibleBenefits={collectibleBenefits}
              />
            );
          }}
        />
      </VStack>
    )
  );
};
