import { useContext } from "react";
import { useQuery } from "react-query";
import { AxiosContext } from "services/axios.context";

export const usePaymentMethods = (type = "*", enabled = true) => {
  const { client } = useContext(AxiosContext);
  return useQuery(
    ["payment-methods", type],
    () =>
      client.get(`payment_methods/?type=${type}`).then((resp) => {
        console.debug(`💵 Payment Methods | Type ${type} `);
        return resp.data;
      }),
    { enabled: enabled }
  );
};
