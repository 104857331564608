import { useContext } from "react";
import { useMutation } from "react-query";
import { AxiosContext } from "services/axios.context";

const useReportComment = (auctionID, commentID) => {
  const { client } = useContext(AxiosContext);

  return {
    reportComment: useMutation((params) =>
      client
        .post(`auctions/${auctionID}/comments/${commentID}/reports/`, params)
        .then((res) => res.data)
    ),
  };
};

export default useReportComment;
