import React, { useState, useRef } from "react";
import { Text, VStack, Center, HStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import dayjs from "dayjs";
// internal components
import { IconModal } from "theme/feedback/icon-modal.component";
import { TabNavigator } from "common/components/misc/tab-navigator.component";
import { Swiper } from "common/components/layout/swiper.component";
// helpers
import { translate, getLocalizedNumber } from "utils/helpers";

const Slide = ({ value }) => (
  <Center
    py="4"
    _light={{ bg: "light.100" }}
    _dark={{ bg: "dark.800" }}
    borderRadius="xl"
  >
    <Text fontSize={["xl", "2xl"]} fontWeight="semibold">
      {`${value} EUR`}
    </Text>
  </Center>
);

export const EarningSwiper = ({ earnings }) => {
  const swiper = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const lastMonthObj =
    earnings?.month && earnings.month[earnings.month?.length - 1];

  return (
    <VStack
      px="5"
      py="7"
      h="300"
      space="4"
      _light={{ bg: "light.100" }}
      _dark={{ bg: "dark.900" }}
      borderRadius="xl"
      borderWidth={1}
      borderColor="dark.800"
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Text fontSize="2xl" bold>
          {translate("income")}
        </Text>
        <IconModal
          iconColor="primary.600"
          icon={<AntDesign name="questioncircleo" />}
          contentText={translate("income_help_text")}
        />
      </HStack>

      <TabNavigator
        activeTab={activeTab}
        setActiveTab={(idx) => {
          swiper.current?.goTo(idx);
          setActiveTab(idx);
        }}
        options={[
          translate("since_beginning"),
          dayjs(lastMonthObj?.month).format("MMM YYYY"),
        ]}
      />

      <Swiper ref={swiper} onIndexChanged={(idx) => setActiveTab(idx)}>
        <Slide value={getLocalizedNumber(lastMonthObj?.cum_value || 0)} />
        <Slide value={getLocalizedNumber(lastMonthObj?.value || 0)} />
      </Swiper>
    </VStack>
  );
};
