import React from "react";
import * as Clipboard from "expo-clipboard";
import { Ionicons } from "@expo/vector-icons";
import {
  Box,
  VStack,
  Toast,
  Text,
  Divider,
  Stack,
  useMediaQuery,
} from "native-base";
import Constants from "expo-constants";
// internal functions
import { translate } from "utils/helpers";
//internal components
import { AppIconButton } from "theme/buttons/app-icon-button.component";
import { ProfileDetailHeaderSkeleton } from "features/accounts/profiles/skeletons/profile-detail-header.skeleton";
import { ProfileDetailHeaderInfo } from "features/accounts/profiles/components/profile-detail-header-info.component";
import { ProfileDetailHeaderStats } from "features/accounts/profiles/components/profile-detail-header-stats.component";
import { ProfileDetailHeaderSocials } from "./profile-detail-header-socials.component";
import { ProfileDetailHeaderBackgroundImage } from "features/accounts/profiles/components/profile-detail-header-background-image.component";

export const ProfileDetailHeader = ({ profile }) => {
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });
  return profile.isFetched ? (
    <VStack space={5}>
      <ProfileDetailHeaderBackgroundImage
        showProgress={false}
        profile={profile.data}
        url={profile?.data?.cover_image_with_url?.md?.url}
      />
      <Box p={5} position={"absolute"} top={0} right={isDesktop ? 24 : 5}>
        <AppIconButton
          as={Ionicons}
          nativeID={"share-profile"}
          iconName={"share-social-outline"}
          bg={"rgba(132, 132, 132, 0.83)"}
          onPress={() => {
            Clipboard.setStringAsync(
              Constants.expoConfig.extra?.APP_BASE_URL +
                `/profiles/${profile.data.id}`
            );
            Toast.show({
              placement: "top",
              render: () => (
                <Box mx={5} py={3} px={5} bg={"light.100"} borderRadius={"xl"}>
                  <Text>{translate("link_copied")}</Text>
                </Box>
              ),
            });
          }}
        />
      </Box>
      <VStack px={isDesktop ? 24 : 0}>
        <ProfileDetailHeaderInfo profile={profile.data} />
        <Stack direction={isDesktop ? "row" : "column"}>
          <ProfileDetailHeaderSocials profile={profile.data} />
          <ProfileDetailHeaderStats profile={profile.data} />
        </Stack>
      </VStack>
    </VStack>
  ) : (
    <ProfileDetailHeaderSkeleton />
  );
};
