import React from "react";
import { Text, VStack, HStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
import { usePaymentMethods } from "features/general/payment-methods/hooks/usePaymentMethods";
import useCreateLicenseProduct from "features/royalties/license-products/hooks/useCreateLicenseProduct";
import useCreateAuction from "features/general/auctions/hooks/useCreateAuction";
// internal components
import { SecondaryButton } from "theme/buttons/secondary-button.component";
import { PrimaryButton } from "theme/buttons/primary-button.component";
import { ReleaseDistributionDetails } from "features/royalties/releases/components/release-distribution-details.component";
import { ReleaseEditSummaryTrackCard } from "features/royalties/releases/components/release-edit-summary-track-card.component";
import { IconModal } from "theme/feedback/icon-modal.component";
// utility functions
import { translate } from "utils/helpers";

export const ReleaseDetail = ({ navigation, release, unlockRelease }) => {
  const { data: profile } = useUserProfile();
  const { data: bankAccount } = usePaymentMethods("bank_account");
  const { createLicenseProduct } = useCreateLicenseProduct(release?.id);
  const { createAuction } = useCreateAuction(release?.id);

  const createDeal = async () => {
    await createLicenseProduct.mutateAsync();
    const auction = await createAuction.mutateAsync();
    if (!!auction?.id) {
      navigation.navigate("Auctions", {
        screen: "AuctionEditInfo",
        params: { id: auction?.id, auctionPartials: auction },
      });
    }
  };

  return (
    <VStack flex={1} space="4">
      <HStack px="4" justifyContent="space-between">
        <Text fontSize={["md", "lg"]}>{translate("album_title")}</Text>
        <Text fontSize={["md", "lg"]} bold>
          {release?.title}
        </Text>
      </HStack>

      {release?.tracklisting?.map((listing, index) => (
        <ReleaseEditSummaryTrackCard
          key={index}
          index={index + 1}
          track={listing?.track}
          release={release}
        />
      ))}
      <ReleaseDistributionDetails
        release={release}
        profile={profile}
        bankAccount={bankAccount?.length ? bankAccount[0] : undefined}
      />

      <VStack mt="20" space="2">
        <PrimaryButton
          disabled={createLicenseProduct.isLoading}
          text={translate("create_fan_deal")}
          onPress={createDeal}
        />
        <HStack my="3" justifyContent="space-between" alignItems="center">
          <Text fontSize="md" color="light.700">
            {translate("unlock_to_edit_info")}
          </Text>
          <IconModal
            size="lg"
            iconColor="primary.600"
            icon={<AntDesign name="questioncircleo" />}
            contentText={translate("unlock_to_edit_help_text")}
          />
        </HStack>
        <SecondaryButton
          nativeID={"unlock-to-edit-release"}
          text={translate("unlock_to_edit")}
          onPress={unlockRelease}
        />
      </VStack>
    </VStack>
  );
};
