import React from "react";
import { FlatList, VStack, Text, HStack } from "native-base";
// internal components
import { PaymentMethod } from "features/general/payment-methods/components/payment-method.component";
import { AddPaymentMethodButton } from "features/general/payment-methods/components/add-payment-method-button.component";
import { SkeletonRounded } from "theme/skeletons/skeleton.rounded";

export const PaymentMethodList = ({ title, query, onAddButtonPress }) => (
  <VStack space={5}>
    <Text fontSize={["xl", "2xl"]} fontWeight="bold">
      {title}
    </Text>
    {query.isLoading ? (
      <HStack h={9} w={"100%"} my={2} space={2} overflow={"hidden"}>
        <SkeletonRounded width={["15%", "7%"]} />
        <SkeletonRounded width={["65%", "35%"]} />
      </HStack>
    ) : (
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        horizontal={true}
        data={query.data}
        contentContainerStyle={{
          alignItems: "center",
        }}
        ListHeaderComponent={
          <AddPaymentMethodButton onPress={onAddButtonPress} />
        }
        renderItem={({ item }) => <PaymentMethod paymentMethod={item} />}
      />
    )}
  </VStack>
);
