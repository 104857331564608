import React, { useState } from "react";
import {
  Pressable,
  VStack,
  Image,
  useMediaQuery,
  HStack,
  Icon,
  Text,
  ScrollView,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
// internal functions
import { translate } from "utils/helpers";
// internal hooks
import { useUserProfile } from "features/accounts/profiles/hooks/useUserProfile";
// internal Components
import {
  ReleaseDrawerList,
  releasesRoutesList,
} from "navigation/drawer/release-drawer-list.component";

const NavigationPanelItem = ({
  icon,
  title,
  onPress,
  focused,
  dropDown,
  iconComponent,
  dropDownComponent,
}) => {
  return (
    <>
      <Pressable
        p={3}
        mb={4}
        rounded={"xl"}
        onPress={onPress}
        bg={focused ? "gray.100" : "transparent"}
      >
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <HStack space={4} alignItems={"center"}>
            {iconComponent || <Image alt={title} size={6} source={icon} />}
            <Text selectable={false} fontSize={"lg"} fontWeight={500}>
              {title}
            </Text>
          </HStack>
          {dropDown && (
            <Icon
              size={5}
              as={Ionicons}
              color={"gray.400"}
              name={`chevron-${focused ? `up` : `down`}`}
            />
          )}
        </HStack>
      </Pressable>
      <VStack ml={6}>{focused && dropDown && dropDownComponent}</VStack>
    </>
  );
};

export const MyDrawerNavigator = () => {
  const route = useRoute();
  const profile = useUserProfile();
  const navigation = useNavigation();
  const [isDesktop] = useMediaQuery({ minWidth: 1200 });

  const [releases, setReleases] = useState(
    releasesRoutesList.includes(route.name)
  );

  const onNavigate = (screen, params = {}) => {
    navigation.navigate("BottomNavigator", { screen: screen, params: params });
    setReleases(false);
  };

  return (
    isDesktop && (
      <VStack
        p={5}
        w={300}
        borderRightWidth={1}
        bg={"lightBackground"}
        borderRightColor={"gray.300"}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          {/* <NavigationPanelItem
            title={translate("home")}
            onPress={() => onNavigate("Marketplace")}
            focused={route.name === "Marketplace" && !releases}
            icon={require("assets/images/icons/navigation/home.png")}
          /> */}
          {profile?.isLoading ? (
            <></>
          ) : profile?.data?.type === "artist" ? (
            <NavigationPanelItem
              dropDown={true}
              focused={releases}
              onPress={() => setReleases(!releases)}
              title={translate("releases_and_deals")}
              icon={require("assets/images/icons/navigation/shares.png")}
              dropDownComponent={<ReleaseDrawerList navigation={navigation} />}
            />
          ) : (
            <>
              <NavigationPanelItem
                title={translate("events")}
                onPress={() => onNavigate("Events")}
                icon={require("assets/images/icons/navigation/events.png")}
                focused={route.name === "Events" && !releases}
              />
              <NavigationPanelItem
                title={translate("share")}
                onPress={() => onNavigate("Shares")}
                icon={require("assets/images/icons/navigation/shares.png")}
                focused={route.name === "Shares" && !releases}
              />
            </>
          )}
          <NavigationPanelItem
            title={translate("settings")}
            focused={route.name === "AccountSettings"}
            icon={require("assets/images/icons/navigation/settings.png")}
            onPress={() =>
              navigation.navigate("Account", { screen: "AccountSettings" })
            }
          />
        </ScrollView>
      </VStack>
    )
  );
};
